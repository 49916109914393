/* eslint-disable import/prefer-default-export */
import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import Text from '../Text/Text';

import IconNative from '~/components/IconCommunity';

// Locals

const MAP_COLORS = {
  primary: {
    default: 'pri500',
    first: 'pri200',
    second: 'pri25'
  },
  white: {
    default: 'pri500',
    first: 'pri200',
    second: 'white'
  },
  secondary: {
    default: 'gra300',
    first: 'gra200',
    second: 'white'
  },
  info: {
    default: 'blu500',
    first: 'blu200',
    second: 'white'
  },
  danger: {
    default: 'red500',
    first: 'red200',
    second: 'white'
  },
  warning: {
    default: 'yel500',
    first: 'yel200',
    second: 'white'
  },
  success: {
    default: 'gre500',
    first: 'gre200',
    second: 'white'
  }
};

const getColors = (type = 'primary') => MAP_COLORS[type];

const colorStyles = ({ emphasis, theme, type, disabled }) => {
  const currentColor = getColors(type);

  if (disabled) {
    if (emphasis === 'outlined') {
      return css`
        background-color: white;
        border: 1px solid ${theme.colors.gra300};
      `;
    }
    return css`
      background-color: ${theme.colors[MAP_COLORS.secondary.default]};
    `;
  }

  if (emphasis === 'outlined') {
    return css`
      background-color: ${theme.colors[currentColor.second]};
      border: 1px solid ${theme.colors[currentColor.default]};
    `;
  }

  return css`
    background-color: ${theme.colors[currentColor.default]};
  `;
};

const RadiusBorder = () => css`
  border-radius: 32px;
`;

const IsClose = ({ theme, isClose }) => css`
  ${isClose &&
  css`
    padding-right: ${theme.spacings.byte};
  `}
`;

const size = {
  md: ({ theme }) =>
    css`
      min-height: ${theme.spacings.mega};
    `,
  lg: () =>
    css`
      min-height: 28px;
    `
};

const MAP_COLORS_TEXT = {
  primary: 'white',
  white: 'white',
  secondary: 'gra700'
};

const getColorsText = (type = 'primary') => MAP_COLORS_TEXT[type];

const colorStylesText = ({ emphasis, theme, chipType, disabled }) => {
  const currentColor = getColors(chipType);
  if (disabled) {
    if (emphasis === 'outlined') {
      return css`
        color: ${theme.colors.gra700};
      `;
    }
    return css`
      corlor: ${theme.colors[MAP_COLORS_TEXT.secondary]};
    `;
  }
  if (emphasis === 'outlined') {
    return css`
      color: ${theme.colors[currentColor.default]} !important;
    `;
  }
  const currentColorText = getColorsText(chipType);
  return css`
    color: ${theme.colors[currentColorText]} !important;
  `;
};
const TextStyled = styled(Text)`
  ${colorStylesText};
`;
const styledCursor = ({ isClose }) => css`
  cursor: pointer;
  ${isClose &&
  css`
    cursor: default;
  `};
`;

const MAP_COLORS_ICON = {
  primary: 'gra100',
  secondary: 'white'
};

const colorStylesIcon = ({ emphasis, theme, disabled, type }) => {
  const currentColor = getColors(type);
  if (disabled) {
    if (emphasis === 'outlined') {
      return css`
        background-color: ${theme.colors.gra300};
        &:hover {
          background-color: ${props => props.theme.colors.gra300};
        }
      `;
    }
    return css`
      background-color: ${theme.colors[MAP_COLORS_ICON.secondary]};
      &:hover {
        background-color: ${theme.colors[MAP_COLORS_ICON.secondary]};
      }
    `;
  }
  if (emphasis === 'outlined') {
    return css`
      background-color: ${theme.colors[currentColor.default]};
      &:hover {
        background-color: ${theme.colors[currentColor.first]};
      }
    `;
  }
  return css`
    background-color: ${theme.colors[MAP_COLORS_ICON.secondary]};
  `;
};

const IconClose = styled.div`
  ${colorStylesIcon};
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  &::after,
  &::before {
    ${colorStyles};
    border: none;
    position: absolute;
    top: 8.2px;
    left: 4px;
    width: 10px;
    height: 1.7px;
    content: '';
    border-radius: 0.6px;
  }
  &::before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;
const styledHover = ({ isClose, type, disabled, emphasis }) => {
  if (type === 'white' && !disabled && emphasis === 'outlined') {
    return css`
      &:hover {
        background-color: ${props => props.theme.colors.pri25};
      }
    `;
  }
  if (isClose) return null;
  return css`
    &:hover {
      opacity: 0.7;
    }
    &:focus {
      opacity: 0.9;
    }
  `;
};
const ContainerStyled = styled.span`
  ${RadiusBorder};
  ${colorStyles};
  ${styledCursor};
  ${styledHover}
  align-items: center;
  display: flex;
  width: max-content;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 4px;
  padding 2px 12px;
  ${props => size[props.size]};
  ${IsClose};
`;

const Left = styled.div`
  padding-right: 8px;
`;

const Right = styled.div`
  padding-left: 8px;
`;

const Icon = styled(IconNative)`
  font-size: 16px;
  width: 16px !important;
  ${({ emphasis, theme, disabled, chipType }) => {
    const currentColor = getColors(chipType);

    if (disabled) {
      return css`
        color: ${theme.colors.gra600} !important;
      `;
    }

    if (emphasis === 'outlined') {
      return css`
        color: ${theme.colors[currentColor.default]} !important;
      `;
    }
    return css`
      color: ${theme.colors[MAP_COLORS_ICON.secondary]};
    `;
  }}
`;

const handleClickWithoutBubbling =
  (cb = () => {}) =>
  ev => {
    ev.stopPropagation();
    cb();
  };
export const Chip = ({
  title,
  isClose,
  onRemove,
  uppercase,
  disabled,
  emphasis,
  icon,
  iconProps,
  ...props
}) => (
  <ContainerStyled
    emphasis={emphasis}
    disabled={disabled}
    isClose={isClose}
    {...props}
    uppercase={uppercase}
    data-testid={props['data-testid']}
  >
    {iff(icon, () => (
      <Left>
        <Icon
          {...iconProps}
          chipType={props.type}
          emphasis={emphasis}
          disabled={disabled}
          name={icon}
        />
      </Left>
    ))}
    <TextStyled
      chipType={props.type}
      as='span'
      emphasis={emphasis}
      disabled={disabled}
    >
      {title}
    </TextStyled>
    {iff(isClose, () => (
      <Right>
        <IconClose
          type={props.type}
          emphasis={emphasis}
          disabled={disabled}
          onClick={handleClickWithoutBubbling(onRemove)}
          name='TimesCircle'
          faType='solid'
          data-testid='close-button'
        />
      </Right>
    ))}
  </ContainerStyled>
);

Chip.propTypes = {
  title: PropTypes.string,
  isClose: PropTypes.bool,
  inverted: PropTypes.bool,
  emphasis: PropTypes.oneOf(['contained', 'outlined']),
  onRemove: PropTypes.func,
  uppercase: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'warning', 'success', 'danger', 'white']),
  size: PropTypes.oneOf(['lg']),
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconProps: PropTypes.objectOf({}).isRequired,
  renderRemoveIconCustom: PropTypes.func,
  'data-testid': PropTypes.string
};

Chip.defaultProps = {
  title: null,
  isClose: false,
  inverted: false,
  onRemove: () => {},
  emphasis: 'contained',
  type: 'primary',
  uppercase: false,
  size: 'lg',
  disabled: false,
  renderRemoveIconCustom: () => null,
  'data-testid': 'chip'
};
