import { css } from 'styled-components';

export const colorStyles = ({
  theme,
  isActived,
  isDanger,
  isValue,
  disabled
}) => css`
  ${!isActived &&
  css`
    color: ${theme.colors.gra700} !important;
  `}

  ${isDanger &&
  css`
    color: ${theme.colors.red500} !important;
  `};

  ${isActived &&
  !isDanger &&
  css`
    color: ${theme.colors.pri500} !important;
  `}
  ${(isActived || isValue) &&
  css`
    transform: translate3d(0, -150%, 0) scale(0.75);
    background-color: ${theme.colors.white};
    padding: 0 6px;
  `};

  ${disabled &&
  css`
    color: ${theme.colors.gra400} !important;
  `};
`;

export const transformStyles = ({ theme, isActived, isValue }) => css`
  ${(isActived || isValue) &&
  css`
    top: 19px;
    transform: translate3d(0, -150%, 0) scale(0.75);
    background-color: ${theme.colors.white};
    padding: 0 6px;
    left: 8px;
  `};
`;

export const textStyled = () => css`
  position: absolute;
  box-sizing: border-box;
  line-height: 1.15rem;
  text-align: left;
  transition: all 0.1s ease-in-out;
  cursor: text;
  transform-origin: left top;
  will-change: transform;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.009375em;
  text-overflow: ellipsis;
`;
