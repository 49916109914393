/* eslint-disable import/no-cycle */
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  result: {
    pipelineHistory: []
  },
  entities: {
    pipelineHistory: {}
  },
  loading: {
    archive: false,
    pipelineHistory: false,
    updatePipelineVersionMinor: false
  },
  items: [],
  modal: {
    visible: false
  },
  errors: {
    pipelineHistory: null
  },
  updateAndDelete: {
    delete: null,
    updatePipelineVersionMinor: null
  }
});

const normalizeData = ({ entity, data }) => {
  const historyPipelineSchema = new schema.Entity(entity);
  const mySchema = { pipelineHistory: [historyPipelineSchema] };
  return normalize(data, mySchema);
};

const pipelineHistory = {
  name: 'pipelineHistory',
  state: initialState,
  reducers: {
    setResultAndEntities(state, { result, entities }) {
      return state.merge({
        result,
        entities,
        errors: { pipelineHistory: null }
      });
    },
    setLoading(state, { path, value }) {
      return state.merge({ loading: { [path]: value } }, { deep: true });
    },
    opend(state, { path, value }) {
      return state.merge({
        modal: {
          [path]: value
        }
      });
    },
    setErrors(state, { path, value }) {
      return state.merge({
        errors: {
          [path]: value
        }
      });
    },
    setUpdateDelete(state, { path, value }) {
      return state.merge({
        errors: {
          [path]: value
        }
      });
    }
  },

  effects: dispatch => ({
    async fetch(params, { application }) {
      try {
        const { realm } = application.realm;
        if (params.pipelineName) {
          const { data } = await api.pipelineHistory.fetch({
            ...params,
            realm
          });
          const pipelines = normalizeData({ entity: 'pipelineHistory', data });
          dispatch.pipelineHistory.setResultAndEntities(pipelines);
        }
      } catch (e) {
        dispatch.pipelineHistory.setErrors({
          path: 'pipelineHistory',
          value: e
        });
      }
    },
    async remove(params, { application }) {
      try {
        const { realm } = application.realm;
        const { data } = await api.pipelineHistory.remove({ ...params, realm });
        dispatch.pipelineHistory.setUpdateDelete(
          { path: 'delete', value: data },
          params
        );

        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipelines.messages.success.archived_successfully'
          )
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('scenes.pipeline_history.messages.error.delete_item', {
            item: i18n.t('scenes.pipeline_history.labels.pipeline_singular')
          })
        });
        dispatch.pipelineHistory.setErrors({ path: 'delete', value: e });
      }
    },
    async archive(params, { application }) {
      try {
        dispatch.pipelineHistory.setLoading({
          path: 'archive',
          value: true
        });
        const { realm } = application.realm;
        const { data } = await api.pipelineHistory.archive({
          ...params,
          realm
        });
        dispatch.pipelineHistory.setLoading({
          path: 'archive',
          value: false
        });
        dispatch.pipelineHistory.setUpdateDelete(
          { path: 'archive', value: data },
          params
        );
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipeline_history.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.pipeline_history.messages.success.item_restored',
                {
                  item: `${i18n.t(
                    'scenes.pipeline_history.labels.pipeline_singular'
                  )}`
                }
              )}`
            }
          )
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipeline_history.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.pipeline_history.messages.error.restore',
                {
                  item: `${i18n.t(
                    'scenes.pipeline_history.labels.pipeline_singular'
                  )}`
                }
              )}`
            }
          )
        });
        dispatch.pipelineHistory.setErrors({
          path: 'archive',
          value: e
        });
        dispatch.pipelineHistory.setLoading({
          path: 'archive',
          value: false
        });
      }
    },
    async updatePipelineVersionMinor(params, { application }) {
      try {
        dispatch.pipelineHistory.setLoading({
          path: 'updatePipelineVersionMinor',
          value: true
        });

        const { realm } = application.realm;
        const { data } = await api.pipelineHistory.updatePipelineVersionMinor({
          ...params,
          realm
        });
        dispatch.pipelineHistory.setUpdateDelete({
          path: 'updatePipelineVersionMinor',
          value: data
        });
        dispatch.router.navigate({
          to: `/${realm}/design/pipelines/${data.updatePipelineVersionMinor}`,
          replace: true
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipeline_history.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.pipeline_history.messages.error.update_item',
                {
                  item: `${i18n.t(
                    'scenes.pipeline_history.labels.pipeline_singular'
                  )}`
                }
              )}`
            }
          )
        });
      }
    }
  }),
  logics: [
    {
      type: 'pipelineHistory/fetch',
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        if (payload.pipelineName) {
          dispatch.pipelineHistory.setLoading({
            path: 'pipelineHistory',
            value: true
          });
          dispatch.pipelineHistory.opend({ path: 'visible', value: true });
        }
        done();
      }
    },
    {
      type: 'pipelineHistory/setResultAndEntities',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelineHistory.setLoading({
          path: 'pipelineHistory',
          value: false
        });
        done();
      }
    },
    {
      type: 'pipelineHistory/setUpdateDelete',
      latest: true,
      process(context, dispatch, done) {
        const { pipelines } = context.getState();
        const { meta } = context.action;
        dispatch.designPipeline.reload();
        if (meta) {
          dispatch.pipelineHistory.fetch({
            pipelineName: meta.name,
            versionMajor: meta.versionMajor
          });
          dispatch.pipelines.getAll({
            search: { ...pipelines?.searchParams, page: 0 }
          });
          dispatch.project.fetch(null, { loading: false });
        }
        dispatch.pipelineHistory.setLoading({
          path: 'updatePipelineVersionMinor',
          value: false
        });
        done();
      }
    },
    {
      type: 'pipelineHistory/updatePipelineVersionMinor',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelineHistory.opend({ path: 'visible', value: false });

        done();
      }
    }
  ]
};

export default pipelineHistory;
