import { omit } from 'lodash';
import { element, array, oneOfType } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

export const StyledSingleValue = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${props => props.theme.colors.gra700};
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => {
    switch (props.selectProps.size) {
      case 'xs':
        return css`
          padding-bottom: 5px;
          padding-left: 3px;
          font-size: 15px;
        `;
      case 'md':
      default:
        return css`
          padding-bottom: 0px;
          font-size: 14px;
        `;
    }
  }}
`;

export const SingleValue = ({ children, ...props }) => (
  <StyledSingleValue title={children} {...omit(props, ['cx', 'theme'])}>
    {children}
  </StyledSingleValue>
);

SingleValue.propTypes = {
  children: oneOfType([element, array])
};

SingleValue.defaultProps = {
  children: null
};
