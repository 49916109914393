import { omit, isEmpty } from 'lodash';
import { normalize, schema } from 'normalizr';
import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';

import api from '~/api/pipelines';
import i18n from '~/common/helpers/i18n';

const normalizeData = ({ entity, data }) => {
  const pipelinesSchema = new schema.Entity(entity, {}, { idAttribute: '_id' });
  const mySchema = [pipelinesSchema];
  return normalize(data, mySchema);
};

const onUniqId = (allIds, entities) => {
  const content = entities?.content?.filter(
    entity => !allIds?.includes(entity._id)
  );
  return {
    ...entities,
    content
  };
};

const initialState = Immutable({
  // deployments: {},
  // triggers: [],
  result: {
    pipelines: []
  },
  entities: {
    pipelines: []
  },
  loading: {
    pipelines: false,
    upgradePipelineVersion: false,
    pagination: false,
    search: false
    // deployments: false
  },
  errors: {
    pipelines: null
  },
  update: {
    upgradePipelineVersion: null
  },
  last: false,
  first: true,
  searchParams: {
    name: '',
    disabledHistory: false,
    page: 0,
    projectId: null
  },
  numberOfElements: 0,
  number: 0,
  size: 0
});

const pipelines = {
  name: 'pipelines',
  state: initialState,
  reducers: {
    setResultAndEntities(state, { result, entities, ...props }) {
      return state.merge({
        result: { pipelines: result },
        entities,
        errors: { pipeline: null },
        ...props
      });
    },
    // setDeployments: (state, payload) => state.merge({ deployments: payload }),
    // setTriggers: (state, payload) => state.merge({ triggers: payload }),
    setError(state, error) {
      return state.merge({ errors: { pipelines: error } });
    },
    setLoading(state, { path, value }) {
      return state.merge({ loading: { [path]: value } }, { deep: true });
    },
    setSearch(state, payload) {
      return state.merge({ searchParams: payload });
    },
    removeResult(state, payload) {
      return state.merge(
        {
          result: {
            pipelines: payload
          }
        },
        { deep: true }
      );
    },
    setUpdate(state, { path, value }) {
      return state.merge(
        {
          update: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    clear: () => initialState
  },
  effects: dispatch => ({
    async getAll(
      context = { search: {} },
      { pipelines, application, project }
    ) {
      try {
        const { realm } = application.realm;
        const params = !isEmpty(context.search)
          ? context.search
          : pipelines.searchParams;
        // const listPipelines = await api.list({
        const listPipelines = await api.fetch({
          realm,
          search: {
            ...params,
            projectId: params.projectId ? params.projectId : project?.current
          }
        });
        // const result = listPipelines.data.listPipelines;
        const result = listPipelines.data.searchPipelines;
        const pipelineNormalize = normalizeData({
          entity: 'pipelines',
          data: result.content
        });

        dispatch.pipelines.setResultAndEntities(
          {
            ...pipelineNormalize,
            ...omit(result, ['content'])
          },
          context
        );
      } catch (e) {
        dispatch.pipelines.setError({ error: { pipeline: e.message } });
      }
    },
    // getPipelinesDeployments: async (_, { application }) => {
    //   try {
    //     dispatch.pipelines.setLoading({
    //       path: 'deployments',
    //       value: true
    //     });

    //     const { realm } = application.realm;
    //     const result = await api.getDeployments({ realm });
    //     const deployments = result.data.getPipelinesDeployments;

    //     dispatch.pipelines.setDeployments(deployments);
    //   } catch (e) {
    //     dispatch.pipelines.setError({ error: { pipeline: e.message } });
    //   } finally {
    //     dispatch.pipelines.setLoading({
    //       path: 'deployments',
    //       value: false
    //     });
    //   }
    // },
    // getTriggers: async (_, { application }) => {
    //   try {
    //     const { realm } = application.realm;
    //     const result = await api.getTriggers({ realm });
    //     const { triggers } = result.data;

    //     dispatch.pipelines.setTriggers(triggers);
    //   } catch (e) {
    //     dispatch.pipelines.setError({ error: { pipeline: e.message } });
    //   }
    // },
    cancel: () => api.cancel(),
    async getPage({ page }, state) {
      try {
        const { realm } = state.application.realm;
        // const listPipelines = await api.list({
        const listPipelines = await api.fetch({
          realm,
          search: { ...state?.pipelines?.searchParams, page }
        });
        const pipelinesNew = onUniqId(
          state?.pipelines?.result?.pipelines,
          // listPipelines?.data?.listPipelines
          listPipelines?.data?.searchPipelines
        );

        const pipelineNormalize = normalizeData({
          entity: 'pipelines',
          data: pipelinesNew.content
        });

        const result = [
          ...state.pipelines.asMutable({ deep: true }).result.pipelines,
          ...pipelineNormalize.result
        ];
        const entities = {
          ...state.pipelines.asMutable({ deep: true }).entities.pipelines,
          ...pipelineNormalize.entities.pipelines
        };
        const pipelines = {
          result,
          entities: {
            pipelines: entities
          }
        };

        dispatch.pipelines.setResultAndEntities({
          ...pipelines,
          // ...omit(listPipelines.data.listPipelines, ['content'])
          ...omit(listPipelines.data.searchPipelines, ['content'])
        });
      } catch (e) {
        dispatch.pipelines.setError({ error: { pipeline: e.message } });
      }
    },
    async upgradePipelineVersion(params, { application }) {
      try {
        const { realm } = application.realm;
        const { data } = await api.upgradePipelineVersion({ ...params, realm });
        dispatch.pipelines.setUpdate({
          path: 'upgradePipelineVersion',
          value: data
        });
        dispatch.router.navigate({
          to: `/${realm}/design/pipelines/${data.upgradePipelineVersion}`
        });
      } catch (e) {
        toast.error(
          `${i18n.t(
            'scenes.pipelines.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t('scenes.pipelines.messages.error.new_item')}`
            }
          )}`
        );
      }
    }
  }),
  logics: [
    {
      type: 'pipelines/getAll',
      latest: true,
      process({ action }, dispatch, done) {
        const { payload, meta } = action;
        if (payload?.search) {
          dispatch.project.setCurrent(payload?.search?.projectId);
        }

        dispatch.pipelines.setLoading({
          path: meta?.type ? meta.type : 'pipelines',
          value: true
        });
        done();
      }
    },
    {
      type: ['pipelines/getPage'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelines.setLoading({ path: 'pagination', value: true });
        done();
      }
    },
    {
      type: ['pipelines/setResultAndEntities', 'pipelines/setError'],
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.pipelines.setLoading({ path: 'pipelines', value: false });
        dispatch.pipelines.setLoading({ path: 'search', value: false });
        dispatch.pipelines.setLoading({ path: 'pagination', value: false });
        if (!isEmpty(meta?.search)) {
          dispatch.pipelines.setSearch(meta.search);
        }
        done();
      }
    },
    {
      type: ['pipelines/upgradePipelineVersion'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelines.setLoading({
          path: 'upgradePipelineVersion',
          value: false
        });
        done();
      }
    },
    {
      type: 'pipelines/setUpdate',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelines.setLoading({
          path: 'upgradePipelineVersion',
          value: true
        });
        done();
      }
    }
  ]
};

export default pipelines;

// 'profile/save'
