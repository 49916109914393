export const TOP = 'top';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
export const RIGHT = 'right';

/**
 * Alignment
 */
export const START = 'start';
export const END = 'end';
export const CENTER = 'center';

/**
 * Colors default
 */
export const SUCCESS = 'success';
export const DANGER = 'danger';
export const WARNING = 'warning';
export const NEUTRAL = 'neutral';
export const PRIMARY = 'primary';

/**
 * size default
 */
export const XL = 'xl';
export const LG = 'lg';
export const MD = 'md';
export const SM = 'sm';
export const XS = 'xs';
export const XXS = 'xxs';
