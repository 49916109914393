import React from 'react';
import styled from 'styled-components';

import IconNative from '~/components/IconCommunity';

const Icon = styled(IconNative)`
  color: ${props => props.theme.colors.pri400};
  margin-right: 16px;
`;

export const LoadingIndicator = () => null;
