import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const acls = {
  fetch: async ({ realm }) => {
    const response = await clientApollo.query({
      query: gql`
        query acls($realm: String!) {
          acls(realm: $realm) {
            id
            realm
            scopes
          }
        }
      `,
      variables: { realm }
    });
    return response.data.acls;
  },
  getScope: async ({ realm }) => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query acl($realm: String!) {
            acl(realm: $realm) {
              name
              roles
            }
          }
        `,
        variables: { realm }
      });
      return response.data.acl;
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default acls;
