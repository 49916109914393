import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import { Table, Th, THead, TBody, Tr, Td } from '~/components/DataTable';
import Skeleton from '~/components/Skeleton';

const DataTableLoading = ({ rows: rowsProps, cols: colsProps }) => {
  const rows = Array(rowsProps)
    .fill(undefined)
    .map((val, idx) => idx);
  const cols = Array(colsProps)
    .fill(undefined)
    .map((val, idx) => idx);

  return (
    <Table data-testid='data-table-loading-component'>
      <THead>
        <Tr>
          {cols?.map(() => (
            <Th>
              <Skeleton variant='rect' width='60%' height='16px' />
            </Th>
          ))}
        </Tr>
      </THead>
      <TBody hover={false}>
        {rows?.map(() => (
          <Tr>
            {cols?.map(() => (
              <Td>
                <Skeleton variant='rect' width='100%' height='16px' />
              </Td>
            ))}
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};

DataTableLoading.propTypes = {
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired
};

export default DataTableLoading;
