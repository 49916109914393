import { Input } from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import { useFormik } from 'formik';
import { FormEvent } from 'react';
import { useNavigate } from 'react-router';
// @ts-ignore
import * as yup from 'yup';

import * as Elements from './FormForgotPassword.elements';
import { Props } from './FormForgotPassword.enhancer';
import PrivacyPolicy from '../PrivacyPolicy';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';

const FogotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('scenes.user.messages.error.invalid_email')
    .required('scenes.login.messages.error.required_field'),
  realm: yup.string().required('scenes.login.messages.error.required_field')
});

const MessageContainer = ({
  label,
  description,
  onClick
}: {
  label: string,
  description: string,
  onClick: () => void,
}) => (
  <Elements.StyledContainer>
    <Elements.StyledMessage data-testid='governance-success-message-forgot-password-page'>{label}</Elements.StyledMessage>
    <Elements.StyledButton variant='primary' onClick={onClick}>
      {description}
    </Elements.StyledButton>
  </Elements.StyledContainer>
);

const FormForgotPassword = ({
  send,
  isSendEmail,
  returnToSignIn,
  loading,
  subdomain,
  disabledForgotPassword
}: Props) => {
  const navigate = useNavigate();
  const {
    handleChange,
    values,
    touched,
    errors,
    handleSubmit,
  } = useFormik({
    validationSchema: FogotPasswordSchema,
    initialValues: {
      email: '',
      realm: subdomain || ''
    },
    onSubmit: send,
  })

  return (
    choose(
      when(!!disabledForgotPassword, () => (
        <MessageContainer
          label={i18n.t('label.to_request_new_password_contact_manager')}
          description={i18n.t('scenes.login.actions.return_sign_in')}
          onClick={returnToSignIn}
        />
      )),
      when(isSendEmail, () => (
        <MessageContainer
          label={i18n.t('label.redefine_password_msg')}
          description={i18n.t('scenes.login.actions.return_sign_in')}
          onClick={returnToSignIn}
        />
      )),
      otherwise(() => (
        <Elements.StyledContainer>
          <form
            onSubmit={ev => {
              ev.preventDefault();
            }}
          >
            {!subdomain && (
              <Elements.StyledFields>
                <Input
                  data-testid='governance-realm-input-forgot-password-page'
                  className='fs-mask'
                  name='realm'
                  value={values.realm}
                  onChange={handleChange}
                  helperText={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t(getFieldError(touched, errors, 'realm'))}`
                    }
                  )}
                  invalid={Boolean(getFieldError(touched, errors, 'realm'))}
                  label={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t('scenes.login.labels.realm')}`
                    }
                  )}
                  placeholder={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t('scenes.login.labels.realm')}`
                    }
                  )}
                />
              </Elements.StyledFields>
            )}
            <Elements.StyledFields>
              <Input
                data-testid='governance-email-input-forgot-password-page'
                className='fs-mask'
                name='email'
                value={values.email}
                onChange={handleChange}
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(getFieldError(touched, errors, 'email'))}`
                  }
                )}
                invalid={Boolean(getFieldError(touched, errors, 'email'))}
                label={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.email')}`
                  }
                )}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.email')}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.ButtonsWrapper>
              <Elements.StyledButton
                data-testid='governance-confirm-password-button-forgot-password-page'
                key='buttonForgotPassword'
                variant='primary'
                onClick={(event) => handleSubmit(event as unknown as FormEvent<HTMLFormElement>)}
                loading={loading}
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.actions.confirm')}`
                  }
                )}
              </Elements.StyledButton>
              <Elements.StyledButton
                data-testid='governance-go-back-button-forgot-password-page'
                key='buttonGoBack'
                outlined
                variant='primary'
                onClick={() => navigate(-1)}
                disabled={loading}
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('noun.back')}`
                  }
                )}
              </Elements.StyledButton>
            </Elements.ButtonsWrapper>
            <PrivacyPolicy />
          </form>
        </Elements.StyledContainer>
      ))
    )
  );
};

export default FormForgotPassword;
