const generalFeatureFlags = {};

const featureFlagsComponents = {
  PLAYGROUND: 'components_playground',
  CONSUMER_BASIC_AUTH: 'components_consumer_basic_auth',
  COMPONENTS_EXPERIMENTAL_PALLETE_ENABLED_FRONT:
    'components_experimental_pallete_enabled_front'
};

const featureFlagDesignSystem = {
  THEME_NEW_BRAND: 'design_system_theme_new_brand',
  SETTINGS_GLOBALS_V2: 'settings_globals_v2',
  SETTINGS_ACCOUNTS_V2: 'settings_accounts_v2'
};

const featureFlagsLicense = {
  PREDICTION_BUTTON: 'subscription_project_license_prediction_button',
  USAGE_LIMITS_PAGE_LINK: 'subscription_usage_limits_page_view',
  USAGE_LIMITS_DETAIL_PAGE: 'subscription_usage_limits_internal_page_view',
  UPSELL_ALERT: 'subscription_license_checkout_modal'
};

const featureFlagsIdentityAccess = {
  ENABLE_IDP_ACTIVATION_RULE:
    'identityAccess_samlGroupMapping_enableActivationRule',
  RECAPTCHA_TEMPORARY_LOCKING:
    'identityAccess_nativeLogin_enableTemporaryLocked',
  SETTINGS_ACCOUNTS_NEW_DS: 'settings_accoutns_new_ds'
};

const featureFlagsGovernance = {
  GOVERNANCE_DS_MIGRATION_LICENSE_USAGE:
    'governance-ds-migration-license_usage',
  GOVERNANCE_DS_MIGRATION_ROLES: 'governance-ds-migration-roles',
  GOVERNANCE_DS_MIGRATION_USERS: 'governance-ds-migration-users',
  GOVERNANCE_ENABLE_SUPPORT_ACCESS: 'governance-enable-support-access',
  GOVERNANCE_ENABLE_POLICY_MANAGEMENT_SYSTEM:
    'governance-policy-management-system',
  GOVERNANCE_DS_MIGRATION_GROUPS: 'governance-ds-migration-groups',
  GOVERNANCE_DS_MIGRATION_CONSUMER_APIS:
    'governance-ds-migration-consumer-apis',
  GOVERNANCE_ENABLE_EXPORT_AUDIT: 'governance-audit-export-button',
  GOVERNANCE_DS_MIGRATION_MULTI_INSTANCE:
    'governance-ds-migration-multi-instance',
  GOVERNANCE_DS_MIGRATION_LOGIN_PAGES: 'governance-ds-migration-login-pages',
  GOVERNANCE_NEW_LOGIN_PAGE: 'governance-new-login-page',
  GOVERNANCE_NEW_THEME_LOGIN_PAGE: 'governance-new-theme-login-page',
  GOVERNANCE_NEW_LICENSE_PAGE: 'governance-new-license-page',
  GOVERNANCE_REDIRECT_CONSUMPTION_TO_TOGAI:
    'governance-redirect-consumption-to-togai',
  GOVERNANCE_NEW_CONSUMPTION_PAGE: 'governance-new-consumption-page'
};

const featureFlagsRun = {
  RUN_ENABLE_DEPLOYMENTPLAN: 'run_enable_deploymentplan',
  RUN_LIST_DESIGNSYSTEM: 'run_list_designsystem',
  RUN_CHANGE_FETCHAPI: 'run_change_fetchapi',
  RUN_POOLING_TIMER_DEPLOYMENTPLAN: 'run_pooling_timer_deploymentPlan',
  RUN_ENABLE_3DOTS_HISTORY: 'run_enable_3dots_history',
  RUN_ENABLE_LICENSEV2: 'run_enable_licensev2',
  RUN_SET_ENGINE_VERSION: 'run_set_engine_version',
  RUN_ALLOW_CONCURRENT_EXECUTION: 'run_allow_concurrent_execution'
};

const featureFlagsMonitor = {
  MONITOR_SETDEFAULTENVIRONMENT_FRONT: 'monitor_setdefaultenvironment_front',
  MONITOR_OVERVIEW_PAGINATION_ORDER: 'monitor_overview_pagination_order',
  MONITOR_GET_STATS_ALL: 'monitor_get_stats_all',
  MONITOR_METRICS_CHARTS_CROSSHAIR: 'monitor_metrics_charts_crosshair',
  MONITOR_METRICS_CHARTS_ZOOM: 'monitor_metrics_charts_zoom',
  MONITOR_CHART_SUCCESS_AND_ERRORS: 'monitor_chart_success_and_errors',
  MONITOR_OVERVIEW_INSIGHTS_COLUMN: 'monitor_overview_insights_column',
  MONITOR_CHART_TOTAL_LATENCY: 'monitor_chart_total_latency',
  MONITOR_CHART_CURRENTLY_RUNNING: 'monitor_chart_currently_running',
  MONITOR_CHART_INVOCATIONS_EXECUTIONS_ERRORS:
    'monitor_chart_invocations_executions_errors',
  MONITOR_CHART_QUEUE_INFLIGHT: 'monitor_chart_queue_inflight',
  MONITOR_CHART_MEMORY_MESSAGE: 'monitor_chart_memory_message',
  MONITOR_CHART_TOTAL_PIPELINE_RESPONSE_TIME:
    'monitor_chart_total_pipeline_response_time',
  MONITOR_CHART_TOTAL_RUNNING_REPLICAS: 'monitor_chart_total_running_replicas'
};

const featureFlagsBuild = {
  BUILD_CANVAS_AI_DOCUMENTATION: 'build_canvas_ai_documentation',
  BUILD_CANVAS_AI_COPILOT: 'build_canvas_ai_copilot',
  BUILD_CANVAS_AUTOCOMPLETE: 'build_canvas_autocomplete',
  BUILD_LINT_ITEM: 'build_lint_item',
  BUILD_TEST_MODE_SCALE_TO_ZERO: 'build_test_mode_scale_to_zero',
  SMART_CONNECTOR: 'smart_connector',
  SMART_CONNECTOR_AI_SUGGESTIONS: 'smart_connector_ai_suggestions',
  SMART_CONNECTOR_ENDPOINTS: 'smart_connector_endpoints',
  BUILD_ITEM_DBV2: 'build_item_dbv2',
  SMART_CONNECTOR_ADD_STEP: 'smart_connector_add_step',
  BUILD_API_FIRST: 'build_api_first',
  BUILD_SCAFFOLD_RESPONSES: 'build_scaffold_responses',
  BUILD_MENU_OPENAPI: 'build_menu_openapi',
  BUILD_TRIGGERS_WITH_CATEGORIES: 'build_triggers_with_categories',
  BUILD_ADD_STEP_REFACTOR_TEST: 'build_add_step_refactor_test',
  BUILD_PAYLOAD_MOCKING: 'build_payload_mocking',
  BUILD_CONNECTOR_MOCKING: 'build_connector_mocking',
  BUILD_FANTA_FORM_PERFORMANCE_IMPROVEMENT:
    'build_fanta_form_performance_improvement',
  BUILD_UNIFIED_CATALOG: 'build_unified_catalog',
  BUILD_RUN_SELECTED_STEPS: 'build_run_selected_steps',
  BUILD_DESIGN_HOOK: 'build_design_hook',
  BUILD_INSPECT_NEW_LAYOUT: 'build_inspect_new_layout'
};

const featureFlagsAI = {
  AI_CAPSULE_DOCUMENTATION: 'ai_capsule_documentation',
  AI_CHAT_ASSISTANT: 'ai_chat_assistant',
  AI_CHAT_ASSISTANT_ACTIONS: 'ai_chat_assistant_actions',
  AI_FLOW_AUTOCOMPLETE: 'ai_flow_autocomplete',
  AI_FLOW_SEGMENT_AUTOCOMPLETE: 'ai_flow_segment_autocomplete',
  AI_NEW_PIPEGEN_MODEL: 'ai_new_pipegen_model',
  AI_INSIGHTS_TOOLBAR_INTERACTIONS: 'ai_insights_toolbar_interactions'
};

const featureFlagsCapsules = {
  CAPSULE_ALLOW_UPDATE_TO_PUBLIC:
    'capsules_capsuleCollection_changeCapsuleCollectionToPublic',
  CAPSULE_API_V2: 'capsule_api_v2',
  CAPSULE_IGNORE_OUTSPEC_SCHEMA:
    'capsules_validateOutSpecSchema_ignoreValidation',
  CAPSULE_NEW_FORM_SETTINGS: 'capsule_new_form_settings',
  CAPSULE_API_V2_GROUPS: 'capsule_api_v2_groups',
  CAPSULES_CANVAS_SAVE_FORM_NAME_FIELD: 'capsules_canvas_save_form_name_field',
  CAPSULE_SAVE_SETTINGS_ALERT: 'capsule_save_settings_alert'
};

const featureFlagsConnectivity = {
  CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT: 'connectivity_ztna_mvp_enabled_front',
  CONNECTIVITY_ZTNA_MVP_DELETE_CONNECTION_ENABLED_FRONT:
    'connectivity_ztna_mvp_delete_connection_enabled_front',
  CONNECTIVITY_ZTNA_MVP_CREATE_ROUTE_ENABLED_FRONT:
    'connectivity_ztna_mvp_create_route_enabled_front',
  CONNECTIVITY_ZTNA_MVP_EDIT_ROUTE_ENABLED_FRONT:
    'connectivity_ztna_mvp_edit_route_enabled_front',
  CONNECTIVITY_ZTNA_MVP_DELETE_ROUTE_ENABLED_FRONT:
    'connectivity_ztna_mvp_delete_route_enabled_front',
  CONNECTIVITY_ZTNA_MVP_CREATE_ROUTE_BTACH_ENABLED_FRONT:
    'connectivity_ztna_batch_creation_enabled_front'
};

const featureFlagConstants = {
  ...generalFeatureFlags,
  ...featureFlagsBuild,
  ...featureFlagsAI,
  ...featureFlagsLicense,
  ...featureFlagsIdentityAccess,
  ...featureFlagsRun,
  ...featureFlagsMonitor,
  ...featureFlagDesignSystem,
  ...featureFlagsComponents,
  ...featureFlagsGovernance,
  ...featureFlagsCapsules,
  ...featureFlagsConnectivity
};

export default featureFlagConstants;
