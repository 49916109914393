import { faFaceSmileWink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Locals
import i18n from '~/common/helpers/i18n';
import Pagination from '~/components/_oldComponents/Pagination';
import Button from '~/components/Button';
import ButtonToolbar from '~/components/ButtonToolbar';
import {
  Table,
  Th,
  THead,
  TBody,
  Tr,
  Td,
  DataTableLoading
} from '~/components/DataTable';
import IconNative from '~/components/IconCommunity';

const Container = styled.div`
  margin-bottom: 40px;
`;

const Icon = styled(IconNative)`
  font-size: 16px;
`;

const Truncate = styled.p`
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  margin: 34px 0px;
`;

const TextHeader = styled.p`
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin: 20px;
`;

const BoxEmpty = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconFontAwesome = styled(props => <FontAwesomeIcon {...props} />)`
  color: ${({ theme }) => theme.colors.pri500};
  font-size: 32px;
  margin: 0 2px;
  margin-bottom: 8px;
`;

const TableDeployments = ({ deployments, fetch, loading }) => {
  const { application } = useSelector(state => state);

  function redirect(idPipeline) {
    return window.open(
      `/${application?.activeRealm}/design/v2/pipelines/${idPipeline}`,
      '_blank'
    );
  }

  if (loading) {
    return <DataTableLoading rows={20} cols={4} />;
  }

  if (deployments?.content?.length === 0) {
    return (
      <BoxEmpty>
        <IconFontAwesome icon={faFaceSmileWink} />
        <Truncate>{i18n.t('label.no_pipes_will_be_affected_empty')}</Truncate>
      </BoxEmpty>
    );
  }

  return (
    <Container className='fs-exclude'>
      <TextHeader>{i18n.t('label.associated_pipelines')}</TextHeader>
      <Table>
        <THead>
          <Tr>
            <Th>
              {i18n.t(
                'scenes.pipelines.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('label.pipeline_name_vers_two')}`
                }
              )}
            </Th>
            <Th>
              {i18n.t(
                'scenes.pipelines.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('label.version')}`
                }
              )}
            </Th>
            <Th>
              {i18n.t(
                'scenes.pipelines.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('label.project')}`
                }
              )}
            </Th>
            <Th>
              {i18n.t(
                'scenes.pipelines.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('label.environment')}`
                }
              )}
            </Th>
            <Th>
              {i18n.t(
                'scenes.pipelines.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('label.actions')}`
                }
              )}
            </Th>
          </Tr>
        </THead>
        <TBody>
          {deployments?.content.map(
            ({ pipeline, project, activeConfiguration }) => (
              <Tr key={pipeline.id}>
                <Td data-testid='pipelines-table_td-name'>
                  <Truncate title={pipeline?.name}>{pipeline?.name}</Truncate>
                </Td>
                <Td data-testid='pipelines-table_td-version'>
                  <Truncate title={pipeline?.description}>
                    {pipeline?.versionMajor}.{pipeline?.versionMinor}
                  </Truncate>
                </Td>
                <Td data-testid='pipelines-table_td-project'>
                  <Truncate title={project}>{project}</Truncate>
                </Td>
                <Td data-testid='pipelines-table_td-env'>
                  <Truncate title={pipeline?.env}>
                    {activeConfiguration?.environment?.name}
                  </Truncate>
                </Td>
                <Td>
                  <ButtonToolbar>
                    <Button
                      data-testid='pipelines-button-action-show-pipeline'
                      type='primary'
                      emphasis='icon'
                      title={i18n.t(
                        'scenes.pipelines.labels.upper_and_capitalize.capitalized',
                        {
                          item: `${i18n.t(
                            'label.open_pipe_in_new_tab_tooltip'
                          )}`
                        }
                      )}
                      onClick={() =>
                        redirect(pipeline.id, pipeline.canvasVersion)
                      }
                    >
                      <Icon name='ExternalLink' faType='solid' />
                    </Button>
                  </ButtonToolbar>
                </Td>
              </Tr>
            )
          )}
        </TBody>
      </Table>
      <Footer>
        <Pagination
          state={{}}
          actions={{}}
          activedPage={deployments?.number + 1 || 0}
          currentRangePage={10}
          onChange={page => fetch(page - 1)}
          totalElements={deployments?.totalElements || 0}
          perPageSize={deployments?.size || 0}
        />
      </Footer>
    </Container>
  );
};

TableDeployments.propTypes = {
  deployments: PropTypes.objectOf({}).isRequired,
  fetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TableDeployments;
