/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'lodash';
import Icon from '../../IconCommunity';

const StyledDropdownIndicator = styled.div`
  padding-right: 16px;
  padding-left: 8px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  ${props =>
    props.isFocused &&
    css`
      color: ${props => props.theme.colors.pri400} !important;
    `};
`;

StyledDropdownIndicator.defaultProps = {
  'data-testid': 'dropdown'
};

const IconStyled = styled(Icon)`
  font-size: ${props => props.theme.typographyCurrent.text.lg.fontSize};
  padding-right: 4px;
  cursor: pointer;
  ${props =>
    props.isFocused &&
    css`
      color: ${props => props.theme.colors.pri400} !important;
    `};
`;

export const DropdownIndicator = props => {
  const { selectProps } = props;

  return (
    <StyledDropdownIndicator {...omit(props, ['theme', 'cx'])}>
      <IconStyled
        isFocused={props.isFocused}
        name={selectProps.menuIsOpen ? 'ChevronUp' : 'ChevronDown'}
      />
    </StyledDropdownIndicator>
  );
};
