import {
  Dialog as DSDialog,
  Button as DSButton,
  Text as DSText
} from '@digibee/beehive-ui';
import React from 'react';

import * as Elements from './ModalRollback.elements';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Dialog, { ContentTitle, Title, Container } from '~/components/Dialog';
import { Deployment } from '~/entities/Deployment';

type ModalRollbackProps = {
  closeModalRollback: Function;
  deployment: Deployment;
  isDeleteRollback: boolean;
  rollbackDeploy: Function;
  loadingRollback: boolean;
};

const ModalRollback: React.FC<ModalRollbackProps> = ({
  closeModalRollback,
  deployment,
  isDeleteRollback,
  rollbackDeploy,
  loadingRollback
}) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  if (showDesignSystemComponents) {
    return (
      <DSDialog.Root open onOpenChange={() => closeModalRollback()}>
        {/* @ts-ignore */}
        <DSDialog.Content css={{ padding: '$3' }}>
          <DSDialog.Header>
            <DSText as='h1' variant='h4'>
              {i18n.t('label.rollback_pipe_deploy_prompt', {
                pipeName: deployment.pipeline.name
              })}
            </DSText>
          </DSDialog.Header>
          <DSText variant='b2'>
            {isDeleteRollback
              ? i18n.t('label.rollback_delete_pipe_deploy_msg')
              : i18n.t('label.rollback_pipe_deploy_msg')}
          </DSText>

          <DSDialog.Footer>
            <DSButton
              loading={loadingRollback}
              onClick={() => rollbackDeploy()}
              size='medium'
              variant='primary'
              data-testid='run-confirm-rollback'
            >
              {i18n.t('action.continue')}
            </DSButton>
            <DSDialog.Close asChild>
              <DSButton
                onClick={() => closeModalRollback()}
                data-testid={`run-cancel-promote-${deployment.id}`}
                outlined
                size='medium'
                variant='primary'
              >
                {i18n.t('scenes.design.labels.upper_and_capitalize.uppercase', {
                  item: `${i18n.t('scenes.design.actions.cancel')}`
                })}
              </DSButton>
            </DSDialog.Close>
          </DSDialog.Footer>
        </DSDialog.Content>
      </DSDialog.Root>
    );
  }

  return (
    <Dialog
      opened
      onRequestClose={closeModalRollback}
      width='40rem'
      closeOnEsc={closeModalRollback}
      closeOnOverlayClick={undefined}
      style={undefined}
      data-testid='run-modal-rollback'
    >
      <Container>
        <ContentTitle>
          <Elements.ContainerText>
            {/* @ts-ignore */}
            <Title align='left' weight='bold'>
              {i18n.t('label.rollback_pipe_deploy_prompt', {
                pipeName: deployment.pipeline.name
              })}
            </Title>
          </Elements.ContainerText>
          {/* @ts-ignore */}
          <Title align='left' weight='regular'>
            {isDeleteRollback
              ? i18n.t('label.rollback_delete_pipe_deploy_msg')
              : i18n.t('label.rollback_pipe_deploy_msg')}
          </Title>
        </ContentTitle>
      </Container>
      <Elements.Footer>
        {/* @ts-ignore */}
        <Elements.Button
          onClick={() => rollbackDeploy()}
          /* @ts-ignore */
          loading={loadingRollback}
          type='primary'
          data-testid='run-confirm-rollback'
        >
          {i18n.t('action.continue')}
        </Elements.Button>
        <Elements.Separator />
        <Elements.Button
          onClick={() => closeModalRollback()}
          type='link'
          data-testid={`run-cancel-promote-${deployment.id}`}
        >
          {i18n.t('scenes.design.labels.upper_and_capitalize.uppercase', {
            item: `${i18n.t('scenes.design.actions.cancel')}`
          })}
        </Elements.Button>
      </Elements.Footer>
    </Dialog>
  );
};

export default ModalRollback;
