import { theme, Text as NativeText, Button as NativeButton } from '@digibee/beehive-ui';

export const Container = theme.styled('div', {
  width: '100%'
});

export const Text = theme.styled(NativeText, {
  fontSize: '$2',
  marginBottom: '28px',
  textAlign: 'left',
  color: '$neu500',
  lineHeight: '1.4',
});

export const Title = theme.styled(Text, {
  fontSize: '$3',
  fontWeight: '$medium',
  color: '$pri500',
  marginBottom: '12px',
});

export const Button = theme.styled(NativeButton, {
  marginBottom: '8px',
  width: '100%',
  justifyContent: 'center'
});

export const Link = theme.styled('a', {
  color: '$blu500',
  fontSize: '$2',
  fontFamily: '$display',
  textDecoration: 'none'
});
