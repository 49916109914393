/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';
import head from 'lodash/head';

const capsules = {
  get: variables =>
    clientApollo.query({
      query: gql`
        query data($realm: String!, $id: String!) {
          capsuleComponents(realm: $realm, componentId: $id) {
            id
            title
            documentation
            name
            versionMajor
            versionMinor
            versionFix
            disabled
            description
            state
            capsuleKey
            capsuleCollectionId
            capsuleCollectionGroup
            iconName
            inSpec
            outSpec
            configExample
            jsonSchema
            testMode
            errorDetail
            canvas {
              edges {
                id
                target
                source
                data {
                  type
                  conditionType
                  conditionRule
                  condition
                  label
                  description
                  executionId
                }
              }
              nodes {
                id
                type
                data
              }
            }
          }
        }
      `,
      variables
    }),
  fetchCapsules: variables =>
    clientApollo.query({
      query: gql`
        query capsulesPaginations($realm: String!) {
          capsulesPaginations(realm: $realm) {
            content
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables
    }),
  createCollectionGroup: async variables => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation createCollectionGroup(
            $realm: String!
            $collectionId: String!
            $group: CollectionGroupInput
          ) {
            createCollectionGroup(
              realm: $realm
              collectionId: $collectionId
              group: $group
            ) {
              id
              title
            }
          }
        `,
        variables
      });
      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  editCollectionGroup: async variables => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation editGroupCollection(
            $realm: String!
            $collectionId: String!
            $groupId: String!
            $title: String
          ) {
            editGroupCollection(
              realm: $realm
              collectionId: $collectionId
              groupId: $groupId
              title: $title
            ) {
              id
              title
            }
          }
        `,
        variables
      });
      if (response?.data?.errors) {
        throw new Error(head(response?.data?.errors));
      }
      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  editCollectionGroupV2: async variables => {
    try {
      const { data } = await clientApollo.mutate({
        mutation: gql`
          mutation editGroupCollectionV2(
            $realm: String!
            $collectionId: String!
            $groupId: String!
            $title: String
          ) {
            editGroupCollectionV2(
              realm: $realm
              collectionId: $collectionId
              groupId: $groupId
              title: $title
            ) {
              id
              title
            }
          }
        `,
        variables
      });
      if (data?.errors) {
        throw new Error(head(data?.errors));
      }
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  archiveGroupCollection: async variables => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation archiveGroupCollection(
            $realm: String!
            $groupId: String!
            $title: String
          ) {
            archiveGroupCollection(
              realm: $realm
              groupId: $groupId
              title: $title
            ) {
              id
              title
            }
          }
        `,
        variables
      });
      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  fetchGroupCapsules: async variables => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query groupCapsules(
            $realm: String!
            $collectionId: String!
            $search: JSON
            $size: Int
          ) {
            groupCapsules(
              realm: $realm
              collectionId: $collectionId
              search: $search
              size: $size
            ) {
              content
              last
              totalElements
              totalPages
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables
      });
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  fetchCollectionGroup: async variables => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query collectionGroups($realm: String!, $collectionId: String!) {
            collectionGroups(realm: $realm, collectionId: $collectionId) {
              id
              title
            }
          }
        `,
        variables
      });
      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  fetchCollectionGroupPagination: async variables => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query collectionGroupsPagination(
            $realm: String!
            $collectionId: String!
            $search: JSON
          ) {
            collectionGroupsPagination(
              realm: $realm
              collectionId: $collectionId
              search: $search
            ) {
              content
              last
              totalElements
              totalPages
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables
      });

      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  fetchCollectionGroupPaginationV2: async variables => {
    try {
      const { data } = await clientApollo.query({
        query: gql`
          query collectionGroupsPaginationV2(
            $realm: String!
            $collectionId: String!
            $search: JSON
          ) {
            collectionGroupsPaginationV2(
              realm: $realm
              collectionId: $collectionId
              search: $search
            ) {
              content
              last
              totalElements
              totalPages
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables
      });

      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  fetchCollectionHeader: async variables => {
    try {
      const { data } = await clientApollo.query({
        query: gql`
          query capsuleCollectionHeaderV3($realm: String!, $disabled: Boolean) {
            capsuleCollectionHeaderV3(realm: $realm, disabled: $disabled) {
              id
              title
              iconName
              iconURL
              disabled
            }
          }
        `,
        variables
      });

      return data.capsuleCollectionHeaderV3;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  fetchComponents: variables =>
    clientApollo.query({
      query: gql`
        query componentsByCapsule(
          $realm: String!
          $capsuleId: String!
          $page: Int
        ) {
          componentsByCapsule(
            realm: $realm
            capsuleId: $capsuleId
            page: $page
          ) {
            content
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables
    }),
  saveComponent: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation updateCapsule(
          $realm: String!
          $capsule: CapsuleComponentInput!
        ) {
          updateCapsule(realm: $realm, capsule: $capsule) {
            id
            name
            title
            versionMajor
            versionMinor
            versionFix
            disabled
            description
            capsuleKey
            state
            capsuleCollectionId
            capsuleCollectionGroup
            iconName
            inSpec
            outSpec
            configExample
            jsonSchema
            testMode
            errorDetail
            canvas {
              nodes {
                id
                type
                data
              }
              edges {
                id
                source
                target
                data {
                  type
                  conditionType
                  conditionRule
                  condition
                  label
                  description
                  executionId
                }
              }
            }
          }
        }
      `,
      variables
    }),
  createNewCapsule: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation createNewCapsule($realm: String!, $capsule: JSON!) {
          createNewCapsule(realm: $realm, capsule: $capsule)
        }
      `,
      variables
    }),
  async isCapsuleNameValid({ realm, name }) {
    const request = await clientApollo.query({
      query: gql`
        query validateCapsuleName($realm: String!, $name: String!) {
          validateCapsuleName(realm: $realm, name: $name)
        }
      `,
      variables: {
        name,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request.data.validateCapsuleName;
  },
  async isCollectionNameValid({ realm, name }) {
    const request = await clientApollo.query({
      query: gql`
        query validateCollectionName($realm: String!, $name: String!) {
          validateCollectionName(realm: $realm, name: $name)
        }
      `,
      variables: {
        name,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request.data.validateCollectionName;
  },
  async saveCollectionHeader(variables) {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation createCollectionHeader(
            $realm: String!
            $header: CollectionHeaderInput
          ) {
            createCollectionHeader(realm: $realm, header: $header) {
              id
              title
            }
          }
        `,
        variables
      });
      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async editCollectionHeader(variables) {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation editCollectionHeader(
            $realm: String!
            $header: CollectionHeaderInput
          ) {
            editCollectionHeader(realm: $realm, header: $header) {
              id
              title
            }
          }
        `,
        variables
      });
      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  archiveComponent: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation archiveCapsuleComponent(
          $realm: String!
          $idCapsule: String!
          $idComponent: String!
        ) {
          archiveCapsuleComponent(
            realm: $realm
            idCapsule: $idCapsule
            idComponent: $idComponent
          )
        }
      `,
      variables
    }),
  deleteCollectionHeader: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation deleteCollectionHeader(
          $realm: String!
          $collectionHeaderId: String!
        ) {
          deleteCollectionHeader(
            realm: $realm
            collectionHeaderId: $collectionHeaderId
          )
        }
      `,
      variables
    }),
  deployCapsuleComponent: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation deployCapsuleComponent(
          $realm: String!
          $idCapsule: String!
          $idComponent: String!
        ) {
          deployCapsuleComponent(
            realm: $realm
            idCapsule: $idCapsule
            idComponent: $idComponent
          ) {
            id
            name
            title
            versionMajor
            versionMinor
            versionFix
            disabled
            description
            capsuleKey
            capsuleCollectionId
            state
            inSpec
            outSpec
            configExample
            jsonSchema
            testMode
            errorDetail
            canvas {
              nodes {
                id
                type
                data
              }
              edges {
                id
                source
                target
                data {
                  type
                  conditionType
                  conditionRule
                  condition
                  label
                  description
                  executionId
                }
              }
            }
          }
        }
      `,
      variables
    }),
  archiveCollection: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation archiveCollection($realm: String!, $collectionId: String!) {
            archiveCollection(realm: $realm, collectionId: $collectionId)
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw Error(e);
    }
  },
  unarchiveCapsule: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation unarchiveCapsuleV2(
            $realm: String!
            $capsuleCollectionId: String!
            $capsuleId: String!
          ) {
            unarchiveCapsuleV2(
              realm: $realm
              capsuleCollectionId: $capsuleCollectionId
              capsuleId: $capsuleId
            )
          }
        `,
        variables: {
          ...params
        }
      });
    } catch (e) {
      throw Error(e);
    }
  }
};

export default capsules;
