import React, { useEffect, useState } from 'react';

import * as Elements from './TokenInput.elements';

type Props = {
  id?: string;
  length?: number;
  onChange(value: string): void;
  danger?: boolean;
  name?: string;
  helperText?: string;
};

type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  name: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  // @ts-ignore
  (props, ref) => <Elements.Input {...props} innerRef={ref} />
);

function TokenInput({
  length = 6,
  onChange,
  id = 'input-token',
  danger = false,
  name = 'input',
  helperText
}: Props): JSX.Element {
  const [values, setValues] = useState<Array<string>>([]);
  const [autoFocusIndex, setAutoFocusIndex] = useState<number>();
  const [inputRefs, setInputRefs] = useState<
    Array<React.RefObject<HTMLInputElement>>
  >([]);

  useEffect(() => {
    let vals: Array<string> = [];
    let autoFocusIdx: number = 0;
    if (values?.length) {
      for (let i = 0; i < length; i++) {
        vals.push(values[i] || '');
      }
      autoFocusIdx = values.length >= length ? length - 1 : values.length - 1;
    } else {
      vals = Array(length).fill('');
    }
    setValues(vals);
    setAutoFocusIndex(autoFocusIdx);

    let iRefs: Array<React.RefObject<HTMLInputElement>> = [];

    for (let i = 0; i < length; i++) {
      iRefs.push(React.createRef());
    }
    setInputRefs(iRefs);
  }, []);

  useEffect(() => {
    const val = values.join('');
    onChange(val);
  }, [values]);

  useEffect(() => {
    if (autoFocusIndex !== undefined) {
      inputRefs[autoFocusIndex].current?.focus();
    }
  }, [autoFocusIndex]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.replace(/[\W_]+/g, '');
    if (value === '') return;
    const index = parseInt(e.target.dataset.id || '');
    let vals: Array<string> = [...values];
    let nextIndex: number = 0;
    if (value.length > 1) {
      nextIndex = value.length + index;
      const split = value.split('');
      split.forEach((item: string, i: number) => {
        const cursor = index + i;
        if (cursor < length) {
          vals[cursor] = item;
        }
      });
    } else {
      nextIndex = index + 1;
      vals[index] = value;
    }
    setValues(vals);

    if (nextIndex >= length) {
      nextIndex = length - 1;
    }
    setAutoFocusIndex(nextIndex);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, index: number) {
    if (e.key === 'Backspace') {
      const prevIndex: number = index - 1;
      e.preventDefault();
      const vals = [...values];
      if (vals[index]) {
        vals[index] = '';
      } else if (prevIndex >= 0) {
        vals[prevIndex] = '';
      }
      setAutoFocusIndex(prevIndex >= 0 ? prevIndex : 0);
      setValues(vals);
    }
  }

  return (
    <Elements.Wrapper danger={danger}>
      <Elements.InputWrapper>
        {values.map((value: string, index: number) => (
          <Input
            name={name}
            ref={inputRefs[index]}
            key={`${id}-${index}`}
            data-id={index}
            value={value}
            onChange={handleChange}
            onKeyDown={e => onKeyDown(e, index)}
          />
        ))}
      </Elements.InputWrapper>
      {helperText && <Elements.HelperText>{helperText}</Elements.HelperText>}
    </Elements.Wrapper>
  );
}

export default TokenInput;
