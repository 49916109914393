import { Text } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { Level } from '@digibee/flow';
import { faLocationCrosshairs } from '@fortawesome/pro-light-svg-icons';
import {
  faCaretDown,
  faCaretRight,
  faCircleDot,
  faRotate,
  faRotateExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from '@xstate/react';
import { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

type LevelNodeProps = {
  node: TreeNode<Level>;
};

const LevelNode = ({ node }: LevelNodeProps) => {
  const [showingActiveButton, setShowingActiveButton] = useState(false);
  const actor = useTreeContext(state => state.actor);
  const goToLevel = useTreeContext(state => state.goToLevel);

  const isChildrenExpanded = useSelector(
    actor,
    state => state.context.expandedById[node.id],
    shallowEqual
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state => {
    const { currentLevelComponent } = state.context;

    return currentLevelComponent
      ? currentLevelComponent.id() === node.item.parentComponent()?.id() ||
          node.item.comesFromComponent(currentLevelComponent.id())
      : false;
  });

  const emphasis = comesFromCurrentLevelComponent ? 'children' : undefined;

  const onClickNavigationAction = () => goToLevel(node);
  const onMouseEnter = () => setShowingActiveButton(true);
  const onMouseLeave = () => setShowingActiveButton(false);

  return (
    <Elements.Container data-testid='flow-tree-node-container'>
      <Elements.Item
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid='build-flow-tree-node-item'
        depthLeft={node.depth}
        emphasis={emphasis}
      >
        {choose(
          when(node.id === 'root', () => (
            <Elements.Icon type='root' icon={faCircleDot} />
          )),
          otherwise(() => (
            <>
              <Elements.ToggleWrapper
                onClick={() =>
                  actor.send({ type: 'TOGGLE_CHILDREN', id: node.id })
                }
              >
                <Elements.Icon
                  icon={isChildrenExpanded ? faCaretDown : faCaretRight}
                />
              </Elements.ToggleWrapper>
              <Elements.Icon
                icon={
                  (node.item as Level).type() === 'onProcess'
                    ? faRotate
                    : faRotateExclamation
                }
              />
            </>
          ))
        )}
        <Elements.Label type='level'>
          <Text variant='b3' disabled css={{ fontWeight: 400 }}>
            {node.label}
          </Text>
        </Elements.Label>
        {iff(showingActiveButton, () => (
          <Elements.FloatAction>
            <Elements.Action
              onClick={onClickNavigationAction}
              icon={faLocationCrosshairs}
            />
          </Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default LevelNode;
