/* eslint-disable */

import Immutable from 'seamless-immutable';
import { omit, isFunction } from 'lodash';
const confirmInitialState = {
  title: '',
  text: '',
  open: false,
  callbackAction: null,
  context: {},
  canConfirm: true
};

const initialState = Immutable({
  confirmDialog: confirmInitialState
});

const dialogs = {
  name: 'dialogs',
  state: initialState,
  reducers: {
    showConfirm(state, confirmDialog) {
      return state.merge({
        confirmDialog: {
          ...state.confirmDialog,
          ...confirmDialog
        }
      });
    },
    reset(state) {
      return state.merge({ confirmDialog: confirmInitialState });
    },
    setConfirmDialogContext(state, { key, value }) {
      return state.setIn(['confirmDialog', 'context', key], value);
    },
    setCanConfirm(state, value) {
      return state.setIn(['confirmDialog', 'canConfirm'], value);
    }
  },
  effects: dispatch => ({
    hideCorfirm: (confirmed, { dialogs }, { isCallbackCancel = false }) => {
      if (confirmed) {
        dispatch(dialogs.confirmDialog.callbackAction);
        dialogs.confirmDialog.callbackConfirm?.();
        return;
      }
      if (isFunction(dialogs.confirmDialog.callbackCancel)) {
        dialogs.confirmDialog.callbackCancel();
      }
    }
  }),
  logics: [
    {
      type: '*',
      latest: true,
      async transform({ action, getState }, next) {
        if (action.meta && action.meta.confirmDialog) {
          if (action.meta.confirmDialog.shouldDialogOpen) {
            const shouldOpen = await action.meta.confirmDialog.shouldDialogOpen(
              getState()
            );
            if (shouldOpen) {
              omit(action.meta.confirmDialog, ['shouldDialogOpen']);
              action.type = `${action.type}_to_confirm`;
              return next(action);
            }
          } else {
            action.type = `${action.type}_to_confirm`;
            return next(action);
          }
        }
        return next({ ...action, meta: omit(action.meta, ['confirmDialog']) });
      }
    },
    {
      type: '*',
      latest: true,
      process({ action }, dispatch, done) {
        if (action.meta && action.meta.confirmDialog) {
          dispatch.dialogs.showConfirm({
            ...action.meta.confirmDialog,
            callbackAction: {
              ...omit(action, ['meta.confirmDialog']),
              type: action.type.replace('_to_confirm', '')
            }
          });
        }
        done();
      }
    },
    {
      type: 'dialogs/hideCorfirm',
      latest: true,
      process({ action }, dispatch, done) {
        setTimeout(() => {
          dispatch.dialogs.reset();
        });
        done();
      }
    }
  ]
};

export default dialogs;
