import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  isSendEmail: StoreRootState['forgotPassword']['isSendEmail'],
  isInvalidLink: StoreRootState['forgotPassword']['isInvalidLink'],
  loading: StoreRootState['forgotPassword']['loading'],
  error: StoreRootState['forgotPassword']['error']['resetPassword'],
  resetPassword: StoreRootState['forgotPassword']['success']['resetPassword'],
  subdomain: StoreRootState['application']['subdomain'],

  send: StoreDispatcher['forgotPassword']['send'],
  returnToSignIn: StoreDispatcher['forgotPassword']['returnToSignIn'],
  returnToForgotPassword: StoreDispatcher['forgotPassword']['returnToForgotPassword'],
  onResetPassword: StoreDispatcher['forgotPassword']['onResetPassword'],
}

const enhancer = withHooks((outProps: object) => {
  const dispatch = useDispatch<StoreDispatcher>();
  const { forgotPassword, application } = useSelector((state: StoreRootState) => state);

  const props: Props = {
    isSendEmail: forgotPassword.isSendEmail,
    isInvalidLink: forgotPassword.isInvalidLink,
    loading: forgotPassword.loading,
    error: forgotPassword.error.resetPassword,
    resetPassword: forgotPassword.success.resetPassword,
    subdomain: application.subdomain,

    send: dispatch.forgotPassword.send,
    returnToSignIn: dispatch.forgotPassword.returnToSignIn,
    returnToForgotPassword: dispatch.forgotPassword.returnToForgotPassword,
    onResetPassword: dispatch.forgotPassword.onResetPassword
  }

  return {
    ...outProps,
    ...props,
  }
});

export default enhancer;
