import PropTypes from 'prop-types';
import React from 'react';
import { Link as LinkNative, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import IconNative from '~/components/IconCommunity';
import ListNative from '~/components/List';
import Menu from '~/components/Menu';
import Role from '~/components/Role';
import Text from '~/components/Text';

const Label = styled(Text)`
  cursor: pointer;
  display: flex;
  font-size: 12px;
  color: ${p => p.theme.colors.gra600};
  align-items: center;
`;

const Icon = styled(IconNative)`
  font-size: 16px;
  margin-left: 16px;
`;

const List = styled(ListNative)`
  width: 116px;
  border-radius: 10px;
`;

const Item = styled(ListNative.Item)`
  padding: 0 16px;
`;

const Link = styled(props => <LinkNative {...props} />)`
  text-decoration: none;
`;

const SelectRealm = ({ activeRealm, allIds, byId }) => {
  const location = useLocation();

  return (
    <Menu
      data-testid='realm-selection-menu'
      component={({ toggle }) => (
        <Label data-testid='selected-text' onClick={toggle} weight='regular'>
          Realm: {activeRealm}
          <Role name={['GROUP:SUPPORT']}>
            <Icon name='CaretDown' faType='solid' />
          </Role>
        </Label>
      )}
    >
      {({ toggle }) => (
        <Role name={['GROUP:SUPPORT']}>
          {allIds.length > 1 && (
            <List inset noHairlines>
              {allIds?.map(id => (
                <Link
                  key={id}
                  to={location.pathname.replace(activeRealm, byId[id]?.name)}
                  data-testid='link'
                  onClick={() => toggle(false)}
                >
                  <Item>
                    <Text data-testid='value-text' align='left'>
                      {byId[id]?.companyName}
                    </Text>
                  </Item>
                </Link>
              ))}
            </List>
          )}
        </Role>
      )}
    </Menu>
  );
};

SelectRealm.defaultProps = {
  activeRealm: '',
  allIds: [],
  byId: {}
};

SelectRealm.propTypes = {
  activeRealm: PropTypes.string,
  byId: PropTypes.objectOf({
    id: PropTypes.string,
    name: PropTypes.string,
    companyName: PropTypes.string
  }),
  allIds: PropTypes.arrayOf([PropTypes.string])
};

export default SelectRealm;
