import { useState } from 'react';
import ConfirmDialog from '../index';
import { ConfirmDialogConfig } from '../type/ConfirmDialog';
import { ConfirmDialogContext } from './confirmDialogContext';

const initialConfig: ConfirmDialogConfig = {
  cancelText: '',
  confirmText: '',
  content: '',
  danger: false,
  explanationLabel: '',
  explanationPlaceholder: '',
  onConfirm: () => {},
  onCancel: () => {},
  title: '',
  withExplanation: false,
  hideCancel: false
};

type ConfirmDialogProviderProps = {
  children: React.ReactElement;
};

export function ConfirmDialogProvider({
  children
}: ConfirmDialogProviderProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<ConfirmDialogConfig>(initialConfig);

  function onConfirm(explanationText?: string) {
    config.onConfirm(explanationText);
    setOpen(false);
  }

  function onCancel() {
    config.onCancel?.();
    setOpen(false);
  }

  return (
    <ConfirmDialogContext.Provider
      value={{
        open,
        config,
        setConfig,
        setOpen
      }}
    >
      <ConfirmDialog
        title={config.title}
        content={config.content}
        confirmText={config.confirmText}
        cancelText={config.cancelText}
        danger={config.danger}
        onConfirm={onConfirm}
        hideCancel={config.hideCancel}
        open={open}
        onCancel={onCancel}
        withExplanation={config.withExplanation}
        explanationPlaceholder={config.explanationPlaceholder}
        explanationLabel={config.explanationLabel}
        explanationShouldValidate={config?.explanationShouldValidate}
        data-testid={config['data-testid']}
      />
      {children}
    </ConfirmDialogContext.Provider>
  );
}
