import axios from 'axios';

// Locals
import { API_KEY, URL } from '~/common/helpers/environment';

const dataUrlToFile = async (dataUrl, fileName) => {
  const bstr = atob(dataUrl);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: 'image/jpg' });
};
const images = {
  save: async ({ realm, token, image, fileName }) => {
    try {
      const formData = new FormData();
      const file = await dataUrlToFile(image, fileName);
      formData.append('file', file);
      const { data } = await axios.post(
        `${URL}/design/realms/${realm}/staticContent`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  }
};

export default images;
