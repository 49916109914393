import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Status = styled.div`
  background: ${props => props?.theme?.colors?.gra25};
  padding: 6px 16px;
  color: ${props =>
    props.disabled ? props.theme.colors.gra300 : props.theme.colors.gra900};
  border-radius: 50px;
  border: 1px solid ${props => props.theme.colors.gra600};
`;

export const ContainerTextStatus = styled.div`
  margin-left: 10px;
`;
