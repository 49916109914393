import { Formik } from 'formik';
import get from 'lodash/get';
import { func, objectOf, bool, string } from 'prop-types';
import * as yup from 'yup';

import * as Elements from './FormLogin.elements';
import PrivacyPolicy from '../PrivacyPolicy';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import Button from '~/components/Button';
import { Input } from '~/components/Input';

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('scenes.login.messages.error.required_field')
    .required('scenes.login.messages.error.required_field'),
  realm: yup.string().required('scenes.login.messages.error.required_field'),
  code: yup.string(),
  password: yup.string().required('scenes.login.messages.error.required_field')
});

const FormLogin = ({ signin, error, loading, subdomain, realmParams }) => {
  function onErrors(touched, errors, field) {
    const keyErrorRequest = 'scenes.login.messages.error.login';
    const fieldError = getFieldError(touched, errors, field);
    const isError = fieldError || error;
    return isError ? fieldError || keyErrorRequest : null;
  }
  return (
    <Elements.StyledContainer autoComplete='off'>
      <Formik
        validationSchema={LoginSchema}
        initialValues={{
          email: '',
          realm: subdomain || '',
          password: '',
          code: '',
          recaptcha: ''
        }}
        onSubmit={({ realm, email, password, code }) =>
          signin({
            realm,
            email,
            password,
            code
          })
        }
        render={({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue
        }) => (
          <form
            onSubmit={ev => {
              ev.preventDefault();
            }}
          >
            {!subdomain && (
              <Elements.StyledFields>
                <Input
                  data-testid='governance-realm-input-login-page'
                  className='fs-mask'
                  name='realm'
                  value={values.realm}
                  helperText={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t(onErrors(touched, errors, 'realm'))}`
                    }
                  )}
                  danger={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t(onErrors(touched, errors, 'realm'))}`
                    }
                  )}
                  onBlur={event =>
                    setFieldValue(
                      'realm',
                      get(event, 'target.value', '').toLowerCase()
                    )
                  }
                  onChange={handleChange}
                  placeholder={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t('scenes.login.labels.realm')}`
                    }
                  )}
                />
              </Elements.StyledFields>
            )}
            <Elements.StyledFields>
              <Input
                data-testid='governance-email-input-login-page'
                className='fs-mask'
                name='email'
                value={values.email}
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(onErrors(touched, errors, 'email'))}`
                  }
                )}
                danger={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(onErrors(touched, errors, 'email'))}`
                  }
                )}
                autoComplete='new-password'
                onChange={handleChange}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.email')}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.StyledFields>
              <Input
                data-testid='governance-password-input-login-page'
                className='fs-exclude'
                name='password'
                value={values.password}
                onChange={handleChange}
                autoComplete='new-password'
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(onErrors(touched, errors, 'password'))}`
                  }
                )}
                danger={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(onErrors(touched, errors, 'password'))}`
                  }
                )}
                type='password'
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.password')}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.StyledFields>
              <Input
                data-testid='governance-two-factor-input-login-page'
                className='fs-exclude'
                name='code'
                value={values.code}
                onChange={handleChange}
                type='text'
                autoComplete='new-password'
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.two_factor')}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.StyledFields>
              <Elements.StyledForgetPassword
                to='/login/forgotpassword'
                data-testid='governance-forgot-password-link-login-page'
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      'scenes.login.messages.prompt.forgot_password'
                    )}`
                  }
                )}
              </Elements.StyledForgetPassword>
              <Elements.StyledForgetPassword
                to='/login/two-factor'
                data-testid='governance-two-factor-link-login-page'
              >
                {i18n.t('action.dont_have_access_to_two_factor_auth_link')}
              </Elements.StyledForgetPassword>
              <Button
                key='save'
                is='submit'
                type='primary'
                fluid
                onClick={handleSubmit}
                loading={loading}
                data-testid='governance-submit-button-login-page'
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.actions.login')}`
                  }
                )}
              </Button>
              {realmParams?.hasCustomerIdentityProvider && (
                <Elements.IdpButton
                  onClick={() => {
                    window.location.href = `${realmParams?.redirectUrl
                      }&locale=${i18n.t('label.fusion_localization_key')}`;
                  }}
                  data-testid='governance-idp-link-login-page'
                >
                  {i18n.t('action.login_identity_provider')}
                </Elements.IdpButton>
              )}
              <PrivacyPolicy />
            </Elements.StyledFields>
          </form>
        )}
      />
    </Elements.StyledContainer>
  );
};

FormLogin.propTypes = {
  signin: func,
  error: objectOf({}),
  loading: false,
  subdomain: string.isRequired,
  realmParams: objectOf({}).isRequired
};

FormLogin.defaultProps = {
  signin: () => { },
  error: null,
  loading: bool
};

export default FormLogin;
