import { Box, Text } from '@digibee/beehive-ui';

import * as Elements from '../FormLogin.elements';

import i18n from '~/common/helpers/i18n';

const HardLocked = ({ back }: { back: () => void}) => (
    <>
      <Elements.Fields>
        <Text 
          css={{
            marginBottom: '12px',
            fontSize: '$3',
            fontWeight: '$medium',
            color: '$pri500',
          }} 
          data-testid='governance-title-blocked-user-page'>
            {i18n.t('label.login_error')}
        </Text>
        <Text 
          css={{
            marginBottom: '28px',
            textAlign: 'left',
            lineHeight: '1.4',
            color: '$neu500',
            fontSize: '$2',
            fontWeight: '$regular',
          }} data-testid='governance-explanation-blocked-user-page'>
            {i18n.t('label.hard_locked_text')}
        </Text>
      </Elements.Fields>
      <Elements.Fields>
        <Elements.SubmitButton
          key='save'
          variant='primary'
          onClick={() => back()}
          data-testid='governance-submit-button-login-page'
        >
          {i18n.t('action.back')}
        </Elements.SubmitButton>
      </Elements.Fields> 
      <Box css={{ bottom: '32px', position: 'absolute', width: '100%' }}>
        <Text css={{ fontSize: '$2', marginBottom: 'unset' }}>
          {i18n.t('label.still_have_login_problems')}
        </Text>
        <Text css={{ fontSize: '$2', marginBottom: 'unset' }}>
          {i18n.t('label.read_our_article_about')}
          &nbsp;
          <Elements.Link
            href={i18n.t('action.blocked_access_login_issues_url')}
            target='_blank'
          >
            {i18n.t('action.blocked_access_login_issues')}
          </Elements.Link>
        </Text>
      </Box>
    </>
  );

export default HardLocked;
