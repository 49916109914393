import PropType from 'prop-types';
import React from 'react';
import { ThemeProvider as Provider } from 'styled-components';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import theme from '~/common/styled/theme';
import themeBrandV3 from '~/common/styled/theme-brand-v3';
import themeBrandV4 from '~/common/styled/theme-brand-v4';

const ThemeProvider = ({ children }) => {
  const { THEME_NEW_BRAND } = featureFlagConstants;
  const { treatments } = useFeatureFlag([THEME_NEW_BRAND]);
  const showNewBrand = treatments[THEME_NEW_BRAND].treatment === 'on';
  const defaultTheme = showNewBrand ? themeBrandV3 : theme;
  const themeBrand = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      ...themeBrandV4.colors
    }
  };

  return <Provider theme={themeBrand}>{children}</Provider>;
};

ThemeProvider.propTypes = {
  children: PropType.element
};

ThemeProvider.defaultProps = {
  children: null
};

export default ThemeProvider;
