/* eslint-disable */

import { gql } from '@apollo/client';
/*
  {
    find: List,
    get: One,
    create: new,
    path: update,
    remove: delete
  }
*/
import clientApollo from './clientApollo';

const relations = {
  find: (realm, environment) => {
    return clientApollo.query({
      query: gql`
        query relations($realm: String!, $environment: String!) {
          relations(realm: $realm, environment: $environment) {
            id
            name
            type
            description
            fieldNameA
            fieldNameB
          }
        }
      `,
      variables: {
        realm: realm,
        environment: environment
      }
    });
  },
  remove: (realm, name, environment) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation relationsDelete(
          $realm: String!
          $name: String!
          $environment: String
        ) {
          relationsDelete(realm: $realm, name: $name, environment: $environment)
        }
      `,
      variables: {
        realm,
        name,
        environment
      }
    });
  },
  create: (realm, newRelation, environment) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation saveRelation(
          $realm: String!
          $newRelation: RelationshipInput
          $environment: String
        ) {
          saveRelation(
            realm: $realm
            newRelation: $newRelation
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        newRelation,
        environment
      }
    });
  },
  put: (realm, newRelation, environment) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation updateRelation(
          $realm: String!
          $newRelation: RelationshipInput
          $environment: String
        ) {
          updateRelation(
            realm: $realm
            newRelation: $newRelation
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        newRelation,
        environment
      }
    });
  },
  findInstance: (realm, name, environment, searchQuery, page) => {
    return clientApollo.query({
      query: gql`
        query relationsInstance(
          $realm: String!
          $name: String!
          $environment: String!
          $field: String
          $value: String
          $page: Int
        ) {
          relationsInstance(
            realm: $realm
            name: $name
            environment: $environment
            field: $field
            value: $value
            page: $page
          ) {
            content
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables: {
        realm,
        environment,
        name,
        ...searchQuery,
        page
      }
    });
  }
};

export default relations;
