import { objectOf } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

// Locals
import FormFirstAccess from './FormFirstAccess';
import FormForgotPassword from './FormForgotPassword';
import FormIdentityProvider from './FormIdentityProvider';
import FormLogin from './FormLogin';
import FormRealm from './FormRealm';
import FormResetPassword from './FormResetPassword';
import FormUserBlocked from './FormUserBlocked';
import FormUserTemporaryLocked from './FormUserTemporaryLocked';
import FormUserUnblocked from './FormUserUnblocked';
import TwoFactorAlert from './TwoFactorAlert';

const mapStateToProps = ({ authentication }) => ({
  firstaccess: authentication.firstaccess
});

const RouteComponent = ({ firstaccess }) => {
  const params = useParams();

  switch (params.actionId) {
    case 'forgotpassword':
      return <FormForgotPassword />;
    case 'changepassword':
      return firstaccess ? <FormFirstAccess /> : <Navigate to='/login' />;
    case 'resetpassword':
      return <FormResetPassword />;
    case 'realm':
      return <FormRealm />;
    case 'error':
      return <FormUserBlocked />;
    case 'success':
      return <FormUserUnblocked />;
    case 'temporary-locked':
      return <FormUserTemporaryLocked />;
    case 'identity-provider':
      return <FormIdentityProvider />;
    case 'two-factor':
      return <TwoFactorAlert />;
    default:
      return <FormLogin />;
  }
};

export default connect(mapStateToProps, null)(RouteComponent);

RouteComponent.propTypes = {
  firstaccess: objectOf({})
};

RouteComponent.defaultProps = {
  firstaccess: null
};
