/* eslint-disable */
import axios, { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line import/no-cycle
import clientApollo from './clientApollo';
//Locals
import { isApolloError, gql } from '@apollo/client';
import { API_KEY, URL } from '~/common/helpers/environment';
import { Pipeline } from '~/entities/Pipeline';
import { TriggerSpec } from '~/entities/TriggerSpec';
import { Deployment } from '~/entities/Deployment';

type SaveImageData = {
  token: string;
  image: string;
  realm: string;
  thumbnailName?: string;
};

type PipelineSaveImageResponseData = {
  contentName: string;
  success: boolean;
  url: string;
};

type PipelineFetchData = {
  id: string;
  realm: string;
};

type GetExperimentalData = {
  id: string;
  realm: string;
};

type UpdateSensitiveFieldsData = {
  realm: string;
  pipelineId: string;
  sensitiveFields: string[];
};

type ValidatePipelineData = {
  realm: string;
  pipelineId: string;
  environment: string;
};

type IsPipelineNameValidData = {
  name: string;
  realm: string;
};

type CheckDraftDiffData = {
  id: string;
  realm: string;
  currentDraft: any;
};

type ConfirmData = {
  realm: string;
  pipelineId: string;
};

type SaveData = {
  realm: string;
  trackingId: string;
  pipeline: Partial<Pipeline>;
  projectId: string;
};

type PipelinesVersionMinorData = {
  realm: string;
  pipelineId: string;
  environment: string;
};

type MultiInstanceData = {
  realm: string;
  replicaName: string;
  environment: string;
};

type SensitiveFieldsProp = {
  logSensitiveFields: string[];
};

type PipelineFetchResult = {
  pipeline: Pick<
    Pipeline,
    | 'id'
    | 'name'
    | 'description'
    | 'draft'
    | 'versionMajor'
    | 'versionMinor'
    | 'inSpec'
    | 'outSpec'
    | 'triggerSpec'
    | 'hasAlert'
    | 'alert'
    | 'thumbnailName'
    | 'flowSpec'
    | 'disconnectedFlowSpecs'
    | 'canvasVersion'
    | 'disabled'
    | 'parameterizedReplica'
  >;
  sensitiveFields: SensitiveFieldsProp | null;
};

type GetExperimentalResult = {
  pipeline: Pick<
    Pipeline,
    | 'id'
    | 'name'
    | 'description'
    | 'draft'
    | 'versionMajor'
    | 'versionMinor'
    | 'inSpec'
    | 'outSpec'
    | 'parameterizedReplica'
    | 'triggerSpec'
    | 'hasAlert'
    | 'alert'
    | 'thumbnailName'
    | 'canvasVersion'
    | 'canvas'
  >;
  sensitiveFields: SensitiveFieldsProp | null;
};

type PipelineFindResult = {
  pipelines: {
    name: string;
    versionMajor: number;
    description: string;
    latestVersionMajor: number;
    parameterizedReplica: any;
    hasAlert: boolean | null;
    alert: string;
    versions: {
      id: string;
      versionMinor: number;
      triggerSpec: TriggerSpec;
    };
    deployments: Pick<Deployment, 'id' | 'status'> & {
      pipeline: Pick<Pipeline, 'id' | 'versionMajor' | 'versionMinor'>;
    };
  };
};

type PipelinesVersionMinorResult = {
  pipelinesVersionMinor: {
    id: string;
    name: string;
    versionMajor: number;
    versionMinor: number;
    deploy: any;
    hasAlert: boolean;
    disabled: boolean;
  };
};

type MultiInstanceResult = {
  replicaInstance: {
    name: string;
  };
};

type PipelineSaveResult = {
  updatePipeline: Pick<
    Pipeline,
    | 'id'
    | 'name'
    | 'description'
    | 'draft'
    | 'versionMajor'
    | 'versionMinor'
    | 'parameterizedReplica'
    | 'thumbnailName'
    | 'canvas'
  >;
};

type CheckDraftDiffResult = {
  pipeline: Pick<
    Pipeline,
    'id' | 'name' | 'description' | 'draft' | 'versionMajor' | 'versionMinor'
  >;
};

type IsPipelineNameValidResult = {
  validatePipelineName: boolean;
};

type ValidatePipelineResult = {
  validatePipeline: boolean;
};

let controllers: AbortController[] = [];

const pipeline = {
  saveImage: async (params: SaveImageData) => {
    const { token, image, realm } = params;
    try {
      const formData = new FormData();
      const name = `${uuid()}.jpg`;
      const file = new File([image], name, { type: 'image/jpg' });
      formData.append('file', file);
      const response: AxiosResponse = await axios.post<null>(
        `${URL}/design/realms/${realm}/staticContent`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      const data: PipelineSaveImageResponseData = response.data;
      return data.contentName;
    } catch (e) {
      if (e instanceof Error) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.message);
        }
      }
      throw e;
    }
  },
  fetch: (variables: PipelineFetchData) => {
    return clientApollo.query<PipelineFetchResult>({
      query: gql`
        query data($realm: String!, $id: ID!) {
          pipeline(realm: $realm, id: $id) {
            id
            name
            description
            draft
            versionMajor
            versionMinor
            inSpec
            outSpec
            parameterizedReplica
            triggerSpec
            hasAlert
            alert
            thumbnailName
            flowSpec
            disconnectedFlowSpecs
            disabled
            canvasVersion
            componentsCount
            connectedOnFlowComponentsCount
            usedComponents
            suggestedComponentsIds
            createdAt
          }
          sensitiveFields(realm: $realm, id: $id) {
            logSensitiveFields
          }
        }
      `,
      variables
    });
  },
  getExperimental: (variables: GetExperimentalData) => {
    try {
      controllers.forEach(controller => {
        return controller.abort();
      });

      controllers = [];

      const controller = new window.AbortController();

      controllers.push(controller);

      return clientApollo.query<GetExperimentalResult>({
        query: gql`
          query data($realm: String!, $id: ID!) {
            pipeline(realm: $realm, id: $id) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
              inSpec
              outSpec
              parameterizedReplica
              triggerSpec
              hasAlert
              alert
              thumbnailName
              canvasVersion
              componentsCount
              connectedOnFlowComponentsCount
              usedComponents
              suggestedComponentsIds
              createdAt
              canvas {
                nodes {
                  id
                  type
                  data
                }
                edges {
                  id
                  source
                  target
                  data {
                    type
                    conditionType
                    conditionRule
                    condition
                    label
                    description
                    executionId
                  }
                }
              }
            }
            sensitiveFields(realm: $realm, id: $id) {
              logSensitiveFields
            }
          }
        `,
        variables,
        context: { fetchOptions: { signal: controller.signal } }
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  get: (id: string, realm: string) => {
    try {
      return clientApollo.query({
        query: gql`
          query data($realm: String!, $id: ID!) {
            pipeline(realm: $realm, id: $id) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
              inSpec
              outSpec
              parameterizedReplica
              triggerSpec
              hasAlert
              alert

              canvas {
                nodes {
                  id
                  type
                  data
                }
                edges {
                  id
                  source
                  target
                  data {
                    type
                    conditionType
                    conditionRule
                    condition
                    label
                    description
                    executionId
                  }
                }
              }
            }
            components(realm: $realm) {
              name
              category
              jsonExample
              jsonSchema
              jsonSchemaEdge
              visual
              deprecated
              title
              type
              icon
            }
            triggers(realm: $realm) {
              name
              jsonExample
              configurable
              icon
              jsonSchema
            }
            sensitiveFields(realm: $realm, id: $id) {
              logSensitiveFields
            }
            templates(realm: $realm) {
              id
              name
              description
              version
              spec
              canvas
              disabled
              type
            }
            libraries(realm: $realm) {
              id
              name
              description
              icon
              libraryServices {
                id
                name
                description
                icon
                inSpec
                title
                versionMajor
                versionMinor
                type
                configExample
                disabled
              }
              realmMaintainer {
                id
                name
                description
                companyName
              }
            }
            replicas(realm: $realm) {
              label
            }
          }
        `,
        variables: {
          realm,
          id
        }
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  find: (realm: string) => {
    try {
      return clientApollo.query<PipelineFindResult>({
        query: gql`
          query pipelines(
            $realm: String!
            $search: String
            $onlyDeployed: Boolean
          ) {
            pipelines(
              realm: $realm
              search: $search
              onlyDeployed: $onlyDeployed
            ) {
              name
              versionMajor
              description
              latestVersionMinor
              parameterizedReplica
              hasAlert
              alert
              versions {
                id
                versionMinor
                triggerSpec
              }
              deployments {
                id
                status
                pipeline {
                  id
                  versionMajor
                  versionMinor
                }
              }
            }
          }
        `,
        variables: {
          realm
        }
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  pipelineVersonMinoir: (
    realm: string,
    pipelineName: string,
    versionMajor: number,
    environment: string
  ) => {
    try {
      return clientApollo.query({
        query: gql`
          query versionMinor(
            $realm: String!
            $pipelineName: String!
            $versionMajor: Int!
            $environment: String!
          ) {
            versionMinor(
              realm: $realm
              pipelineName: $pipelineName
              versionMajor: $versionMajor
              environment: $environment
            ) {
              id
              name
              versionMajor
              versionMinor
              deploy
              hasAlert
              alert
              disabled
            }
          }
        `,
        variables: {
          realm,
          pipelineName,
          versionMajor,
          environment
        }
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  pipelinesVersionMinor: (variables: PipelinesVersionMinorData) => {
    try {
      const response = clientApollo.query<PipelinesVersionMinorResult>({
        query: gql`
          query pipelinesVersionMinor(
            $realm: String!
            $pipelineId: String!
            $environment: String!
          ) {
            pipelinesVersionMinor(
              realm: $realm
              pipelineId: $pipelineId
              environment: $environment
            ) {
              id
              name
              versionMajor
              versionMinor
              deploy
              hasAlert
              alert
              disabled
            }
          }
        `,
        variables
      });
      return response;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  multiInstance: (variables: MultiInstanceData) => {
    try {
      return clientApollo.query<MultiInstanceResult>({
        query: gql`
          query replicaInstance(
            $realm: String!
            $replicaName: String!
            $environment: String
          ) {
            replicaInstance(
              realm: $realm
              replicaName: $replicaName
              environment: $environment
            ) {
              name
            }
          }
        `,
        variables
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  save: (variables: SaveData) => {
    try {
      return clientApollo.mutate<PipelineSaveResult>({
        mutation: gql`
          mutation updatePipeline(
            $realm: String!
            $trackingId: String!
            $pipeline: PipelineVersionInput!
            $projectId: String
          ) {
            updatePipeline(
              realm: $realm
              pipeline: $pipeline
              trackingId: $trackingId
              projectId: $projectId
            ) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
              parameterizedReplica
              thumbnailName
              canvas {
                nodes {
                  id
                  type
                  data
                }
                edges {
                  id
                  source
                  target
                  data {
                    type
                    conditionType
                    conditionRule
                    condition
                    label
                    description
                    executionId
                  }
                }
              }
            }
          }
        `,
        variables
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  confirm: (variables: ConfirmData) => {
    try {
      return clientApollo.mutate({
        mutation: gql`
          mutation pipelineConfirmAlert($realm: String!, $pipelineId: String!) {
            pipelineConfirmAlert(realm: $realm, pipelineId: $pipelineId)
          }
        `,
        variables
      });
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  async checkDraftDiff(params: CheckDraftDiffData) {
    const { realm, id, currentDraft } = params;
    try {
      const request = await clientApollo.query<CheckDraftDiffResult>({
        query: gql`
          query pipeline($realm: String!, $id: ID!) {
            pipeline(realm: $realm, id: $id) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
            }
          }
        `,
        variables: { id, realm }
      });
      const { pipeline } = request.data;
      return pipeline && pipeline.draft !== currentDraft;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  async isPipelineNameValid(variables: IsPipelineNameValidData) {
    try {
      const request = await clientApollo.query<IsPipelineNameValidResult>({
        query: gql`
          query validatePipelineName($realm: String!, $name: String!) {
            validatePipelineName(realm: $realm, name: $name)
          }
        `,
        variables,
        fetchPolicy: 'network-only'
      });

      return request.data.validatePipelineName;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  async validatePipeline(variables: ValidatePipelineData) {
    try {
      const request = await clientApollo.query<ValidatePipelineResult>({
        query: gql`
          query validatePipeline(
            $realm: String!
            $pipelineId: String!
            $environment: String!
          ) {
            validatePipeline(
              realm: $realm
              pipelineId: $pipelineId
              environment: $environment
            )
          }
        `,
        variables,
        fetchPolicy: 'network-only'
      });

      return request.data.validatePipeline;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  async updateSensitiveFields(variables: UpdateSensitiveFieldsData) {
    try {
      const request = await clientApollo.mutate({
        mutation: gql`
          mutation updatePipelineSensitiveFields(
            $realm: String!
            $pipelineId: String!
            $sensitiveFields: [String]
          ) {
            updatePipelineSensitiveFields(
              realm: $realm
              pipelineId: $pipelineId
              sensitiveFields: $sensitiveFields
            )
          }
        `,
        variables
      });
      return request?.data?.updatePipelineSensitiveFields;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default pipeline;
//@ts-ignore
window.pipelineApi = pipeline;
