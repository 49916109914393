import styled from 'styled-components';

import SideSheetNative from '~/components/SideSheet';

export const SideSheet = styled(SideSheetNative)`
  width: 42%;
`;

export const SeparatorAlert = styled.div`
  padding-bottom: 16px;
`;

export const WrapperCode = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
`;

export const Code = styled.code`
  border-radius: 2.5px;
  padding: 4px 4px;
  font-size: 90%;
  color: ${props => props?.theme?.colors?.gra500};
  background-color: ${props => props.theme.colors.gra100};
  cursor: pointer;
  display: flex;
  justify-content: safe;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TextStyled = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-orient: vertical;
  & > span {
    text-transform: capitalize;
    font-weight: 500;
  }
`;

export const WrapperTrigger = styled.div`
  margin: 16px 0;
`;

export const Content = styled.div`
  padding: 15px;
  word-wrap: break-w;
  width: 95%;
  line-height: 1.3;
`;

export const LinkDoc = styled.a`
  color: ${props => props?.theme?.colors?.gra900};
`;
