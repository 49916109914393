import styled from 'styled-components';

const padding = 16;

export const Box = styled.div`
  padding-bottom: ${props => props.bottom}px;
  padding-top: ${props => props.top}px;
  padding-left: ${props => props.left}px;
  padding-right: ${props => props.right}px;
`;
export const Container = styled.div`
  height: calc(100% - 50px);
  overflow: auto;
`;

export const Content = styled.div`
  padding: ${padding}px;
`;

export const CheckBox = styled.input``;

export const Check = styled.div`
  padding-bottom: 16px;
`;

export const Label = styled.label`
  padding-left: 10px;
  padding-bottom: 16px;
  size: 16px;
`;

export const ContainerPipelineEngine = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionPipelineEngine = styled.div`
  padding-right: 20px;
`;
