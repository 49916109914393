import axios, { AxiosError } from 'axios';
import { gql } from '@apollo/client';

import { API_KEY, URL } from '~/common/helpers/environment';
import { ApiResponse } from '~/common/types/Api';
import { GroupsIntegrations } from '~/entities/GroupsIntegrations';
import clientApollo from './clientApollo';

type SimulationType = {
  id: string;
  email: string;
  createdAt: number;
  expiresIn: number;
  samlGroupMappingId: string;
  status: string;
};

type ApiRequestProps = {
  realm: string;
  token: string;
};

type ApiResult = {
  status: number | undefined;
  data?: SimulationType;
  error?: boolean;
};

type CreateSimulationProps = ApiRequestProps & {
  email: string;
  samlGroupMappingId: string;
  simulationTimeoutInSeconds?: number;
};

type UpdateSimulationProps = ApiRequestProps & {
  simulationId: string;
  simulationStatus: string;
};

type GetSimulationProps = ApiRequestProps & {
  simulationId: string;
};

type GetInactiveIntegrationsProps = ApiRequestProps & {
  page: number;
  size: number;
};

type IntegrationsApiResult = ApiResult & {
  data: GroupsIntegrations[];
  totalResults: number;
};

type ActivateIntegrationsProps = ApiRequestProps & {
  integrationsIds: string[];
};

const groupsIntegration = {
  async createSimulation({
    realm,
    token,
    email,
    samlGroupMappingId,
    simulationTimeoutInSeconds = 300
  }: CreateSimulationProps): Promise<ApiResult> {
    try {
      const { data, status } = await axios.post(
        `${URL}/design/realms/${realm}/group-mapping-simulations`,
        {
          email,
          simulationTimeoutInSeconds,
          samlGroupMappingId
        },
        {
          headers: {
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return {
        status,
        data
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err = error as AxiosError<SimulationType>;
        return {
          status: err.response?.status,
          data: err.response?.data 
        };
      } else {
        throw error;
      }
    }
  },
  async updateSimulation({
    realm,
    token,
    simulationId,
    simulationStatus
  }: UpdateSimulationProps): Promise<ApiResult> {
    try {
      const { status, data } = await axios.put(
        `${URL}/design/realms/${realm}/group-mapping-simulations/${simulationId}`,
        {
          status: simulationStatus
        },
        {
          headers: {
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return {
        status,
        data
      };
    } catch (error) {
      throw error;
    }
  },
  async getSimulation({
    realm,
    token,
    simulationId
  }: GetSimulationProps): Promise<ApiResult> {
    try {
      const { status, data } = await axios.get(
        `${URL}/design/realms/${realm}/group-mapping-simulations/${simulationId}`,
        {
          headers: {
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return {
        status,
        data
      };
    } catch (error) {
      throw error;
    }
  },
  async getInactiveIntegrations({
    realm,
    token,
    page,
    size
  }: GetInactiveIntegrationsProps): Promise<IntegrationsApiResult> {
    try {
      const { status, data } = await axios.get(
        `${URL}/design/realms/${realm}/samlGroupMapping/deactivated?page=${page}&size=${size}`,
        {
          headers: {
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return {
        status,
        data: data.content,
        totalResults: data.totalElements
      };
    } catch (error) {
      throw error;
    }
  },
  async activateIntegrations({
    realm,
    token,
    integrationsIds
  }: ActivateIntegrationsProps): Promise<ApiResult> {
    try {
      const { status } = await axios.patch(
        `${URL}/design/realms/${realm}/samlGroupMapping/activate`,
        integrationsIds,
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return {
        status
      };
    } catch (error) {
      return {
        status: 0,
        error: true
      };
    }
  },
  async getActiveIntegrationsCount(realm: string): Promise<
    ApiResponse<{
      countActiveIntegrations: number;
    }>
  > {
    try {
      const { data } = await clientApollo.query({
        query: gql`
          query countActiveIntegrations($realm: String) {
            countActiveIntegrations(realm: $realm)
          }
        `,
        variables: {
          realm
        }
      });
      return {
        success: true,
        data
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: axios.isAxiosError(error)
          ? error.message
          : 'Unknown Error'
      };
    }
  }
};

export default groupsIntegration;
