const translatedLimits = (key: string) => {
    const data: { [keyof: string]: {name: string, explanation: string, docUrl: string, docLink: string, title?: string, explanation_tooltip?: string } } = {
      MPS: {
        name: 'label.message_per_seconds',
        title: 'label.exec_per_sec_eps_details',
        explanation: 'label.exec_per_sec_eps_explanation',
        explanation_tooltip:'label.eps_explanation_tooltip', 
        docUrl: 'action.learn_more_about_eps_here_url',
        docLink: 'action.learn_more_about_eps_here_link'
      },
      EGRESS_TRAFFIC: {
        name: 'label.egress_traffic',
        explanation: 'label.egress_traffic_explanation',
        explanation_tooltip: 'label.egress_traffic_explanation_tooltip', 
        docUrl: 'action.learn_more_egress_traffic_doc_url',
        docLink: 'action.learn_more_egress_traffic_doc_link'
      },
      VPN: {
        name: 'label.virtual_private_network_vpn',
        title: 'label.vpn_virtual_private_network',
        explanation: 'label.vpn_explanation',
        explanation_tooltip: 'label.vpn_explanation_tooltip', 
        docUrl: 'action.learn_more_vpn_doc_url',
        docLink: 'action.learn_more_vpn_doc_link'
      },
      RELATIONSHIP_MANAGEMENT: {
        name: 'label.relationship_management',
        explanation: 'label.relationship_management_explanation',
        explanation_tooltip: 'label.relation_management_explanation_tooltip',
        docUrl: 'action.learn_more_relationship_management_doc_url',
        docLink: 'action.learn_more_relationship_management_doc_link'
      },
      DIGIBEE_STORE: {
        name: 'label.digibee_storage_component',
        explanation: 'label.digibee_storage_explanation',
        explanation_tooltip: 'label.digibee_storage_explanation_tooltip',
        docUrl: 'action.learn_more_digibee_storage_doc_url',
        docLink: 'action.learn_more_digibee_storage_doc_link'
      },
      OBJECT_STORE: {
        name: 'label.object_store_component',
        explanation: '',
        docUrl: '',
        docLink: ''
      },
      PIPELINE_LOG: {
        name: 'label.pipeline_logs',
        explanation: 'label.pipeline_logs_explanation',
        explanation_tooltip: 'label.pipeline_logs_explanation_tooltip',
        docUrl: 'action.learn_more_pipeline_logs_doc_url',
        docLink: 'action.learn_more_pipeline_logs_doc_link'
      },
      COMPLETED_EXECUTION_LOG: {
        name: 'label.completed_exec_logs',
        title: 'label.completed_executions_logs',
        explanation: 'label.completed_executions_logs_explanation',
        explanation_tooltip: 'label.completed_executions_explanation_tooltip',
        docUrl: 'action.completed_executions_logs_doc_url',
        docLink: 'action.completed_executions_logs_doc_link'
      },
      QUEUE: {
        name: 'label.queue',
        explanation: 'label.queue_resource_quota_explanation',
        explanation_tooltip: 'label.pipe_queue_explanation_tooltip',
        docUrl: 'action.learn_more_queue_doc_url',
        docLink: 'action.learn_more_queue_doc_link'
      }
    };

    return data[key] || null;
};

export default translatedLimits;