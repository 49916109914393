// import { normalize, schema } from 'normalizr';
//
import Immutable from 'seamless-immutable';

// Locals
import * as api from '~/api';

// const ralmsEntity = new schema.Entity('realms');
// const realmsSchema = { realms: [ralmsEntity] };

const initialState = Immutable({
  result: {
    messages: []
  },
  loading: {
    messages: false
  },
  error: {
    messages: null
  }
});

const message = {
  name: 'message',
  state: initialState,
  reducers: {
    setResult(state, { path, value }) {
      return state.merge({
        result: {
          [path]: value
        }
      });
    },
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    reset: () => initialState
  },
  effects: dispatch => ({
    async fetchMessages({ environment = 'test', key, limit }, state) {
      try {
        const { realm } = state.application.realm;
        const { data } = await api.message.fetchMessages({
          environment,
          realm,
          key,
          limit
        });
        dispatch.message.setResult({
          path: 'messages',
          value: data.messagesByKey ? data.messagesByKey : []
        });
      } catch (e) {
        dispatch.message.setError({ path: 'messages', value: e.message });
      }
    }
  }),
  logics: [
    {
      type: 'message/fetchMessages',
      latest: true,
      process(context, dispatch, done) {
        dispatch.message.setLoading({ path: 'messages', value: true });
        done();
      }
    },
    {
      type: ['message/setResult', 'message/setError'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.message.setLoading({ path: 'messages', value: false });
        done();
      }
    }
  ]
};

export default message;
