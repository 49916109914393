import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { PORTAL_URL } from '~/common/helpers/environment';
import parseStringWithVariable from '~/common/helpers/parseStringWithVariable';
import withHooks from '~/common/hoc/withHooks';

const mapStateToProps = ({ application }) => ({
  subdomain: application.subdomain
});

const mapDispatchToProps = () => ({});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHooks(props => {
    useEffect(() => {
      if (props.subdomain)
        window.location.href = parseStringWithVariable(PORTAL_URL, {
          realm: props.subdomain
        });
    }, []);

    const actions = {
      redirectToRealm: realm => {
        window.location.href = parseStringWithVariable(PORTAL_URL, { realm });
      }
    };

    return {
      ...props,
      ...actions
    };
  })
);

export default enhancer;
