/* eslint-disable */

import { gql } from '@apollo/client';
/*
  {
    find: List,
    get: One,
    create: new,
    path: update,
    remove: delete
  }
*/
import clientApollo from './clientApollo';
import { isNumber } from 'lodash';

const runtime = {
  find: (realm, environment, search) => {
    return clientApollo.query({
      query: gql`
        query deployments(
          $realm: String!
          $environment: String!
          $search: JSON
        ) {
          deployments(
            realm: $realm
            environment: $environment
            search: $search
          ) {
            id
            status
            startTime
            availableReplicas
            oomCount
            errorCount
            activeConfiguration
            endpoint
            trigger
            licenses
            lastError
            lastOMM
            pipeline
            metadata
            replicaName
            project
            deploymentStatus
          }
        }
      `,
      variables: {
        realm,
        search,
        environment
      }
    });
  },
  findPaginated: props => {
    return clientApollo.query({
      query: gql`
        query deploymentsPaginated(
          $realm: String!
          $environment: String!
          $pipelineName: String
          $status: [String]
          $page: Int
          $pageSize: Int
          $projectName: String
        ) {
          deploymentsPaginated(
            realm: $realm
            environment: $environment
            pipelineName: $pipelineName
            status: $status
            page: $page
            pageSize: $pageSize
            projectName: $projectName
          ) {
            content {
              id
              status
              startTime
              availableReplicas
              oomCount
              errorCount
              activeConfiguration
              endpoint
              trigger
              licenses
              lastError
              lastOMM
              pipeline
              metadata
              replicaName
              project
              deploymentStatus
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
      variables: {
        ...props
      }
    });
  },
  remove: (realm, deployment, environment) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteDeploy(
          $realm: String!
          $deployment: String!
          $environment: String!
        ) {
          deleteDeploy(
            realm: $realm
            deployment: $deployment
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        deployment,
        environment
      }
    });
  },
  findByNameAndVersionMajor: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deploymentByNameAndVersionMajor(
            $realm: String!
            $environment: String!
            $pipelineName: String!
            $versionMajor: Int!
          ) {
            deploymentByNameAndVersionMajor(
              realm: $realm
              environment: $environment
              pipelineName: $pipelineName
              versionMajor: $versionMajor
            ) {
              id
              status
              startTime
              environment
              availableReplicas
              oomCount
              errorCount
              activeConfiguration
              pipeline
              endpoint
              trigger
              licenses
              replicaName
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  checkLicense: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query checkLicense(
            $realm: String!
            $pipelineId: String!
            $size: String!
            $replicas: Int!
            $replicaInstanceName: String!
            $environment: String!
          ) {
            checkLicense(
              realm: $realm
              pipelineId: $pipelineId
              size: $size
              replicas: $replicas
              replicaInstanceName: $replicaInstanceName
              environment: $environment
            ) {
              hasAnalysis
              analysis
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  create: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation deploy(
            $realm: String!
            $pipelineId: String!
            $configuration: JSON!
            $environment: String!
            $force: Boolean
            $replicas: Int!
            $minReplicas: Int
            $replicaInstanceName: String
            $isPipelineEngine: Boolean
          ) {
            deploy(
              realm: $realm
              pipelineId: $pipelineId
              configuration: $configuration
              environment: $environment
              force: $force
              replicas: $replicas
              minReplicas: $minReplicas
              replicaInstanceName: $replicaInstanceName
              isPipelineEngine: $isPipelineEngine
            )
          }
        `,
        variables: {
          ...params,
          minReplicas: isNumber(params.minReplicas)
            ? params.minReplicas
            : params.replicas
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  redeploy: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation redeploy(
            $realm: String!
            $pipelineId: String!
            $configuration: JSON!
            $environment: String!
            $force: Boolean
            $replicas: Int!
            $replicaInstanceName: String
            $isPipelineEngine: Boolean
          ) {
            redeploy(
              realm: $realm
              pipelineId: $pipelineId
              configuration: $configuration
              environment: $environment
              force: $force
              replicas: $replicas
              replicaInstanceName: $replicaInstanceName
              isPipelineEngine: $isPipelineEngine
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  put: (realm, newRelation, environment) => {},
  findInstance: (realm, name, environment, searchQuery, page) => {},
  getPipelineInDeploy: ({
    realm,
    environment,
    replicaInstanceName,
    pipelineId
  }) => {
    return clientApollo.query({
      query: gql`
        query pipelineInDeploy(
          $realm: String!
          $environment: String!
          $pipeline: String!
          $replicaInstanceName: String
        ) {
          pipelineInDeploy(
            realm: $realm
            environment: $environment
            pipelineId: $pipelineId
            replicaInstanceName: $replicaInstanceName
          )
        }
      `,
      variables: {
        realm,
        environment,
        pipelineId,
        replicaInstanceName
      }
    });
  },
  findByDeployHistoryDistinct: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deployHistoryDistinct(
            $realm: String!
            $projectId: String
            $pipelineName: String
            $status: [String!]
            $page: Int
          ) {
            deployHistoryDistinct(
              realm: $realm
              projectId: $projectId
              pipelineName: $pipelineName
              status: $status
              page: $page
            ) {
              content {
                pipelineId
                projectName
                lastSize
                pipelineName
                pipelineVersionMajor
                pipelineVersionMinor
                deployedAtFirst
                lastStatus
                lastStatusDate
                deployedBy
                deployedByLast
              }
              totalElements
              totalPages
              first
              last
              number
              size
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  findByDeployHistory: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deployHistory(
            $realm: String!
            $pipelineName: String
            $pipelineMajorVersion: String
            $page: Int
          ) {
            deployHistory(
              realm: $realm
              pipelineName: $pipelineName
              pipelineMajorVersion: $pipelineMajorVersion
              page: $page
            ) {
              content {
                id
                deployedAt
                pipeline {
                  name
                  canvasVersion
                  parameterizedReplica
                  versionMinor
                  versionMajor
                  id
                  triggerSpec
                }
                deployedAt
                deployedBy
                environment {
                  name
                }
                project {
                  name
                }
                runtimeConfiguration
                status {
                  date
                  label
                }
              }
              totalElements
              totalPages
              first
              last
              number
              size
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  getLastDeploy: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deployHistory(
            $realm: String!
            $pipelineName: String
            $pipelineMajorVersion: String
            $page: Int
            $size: Int
          ) {
            deployHistory(
              realm: $realm
              pipelineName: $pipelineName
              pipelineMajorVersion: $pipelineMajorVersion
              page: $page
              size: $size
            ) {
              content {
                id
                deployedAt
                pipeline {
                  name
                  parameterizedReplica
                  versionMinor
                  versionMajor
                  id
                  triggerSpec
                }
                deployedAt
                deployedBy
                environment {
                  name
                }
                project {
                  name
                }
                runtimeConfiguration
              }
            }
          }
        `,
        variables: {
          ...params,
          page: 0,
          size: 1
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  getTriggers: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query triggers($realm: String!) {
            triggers(realm: $realm) {
              name
              jsonExample
              configurable
              icon
              iconName
              iconURL
              jsonSchema
              documentationURL
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  pipelineVersions: async variables => {
    try {
      const FETCH_PIPELINE = gql`
        query data($realm: String!, $id: ID!) {
          pipelineOnDeployById(realm: $realm, id: $id) {
            id
            name
            description
            draft
            versionMajor
            versionMinor
            inSpec
            outSpec
            parameterizedReplica
            triggerSpec
            hasAlert
            alert
            thumbnailName
            flowSpec
            realm
          }
          sensitiveFields(realm: $realm, id: $id) {
            logSensitiveFields
          }
        }
      `;

      const FETCH_PIPELINE_VERSIONS = gql`
        query pipelineVersions(
          $realm: String!
          $pipelineName: String!
          $versionMajor: String!
          $environment: String!
        ) {
          pipelineVersions(
            realm: $realm
            pipelineName: $pipelineName
            versionMajor: $versionMajor
            environment: $environment
          ) {
            id
            name
            versionMajor
            versionMinor
            deploy
            hasAlert
            alert
            disabled
            parameterizedReplica
          }
        }
      `;
      const { data } = await clientApollo.query({
        query: FETCH_PIPELINE,
        variables
      });

      const response = await clientApollo.query({
        query: FETCH_PIPELINE_VERSIONS,
        variables: {
          pipelineName: data?.pipelineOnDeployById?.name,
          versionMajor: data?.pipelineOnDeployById?.versionMajor.toString(),
          ...variables
        }
      });

      return {
        id: data?.pipelineOnDeployById?.id,
        name: data?.pipelineOnDeployById?.name,
        description: data?.pipelineOnDeployById?.description,
        licenseTotal: data?.pipelineOnDeployById?.realm?.licenseTotal,
        parameterizedReplica: data?.pipelineOnDeployById?.parameterizedReplica,
        versionMinor: response?.data?.pipelineVersions,
        triggerSpec: data?.pipelineOnDeployById?.triggerSpec
      };
    } catch (e) {
      throw new Error(e);
    }
  },
  rollbackDeploy: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation rollbackDeploy(
            $realm: String!
            $deployment: String!
            $pipelineName: String!
            $environment: String!
            $pipelineMajorVersion: String!
          ) {
            rollbackDeploy(
              realm: $realm
              deployment: $deployment
              pipelineName: $pipelineName
              environment: $environment
              pipelineMajorVersion: $pipelineMajorVersion
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  rollbackDeployDetails: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation rollbackDeployDetails(
            $realm: String!
            $deployment: String!
            $pipelineName: String!
            $environment: String!
            $pipelineMajorVersion: String!
          ) {
            rollbackDeployDetails(
              realm: $realm
              deployment: $deployment
              pipelineName: $pipelineName
              environment: $environment
              pipelineMajorVersion: $pipelineMajorVersion
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPlanList: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deploymentPlanList(
            $realm: String!
            $pageSize: Int
            $page: Int
          ) {
            deploymentPlanList(
              realm: $realm
              pageSize: $pageSize
              page: $page
            ) {
              content {
                id
                name
                createdBy
                description
                currentEnvironments
                createdAt
                deploymentPlanItems {
                  pipelineMajorVersion {
                    name
                    majorVersion
                  }
                  environmentConfiguration {
                    runtimeConfiguration
                    deploymentPlanStatus
                    environment {
                      name
                    }
                    pipeline {
                      id
                      name
                      versionMajor
                      versionMinor
                      canvasVersion
                    }
                  }
                }
              }
              totalElements
              totalPages
              first
              last
              number
              size
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPlanInsert: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation deploymentPlanInsert(
            $realm: String!
            $deploymentPlan: JSON!
          ) {
            deploymentPlanInsert(realm: $realm, deploymentPlan: $deploymentPlan)
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPlanDelete: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation deploymentPlanDelete(
            $realm: String!
            $deploymentPlanId: String!
          ) {
            deploymentPlanDelete(
              realm: $realm
              deploymentPlanId: $deploymentPlanId
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  getVersion: async params => {
    const FETCH_PIPELINE_VERSIONS = gql`
      query pipelineVersions(
        $realm: String!
        $pipelineName: String!
        $versionMajor: String!
        $environment: String!
      ) {
        pipelineVersions(
          realm: $realm
          pipelineName: $pipelineName
          versionMajor: $versionMajor
          environment: $environment
        ) {
          id
          name
          versionMajor
          versionMinor
          deploy
          hasAlert
          alert
          disabled
          parameterizedReplica
        }
      }
    `;
    const response = await clientApollo.query({
      query: FETCH_PIPELINE_VERSIONS,
      variables: {
        pipelineName: params.name,
        versionMajor: params.versionMajor,
        ...params
      }
    });

    return response;
  },
  deploymentPlanExecute: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation deploymentPlanExecute(
            $realm: String!
            $deploymentPlanId: String!
            $pipelineDeploys: JSON
          ) {
            deploymentPlanExecute(
              realm: $realm
              deploymentPlanId: $deploymentPlanId
              pipelineDeploys: $pipelineDeploys
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPlanPromote: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation deploymentPlanPromote(
            $realm: String!
            $deploymentPlanId: String!
            $environment: String!
            $sourceEnvironment: String!
          ) {
            deploymentPlanPromote(
              realm: $realm
              deploymentPlanId: $deploymentPlanId
              environment: $environment
              sourceEnvironment: $sourceEnvironment
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPlanPromoteInfo: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deploymentPlanPromoteInfo(
            $realm: String!
            $deploymentPlanId: String!
            $environment: String!
            $sourceEnvironment: String!
          ) {
            deploymentPlanPromoteInfo(
              realm: $realm
              deploymentPlanId: $deploymentPlanId
              environment: $environment
              sourceEnvironment: $sourceEnvironment
            ) {
              pipelineMajorVersion {
                name
                majorVersion
              }
              environmentConfiguration {
                runtimeConfiguration
                deploymentPlanStatus
                environment {
                  name
                }
                pipeline {
                  id
                  name
                  versionMajor
                  versionMinor
                  canvasVersion
                }
              }
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPlanLicense: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deploymentPlanLicense(
            $realm: String!
            $deploymentPlanId: String!
            $environment: String!
          ) {
            deploymentPlanLicense(
              realm: $realm
              deploymentPlanId: $deploymentPlanId
              environment: $environment
            ) {
              licenseModel
              pipelineSubscriptionAndRtu
              totalRealmLicenses
              totalLicensesInUse
              totalAvailableLicenses
              totalLicensesUsedByPlan
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  revertDeploy: async params => {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation revertDeploy(
            $realm: String!
            $deployHistoryId: String!
            $environment: String!
          ) {
            revertDeploy(
              realm: $realm
              deployHistoryId: $deployHistoryId
              environment: $environment
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  deploymentPipelineEngineConfiguration: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query deploymentPipelineEngineConfiguration(
            $realm: String!
            $environment: String!
          ) {
            deploymentPipelineEngineConfiguration(
              realm: $realm
              environment: $environment
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default runtime;
