import { isEmpty, uniqBy, omit, head, pickBy, identity } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const normalizeData = ({ entity, data }) => {
  const pipelinesSchema = new schema.Entity(entity);
  const mySchema = [pipelinesSchema];
  return normalize(data, mySchema);
};
const onUniqId = (allIds, entities, alias) => {
  const content = entities?.content?.filter(
    entity => !allIds?.includes(entity[alias])
  );
  return {
    ...entities,
    content
  };
};

const removeId = (allIds, id) => {
  if (!allIds && !id) {
    return [];
  }

  return allIds.filter(item => item.id !== id);
};

const initialState = Immutable({
  result: {
    components: {
      content: []
    },
    capsules: {
      content: []
    },
    collectionHeader: {
      content: []
    },
    fetchCollectionGroup: {
      result: [],
      entities: {
        groups: {}
      },
      last: false,
      totalElements: 0,
      totalPages: 0,
      number: 0,
      first: true,
      numberOfElements: 0,
      size: 0
    }
  },
  fetchCapsules: {
    result: [],
    entities: {
      capsules: {}
    },
    last: false,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    first: true,
    numberOfElements: 0,
    size: 0
  },
  loading: {
    components: false,
    capsules: true,
    pushComponents: false,
    collectionHeader: true,
    deleteCollectionHeader: false
  },
  searchParams: null,
  modal: {
    collectionEdit: {
      isOpen: false,
      entity: null
    },
    collectionHeader: {
      isOpen: false,
      entity: null
    }
  },
  error: {
    components: null,
    capsules: null,
    deployCapsuleComponent: null,
    archiveComponent: null,
    collectionHeader: null
  },
  success: {
    archiveComponent: null,
    deployCapsuleComponent: null
  },
  current: {
    capsules: ''
  }
});

const capsulesModel = {
  name: 'capsules',
  state: initialState,
  reducers: {
    setResult(state, { path, value }) {
      return state.merge(
        {
          result: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setLoading(state, { path, value }) {
      return state.merge(
        {
          loading: {
            [path]: value
          }
        },
        {
          deep: true
        }
      );
    },
    setSearch(state, value) {
      return state.merge(
        {
          searchParams: value
        },
        { deep: true }
      );
    },
    setArchive(state, content) {
      return state.merge(
        {
          result: {
            capsules: {
              content: [...content]
            }
          }
        },
        { deep: true }
      );
    },
    resetSearch(state) {
      return state.merge({
        searchParams: null
      });
    },
    resetCollectionGroup(state) {
      return state.merge(
        {
          result: {
            fetchCollectionGroup: initialState.result.fetchCollectionGroup
          }
        },
        { deep: true }
      );
    },
    resetfetchGroupCapsule(state) {
      return state.merge({
        fetchGroupSearch: initialState.fetchGroupSearch,
        searchParams: null
      });
    },
    addCollection(state, payload) {
      return state.merge(
        {
          result: {
            capsules: {
              content: [payload, ...state.result.capsules.content]
            }
          }
        },
        { deep: true }
      );
    },
    editInGroup(state, payload) {
      return state.setIn(
        [
          'result',
          'fetchCollectionGroup',
          'entities',
          'groups',
          payload.id,
          'title'
        ],
        payload.title
      );
    },
    editCollection(state, payload) {
      const newCollections = state?.result?.capsules?.content?.map(
        collection => {
          if (payload.id === collection.id) {
            return {
              ...payload,
              amountOfCapsules: collection.amountOfCapsules
            };
          }
          return collection;
        }
      );

      return state.merge(
        {
          result: {
            capsules: {
              content: newCollections
            }
          }
        },
        { deep: true }
      );
    },
    addNormalizeGroup(state, payload) {
      return state.merge(
        {
          result: {
            fetchCollectionGroup: {
              entities: {
                groups: {
                  ...payload?.entities?.fetchCollectionGroup
                }
              },
              result: [
                payload.result,
                ...state?.result?.fetchCollectionGroup.result
              ]
            }
          }
        },
        { deep: true }
      );
    },
    resetCapsules(state) {
      return state.merge(
        {
          fetchCapsules: {
            result: [],
            entities: {
              capsules: {}
            },
            loading: false,
            totalElements: 0,
            last: true,
            totalPages: 0,
            number: 0,
            first: false,
            numberOfElements: 0,
            size: 0,
            groupId: ''
          }
        },
        { deep: true }
      );
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    setModal(state, { path, value }) {
      return state.merge(
        {
          modal: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setCapsules(state, capsules) {
      return state.merge(
        {
          fetchCapsules: {
            ...capsules
          }
        },
        { deep: true }
      );
    },
    setLoadingGroup(state, { value, groupId }) {
      return state.merge(
        {
          result: {
            fetchCollectionGroup: {
              entities: {
                groups: {
                  [groupId]: {
                    capsules: {
                      loading: value
                    }
                  }
                }
              }
            }
          }
        },
        { deep: true }
      );
    },
    setSuccess(state, { path, value }) {
      return state.merge(
        {
          success: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setGroupCapsules(state, { groupId, capsules }) {
      return state.merge(
        {
          result: {
            fetchCollectionGroup: {
              entities: {
                groups: {
                  [groupId]: {
                    capsules: {
                      ...capsules,
                      loading: false
                    }
                  }
                }
              }
            }
          }
        },
        { deep: true }
      );
    },
    resetGroupCapsules(state, { groupId }) {
      return state.merge(
        {
          result: {
            fetchCollectionGroup: {
              entities: {
                groups: {
                  [groupId]: {
                    capsules: {
                      resetLoading: true,
                      content: [],
                      loading: false,
                      totalElements: 1,
                      last: false,
                      totalPages: 0,
                      number: 0,
                      first: false,
                      numberOfElements: 0,
                      size: 0,
                      groupId: ''
                    }
                  }
                }
              }
            }
          }
        },
        { deep: true }
      );
    },
    loadingGroup(state, payload) {
      return state.merge(
        {
          result: {
            fetchCollectionGroup: {
              entities: {
                groups: {
                  [payload.groupId]: {
                    capsules: {
                      resetLoading: payload.value
                    }
                  }
                }
              }
            }
          }
        },
        { deep: true }
      );
    },
    setCurrent(state, { path, value }) {
      return state.merge(
        {
          current: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    clear() {
      return initialState;
    }
  },
  effects: dispatch => ({
    async fetchCapsules(params, state) {
      try {
        const { realm } = state.application.realm;
        const { data, errors } = await api.capsules.fetchCapsules({ realm });
        if (errors?.length) {
          throw new Error(errors);
        }
        dispatch.capsules.setCurrent({
          path: 'capsules',
          value: head(data.capsulesPaginations.content)?.id
        });
        dispatch.capsules.setResult({
          path: 'capsules',
          value: data.capsulesPaginations
        });
      } catch (e) {
        dispatch.capsules.setError({ path: 'capsules', value: e.message });
      }
    },
    async fetchGroupSearch(search, state) {
      try {
        const { realm } = state.application.realm;
        const { capsules } = state.capsules.current;
        const { data } = await api.capsules.fetchGroupCapsules({
          collectionId: capsules,
          realm,
          search: pickBy(search, identity),
          size: 20
        });
        const groupBycapsules = onUniqId(
          state.capsules.fetchCapsules.result,
          data?.groupCapsules,
          'id'
        );
        const capsulesNormalize = normalizeData({
          entity: 'capsules',
          data: groupBycapsules.content
        });

        const content = {
          result: [
            ...state?.capsules?.asMutable({ deep: true })?.fetchCapsules
              ?.result,
            ...capsulesNormalize.result
          ],
          entities: {
            capsules: {
              ...state.capsules.asMutable({ deep: true }).fetchCapsules
                ?.entities.capsules,
              ...capsulesNormalize?.entities?.capsules
            }
          },
          ...omit(data?.groupCapsules, ['content'])
        };

        dispatch.capsules.setCapsules(content);
      } catch (e) {
        dispatch.capsules.setError({
          path: 'fetchGroupCapsules',
          value: e
        });
      }
    },
    async fetchGroupCapsules({ groupId, page }, state) {
      try {
        const { realm } = state.application.realm;
        const { capsules } = state.capsules.current;
        const { data, errors } = await api.capsules.fetchGroupCapsules({
          collectionId: capsules,
          realm,
          search: {
            page,
            capsuleGroupId: groupId
          },
          size: 3
        });

        const groupBycapsules = onUniqId(
          state?.capsules?.result?.fetchCollectionGroup?.entities?.groups[
            groupId
          ].capsules.content.map(e => e.id),
          data?.groupCapsules,
          'id'
        );
        dispatch.capsules.setGroupCapsules({
          groupId,
          value: false
        });
        const capsulesPaginations = {
          ...omit(data?.groupCapsules, ['content']),
          content: [
            ...state?.capsules?.result?.fetchCollectionGroup?.entities?.groups[
              groupId
            ].capsules.content,
            ...groupBycapsules?.content
          ]
        };

        dispatch.capsules.setGroupCapsules({
          groupId,
          capsules: capsulesPaginations
        });

        if (errors?.length) {
          throw new Error(errors);
        }
      } catch (e) {
        dispatch.capsules.setError({
          path: 'fetchGroupCapsules',
          value: e
        });
      }
    },
    async fetchCollectionGroup({ page = 0, collectionId }, state) {
      const { realm } = state.application.realm;
      try {
        const response = await api.capsules.fetchCollectionGroupPagination({
          realm,
          collectionId,
          search: { page }
        });
        const collectionGroups = onUniqId(
          state?.capsules?.result?.fetchCollectionGroup?.result,
          response?.collectionGroupsPagination
        );
        const collectionGroupsNormalize = normalizeData({
          entity: 'groups',
          data: collectionGroups?.content?.map(e => ({
            ...e,
            capsules: {
              ...e?.capsules,
              loading: false
            }
          }))
        });
        const data = {
          result: [
            ...state?.capsules?.asMutable({ deep: true })?.result
              ?.fetchCollectionGroup?.result,
            ...collectionGroupsNormalize.result
          ],
          entities: {
            groups: {
              ...state.capsules.asMutable({ deep: true }).result
                ?.fetchCollectionGroup?.entities.groups,
              ...collectionGroupsNormalize?.entities?.groups
            }
          },
          ...omit(response?.collectionGroupsPagination, ['content'])
        };
        dispatch.capsules.setResult({
          path: 'fetchCollectionGroup',
          value: data || []
        });
      } catch (e) {
        dispatch.capsules.setError({
          path: 'fetchCollectionGroup',
          value: e.message
        });
      }
    },
    async fetchCollectionHeader(params, state) {
      try {
        const { realm } = state.application.realm;
        const data = await api.capsules.fetchCollectionHeader({ realm });
        dispatch.capsules.setResult({
          path: 'collectionHeader',
          value: { content: data?.length ? data : [] }
        });
      } catch (e) {
        dispatch.capsules.setError({
          path: 'collectionHeader',
          value: e.message
        });
      }
    },
    async fetchComponents(params, state) {
      try {
        const capsuleId = params.id;
        const { realm } = state.application.realm;
        const { data } = await api.capsules.fetchComponents({
          capsuleId,
          realm
        });
        dispatch.capsules.setResult({
          path: 'components',
          value: data.componentsByCapsule
        });
      } catch (e) {
        dispatch.capsules.setError({ path: 'components', value: e.message });
      }
    },
    async deleteCollectionHeader({ collectionHeaderId }, state) {
      try {
        const { realm } = state.application.realm;
        const response = await api.capsules.deleteCollectionHeader({
          realm,
          collectionHeaderId
        });
        dispatch.capsules.setSuccess({
          path: 'deleteCollectionHeader',
          value: response
        });
        dispatch.snackbar.create({
          text: i18n.t('noun.header_archived_msg')
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: normalizeError.onGraphQL(e.message)
        });
        dispatch.capsules.setError({
          path: 'deleteCollectionHeader',
          value: e.message
        });
      }
    },
    async archiveCollection(params, state) {
      try {
        const { realm } = state.application.realm;
        await api.capsules.archiveCollection({
          realm,
          ...params
        });
        const newContent = removeId(
          state.capsules.result.capsules.content,
          params?.collectionId
        );
        dispatch.capsules.setArchive(newContent, {
          snackbar: {
            text: i18n.t('scenes.capsules.messages.success.arquive_component'),
            success: true,
            open: true
          }
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: normalizeError.onGraphQL(e.message)
        });
        dispatch.capsules.setError({
          path: 'archiveComponent',
          value: e.message
        });
      }
    },
    async pushComponents(params, state) {
      const requestParams = {
        capsuleId: params.id,
        ...params
      };
      const { realm } = state.application.realm;
      const { data } = await api.capsules.fetchComponents({
        realm,
        ...requestParams
      });
      const content = uniqBy(
        [
          ...state.capsules.result.components.content,
          ...data.componentsByCapsule.content
        ],
        item => item.id
      );

      dispatch.capsules.setResult({
        path: 'components',
        value: {
          ...data.componentsByCapsule,
          content
        }
      });
    },
    async archiveComponent({ idComponent, groupId }, state) {
      try {
        const { capsules: idCapsule } = state.capsules.current;
        const { realm } = state.application.realm;
        dispatch.capsules.loadingGroup({
          groupId,
          value: true
        });
        const result = await api.capsules.archiveComponent({
          idComponent,
          idCapsule,
          realm
        });

        dispatch.capsules.setSuccess(
          {
            path: 'archiveComponent',
            value: result
          },
          {
            groupId,
            snackbar: {
              text: i18n.t(
                'scenes.capsules.messages.success.arquive_component'
              ),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.capsules.setError(
          {
            path: 'archiveComponent',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              success: true,
              open: true
            }
          }
        );
      }
    },
    async editGroup(params) {
      try {
        dispatch.capsules.editInGroup(params, {
          snackbar: {
            text: `${i18n.t('scenes.capsules.messages.success.group_updated')}`,
            success: true,
            open: true
          }
        });
      } catch (e) {
        dispatch.capsules.setError(
          {
            path: 'editGroup',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              success: true,
              open: true
            }
          }
        );
      }
    },
    async archiveGroup(payload, state) {
      try {
        const { realm } = state.application.realm;
        const { capsules: collectionId } = state.capsules.current;
        const response = await api.capsules.archiveGroupCollection({
          groupId: payload,
          realm
        });
        dispatch.capsules.resetCollectionGroup();
        dispatch.capsules.fetchCollectionGroup({ collectionId });
        dispatch.capsules.setSuccess(
          {
            path: 'archiveGroup',
            value: response.data
          },
          {
            snackbar: {
              text: `${i18n.t('scenes.capsules.messages.success.archive')}`,
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.capsules.setError(
          {
            path: 'archiveGroup',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              success: true,
              open: true
            }
          }
        );
      }
    },
    async unarchiveCapsule({ capsuleId }, state) {
      const { capsules: capsuleCollectionId } = state.capsules.current;
      const { realm } = state.application.realm;
      try {
        await api.capsules.unarchiveCapsule({
          realm,
          capsuleId,
          capsuleCollectionId
        });

        dispatch.capsules.setSuccess(
          {
            path: 'unarchiveCapsule',
            value: {}
          },
          {
            snackbar: {
              text: `${i18n.t('label.unarchive_capsule_success_msg')}`,
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.capsules.setError(
          {
            path: 'unarchiveCapsule',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              success: true,
              open: true
            }
          }
        );
      }
    },
    async deployCapsuleComponent({ idComponent, groupId }, state) {
      try {
        const { capsules: idCapsule } = state.capsules.current;
        const { realm } = state.application.realm;
        const result = await api.capsules.deployCapsuleComponent({
          idComponent,
          idCapsule,
          realm
        });
        const { deployCapsuleComponent } = result.data;
        dispatch.capsule.updatePipeline(deployCapsuleComponent);

        dispatch.capsules.setSuccess(
          {
            path: 'deployCapsuleComponent',
            value: result
          },
          {
            groupId,
            snackbar: {
              text: i18n.t(
                'scenes.capsules.messages.success.deployed_successfully'
              ),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.capsules.setError(
          {
            path: 'deployCapsuleComponent',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              success: true,
              open: true
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: 'capsules/fetchCapsules',
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setLoading({ path: 'fetchCapsules', value: true });
        done();
      }
    },
    {
      type: 'capsules/pushComponents',
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setLoading({ path: 'pushComponents', value: true });
        done();
      }
    },
    {
      type: ['capsules/setResult'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setError({
          path: context.action.payload.path,
          value: null
        });
        dispatch.capsules.setLoading({ path: 'pushComponents', value: false });
        dispatch.capsules.setLoading({
          path: 'collectionHeader',
          value: false
        });
        done();
      }
    },
    {
      type: ['capsules/setResult', 'capsules/setError'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setLoading({
          path: context.action.payload.path,
          value: false
        });
        dispatch.capsules.setLoading({ path: 'pushComponents', value: false });
        dispatch.capsules.setLoading({ path: 'fetchCapsules', value: false });
        done();
      }
    },
    {
      type: ['capsules/fetchComponents'],
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.capsules.setCurrent({ path: 'capsules', value: payload.id });
        dispatch.capsules.setLoading({ path: 'components', value: true });
        done();
      }
    },
    {
      type: ['capsules/setSuccess'],
      latest: true,
      process({ action, getState }, dispatch, done) {
        const { meta } = action;
        const { searchParams } = getState().capsules;

        dispatch.capsules.resetGroupCapsules({
          groupId: meta?.groupId
        });

        dispatch.capsules.fetchCollectionHeader();
        dispatch.capsules.resetCapsules();
        if (!meta?.groupId) {
          dispatch.capsules.fetchGroupSearch({
            ...searchParams,
            page: 0
          });
        }
        if (meta.groupId) {
          dispatch.capsules.fetchGroupCapsules({
            groupId: meta?.groupId,
            page: 0
          });
        }
        done();
      }
    },
    {
      type: ['capsules/fetchGroupCapsules'],
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        dispatch.capsules.setLoadingGroup({
          groupId: payload.groupId,
          value: true
        });
        dispatch.capsules.loadingGroup({
          groupId: payload.groupId,
          value: false
        });
        done();
      }
    },
    {
      type: ['capsules/fetchCollectionHeader'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setLoading({
          path: 'collectionHeader',
          value: true
        });
        done();
      }
    },
    {
      type: ['capsules/deleteCollectionHeader'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setLoading({
          path: 'deleteCollectionHeader',
          value: true
        });
        done();
      }
    },
    {
      type: ['capsules/fetchGroupSearch'],
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        dispatch.capsules.setLoading({
          path: 'fetchGroupSearch',
          value: true
        });
        const search = isEmpty(pickBy(payload, identity)) ? null : payload;
        dispatch.capsules.setSearch(search);
        done();
      }
    },
    {
      type: ['capsules/setCapsules'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsules.setLoading({
          path: 'fetchGroupSearch',
          value: false
        });
        done();
      }
    },
    {
      type: ['capsules/setArchive'],
      latest: true,
      process({ getState }, dispatch, done) {
        const { capsules } = getState().capsules.result;
        const capsule = head(capsules.content);
        dispatch.capsules.fetchCollectionGroup({ collectionId: capsule?.id });
        dispatch.capsules.setCurrent({
          path: 'capsules',
          value: capsule?.id
        });
        done();
      }
    }
  ]
};

export default capsulesModel;
