import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Text from '../Text';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Ilustration = styled.img`
  height: 50%;
  width: 30%;
  margin: 32px 0;
`;

const Title = styled(Text)`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: ${p => p.theme.colors.gra900};
  padding-bottom: 24px;
  max-width: 600px;
`;

const SubTitle = styled(Text)`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: ${p => p.theme.colors.gra600};
  padding-bottom: 32px;
  max-width: 600px;
`;

const CallToAction = styled(Text)`
  margin-top: 70px;
  & a {
    color: ${p => p.theme.colors.pri500};
    font-weight: 500;
    cursor: pointer;
  }
`;

const Empty = ({
  ilustration,
  buttonText,
  buttonClick,
  title,
  subTitle,
  callToAction,
  buttonLoading,
  buttonIcon,
  buttonEmphasis,
  buttonType,
  ...rest
}) => (
  <Container data-testid={rest['data-testid']}>
    <Content>
      {ilustration && <Ilustration src={ilustration} />}
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {buttonText && (
        <Button
          type={buttonType}
          emphasis={buttonEmphasis}
          iconName={buttonIcon}
          loading={buttonLoading}
          onClick={buttonClick}
        >
          {buttonText}
        </Button>
      )}
      {callToAction && <CallToAction>{callToAction}</CallToAction>}
    </Content>
  </Container>
);

Empty.defaultProps = {
  buttonLoading: false,
  buttonIcon: 'Plus',
  buttonEmphasis: 'contained',
  buttonType: 'primary',
  'data-testid': 'empty'
};

Empty.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  ilustration: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
  callToAction: PropTypes.node.isRequired,
  buttonLoading: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonEmphasis: PropTypes.string,
  buttonType: PropTypes.string,
  'data-testid': PropTypes.string
};

export default Empty;
