import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  loading: StoreRootState['authentication']['loading'],
  error: StoreRootState['authentication']['error'],
  subdomain: StoreRootState['application']['subdomain'],
  realmParams: StoreRootState['authentication']['realmParams'],

  signin: StoreDispatcher['authentication']['signin'],
}

const enhancer = withHooks((outProps: object) => {
  const { authentication, application } = useSelector((state: StoreRootState) => state);
  const dispatch = useDispatch<StoreDispatcher>();

  const props: Props = {
    error: authentication.error,
    loading: authentication.loading,
    subdomain: application.subdomain,
    realmParams: authentication.realmParams,

    signin: dispatch.authentication.signin,
  }

  useEffect(() => {
    if (props?.subdomain) {
      dispatch.authentication.getNotLoggedPlatformParameter({ realm: props?.subdomain, param: 'hasCustomerIdentityProvider' });
      dispatch.authentication.getNotLoggedPlatformParameter({ realm: props?.subdomain, param: 'disabledForgotPassword' });
    }
  }, [props?.subdomain]);

  return {
    ...outProps,
    ...props,
  };
});

export default enhancer;
