import {
  Box,
  Icon,
  IconButton,
  Select,
  SideSheet,
  Table,
  Tag,
  Text
} from '@digibee/beehive-ui';
import React, { useState } from 'react';
import styled from 'styled-components';

import { EnvironmentConfiguration } from '../../types';

import i18n from '~/common/helpers/i18n';

interface TableDeploymentPlanProps {
  deploymentPlanItems: EnvironmentConfiguration[];
  changeMinorSize: Function;
  loading: boolean;
  env: string;
}

export const Footer = styled.div`
  margin: 34px 0px;
`;

const TablePipelineDeploy: React.FC<TableDeploymentPlanProps> = ({
  deploymentPlanItems,
  loading,
  changeMinorSize,
  env
}) => {
  const [openTableId, setOpenTableId] = useState('');

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.Row>
            <Table.Cell>{i18n.t('label.pipeline_name_vers_two')}</Table.Cell>
            <Table.Cell>{i18n.t('label.major_version')}</Table.Cell>
            <Table.Cell>{i18n.t('label.environment')}</Table.Cell>
            <Table.Cell>{i18n.t('label.pipeline_size')}</Table.Cell>
            <Table.Cell>{i18n.t('label.replicas')}</Table.Cell>
            <Table.Cell>{i18n.t('label.concurrent_executions')}</Table.Cell>
          </Table.Row>
        </Table.Head>
        {loading ? (
          <Table.Loading rows={5} cols={6} />
        ) : (
          <Table.Body>
            {deploymentPlanItems?.map(
              (planItem: EnvironmentConfiguration, index: number) => (
                <>
                  <Table.Row key={planItem.pipeline.name.toString()}>
                    <Table.Cell>
                      <Text
                        as='p'
                        css={{
                          _truncate: '$12'
                        }}
                      >
                        {env !== 'prod' && (
                          <IconButton
                            onClick={() => {
                              if (planItem.pipeline.name === openTableId) {
                                setOpenTableId('');
                                return;
                              }

                              setOpenTableId(planItem.pipeline.name.toString());
                            }}
                            type='button'
                            size='medium'
                          >
                            <Icon
                              icon={`angle-${
                                openTableId === planItem.pipeline.name
                                  ? 'up'
                                  : 'down'
                              }`}
                              size='md'
                              variant='primary'
                            />
                          </IconButton>
                        )}

                        {planItem.pipeline.name}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      V{planItem.pipeline.versionMajor}.
                      {planItem.pipeline.versionMinor}
                    </Table.Cell>
                    <Table.Cell>
                      <Tag
                        // @ts-ignore
                        css={{ marginRight: '$3' }}
                        variant={env === 'prod' ? 'primary' : 'info'}
                      >
                        {env}
                      </Tag>{' '}
                    </Table.Cell>
                    <Table.Cell>
                      {planItem.runtimeConfiguration.size}
                    </Table.Cell>
                    <Table.Cell>
                      {planItem?.runtimeConfiguration?.replicaCount}
                    </Table.Cell>
                    <Table.Cell>
                      {planItem?.runtimeConfiguration?.actualConsumers}
                    </Table.Cell>
                  </Table.Row>
                  {openTableId === planItem.pipeline.name && (
                    // <Table.Row>
                    <td
                      size='lg'
                      // @ts-ignore
                      colSpan='6'
                    >
                      <SideSheet.Section
                        title={i18n.t(
                          'label.env_name_as_variable_environment',
                          {
                            environmentName: env
                          }
                        )}
                        subTitle={i18n.t(
                          'label.configure_pipe_environm_variable_explanation',
                          {
                            environmentName: env
                          }
                        )}
                      >
                        <Box>
                          <Box
                            css={{
                              display: 'flex',

                              alignItems: 'center'
                            }}
                          >
                            <Box css={{ width: '14rem' }}>
                              <Select
                                label={i18n.t('label.minor_version')}
                                value={
                                  planItem?.runtimeConfiguration
                                    ?.minorVersionsId
                                }
                                options={
                                  planItem?.runtimeConfiguration?.minorVersions
                                }
                                // @ts-ignore
                                onChange={e => {
                                  changeMinorSize(planItem.pipeline.id, e);
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </SideSheet.Section>
                    </td>
                  )}
                </>
              )
            )}
          </Table.Body>
        )}
      </Table.Root>
    </>
  );
};

export default TablePipelineDeploy;
