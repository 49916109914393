import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  errorFirstAccess: StoreRootState['authentication']['errorFirstAccess'],
  loading: StoreRootState['authentication']['loading'],
  signinFirstAccess: StoreDispatcher['authentication']['signinFirstAccess'],
  successFirstAccess: StoreRootState['authentication']['successFirstAccess'],
  returnToSignIn: () => void
}

const enhancer = withHooks((outProps: object) => {
  const { authentication } = useSelector((state: StoreRootState) => state);
  const dispatch = useDispatch<StoreDispatcher>();

  const props: Props = {
    successFirstAccess: authentication.successFirstAccess,
    errorFirstAccess: authentication.errorFirstAccess,
    loading: authentication.loading,
    signinFirstAccess: dispatch.authentication.signinFirstAccess,
    returnToSignIn: () => {
      dispatch.router.navigate({ to: '/login' });
    }
  }


  useEffect(() => () => {
    dispatch.authentication.successFirstAccess(false);
}, []);

  return {
    ...outProps,
    ...props,
  }
});

export default enhancer;
