import { Icon, Text } from '@digibee/beehive-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  height: 32px;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.gra300};
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;
const ContentBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
`;

ContentBack.defaultProps = {
  'data-testid': 'build-topbar-back-to-pipelines-link'
};

const IconWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
`;
const ContentTitle = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
  align-items: center;
  width: 100%;
`;

const TopBar = ({ title, linkText, onBack, afterTitle }) => {
  const onClick = event => {
    const isTargetBlank = event.metaKey || event.ctrlKey;
    onBack(isTargetBlank);
  };

  return (
    <Container>
      <ContentBack onBack={() => {}} onClick={onClick}>
        <IconWrapper>
          <Icon
            data-testid='build-topbar-back-to-pipelines-link'
            icon='arrow-circle-left'
            variant='primary'
          />
        </IconWrapper>
        <Text variant='b2' css={{ color: '$pri500', fontWeight: '$medium' }}>
          {linkText}
        </Text>
      </ContentBack>
      <ContentTitle>
        <Text variant='b2' css={{ fontWeight: 500 }}>
          {title}
        </Text>
        {afterTitle && afterTitle}
      </ContentTitle>
    </Container>
  );
};

TopBar.defaultProps = {
  afterTitle: null
};

TopBar.propTypes = {
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  afterTitle: PropTypes.node
};

export default TopBar;
