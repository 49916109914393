import { Input } from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import { useFormik } from 'formik';
import { FormEvent } from 'react';
import { useParams } from 'react-router';
// @ts-ignore
import * as yup from 'yup';

import * as Elements from './FormResetPassword.elements';
import { Props } from './FormResetPassword.enhancer';
import { getTranslateMessageArray } from '../../helpers/fusionAuthMessageTranslation';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';

const ResetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('scenes.user.messages.error.invalid_email')
    .required('scenes.login.messages.error.required_field'),
  realm: yup.string().required('scenes.login.messages.error.required_field'),
  newPassword: yup
    .string()
    .min(8, 'scenes.new_password.labels.new_password_assist')
    // Uppercase
    .matches(/[A-Z]+/, 'scenes.new_password.labels.new_password_assist')
    // Special character
    .matches(/[@$!%*#?&]+/, 'scenes.new_password.labels.new_password_assist')
    // Number
    .matches(/\d+/, 'scenes.new_password.labels.new_password_assist')
    .required('scenes.login.messages.error.required_field'),
  newPasswordConfirm: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'scenes.login.messages.error.password_not_equal'
    )
    .required('scenes.login.messages.error.required_field')
});

const FormResetPassword = ({
  loading,
  onResetPassword,
  error,
  returnToSignIn,
  returnToForgotPassword,
  resetPassword,
  isInvalidLink,
  subdomain
}: Props) => {
  const params = useParams();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: '',
      realm: subdomain || '',
      newPassword: '',
      newPasswordConfirm: ''
    },
    onSubmit: (formValues) =>
      onResetPassword({
        ...formValues,
        changePasswordId: params.changePasswordId
      }),
    validationSchema: ResetPasswordSchema,
  });

  const newPasswordErrorMessage = () => {
    if (getFieldError(touched, errors, 'newPassword')) {
      return i18n.t(
        'scenes.login.labels.upper_and_capitalize.capitalized',
        {
          item: i18n.t(
            getFieldError(touched, errors, 'newPassword')
          )
        }
      );
    }
    if (error?.messages?.password) {
      return getTranslateMessageArray(error?.messages?.password as { code: string; message: string; }[]);
    }
    return '';
  }

  return choose(
    when(isInvalidLink, () => (
      <Elements.StyledContainer>
        <Elements.StyledMessage data-testid='governance-error-message-forgot-password-page'>
          {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
            item: `${i18n.t(
              'scenes.login.messages.error.reset_password_invalid'
            )}`
          })}
        </Elements.StyledMessage>
        <Elements.ButtonWrapper>
          <Elements.StyledButton data-testid='governance-error-message-return-to-sing-in-forgot-password-page' variant='primary' onClick={returnToForgotPassword}>
            {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
              item: `${i18n.t('scenes.login.actions.new_link')}`
            })}
          </Elements.StyledButton>
        </Elements.ButtonWrapper>
      </Elements.StyledContainer>
    )),
    when(!!resetPassword, () => (
      <Elements.StyledContainer>
        <Elements.StyledMessage data-testid='governance-success-message-forgot-password-page'>
          {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
            item: `${i18n.t('scenes.login.messages.success.new_password_set')}`
          })}
        </Elements.StyledMessage>
        <Elements.ButtonWrapper>
          <Elements.StyledButton data-testid='governance-success-message-return-to-sing-in-forgot-password-page' variant='primary' onClick={returnToSignIn}>
            {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
              item: `${i18n.t('scenes.login.actions.return_sign_in')}`
            })}
          </Elements.StyledButton>
        </Elements.ButtonWrapper>
      </Elements.StyledContainer>
    )),
    otherwise(() => (
      <Elements.FormContainer
        onSubmit={ev => {
          ev.preventDefault();
        }}
      >
        <Elements.StyledMessage>
          {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
            item: `${i18n.t('noun.change_password')}`
          })}
        </Elements.StyledMessage>
        {!subdomain && (
          <Elements.StyledFields>
            <Input
              data-testid='governance-realm-input-forgot-password-page'
              className='fs-mask'
              name='realm'
              value={values.realm}
              onChange={handleChange}
              invalid={Boolean(
                getFieldError(touched, errors, 'realm')
                  ? i18n.t(getFieldError(touched, errors, 'realm'))
                  : error.unauthorized
              )}
              helperText={i18n.t(
                'scenes.login.labels.upper_and_capitalize.capitalized',
                {
                  item: getFieldError(touched, errors, 'realm')
                    ? i18n.t(getFieldError(touched, errors, 'realm'))
                    : error.unauthorized
                }
              )}
              label={i18n.t(
                'scenes.login.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('scenes.login.labels.realm')}`
                }
              )}
              placeholder={i18n.t(
                'scenes.login.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('scenes.login.labels.realm')}`
                }
              )}
            />
          </Elements.StyledFields>
        )}
        <Elements.StyledFields>
          <Input
            data-testid='governance-email-input-forgot-password-page'
            className='fs-mask'
            name='email'
            value={values.email}
            onChange={handleChange}
            autoComplete='new-password'
            invalid={Boolean(
              getFieldError(touched, errors, 'email')
                ? i18n.t(getFieldError(touched, errors, 'email'))
                : error.unauthorized
            )}
            helperText={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: getFieldError(touched, errors, 'email')
                  ? i18n.t(getFieldError(touched, errors, 'email'))
                  : error.unauthorized
              }
            )}
            label={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('scenes.login.labels.email')}`
              }
            )}
            placeholder={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('scenes.login.labels.email')}`
              }
            )}
          />
        </Elements.StyledFields>
        <Elements.StyledFields>
          <Input
            data-testid='governance-password-input-forgot-password-page'
            className='fs-exclude'
            name='newPassword'
            type='password'
            autoComplete='new-password'
            value={values.newPassword}
            onChange={handleChange}
            invalid={Boolean(
              getFieldError(touched, errors, 'newPassword')
                ? i18n.t(getFieldError(touched, errors, 'newPassword'))
                : !!error.password
            )}
            helperText={newPasswordErrorMessage()}
            label={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('scenes.login.labels.new_password')}`
              }
            )}
            placeholder={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('scenes.login.labels.new_password')}`
              }
            )}
          />
        </Elements.StyledFields>
        <Elements.StyledFields>
          <Input
            data-testid='governance-confirm-password-input-forgot-password-page'
            className='fs-exclude'
            name='newPasswordConfirm'
            type='password'
            autoComplete='new-password'
            value={values.newPasswordConfirm}
            invalid={Boolean(getFieldError(touched, errors, 'newPasswordConfirm'))}
            helperText={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t(
                  getFieldError(touched, errors, 'newPasswordConfirm')
                )}`
              }
            )}
            onChange={handleChange}
            label={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t(
                  'scenes.login.actions.confirm_new_password'
                )}`
              }
            )}
            placeholder={i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t(
                  'scenes.login.actions.confirm_new_password'
                )}`
              }
            )}
          />
        </Elements.StyledFields>
        <Elements.ButtonWrapper>
          <Elements.StyledButton
            data-testid='governance-confirm-button-forgot-password-page'
            key='buttonFirstAccess'
            variant='primary'
            onClick={(event) => handleSubmit(event as unknown as FormEvent<HTMLFormElement>)}
            loading={loading}
          >
            {i18n.t(
              'scenes.login.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('scenes.login.actions.confirm')}`
              }
            )}
          </Elements.StyledButton>
        </Elements.ButtonWrapper>
      </Elements.FormContainer>
    ))
  )
};

export default FormResetPassword;
