/* eslint-disable @typescript-eslint/no-empty-function */
import { IconButton, Tag } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMachine, useSelector } from '@xstate/react';
import { useMemo } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import * as Elements from './GlobalSidePanel.elements';
import globalSidePanelMachine from './GlobalSidePanel.machine';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

export type GlobalSidePanelConfig = {
  name: string;
  icon?: JSX.Element;
  title: string;
  component: JSX.Element;
  actionsSlot?: JSX.Element;
  isBeta?: boolean;
};

type GlobalSidePanelContextValue = {
  open: (panelName: string) => void;
  toggle: (panelName: string) => void;
  close: () => void;
};

const GlobalSidePanelContext = createContext<GlobalSidePanelContextValue>({
  open: () => {},
  close: () => {},
  toggle: () => {}
});

export const useGlobalSidePanel = () =>
  useContextSelector(GlobalSidePanelContext, actions => actions);

type GlobalSidePanelProps = {
  children: JSX.Element;
  panels: GlobalSidePanelConfig[];
};

function GlobalSidePanel({ panels, children }: GlobalSidePanelProps) {
  const { AI_CHAT_ASSISTANT } = featureFlagConstants;

  const { treatments } = useFeatureFlag([AI_CHAT_ASSISTANT]);

  const isAIChatAssistantEnabled =
    treatments[AI_CHAT_ASSISTANT].treatment === 'on';

  const panelsNames = panels.map(panel => panel.name);
  const [state, send, actor] = useMachine(
    globalSidePanelMachine({ panelsNames })
  );

  const currentPanel = useSelector(actor, ({ context }) =>
    context.currentPanel
      ? panels.find(panel => panel.name === state.context.currentPanel)
      : null
  );

  const isPanelOpen = state.matches('open');

  const contextValue: GlobalSidePanelContextValue = useMemo(
    () => ({
      open: panelName => send({ type: 'OPEN', panelName }),
      toggle: panelName => send({ type: 'TOGGLE', panelName }),
      close: () => send({ type: 'CLOSE' })
    }),
    [send]
  );

  if (!isAIChatAssistantEnabled) {
    return children;
  }

  return (
    <GlobalSidePanelContext.Provider value={contextValue}>
      <Elements.GlobalPanelContainer>
        <Elements.GlobalPanelWrapper open={isPanelOpen}>
          {children}
        </Elements.GlobalPanelWrapper>
        {iff(isPanelOpen, () => (
          <Elements.GlobalPanelContentWrapper>
            <Elements.PanelHeader>
              <Elements.PanelTitle>
                {currentPanel?.title}
                {iff(!!currentPanel?.isBeta, () => (
                  <Tag variant='primary'>Beta</Tag>
                ))}
              </Elements.PanelTitle>
              <Elements.PanelActions>
                {currentPanel?.actionsSlot}
              </Elements.PanelActions>
              <Elements.PanelCloseAction>
                <IconButton
                  data-testid='global-panel-close-button'
                  onClick={() => send({ type: 'CLOSE' })}
                  css={{
                    width: 32,
                    height: 32
                  }}
                >
                  <FontAwesomeIcon size='lg' icon={faClose} />
                </IconButton>
              </Elements.PanelCloseAction>
            </Elements.PanelHeader>
            <Elements.GlobalPanelContent>
              {currentPanel?.component}
            </Elements.GlobalPanelContent>
          </Elements.GlobalPanelContentWrapper>
        ))}
      </Elements.GlobalPanelContainer>
    </GlobalSidePanelContext.Provider>
  );
}

export default GlobalSidePanel;
