// import { inspect } from '@xstate/inspect';
import { render } from 'react-dom';
import 'reset-css/reset.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from '~/scenes/Others/scenes/App';

import '~/common/helpers/i18n';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  // eslint-disable-next-line consistent-return
  return worker.start();
}

// if (process.env.NODE_ENV !== 'production') {
//   inspect({
//     // options,
//     url: 'https://stately.ai/viz?inspect', // (default)
//     iframe: false // open in new window
//   });
// }

enableMocking().then(() => {
  render(<App />, document.getElementById('root'));
});
