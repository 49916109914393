/* global window */
import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const controllers = [];

const convertJson = stringJson => {
  if (!stringJson) return {};
  return JSON.parse(stringJson);
};

const convertString = json => {
  if (!json) return '';
  return JSON.stringify(json, null, '  ');
};

const testCapsule = {
  execute: async params => {
    const controller = new window.AbortController();

    controllers.push(controller);

    try {
      const responseExecTest = await clientApollo.mutate({
        mutation: gql`
          mutation testCapsule(
            $realm: String!
            $canvas: CanvasInput!
            $payload: String!
            $trackingId: String!
            $inSpec: JSON
            $outSpec: JSON
            $params: JSON
            $accountLabels: JSON
          ) {
            testCapsule(
              realm: $realm
              canvas: $canvas
              payload: $payload
              trackingId: $trackingId
              params: $params
              inSpec: $inSpec
              outSpec: $outSpec
              accountLabels: $accountLabels
            )
          }
        `,
        variables: {
          ...params
        },
        context: { fetchOptions: { signal: controller.signal } }
      });
      const responseTestCapsule = convertJson(
        responseExecTest.data.testCapsule
      );

      if ([500, 409, 405].includes(responseTestCapsule.status)) {
        if (typeof responseTestCapsule.message === 'string')
          throw new Error(responseTestCapsule.message);
        throw new Error('Problem sending to test');
      }

      return {
        digibeeKey: responseTestCapsule['X-Digibee-Key'],
        data: responseTestCapsule.data
      };
    } catch (e) {
      throw e;
    }
  },
  cancel: () => {
    controllers.map(controller => controller.abort());
  }
};
export default testCapsule;
