import i18n from '~/common/helpers/i18n';

export const getTranslateMessageArray = (errors: { code: string; message: string }[]): string =>
  errors
    .map((error) => {
      if (error.code === '[previouslyUsed]password') {
        const passwordCount = error?.message?.match(/\d+/)?.[0] || '';
        return i18n.t('scenes.login.messages.error.already_used_password', {
          passwordCount,
        })
      }

      return null;
    })
    .filter(Boolean)
    .join(', ');
