import styled, { css } from 'styled-components';

import NativeCard from '~/components/Card';
import NativeIcon from '~/components/IconCommunity';
import TextNative from '~/components/Text';

/* eslint-disable  import/prefer-default-export */
export const Box = styled.div`
  padding-bottom: ${({ bottom }) => bottom}px;
  padding-top: ${({ top }) => top}px;
  padding-left: ${({ left }) => left}px;
  padding-right: ${({ right }) => right}px;
`;

export const Details = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.gra400}
  box-sizing: border-box;
`;

export const Text = styled(TextNative)`
  ${({ type, theme }) =>
    type === 'secondary' &&
    css`
      color: ${theme.colors.gra600};
    `}
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gra700};
`;

export const LinkIcon = styled(NativeIcon)`
  margin-left: 1.5px;
  height: 9px;
  width: 9px;
`;

export const Card = styled(NativeCard)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gra400};
  padding: 16px 12px 16px 12px;
  margin-top: 16px;
`;

export const CardContent = styled.div`
  padding-bottom: ${({ bottom }) => bottom}px;
  padding-top: ${({ top }) => top}px;
  padding-left: ${({ left }) => left}px;
  padding-right: ${({ right }) => right}px;
  display: grid;
  column-gap: 5px;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-items: start;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme, total }) =>
    total ? theme?.colors?.gra50 : theme?.colors?.white};
`;

export const Operator = styled.div`
  background-color: ${({ theme }) => theme.colors.gra50};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
