import { Text } from '@digibee/beehive-ui';
import React from 'react';

import * as Elements from './Details.elements';

type DetailsProps = {
  name: string;
  title: string;
  disabled?: boolean;
  result: string;
};

const DetailsV2: React.FC<DetailsProps> = ({
  name,
  result,
  disabled = false,
  title,
  ...props
}) => (
  <Elements.DetailsContainer title={title} {...props}>
    <Text>{name}</Text>
    <Text variant='b1' css={{ fontWeight: 'bold', color: '$pri500' }}>
      {result}
    </Text>
  </Elements.DetailsContainer>
);

export default DetailsV2;
