import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';

import checkConditions from './checkConditions';

export const getTypeDefault = type => {
  const defaults = {
    string: '',
    array: [],
    int: 0,
    number: 0,
    float: 0,
    boolean: false,
    object: {}
  };

  // eslint-disable-next-line no-prototype-builtins
  return defaults.hasOwnProperty(type) ? defaults[type] : '';
};

const getFieldDefault = (inputSchema, env = {}) => {
  // eslint-disable-next-line no-underscore-dangle
  const _default =
    inputSchema.default !== undefined
      ? inputSchema.default
      : getTypeDefault(inputSchema.type);

  if (inputSchema.overrides && inputSchema.overrides.length) {
    // eslint-disable-next-line no-shadow
    return inputSchema.overrides.reduce((_default, currentOverride) => {
      const currentDefault = currentOverride.schema.default;
      if (!currentDefault) return _default;
      const shouldOverride = checkConditions({
        conditions: currentOverride.conditions,
        values: {},
        env
      });
      return shouldOverride ? currentDefault : _default;
    }, _default);
  }
  return _default;
};

const getInitialValues = (formSchema, env, value) => {
  const result = formSchema.reduce((accum, currSection) => {
    const values = currSection.schema.reduce(
      // eslint-disable-next-line no-shadow
      (accum, currField) =>
        set(
          accum,
          currField.property,
          get(value, currField.property) ?? getFieldDefault(currField, env)
        ),
      {}
    );

    return merge(accum, values);
  }, {});

  return result;
};

export default getInitialValues;
