import React from 'react';

export default function useId(idOverride) {
  const [defaultId, setDefaultId] = React.useState(idOverride);

  const id = idOverride || defaultId;

  React.useEffect(() => {
    if (defaultId == null) {
      setDefaultId(`mui-${Math.round(Math.random() * 1e9)}`);
    }
  }, [defaultId]);

  return id;
}
