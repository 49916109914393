/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

type Global = {
  label: string;
  package: string;
  type: string;
  value: string;
};

type GlobalsResponse = {
  intellisenseCompletions: Global[];
};

const intellisense = {
  fetch: async (realm: string) => {
    const { data } = await clientApollo.query<GlobalsResponse>({
      query: gql`
        query intellisenseCompletions($realm: String!) {
          intellisenseCompletions(realm: $realm) {
            type
            label
            value
            package
          }
        }
      `,
      variables: { realm: realm }
    });

    return data.intellisenseCompletions;
  }
};

export default intellisense;
