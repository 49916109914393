export enum NotificationChannelTypes {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Telegram = 'TELEGRAM',
  Webhook = 'WEBHOOK'
}

export type SlackParams = {
  slackUrl: string;
};

export type WebhookParams = {
  url: string;
};

export type TelegramParams = {
  chatId: string;
};

export type EmailParams = {
  emailTo: string[];
  emailSubject: string;
  emailContent: string;
};

export type EmailNotificationChannel = {
  notificationChannelType: NotificationChannelTypes.Email;
  notificationChannelParams: EmailParams;
};

export type SlackNotificationChannel = {
  notificationChannelType: NotificationChannelTypes.Slack;
  notificationChannelParams: SlackParams;
};

export type TelegramNotificationChannel = {
  notificationChannelType: NotificationChannelTypes.Telegram;
  notificationChannelParams: TelegramParams;
};

export type WebhookNotificationChannel = {
  notificationChannelType: NotificationChannelTypes.Webhook;
  notificationChannelParams: WebhookParams;
};

export type NotificationChannels =
  | EmailNotificationChannel
  | TelegramNotificationChannel
  | WebhookNotificationChannel
  | SlackNotificationChannel;

type NotificationConfig = {
  notificationChannels: NotificationChannels[];
};

export type AlertParams = {
  paramName: string;
  paramVersion: number;
  paramType: string;
};

type AlertConfig = {
  alertParams: AlertParams[];
  alertMetricName: string;
  alertMetricThresholdValue: string;
  alertMetricThresholdOperator: string;
  alertIntervalCheck: string;
};

export type MonitoringAlert = {
  id: string;
  realm: string;
  environment: string;
  alertName: string;
  alertStatus: boolean;
  alertConfig: AlertConfig;
  notificationConfig: NotificationConfig;
};
