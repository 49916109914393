import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import styled, { keyframes } from 'styled-components';

import IconNative from '~/components/IconCommunity';
import ListNative from '~/components/List';
import Menu from '~/components/Menu';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const HelpMenu = styled(Menu)`
  width: 230px;
  height: auto;
  z-index: 1002;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Item = styled(ListNative.Item)`
  padding: 0 16px;
  display: flex;
`;

export const List = styled(ListNative)`
  width: 169px;
  width: 100%;
  border-radius: 10px;
`;

export const IconCustom = styled(IconNative)`
  font-size: 16px;
  margin-right: 8px;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-right: 14px;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

// eslint-disable-next-line react/jsx-filename-extension
export const Icon = styled(props => <FontAwesomeIcon {...props} />)`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.pri500 : theme.colors.gra700};
  font-size: 1rem;
  margin: 0 2px;
`;

export const Puser = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
`;

export const Notification = styled.div`
  display: inline-block;
  background-color: ${props => lighten(0.2, props.theme.colors.red500)};
  border-radius: 50%;
  color: ${props => props.theme.white};
  padding: 4px;
  position: absolute;
  margin: 0px;
  top: 6px;
  right: 8px;
  z-index: 10;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: ${Puser} 1s ease infinite;
    border-radius: 50%;
    border: 2px double ${props => lighten(0.2, props.theme.colors.red500)};
  }
`;
