const commands = [
  {
    text: 'noun.keyboard_shortcuts',
    data: [
      ['ctrl', ';'],
      ['command', ';']
    ]
  },
  {
    text: 'noun.connector_list',
    data: [
      ['ctrl', 'P'],
      ['command', 'P']
    ]
  },
  {
    text: 'noun.test_mode',
    data: [
      ['ctrl', 'D'],
      ['command', 'D']
    ]
  },
  {
    text: 'verb.delete',
    data: [
      ['ctrl', 'delete'],
      ['command', 'backspace'],
      ['shift', 'delete'],
      ['shift', 'backspace']
    ]
  },
  {
    text: 'noun.paste',
    data: [
      ['ctrl', 'V'],
      ['command', 'V']
    ]
  },
  {
    text: 'noun.copy',
    data: [
      ['ctrl', 'C'],
      ['command', 'C']
    ]
  },
  {
    text: 'noun.exec_test_mode',
    data: [
      ['ctrl', 'enter'],
      ['command', 'enter']
    ]
  },
  {
    text: 'noun.modal_panel',
    data: [['esc'], []]
  },
  {
    text: 'noun.organize',
    data: [
      ['ctrl', 'O'],
      ['command', 'O']
    ]
  },
  {
    text: 'button.save',
    data: [
      ['ctrl', 'S'],
      ['command', 'S']
    ]
  },
  {
    text: 'noun.direct_selection',
    data: [['shift', 'Click'], []]
  },
  {
    text: 'noun.select_all',
    data: [
      ['ctrl', 'A'],
      ['command', 'A']
    ]
  }
];

export default commands;
