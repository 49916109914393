import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Icon from '~/components/IconCommunity';

const Wrapper = styled.div`
  border: none;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: ${props => props?.theme?.colors?.gra400};
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  min-height: 52px;
  padding: 0px 16px 0px 0px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  transition-delay: 0s;
  position: relative;
  &:focus-within {
    ${({ theme }) => {
      const { colors } = theme;
      return `
        border-bottom: 1px solid ${colors.pri500}65;
        transition-delay: 300ms;

        ::placeholder {
          color: ${colors.pri500}85;
        }
      `;
    }}
  }
`;

const Input = styled.input`
  font-family: ${p => p.theme.typography.fontFamily};
  flex-grow: 1;
  border: none;
  outline: none;
  height: 52px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0px 0px 0px 12px;
  background: transparent;

  :focus {
    color: ${props => props?.theme?.colors?.gra600};
    caret-color: ${props => props?.theme?.colors?.gra600};
  }

  ::-webkit-search-cancel-button {
    display: none;
  }
  ::-ms-clear {
    display: none;
  }
  ::-webkit-search-results-button {
    display: none;
  }

  ::-webkit-input-placeholder {
    color: ${props => props?.theme?.colors?.gra400};
  }
  ::-moz-placeholder {
    color: ${props => props?.theme?.colors?.gra400};
  }
  ::-ms-input-placeholder {
    color: ${props => props?.theme?.colors?.gra400};
  }

  ::placeholder {
    color: ${props => props?.theme?.colors?.gra400};
  }
`;

const ClearIcon = styled(Icon)`
  cursor: pointer;
  color: ${props => props?.theme?.colors?.gra400};
  font-size: 16px;
  margin-left: 16px;
  :hover {
    color: ${props => props?.theme?.colors?.gra600};
  }
`;

const InputSearch = React.forwardRef((props, ref) => {
  const { value: initialValue, placeholder, clearIcon, onChange, type } = props;

  const [value, setValue] = useState(initialValue);

  const onChangeHandler = event => {
    setValue(event.target.value);
    onChange(event, value);
  };

  const onClear = () => {
    onChangeHandler({ target: { value: '' } });
    ref.current.focus();
  };

  return (
    <Wrapper>
      <Input
        innerRef={ref}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
      />
      {clearIcon && value && <ClearIcon name={clearIcon} onClick={onClear} />}
    </Wrapper>
  );
});

InputSearch.defaultProps = {
  value: '',
  type: 'search',
  onChange: () => {},
  placeholder: '',
  clearIcon: 'Times'
};

InputSearch.propTypes = {
  type: PropTypes.oneOf(['text', 'search']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  clearIcon: PropTypes.string
};

export default InputSearch;
