import { Box, Button, Flex, Icon, SearchBar } from '@digibee/beehive-ui';
import { faTable } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Search from '~/components/Search';

type View = 'card' | 'table';

interface HeaderProps {
  find?: () => void;
  realm?: string;
  environment?: string;
  setSearch?: (searchTerm: string) => void;
  search: Function;
  status: string[];
  selectedTags: string[];
  onViewButtonClick?: (view: View) => void;
}

const Header: React.FC<HeaderProps> = ({
  search,
  selectedTags,
  onViewButtonClick
}) => {
  const [searchParams] = useSearchParams();
  const [activeView, setActiveView] = React.useState(
    searchParams.get('activeView') ||
      localStorage.getItem('activeView') ||
      'card'
  );
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const tags = [
    {
      label: i18n.t('scenes.runtime.labels.deployed'),
      value: 'SERVICE_ACTIVE'
    },
    {
      label: i18n.t('scenes.runtime.labels.status_error'),
      value: 'SERVICE_ERROR'
    },
    {
      label: i18n.t('scenes.runtime.labels.redeploy'),
      value: 'REDEPLOY'
    },
    {
      label: i18n.t('scenes.runtime.labels.starting'),
      value: 'STARTING'
    }
  ];

  const statusTag = [searchParams.get('status') ?? ''];

  const handleViewButtonClick = (view: View) => {
    setActiveView(view);

    onViewButtonClick?.(view);
  };

  return (
    <Flex
      css={{
        width: '100%',
        height: '66px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        css={{
          flex: 1
        }}
      />
      <Box
        css={{
          width: '50vw',
          '@media only screen and (max-width: 761px)': {
            width: '100%',
            padding: '0 15px'
          }
        }}
      >
        {showDesignSystemComponents ? (
          <SearchBar
            data-testid='run-input-search'
            placeholder={i18n.t('common.actions.search')}
            placeholderTags={i18n.t('label.all')}
            textValue={searchParams.get('pipelineName') || ''}
            onSearch={(e, tags) => search(e, tags)}
            // @ts-ignore
            tagsSource={tags}
            tagsValue={statusTag}
          />
        ) : (
          <Search
            placeholder={i18n.t('scenes.runtime.actions.search')}
            data-testid='run-input-search'
            onSearch={(e: any, tags: any) => search(e, tags)}
            tagsSource={tags}
            zIndexValue='auto'
            tagsValue={selectedTags}
            onTextChange={() => {}}
            onTagsChange={() => {}}
            onChange={() => {}}
            textValue={undefined}
          />
        )}
      </Box>
      <Box
        css={{
          flex: 1
        }}
      >
        <Flex
          css={{
            width: 'fit-content',
            position: 'relative',
            backgroundColor: '$primary',
            marginInlineStart: 'auto',
            '& > button': {
              padding: '$2',
              background: 'transparent',
              color: '$pri600',
              width: '36px',
              height: '36px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 0,
              transition: 'all 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335)',

              '&:hover': {
                backgroundColor: '$pri50'
              },
              '&:focus': {
                backgroundColor: '$pri50'
              },
              '&:active': {
                backgroundColor: '$pri50'
              },
              '&:focus-visible': {
                outline: '1px solid blue'
              }
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '50%',
              height: '100%',
              backgroundColor: '$pri600',
              zIndex: -1,
              transition: 'all 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335)'
            },

            '& > button[data-active="true"]': {
              color: '$white',
              '&:hover': {
                backgroundColor: 'initial'
              },
              '&:focus': {
                backgroundColor: 'initial'
              },
              '&:active': {
                backgroundColor: 'initial'
              }
            },

            '&[data-active="card"]': {
              '&::before': {
                borderRadius: '4px 0 0 4px'
              },

              '& > button': {
                '&:hover': {
                  borderRadius: '0 4px 4px 0'
                }
              }
            },

            '&[data-active="table"]': {
              '& > button': {
                '&:hover': {
                  borderRadius: '4px 0 0 4px'
                }
              },

              '&::before': {
                borderRadius: '0 4px 4px 0',
                transform: 'translateX(100%)'
              }
            }
          }}
          data-active={activeView}
        >
          <Button
            data-testid='run-card-view-button'
            data-active={activeView === 'card'}
            onClick={() => handleViewButtonClick('card')}
          >
            <Icon icon='grip-vertical' />
          </Button>
          <Button
            data-testid='run-table-view-button'
            data-active={activeView === 'table'}
            onClick={() => handleViewButtonClick('table')}
          >
            <FontAwesomeIcon icon={faTable} />
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
