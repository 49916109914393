/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
// Locals
import { Chip } from '../../Chip';

export const MultiValue = function ({ children, ...props }) {
  const { Container } = props.components;
  return (
    <Container {...props}>
      <Chip
        title={children}
        isClose
        onRemove={() => props?.removeProps?.onClick()}
        {...props.selectProps.chipProps}
      />
    </Container>
  );
};
