import { Text } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { shallowEqual, useSelector } from '@xstate/react';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

type TriggerNodeProps = {
  node: TreeNode;
};

const TriggerNode = ({ node }: TriggerNodeProps) => {
  const actor = useTreeContext(state => state.actor);
  const actionsSlot = useTreeContext(state => state.actionsSlot);
  const mapConnectorImage = useTreeContext(state => state.mapConnectorImage);
  const onItemDoubleClick = useTreeContext(state => state.onItemDoubleClick);

  const actionsSlotRendered = actionsSlot?.({
    item: node.item
  });

  const { shape, image } = useSelector(
    actor,
    state =>
      mapConnectorImage(state.context.triggerSpec?.name || 'default-trigger'),
    shallowEqual
  );

  return (
    <Elements.Container data-testid='flow-tree-node-container'>
      <Elements.Item
        onDoubleClick={() => onItemDoubleClick(node.item)}
        depthLeft={node.depth}
        data-testid='build-flow-tree-node-item'
      >
        <Elements.Shape2 shape={shape} image={image} />
        <Elements.Label>
          <Text variant='b3' css={{ fontWeight: 400 }}>
            {node.label}
          </Text>
        </Elements.Label>

        {iff(!!actionsSlotRendered, () => (
          <Elements.FloatAction>{actionsSlotRendered}</Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default TriggerNode;
