/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  faCalendar,
  faGears,
  faLayerGroup,
  faUserLarge
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { memo } from 'react';

import { EventCardRuntime } from './EventCardRuntime';
import {
  GenericCardRuntime,
  GenericCardRuntimeProps
} from './GenericCardRuntime';
import { SchedulerCardRuntime } from './SchedulerCardRuntime';

import i18n from '~/common/helpers/i18n';

const CardRuntime = (props: GenericCardRuntimeProps) => {
  switch (props.deployment.pipeline.triggerSpec?.type) {
    case 'event':
      return <EventCardRuntime {...props} />;
    case 'scheduler':
      return <SchedulerCardRuntime {...props} />;
    default:
      return (
        <GenericCardRuntime
          {...props}
          infos={[
            [
              {
                icon: faCalendar,
                value: moment(props.deployment.startTime, 'x').format(
                  'DD/MM/YYYY HH:mm:ss'
                )
              },
              {
                icon: faGears,
                title: i18n.t('label.engine_version'),
                value: props.disabledMenu
                  ? null
                  : props.deployment.deploymentStatus?.pipelineEngineVersion?.replace(
                      /^([^.]*\.[^.]*\.[^.]*).*/,
                      '$1'
                    )
              }
            ],
            [
              {
                icon: faUserLarge,
                value: props.deployment.metadata?.userName
              },
              {
                icon: faLayerGroup,
                title: i18n.t('common.labels.multi_instance'),
                value: props.deployment.pipeline?.parameterizedReplica
                  ? 'Multi-instance'
                  : null
              }
            ]
          ]}
        />
      );
  }
};

export default memo(CardRuntime);
