import { omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import { accounts } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

// Locals

const normalizeData = ({ entity, data, id = 'id' }) => {
  const Entity = new schema.Entity(entity, {}, { idAttribute: id });
  const Schema = { [entity]: [Entity] };
  return normalize(data, Schema);
};

const initialState = Immutable({
  result: {
    accounts: []
  },
  accounts: [],
  entities: {
    accounts: {}
  },
  loading: {
    accounts: false
  },
  error: null,
  status: [],
  search: '',
  searchText: null,
  searchTags: null,
  environment: '',
  isAccountAssociate: false,
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    account: {}
  }
});

const accountsModel = {
  name: 'accounts',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      });
    },
    setEntity(state, { result, entities }) {
      return state.merge({
        ...state,
        result: {
          ...state.result,
          accounts: result?.accounts
        },
        entities: {
          ...state.entities,
          accounts: entities?.accounts
        }
      });
    },
    setError: (state, error) => state.merge({ error }),
    setSearchText: (state, searchText) => state.merge({ searchText }),
    setSearchTags: (state, searchTags) => state.merge({ searchTags }),
    setModal(state, payload) {
      return state.merge(
        {
          modal: {
            ...state.modal,
            ...payload
          }
        },
        { deep: true }
      );
    },
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setIsAccountAssociate(state, data) {
      return state.merge({
        ...state,
        isAccountAssociate: data
      });
    },
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const result = await accounts.find(
          Object.assign(params, {
            realm: state.application.realm.realm
          })
        );
        const data = normalizeData({
          entity: 'accounts',
          data: result.data
        });
        dispatch.accounts.setEntity(data);
      } catch (e) {
        dispatch.accounts.setError(e.message);
      }
    },
    changeV2(value = false) {
      dispatch.accounts.setIsAccountAssociate(value)
    },
    async remove(params, state) {
      try {
        const remove = await accounts.remove({
          realm: state.application.realm.realm,
          id: params.id
        });
        dispatch.accounts.setActionModal(
          {
            success: remove
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t(
                    'label.delete_account_msg_success',
                    {
                      accountName: params.label
                    }
                  )}`
                }
              )
            }
          }
        );
      } catch (e) {
        dispatch.accounts.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async save({ id, values }, state) {
      const accountData = { ...values, fields: JSON.stringify(values.env) };
      dispatch.accounts.setModal({ loading: true });
      try {
        const response = await accounts.create({
          realm: state.application.realm.realm,
          account: omit(accountData, ['env', 'providerType', 'id']),
          accountId: id
        });

        dispatch.accounts.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t(
                    id
                      ? 'label.edit_account_msg_success'
                      : 'label.create_account_msg_success',
                    {
                      accountName: accountData.label
                    }
                  )}`
                }
              )
            }
          }
        );
      } catch (error) {
        const { message } = error.graphQLErrors[0];

        dispatch.accounts.setActionModal(
          { error },
          {
            snackbar: {
              text:
                message === 'Error'
                  ? `${i18n.t(
                    'scenes.accounts.messages.error.new_item'
                  )} ${i18n.t('scenes.accounts.labels.account')}`
                  : message
            }
          }
        );
      }
    },
  }),
  logics: [
    {
      type: ['accounts/find'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.accounts.setLoading({ path: 'accounts', value: true });
        done();
      }
    },
    {
      type: ['accounts/save'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.accounts.setModalLoading(true);
        done();
      }
    },
    {
      type: ['accounts/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { search, isAccountAssociate } = context.getState().accounts;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.accounts.setModal({
            visible: false,
            loading: false,
            success: true,
            account: null
          });
          dispatch.accounts.setLoading({ path: 'accounts', value: false });
          if (isAccountAssociate) {
            return
          }
          dispatch.accounts.find({ search });
        } else {
          dispatch.accounts.setModal({ loading: false, success: false });
          dispatch.accounts.setLoading({ path: 'accounts', value: false });
        }
        done();
      }
    },
    {
      type: ['accounts/setError', 'accounts/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.accounts.setLoading({ path: 'accounts', value: false });
        done();
      }
    }
  ]
};

export default accountsModel;
