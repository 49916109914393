import { Box, Button, Dialog, Skeleton, Text } from '@digibee/beehive-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DeploymentPlanContext } from '../../../context/DeploymentPlanContext';

import apiRuntime from '~/api/runtime';
import i18n from '~/common/helpers/i18n';

type Props = {
  onClose: () => void;
  onDeploy: () => void;
  env: string;
};

type LicenseData = {
  licenseModel: string;
  totalAvailableLicenses: number;
  totalLicensesUsedByPlan: number;
  pipelineSubscriptionAndRtu: any;
};
const DialogLicense: React.FC<Props> = ({ onClose, onDeploy, env }) => {
  const { planSelected } = useContext(DeploymentPlanContext);
  const { application } = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true);
  const [licenseData, setLicenseData] = useState<LicenseData>();
  const envLowerCase = env.charAt(0).toUpperCase() + env.slice(1);

  const pipelineSubscriptionAndRtu = licenseData?.pipelineSubscriptionAndRtu;

  const dataRTU =
    pipelineSubscriptionAndRtu?.[`pipelineSubscription${envLowerCase}`];

  async function getLicense() {
    const { data } = await apiRuntime.deploymentPlanLicense({
      realm: application?.activeRealm,
      environment: planSelected.env,
      deploymentPlanId: planSelected.id
    });

    setLicenseData(data.deploymentPlanLicense);
    setLoading(false);
  }

  useEffect(() => {
    getLicense();
  }, [planSelected]);

  return (
    <Dialog.Root open onOpenChange={onClose}>
      <React.Fragment key='.0'>
        {/* @ts-ignore */}
        <Dialog.Content css={{ padding: '$3', overflow: 'none' }}>
          <Dialog.Header>
            <Text as='h1' variant='h4'>
              {i18n.t('label.deploy_deployment_plan_prompt', {
                planName: planSelected.name
              })}
            </Text>
          </Dialog.Header>
          {loading ? (
            <Skeleton animation='pulse' sizes='small' variant='text' />
          ) : (
            <>
              <Text variant='b2'>
                {i18n.t('label.promote_deployment_plan_msg')}
              </Text>
              <Box css={{ marginTop: '$3' }}>
                <Text variant='h4'>
                  {i18n.t('label.env_name_as_variable_environment', {
                    environmentName: env
                  })}
                </Text>
              </Box>
              <Box css={{ marginTop: '$3' }}>
                <Text>
                  {i18n.t(
                    env === 'prod'
                      ? 'label.prod_pipeline_subscription'
                      : 'label.test_pipeline_subscription'
                  )}
                </Text>
              </Box>
              <Box css={{ marginTop: '$3' }}>
                {licenseData?.licenseModel === 'PIPELINE' ? (
                  <>
                    <Box css={{ marginBottom: '$3' }}>
                      <Text variant='b3'>
                        {i18n.t(
                          'scenes.runtime.labels.total_realm_licenses_alert'
                        )}
                      </Text>
                      <Text variant='b3'>
                        {licenseData?.totalAvailableLicenses}
                      </Text>
                    </Box>
                    <Box css={{ marginBottom: '$3' }}>
                      <Text variant='b3'>
                        {i18n.t('scenes.runtime.labels.deploy_licenses_alert')}
                      </Text>
                      <Text variant='b3'>
                        {licenseData?.totalLicensesUsedByPlan}
                      </Text>
                    </Box>
                    <Box
                      css={{
                        width: '100%',
                        height: '1px',
                        background: '$neu400'
                      }}
                    />
                    <Box css={{ marginTop: '$3' }}>
                      <Text variant='b3'>
                        {i18n.t(
                          'scenes.runtime.labels.available_licenses_deploy'
                        )}
                      </Text>
                      <Text variant='b3'>
                        {licenseData?.totalAvailableLicenses ||
                          0 -
                            // @ts-ignore
                            licenseData?.totalLicensesUsedByPlan}
                      </Text>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2'
                        }}
                      >
                        {
                          dataRTU[
                            `totalAmountOfPipelineSubscriptionAllowed${envLowerCase}`
                          ]
                        }
                        <Text variant='b3'>{i18n.t('label.licensed')}</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          borderRadius: '$round',
                          width: '24px',
                          height: '24px',
                          justifyContent: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <Text variant='b3'>-</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center'
                        }}
                      >
                        {pipelineSubscriptionAndRtu.totalRtuUsedByPlan}
                        <Text variant='b3'>{i18n.t('label.in_use')}</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          borderRadius: '$round',
                          width: '24px',
                          height: '24px',
                          justifyContent: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <Text variant='b3'>-</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center'
                        }}
                      >
                        {dataRTU[`pipelineSubscriptionAdd${envLowerCase}`]}

                        <Text variant='b3'>
                          {i18n.t('label.used_in_this_deploy')}
                        </Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          borderRadius: '$round',
                          width: '24px',
                          height: '24px',
                          justifyContent: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <Text variant='b3'>=</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          marginLeft: '$2'
                        }}
                      >
                        {
                          dataRTU[
                            `availableAmountOfPipelineSubscription${envLowerCase}`
                          ]
                        }
                        <Text variant='b3'>
                          {i18n.t('label.available_after_deploy')}
                        </Text>
                      </Box>
                    </Box>

                    <Text
                      css={{
                        marginTop: '$3'
                      }}
                    >
                      {i18n.t(
                        env === 'prod'
                          ? 'label.prod_runtime_unit'
                          : 'label.test_runtime_unit'
                      )}
                    </Text>
                    <Box
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '$3'
                      }}
                    >
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2'
                        }}
                      >
                        {
                          pipelineSubscriptionAndRtu[`rtu${envLowerCase}`][
                            `totalRtuAllowed${envLowerCase}`
                          ]
                        }
                        <Text variant='b3'>{i18n.t('label.licensed')}</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          borderRadius: '$round',
                          width: '24px',
                          height: '24px',
                          justifyContent: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <Text variant='b3'>-</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center'
                        }}
                      >
                        {pipelineSubscriptionAndRtu.totalRtuUsedByPlan}

                        <Text variant='b3'>{i18n.t('label.in_use')}</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          borderRadius: '$round',
                          width: '24px',
                          height: '24px',
                          justifyContent: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <Text variant='b3'>-</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center'
                        }}
                      >
                        {
                          pipelineSubscriptionAndRtu[`rtu${envLowerCase}`][
                            `totalRtuAdd${envLowerCase}`
                          ]
                        }

                        <Text variant='b3'>
                          {i18n.t('label.used_in_this_deploy')}
                        </Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          borderRadius: '$round',
                          width: '24px',
                          height: '24px',
                          justifyContent: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <Text variant='b3'>=</Text>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '$2',
                          textAlign: 'center',
                          background: '$neu50',
                          marginLeft: '$2'
                        }}
                      >
                        {
                          pipelineSubscriptionAndRtu[`rtu${envLowerCase}`][
                            `totalRtuAvailable${envLowerCase}`
                          ]
                        }

                        <Text variant='b3'>
                          {i18n.t('label.available_after_deploy')}
                        </Text>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}

          <Dialog.Footer>
            <Button size='medium' variant='primary' onClick={() => onDeploy()}>
              {i18n.t('action.deploy')}
            </Button>
            <Dialog.Close asChild>
              <Button
                outlined
                size='medium'
                variant='primary'
                onClick={() => onClose()}
              >
                {i18n.t('action.cancel')}
              </Button>
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </React.Fragment>
    </Dialog.Root>
  );
};

export default DialogLicense;
