import React from 'react';

import { HistoryDeploymentProvider } from './context/HistoryDeploymentProvider';
import Detail from './Detail';
import List from './List';

import i18n from '~/common/helpers/i18n';
import { ConfirmDialogProvider } from '~/components/ConfirmDialogV2/contexts/confirmDialogProvider';

export const HistoryDeploymentRoutes = {
  path: 'deployment/history',
  name: 'run-history',
  element: (
    <ConfirmDialogProvider>
      <HistoryDeploymentProvider>
        <List />
      </HistoryDeploymentProvider>
    </ConfirmDialogProvider>
  ),
  title: i18n.t('label.deployment_history_tab'),
  children: [
    {
      path: 'detail',
      title: i18n.t('label.pipeline_information'),
      element: <Detail />
    }
  ]
};
