import { Text } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { Condition, Execution } from '@digibee/flow';
import {
  faCaretDown,
  faCaretRight,
  faSplit
} from '@fortawesome/pro-solid-svg-icons';
import { shallowEqual, useSelector } from '@xstate/react';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

type ConditionNodeProps = {
  node: TreeNode<Condition | Execution>;
};

const ConditionNode = ({ node }: ConditionNodeProps) => {
  const actor = useTreeContext(state => state.actor);
  const actionsSlot = useTreeContext(state => state.actionsSlot);

  const isChildrenExpanded = useSelector(
    actor,
    state => state.context.expandedById[node.id],
    shallowEqual
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state => {
    const { currentLevelComponent } = state.context;
    const component =
      node.item instanceof Condition
        ? node.item.parentChoice()
        : node.item.parentParallel();
    return currentLevelComponent
      ? component.comesFromComponent(currentLevelComponent.id())
      : false;
  });

  const labelValue = useSelector(actor, state => {
    const { flow } = state.context;
    const item = node?.item.spec();

    if (item && item.target.startsWith('api-first:')) {
      const target = flow.getComponent(item.target);
      const spec = target?.spec();
      if (Array.isArray(spec)) {
        return spec[0].stepName;
      }
    }
    return '';
  });

  const emphasis = comesFromCurrentLevelComponent ? 'children' : undefined;

  const actionsSlotRendered = actionsSlot?.({
    item: node.item
  });

  const label = node.id.startsWith('api-first:') ? labelValue : node.label;

  return (
    <Elements.Container data-testid='flow-tree-node-container'>
      <Elements.Item
        data-testid='build-flow-tree-node-item'
        depthLeft={node.depth}
        emphasis={emphasis}
      >
        <Elements.ToggleWrapper
          onClick={() => actor.send({ type: 'TOGGLE_CHILDREN', id: node.id })}
        >
          <Elements.Icon
            icon={isChildrenExpanded ? faCaretDown : faCaretRight}
          />
        </Elements.ToggleWrapper>
        <Elements.Icon icon={faSplit} />
        <Elements.Label>
          <Text variant='b3' css={{ fontWeight: 400 }} disabled>
            {label}
          </Text>
        </Elements.Label>

        {iff(!!actionsSlotRendered, () => (
          <Elements.FloatAction>{actionsSlotRendered}</Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default ConditionNode;
