import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
  PropsWithChildren,
  FC,
  Dispatch,
  SetStateAction
} from 'react';

interface HeaderContextProps {
  configActionButton: object[];
  setConfigActionButton: Dispatch<SetStateAction<object[]>>;
  setNode: Dispatch<SetStateAction<undefined>>;
  node?: any;
}

export const HeaderContext = createContext<HeaderContextProps>({
  configActionButton: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfigActionButton: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNode: () => {}
});

export const HeaderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [configActionButton, setConfigActionButton] = useState<object[]>([]);
  const [node, setNode] = useState();

  const value = useMemo(
    () => ({
      setConfigActionButton,
      configActionButton,
      node,
      setNode
    }),
    [configActionButton, node]
  );
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = () => useContext(HeaderContext);

export const useHeader = (config: any, dependencies: Array<any> = []) => {
  const { setConfigActionButton, setNode, node } = useContext(HeaderContext);

  const { configActionButton } = useMemo(() => config, dependencies);

  useEffect(
    () => () => {
      setConfigActionButton([]);
    },
    [setConfigActionButton]
  );

  useEffect(() => {
    setConfigActionButton(configActionButton);
  }, [configActionButton, setConfigActionButton]);

  useEffect(() => {
    if (!node) return;
    setNode(node);
  }, [node, setNode]);

  return {
    scrollingRef: (n: any) => {
      setNode(n);
    }
  };
};

HeaderProvider.propTypes = {
  children: PropTypes.element
};
