import { ApolloError, isApolloError } from '@apollo/client';
import gql from 'graphql-tag';

import clientApollo from './clientApollo';

import { ApiResponse } from '~/common/types/Api';
import { Pagination } from '~/common/types/Pagination';
import {
  Capsule,
  CapsuleCollection,
  CapsuleCollectionGroup,
  CapsuleCollectionHeader
} from '~/entities/Capsule';

const collectionsApi = {
  async createCollection(
    realm: string,
    capsuleCollection: CapsuleCollection
  ): Promise<ApiResponse<CapsuleCollection>> {
    try {
      const { data } = await clientApollo.mutate<{
        createNewCapsule: CapsuleCollection;
      }>({
        mutation: gql`
          mutation createNewCapsule($realm: String!, $capsule: JSON!) {
            createNewCapsule(realm: $realm, capsule: $capsule)
          }
        `,
        variables: {
          realm,
          capsule: capsuleCollection
        }
      });
      return {
        success: true,
        data: data?.createNewCapsule
      };
    } catch (error) {
      if (String(error).includes('Capsule collection name already exists')) {
        return {
          success: false,
          errorMessage: 'COLLECTION_NAME_ALREADY_EXISTS'
        };
      }
      return {
        success: false,
        errorMessage: String(error)
      };
    }
  },
  async createCollectionV2(
    realm: string,
    capsuleCollection: CapsuleCollection
  ): Promise<ApiResponse<CapsuleCollection>> {
    try {
      const { data } = await clientApollo.mutate<{
        createOrUpdateCapsuleCollection: CapsuleCollection;
      }>({
        mutation: gql`
          mutation createOrUpdateCapsuleCollectionV2(
            $realm: String!
            $collection: JSON!
          ) {
            createOrUpdateCapsuleCollectionV2(
              realm: $realm
              collection: $collection
            )
          }
        `,
        variables: {
          realm,
          collection: capsuleCollection
        }
      });
      return {
        success: true,
        data: data?.createOrUpdateCapsuleCollection
      };
    } catch (error) {
      if (String(error).includes('Capsule collection name already exists')) {
        return {
          success: false,
          errorMessage: 'COLLECTION_NAME_ALREADY_EXISTS'
        };
      }
      return {
        success: false,
        errorMessage: String(error)
      };
    }
  },
  async archiveCollection(
    realm: string,
    collectionId: string
  ): Promise<ApiResponse<void>> {
    try {
      await clientApollo.mutate({
        mutation: gql`
          mutation archiveCollection($realm: String!, $collectionId: String!) {
            archiveCollection(realm: $realm, collectionId: $collectionId)
          }
        `,
        variables: {
          realm,
          collectionId
        }
      });
      return {
        success: true
      };
    } catch (error) {
      if (isApolloError(error as Error)) {
        return {
          success: false,
          errorMessage: (error as ApolloError).message
        };
      }
      return {
        success: false
      };
    }
  },
  async fetchHeaders(
    realm: string,
    disabled?: boolean
  ): Promise<ApiResponse<CapsuleCollectionHeader[]>> {
    try {
      const { data } = await clientApollo.query<{
        capsuleCollectionHeaderV3: CapsuleCollectionHeader[];
      }>({
        query: gql`
          query capsuleCollectionHeaderV3($realm: String!, $disabled: Boolean) {
            capsuleCollectionHeaderV3(realm: $realm, disabled: $disabled) {
              id
              title
              iconName
              iconURL
              disabled
            }
          }
        `,
        variables: {
          realm,
          disabled
        }
      });
      return {
        data: data.capsuleCollectionHeaderV3
      };
    } catch (e) {
      return {
        data: undefined
      };
    }
  },
  async fetchGroups(
    realm: string,
    collectionId: string,
    search: object = {
      page: 0,
      disabled: false,
      title: ''
    }
  ): Promise<ApiResponse<Pagination<CapsuleCollectionGroup>>> {
    try {
      const { data } = await clientApollo.query<{
        collectionGroupsPagination: Pagination<CapsuleCollectionGroup>;
      }>({
        query: gql`
          query collectionGroupsPagination(
            $realm: String!
            $collectionId: String!
            $search: JSON
          ) {
            collectionGroupsPagination(
              realm: $realm
              collectionId: $collectionId
              search: $search
            ) {
              content
              last
              totalElements
              totalPages
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables: {
          realm,
          collectionId,
          search
        }
      });
      return {
        data: data.collectionGroupsPagination
      };
    } catch (error) {
      return {
        errorMessage: String(error)
      };
    }
  },
  async fetchGroupsV2(
    realm: string,
    collectionId: string,
    search: object = {
      page: 0,
      disabled: false,
      title: ''
    }
  ): Promise<ApiResponse<Pagination<CapsuleCollectionGroup>>> {
    try {
      const { data } = await clientApollo.query<{
        collectionGroupsPaginationV2: Pagination<CapsuleCollectionGroup>;
      }>({
        query: gql`
          query collectionGroupsPaginationV2(
            $realm: String!
            $collectionId: String!
            $search: JSON
          ) {
            collectionGroupsPaginationV2(
              realm: $realm
              collectionId: $collectionId
              search: $search
            ) {
              content
              last
              totalElements
              totalPages
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables: {
          realm,
          collectionId,
          search
        }
      });
      return {
        data: data.collectionGroupsPaginationV2
      };
    } catch (error) {
      return {
        errorMessage: String(error)
      };
    }
  },
  async createGroup(
    realm: string,
    collectionId: string,
    groupTitle: string
  ): Promise<ApiResponse<CapsuleCollectionGroup>> {
    try {
      const { data } = await clientApollo.mutate<{
        createCollectionGroup: CapsuleCollectionGroup;
      }>({
        mutation: gql`
          mutation createCollectionGroup(
            $realm: String!
            $collectionId: String!
            $group: CollectionGroupInput
          ) {
            createCollectionGroup(
              realm: $realm
              collectionId: $collectionId
              group: $group
            ) {
              id
              title
            }
          }
        `,
        variables: {
          realm,
          collectionId,
          group: {
            title: groupTitle
          }
        }
      });
      return {
        data: data?.createCollectionGroup
      };
    } catch (error) {
      if (
        String(error).includes(
          'title already exists for this capsuleCollection'
        )
      ) {
        return {
          errorMessage: 'GROUP_NAME_ALREADY_EXISTS'
        };
      }
      return {
        errorMessage: String(error)
      };
    }
  },
  async createGroupV3(
    realm: string,
    collectionId: string,
    groupTitle: string
  ): Promise<ApiResponse<CapsuleCollectionGroup>> {
    try {
      const { data } = await clientApollo.mutate<{
        createCapsuleCollectionGroupV3: CapsuleCollectionGroup;
      }>({
        mutation: gql`
          mutation createCapsuleCollectionGroupV3(
            $realm: String!
            $collectionId: String!
            $group: CollectionGroupInput
          ) {
            createCapsuleCollectionGroupV3(
              realm: $realm
              collectionId: $collectionId
              group: $group
            ) {
              id
              title
            }
          }
        `,
        variables: {
          realm,
          collectionId,
          group: {
            title: groupTitle
          }
        }
      });
      return {
        data: data?.createCapsuleCollectionGroupV3
      };
    } catch (error) {
      return {
        errorMessage: String(error).includes(
          'title already exists for this capsuleCollection'
        )
          ? 'GROUP_NAME_ALREADY_EXISTS'
          : String(error)
      };
    }
  },
  async editGroup(
    realm: string,
    collectionId: string,
    groupId: string,
    groupTitle: string
  ): Promise<ApiResponse<CapsuleCollectionGroup>> {
    try {
      const { data } = await clientApollo.mutate<{
        editGroupCollection: CapsuleCollectionGroup;
      }>({
        mutation: gql`
          mutation editGroupCollection(
            $realm: String!
            $collectionId: String!
            $groupId: String!
            $title: String
          ) {
            editGroupCollection(
              realm: $realm
              collectionId: $collectionId
              groupId: $groupId
              title: $title
            ) {
              id
              title
            }
          }
        `,
        variables: {
          realm,
          collectionId,
          groupId,
          title: groupTitle
        }
      });
      return {
        success: true,
        data: data?.editGroupCollection
      };
    } catch (error) {
      if (
        String(error).includes(
          'title already exists for this capsuleCollection'
        )
      ) {
        return {
          success: false,
          errorMessage: 'GROUP_NAME_ALREADY_EXISTS'
        };
      }
      return {
        success: false,
        errorMessage: String(error)
      };
    }
  },
  async editGroupV2(
    realm: string,
    groupId: string,
    groupTitle: string
  ): Promise<ApiResponse<CapsuleCollectionGroup>> {
    try {
      const { data } = await clientApollo.mutate<{
        editGroupCollectionV2: CapsuleCollectionGroup;
      }>({
        mutation: gql`
          mutation editGroupCollectionV2(
            $realm: String!
            $groupId: String!
            $title: String
          ) {
            editGroupCollectionV2(
              realm: $realm
              groupId: $groupId
              title: $title
            ) {
              id
              title
            }
          }
        `,
        variables: {
          realm,
          groupId,
          title: groupTitle
        }
      });
      return {
        data: data?.editGroupCollectionV2
      };
    } catch (error) {
      return {
        errorMessage: String(error).includes('title already exists')
          ? 'GROUP_NAME_ALREADY_EXISTS'
          : String(error)
      };
    }
  },
  async archiveGroup(
    realm: string,
    groupId: string
  ): Promise<ApiResponse<void>> {
    try {
      await clientApollo.mutate<{
        archiveGroupCollection: CapsuleCollectionGroup;
      }>({
        mutation: gql`
          mutation archiveGroupCollection($realm: String!, $groupId: String!) {
            archiveGroupCollection(realm: $realm, groupId: $groupId) {
              id
              title
            }
          }
        `,
        variables: {
          realm,
          groupId
        }
      });
      return {
        success: true
      };
    } catch (e) {
      return {
        success: false
      };
    }
  },
  async fetchCapsulesByGroup(
    realm: string,
    collectionId: string,
    groupId: string,
    page: number,
    title: string,
    disabled: boolean
  ): Promise<ApiResponse<Pagination<Capsule>>> {
    try {
      const { data } = await clientApollo.query<{
        groupCapsules: Pagination<Capsule>;
      }>({
        query: gql`
          query groupCapsules(
            $realm: String!
            $collectionId: String!
            $search: JSON
            $size: Int
          ) {
            groupCapsules(
              realm: $realm
              collectionId: $collectionId
              search: $search
              size: $size
            ) {
              content
              last
              totalElements
              totalPages
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables: {
          realm,
          collectionId,
          size: 9,
          search: {
            page,
            capsuleGroupId: groupId,
            title,
            disabled
          }
        }
      });
      return {
        success: true,
        data: data.groupCapsules
      };
    } catch (e) {
      return {
        success: false
      };
    }
  }
};

export default collectionsApi;
