import React from 'react';
import { Link as LinkNative } from 'react-router-dom';
import styled, { css } from 'styled-components';

import IconNative from '~/components/IconCommunity';
import TextNative from '~/components/Text';

export const Header = styled.div`
  padding-bottom: ${props => props.bottom}px;
  line-height: 1.3;
  display: flex;
  justify-content: space-between;
`;

export const Box = styled.div`
  padding-bottom: ${props => props.bottom}px;
  padding-top: ${props => props.top}px;
  padding-left: ${props => props.left}px;
  paddind-right: ${props => props.right}px;
  display: flex;
  align-items: center;
`;

export const Link = styled(props => <LinkNative {...props} />)`
  display: flex;
  text-decoration: none;
  p {
    padding-right: 8px;
  }
`;

export const Icon = styled(IconNative)`
  width: 12px;
`;

export const WrapperTrigger = styled.div`
  width: 10%;
  display: flex;
  width: 45px;
  height: 45px;
`;

export const TriggerImage = styled.img`
  user-drag: none;
  user-select: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.gra25};
`;

export const Text = styled(TextNative)`
  ${props =>
    props.type === 'secondary' &&
    css`
      color: ${props.theme.colors.gra600} !important;
    `};
`;

export const TextDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
  margin-top: 2px;
  padding-left: 10px;
`;

export const WrapperDetails = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const Separator = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.gra200};
`;
