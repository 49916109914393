import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

export type GetLatestProps = {
  realm: string;
  termType: string;
};

export type ResponseProps = {
  realm: string;
  id: string;
  response: 'accept' | 'postpone' | 'refuse';
};

export type AcceptanceTerm = {
  description: string;
  term: string;
  countDown: number;
  acceptanceDate: number | null;
  expired: boolean;
  lastPostponementDate: number | null;
  refusalDate: number | null;
};

const acceptanceTerm = {
  getLatest: (variables: GetLatestProps) =>
    clientApollo.query<{ termAcceptanceLatest: AcceptanceTerm }>({
      query: gql`
        query termAcceptanceLatest($realm: String!, $termType: String) {
          termAcceptanceLatest(realm: $realm, termType: $termType) {
            id
            termId
            userId
            firstAccessDate
            expirationDate
            lastPostponementDate
            acceptanceDate
            refusalDate
            countDown
            expired
            description
            termType
            daysToAccept
            term
            version
            legalRegion
          }
        }
      `,
      variables
    }),
  response: (params: ResponseProps) =>
    clientApollo.mutate<{ termAcceptanceResponse: AcceptanceTerm }>({
      mutation: gql`
        mutation termAcceptanceResponse(
          $realm: String!
          $id: String!
          $response: String!
        ) {
          termAcceptanceResponse(realm: $realm, id: $id, response: $response)
        }
      `,
      variables: {
        ...params
      }
    })
};

export default acceptanceTerm;
