import { createContext } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';
import WebAnalytics from '~/common/helpers/webAnalytics';
import {
  FEATUREFLAG_API_KEY,
  FEATUREFLAG_URL,
  FEATUREFLAG_MOCK_FEATURES
} from '~/common/helpers/environment';
import storage from '~/common/helpers/storage';

type UserData = {
  email: string;
};

type Props = {
  children: JSX.Element;
};

const SplitAttributesContext = createContext<SplitIO.Attributes>({
  key: 'anonymous'
});

const getKey = (userData?: UserData): string => {
  if (userData?.email) return userData.email;

  const keySession = storage.get('userToggleKeySession', false);

  if (keySession) return keySession;

  const anonKeySession = `anon-${new Date().getTime()}`;

  storage.set('userToggleKeySession', anonKeySession);

  return anonKeySession;
};

const SplitProvider = ({ children }: { children: JSX.Element }) => {
  try {
    // @ts-ignore
    const authentication = useSelector(state => state.authentication);
    // @ts-ignore
    const application = useSelector(state => state.application);

    const key = getKey(authentication.userData);

    const config: SplitIO.IBrowserSettings = {
      // debug: true,
      core: {
        authorizationKey: FEATUREFLAG_API_KEY,
        key,
        trafficType: authentication.isSignedIn ? 'user' : 'anonymous'
      },
      urls: {
        sdk: FEATUREFLAG_URL,
        events: FEATUREFLAG_URL,
        auth: FEATUREFLAG_URL
      },
      features: FEATUREFLAG_MOCK_FEATURES as SplitIO.MockedFeaturesMap,
      impressionListener: {
        logImpression: impressionData =>
          datadogRum.addFeatureFlagEvaluation(
            impressionData.impression.feature,
            impressionData.impression.treatment
          )
      }
    };

    const attributes = {
      realm: application.realm?.realm || '',
      key
    };

    return (
      <SplitAttributesContext.Provider value={attributes}>
        <SplitFactory config={config} attributes={attributes}>
          {children}
        </SplitFactory>
      </SplitAttributesContext.Provider>
    );
  } catch (error) {
    WebAnalytics.sendEvent('[Global] (Feature Flag) Provider Error', { error });
    return children;
  }
};

export { SplitAttributesContext };
export default SplitProvider;
