/* eslint-disable */

import axios from 'axios';
import { gql } from '@apollo/client';
import omit from 'lodash/omit';
import moment from 'moment';
import { saveAs } from 'file-saver';
//Local
import { API_KEY, URL } from '~/common/helpers/environment';
import clientApollo from './clientApollo';
// import {pickBy, identity} from 'lodash';

const authConfig = {
  fetch: params => {
    return clientApollo.query({
      query: gql`
        query authConfig($realm: String!, $search: JSON) {
          authConfig(realm: $realm, search: $search) {
            id
            owner
            title
            consumerId
            acls
            expiresIn
            configVersion
            revoked
            timestamp
            expiresInDate
            status
          }
        }
      `,
      variables: {
        ...params
      }
    });
  },
  async remove(params) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteAuthConfig($realm: String!, $authConfigId: String!) {
          deleteAuthConfig(realm: $realm, authConfigId: $authConfigId)
        }
      `,
      variables: {
        ...params
      }
    });
  },
  async create(params) {
    try {
      const { data } = await axios.post(
        `${URL}/design/realms/${params.realm}/authConfig`,
        omit(params, ['token']),
        {
          headers: {
            apikey: API_KEY,
            authorization: params.token
          }
        }
      );
      var blob = new Blob([JSON.stringify(data)]);
      saveAs(blob, `${moment().unix()}-token.json`);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async fetchAcls(params) {
    try {
      const response = clientApollo.query({
        query: gql`
          query aclsDigibeectl($realm: String!) {
            aclsDigibeectl(realm: $realm) {
              id
              realm
              scopes
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  }
};

export default authConfig;
