import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Locals
import TextNative from '../../../Text';

import { placeholder } from '~/common/styled/input';

const Text = styled(TextNative)`
  ${placeholder.colorStyles};
  ${placeholder.transformStyles};
  ${placeholder.textStyled};
`;

const Placeholder = ({ children, isActived, danger, isValue, ...props }) => (
  <Text
    size='sm'
    isActived={isActived}
    isDanger={danger}
    isValue={isValue}
    as='label'
    {...props}
  >
    {children}
  </Text>
);

Placeholder.propTypes = {
  /**
   * The typography text.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  isActived: PropTypes.bool,
  danger: PropTypes.bool,
  isValue: PropTypes.bool
};

Placeholder.defaultProps = {
  isActived: false,
  danger: false,
  isValue: false,
  'data-testid': 'placeholder'
};

export default Placeholder;
