import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import createStore from '~/store';

const Provider = ({ children }) => {
  const [store, setStore] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (store) return;

    setStore(createStore(navigate));
  }, []);

  if (!store) return null;

  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export default Provider;
