

interface Props {
  data: any[];
  filename?: string;
  delimiter?: string;
  headers?: string[];
}

const FILE_TYPE = "text/csv;charset=utf-8;";

const download = (csvAsString: string, fileName: string) => {
  const byteOrderMark = "\ufeff";
  const blob = new Blob([byteOrderMark, csvAsString], { type: FILE_TYPE });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const toCsv = (
  data: any[],
  headers: string[] | undefined,
  delimiter: string
) => {
  const headerKeys = Object.keys(data[0]);
  const columnNames = headers ?? headerKeys;
  const csv = data.map((row) => headerKeys.map((fieldName) =>
    typeof row[fieldName] === "number"
      ? row[fieldName]
      : `"${String(row[fieldName]).replace(/"/g, '""')}"`
  ).join(delimiter));

  csv.unshift(columnNames.join(delimiter));

  return csv.join("\r\n");
};

const getFilename = (providedFilename: string): string => /csv$/i.test(providedFilename)
  ? providedFilename
  : `${providedFilename}.csv`;

const csvDownload = ({ data, filename = "arquivo.csv", delimiter = ";", headers }: Props): void => {
  const downloadFileName = getFilename(filename);

  if (data.length === 0) {
    download(
      headers ? headers.join(delimiter) : "", downloadFileName);
    return;
  }

  const csvString = toCsv(data, headers, delimiter);
  download(csvString, downloadFileName);
};

export default toCsv;
export { csvDownload, download };
