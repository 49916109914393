import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import TextNative from '../../../Text';

const colorStyles = ({
  theme,
  isActived,
  isDanger,
  isValue,
  disabled,
  secondary
}) => css`
  ${!isActived &&
  css`
    color: ${theme.colors.gra700} !important;
  `}

  ${isDanger &&
  css`
    color: ${theme.colors.red500} !important;
  `};

  ${disabled &&
  css`
    color: ${theme.colors.gra400} !important;
  `};

  ${isActived &&
  !isDanger &&
  css`
    color: ${theme.colors.pri500} !important;
  `}
  ${(isActived || isValue) &&
  css`
    top: 19px;
    transform: translate3d(0, -150%, 0) scale(0.75);
    background-color: ${secondary ? theme.colors.gra25 : theme.colors.white};
    padding: 0 6px;
    z-index: 6;
  `};
`;
const transformStyles = ({ theme, isActived, isValue, secondary }) => css`
  ${(isActived || isValue) &&
  css`
    font-size: 14px;
    top: 19px;
    transform: translate3d(0, -150%, 0) scale(0.75);
    background-color: ${secondary ? theme.colors.gra25 : theme.colors.white};
    padding: 0 6px;
    left: 8px;
    z-index: 6;
  `};
`;
const Text = styled(TextNative)`
  font-size: ${props => props.theme.typographyCurrent.text.sm.fontSize};
  ${colorStyles};
  ${transformStyles};
  position: absolute;
  box-sizing: border-box;
  left: 14px;
  top: 20px;
  line-height: 1.15rem;
  text-align: left;
  transition: all 0.1s ease-in-out;
  cursor: text;
  transform-origin: left top;
  will-change: transform;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.009375em;
  text-overflow: ellipsis;
  font-weight: inherit;
`;

const Placeholder = ({ children, isActived, danger, isValue, ...props }) => (
  <Text
    size='lg'
    isActived={isActived}
    isDanger={danger}
    isValue={isValue}
    as='label'
    {...props}
  >
    {children}
  </Text>
);

Placeholder.propTypes = {
  /**
   * The typography text.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  isActived: PropTypes.bool,
  danger: PropTypes.bool,
  isValue: PropTypes.bool,
  secondary: PropTypes.bool
};

Placeholder.defaultProps = {
  isActived: false,
  danger: false,
  isValue: false,
  secondary: false,
  'data-testid': 'placeholder'
};
export default Placeholder;
