import lowerCase from 'lodash/lowerCase';
import React from 'react';
import styled, { css } from 'styled-components';

// Local
import getFirstLetter from '~/common/helpers/getFirstLetter';
import Heading from '~/components/Heading';
import useRadioGroup from '~/components/RadioGroup/useRadioGroup';

const height = 96;
const width = 96;
const border = 1;
const borderRadius = 4;

interface TypeSize {
  [key: string]: {
    width: string;
    height: string;
  };
}

const typeSize: TypeSize = {
  small: {
    width: '38px',
    height: '38px'
  },
  medium: {
    width: '58px',
    height: '58px'
  },
  large: {
    width: '100%',
    height: '100%'
  }
};

interface PropsRoot {
  name?: string;
  disabled?: boolean;
}

const Root = styled.div<PropsRoot>`
    label: SizeBottom;
    position: relative;
    height: ${height}px;
    width: ${width}px;
    border-radius: ${borderRadius}px;
    border ${border}px dashed ${props => props.theme.colors.gra500};
    background-color: ${props => props.theme.colors.pri25};
    ${props =>
      props.disabled
        ? css`
            background: ${props.theme.colors.gra25};
          `
        : ''};
    margin: 10px;
`;

const getSize = ({ size = 'small' }) => {
  const sizeStyled = typeSize[size] || typeSize.small;
  return css`
    width: ${sizeStyled.width};
    height: ${sizeStyled.height};
  `;
};
interface PropsSquare {
  focus?: boolean;
  selected: boolean;
  disabled?: boolean;
  size?: string;
}

const Square = styled.span<PropsSquare>`
  label: Square;
  background-color: ${props => props.theme.colors.white};
  ${props => getSize(props)};
  border: ${border}px solid ${props => props.theme.colors.pri500};
  border-radius: ${borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  left: -1px;
  ${props =>
    props.focus
      ? css`
          background-color: ${props.theme.colors.pri50};
        `
      : ''};

  ${props =>
    props.selected
      ? css`
          background: ${props.theme.colors.pri500};
        `
      : ''};

  ${props =>
    props.disabled
      ? css`
          border: ${border}px solid ${props.theme.colors.gra500};
        `
      : ''};

  ${props =>
    props.disabled && props.selected
      ? css`
          background: ${props.theme.colors.gra500};
        `
      : ''};
`;

interface PropsHeading {
  focus?: boolean;
  selected?: boolean;
  disabled?: boolean;
  size?: string;
  children: any;
  weight: string;
}

const HeadingStyle = styled(Heading)<PropsHeading>`
  color: ${props => props.theme.colors.pri500} !important;
  ${props =>
    props.disabled
      ? css`
          color: ${props.theme.colors.gra500} !important;
        `
      : ''};
  ${props =>
    props.disabled && props.selected
      ? css`
          color: ${props.theme.colors.white} !important;
        `
      : ''};
  ${props =>
    props.selected
      ? css`
          color: ${props.theme.colors.white} !important;
        `
      : ''};
`;

interface Props {
  value?: any;
  label?: string;
  size: 'small' | 'medium' | 'large';
  disabled?: boolean;
  selected?: boolean;
  focus?: boolean;
}

const SizeButton: React.FC<Props> = ({
  value,
  selected: selectedProps,
  label,
  ...props
}) => {
  const radio = useRadioGroup();
  const select = () => {
    if (selectedProps) return true;

    if (radio && lowerCase(radio?.value) === lowerCase(value)) {
      return true;
    }

    return false;
  };
  const selected = select();
  return (
    <Root {...props}>
      <Square {...props} selected={selected}>
        <HeadingStyle {...props} selected={selected} weight='regular' size='xs'>
          {getFirstLetter(label)}
        </HeadingStyle>
      </Square>
    </Root>
  );
};

export default SizeButton;
