import { omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

// Locals
// eslint-disable-next-line import/no-cycle
import { user } from '~/api';
// eslint-disable-next-line import/no-cycle
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const userEntity = new schema.Entity('users');
const userSchema = { users: [userEntity] };

const userBetaEntity = new schema.Entity('betaUsers');
const betaUserSchema = { betaUsers: [userBetaEntity] };

const initUser = {
  id: '',
  email: '',
  scopes: []
};

const initialState = Immutable({
  result: {
    users: [],
    betaUsers: []
  },
  entities: {
    users: {},
    betaUsers: {}
  },
  loading: {
    users: false,
    groupUsers: false,
    user: false,
    permissions: false
  },
  pagination: {
    users: {
      last: true,
      totalElements: null,
      totalPages: null,
      size: 10,
      number: 0,
      first: null,
      numberOfElements: null
    },
    betaUsers: {
      last: true,
      totalElements: null,
      totalPages: null,
      size: 10,
      number: 0,
      first: null,
      numberOfElements: null
    }
  },
  groupUsers: null,
  permissionsUser: null,
  user: null,
  error: null,
  status: [],
  search: '',
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    user: initUser
  },
  modalDetails: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    id: null,
    user: initUser
  },
  modalRevoke: {
    visible: false,
    loading: false,
    success: false,
    error: null,
    id: null,
    user: initUser
  }
});

const userModel = {
  name: 'user',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      });
    },
    setEntity(state, { path, users, params }) {
      return state.merge({
        result: {
          ...state.result,
          [path]: users.result[path]
        },
        entities: {
          ...state.entities,
          [path]: users.entities[path]
        },
        ...params
      });
    },
    setError: (state, error) => state.merge({ error }),
    setGroupUsers: (state, groupUsers) => state.merge({ groupUsers }),
    setUser: (state, payload) => state.merge({ user: payload }),
    resetGroupUsers: state => state.merge({ groupUsers: null }),
    setModal(state, payload) {
      return state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      });
    },
    setPermissionsUser(state, permissionsUser) {
      return state.merge({ permissionsUser });
    },
    setModalDetails(state, payload) {
      return state.merge({
        modalDetails: {
          ...state.modalDetails,
          ...payload
        }
      });
    },
    setModalRevoke(state, payload) {
      return state.merge({
        modalRevoke: {
          ...state.modalRevoke,
          ...payload
        }
      });
    },
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setActionModalDetails: (state, payload) =>
      state.merge({
        modalDetails: {
          ...state.modalDetails,
          ...payload
        }
      }),
    setActionModalRevoke: (state, payload) =>
      state.merge({
        modalRevoke: {
          ...state.modalRevoke,
          ...payload
        }
      }),
    setModaDetailslLoading(state, loading) {
      return state.merge({
        modalDetails: {
          ...state.modalDetails,
          loading
        }
      });
    },
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setModalRevokeLoading(state, loading) {
      return state.merge({
        modalRevoke: {
          ...state.modalRevoke,
          loading
        }
      });
    },
    selectUser(state, editUser) {
      return state.merge({
        modal: {
          ...state.modal,
          ...editUser
        }
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    },
    setPagination(state, { path, value }) {
      return state.merge({
        pagination: {
          ...state.pagination,
          [path]: value
        }
      });
    },
    clear: () => initialState
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const resultListUser = await user.find(
          Object.assign(params, {
            realm: state.application.realm.realm
          })
        );
        const users = normalize(resultListUser.data, userSchema);
        dispatch.user.setEntity({
          path: 'users',
          users,
          params: omit(params, 'realm')
        });
      } catch (e) {
        dispatch.user.setError(e);
      }
    },
    async fetch(params = {}, state) {
      try {
        const request = await user.fetch(
          Object.assign(params, {
            realm: state.application.realm.realm,
            search: {
              page: state?.user?.pagination?.users?.number,
              size: state?.user?.pagination?.users?.size,
              ...state?.user?.search,
              ...params?.search
            }
          })
        );

        const betaUsers = normalize(
          {
            betaUsers: request?.data?.searchUsers?.content
          },
          betaUserSchema
        );

        dispatch.user.setPagination({
          path: 'betaUsers',
          value: omit(request?.data?.searchUsers, ['content'])
        });

        dispatch.user.setEntity({
          path: 'betaUsers',
          users: betaUsers,
          params: {
            ...omit(params, 'realm'),
            data: request?.data?.searchUsers?.content
          }
        });
      } catch (e) {
        dispatch.user.setError(e);
      }
    },
    async create(params, state) {
      try {
        const response = await user.create({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.user.setActionModal(
          { success: response, beta: params?.beta || false },
          {
            snackbar: {
              text: i18n.t('label.user_created_msg_success', {
                userName: `${params?.firstName} ${params?.lastName}`
              }),
              success: true,
              open: true
            }
          }
        );

        params?.props?.resetForm();
      } catch (e) {
        dispatch.user.setActionModal(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async assignGroup(params, state) {
      try {
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: params?.explanation
        });
        const { firstName, lastName } = state.user.modalDetails?.user;
        const response = await user.assignGroup({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.user.setActionModalDetails(
          { success: response, beta: params?.beta || false },
          {
            snackbar: {
              text: i18n.t('label.edit_user_permissions_msg_success', {
                userName: `${firstName} ${lastName}`
              }),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.user.setActionModalDetails(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      } finally {
        dispatch.dialogs.reset();
      }
    },
    async getUser(id, state) {
      try {
        const { data } = await user.getUser({
          realm: state.application.realm.realm,
          id
        });
        dispatch.user.setModalDetails({ user: data?.getUser });
      } catch (e) {
        dispatch.user.setModalDetails({ user: null });
      }
    },
    async getLostPermissions(id, state) {
      try {
        const { data } = await user.getLostPermissions({
          realm: state.application.realm.realm,
          id
        });

        const meta = {
          confirmDialog: {
            title: i18n.t(
              'scenes.access_control_transition.labels.all_users_not_ready_dialog_title'
            ),
            text: i18n.t(
              'scenes.access_control_transition.labels.all_users_not_ready_dialog_body'
            ),
            confirmText: i18n.t(
              'scenes.access_control_transition.labels.verify_all_access_dialog_button'
            ),
            cancelText: i18n.t(
              'scenes.access_control_transition.action.back_dialog_button'
            ),
            open: true,
            callbackCancel: () => {
              dispatch.user.setModalRevoke({
                visible: false,
                id: null,
                user: null,
                success: false
              });
            }
          }
        };

        const allUsersNotReady =
          data?.getLostPermissions?.totalNotReady ===
          data?.getLostPermissions?.totalUsers;

        dispatch.user.setModalRevoke(
          {
            user: data?.getLostPermissions?.users,
            id,
            visible: true
          },
          id === 'all' && allUsersNotReady && meta
        );
      } catch (e) {
        dispatch.user.setModalRevoke({ user: [] });
      }
    },
    async updatePermissionsV2User(id, state) {
      try {
        await user.updatePermissionsV2User({
          realm: state.application.realm.realm,
          id
        });
        dispatch.user.setActionModalRevoke({
          success: true,
          beta: true,
          id
        });
      } catch (e) {
        dispatch.user.setActionModalRevoke({
          error: e,
          beta: true
        });
      }
    },
    async getGroupUsers(params, state) {
      try {
        const { data } = await user.getGroupUsers({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.user.setGroupUsers(data?.getGroupUsers);
      } catch (e) {
        dispatch.user.setGroupUsers([]);
      }
    },
    async getPermissionsUser({ id, inactive }, state) {
      try {
        const { realm } = state.application.realm;
        const { data } = await user.getPermissionsUser({ realm, id, inactive });
        dispatch.user.setPermissionsUser(data.getPermissionsUser);
      } catch (e) {
        dispatch.user.setPermissionsUser([]);
      }
    },
    async edit(params, state) {
      try {
        const response = await user.edit({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.user.setActionModal(
          { success: response, beta: params?.beta || false },
          {
            snackbar: {
              text: i18n.t(
                'scenes.user.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('scenes.user.messages.success.item_updated', {
                    item: `${i18n.t('noun.user')}`
                  })}`
                }
              ),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.user.setActionModal(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async remove(params, state) {
      try {
        const remove = await user.remove({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.user.setActionModal(
          {
            success: remove,
            beta: params?.beta || false
          },
          {
            snackbar: {
              text: i18n.t('label.archive_user_msg_success', {
                userName: params?.firstName ? `${params?.firstName} ${params?.lastName}` : params?.email
              }),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.user.setActionModal(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async resetPassword(params, state) {
      try {
        const request = await user.resetPassword({
          realm: state.application.realm.realm,
          email: params?.email
        });
        dispatch.user.setActionModal(
          {
            success: request,
            beta: params?.beta || false
          },
          {
            snackbar: {
              text: i18n.t('label.user_password_reset_msg_success', {
                userName: params?.userName || params?.email
              }),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.user.setActionModal(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async unlockUser(params, state) {
      try {
        const request = await user.unlockUser({
          realm: state.application.realm.realm,
          email: params?.email
        });
        dispatch.user.setActionModal(
          {
            success: request,
            beta: params?.beta || false
          },
          {
            snackbar: {
              text: i18n.t('label.unlocked_login_msg_success'),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.user.setActionModal(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: i18n.t('label.unlocked_login_msg_error'),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async reactivate(params, state) {
      try {
        const reactivate = await user.reactivate({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.user.setActionModal(
          {
            success: reactivate,
            beta: params?.beta || false
          },
          {
            snackbar: {
              text: i18n.t('label.restore_user_msg_success', {
                userName: params?.firstName ? `${params?.firstName} ${params?.lastName}` : params?.email
              }),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.user.setActionModal(
          { error: e, beta: params?.beta || false },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async cancel() {
      await user.calcel();
    }
  }),
  logics: [
    {
      type: ['user/find'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'users', value: true });
        done();
      }
    },
    {
      type: ['user/fetch'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'betaUsers', value: true });
        done();
      }
    },
    {
      type: ['user/setError', 'user/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'users', value: false });
        dispatch.user.setLoading({ path: 'betaUsers', value: false });
        done();
      }
    },
    {
      type: 'user/getUser',
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'user', value: true });
        done();
      }
    },
    {
      type: 'user/getLostPermissions',
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'revoke', value: true });
        done();
      }
    },
    {
      type: 'user/getPermissionsUser',
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'permissions', value: true });
        done();
      }
    },
    {
      type: 'user/setPermissionsUser',
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'permissions', value: false });
        done();
      }
    },
    {
      type: 'user/setModalDetails',
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'user', value: false });
        done();
      }
    },
    {
      type: 'user/setModalRevoke',
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'revoke', value: false });
        done();
      }
    },
    {
      type: ['user/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { status, search } = context.getState().user;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.user.setModalLoading(!!payload.success);
          dispatch.user.setModal({
            visible: false,
            loading: false,
            success: true
          });
          if (payload.beta) {
            setTimeout(() => {
              dispatch.user.fetch();
            }, 1000);
          }
          if (!payload.beta) {
            dispatch.user.find({ status, search });
          }
        } else {
          dispatch.user.setModal({ loading: false, success: false });
        }
        done();
      }
    },
    {
      type: ['user/setActionModalDetails'],
      latest: true,
      process(context, dispatch, done) {
        const { status, search } = context.getState().user;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.user.setModaDetailslLoading(false);
          dispatch.user.setModalDetails({
            visible: false,
            id: null,
            user: null,
            success: true
          });
          if (payload.beta) {
            setTimeout(() => {
              dispatch.user.fetch();
            }, 1000);
          }
          if (!payload.beta) {
            dispatch.user.find({ status, search });
          }
        } else {
          dispatch.user.setModalDetails({ loading: false, success: false });
        }
        done();
      }
    },
    {
      type: ['user/updatePermissionsV2User'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setModalRevokeLoading(true);
        done();
      }
    },
    {
      type: ['user/setActionModalRevoke'],
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        if (payload.success) {
          dispatch.user.setModalRevokeLoading(false);
          dispatch.user.setModalDetails({
            success: true
          });
          dispatch.user.fetch();
        } else {
          dispatch.user.setModalRevokeLoading(false);
        }
        done();
      }
    },
    {
      type: ['user/create', 'user/edit'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setModalLoading(true);
        done();
      }
    },
    {
      type: ['user/assignGroup'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setModaDetailslLoading(true);
        done();
      }
    },
    {
      type: ['user/getGroupUsers'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'groupUsers', value: true });
        done();
      }
    },
    {
      type: ['user/setGroupUsers'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.user.setLoading({ path: 'groupUsers', value: false });
        done();
      }
    },
    {
      type: 'user/setModal',
      latest: true,
      process({ action }, dispatch, done) {
        const { visible, success } = action.payload;
        if (!visible && success) {
          dispatch.user.selectUser({ user: initUser });
        }
        done();
      }
    },
    {
      type: ['user/setSearch'],
      latest: true,
      process({ action }, dispatch, done) {
        const { beta } = action.payload;
        if (beta) {
          dispatch.user.fetch({ search: { page: 0 } });
        }
        done();
      }
    }
  ]
};

export default userModel;
