/* eslint-disable */

import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, css } from 'styled-components';
import Text from '~/components/Text';

/**
|--------------------------------------------------
| Styles
|--------------------------------------------------
*/

const reactModalStyles = {
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 9999
  }
};

const Modal = styled(ReactModal)`
  background-color: white;
  border: none;
  outline: none;
  bottom: auto;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1);
  left: auto;
  width: ${p => p.width} !important;
  padding: 0;
  right: auto;
  border-radius: 4px;
  top: auto;
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: hidden;
    `}
`;
Modal.displayName = 'StyledModal';

export const DialogTitle = styled.div`
  font-family: ${p => p.theme.typography.fontFamily};
  font-size: 20px;
`;

export const ContentTitle = styled.div`
  margin-bottom: ${props => props.theme.spacings.mega};
`;

export const Title = styled(Text)`
  color: ${props => props.theme.colors.gra900};
  font-size: 16px;
  line-height: 1.4;
`;

export const ContentText = styled.div`
  margin-bottom: ${props => props.theme.spacings.mega};
  line-height: 1.4;
`;

export const DialogContent = styled.div`
  font-family: ${p => p.theme.typography.fontFamily};
  font-weight: 200;
  color: ${p => p.theme.colors.gra500};
  padding: 24px;
`;

export const DialogFooter = styled.div`
  font-family: ${p => p.theme.typography.fontFamily};
  margin-bottom: 10px;
`;

const getPadding = ({ theme }) => css`
  padding: ${theme.spacings.giga} ${theme.spacings.giga} 0
    ${theme.spacings.giga};
`;
export const Container = styled.div`
  ${getPadding};
`;

const Dialog = ({
  children,
  closeOnEsc,
  closeOnOverlayClick,
  onRequestClose,
  opened,
  style,
  width,
  'data-testid': dataTestId,
  ...restProps
}) => (
  <Modal
    isOpen={opened}
    onRequestClose={onRequestClose}
    portalClassName='application-root'
    shouldCloseOnOverlayClick={closeOnOverlayClick}
    shouldCloseOnEsc={closeOnEsc}
    style={reactModalStyles}
    width={width}
    testId={dataTestId}
    {...restProps}
  >
    <>{children}</>
  </Modal>
);

Dialog.defaultProps = {
  children: [],
  closeOnEsc: true,
  closeOnOverlayClick: true,
  onRequestClose: () => {},
  opened: false,
  overflow: true,
  width: '512px',
  'data-testid': 'dialog'
};

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  closeOnEsc: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  onRequestClose: PropTypes.func,
  opened: PropTypes.bool,
  width: PropTypes.string,
  'data-testid': PropTypes.string
};

export default React.memo(Dialog);
