import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const baseStyles = ({ theme }) => css`
  padding-right: ${theme.spacings.mega};
  padding-left: ${theme.spacings.mega};
  padding-top: ${theme.spacings.mega};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  appearance: none;
  resize: vertical;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typographyCurrent.text.md.fontSize};
  line-height: 20px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
const animationStyles = ({ isActived }) => css`
  background: ${props => props.theme.colors.white};
  transition: background-color 5000s ease-in-out 0s;
  &::placeholder {
    opacity: ${isActived ? 1 : 0};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
`;
const borderStyles = ({ theme, danger, disabled }) => css`
  border-radius: ${theme.borderRadius.mega};
  border: 1px solid ${theme.colors.gra600};
  &:focus-within {
    margin: ${danger ? '0px' : '-1px'};
    border: 2px solid ${danger ? theme.colors.red500 : theme.colors.pri500};
    &:hover {
      border: 2px solid ${danger ? theme.colors.red500 : theme.colors.pri500};
    }
  }
  ${danger &&
  css`
    border: 2px solid ${theme.colors.red500};
  `};

  ${disabled &&
  css`
    border: 1px solid ${theme.colors.gra400};
  `};
`;

const BaseComponent = styled.textarea`
  min-height: 80px;
  ${p =>
    p.password &&
    css`
      -webkit-text-security: disc;
      text-security: disc;
    `}
  ${baseStyles};
  ${borderStyles};
  ${animationStyles};
`;

BaseComponent.propTypes = {
  danger: PropTypes.string,
  actived: PropTypes.bool
};

BaseComponent.defaultProps = {
  danger: false,
  'data-testid': 'textarea'
};

export default BaseComponent;
