const newBrandColors = {
  primary: {
    pri25: '#F4F2FF',
    pri50: '#ECE6FF',
    pri100: '#DBD1FF',
    pri200: '#C1ACFF',
    pri300: '#A37DFF',
    pri400: '#8949FF',
    pri500: '#6B10EB',
    pri600: '#5204C7'
  }
};

const colors = {
  ...newBrandColors.primary
};

const theme = {
  colors
};

export default theme;
