import Immutable from 'seamless-immutable';

const initialState = Immutable({
  queue: [],
  current: null
});

const snackbarModel = {
  name: 'snackbar',
  state: initialState,
  reducers: {
    pushSnackbar(state, snackbar) {
      return state.merge({
        queue: [...state.queue, snackbar]
      });
    },
    popSnackbar(state) {
      return state.merge({
        current: state.queue[0] || null,
        queue: state.queue.slice(1)
      });
    },
    setCurrent(state, snackbar) {
      return state.setIn(['current'], snackbar);
    },
    clearCurrent(state) {
      return state.setIn(['current'], null);
    }
  },
  effects: dispatch => ({
    async create(payload, { snackbar }) {
      if (!snackbar.current) {
        dispatch.snackbar.setCurrent(payload);
      } else {
        dispatch.snackbar.pushSnackbar(payload);
      }
    }
  }),
  logics: [
    {
      type: ['*'],
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        if (meta && meta.snackbar) {
          dispatch.snackbar.create(meta.snackbar);
        }
        done();
      }
    }
  ]
};

export default snackbarModel;
