import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { DeploymentPlanContext } from '../context/DeploymentPlanContext';

import api from '~/api/runtime';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import withHooks from '~/common/hoc/withHooks';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import useSnackbar from '~/common/hooks/useSnackbar';
import ConfirmDialogV2 from '~/components/ConfirmDialogV2';
import { useHeader } from '~/components/HeaderProvider';

const normalizeSize = (name: string) => {
  if (!name) return '';
  return name?.split(' ')[0];
};

type ValidationPropTypes = {
  code: string;
  message: string;
  details: [
    {
      level: string;
      details: string[];
      code: string;
      message: string;
      objects: string[];
    }
  ];
};

const enhancer = withHooks(() => {
  const { application } = useSelector((state: any) => state);
  const {
    getPlan,
    deploymentsPlan,
    loading,
    changeLoadingExecuteDeploy,
    validatePipeline,
    checkLicense,
    selectPlan,
    page
  } = useContext(DeploymentPlanContext);
  const navigate = useNavigate();

  const { RUN_POOLING_TIMER_DEPLOYMENTPLAN } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_POOLING_TIMER_DEPLOYMENTPLAN]);
  const confirmDialog = ConfirmDialogV2.useConfirmDialog();
  const snackbar = useSnackbar();

  const timePulling = treatments[RUN_POOLING_TIMER_DEPLOYMENTPLAN].treatment;

  const actionsButton = [
    {
      icon: 'RocketLaunch',
      text: i18n.t('action.create'),
      role: [
        'DEPLOYMENT:CREATE',
        `DEPLOYMENT:CREATE{ENV=PROD}`,
        `DEPLOYMENT:CREATE{ENV=TEST}`
      ],
      'data-testid': 'run-button-create',
      action: () => {
        navigate('create');
      }
    }
  ];

  useHeader({
    configActionButton: actionsButton
  });

  useEffect(() => {
    const fetchData = async () => {
      const isCreate = window.location.href.includes('/create');
      const isExecute = window.location.href.includes('/executeDeploy');
      if (!isCreate && !isExecute) {
        getPlan();
      }
    };

    const intervalId = setInterval(fetchData, parseInt(timePulling) * 1000);

    return () => clearInterval(intervalId);
  }, [page]);

  async function deleteItem(id: string, planName: string) {
    confirmDialog.show({
      title: i18n.t('label.delete_deployment_plan_prompt', {
        planName
      }),
      content: i18n.t('label.delete_deployment_plan_msg', {
        planName
      }),
      danger: true,
      confirmText: i18n.t('action.delete'),
      cancelText: i18n.t('action.cancel'),
      onConfirm: async () => {
        await api.deploymentPlanDelete({
          deploymentPlanId: id,
          realm: application.activeRealm
        });

        await getPlan();
        snackbar.createSnack(
          i18n.t('label.deployment_plan_deleted_msg_success', {
            planName
          })
        );
      }
    });
  }

  async function getPromoteInfo(id: string) {
    const { data } = await api.deploymentPlanPromoteInfo({
      realm: application?.activeRealm,
      deploymentPlanId: id,
      environment: 'prod',
      sourceEnvironment: 'test'
    });

    return data?.deploymentPlanPromoteInfo;
  }

  async function executePlan(plan: any, env: string) {
    selectPlan({});
    navigate(`executeDeploy/${env}`);
    changeLoadingExecuteDeploy(true);

    const obj = {
      ...plan,
      deploymentPlanItems: [],
      env
    };

    let deploymentPlanItemsByEnv = plan?.deploymentPlanItems;

    if (env === 'prod') {
      deploymentPlanItemsByEnv = await getPromoteInfo(plan.id);
    }

    for (const deploymentPlanItem of deploymentPlanItemsByEnv) {
      const envConfig = deploymentPlanItem.environmentConfiguration?.find(
        (i: any) => i.environment.name === env
      );

      const sizeValue = normalizeSize(
        envConfig.runtimeConfiguration.description
      );
      const pipeline = envConfig?.pipeline;
      let dataVersions = [];
      if (env !== 'prod') {
        const { data } = await api.getVersion({
          realm: application?.activeRealm,
          environment: env,
          versionMajor: pipeline.versionMajor.toString(),
          name: pipeline.name
        });

        dataVersions = data;
      }

      const pipelineValidation = await validatePipeline({
        pipelineId: pipeline.id,
        environment: env,
        realm: application?.activeRealm
      });

      const isValid = pipelineValidation?.filter?.(
        (item: ValidationPropTypes) => {
          const errors = item?.details.filter(
            detail => detail?.level == 'ERROR'
          );
          return errors.length;
        }
      );

      const licenseValid = await checkLicense({
        environment: env,
        pipelineId: pipeline.id,
        size: sizeValue,
        replicas: parseInt(envConfig.runtimeConfiguration.replicaCount),
        replicaInstanceName: '',
        realm: application.activeRealm
      });

      obj.deploymentPlanItems.push({
        ...envConfig,
        runtimeConfiguration: {
          ...envConfig.runtimeConfiguration,
          size: sizeValue.toUpperCase(),
          isValid,
          validationLicense: licenseValid,
          minorVersionsId: {
            value: pipeline.id,
            label: `${pipeline.versionMajor}.${pipeline.versionMinor}`
          },
          minorVersions: dataVersions?.pipelineVersions?.map((item: any) => ({
            value: item.id,
            label: `${item.versionMajor}.${item.versionMinor}`
          })),
          namePipeline: pipeline.name
        }
      });
    }
    selectPlan(obj);
    changeLoadingExecuteDeploy(false);
  }

  return {
    deploymentsPlan,
    loading,
    getPlan,
    deleteItem,
    executePlan,
    timePulling
  };
});

export default enhancer;
