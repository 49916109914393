import { useNavigate } from 'react-router';

import withHooks from '~/common/hoc/withHooks';

export type Props = {
  onReturnToLogin: () => void,
}

const enhancer = withHooks(() => {
  const navigate = useNavigate();

  function onReturnToLogin() {
    return navigate('/login');
  }

  return {
    onReturnToLogin
  } as Props;
});

export default enhancer;
