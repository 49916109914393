import immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const initialstate = immutable({
  key: '',
  modal: {
    fallback: false
  },
  loading: {
    fallback: false
  },
  result: {
    fallback: null
  },
  error: {
    fallback: null,
    key: null
  }
});

const fallbackchatModel = {
  name: 'fallbackchat',
  state: initialstate,
  reducers: {
    setKey(state, payload) {
      return state.merge({
        key: payload.key
      });
    },
    setError(state, error) {
      return state.merge({ error });
    },
    reset() {
      return initialstate;
    },
    setModal(state, { path, value }) {
      return state.merge({
        modal: {
          [path]: value
        }
      });
    },
    setResult(state, { path, value }) {
      return state.merge({
        result: {
          [path]: value
        }
      });
    },
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    }
  },
  effects: dispatch => ({
    async create() {
      try {
        const response = await api.intercom.create();

        dispatch.fallbackchat.setKey({
          key: response?.data?.fallbackchat
        });
      } catch (e) {
        dispatch.fallbackchat.setError({
          path: 'key',
          value: e.message
        });
      }
    },
    async createFallbackMessage({ values, actions }, { authentication }) {
      try {
        const response = await api.intercom.createFallbackMessage({
          realm: values?.realm,
          email: values?.email,
          message: values?.message,
          name: values?.name,
          subject:
            values?.subject == 'another_subject'
              ? values?.customSubject
              : values?.subject
        });
        actions?.resetForm?.();
        dispatch.application.handleIsOpenFallBackChat()
        dispatch.fallbackchat.setResult({
          path: 'fallback',
          value: response
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('scenes.fallback_chat.messages.error.error_message'),
          action: {
            label: i18n.t('action.ok'),
          }
        });
        dispatch.fallbackchat.setError({
          path: 'fallback',
          value: e?.message
        });
      }
    }
  }),
  logics: [
    {
      type: ['fallbackchat/createFallbackMessage'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.fallbackchat.setLoading({
          path: 'fallback',
          value: true
        });
        done();
      }
    },
    {
      type: ['fallbackchat/setError'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.fallbackchat.setLoading({
          path: 'fallback',
          value: false
        });
        done();
      }
    },
    {
      type: ['fallbackchat/setResult'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.fallbackchat.setLoading({
          path: 'fallback',
          value: false
        });
        dispatch.fallbackchat.setModal({
          path: 'fallback',
          value: false
        });
        dispatch.snackbar.create({
          text: i18n.t('scenes.fallback_chat.messages.success.success_message'),
          action: {
            label: i18n.t('action.ok'),
          }
        });
        done();
      }
    }
  ]
};

export default fallbackchatModel;
