import { Accordion, Icon, Skeleton, Box, Text, Tag } from '@digibee/beehive-ui';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import DetailsV2 from '../../../Details/DetailsV2';

import i18n from '~/common/helpers/i18n';
import { Deployment } from '~/entities/Deployment';
import { Trigger } from '~/entities/Trigger';

type Props = {
  deployment: Deployment;
  trigger: Trigger;
  linkGoToPipeline?: string;
  title?: string;
};

export const WrapperTrigger = styled.div`
  width: 10%;
  display: flex;
  width: 45px;
  height: 45px;
`;

export const TriggerImage = styled.img`
  user-drag: none;
  user-select: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.gra25};
`;

export const Separator = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.gra200};
`;

const normalizeSizeTitle = (name: string) => {
  if (!name) return '';
  return name.split('-')[0]?.toUpperCase();
};

const normalizeSizeDeploy = (name: string) =>
  name?.split('-')[0]?.toUpperCase();

const PipelineToRedeployV2: React.FC<Props> = ({
  title,
  deployment,
  trigger
}) => (
  <Box>
    <Accordion
      css={{ background: '$pri25' }}
      defaultOpen
      title={title || ''}
      data-testid='accordion-single'
      icon={<Icon icon='plus' />}
      id='accordion'
    >
      <Box>
        <Text variant='h4' css={{ marginBottom: '$3' }}>
          {deployment?.pipeline?.name}{' '}
        </Text>

        <Tag variant='version'>
          {`${i18n.t('scenes.runtime.labels.version')} ${
            deployment?.pipeline.versionMajor
          }.${deployment?.pipeline?.versionMinor}`}
        </Tag>
        {deployment?.deploymentStatus?.pipelineEngineVersion && (
          <Box css={{ paddingTop: '$2' }}>
            <Text variant='b3'>{`${i18n.t('label.engine_version')} : ${
              deployment?.deploymentStatus?.pipelineEngineVersion
            }`}</Text>
          </Box>
        )}
        {deployment?.project && (
          <Box css={{ paddingTop: '$2' }}>
            <Text variant='b3'>{`${i18n.t('noun.project')} : ${
              deployment.project
            }`}</Text>
          </Box>
        )}
        <Box css={{ paddingBottom: '$2', marginBottom: '$3', marginTop: '$3' }}>
          <Text variant='b3'>
            {`${i18n.t('scenes.runtime.labels.deploy_start_date')} ${moment(
              deployment?.startTime,
              'x'
            ).format('DD/MM/YYYY HH:mm')}`}
          </Text>
        </Box>
        {deployment?.metadata?.userEmail && (
          <Box css={{ paddingBottom: '$2' }}>
            <Text
              variant='b3'
              title={deployment?.metadata?.userName}
              className='fs-mask'
            >
              {`${i18n.t('label.deploy_user')}: ${
                deployment?.metadata?.userEmail
              }`}
            </Text>
          </Box>
        )}

        <Box css={{ display: 'flex', alignItems: 'center' }}>
          {!trigger ? (
            <Skeleton variant='circular' />
          ) : (
            <WrapperTrigger>
              <TriggerImage
                title={i18n.t('scenes.beta_pipelines.labels.trigger_icon')}
                src={`${trigger.iconURL}`}
              />
            </WrapperTrigger>
          )}

          <Text>{deployment?.pipeline?.description}</Text>
        </Box>
        <Separator />
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '$3'
          }}
        >
          <DetailsV2
            title={`${i18n.t(
              'scenes.runtime.guidance.deploy_size_tooltip'
            )} - ${normalizeSizeTitle(
              deployment?.activeConfiguration?.name || ''
            )}`}
            name={i18n.t('scenes.runtime.labels.deploy_size')}
            result={normalizeSizeDeploy(
              deployment?.activeConfiguration?.name || ''
            )}
          />
          <DetailsV2
            title={i18n.t('scenes.runtime.guidance.max_executions_tooltip')}
            name={i18n.t('scenes.runtime.labels.max_executions')}
            result={
              deployment?.activeConfiguration?.actualConsumers.toString() || ''
            }
          />
          <DetailsV2
            name={i18n.t('scenes.runtime.labels.deploy_replicas')}
            title={i18n.t('scenes.runtime.labels.deploy_replicas')}
            result={deployment?.availableReplicas?.toString() || ''}
          />
          <DetailsV2
            name={i18n.t('scenes.runtime.labels.deploy_licenses')}
            title={i18n.t('scenes.runtime.guidance.deploy_licenses_tooltip')}
            result={deployment?.licenses?.toString() || ''}
          />
        </Box>
      </Box>
    </Accordion>
  </Box>
);

export default PipelineToRedeployV2;
