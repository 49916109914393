import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
// Locals

import RadioButtonChecked from '../RadioButtonChecked';
import RadioButtonUnchecked from '../RadioButtonUnchecked';

const RadioButtonIconRoot = styled.span`
  position: relative;
  display: flex;
`;

const RadioButtonIconBackGround = styled(RadioButtonUnchecked)`
  color: ${props =>
    props.disabled ? props.theme.colors.gra500 : props.theme.colors.pri500};
  transform: scale(1);
`;

const RadioButtonIconDot = styled(RadioButtonChecked)`
  left: 0;
  position: absolute;
  transform: scale(0);
  color: ${props =>
    props.disabled ? props.theme.colors.gra500 : props.theme.colors.pri500};
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms
    ${props =>
      props.checked &&
      css`
        transform: scale(1);
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      `};
`;

const RadioButtonIcon = ({ checked = false, disabled }) => (
  <RadioButtonIconRoot>
    <RadioButtonIconBackGround checked={checked} disabled={disabled} />
    <RadioButtonIconDot checked={checked} disabled={disabled} />
  </RadioButtonIconRoot>
);

RadioButtonIcon.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RadioButtonIcon;
