// eslint-disable-next-line import/no-extraneous-dependencies
import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const groups = {
  find: ({ realm, search = {} }) =>
    clientApollo.query({
      query: gql`
        query groups($realm: String!, $search: JSON) {
          groups(realm: $realm, search: $search) {
            content {
              id
              name
              description
              disabled
              members
            }
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables: {
        realm,
        search
      }
    }),
  getIntegrations: ({ realm, search = {} }) =>
    clientApollo.query({
      query: gql`
        query samlGroupMapping($realm: String!, $search: JSON) {
          samlGroupMapping(realm: $realm, search: $search) {
            content {
              id
              label
              groupName
              samlScheme
              customSamlXPath
              sourceGroupId
              lastSimulationStatus
              disabled
              activated
              existsDeactivatedSamlGroupMapping
            }
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables: {
        realm,
        search
      }
    }),
  getIntegrationsSchemes: ({ realm }) =>
    clientApollo.query({
      query: gql`
        query samlSchemes($realm: String!) {
          samlSchemes(realm: $realm)
        }
      `,
      variables: {
        realm
      }
    }),
  async removeIntegration({ realm, id, explanation }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteSamlGroupMapping($realm: String!, $id: String!) {
          deleteSamlGroupMapping(realm: $realm, id: $id)
        }
      `,
      variables: {
        realm,
        id,
        explanation
      }
    });
  },
  createIntegrations({ realm, integrations, explanation }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createSamlGroupMapping(
          $realm: String!
          $integrations: [SamlGroupMappingInput]
        ) {
          createSamlGroupMapping(realm: $realm, integrations: $integrations)
        }
      `,
      variables: {
        realm,
        integrations,
        explanation
      }
    });
  },
  create({ realm, name, description, members = [] }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createGroup($realm: String!, $group: GroupInput!) {
          createGroup(realm: $realm, group: $group) {
            id
            name
            description
            disabled
            members
          }
        }
      `,
      variables: {
        realm,
        group: {
          name,
          description,
          members
        }
      }
    });
  },
  edit({ realm, group, policy }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editGroup(
          $realm: String!
          $group: GroupInput!
          $policy: JSON
        ) {
          editGroup(realm: $realm, group: $group, policy: $policy) {
            id
            name
            description
            disabled
            members
          }
        }
      `,
      variables: {
        realm,
        group,
        policy
      }
    });
  },
  editIntegration({ realm, integration }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editSamlGroupMapping(
          $realm: String!
          $integration: SamlGroupMappingInput!
        ) {
          editSamlGroupMapping(realm: $realm, integration: $integration)
        }
      `,
      variables: {
        realm,
        integration
      }
    });
  },
  async remove({ realm, id, explanation }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteGroup($realm: String!, $id: String!) {
          deleteGroup(realm: $realm, id: $id)
        }
      `,
      variables: {
        realm,
        id,
        explanation
      }
    });
  },
  async restore({ realm, ids }) {
    const { data } = await clientApollo.mutate({
      mutation: gql`
        mutation restoreGroup($realm: String!, $ids: [String!]) {
          restoreGroup(realm: $realm, ids: $ids) {
            success
            data {
              id
            }
            error {
              message
            }
          }
        }
      `,
      variables: {
        realm,
        ids
      }
    });
    return data.restoreGroup;
  },
  async isGroupNameValid({ realm, name }) {
    const request = await clientApollo.query({
      query: gql`
        query validateGroupName($realm: String!, $name: String!) {
          validateGroupName(realm: $realm, name: $name)
        }
      `,
      variables: {
        name,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request?.data?.validateGroupName;
  },
  async getFirstPolicyGroup({ realm, name }) {
    return clientApollo.query({
      query: gql`
        query getFirstPolicyGroup($realm: String!, $name: String!) {
          getFirstPolicyGroup(realm: $realm, name: $name) {
            id
            name
            description
            disabled
            custom
            bindings {
              groups
              role {
                id
                name
                description
                disabled
                custom
                permissions {
                  id
                  name
                  label
                  description
                  restricted
                  environmentCapable
                }
              }
              envs
            }
          }
        }
      `,
      variables: {
        realm,
        name
      }
    });
  }
};

export default groups;
