/* eslint-disable */
import { useState, useEffect } from 'react';
import compose from '~/common/helpers/compose';
import { isEmpty } from 'lodash';
import withHooks from '~/common/hoc/withHooks';
import useApi from '~/common/hooks/useApi';
import { connect } from 'react-redux';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import apiAccounts from '~/api/accounts'

const mapStateToProps = ({ application, accounts }) => ({
  hideDisplaySensitiveAccountFields:
    application.hideDisplaySensitiveAccountFields,
  realm: application.realm.realm,
  isSaving: accounts.modal.loading,
  environments: application?.environments?.allNames?.asMutable({ deep: true }),
  isAssociete: accounts.isAccountAssociate
});

const mapDispatchToProps = ({ accounts }) => ({
  save: accounts.save
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHooks(props => {
    const { realm, save, visible } = props;

    const [activeTab, setActiveTab] = useState(0);

    const { SETTINGS_ACCOUNTS_V2 } = featureFlagConstants;
    const { treatments } = useFeatureFlag([SETTINGS_ACCOUNTS_V2]);

    const showAccountsV2 = treatments[SETTINGS_ACCOUNTS_V2].treatment === 'on';

    const normalizedAccount = isEmpty(props.account)
      ? props.account
      : {
          ...props.account,
          env: props.account.fields ? JSON.parse(props.account.fields) : {}
        };

    useEffect(() => {
      if (!visible) {
        setActiveTab(0);
      }
    }, [visible]);

    /**
    |--------------------------------------------------
    | Getting Environments
    |--------------------------------------------------
    */

    const [
      environmentsResponse,
      fetchEnvironments,
      environmentsLoading,
      environmentsError
    ] = useApi('environments', 'find');
    useEffect(() => {
      fetchEnvironments({ realm });
    }, [realm]);

    const environments = environmentsResponse?.data?.environments || [];

    /**
    |--------------------------------------------------
    | Getting Accounts
    |--------------------------------------------------
    */

    const [
      accountsResponse,
      fetchAccounts,
      accountTypesLoading,
      accountTypesError
    ] = useApi('accounts', 'findTypes');
    useEffect(() => {
      fetchAccounts({ realm });
    }, [realm]);

    const accountTypes = accountsResponse?.data?.accountTypes || [];
    const accountTypesForSelect = accountTypes.map(accountType => ({
      label: accountType.name,
      value: accountType.name
    }));

    /**
    |--------------------------------------------------
    | State
    |--------------------------------------------------
    */

    const state = {
      account: normalizedAccount,
      activeTab,
      environments,
      environmentsLoading,
      environmentsError,
      accountTypes,
      accountTypesLoading,
      accountTypesError,
      accountTypesForSelect,
      showAccountsV2
    };

    /**
    |--------------------------------------------------
    | Actions
    |--------------------------------------------------
    */

    async function notificationAccount(env, account, deployment){
      await apiAccounts.accountsNotification({
        realm: props.realm,
        accountId: account.id,
        environment: env,
        deployments: deployment
      })
    }

    const actions = {
      setActiveTab,
      onConfirm: async (values) => {
        await save({ values, id: normalizedAccount?.id });
        if(showAccountsV2) {
          const envs = Object.keys(values?.env)
          if(envs.length > 0 ){
            envs.forEach(async (env) => {
              const deployment =  props.deployments?.content.filter((deploy) => deploy?.activeConfiguration?.environment?.name === env )

              if(deployment && deployment.length > 0) {
                await notificationAccount(env, values, deployment);
              }
            })
          }

          props.getAccountsPaginated(0)
        }
      }
    };

    return {
      ...props,
      ...state,
      ...actions
    };
  })
);
