import { Input } from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { FormEvent } from 'react';
// @ts-ignore
import * as yup from 'yup';

import * as Elements from './FormFirstAccess.elements';
import { Props } from './FormFirstAccess.enhancer';
import { getTranslateMessageArray } from '../../helpers/fusionAuthMessageTranslation';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';

const ResetSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'scenes.new_password.labels.new_password_assist')
    // Uppercase
    .matches(/[A-Z]+/, 'scenes.new_password.labels.new_password_assist')
    // Special character
    .matches(/[@$!%*#?&]+/, 'scenes.new_password.labels.new_password_assist')
    // Number
    .matches(/\d+/, 'scenes.new_password.labels.new_password_assist')
    .required('scenes.login.messages.error.required_field'),
  password: yup.string()
    .required('scenes.login.messages.error.required_field'),
  newPasswordConfirm: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'scenes.login.messages.error.password_not_equal'
    )
    .required('scenes.login.messages.error.required_field'),
});

export type FirstAccessSchemaType = {
  newPassword: string,
  password: string,
  newPasswordConfirm: string,
  versionLogin?: string,
};

const FormFirstAccess = ({
  signinFirstAccess,
  errorFirstAccess,
  successFirstAccess,
  loading,
  returnToSignIn,
}: Props) => {
  const {
    handleChange,
    values,
    touched,
    errors,
    handleSubmit,
  } = useFormik({
    validationSchema: ResetSchema,
    onSubmit: signinFirstAccess,
    initialValues: {
      newPassword: '',
      password: '',
      newPasswordConfirm: ''
    },
  });

  function onErrors(touchedFields: FormikTouched<FirstAccessSchemaType>, errorsFields: FormikErrors<FirstAccessSchemaType>, field: string) {
    const keyErrorRequest = 'scenes.login.messages.error.password_invalid';
    const fieldError = getFieldError(touchedFields, errorsFields, field);
    const isError = fieldError || errorFirstAccess?.authentication;
    return isError ? fieldError || keyErrorRequest : null;
  }

  const newPasswordErrorMessage = () => {
    if (getFieldError(touched, errors, 'newPassword')) {
      return i18n.t(
        'scenes.login.labels.upper_and_capitalize.capitalized',
        {
          item: i18n.t(
            getFieldError(touched, errors, 'newPassword')
          )
        }
      );
    }
    if (errorFirstAccess?.messages) {
      return getTranslateMessageArray(errorFirstAccess?.messages);
    }
    return '';
  }

  return (
    choose(
      when(successFirstAccess, () => (
        <Elements.StyledContainer>
          <Elements.StyledMessage data-testid='governance-success-message-forgot-password-page'>
            {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
              item: `${i18n.t('scenes.login.messages.success.new_password_set')}`
            })}
          </Elements.StyledMessage>
          <Elements.ButtonWrapper>
            <Elements.StyledButton data-testid='governance-success-message-return-to-sing-in-forgot-password-page' variant='primary' onClick={returnToSignIn}>
              {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
                item: `${i18n.t('scenes.login.actions.return_sign_in')}`
              })}
            </Elements.StyledButton>
          </Elements.ButtonWrapper>
        </Elements.StyledContainer>
      )),
      otherwise(() => (
        <Elements.StyledContainer>
          <form
            onSubmit={ev => {
              ev.preventDefault();
            }}
          >
            <Elements.StyledWelcome>
              {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
                item: `${i18n.t('scenes.login.guidance.welcome')}`
              })}
            </Elements.StyledWelcome>
            <Elements.StyledFields>
              <Input
                data-testid='governance-input-password-first-access-page'
                name='password'
                value={values.password}
                onChange={handleChange}
                type='password'
                invalid={Boolean(onErrors(touched, errors, 'password') || errorFirstAccess?.password)}
                helperText={
                  onErrors(touched, errors, 'password')
                    ? i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t(
                          onErrors(touched, errors, 'password')
                        )}`
                      }
                    )
                    : errorFirstAccess?.password &&
                    i18n.t('label.current_password_incorrect_assist_error')
                }
                label={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.password')}`
                  }
                )}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.password')}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.StyledFields>
              <Input
                data-testid='governance-input-new-password-first-access-page'
                name='newPassword'
                value={values.newPassword}
                onChange={handleChange}
                type='password'
                invalid={Boolean(getFieldError(touched, errors, 'newPassword') || errorFirstAccess?.newPassword)}
                helperText={newPasswordErrorMessage()}
                label={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.new_password')}`
                  }
                )}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.new_password')}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.StyledFields>
              <Input
                data-testid='governance-input-new-password-confim-first-access-page'
                name='newPasswordConfirm'
                type='password'
                value={values.newPasswordConfirm}
                invalid={Boolean(getFieldError(touched, errors, 'newPasswordConfirm'))}
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      getFieldError(touched, errors, 'newPasswordConfirm')
                    )}`
                  }
                )}
                onChange={handleChange}
                label={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      'scenes.login.actions.confirm_new_password'
                    )}`
                  }
                )}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      'scenes.login.actions.confirm_new_password'
                    )}`
                  }
                )}
              />
            </Elements.StyledFields>
            <Elements.StyledFields>
              <Elements.StyledButton
                data-testid='governance-confirm-button-first-access-page'
                key='buttonFirstAccess'
                variant='primary'
                onClick={(event) => handleSubmit(event as unknown as FormEvent<HTMLFormElement>)}
                loading={loading}
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.actions.confirm')}`
                  }
                )}
              </Elements.StyledButton>
            </Elements.StyledFields>
          </form>
        </Elements.StyledContainer>
      ))
    )
  );
};

export default FormFirstAccess;
