import { Text } from '@digibee/beehive-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ContainerFooter = styled.div`
  width: 100%;
  position: absolute;
  left: 50px;
  bottom: 32px;
  text-align: left;

  div {
    color: ${({ theme }) => theme.colors.gra500};
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.pri500};
`;

function FooterLoginIssues() {
  const { t } = useTranslation();

  return (
    <ContainerFooter>
      <Text variant='b3'>{t('label.still_have_login_problems')}</Text>
      <Text variant='b3'>
        {t('label.read_our_article_about')}
        &nbsp;
        <Link
          href={t('action.blocked_access_login_issues_url')}
          target='_blank'
        >
          {t('action.blocked_access_login_issues')}
        </Link>
      </Text>
    </ContainerFooter>
  );
}

export default FooterLoginIssues;
