import PropTypes from 'prop-types';
import React from 'react';
import * as IconNative from 'react-feather';
import styled, { css } from 'styled-components';
import { capitalize } from 'underscore.string';

const Icon = ({ name, ...props }) => {
  const Component = IconNative[capitalize(name)];
  return Component ? <Component {...props} /> : null;
};

const StyledIcon = styled(Icon)`
  ${props =>
    props.color &&
    css`
      color: props.color;
    `};
  ${props =>
    props.primary &&
    css`
      color: ${props.theme.colors.pri500};
    `};
  ${props =>
    props.success &&
    css`
      color: ${props.theme.colors.gre500};
    `}
  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.red500};
    `};
  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.red500};
    `};
  ${props =>
    props.warning &&
    css`
      color: ${props.theme.colors.yel500};
    `};
  ${props =>
    props.warning &&
    css`
      color: ${props.theme.colors.yel500};
    `};

  ${props =>
    props.inverted &&
    css`
    color ${props.theme.colors.white};
  `}
`;

Icon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string
};

Icon.defaultProps = {
  color: 'black',
  name: ''
};

export default StyledIcon;
