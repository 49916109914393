import get from 'lodash/get';
import isNil from 'lodash/isNil';

// eslint-disable-next-line no-underscore-dangle
const _do = func => func();

const checkConditions = ({
  conditions,
  values,
  env,
  iteratee = 'every',
  path
}) => {
  if (!conditions) return true;
  // eslint-disable-next-line consistent-return
  const valid = conditions[iteratee](condition => {
    const {
      type,
      property,
      nth,
      eq,
      not,
      and,
      nand,
      or,
      exists,
      gt,
      gte,
      lt,
      lte,
      nor,
      includes,
      regex
    } = condition;

    const conditionPropertyValuePath = _do(() => {
      if (nth && /^~[0-9]?$/.test(nth)) {
        const quantity = nth.split('')[1];
        if (!quantity) return property;

        const prefix = path
          .split(/(.+?\.[0-9])\.?/)
          .filter(Boolean)
          .slice(0, -Number(quantity))
          .join('.');

        return `${prefix}.${property}`;
      }

      return path ? `${path}.${property}` : property;
    });

    const conditionValue =
      type === 'env'
        ? get(env, property)
        : get(values, conditionPropertyValuePath);

    if (or) {
      return checkConditions({ conditions: or, values, iteratee: 'some' });
    }
    if (nor) {
      return !checkConditions({ conditions: nor, values, iteratee: 'some' });
    }
    if (and) {
      return checkConditions({ conditions: and, values, iteratee: 'every' });
    }
    if (nand) {
      return !checkConditions({ conditions: and, values, iteratee: 'every' });
    }
    if (!isNil(eq)) {
      // eslint-disable-next-line no-undef
      if (eq === false && dependentPropertyValue === undefined) return true;
      return conditionValue === eq;
    }
    if (!isNil(not)) {
      return conditionValue !== not;
    }
    if (exists) {
      return !!conditionValue;
    }
    if (gt) {
      return conditionValue > gt;
    }
    if (gte) {
      return conditionValue >= gte;
    }
    if (lt) {
      return conditionValue < lt;
    }
    if (lte) {
      return conditionValue <= lte;
    }
    if (includes) {
      return conditionValue.includes(includes);
    }
    if (regex) {
      const reg = new RegExp(regex.pattern, regex.flags);
      return reg.test(conditionValue);
    }
  });

  return valid;
};

export default checkConditions;
