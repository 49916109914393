import { gql } from '@apollo/client';
import axios from 'axios';

import clientApollo from './clientApollo';
import { URL } from './variables';

import { API_KEY } from '~/common/helpers/environment';

const audit = {
  getExportAuditFile: async ({
    realm,
    description = '',
    email = '',
    method = '',
    dateFrom = '',
    dateTo = '',
    service = '',
    objectId = '',
    status = '',
    sourceIp = '',
    environment = '',
    token
  }) => {
    try {
      const params = new URLSearchParams({
        description,
        email,
        method,
        dateFrom,
        dateTo,
        service,
        objectId,
        status,
        sourceIp,
        environment,
      });
      const { data } = await axios.post(
        `${URL}/runtime/realms/${realm}/audit/export?${params.toString()}`,
        {},
        {
          responseType: 'blob',
          headers: {
            authorization: token,
            apikey: API_KEY
          }
        }
      );

      return data;
    } catch (error) {
      if (error instanceof Error) throw Error(error.message);
      throw error;
    }
  },
  find: params => clientApollo.query({
    query: gql`
        query audit(
          $realm: String!
          $description: String
          $email: String
          $method: String
          $dateFrom: String
          $dateTo: String
          $page: Int
          $service: String
          $objectId: String
          $status: String
          $sourceIp: String
          $environment: String
        ) {
          audit(
            realm: $realm
            description: $description
            email: $email
            method: $method
            dateFrom: $dateFrom
            dateTo: $dateTo
            page: $page
            service: $service
            objectId: $objectId
            status: $status
            sourceIp: $sourceIp
            environment: $environment
          ) {
            content {
              id
              dateStart
              entityRealm
              platformUser
              description
              method
              objectId
              status
              objectIdFriendlyLabel
              service
              fullName
              email
              sourceIp
              environment
            }
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
    variables: {
      ...params
    }
  }),
  findServices: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query services($search: JSON) {
            services(search: $search)
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default audit;
