import styled, { css } from 'styled-components';

export const SwitchRoot = styled.span`
  width: 58px;
  height: 38px;
  display: inline-flex;
  padding: 12px;
  z-index: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  vertical-align: middle;
  margin-left: -9px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `};
`;

SwitchRoot.displayName = 'SwitchRoot';

export const SwitchButton = styled.span`
	top: 0;
	left: 0;
	color: ${({ theme }) => theme.colors.white};
	z-index: 1;
	position: absolute;
	transition:
		left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 9px;
	flex: 0 0 auto;
	overflow: visible;
	font-size: 1.5rem;
	text-align: center;
	border-radius: 50%
	border: 0;
	cursor: pointer;
	margin: 0;
	display: inline-flex;
	outline: 0;
	align-items: center;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	justify-content: center;
	text-decoration: none;
	background-color: transparent;
	-webkit-appearance: none;

	&:hover {
		background-color: ${({ theme }) => `${theme.colors.gra900}0A`};
  	}

	${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.colors.gra25};
    `};

	${({ checked, theme }) =>
    checked &&
    css`
      transform: translateX(20px);
      color: ${theme.colors.pri500};
    `};
`;

SwitchButton.displayName = 'SwitchButton';
SwitchButton.defaultProps = {
  'data-testid': 'button'
};

export const SwitchTrack = styled.span`
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gra400};

  ${({ checked, theme }) =>
    checked &&
    css`
      background-color: ${theme.colors.pri100};
    `};
`;

SwitchTrack.displayName = 'SwitchTrack';

export const SwitchButtonContent = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
`;

SwitchButtonContent.displayName = 'SwitchButtonContent';

export const SwitchThumb = styled.span`
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  background-color: currentColor;
`;

SwitchThumb.displayName = 'SwitchThumb';

export const Input = styled.input`
  top: 0;
  left: -100%;
  width: 300%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

Input.displayName = 'Input';
Input.defaultProps = {
  'data-testid': 'checkbox'
};
