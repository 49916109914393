import { isApolloError, gql } from '@apollo/client';

import clientApollo from './clientApollo';

import { Consumer } from '~/entities/Consumer';

export type ConsumerFetchAllData = {
  realm: string;
  environment: string;
  search: string;
};

export type ConsumerFindAllResult = {
  find: Consumer[];
};

export type PipelinesToAclsData = {
  realm: string;
};
export type ConsumerSaveData = {
  realm: string;
  consumer: string;
  environment: string;
  acls: string[];
  description: string;
};
export type ConsumerSaveResult = {
  create: Consumer;
};

export type ApiKeySaveData = {
  realm: string;
  consumer: string;
  environment: string;
};

export type ApiKeyEditData = {
  realm: string;
  consumer: string;
  environment: string;
  acls: string[];
  description: string;
  changeAcls: string[];
};

export type ConsumerRemoveData = {
  realm: string;
  consumerId: string;
  environment: string;
};

export type ApiKeyRemoveData = {
  realm: string;
  consumer: string;
  environment: string;
  apikeyId: string;
};

export type BasicAuthSaveData = {
  realm: string;
  consumer: string;
  username: string;
  password: string;
  environment: string;
};

export type BasicAuthRemoveData = {
  realm: string;
  consumer: string;
  username: string;
  environment: string;
};

export type BasicAuthValidateName = {
  realm: string;
  username: string;
  environment: string;
};

export type CheckConsumerName = {
  realm: string;
  name: string;
  environment: string;
};

const consumers = {
  find: ({ realm, search, environment }: ConsumerFetchAllData) =>
    clientApollo.query<ConsumerFindAllResult>({
      query: gql`
        query apiKeys($realm: String!, $search: String, $environment: String!) {
          apiKeys(realm: $realm, search: $search, environment: $environment) {
            id
            acls
            customId
            apiKeys {
              apiKey
              idKeyAuth
              createdAt
            }
            basicAuths {
              username
              created_at
            }
            description
          }
        }
      `,
      variables: {
        realm,
        search,
        environment
      }
    }),

  findConsumerApiKeys: ({ realm, search, environment }: ConsumerFetchAllData) =>
    clientApollo.query<ConsumerFindAllResult>({
      query: gql`
        query consumerApiKeys(
          $realm: String!
          $search: String
          $environment: String!
        ) {
          consumerApiKeys(
            realm: $realm
            search: $search
            environment: $environment
          ) {
            id
            acls
            customId
            apiKeys {
              id
              key
              created_at
            }
            basicAuths {
              username
              created_at
            }
            description
          }
        }
      `,
      variables: {
        realm,
        search,
        environment
      }
    }),
  findAcls: ({ realm }: PipelinesToAclsData) =>
    clientApollo.query({
      query: gql`
        query pipelines($realm: String!) {
          pipelines(realm: $realm) {
            name
            description
          }
        }
      `,
      variables: {
        realm
      }
    }),
  create({
    realm,
    consumer,
    environment,
    acls,
    description
  }: ConsumerSaveData) {
    return clientApollo.mutate<ConsumerSaveResult>({
      mutation: gql`
        mutation createApiKeys(
          $realm: String!
          $consumer: String!
          $environment: String!
          $acls: [String]
          $description: String
        ) {
          createApiKeys(
            realm: $realm
            consumer: $consumer
            environment: $environment
            acls: $acls
            description: $description
          )
        }
      `,
      variables: {
        realm,
        consumer,
        environment,
        acls,
        description
      }
    });
  },
  createApiKey({ realm, consumer, environment }: ApiKeySaveData) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createApiKey(
          $realm: String!
          $consumer: String!
          $environment: String!
        ) {
          createApiKey(
            realm: $realm
            consumer: $consumer
            environment: $environment
          ) {
            apiKey
            idKeyAuth
          }
        }
      `,
      variables: {
        realm,
        consumer,
        environment
      }
    });
  },
  edit({
    realm,
    consumer,
    environment,
    acls,
    description,
    changeAcls
  }: ApiKeyEditData) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editApiKeys(
          $realm: String!
          $consumer: String!
          $environment: String!
          $acls: [String]
          $description: String
          $changeAcls: Boolean
        ) {
          editApiKeys(
            realm: $realm
            consumer: $consumer
            environment: $environment
            acls: $acls
            description: $description
            changeAcls: $changeAcls
          )
        }
      `,
      variables: {
        realm,
        consumer,
        environment,
        acls,
        description,
        changeAcls
      }
    });
  },
  async remove({ realm, consumerId, environment }: ConsumerRemoveData) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteApiKeys(
          $realm: String!
          $consumerId: String!
          $environment: String!
        ) {
          deleteApiKeys(
            realm: $realm
            consumerId: $consumerId
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        consumerId,
        environment
      }
    });
  },
  async removeApiKey({
    realm,
    consumer,
    environment,
    apikeyId
  }: ApiKeyRemoveData) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteApiKey(
          $realm: String!
          $consumer: String!
          $apikeyId: String!
          $environment: String!
        ) {
          deleteApiKey(
            realm: $realm
            consumer: $consumer
            apikeyId: $apikeyId
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        consumer,
        apikeyId,
        environment
      }
    });
  },
  async createBasicAuth({
    realm,
    consumer,
    username,
    password,
    environment
  }: BasicAuthSaveData) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createBasicAuth(
          $realm: String!
          $consumer: String!
          $environment: String!
          $basicAuth: BasicAuthInput
        ) {
          createBasicAuth(
            realm: $realm
            consumer: $consumer
            environment: $environment
            basicAuth: $basicAuth
          ) {
            id
            username
          }
        }
      `,
      variables: {
        realm,
        consumer,
        environment,
        basicAuth: {
          username,
          password
        }
      }
    });
  },
  async deleteBasicAuth({
    realm,
    consumer,
    username,
    environment
  }: BasicAuthRemoveData) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteBasicAuth(
          $realm: String!
          $consumer: String!
          $environment: String!
          $username: String!
        ) {
          deleteBasicAuth(
            realm: $realm
            consumer: $consumer
            environment: $environment
            username: $username
          )
        }
      `,
      variables: {
        realm,
        consumer,
        environment,
        username
      }
    });
  },
  async validateBasicName({
    realm,
    username,
    environment
  }: BasicAuthValidateName) {
    try {
      const request = await clientApollo.query({
        query: gql`
          query validateBasicName(
            $realm: String!
            $username: String!
            $environment: String!
          ) {
            validateBasicName(
              realm: $realm
              username: $username
              environment: $environment
            )
          }
        `,
        variables: {
          realm,
          environment,
          username
        },
        fetchPolicy: 'network-only'
      });
      return request.data.validateBasicName;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  async checkConsumerName({
    realm,
    name,
    environment
  }: CheckConsumerName) {
    return clientApollo.mutate({
      mutation: gql`
        mutation checkConsumerName(
          $realm: String!
          $name: String!
          $environment: String!
        ) {
          checkConsumerName(
            realm: $realm
            name: $name
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        name,
        environment,
      }
    });
  },
};

export default consumers;
