import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  danger?: boolean;
}>`
  font-family: ${props => props.theme.typography.fontFamily};
  input {
    border-width: ${props => (props.danger ? '2px' : '1px')};
    border-color: ${props =>
      props.danger ? props.theme.colors.red500 : 'unset'};
  }
  span {
    color: ${props =>
      props.danger ? props.theme.colors.red500 : props.theme.colors.gra700};
  }
  &:focus-within {
    input {
      border: 2px solid
        ${props =>
          props.danger ? props.theme.colors.red500 : props.theme.colors.pri500};
    }
    span {
      color: ${props => !props.danger && props.theme.colors.pri500};
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export const Input = styled.input`
  min-width: 40px;
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 14px;
  background-color: transparent;
  outline: 0;
  box-sizing: border-box;
  border: solid 1px ${props => props.theme.colors.gra600};
  &::before,
  &::after {
    box-sizing: border-box;
  }
`;

export const HelperText = styled.span`
  position: relative;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  box-sizing: border-box;
`;
