import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Project from '../Project';

import Nav from '~/components/SideNav';

const Container = styled(Nav.Container)`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.typography.fontFamily};
  padding-left: 8px;
  margin-top: 32px;
  z-index: 1;
  height: calc(100vh - 250px);
  overflow: auto;
  width: 208px;
`;

const NavStyled = styled(Nav)`
  padding-top: 52px;
`;

const SideBar = ({
  allIds,
  byId,
  current,
  onClick,
  setModal,
  onArchived,
  draggingId
}) => (
  <NavStyled>
    <Container>
      {allIds.map((item, index) => (
        <Project
          draggingId={draggingId}
          onArchived={onArchived}
          /* eslint-disable react/jsx-props-no-spreading */
          {...byId[item]}
          current={current}
          onClick={id => onClick(id)}
          setModal={setModal}
          index={index}
        />
      ))}
    </Container>
  </NavStyled>
);

SideBar.defaultProps = {
  allIds: PropTypes.arrayOf([]),
  byId: PropTypes.objectOf({}),
  current: null,
  onClick: () => {},
  setModal: () => {},
  onArchived: () => {},
  draggingId: null
};

SideBar.propTypes = {
  allIds: PropTypes.arrayOf([PropTypes.string]),
  byId: PropTypes.objectOf({}),
  current: PropTypes.string,
  onClick: PropTypes.func,
  setModal: PropTypes.func,
  onArchived: PropTypes.func,
  draggingId: PropTypes.string
};

export default SideBar;
