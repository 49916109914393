import { Button, theme } from '@digibee/beehive-ui';

export const StyledFields = theme.styled('div', {
  marginTop: '10px',
  marginBottom: '4px',
});

export const ButtonsWrapper = theme.styled('div', {
  marginTop: '$3',
  marginBottom: '4px',
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column wrap',
  gap: '$2',
});

export const StyledContainer = theme.styled('div', {
  width: '100%',
});

export const StyledMessage = theme.styled('div', {
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
  textAlign: 'left',
  lineHeight: '1.5',
  paddingTop: '10px',
  paddingBottom: '$6',
  fontFamily: '$display',
});

export const StyledButton = theme.styled(Button, {
  width: '100%',
  justifyContent: 'center',
});
