import {
  faCalendar,
  faFileSignature,
  faGears
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { memo } from 'react';

import { Infos } from './components/StatusBody';
import {
  GenericCardRuntime,
  GenericCardRuntimeProps
} from './GenericCardRuntime';

import i18n from '~/common/helpers/i18n';

type EventCardRuntimeProps = GenericCardRuntimeProps;

export const EventCardRuntime = memo((props: EventCardRuntimeProps) => {
  const infos: Infos = [
    [
      {
        icon: faCalendar,
        value: moment(props.deployment.startTime, 'x').format(
          'DD/MM/YYYY HH:mm:ss'
        )
      },
      {
        icon: faGears,
        title: i18n.t('label.engine_version'),
        value: props.disabledMenu
          ? null
          : props.deployment.deploymentStatus?.pipelineEngineVersion?.replace(
              /^([^.]*\.[^.]*\.[^.]*).*/,
              '$1'
            )
      }
    ],
    [
      {
        icon: faFileSignature,
        title: i18n.t('scenes.runtime.labels.event_name'),
        value: props.deployment.pipeline.triggerSpec.eventName
      }
    ]
  ];

  return <GenericCardRuntime {...props} infos={infos} />;
});
