/* eslint-disable react/jsx-no-undef */
import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Locals
import IconNative from '~/components/IconCommunity';

const Container = styled.div`
  background-color: ${p => p.theme.colors.blu50};
  width: ${p => p.width};

  ${({ collapsable }) =>
    collapsable &&
    css`
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    `};

  ${({ type }) =>
    type === 'success' &&
    css`
      background-color: ${p => p.theme.colors.gre50};
    `};

  ${({ type }) =>
    (type === 'warning' || type === 'warn') &&
    css`
      background-color: ${p => p.theme.colors.yel50};
    `};

  ${({ type }) =>
    type === 'error' &&
    css`
      background-color: ${p => p.theme.colors.red50};
    `};

  ${({ type }) =>
    type === 'hybrid' &&
    css`
      background-image: -webkit-linear-gradient(
        -265deg,
        ${p => p.theme.colors.yel50} 50%,
        ${p => p.theme.colors.red50} 50%
      );
    `};

  ${({ type, theme }) =>
    type === 'infoWarn' &&
    css`
      background-color: ${theme.colors.gra25};
    `};
`;

const Header = styled.div`
  height: 70px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  ${({ collapsable }) =>
    collapsable &&
    css`
      cursor: pointer;
    `};

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0px 16px;
      height: 40px;
    `};

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 0px 16px;
      height: 90px;
    `};
`;

const Text = styled.div`
  width: 100%;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${p => p.theme.colors.gra900};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Collapsable = styled.div`
  overflow: auto;
  width: 100%;
  padding: 16px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  color: ${p => p.theme.colors.gra900};
  box-sizing: border-box;
`;

const Right = styled.div`
  margin-left: auto;
  padding-left: 24px;
  display: flex;
  align-items: center;
  height: 15px;
`;

const ToggleIcon = styled(IconNative)`
  font-size: 16px;
  width: 16px !important;
  ${props =>
    props.rotate &&
    css`
      transform: rotate(180deg);
    `};
`;

const Icon = styled(IconNative)`
  width: 24px !important;
  font-size: 24px;
  margin-right: 24px;
  color: ${p => p.theme.colors.blu700};

  ${({ type }) =>
    type === 'success' &&
    css`
      color: ${p => p.theme.colors.gre900};
    `};

  ${({ type }) =>
    (type === 'warning' || type === 'warn') &&
    css`
      color: ${p => p.theme.colors.yel900};
    `};

  ${({ type }) =>
    type === 'error' &&
    css`
      color: ${p => p.theme.colors.red700};
    `};

  ${({ type }) =>
    type === 'hybrid' &&
    css`
      color: ${p => p.theme.colors.red700};
    `};

  ${({ type }) =>
    type === 'infoWarn' &&
    css`
      color: ${p => p.theme.colors.gra600};
    `};
`;

const Alert = ({
  text,
  type,
  icon,
  iconType,
  collapsable,
  content,
  width,
  size,
  right,
  ...props
}) => {
  const [opened, setOpened] = useState(false);

  const getIconName = () => {
    if (icon) return icon;
    const icons = {
      info: 'InfoCircle',
      infoWarn: 'InfoCircle',
      success: 'CheckCircle',
      warning: 'ExclamationTriangle',
      warn: 'ExclamationTriangle',
      error: 'ExclamationCircle',
      hybrid: 'ExclamationCircle'
    };

    return icons[type];
  };

  const iconName = getIconName();

  const toggleAccordion = () => {
    const value = !opened;
    setOpened(value);
  };

  return (
    <Container
      width={width}
      type={type}
      title={text}
      collapsable={collapsable}
      {...props}
    >
      <Header collapsable={collapsable} onClick={toggleAccordion} size={size}>
        <Icon type={type} name={iconName} faType={iconType} />
        <Text>{text}</Text>
        {collapsable && (
          <Right>
            <ToggleIcon name='AngleDown' rotate={opened} />
          </Right>
        )}
        {!collapsable && right && <Right>{right}</Right>}
      </Header>
      {iff(opened && collapsable, () => (
        <Collapsable width={width} opened={opened}>
          {content}
        </Collapsable>
      ))}
    </Container>
  );
};

Alert.defaultProps = {
  type: 'info',
  iconType: 'light',
  collapsable: false,
  width: '488px',
  opened: undefined,
  size: 'medium'
};

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'info',
    'infoWarn',
    'success',
    'warning',
    'error',
    'hybrid',
    'warn'
  ]),
  icon: PropTypes.string.isRequired,
  collapsable: PropTypes.bool,
  content: PropTypes.node.isRequired,
  iconType: PropTypes.oneOf(['solid', 'light']),
  width: PropTypes.string,
  opened: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  right: PropTypes.node.isRequired
};

export default Alert;
