/* global window */

import React, { useContext } from 'react';
import { remove } from 'lodash';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Mousetrap from 'mousetrap';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export async function paste(next) {
  const clipboardEvent = await new Promise(resolve => {
    const listener = ev => {
      const value = (ev.clipboardData || window.clipboardData).getData('text');

      resolve({ ev, value });

      ev.preventDefault();
      ev.stopPropagation();

      window.removeEventListener('paste', listener, true);

      return false;
    };

    window.addEventListener('paste', listener, true);
  });

  return next(clipboardEvent);
}

class Shortcuts {
  constructor() {
    this.callbacks = [];
    this.scope = Mousetrap();

    // eslint-disable-next-line max-len
    this.scope.stopCallback = (ev, e, combo) =>
      this.callbacks.reduce((v, fn) => fn(ev, e, combo, v), false);

    this.context = React.createContext({ name: 'default', scope: this.scope });
  }

  getScope = () => this.scope;

  rules = {
    add: callback => {
      if (typeof callback === 'function') this.callbacks.push(callback);
    },
    inner: fn => {
      this.callbacks = fn(this.callbacks);
    },
    remove: fn => {
      remove(this.callbacks, item => item === fn);
    },
    restore: fn => {
      this.callback = [fn];
    }
  };

  useShortcuts = () => useContext(this.context);

  withShortcuts = (name, stopCallback) => {
    this.scope.rules = this.rules;

    if (typeof stopCallback === 'function') this.scope.rules.add(stopCallback);

    return WrappedComponent => {
      const ShortcutsContext = this.context;

      const WithShortcuts = props => {
        const { scope } = useContext(this.context);

        if (typeof name !== 'string')
          return <WrappedComponent {...props} shortcuts={{ scope, paste }} />;

        return (
          <ShortcutsContext.Provider value={{ scope }}>
            <WrappedComponent {...props} />
          </ShortcutsContext.Provider>
        );
      };

      WithShortcuts.displayName = `WithShortcuts(${getDisplayName(
        WrappedComponent
      )})`;

      return hoistNonReactStatics(WithShortcuts, WrappedComponent);
    };
  };
}

const shortcuts = new Shortcuts();

export const { useShortcuts, withShortcuts } = shortcuts;

export default shortcuts;
