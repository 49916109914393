import { Box, Text as DSText } from '@digibee/beehive-ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './OldDetails.elements';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Text from '~/components/Text';

const OldDetails = ({ name, result, disabled, title, ...props }) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);

  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  if (showDesignSystemComponents) {
    return (
      <Box css={{ marginTop: '$3' }}>
        <Elements.DetailsContainer title={title} {...props}>
          <DSText css={{ fontSize: '$2' }}>{name}</DSText>
          <DSText
            css={{ color: '$pri500', fontWeight: '$medium', fontSize: '$4' }}
          >
            {result}
          </DSText>
        </Elements.DetailsContainer>
      </Box>
    );
  }

  return (
    <Elements.DetailsContainer title={title} {...props}>
      <Text as='span' size='sm' type={disabled ? 'disabled' : 'secondary'}>
        {name}
      </Text>
      <Elements.DetailsDescription
        weight='medium'
        as='span'
        disabled={disabled}
      >
        {result}
      </Elements.DetailsDescription>
    </Elements.DetailsContainer>
  );
};

OldDetails.defaultProps = {
  name: '',
  result: '',
  disabled: false,
  title: ''
};

OldDetails.propTypes = {
  name: PropTypes.string,
  result: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string
};

export default OldDetails;
