/* eslint-disable camelcase */
import { isApolloError, gql } from '@apollo/client';
import { FlowSpec } from '@digibee/flow';

import clientApollo from './clientApollo';

import { AIMessageData } from '~/scenes/AI/components/AIChat/AIChat.machine';

type GetNextComponentParams = {
  realm?: string;
  triggerName?: string;
  sequence?: string[];
  params?: {
    trigger?: string;
    connectors?: string[];
    isTargetOf?: string;
    isOtherwiseOf?: string;
    isOnProcessOf?: string;
    isOnExceptionOf?: string;
    pipelineEndpointId?: string;
    flowspec?: FlowSpec;
  };
};

type ExecutionMessages = {
  id: string;
  body: string;
  processorStepName: string;
  processorType: string;
  elapsed: number;
  connectorId: string;
  connectorName: string;
  timestampStart: number;
  timestampEnd: number;
  indexes: object;
};

type ExecutionLogs = {
  id: string;
  pipelineName: string;
  logMessage: string;
  timestamp: string;
  loglevel: string;
};

export type Insights = {
  type: string;
  message: string;
  details: string;
  connectorId: string;
};

type SendFlowToInsightsParams = {
  flowSpec: FlowSpec;
  executionMessages?: ExecutionMessages[];
  executionLogs?: ExecutionLogs[];
  executionInput?: object;
  executionOutput?: object;
  realm: string;
};

type NextComponentSuggestion = {
  class: string;
  probability: number;
};
type GetNextComponentResponse = {
  aiNextComponentSuggestions: NextComponentSuggestion[];
};

type GetFlowSuggestionResponse = {
  aiGetFlowSuggestion: {
    connectorName: string;
    flowspec: FlowSpec;
  };
};
type SendFlowToInsightsResponse = {
  aiSendFlowToInsights: {
    insights: Insights[];
  };
};

export type AIMessage = {
  actions?: Record<string, unknown>[];
  triggers?: Record<string, unknown>[];
  data?: AIMessageData;
  sender: string;
  feedback?: 'like' | 'dislike';
  text: string;
  off_topic?: boolean;
};

export type SendChatMessageParams2 = {
  realm: string;
  target: string;
  lang: string;
  history?: AIMessage[];
  message: AIMessage;
};

type SendChatMessageResponse2 = {
  aiSendChatMessage: AIMessage;
};

const designAIAssistant = {
  sendAIChatMessage: async ({
    lang,
    message,
    realm,
    target,
    history
  }: SendChatMessageParams2) => {
    try {
      const result = await clientApollo.mutate<SendChatMessageResponse2>({
        mutation: gql`
          mutation aiSendChatMessage($payload: AIAssistantPayload!) {
            aiSendChatMessage(payload: $payload)
          }
        `,
        variables: {
          payload: {
            lang,
            message,
            realm,
            target,
            history
          }
        }
      });

      if (!result.data) throw Error('No data returned from server');

      return result.data.aiSendChatMessage;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  getNextComponentSuggestions: async ({
    realm,
    triggerName,
    sequence
  }: GetNextComponentParams) => {
    try {
      const result = await clientApollo.query<GetNextComponentResponse>({
        query: gql`
          query aiNextComponentSuggestions(
            $realm: String!
            $triggerName: String
            $sequence: [String]
          ) {
            aiNextComponentSuggestions(
              realm: $realm
              triggerName: $triggerName
              sequence: $sequence
            ) {
              class
              probability
            }
          }
        `,
        variables: {
          realm,
          triggerName,
          sequence
        }
      });

      return result.data.aiNextComponentSuggestions;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getFlowSuggestion: async ({
    triggerName,
    sequence,
    signal,
    params,
    realm
  }: GetNextComponentParams & { signal?: AbortSignal }) => {
    try {
      const result = await clientApollo.query<GetFlowSuggestionResponse>({
        query: gql`
          query aiGetFlowSuggestion(
            $realm: String!
            $triggerName: String
            $sequence: [String]
            $params: AIAutocompletePayload
          ) {
            aiGetFlowSuggestion(
              realm: $realm
              triggerName: $triggerName
              sequence: $sequence
              params: $params
            ) {
              connectorName
              flowspec
            }
          }
        `,
        variables: {
          triggerName,
          sequence,
          params,
          realm
        },
        context: {
          fetchOptions: {
            signal
          }
        }
      });

      return result.data.aiGetFlowSuggestion;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  sendFlowToInsights: async ({
    flowSpec,
    executionMessages,
    executionLogs,
    executionInput,
    executionOutput,
    realm
  }: SendFlowToInsightsParams) => {
    try {
      const result = await clientApollo.mutate<SendFlowToInsightsResponse>({
        mutation: gql`
          mutation aiSendFlowToInsights(
            $realm: String
            $flowSpec: JSON
            $executionMessages: [JSON]
            $executionLogs: [JSON]
            $executionInput: JSON
            $executionOutput: JSON
          ) {
            aiSendFlowToInsights(
              realm: $realm
              flowSpec: $flowSpec
              executionMessages: $executionMessages
              executionLogs: $executionLogs
              executionInput: $executionInput
              executionOutput: $executionOutput
            )
          }
        `,
        variables: {
          realm,
          flowSpec,
          executionMessages,
          executionLogs,
          executionInput,
          executionOutput
        }
      });
      return result.data?.aiSendFlowToInsights;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default designAIAssistant;
