import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  buttonLoading: boolean,
  idpLoginUrl: string
}

const enhancer = withHooks(() => {
  const [buttonReady, setButtonReady] = useState<boolean>(false);
  const { redirectUrl } = useSelector(({ authentication }: StoreRootState) => ({
    redirectUrl: authentication.realmParams?.redirectUrl,
  }));
  const { realm } = useSelector(({ application }: StoreRootState) => ({
    realm: application.subdomain,
  }));
  const dispatch = useDispatch<StoreDispatcher>();

  useEffect(() => {
    if (!redirectUrl) {
     dispatch.authentication.getNotLoggedPlatformParameter({ realm, param: 'hasCustomerIdentityProvider' });
    }
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      setButtonReady(true);
    } else {
      setButtonReady(false);
    }
  }, [redirectUrl]);

  return {
    buttonLoading: !buttonReady,
    idpLoginUrl: redirectUrl
  } as Props;
});

export default enhancer;
