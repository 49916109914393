import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect, memo } from 'react';
import styled from 'styled-components';

const RippleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Ripple = styled.span`
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.3;
  animation-name: ripple;
  background-color: ${props => props.color};
  animation-duration: ${props => props.duration}ms;
  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
`;

const useDebouncedRippleCleanUp = (rippleCount, duration, cleanUpFunction) => {
  useLayoutEffect(() => {
    let bounce = null;
    if (rippleCount > 0) {
      clearTimeout(bounce);
      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration * 4);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

const Ripples = ({ duration, color }) => {
  const [ripples, setRipples] = useState([]);
  const [key, setKey] = useState(0);

  useDebouncedRippleCleanUp(ripples.length, duration, () => {
    setRipples([]);
    setKey(0);
  });

  const addRipple = event => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;

    setRipples([
      ...ripples,
      <Ripple
        duration={duration}
        color={color}
        key={key}
        style={{
          top: y,
          left: x,
          width: size,
          height: size
        }}
      />
    ]);
    setKey(key + 1);
  };

  return (
    <RippleContainer duration={duration} color={color} onMouseDown={addRipple}>
      {ripples}
    </RippleContainer>
  );
};

Ripples.defaultProps = {
  duration: 550,
  color: 'currentColor'
};

Ripples.propTypes = {
  duration: PropTypes.number,
  color: PropTypes.string
};

export default memo(Ripples);
