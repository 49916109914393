/* eslint-disable */

import { compose, withProps, lifecycle, withState } from 'recompose';
import { forEach } from 'lodash';

const withSubMenus = compose(
  withProps(() => {
    const subMenus = {};

    return {
      registerSubMenu: (name, element) => {
        subMenus[name] = {
          element
        };
      },
      getSubMenus: () => subMenus
    };
  }),
  withState('subMenusVisibility', 'setSubMenusVisibility', {}),
  withProps(({ getSubMenus, subMenusVisibility, setSubMenusVisibility }) => ({
    toggleSubMenu: name => event => {
      const newSubMenusVisibility = { ...subMenusVisibility };

      newSubMenusVisibility[name] = !newSubMenusVisibility[name];
      setSubMenusVisibility(newSubMenusVisibility);

      event.preventDefault();
      event.stopPropagation();
    },
    handleOutsideClick: event => {
      const subMenus = getSubMenus();
      const newSubMenusVisibility = { ...subMenusVisibility };

      forEach(subMenus, (value, key) => {
        if (value.element && !value.element.contains(event.target)) {
          newSubMenusVisibility[key] = false;
        }
      });

      setSubMenusVisibility(newSubMenusVisibility);
    },
    isSubMenuActive: key => !!subMenusVisibility[key]
  })),
  lifecycle({
    componentDidMount() {
      document.addEventListener('mousedown', this.props.handleOutsideClick);
    },
    componentWillUpdate() {
      document.removeEventListener('mousedown', this.props.handleOutsideClick);
    },
    componentDidUpdate() {
      document.addEventListener('mousedown', this.props.handleOutsideClick);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.handleOutsideClick);
    }
  })
);

export default withSubMenus;
