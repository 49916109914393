import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Table = styled.table`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  text-align: left;

  ${({ fixed }) =>
    fixed &&
    css`
      table-layout: fixed;
      width: 100%;
    `};
`;

Table.defaultProps = {
  'data-testid': 'table'
};

const Tr = styled.tr``;

Tr.defaultProps = {
  'data-testid': 'row'
};

const THead = styled.thead``;

THead.defaultProps = {
  'data-testid': 'head'
};

const TBody = styled.tbody`
  ${({ hover }) =>
    hover &&
    css`
      ${Tr} {
        &:hover {
          background-color: ${({ theme }) => theme.colors.gra25};
        }
      }
    `};
`;

TBody.defaultProps = {
  hover: true,
  'data-testid': 'body'
};

const overwrite = () => css`
  vertical-align: middle;
  font: revert;
`;

const Th = styled.th`
  ${overwrite};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
  height: 56px;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  font-weight: 500;
  ${({ width }) =>
    width &&
    css`
      width: ${p => p.width};
      @media (max-width: 1050px) {
        width: auto;
      }
    `};
  ${({ fixedRight }) =>
    fixedRight &&
    css`
      position: sticky;
      right: 0;
      background: ${p => p.theme.colors.white};
      border-left: 2px solid ${({ theme }) => theme.colors.gra25};
      z-index: 9;
      -moz-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      -webkit-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
    `};
  ${({ fixedLeft }) =>
    fixedLeft &&
    css`
      position: sticky;
      left: 0;
      background: ${p => p.theme.colors.white};
      border-right: 2px solid ${({ theme }) => theme.colors.gra25};
      z-index: 9;
      -moz-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      -webkit-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
}
  `};
`;

Th.defaultProps = {
  'data-testid': 'header'
};

const Td = styled.td`
  ${overwrite};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
  height: 48px;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors.gra200};
  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${p => p.overflow};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${p => p.width};
    `};
  ${({ fixedRight }) =>
    fixedRight &&
    css`
      position: sticky;
      right: 0;
      background: ${p => p.theme.colors.white};
      border-left: 2px solid ${({ theme }) => theme.colors.gra25};
      z-index: 9;
      -moz-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      -webkit-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
    `};
  ${({ fixedLeft }) =>
    fixedLeft &&
    css`
      position: sticky;
      left: 0;
      background: ${p => p.theme.colors.white};
      border-right: 2px solid ${({ theme }) => theme.colors.gra25};
      z-index: 9;
      -moz-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      -webkit-box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
      box-shadow: 0 0 9px -1px ${({ theme }) => theme.colors.gra200};
    `};
`;

Td.defaultProps = {
  'data-testid': 'cell'
};

const Container = styled.div`
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gra200};
  border-radius: 4px;
`;

const DataTable = ({ children, ...props }) => (
  <Container {...props} data-testid={null}>
    <Table {...props}>{children}</Table>
  </Container>
);

DataTable.defaultProps = {
  'data-testid': 'table'
};

DataTable.propTypes = {
  children: PropTypes.element.isRequired,
  'data-testid': PropTypes.string
};

export { DataTable as Table, THead, Tr, Th, TBody, Td };
