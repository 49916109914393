import { SideSheet, Box, Button, Input } from '@digibee/beehive-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import DialogLicense from './components/DialogLicense';
import TablePipelineDeploy from './components/TablePipelineDeploy';
import { TypeExecuteDeploymentPlan } from './ExecuteDeploy.enhancer';
import { DeploymentPlanContext } from '../context/DeploymentPlanContext';

import i18n from '~/common/helpers/i18n';

const ExecuteDeploy: React.FC<TypeExecuteDeploymentPlan> = ({
  deploymentPlanItems,
  saveData,
  loadingSave,
  changeMinorSize
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { application } = useSelector((state: any) => state);
  const { planSelected, selectPlan, loadingExecuteDeploy } = useContext(
    DeploymentPlanContext
  );

  useEffect(() => {
    if (planSelected === null) {
      navigate(
        `/${application?.activeRealm}/operation/run/deployment/deploymentPlan`
      );
    }
  }, [planSelected]);

  const { env } = params;
  const [openDialog, setOpenDialog] = useState(false);

  const isInValidPipeline = [];

  if (
    planSelected?.deploymentPlanItems &&
    planSelected?.deploymentPlanItems.length > 0
  ) {
    for (const item of planSelected?.deploymentPlanItems) {
      const isInValid =
        item.runtimeConfiguration.validationLicense?.length > 0 ||
        item.runtimeConfiguration.isValid?.length > 0;

      if (isInValid) {
        isInValidPipeline.push(item);
      }
    }
  }

  function onOpenDialog() {
    setOpenDialog(true);
  }

  function onCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <SideSheet
      open
      onClose={() => {
        selectPlan(null);
      }}
      header={i18n.t(
        env === 'prod'
          ? 'label.promote_deployment_plan'
          : 'label.edit_deployment_plan_minor_version'
      )}
      subHeader={i18n.t(
        env === 'prod'
          ? 'label.promote_deployment_plan_minor_version_explanation'
          : 'label.edit_deployment_plan_minor_version_explanation'
      )}
      data-testid='run-sidesheet-execute'
      size='medium'
    >
      <SideSheet.Section title={i18n.t('label.deployment_plan_information')}>
        <Box css={{ marginBottom: '$3' }}>
          <Input
            label={i18n.t('label.deployment_plan_name')}
            id='name'
            name='name'
            value={planSelected?.name}
            placeholder={i18n.t(
              'label.enter_deployment_plan_name_here_placeholder'
            )}
            css={{ marginBottom: '$2' }}
            disabled
          />
        </Box>
        <Box css={{ marginBottom: '$3' }}>
          <Input
            label={i18n.t('label.description')}
            id='description'
            name='description'
            value={planSelected?.description}
            placeholder={i18n.t(
              'label.enter_deployment_plan_name_here_placeholder'
            )}
            css={{ marginBottom: '$2' }}
            disabled
          />
        </Box>
      </SideSheet.Section>
      <TablePipelineDeploy
        loading={loadingExecuteDeploy}
        deploymentPlanItems={deploymentPlanItems}
        env={env || ''}
        changeMinorSize={changeMinorSize}
      />
      {openDialog && env && (
        <DialogLicense
          onClose={onCloseDialog}
          env={env}
          onDeploy={() => {
            onCloseDialog();
            saveData();
          }}
        />
      )}
      <SideSheet.Footer>
        <Button
          disabled={isInValidPipeline.length > 0 || loadingExecuteDeploy}
          onClick={() => onOpenDialog()}
          loading={loadingSave}
        >
          {env !== 'prod'
            ? i18n.t('common.actions.deploy')
            : i18n.t('action.promote')}
        </Button>
      </SideSheet.Footer>
    </SideSheet>
  );
};

export default ExecuteDeploy;
