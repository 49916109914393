import { Button, theme } from '@digibee/beehive-ui';

export const StyledContainer = theme.styled('form', {
  width: '100%'
});

export const StyledFields = theme.styled('div', {
  padding: '10px 0px',
});

export const SubmitButton = theme.styled(Button, {
  width: '100%',
  justifyContent: 'center',
});
