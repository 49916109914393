import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DataDeployment, Props } from './types';
import { DeploymentPlanContext } from '../context/DeploymentPlanContext';
import { DeploymentPlanItem } from '../types';

import withHooks from '~/common/hoc/withHooks';

const normalizeSize = (name: string) => {
  if (!name) return '';
  return name?.split('-')[0];
};

const enhancer = withHooks(() => {
  const { planSelected } = useContext(DeploymentPlanContext);
  const { application } = useSelector((state: any) => state);

  const [data, setData] = useState<DataDeployment[]>([]);

  useEffect(() => {
    if (planSelected) {
      const dataDeployments: DataDeployment[] = [];
      planSelected.deploymentPlanItems.forEach((dep: DeploymentPlanItem) => {
        dep.environmentConfiguration?.forEach(config => {
          dataDeployments.push({
            id: config.runtimeConfiguration.id,
            environment: config.environment.name,
            name: config.pipeline
              ? config.pipeline.name.toString()
              : dep.pipelineMajorVersion.name.toString(),
            version: config.pipeline
              ? `V${config.pipeline?.versionMajor}.${config.pipeline?.versionMinor}`
              : `V${dep.pipelineMajorVersion.majorVersion}`,

            size: normalizeSize(
              config.runtimeConfiguration.name
            )?.toUpperCase(),
            replicas: config.runtimeConfiguration.replicaCount,
            actualConsumers: config.runtimeConfiguration.actualConsumers,
            pipelineId: config.pipeline ? config.pipeline.id : '',
            canvasVersion: config.pipeline ? config.pipeline.canvasVersion : 0,
            status: config.deploymentPlanStatus
          });
        });
      });

      setData(dataDeployments);
    }
  }, [planSelected]);

  function redirect(idPipeline: string) {
    return window.open(
      `/${application?.activeRealm}/design/v2/pipelines/${idPipeline}`,
      '_blank'
    );
  }

  const props: Props = {
    realm: application?.activeRealm || '',
    redirect,
    data
  };

  return props;
});

export default enhancer;
