const countSize = {
  small: 1,
  medium: 2,
  large: 4
};

const getSize = size => {
  if (!size) return countSize.small;
  return countSize[size] ? countSize[size] : countSize.small;
};

const normalizeSizeName = name => {
  if (!name) return '';
  return name.split('-')[0].toLocaleLowerCase();
};

const calcLicenses = (name, replicaCount) => {
  const size = getSize(normalizeSizeName(name));
  return size * replicaCount;
};

export default calcLicenses;
