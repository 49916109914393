import styled, { keyframes } from 'styled-components';

import theme from '~/common/styled/theme';

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const Loader = styled.img`
  background-color: ${props => props.background};
  animation: ${blink} 2s linear infinite;
  padding: 30px;
  width: 50px;
`;

const StyledLoading = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Loading = props => (
  <StyledLoading>
    <Loader
      src={theme.images.pictureNewLogoDigibeeWithoutText}
      {...props}
      className='styledLoading'
    />
  </StyledLoading>
);

export default Loading;
