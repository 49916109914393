import { isApolloError, gql } from '@apollo/client';

import { PipelineVersion } from '~/entities/PipelineVersion';

import clientApollo from './clientApollo';

type FetchPipelineHistoryData = {
  realm: string;
  pipelineName: string;
  versionMajor: string;
};

type FetchPipelineHistoryResult = {
  pipelineVersion: PipelineVersion;
};

type RemovePipelineResult = {
  deletePipeline: boolean;
};

type ArchiveRemovePipelineHistoryData = {
  id: string;
  name: string;
  page: number;
  pipelineId: string;
  realm: string;
  size: number;
  versionMajor: number;
};

type ArchiveResult = {
  pipelineRestore: boolean;
};

type FindHistoryPipelineData = {
  page: number;
  pipelineName: string;
  realm: string;
  size: number;
  versionMajor: string;
};

type Content = {};

type Sort = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

type Pageable = {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
};

type FindHistoryPipelineResult = {
  content: Content[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  sort: Sort;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

type UpdatePipelineVersionMinorData = {
  pipelineId: string;
  pipelineName: string;
  versionMajor: number;
  realm: string;
};

type UpdatePipelineVersionMinorResult = {
  updatePipelineVersionMinor: string;
};

type FindPipelineDeployedData = {
  pipelineName: string;
  realm: string;
  versionMajor: string;
};

type VersionDeployedInAllEnvironments = {
  environment: string;
  id: string;
  name: string;
  pipelineId: string;
  versionMajor: number;
  versionMinor: number;
};

type FindPipelineDeployedResult = {
  versionDeployedInAllEnvironments: VersionDeployedInAllEnvironments[];
};

const pipelineHistory = {
  fetch: async (params: FetchPipelineHistoryData) => {
    try {
      const result = await clientApollo.query<FetchPipelineHistoryResult>({
        query: gql`
          query pipelineHistory(
            $realm: String!
            $pipelineName: String!
            $versionMajor: String!
          ) {
            pipelineHistory(
              realm: $realm
              pipelineName: $pipelineName
              versionMajor: $versionMajor
            ) {
              id
              name
              description
              versionMajor
              versionMinor
              disabled
              deployments
            }
          }
        `,
        variables: {
          ...params,
          versionMajor: params.versionMajor.toString()
        }
      });

      return result;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  remove: async (params: ArchiveRemovePipelineHistoryData) => {
    try {
      const response = await clientApollo.mutate<RemovePipelineResult>({
        mutation: gql`
          mutation deletePipeline($realm: String!, $pipelineId: String!) {
            deletePipeline(realm: $realm, pipelineId: $pipelineId)
          }
        `,
        variables: {
          ...params
        }
      });

      return response;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  archive: async (params: ArchiveRemovePipelineHistoryData) => {
    try {
      const response = await clientApollo.mutate<ArchiveResult>({
        mutation: gql`
          mutation pipelineRestore($realm: String!, $pipelineId: String!) {
            pipelineRestore(realm: $realm, pipelineId: $pipelineId)
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  updatePipelineVersionMinor: async (
    params: UpdatePipelineVersionMinorData
  ) => {
    try {
      const response =
        await clientApollo.mutate<UpdatePipelineVersionMinorResult>({
          mutation: gql`
            mutation updatePipelineVersionMinor(
              $realm: String!
              $pipelineId: String!
              $pipelineName: String!
              $versionMajor: Int!
            ) {
              updatePipelineVersionMinor(
                realm: $realm
                pipelineId: $pipelineId
                pipelineName: $pipelineName
                versionMajor: $versionMajor
              )
            }
          `,
          variables: {
            ...params
          }
        });

      return response;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  findHistoryPipeline: async (params: FindHistoryPipelineData) => {
    try {
      const result = await clientApollo.query<FindHistoryPipelineResult>({
        query: gql`
          query historyPipeline(
            $realm: String!
            $pipelineName: String!
            $versionMajor: String!
            $size: Int
            $page: Int
          ) {
            historyPipeline(
              realm: $realm
              pipelineName: $pipelineName
              versionMajor: $versionMajor
              size: $size
              page: $page
            )
          }
        `,
        variables: {
          ...params,
          versionMajor: params.versionMajor.toString()
        }
      });

      return result;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  findPipelineDeployed: async (params: FindPipelineDeployedData) => {
    try {
      const result = await clientApollo.query<FindPipelineDeployedResult>({
        query: gql`
          query versionDeployedInAllEnvironments(
            $realm: String!
            $pipelineName: String!
            $versionMajor: String!
            $projectName: String
          ) {
            versionDeployedInAllEnvironments(
              realm: $realm
              pipelineName: $pipelineName
              versionMajor: $versionMajor
              projectName: $projectName
            )
          }
        `,
        variables: {
          ...params
        }
      });

      return result;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);

      throw e;
    }
  }
};

export default pipelineHistory;
