import Flow, {
  ChoiceComponent,
  LevelComponent,
  ParallelComponent
} from '@digibee/flow';

export type ExpandedByIdMap = { [key: string]: boolean };

const getStartMapping = (flow: Flow, currentValue: ExpandedByIdMap) => {
  const getValue = (id: string): boolean =>
    id in currentValue ? currentValue[id] : true;
  const startLevel = flow.getLevel('start');

  const startDisconnectedGroupId = `start-disconnected-group`;

  const startDisconnectedIds = startLevel
    ?.disconnectedTracks()
    .map(track => track.name())
    .reduce((acc, id) => ({ ...acc, [id]: getValue(id) }), {});

  return {
    [startDisconnectedGroupId]: getValue(startDisconnectedGroupId),
    ...startDisconnectedIds
  };
};

const getLevelComponentMapping = (
  component: LevelComponent,
  currentValue: ExpandedByIdMap
) => {
  const getValue = (id: string): boolean =>
    id in currentValue ? currentValue[id] : true;

  const onProcessLevel = component.level('onProcess');
  const onExceptionLevel = component.level('onException');
  const onProcessLevelName = onProcessLevel?.name() || '';
  const onExceptionLevelName = onExceptionLevel?.name() || '';

  const onProcessDisconnectedGroupId = `${onProcessLevelName}-disconnected-group`;
  const onExceptionDisconnectedGroupId = `${onExceptionLevelName}-disconnected-group`;

  const onProcessDisconnectedIds = onProcessLevel
    ?.disconnectedTracks()
    .map(track => track.name())
    .reduce((acc, id) => ({ ...acc, [id]: getValue(id) }), {});
  const onExceptionDisconnectedIds = onExceptionLevel
    ?.disconnectedTracks()
    .map(track => track.name())
    .reduce((acc, id) => ({ ...acc, [id]: getValue(id) }), {});

  return {
    [onProcessLevelName]: getValue(onProcessLevelName),
    [onExceptionLevelName]: getValue(onExceptionLevelName),
    [onProcessDisconnectedGroupId]: getValue(onProcessDisconnectedGroupId),
    ...onProcessDisconnectedIds,
    [onExceptionDisconnectedGroupId]: getValue(onExceptionDisconnectedGroupId),
    ...onExceptionDisconnectedIds
  };
};

const getChoiceComponentMapping = (
  component: ChoiceComponent,
  currentValue: ExpandedByIdMap
) => {
  const getValue = (id: string): boolean =>
    id in currentValue ? currentValue[id] : true;

  const conditions = component.conditions();

  const mapping = conditions.all().reduce(
    (acc, currentCondition) => ({
      ...acc,
      [currentCondition.targetName()]: getValue(currentCondition.targetName())
    }),
    {}
  );

  return mapping;
};

const getParallelComponentMapping = (
  component: ParallelComponent,
  currentValue: ExpandedByIdMap
) => {
  const getValue = (id: string): boolean =>
    id in currentValue ? currentValue[id] : true;

  const executions = component.executions();

  const mapping = executions.all().reduce(
    (acc, currentExecution) => ({
      ...acc,
      [currentExecution.targetName()]: getValue(currentExecution.targetName())
    }),
    {}
  );

  return mapping;
};

export const isAllCollapsed = (flow: Flow, currentValue: ExpandedByIdMap) => {
  const startLevel = flow.getLevel('start');
  const choices = (
    startLevel
      ? startLevel.components(
          currentComponent => currentComponent instanceof ChoiceComponent
        )
      : []
  ) as ChoiceComponent[];

  const choicesMapping = choices.reduce(
    (acc, currentComponent) => ({
      ...acc,
      ...getChoiceComponentMapping(currentComponent, currentValue)
    }),
    {}
  );

  const parallels = (
    startLevel
      ? startLevel.components(
          currentComponent => currentComponent instanceof ParallelComponent
        )
      : []
  ) as ParallelComponent[];

  const parallelsMapping = parallels.reduce(
    (acc, currentComponent) => ({
      ...acc,
      ...getParallelComponentMapping(currentComponent, currentValue)
    }),
    {}
  );

  const levelComponents = (
    startLevel
      ? startLevel.components(
          currentComponent => currentComponent instanceof LevelComponent
        )
      : []
  ) as LevelComponent[];

  const levelComponentsMapping = levelComponents.reduce(
    (acc, currentComponent) => ({
      ...acc,
      ...getLevelComponentMapping(currentComponent, currentValue)
    }),
    {}
  );

  const levelMapping = {
    ...choicesMapping,
    ...parallelsMapping,
    ...levelComponentsMapping
  };

  const value = Object.values(levelMapping).every(v => v === false);

  return value;
};

const generateExpandedById = (
  flow: Flow,
  currentValue: ExpandedByIdMap
): ExpandedByIdMap => {
  const choices = flow.components(
    currentComponent => currentComponent instanceof ChoiceComponent
  ) as ChoiceComponent[];

  const choicesMapping = choices.reduce(
    (acc, currentComponent) => ({
      ...acc,
      ...getChoiceComponentMapping(currentComponent, currentValue)
    }),
    {}
  );

  const parallels = flow.components(
    currentComponent => currentComponent instanceof ParallelComponent
  ) as ParallelComponent[];

  const parallelsMapping = parallels.reduce(
    (acc, currentComponent) => ({
      ...acc,
      ...getParallelComponentMapping(currentComponent, currentValue)
    }),
    {}
  );

  const levelComponents = flow.components(
    currentComponent => currentComponent instanceof LevelComponent
  ) as LevelComponent[];

  const levelComponentsMapping = levelComponents.reduce(
    (acc, currentComponent) => ({
      ...acc,
      ...getLevelComponentMapping(currentComponent, currentValue)
    }),
    {}
  );

  const startMapping = getStartMapping(flow, currentValue);

  return {
    ...choicesMapping,
    ...parallelsMapping,
    ...levelComponentsMapping,
    ...startMapping
  };
};

export default generateExpandedById;
