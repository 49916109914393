import { func } from 'prop-types';
import styled from 'styled-components';

import i18n from '~/common/helpers/i18n';
import Button from '~/components/Button';

const StyledMessage = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: rgba(0, 0, 0, 0.38);
  padding-top: 10px;
  padding-bottom: 40px;
  font-family: ${props => props.theme.typography.fontFamily};
`;

const StyledContainer = styled.div`
  width: 100%;
`;

const TwoFactorAlert = ({ returnToSignIn }) => (
  <StyledContainer>
    <StyledMessage data-testid='governance-message-two-factor-page'>{i18n.t('label.if_you_dont_have_access_two_factor_contact_manager')}</StyledMessage>
    <Button data-testid='governance-return-to-sing-in-button-two-factor-page' fluid type='primary' onClick={returnToSignIn}>
      {i18n.t('scenes.login.actions.return_sign_in')}
    </Button>
  </StyledContainer>
);

TwoFactorAlert.propTypes = {
  returnToSignIn: func.isRequired
};

export default TwoFactorAlert;
