import React from 'react';

import { DeploymentSelect } from '../../Types';
import { DeployPipeline } from '../DeployPipeline';

interface DetailsPromoteProps {
  close: () => void;
  deploymentSelected: DeploymentSelect;
  realm: string;
  triggers: any[];
  find: () => void;
  editPipelinePath: () => void;
  create: () => void;
  changeEnvironment: () => void;
  remove: () => void;
}

const DetailsPromote: React.FC<DetailsPromoteProps> = ({
  close,
  deploymentSelected,
  realm,
  triggers,
  find,
  editPipelinePath,
  create,
  changeEnvironment,
  remove
}) => (
  <DeployPipeline
    close={close}
    realm={realm}
    environment={deploymentSelected.newEnvironment}
    createDeploy={create}
    loading={false}
    triggers={triggers}
    editPipelinePath={editPipelinePath}
    find={find}
    enviromentsOptions={deploymentSelected.enviromentsOptions}
    showPromote
    deploymentSelected={deploymentSelected}
    changeEnvironment={changeEnvironment}
    remove={remove}
    onCloseClick={close}
  />
);

export default DetailsPromote;
