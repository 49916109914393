import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Elements from './FormUserTemporaryLocked.elements';
import FooterLoginIssues from '../FooterLoginIssuesHelp';


function FormUserTemporaryLocked() {
  const { t } = useTranslation();

  return (
    <>
      <Elements.Container>
        <Elements.Title as='h4'>{t('label.blocked_login')}</Elements.Title>
        <Elements.Information as='p'>
          {t('label.login_blocked_explanation')}
          &nbsp;
          <Elements.StyledLink
            as={Link}
            to='/login/two-factor'
            data-testid='governance-temporary-locked-login-forgot-password-link-blocked-user-page'
          >
            {t('action.dont_have_access_to_two_factor_auth_link')}
          </Elements.StyledLink>
        </Elements.Information>
        <Elements.Information data-testid='governance-support-message-blocked-user-page' as='p'>
          {t('label.blocked_login_if_problems_persists_support_msg')}
        </Elements.Information>
      </Elements.Container>
      <FooterLoginIssues />
    </>
  );
}

export default FormUserTemporaryLocked;
