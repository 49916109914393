import { useState } from 'react';

import * as api from '~/api';

const useApi = (entity, method) => {
  const [argsCache, setArgsCache] = useState(null);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const apiMethod = api[entity][method];

  const fetch = async (...args) => {
    setArgsCache(args);
    setLoading(true);
    setCalled(true);
    try {
      const res = await apiMethod(...args);
      setResponse(res);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => fetch(...argsCache);

  return [response, fetch, loading, error, called, refetch];
};

export default useApi;
