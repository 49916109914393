import { Skeleton as DSSkeleton } from '@digibee/beehive-ui';
import React from 'react';
import styled, { css } from 'styled-components';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import CardNative from '~/components/Card';
import Skeleton from '~/components/Skeleton';

const Card = styled(CardNative)`
  display: flex;
  flex-direction: column;
  height: 198px;
  cursor: pointer;
  width: 100%;
  border: none;
  box-shadow: none;
  position: relative;
`;

const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.gra400};
  border-radius: 4px;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const Separator = styled.div`
  padding: 0 15px 0 0;
`;

const Actions = styled.div`
  display: flex;
`;

const SubHeader = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  align-items: center;
  margin-bottom: 9px;
`;

const Body = styled.div`
  margin-left: 16px;
  height: 52px;
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  justify-content: space-between;
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;
`;

const TextTitle = styled.div``;

interface PropsGrid {
  items?: number[];
  children: any;
}

const Grid = styled.div<PropsGrid>`
  margin: 0 auto;
  display: grid;
  grid-gap: 16px;
  padding-top: 24px;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));

  ${props =>
    props?.items && props?.items?.length <= 3
      ? css`
          @media (min-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (min-width: 1150px) {
            grid-template-columns: repeat(3, 1fr);
          }
          @media (min-width: 1550px) {
            grid-template-columns: repeat(4, 1fr);
          }
          @media (min-width: 2000px) {
            grid-template-columns: repeat(7, 1fr);
          }
        `
      : ''};
`;

const GridCard = styled.div`
  flex: 0 0 370px;
`;

type Props = {
  items?: number[];
};

const CardsLoading: React.FC<Props> = ({ items: itemsProps }) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const items = Array(itemsProps)
    .fill(undefined)
    .map((val, idx) => idx);

  const propsSkeleton: any = {
    variant: 'rect',
    height: '16px',
    width: '220px'
  };

  const propsSkeletonCircular: any = {
    variant: 'circular',
    height: '20px',
    width: '20px'
  };

  const propsSkeletonHeader: any = {
    variant: 'rect',
    height: '10px',
    width: '100px'
  };

  const propsSkeletonBody: any = {
    variant: 'rect',
    height: '10px',
    width: '50%'
  };

  const propsSkeletonFooter: any = {
    variant: 'rect',
    height: '44px',
    width: '20%'
  };

  return (
    <Grid items={itemsProps}>
      {showDesignSystemComponents ? (
        <>
          {items?.map(deployment => (
            <GridCard key={deployment}>
              <Card>
                <Container>
                  <Header>
                    <TextTitle>
                      <DSSkeleton
                        variant='rectangular'
                        sizes='medium'
                        style={{ ...propsSkeletonCircular, width: '100px' }}
                      />
                    </TextTitle>
                    <Actions>
                      <DSSkeleton
                        variant='rectangular'
                        sizes='medium'
                        style={{ ...propsSkeletonCircular }}
                      />
                    </Actions>
                  </Header>
                  <SubHeader>
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonHeader }}
                    />
                  </SubHeader>
                  <Body>
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonBody }}
                    />
                  </Body>
                  <Footer>
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonFooter }}
                    />
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonFooter }}
                    />
                    <Separator />
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonFooter }}
                    />
                    <Separator />
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonFooter }}
                    />
                    <Separator />
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonFooter }}
                    />
                  </Footer>
                </Container>
              </Card>
            </GridCard>
          ))}
        </>
      ) : (
        <>
          {items?.map(deployment => (
            <GridCard key={deployment}>
              <Card>
                <Container>
                  <Header>
                    <TextTitle>
                      <Skeleton {...propsSkeleton} />
                    </TextTitle>
                    <Actions>
                      <Skeleton {...propsSkeletonCircular} />
                    </Actions>
                  </Header>
                  <SubHeader>
                    <Skeleton {...propsSkeletonHeader} />
                  </SubHeader>
                  <Body>
                    <Skeleton {...propsSkeletonBody} />
                  </Body>
                  <Footer>
                    <Skeleton {...propsSkeletonFooter} />
                    <Skeleton {...propsSkeletonFooter} />
                    <Separator />
                    <Skeleton {...propsSkeletonFooter} />
                    <Separator />
                    <Skeleton {...propsSkeletonFooter} />
                    <Separator />
                    <Skeleton {...propsSkeletonFooter} />
                  </Footer>
                </Container>
              </Card>
            </GridCard>
          ))}
        </>
      )}
      {items?.map(deployment => (
        <GridCard key={deployment}>
          <Card>
            <Container>
              <Header>
                <TextTitle>
                  {showDesignSystemComponents ? (
                    <DSSkeleton
                      variant='rectangular'
                      sizes='medium'
                      style={{ ...propsSkeletonCircular }}
                    />
                  ) : (
                    <Skeleton {...propsSkeleton} />
                  )}
                </TextTitle>
                <Actions>
                  {showDesignSystemComponents ? (
                    <DSSkeleton
                      variant='circular'
                      sizes='medium'
                      style={{ ...propsSkeletonCircular }}
                    />
                  ) : (
                    <Skeleton {...propsSkeletonCircular} />
                  )}
                </Actions>
              </Header>
              <SubHeader>
                {showDesignSystemComponents ? (
                  <DSSkeleton
                    variant='rectangular'
                    sizes='medium'
                    style={{ ...propsSkeletonHeader }}
                  />
                ) : (
                  <Skeleton {...propsSkeletonHeader} />
                )}
              </SubHeader>
              <Body>
                {showDesignSystemComponents ? (
                  <DSSkeleton variant='circular' {...propsSkeletonBody} />
                ) : (
                  <Skeleton {...propsSkeletonBody} />
                )}
              </Body>
              <Footer>
                {showDesignSystemComponents ? (
                  <DSSkeleton variant='circular' {...propsSkeletonFooter} />
                ) : (
                  <Skeleton {...propsSkeletonFooter} />
                )}
                <Skeleton {...propsSkeletonFooter} />
                <Separator />
                <Skeleton {...propsSkeletonFooter} />
                <Separator />
                <Skeleton {...propsSkeletonFooter} />
                <Separator />
                <Skeleton {...propsSkeletonFooter} />
              </Footer>
            </Container>
          </Card>
        </GridCard>
      ))}
    </Grid>
  );
};

export default CardsLoading;
