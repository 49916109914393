import { connect } from 'react-redux';
import { compose } from 'recompose';

const mapStateToProps = () => ({});

const mapDispatchToProps = ({ forgotPassword }) => ({
  returnToSignIn: forgotPassword.returnToSignIn,
});

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhancer;
