import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import apiAccounts from '~/api/accounts'
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
// Locals
import getPropertiesInObject from '~/common/helpers/getPropertiesInObject';
import i18n from '~/common/helpers/i18n';
import withAcls from '~/common/hoc/withAcls';
import withHooks from '~/common/hoc/withHooks';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import { useHeader } from '~/components/HeaderProvider';

const getAccountsObject = values => {
  const formEnv = getPropertiesInObject(values, 'env-');
  const keysEnv = Object.keys(formEnv);

  const newObjectEnv = keysEnv.reduce(
    (result, key) => ({
      ...result,
      [key.replace('env-', '')]: formEnv[key]
    }),
    {}
  );

  const { category, description, field } = values;

  return {
    category,
    description,
    field,
    valuesByEnv: JSON.stringify(newObjectEnv)
  };
};

const mapStateToProps = ({ accounts, application }) => ({
  activeRealm: application.realm,
  allIds: accounts.result.accounts,
  byId: accounts.entities.accounts,
  loading: accounts.loading.accounts,
  modal: accounts.modal,
  environments: application?.environments?.allNames?.asMutable({ deep: true }),
  error: accounts.error,
  searchText: accounts.searchText
});

const mapDispatchToProps = ({ accounts, application }) => ({
  find: accounts.find,
  edit: accounts.edit,
  create: accounts.create,
  remove: accounts.remove,
  setModal: accounts.setModal,
  findEnvironments: application.findEnvironments,
  setSearchText: accounts.setSearchText,
  changeV2: accounts.changeV2
});

const initAccount = {
  id: '',
  category: '',
  field: '',
  description: ''
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAcls,
  withHooks(({ remove, create, edit, find, findEnvironments, searchText, environments, ...props }) => {
    const itensToFilter = [];
    const [accounts, setAccounts] = useState(props.allIds);
    const [loading, setLoading] = useState(props.loading);
    const [openSidesheetPipelines, setOpenSidesheetPipelines] =
      useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [pipelines, setPipelines] = useState(null);
    const [loadingPipelines, setLoadingPipelines] = useState(false);
    const [deployments, setDeployments] = useState(null);
    const { SETTINGS_ACCOUNTS_V2 } = featureFlagConstants
    const { treatments } = useFeatureFlag([
      SETTINGS_ACCOUNTS_V2,
    ]);

    const showAccountsV2 = treatments[SETTINGS_ACCOUNTS_V2].treatment === 'on';

    useEffect(() => {
      setAccounts(props.allIds);
    }, [props.allIds]);

    useEffect(() => {
      setLoading(props.loading);
    }, [props.loading]);

    async function getAccounts() {
      await find();
      await findEnvironments({ realm: props.activeRealm.realm });
    }

    async function getAccountsPaginated(page = 0) {
      setLoading(true)
      const { data } = await apiAccounts.findPaginated({ realm: props.activeRealm.realm, label: searchText || '', page })

      setAccounts(data.accountsPaginated)
      await findEnvironments({ realm: props.activeRealm.realm });
      setLoading(false)
    }

    useEffect(() => {
      props.changeV2(showAccountsV2)
      if (showAccountsV2) {
        getAccountsPaginated();
      } else {
        getAccounts();
      }
    }, [showAccountsV2]);

    useEffect(() => {
      if (showAccountsV2) {
        getAccountsPaginated();
      }
    }, [searchText]);

    useEffect(() => {
      if (showAccountsV2) {
        getAccountsPaginated();
      } else {
        getAccounts();
      }
    }, [props.modal.success]);

    async function getPipelinesByAccounts(
      page = 0,
      accountId = currentAccount.id
    ) {
      setLoadingPipelines(true);
      const { data } = await apiAccounts.findPaginatedaccountsPipelines({
        realm: props.activeRealm.realm,
        page,
        accountId
      });
      setPipelines(data.accountsAssociatedPipelines);
      setLoadingPipelines(false);
    }

    async function getDeploymentsByAccount(
      page = 0,
      accountId = currentAccount.id
    ) {
      setLoadingPipelines(true);
      const { data } = await apiAccounts.findPaginatedDeployments({
        realm: props.activeRealm.realm,
        page,
        accountId
      });
      setDeployments(data.accountsAssociatedDeployments);
      setLoadingPipelines(false);
    }

    async function getAccountById(
      accountId = currentAccount.id
    ) {

      const { data } = await apiAccounts.findById({
        realm: props.activeRealm.realm,
        accountId
      });

      return data?.accountById
    }

    useHeader({
      configActionButton: [
        {
          icon: 'ProjectDiagram',
          text: i18n.t('scenes.accounts.labels.account_plural'),
          role: ['ACCOUNT:CREATE'],
          action: () => {
            props.setModal({
              visible: true,
              account: null
            });
          }
        }
      ]
    });

    const confirm = {
      confirmDialog: {
        title: i18n.t('scenes.accounts.actions.confirm'),
        text: i18n.t(
          'scenes.accounts.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t('scenes.accounts.messages.prompt.delete_item', {
              item: i18n.t('scenes.accounts.labels.account')
            })}`
          }
        ),
        confirmText: i18n.t('scenes.accounts.actions.confirm'),
        cancelText: i18n.t('scenes.accounts.actions.cancel'),
        variant: 'danger',
        open: true
      }
    };
    const actions = {
      remove: account => remove(account.id, confirm),
      edit: async (account, propsRest) => {
        await edit({ params: getAccountsObject(account), props: propsRest });
      },
      openModal: async (account) => {
        if (showAccountsV2) {
          await getDeploymentsByAccount(0, account.id);
          const accountBydId = await getAccountById(account.id)
          props.setModal({ visible: true, account: accountBydId });
          return
        }
        props.setModal({ visible: true, account });
      },
      create: async (account, propsRest) => {
        await create({ params: getAccountsObject(account), props: propsRest });
      },
      closeSidesheetPipelineAssociate: () => {
        setOpenSidesheetPipelines(false);
        setCurrentAccount(null);
      },
      openSidesheetPipelineAssociate: async (account = {}) => {
        setOpenSidesheetPipelines(true);
        setCurrentAccount(account);
        getPipelinesByAccounts(0, account.id);
      },
    };

    return {
      ...props,
      ...actions,
      allIds: accounts,
      itensToFilter,
      initAccount,
      loading,
      showAccountsV2,
      getAccountsPaginated,
      currentAccount,
      openSidesheetPipelines,
      pipelines,
      loadingPipelines,
      getPipelinesByAccounts,
      deployments,
      getDeploymentsByAccount
    };
  })
);

export default enhancer;
