import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import withHooks from '~/common/hoc/withHooks';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

const enhancer = withHooks(() => {
  const navigate = useNavigate();
  const { error } = useSelector(({ authentication }: StoreRootState) => authentication);

  function backToLogin() {
    navigate('/login');
  }

  useEffect(() => {
    if (!error || !error.lockAuth || !error.email) backToLogin();
  }, [error]);
});

export default enhancer;
