import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

export const Content = styled.div`
  overflow-y: auto;
`;

Content.displayName = 'Content';

export const Grid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 16px;
  padding-top: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1750px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1008px) {
    grid-template-columns: 1fr;
  }
`;

Grid.displayName = 'Grid';

export const GridCard = styled.div`
  flex: 0 0 370px;
`;

GridCard.displayName = 'GridCard';

export const Container = styled.div`
  position: relative;
`;

Container.displayName = 'Container';

export const Top = styled.div`
  position: absolute;
  width: 50px;
  top: 24px;
`;

Top.displayName = 'Top';

export const Body = styled.div`
  overflow-y: auto;
  padding: 16px 16px;
  overflow-x: hidden;
  width: 100%;
`;

Body.displayName = 'Body';

export const ContentSideBar = styled.div`
  display: flex;
  height: calc(100vh - 165px);
  overflow: hidden;
`;

ContentSideBar.displayName = 'ContentSideBar';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(props => <FontAwesomeIcon {...props} />)`
  animation-duration: 1.65s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  font-size: 1rem;
`;

export const ContainerProjectName = styled.div`
  margin-bottom: 40px;
`;

export const Separator = styled.div`
  margin: 4px;
  font-size: 10px;
  color: ${props => props.theme.colors.gra500};
`;
