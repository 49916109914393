const useContainPaths =
  () =>
  (paths: string[] = []) =>
    paths?.reduce((result, path) => {
      if (!result) {
        return window.location.pathname.indexOf(path) > -1;
      }
      return result;
    }, false);

export default useContainPaths;
