/* eslint-disable */
import parseCookie from '~/common/helpers/parseCookie';

let type = 'cookie';

const storage = {
  get: (key, isJson = true) => types[type].get(key, (isJson = true)),
  set: (key, value) => types[type].set(key, value),
  remove: key => types[type].remove(key)
};

const types = {
  localstorage: {
    get: (key, isJson = true) =>
      isJson
        ? JSON.parse(localStorage.getItem(key))
        : localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
    remove: key => localStorage.removeItem(key)
  },
  cookie: {
    get: (key, isJson = true) => {
      try {
        const objectCookie = parseCookie(document.cookie);
        return isJson ? JSON.parse(objectCookie[key]) : objectCookie[key];
      } catch (error) {
        return null;
      }
    },
    set: (key, value, isJson = true) => {
      try {
        return (document.cookie = `${key}=${
          isJson ? JSON.stringify(value) : value
        }; path=/`);
      } catch (error) {
        return null;
      }
    },
    remove: key => {
      return (document.cookie = key + '=; expires=-1; path=/');
    }
  }
};

export default storage;
