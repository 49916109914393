import { uniqBy } from 'lodash';
import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  result: {
    components: {
      content: []
    },
    libraries: {
      content: []
    }
  },
  loading: {
    components: false,
    libraries: true,
    pushComponents: false
  },
  error: {
    components: null,
    libraries: null,
    deployLibraryComponent: null,
    archiveComponent: null
  },
  success: {
    archiveComponent: null,
    deployLibraryComponent: null
  },
  current: {
    libraries: ''
  }
});

const LibrariesModel = {
  name: 'libraries',
  state: initialState,
  reducers: {
    setResult(state, { path, value }) {
      return state.merge(
        {
          result: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setLoading(state, { path, value }) {
      return state.merge(
        {
          loading: {
            [path]: value
          }
        },
        {
          deep: true
        }
      );
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    setSuccess(state, { path, value }) {
      return state.merge({
        success: {
          [path]: value
        }
      });
    },
    setCurrent(state, { path, value }) {
      return state.merge({
        current: {
          [path]: value
        }
      });
    }
  },
  effects: dispatch => ({
    async fetchLibraries(params, state) {
      try {
        const { realm } = state.application.realm;
        const { data } = await api.libraries.fetchLibraries({ realm });
        dispatch.libraries.setResult({
          path: 'libraries',
          value: data.librariesPaginations
        });
      } catch (e) {
        dispatch.libraries.setError({ path: 'libraries', value: e.message });
      }
    },

    async fetchComponents(params, state) {
      try {
        const libraryId = params.id;
        const { realm } = state.application.realm;
        const { data } = await api.libraries.fetchComponents({
          libraryId,
          realm
        });

        dispatch.libraries.setResult({
          path: 'components',
          value: data.componentsByLibrary
        });
      } catch (e) {
        dispatch.libraries.setError({ path: 'components', value: e.message });
      }
    },

    async pushComponents(params, state) {
      const requestParams = {
        libraryId: params.id,
        ...params
      };
      const { realm } = state.application.realm;
      const { data } = await api.libraries.fetchComponents({
        realm,
        ...requestParams
      });
      const content = uniqBy(
        [
          ...state.libraries.result.components.content,
          ...data.componentsByLibrary.content
        ],
        item => item.id
      );

      dispatch.libraries.setResult({
        path: 'components',
        value: {
          ...data.componentsByLibrary,
          content
        }
      });
    },
    async archiveComponent({ idComponent }, state) {
      try {
        const { libraries: idLibrary } = state.libraries.current;
        const { realm } = state.application.realm;
        const result = await api.libraries.archiveComponent({
          idComponent,
          idLibrary,
          realm
        });
        toast.success(
          `${i18n.t(
            'scenes.libraries.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.libraries.messages.success.arquive_component'
              )}`
            }
          )}`
        );

        dispatch.libraries.setSuccess({
          path: 'archiveComponent',
          value: result
        });
      } catch (e) {
        toast.error(e.message);
        dispatch.libraries.setError({
          path: 'archiveComponent',
          value: e.message
        });
      }
    },
    async deployLibraryComponent({ idComponent }, state) {
      try {
        const { libraries: idLibrary } = state.libraries.current;
        const { realm } = state.application.realm;
        const result = await api.libraries.deployLibraryComponent({
          idComponent,
          idLibrary,
          realm
        });
        const { deployLibraryComponent } = result.data;
        dispatch.library.updatePipeline(deployLibraryComponent);
        toast.success(
          `${i18n.t(
            'scenes.libraries.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t('scenes.libraries.labels.deployed')}`
            }
          )}`
        );

        dispatch.libraries.setSuccess({
          path: 'deployLibraryComponent',
          value: result
        });
      } catch (e) {
        toast.error(e.message);
        dispatch.libraries.setError({
          path: 'deployLibraryComponent',
          value: e.message
        });
      }
    }
  }),
  logics: [
    {
      type: 'libraries/fetchLibraries',
      latest: true,
      process(context, dispatch, done) {
        dispatch.libraries.setLoading({ path: 'libraries', value: true });
        done();
      }
    },
    {
      type: 'libraries/pushComponents',
      latest: true,
      process(context, dispatch, done) {
        dispatch.libraries.setLoading({ path: 'pushComponents', value: true });
        done();
      }
    },
    {
      type: ['libraries/setResult'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.libraries.setError({
          path: context.action.payload.path,
          value: null
        });
        dispatch.libraries.setLoading({ path: 'pushComponents', value: false });
        done();
      }
    },
    {
      type: ['libraries/setResult', 'libraries/setError'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.libraries.setLoading({
          path: context.action.payload.path,
          value: false
        });
        dispatch.libraries.setLoading({ path: 'pushComponents', value: false });
        done();
      }
    },
    {
      type: ['libraries/fetchComponents'],
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.libraries.setCurrent({ path: 'libraries', value: payload.id });
        dispatch.libraries.setLoading({ path: 'components', value: true });
        done();
      }
    },
    {
      type: ['libraries/setSuccess'],
      latest: true,
      process({ getState }, dispatch, done) {
        const { libraries: id } = getState().libraries.current;
        dispatch.libraries.fetchComponents({ id });
        done();
      }
    }
  ]
};

export default LibrariesModel;
