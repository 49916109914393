import { Empty } from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import React from 'react';
import { Outlet } from 'react-router';

import Header from './components/Header';
import TableHistory from './components/TableHistory';
import * as Elements from './List.elements';
import { Props, SearchType } from '../types';

import i18n from '~/common/helpers/i18n';
import theme from '~/common/styled/theme';

const List: React.FC<Props> = ({
  projects,
  setParams,
  deployments,
  loading,
  changePage
}: Props) => (
  <Elements.Container>
    <Header
      fetch={(values: SearchType) => setParams(values)}
      projects={projects}
    />
    {choose(
      when(!loading && (!deployments || !deployments?.content?.length), () => (
        <Empty
          illustration={theme.images.emptyStateList}
          title={i18n.t('label.no_items_on_list')}
          data-testid='usageList-no-items-empty'
          buttonLoading={false}
        />
      )),
      otherwise(() => (
        /* eslint-disable-line */
        <TableHistory
          deployments={deployments}
          changePage={changePage}
          loading={loading}
        />
      ))
    )}
    <Outlet />
  </Elements.Container>
);

export default List;
