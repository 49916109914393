/* eslint-disable */

import { func } from 'prop-types';
export const CopyToClipboard = ({ renderCopy }) => {
  return renderCopy();
};

CopyToClipboard.propTypes = {
  renderCopy: func.isRequired
};
