import CloseIcon from 'react-feather/dist/icons/x';
import styled from 'styled-components';

export const Close = styled(CloseIcon)`
  color: ${props => props?.theme?.colors?.gra700};
`;

Close.displayName = 'Close';

export const Button = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  left: calc(-32px - 12px);
  position: absolute;
  top: 12px;
  width: 32px;
`;

Button.displayName = 'Button';
Button.defaultProps = {
  'data-testid': 'close-button'
};
