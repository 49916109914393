/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const stats = {
  find: ({ dateFrom, dateTo, environment, realm, interval, pipelineName, all = false }) => {
    return clientApollo.query({
      query: gql`
        query stats(
          $dateFrom: String!
          $dateTo: String!
          $realm: String!
          $interval: String!
          $environment: String!
          $pipelineName: String
          $all: Boolean
        ) {
          stats(
            realm: $realm
            environment: $environment
            interval: $interval
            dateTo: $dateTo
            dateFrom: $dateFrom
            pipelineName: $pipelineName
            all: $all
          ) {
            runtimeStats {
              stats {
                tag
                count
                countSuccess
                countError
                min
                max
                avg
                sum
              }
              dateHistogramStats {
                tag
                count
                countSuccess
                countError
                min
                max
                avg
                sum
              }
              deploymentStats {
                SERVICE_ACTIVE
                DEPLOYED
                EXPIRES
              }
              pipelineStats {
                pipelineVersionStats {
                  pipelineName
                  version
                  dateHistogramStats {
                    tag
                    count
                    countSuccess
                    countError
                    min
                    max
                    avg
                    sum
                  }
                  stats {
                    tag
                    count
                    countSuccess
                    countError
                    min
                    max
                    avg
                    sum
                  }
                }
              }
            }
            designTimeStats {
              pipelines
              accounts
              events
              scopes
            }
          }
        }
      `,
      variables: {
        realm,
        environment,
        dateFrom,
        dateTo,
        interval,
        pipelineName,
        all
      }
    });
  }
};

export default stats;
