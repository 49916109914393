import { theme, Icon } from '@digibee/beehive-ui';

/**
|--------------------------------------------------
| Structure
|--------------------------------------------------
*/

export const Container = theme.styled('div', {
  width: 'fit-content',
  display: 'flex',
  gap: '$2',
})

export const HelpIcon = theme.styled(Icon, {
  color: '$neu200',
  fontSize: '$2'
})