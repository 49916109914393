import Immutable from 'seamless-immutable';
import { acceptanceTerm } from '~/api';

const initialState = Immutable({
  modal: false,
  loading: true,
  result: null
});

const acceptanceTermModel = {
  name: 'acceptanceTerm',
  state: initialState,
  reducers: {
    setLoading(state, loading) {
      return state.merge({ loading });
    },
    setModal(state, modal) {
      return state.merge({ modal });
    },
    setTerm(state, result) {
      return state.merge({ result });
    },
    setTermResponse(state, result) {
      return state.merge({ result });
    }
  },
  effects: dispatch => ({
    async getLatest(params, state) {
      dispatch.acceptanceTerm.setLoading(true);
      const termType = params?.termType || 'eula';
      try {
        const { data } = await acceptanceTerm.getLatest({
          realm: state?.application?.realm?.realm,
          termType
        });

        dispatch.acceptanceTerm.setTerm(data?.termAcceptanceLatest);

        if (!data?.termAcceptanceLatest) return;

        const { lastPostponementDate, acceptanceDate, refusalDate, expired } =
          data?.termAcceptanceLatest;
        if (
          (!lastPostponementDate && !acceptanceDate && !refusalDate) ||
          expired
        ) {
          dispatch.eula.setModal(true);
        }
      } catch (e) {
        params?.onError?.(e);
      } finally {
        dispatch.acceptanceTerm.setLoading(false);
      }
    },
    async response({ response, onSuccess, onError }, state) {
      dispatch.acceptanceTerm.setLoading(true);
      try {
        const { data } = await acceptanceTerm.response({
          realm: state?.application?.realm?.realm,
          id: state?.acceptanceTerm?.result?.id,
          response
        });
        dispatch.acceptanceTerm.setTermResponse(data?.termAcceptanceResponse);
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        if (onError) {
          onError();
        }
      } finally {
        dispatch.acceptanceTerm.setLoading(false);
      }
    }
  })
};

export default acceptanceTermModel;
