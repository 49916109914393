import head from 'lodash/head';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';

import SizePipelineLoading from './SizePipelineLoading';
import SizeConsumer from '../SizeConsumer';

import RadioGroup from '~/components/RadioGroup';

const normalizeNameConfiguration = name => head(name?.split('-'));

const SizePipeline = ({ configurations, onChange, value, hidden, loading }) => {
  const handerChange = (event, id) => {
    if (isFunction(onChange)) {
      onChange(event, id);
    }
  };
  if (loading) {
    return <SizePipelineLoading length={3} />;
  }
  if (hidden) {
    return null;
  }

  return (
    <RadioGroup row name='configuration' onChange={handerChange} value={value}>
      {map(configurations, item => {
        const name = normalizeNameConfiguration(item.name);
        return <SizeConsumer label={name} value={name} key={name} />;
      })}
    </RadioGroup>
  );
};

SizePipeline.defaultProps = {
  configurations: null,
  onChange: () => {},
  value: null,
  hidden: false,
  loading: false
};

SizePipeline.propTypes = {
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      consumers: PropTypes.number,
      actualConsumers: PropTypes.number
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
  hidden: PropTypes.bool,
  loading: PropTypes.bool
};

export default SizePipeline;
