/* eslint-disable */

import { split, without, last } from 'lodash';

const blackList = [
  'login',
  'pipelines',
  'settings',
  'audit',
  'user',
  'overview',
  'execution-finish',
  'execution-inflight',
  'pipeline-logs',
  'pipeline-metrics'
];

const getEnvironmentsToPathName = pathName => {
  const listOfPathSplitToBar = split(pathName, '/');
  return last(without(listOfPathSplitToBar, '', ...blackList) || '');
};

export default getEnvironmentsToPathName;
