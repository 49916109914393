import { choose, when, otherwise } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';

import AccountsPipelineSidesheet from './components/AccountsPipelineSidesheet';
import AccountsTable from './components/AccountsTable';
import Header from './components/Header';
import AccountForm from './partials/AccountForm';

import i18n from '~/common/helpers/i18n';
import theme from '~/common/styled/theme';
import { DataTableLoading } from '~/components/DataTable';
import Empty from '~/components/Empty';

const Accounts = ({
  allIds,
  byId,
  loading,
  find,
  itensToFilter,
  remove,
  reactivate,
  openModal,
  modal,
  create,
  edit,
  setModal,
  error,
  searchText,
  setSearchText,
  containScopes,
  showAccountsV2,
  getAccountsPaginated,
  currentAccount,
  openSidesheetPipelines,
  closeSidesheetPipelineAssociate,
  openSidesheetPipelineAssociate,
  pipelines,
  loadingPipelines,
  getPipelinesByAccounts,
  deployments,
  getDeploymentsByAccount
}) => {
  const data = showAccountsV2 ? allIds.content : allIds;

  return (
    <>
      <Header
        setSearchText={setSearchText}
        find={showAccountsV2 ? getAccountsPaginated : find}
        itensToFilter={itensToFilter}
        showAccountsV2={showAccountsV2}
      />
      {choose(
        when(loading, () => <DataTableLoading rows={20} cols={4} />),
        when(error, () => (
          <Empty
            ilustration={theme.images.pictureError}
            title={i18n.t(
              'scenes.accounts.messages.error.error_generic_prompt'
            )}
            subTitle={i18n.t('scenes.accounts.guidance.error_generic_msg')}
            buttonText={i18n.t('verb.update')}
            buttonClick={() => window.location.reload()}
          />
        )),
        when(!data?.length && searchText, () => (
          <Empty
            ilustration={theme.images.pictureNoResults}
            title={`${i18n.t(
              'scenes.accounts.guidance.not_found_title'
            )} ${searchText}`}
            subTitle={i18n.t('scenes.accounts.guidance.not_found_subtitle')}
          />
        )),
        when(!data?.length, () => (
          <Empty
            ilustration={theme.images.emptyStateList}
            title={i18n.t('scenes.accounts.guidance.empty_list_prompt')}
            subTitle={
              !containScopes(['ACCOUNT:CREATE'])
                ? i18n.t('scenes.accounts.guidance.empty_list_msg')
                : false
            }
            buttonText={
              !containScopes(['ACCOUNT:CREATE']) ? i18n.t('verb.create') : false
            }
            buttonClick={() => openModal()}
            buttonLoading={loading}
          />
        )),
        otherwise(() => (
          <AccountsTable
            allIds={allIds}
            byId={byId}
            remove={remove}
            reactivate={reactivate}
            openModal={openModal}
            canEdit={!containScopes(['ACCOUNT:UPDATE'])}
            getAccountsPaginated={getAccountsPaginated}
            openSidesheetPipelineAssociate={openSidesheetPipelineAssociate}
          />
        ))
      )}

      <AccountForm
        className='fs-exclude'
        onConfirm={modal.account?.id ? edit : create}
        onCancel={() => setModal({ visible: false })}
        visible={modal.visible}
        account={modal.account}
        canEdit={!containScopes(['ACCOUNT:UPDATE'])}
        deployments={deployments}
        loadingPipelines={loadingPipelines}
        getDeploymentsByAccount={getDeploymentsByAccount}
        getAccountsPaginated={getAccountsPaginated}
      />

      {openSidesheetPipelines && (
        <AccountsPipelineSidesheet
          account={currentAccount}
          onClose={closeSidesheetPipelineAssociate}
          pipelines={pipelines}
          loading={loadingPipelines}
          getPipelinesByAccounts={getPipelinesByAccounts}
        />
      )}
    </>
  );
};

Accounts.defaultProps = {
  allIds: [],
  byId: null,
  loading: false,
  find: () => {},
  remove: () => {},
  create: () => {},
  reactivate: () => {},
  setModal: () => {},
  modal: {
    visible: false
  },
  itensToFilter: [],
  edit: () => {},
  openModal: () => {},
  error: '',
  searchText: '',
  setSearchText: () => {},
  containScopes: () => false,
  showAccountsV2: false,
  getAccountsPaginated: () => {},
  currentAccount: {},
  openSidesheetPipelines: false,
  deployments: []
};

Accounts.propTypes = {
  allIds: PropTypes.arrayOf([PropTypes.string]),
  byId: PropTypes.objectOf({}),
  loading: PropTypes.bool,
  find: PropTypes.func,
  remove: PropTypes.func,
  create: PropTypes.func,
  reactivate: PropTypes.func,
  setModal: PropTypes.func,
  modal: PropTypes.objectOf({
    visible: PropTypes.bool
  }),
  itensToFilter: PropTypes.arrayOf([
    {
      label: PropTypes.string,
      value: PropTypes.string
    }
  ]),
  edit: PropTypes.func,
  openModal: PropTypes.func,
  error: PropTypes.string,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  containScopes: PropTypes.func,
  showAccountsV2: PropTypes.bool,
  getAccountsPaginated: PropTypes.func,
  currentAccount: PropTypes.objectOf({}),
  openSidesheetPipelines: PropTypes.bool,
  closeSidesheetPipelineAssociate: PropTypes.func.isRequired,
  openSidesheetPipelineAssociate: PropTypes.func.isRequired,
  pipelines: PropTypes.objectOf({}).isRequired,
  loadingPipelines: PropTypes.bool.isRequired,
  getPipelinesByAccounts: PropTypes.func.isRequired,
  deployments: PropTypes.arrayOf([]),
  getDeploymentsByAccount: PropTypes.func.isRequired
};

export default Accounts;
