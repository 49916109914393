/* eslint-disable */
import immutable from 'seamless-immutable';

// locals
import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const initialstate = immutable({
  key: '',
  modal: {
    fallback: false
  },
  loading: {
    fallback: false
  },
  result: {
    fallback: null
  },
  error: {
    fallback: null,
    key: null
  }
});

const intercom = {
  name: 'intercom',
  state: initialstate,
  reducers: {
    setKey(state, payload) {
      return state.merge({
        key: payload.key
      });
    },
    setError(state, error) {
      return state.merge({ error });
    },
    reset() {
      return initialstate;
    },
    setModal(state, { path, value }) {
      return state.merge({
        modal: {
          [path]: value
        }
      });
    },
    setResult(state, { path, value }) {
      return state.merge({
        result: {
          [path]: value
        }
      });
    },
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    }
  },
  effects: dispatch => ({
    async create() {
      try {
        const response = await api.intercom.create();

        dispatch.intercom.setKey({
          key: response?.data?.intercom
        });
      } catch (e) {
        dispatch.intercom.setError({
          path: 'key',
          value: e.message
        });
      }
    }
  }),
  logics: [
    {
      type: ['intercom/setError'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.intercom.setLoading({
          path: 'fallback',
          value: false
        });
        done();
      }
    },
    {
      type: ['intercom/setResult'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.intercom.setLoading({
          path: 'fallback',
          value: false
        });
        dispatch.intercom.setModal({
          path: 'fallback',
          value: false
        });
        dispatch.snackbar.create({
          text: i18n.t('scenes.fallback_chat.messages.success.success_message'),
          action: {
            label: i18n.t('action.ok'),
          }
        });
        done();
      }
    }
  ]
};

export default intercom;
