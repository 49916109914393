/* eslint no-param-reassign: "error" */
import { omit } from 'lodash';
import Immutable from 'seamless-immutable';

const normalizeCanvas = canvas => {
  const nodes = Immutable(canvas)
    .asMutable({ deep: true })
    .nodes.map(node => {
      if (node.data.onProcessTrack) {
        node.data.onProcessTrack = normalizeCanvas(node.data.onProcessTrack);
      }

      if (node.data.onExceptionTrack) {
        node.data.onExceptionTrack = normalizeCanvas(
          node.data.onExceptionTrack
        );
      }

      return {
        id: node.data.id,
        data: omit(node.data, ['id', 'uuid']),
        type: node.data.type
      };
    });

  const edges = canvas.edges.map(edge => ({
    source: edge.data.source,
    target: edge.data.target,
    data: edge.data.data
  }));

  return {
    // nodes: nodes,
    nodes: nodes.map(node => ({ ...node, data: JSON.stringify(node.data) })),
    edges
  };
};

export default normalizeCanvas;
