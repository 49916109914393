import { iff } from '@digibee/control-statements';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import clamp from './common/clamp';
import findClosest from './common/findClosest';
import Container from './components/Container';
import InputNumber from './components/Input';
import Mark from './components/Mark';
import Rail from './components/Rail';
import Track from './components/Track';
import ValueLabel from './components/ValueLabel';

import useDynamicState from '~/common/hooks/useDynamicState';

const WrapperInput = styled.div`
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
    `}
  ${props =>
    props.orientation === 'vertical' &&
    css`
      position: relative;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
    `}
`;

const axisProps = {
  horizontal: {
    offset: percent => ({ left: `${percent}%` }),
    leap: percent => ({ width: `${percent}%` })
  },
  vertical: {
    offset: percent => ({ bottom: `${percent}%` }),
    leap: percent => ({ height: `${percent}%` })
  }
};

const trackPosition = (event, touchId) => {
  if (touchId.current !== undefined && event.changedTouches) {
    const touch = event.changedTouches[0];
    if (touch.identifier === touchId.current) {
      return {
        x: touch.clientX,
        y: touch.clientY
      };
    }
    return false;
  }

  return {
    x: event.clientX,
    y: event.clientY
  };
};

const valueToPercent = (value, min, max) => ((value - min) * 100) / (max - min);

const asc = (a, b) => a - b;

const setValueIndex = ({ values, source, newValue, index }) => {
  if (source[index] === newValue) {
    return source;
  }

  const output = values.slice();
  output[index] = newValue;
  return output;
};

const percentToValue = (percent, min, max) => (max - min) * percent + min;

const roundValue = (value, step, min) => {
  const shortestDistance = Math.round((value - min) / step) * step + min;
  return Number(shortestDistance);
};

const getBoundingClientRect = slider => {
  const { width, height, bottom, left } = slider?.getBoundingClientRect() || {};
  return { width, height, bottom, left };
};

const getPercentOrientation = (props, axis) => {
  if (['vertical'].includes(axis)) {
    return (props.bottom - props.position.y) / props.height;
  }
  return (props.position.x - props.left) / props.width;
};

const getNewValueToStep = ({ value, step, min, marks }) => {
  if (step) {
    return roundValue(value, step, min);
  }
  const marksValues = marks.map(mark => mark.value);
  const closestIndex = findClosest(marksValues, value);
  return marksValues[closestIndex];
};

const Slider = ({
  disabled,
  marks: marksProp,
  max,
  min,
  step,
  name,
  onChange,
  orientation,
  value: valueProp,
  size,
  isInput
}) => {
  const axis = orientation;
  const [open, setOpen] = useState(-1);
  const [active, setActive] = useState(-1);
  const [valueDerived, setValueState] = useDynamicState(valueProp, min);

  const touchId = React.useRef();
  const previousTrackIndex = React.useRef();
  const sliderRef = React.useRef();

  const range = Array.isArray(valueDerived);

  const values = map(
    range ? valueDerived.slice().sort(asc) : [valueDerived],
    value => clamp(value, min, max)
  );

  const getMarks = () => {
    const items =
      marksProp === true && step !== null
        ? map([...Array(Math.floor((max - min) / step) + 1)], (_, index) => ({
            value: min + step * index
          }))
        : marksProp || [];

    const markLabel = items.length > 0 && items.some(mark => mark.label);
    return { marks: items, marked: markLabel };
  };

  const { marks, marked } = getMarks();

  const handleChange =
    onChange &&
    ((event, value, thumbIndex) => {
      onChange(event, value, thumbIndex);
    });
  const getPositionNewValue = ({
    position,
    move = false,
    values: values2,
    source
  }) => {
    const { current: slider } = sliderRef;
    const { width, height, bottom, left } = getBoundingClientRect(slider);
    const percenttOrientation = getPercentOrientation(
      { width, height, bottom, left, position },
      axis
    );
    const newPercentToValue = percentToValue(percenttOrientation, min, max);

    const newValueStep = getNewValueToStep({
      value: newPercentToValue,
      step,
      min,
      marks
    });
    const newValueClamp = clamp(newValueStep, min, max);
    let activeIndex = 0;

    if (range) {
      if (!move) {
        activeIndex = findClosest(values2, newValueClamp);
      } else {
        activeIndex = previousTrackIndex.current;
      }

      const newValue = setValueIndex({
        values: values2,
        source,
        newValue: newValueClamp,
        index: activeIndex
      }).sort(asc);
      if (!move) {
        activeIndex = newValue.indexOf(newValueClamp);
        previousTrackIndex.current = activeIndex;
      }
      return { newValue, activeIndex };
    }

    return { newValue: newValueClamp, activeIndex };
  };

  const handleTouchEnd = event => {
    const position = trackPosition(event, touchId);
    if (!position) {
      return;
    }
    const { newValue } = getPositionNewValue({
      position,
      values,
      source: valueDerived
    });
    setActive(-1);
    if (event.type === 'touchend') {
      setOpen(-1);
    }

    if (handleChange) {
      handleChange(event, newValue);
    }

    touchId.current = undefined;
    /* eslint-disable-next-line no-use-before-define */
    stopListening();
  };
  const handleTouchMove = event => {
    const position = trackPosition(event, touchId);
    if (!position) {
      return;
    }
    if (event.type === 'mousemove' && event.buttons === 0) {
      handleTouchEnd(event);
      return;
    }
    const { newValue, activeIndex } = getPositionNewValue({
      position,
      move: true,
      values,
      source: valueDerived
    });
    setActive(activeIndex);

    setValueState(newValue);

    if (handleChange) {
      handleChange(event, newValue, activeIndex);
    }
  };
  const stopListening = useCallback(() => {
    const doc = sliderRef?.current
      ? sliderRef?.current.ownerDocument
      : document;
    doc.removeEventListener('mousemove', handleTouchMove);
    doc.removeEventListener('mouseup', handleTouchEnd);
    doc.removeEventListener('touchmove', handleTouchMove);
    doc.removeEventListener('touchend', handleTouchEnd);
  }, [handleTouchEnd, handleTouchMove]);

  const handleMouseDown = event => {
    if (event.button !== 0) {
      return;
    }
    event.preventDefault();

    const position = trackPosition(event, touchId);

    const { newValue, activeIndex } = getPositionNewValue({
      position,
      values,
      source: valueDerived
    });

    setActive(activeIndex);
    setValueState(newValue);

    if (handleChange) {
      handleChange(event, newValue, activeIndex);
    }
    const doc = sliderRef?.current
      ? sliderRef?.current.ownerDocument
      : document;
    doc.addEventListener('mousemove', handleTouchMove);
    doc.addEventListener('mouseup', handleTouchEnd);
  };

  const nextValueToStep = ({
    currentValue,
    value,
    index,
    markIndex,
    marksValues
  }) => {
    const newValue =
      /* eslint-disable-next-line no-nested-ternary */
      marksValues?.length && step == null
        ? currentValue < value
          ? marksValues[markIndex - 1]
          : marksValues[markIndex + 1]
        : clamp(value, min, max);

    if (marksValues?.length && step == null) {
      const currentMarkIndex = marksValues.indexOf(values[index]);
      return value < values[index]
        ? marksValues[currentMarkIndex - 1]
        : marksValues[currentMarkIndex + 1];
    }
    if (range) {
      return setValueIndex({
        values,
        source: valueDerived,
        newValue,
        index
      }).sort(asc);
    }
    return newValue;
  };

  const handleTouchStart = eventNative => {
    const touch = eventNative.changedTouches[0];
    if (touch) {
      touchId.current = touch.identifier;
    }
    const position = trackPosition(eventNative, touchId);
    const { newValue, activeIndex } = getPositionNewValue({
      position,
      values,
      source: valueDerived
    });
    setActive(activeIndex);

    setValueState(newValue);

    if (handleChange) {
      handleChange(eventNative, newValue, activeIndex);
    }
    const doc = sliderRef?.current
      ? sliderRef?.current.ownerDocument
      : document;
    doc.addEventListener('touchmove', handleTouchMove);
    doc.addEventListener('touchend', handleTouchEnd);
  };

  const handlerInputChange = (event, index) => {
    const value = values[index];

    setActive(index);
    const marksValues = marks.map(mark => mark.value);

    const markIndex = marksValues.indexOf(value);

    const valueStep = nextValueToStep({
      step,
      value: event.target.valueAsNumber,
      currentValue: value,
      index,
      marksValues,
      markIndex
    });
    setValueState(valueStep);

    if (handleChange) {
      handleChange(event, valueStep, index);
    }
  };

  // const range = Array.isArray(valueDerived);

  // const values = map(
  //   range ? valueDerived.slice().sort(asc) : [valueDerived],
  //   value => clamp(value, min, max)
  // );

  // const getMarks = () => {
  //   const items =
  //     marksProp === true && step !== null
  //       ? map([...Array(Math.floor((max - min) / step) + 1)], (_, index) => ({
  //         value: min + step * index
  //       }))
  //       : marksProp || [];

  //   const markLabel = items.length > 0 && items.some(mark => mark.label);
  //   return { marks: items, marked: markLabel };
  // };

  // const { marks, marked } = getMarks();

  useEffect(() => {
    const { current: slider } = sliderRef;
    slider.addEventListener('touchstart', handleTouchStart, {
      passive: false
    });
    return () => {
      slider.removeEventListener('touchstart', handleTouchStart, {
        passive: false
      });
    };
  }, [stopListening, handleTouchStart]);

  const trackOffset = valueToPercent(range ? values[0] : min, min, max);
  const trackLeap =
    valueToPercent(values[values.length - 1], min, max) - trackOffset;

  const trackStyle = {
    ...axisProps[axis].offset(trackOffset),
    ...axisProps[axis].leap(trackLeap)
  };

  const handleMouseOver = event => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    setOpen(index);
  };

  useEffect(() => {
    if (disabled) {
      stopListening();
    }
  }, [disabled, stopListening]);

  const handleMouseLeave = () => {
    setOpen(-1);
  };

  if (disabled && active !== -1) {
    setActive(-1);
  }

  const ownwPropsStyle = {
    orientation: axis,
    disabled,
    size,
    marked,
    isInput,
    name
  };

  const position = {
    vertical: {
      0: 'right',
      1: 'left'
    },
    horizontal: {
      0: 'left',
      1: 'right'
    }
  };

  const inputList = reduce(
    values,
    (acc, current, index) => {
      if (!range) {
        return {
          right: {
            index: 1,
            value: current,
            activeIndex: 0
          }
        };
      }
      return {
        ...acc,
        [position[axis][index]]: {
          index,
          value: current,
          activeIndex: index
        }
      };
    },
    { right: {}, left: {} }
  );

  return (
    <WrapperInput
      /* eslint-disable react/jsx-props-no-spreading */
      {...ownwPropsStyle}
    >
      {iff(inputList.left && isInput, () => (
        <InputNumber
          onFocus={() => setActive(inputList.left.activeIndex)}
          onBlur={() => setActive(-1)}
          key={inputList.left.index}
          max={max}
          min={min}
          step={step}
          disabled={disabled}
          onChange={event =>
            handlerInputChange(event, inputList.left.activeIndex)
          }
          data-index={inputList.left.index}
          range={range}
          value={inputList.left.value}
          orientation={axis}
        />
      ))}
      <Container
        /* eslint-disable react/jsx-props-no-spreading */
        {...ownwPropsStyle}
        innerRef={sliderRef}
        onMouseDown={handleMouseDown}
      >
        <Rail
          /* eslint-disable react/jsx-props-no-spreading */
          {...ownwPropsStyle}
        />
        <Track
          /* eslint-disable react/jsx-props-no-spreading */
          {...ownwPropsStyle}
          style={trackStyle}
        />
        {map(marks, (mark, index) => {
          const percent = valueToPercent(mark.value, min, max);
          const style = axisProps[axis].offset(percent);
          const markActive = range
            ? mark.value >= values[0] && mark.value <= values[values.length - 1]
            : mark.value <= values[0];
          return (
            <Mark
              style={style}
              data-index={index}
              {...ownwPropsStyle}
              markActive={markActive}
              key={index}
              /* eslint-disable react/jsx-props-no-spreading */
              {...mark}
            />
          );
        })}

        {map(values, (value, index) => {
          const percent = valueToPercent(value, min, max);
          const style = axisProps[axis].offset(percent);
          return (
            <ValueLabel
              active={active === index || open === index}
              thumb={{
                onMouseOver: handleMouseOver,
                onMouseLeave: handleMouseLeave
              }}
              /* eslint-disable react/jsx-props-no-spreading */
              {...ownwPropsStyle}
              index={index}
              key={index}
              value={value}
              style={style}
            />
          );
        })}
      </Container>
      {iff(inputList.right && isInput, () => (
        <InputNumber
          onFocus={() => setActive(inputList.right.activeIndex)}
          onBlur={() => setActive(-1)}
          key={inputList.right.index}
          max={max}
          min={min}
          step={step}
          disabled={disabled}
          onChange={event =>
            handlerInputChange(event, inputList.right.activeIndex)
          }
          data-index={inputList.right.index}
          range={range}
          value={inputList.right.value}
          orientation={axis}
        />
      ))}
    </WrapperInput>
  );
};

Slider.defaultProps = {
  disabled: false,
  marks: false,
  max: 100,
  min: 0,
  step: 1,
  orientation: 'horizontal',
  isInput: false,
  value: 0,
  name: '',
  onChange: () => {},
  size: 'md'
};

Slider.propTypes = {
  /**
   *
   * defaultValue if disabled ```true``` if true disable the slider component
   *
   * */
  disabled: PropTypes.bool,
  /**
   *
   * marks This property will make markings depending on the spare wheel or can be custom through an array
   * It can be a bool or an array.
   * marks: true
   * If it comes Boolean, the marking will depend on the step
   *  marks: [{value: 0, label: '0'}]
   * If an array comes, we will have custom tags
   *
   * */
  marks: PropTypes.bool,

  /**
   *
   * max The maximum allowed value of the component.
   *
   * */
  max: PropTypes.number,
  /**
   *
   * min The Minimum allowed value of the component.
   *
   * */
  min: PropTypes.number,
  /**
   *
   * step is a property that defines the range of the slider.
   * When the step is null and the marks are being customized the thumb can only select the mark values
   *
   * */
  step: PropTypes.number,
  /**
   *
   * name input range element
   *
   * */
  name: PropTypes.string,
  /**
   *
   * Callback function to get the slide value on any change
   *
   * */
  onChange: PropTypes.func,
  /**
   *
   * the slider orientation
   *
   * */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   *
   *  value of the slider range. can only come the number if you or an array
   *  ```defaultValue: 50```
   *  ```defaultValue: [10, 50]```
   * */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  /**
   *
   * the size slider
   *
   * */
  size: PropTypes.oneOf(['sm', 'md']),
  /**
   *
   * include input in componente slider ranger
   *
   * */
  isInput: PropTypes.bool
};

export default Slider;
