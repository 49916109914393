import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Locals
import { base } from '~/common/styled/input';

const InputStyled = styled.input`
  ${base};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const BaseComponent = ({ onChange, ...props }) => {
  const handleOnChange = event => {
    const { value } = event.target;
    if (isFunction(onChange)) {
      onChange(value);
    }
  };
  return <InputStyled onChange={handleOnChange} {...props} />;
};

BaseComponent.propTypes = {
  onChange: PropTypes.func
};

export default BaseComponent;
