import { useSelector } from 'react-redux';

/**
 * Returns a list of scopes, and a function to verify if a scope is on the list.
 *
 * @returns [scopes, containScopes()]
 */
function useAcls() {
  const scopes = useSelector(state => state.acls?.scopes);
  const defaultAcls = ['GROUP:ADMIN'];

  const containScopes = scopeValue => {
    if (!scopeValue) return false;
    const scopeArray = Array.isArray(scopeValue) ? scopeValue : [scopeValue];
    const roles = [].concat(scopeArray, defaultAcls);

    return scopes.some(scope => roles.includes(scope));
  };

  return [scopes, containScopes];
}

export default useAcls;
