import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

// Locals
import i18n from '~/common/helpers/i18n';
import Search from '~/components/Search';

const Container = styled.div`
  width: 100%;
  height: 66px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperSearch = styled.div`
  width: 50vw;
  @media only screen and (max-width: 761px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const Header = ({
  find,
  setSearchText,
  itensToFilter = [],
  showAccountsV2
}) => (
  <Container>
    <WrapperSearch>
      <Search
        data-testid='accounts-searchbar-accounts-names'
        tagsSource={itensToFilter}
        onSearch={search => {
          if (!showAccountsV2) {
            find({ search });
          }

          setSearchText(search);
        }}
        placeholder={i18n.t('label.search_by_account_name_placeholder')}
      />
    </WrapperSearch>
  </Container>
);

Header.defaultProps = {
  find: () => {},
  setSearchText: () => {},
  itensToFilter: [],
  showAccountsV2: false
};

Header.propTypes = {
  find: PropTypes.func,
  setSearchText: PropTypes.func,
  showAccountsV2: PropTypes.bool,
  itensToFilter: PropTypes.arrayOf([
    {
      label: PropTypes.string,
      value: PropTypes.string
    }
  ])
};

export default memo(Header);
