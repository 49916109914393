const parseStringWithVariable = (url, context = {}) => {
  const variables = Object.keys(context);
  let newUrl = url || '';

  variables.map(variable => {
    if (!context[variable]) {
      newUrl = newUrl.replace(`{${variable}}.`, '');
      newUrl = newUrl.replace(`.{${variable}}`, '');
      return newUrl;
    }

    newUrl = newUrl.replace(`{${variable}}`, context[variable]);
    return newUrl;
  });

  return newUrl;
};

export default parseStringWithVariable;
