import { Box, Text } from '@digibee/beehive-ui';
import { FormEvent } from 'react';

import TokenInput from '../../TokenInput';
import * as Elements from '../FormLogin.elements';
import { FormPageType } from '../FormLogin.enhancer';

import i18n from '~/common/helpers/i18n';

const SecurityCode = ({ loading, touched, onErrors, errors, setFieldValue, handleSubmit, values }: FormPageType) => {
  const errorMessage = onErrors(touched, errors, 'securityCode', false, 
  ['security_code_invalid', 'security_code_expired']);
  return (
    <>
      <Elements.Fields>
        <Text 
          css={{
            marginBottom: '12px',
            fontSize: '$3',
            fontWeight: '$medium',
            color: '$pri500',
          }} 
          data-testid='governance-title-blocked-user-page'>
            {i18n.t('label.verification_needed')}
        </Text>
        <Text 
          css={{
            marginBottom: '28px',
            textAlign: 'left',
            lineHeight: '1.4',
            color: '$neu500',
            fontSize: '$2',
            fontWeight: '$regular',
          }} data-testid='governance-explanation-blocked-user-page'>
            {i18n.t('label.verification_needed_assist')}
        </Text>
        <TokenInput
          data-testid='governance-two-factor-input-login-page'
          name='code'
          onChange={(value: string) => setFieldValue('securityCode', value)}
          length={6}
          helperText={i18n.t(errorMessage)}
          danger={Boolean(errorMessage)}
        />
      </Elements.Fields>
      <Elements.Fields>
        <Elements.SubmitButton
          key='save'
          variant='primary'
          loading={loading}
          disabled={values?.securityCode?.length < 6}
          onClick={(event) => handleSubmit(event as unknown as FormEvent<HTMLFormElement>)}
          data-testid='governance-submit-button-login-page'
        >
          {i18n.t('action.verity_code')}
        </Elements.SubmitButton>
      </Elements.Fields> 
      <Box css={{ bottom: '32px', position: 'absolute', width: '100%' }}>
        <Text css={{ fontSize: '$2', marginBottom: 'unset' }}>
          {i18n.t('label.still_have_login_problems')}
        </Text>
        <Text css={{ fontSize: '$2', marginBottom: 'unset' }}>
          {i18n.t('label.read_our_article_about')}
          &nbsp;
          <Elements.Link
            href={i18n.t('action.blocked_access_login_issues_url')}
            target='_blank'
          >
            {i18n.t('action.blocked_access_login_issues')}
          </Elements.Link>
        </Text>
      </Box> 
    </>
  );
}

export default SecurityCode;
