import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.span`
  label: Root;
  border-radius: 12px;
  box-sizing: content-box;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  curso: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  color: ${props => props.theme.colors.pri500};
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      height: 8px;
      width: 100%;
      padding: 16px 0;
      @media (pointer: coarse) {
        padding: 20px 0px;
      }
      ${props.size === `sm` &&
      css`
        height: 6px;
      `};

      ${props.isInput &&
      css`
        padding: 16px 40px;
        margin: 20px 30px;
      `};
    `};
  ${props =>
    props.orientation === 'vertical' &&
    css`
      height: 100%;
      width: 8px;
      padding: 0 16px;
      @media (pointer: coarse) {
        padding: 0px 20px;
      }

      ${props.isInput &&
      css`
        padding: 40px 16px;
        margin: 30px 20px;
      `};

      ${props.size === `sm` &&
      css`
        width: 6px;
      `};
    `};
  ${props =>
    props.disabled &&
    css`
      curso: default;
      pointer-events: none;
      color: ${props.theme.colors.gra400};
    `};
`;

Container.propTypes = {
  orientation: PropTypes.string,
  disabled: PropTypes.bool
};
export default Container;
