import PropTypes from 'prop-types';

const Validation = {
  code: PropTypes.string,
  message: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.objectOf({
      level: PropTypes.string,
      details: PropTypes.arrayOf(PropTypes.string),
      code: PropTypes.string,
      message: PropTypes.string,
      objects: PropTypes.string
    })
  )
};

export default Validation;
