import { css } from 'styled-components';

const base = ({ theme, disabled }) => css`
  padding-right: 12px;
  padding-left: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  appearance: none;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  outline: 0;
  display: block;
  margin: 0;
  font-family: ${theme.typography.fontFamily};
  background: none;
  resize: none;
  font-size: 14px;
  background-color: ${theme.colors.white};
  -webkit-box-shadow: 0 0 0px 1000px ${theme.colors.white} inset;
  transition: background-color 5000s ease-in-out 0s;
  &::placeholder {
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  ${disabled &&
  css`
    color: ${theme.colors.gra400} !important;
  `};
`;

export default base;
