import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ContainerBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

export const ContainerBodyV2 = styled(ContainerBody)`
  margin-bottom: 0px;
`;

export const ContainerMultiIntance = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: baseline;
`;

export const Icon = styled(props => <FontAwesomeIcon {...props} />)`
  color: ${props => (props.color ? props.color : props.theme.colors.gra600)};
  margin-right: 8px;
  font-size: 14px;
`;
