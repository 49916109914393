import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const project = {
  async fetch(params) {
    try {
      const result = await clientApollo.query({
        query: gql`
          query project($realm: String!) {
            project(realm: $realm) {
              id
              name
              description
              amountOfPipelines
              allowAllUsers
              allowedGroups
              allowedUsers
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return {
        data: {
          project: result?.data?.project.map(p => ({
            ...p,
            referencesIds: []
          }))
        }
      };
    } catch (e) {
      throw Error(e);
    }
  },
  async save(params) {
    try {
      const data = await clientApollo.mutate({
        mutation: gql`
          mutation createProject($realm: String!, $project: ProjectInput) {
            createProject(realm: $realm, project: $project) {
              id
              name
              description
              amountOfPipelines
              allowAllUsers
              allowedGroups
              allowedUsers
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  },
  async edit(params) {
    try {
      const data = await clientApollo.mutate({
        mutation: gql`
          mutation editProject($realm: String!, $project: ProjectInput) {
            editProject(realm: $realm, project: $project) {
              id
              name
              description
              amountOfPipelines
              allowAllUsers
              allowedGroups
              allowedUsers
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  },
  async move(params) {
    try {
      const data = await clientApollo.mutate({
        mutation: gql`
          mutation moveProject(
            $realm: String!
            $projectId: String!
            $pipelines: [JSON]
          ) {
            moveProject(
              realm: $realm
              projectId: $projectId
              pipelines: $pipelines
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  },
  async remove(params) {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation achiveProject($realm: String!, $projectId: String!) {
            archiveProject(realm: $realm, projectId: $projectId)
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw Error(e);
    }
  },
  async undo(params) {
    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation unarchiveProject($realm: String!, $projectId: String!) {
            unarchiveProject(realm: $realm, projectId: $projectId)
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      throw Error(e);
    }
  },
  async getProject(params) {
    try {
      const result = await clientApollo.query({
        query: gql`
          query getProject($realm: String!, $projectId: String!, $populate: Boolean) {
            getProject(realm: $realm, projectId: $projectId, populate: $populate) {
              id
              name
              description
              amountOfPipelines
              allowAllUsers
              allowedGroups
              allowedUsers
              detailsOfAllowedGroups
              detailsOfAllowedUsers
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return result;
    } catch (e) {
      throw Error(e);
    }
  },
  async validateProjectName(params) {
    try {
      const result = await clientApollo.query({
        query: gql`
          query validateProjectName($realm: String!, $name: String!) {
            validateProjectName(realm: $realm, name: $name)
          }
        `,
        variables: {
          ...params
        }
      });
      return result;
    } catch (e) {
      throw Error(e);
    }
  }
};

export default project;
