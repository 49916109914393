import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

// Locals
import clamp from '../../common/clamp';

const height = 40;
const width = 48;
const borderRadius = 4;

const InputNative = styled.input`
  width: ${width}px;
  height: ${height}px;
  border-radius: ${borderRadius}px;
  font-family: ${props => props.theme.typography.fontFamily};
  outline: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.gra500};

  &:focus {
    background: ${props => props.theme.colors.pri50};
    border: 1px solid ${props => props.theme.colors.pri500};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${props.theme.colors.gra25};
      border: none;
      curso: default;
      pointer-events: none;
    `};

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: center;
`;

const Span = styled.span`
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      margin-left: 10px;
    `};
`;

const getValue = event => {
  if (!event) return null;
  return typeof event.target.value === 'string'
    ? 0
    : Number(event.target.value);
};

const Input = ({
  max = 100,
  min = 0,
  value,
  onChange,
  onBlur,
  step = 1,
  onFocus,
  ...props
}) => {
  const handleFocus = event => {
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleBlur = event => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = event => {
    if (onChange) {
      const eventValue = getValue(event);
      onChange(event, clamp(eventValue, min, max));
    }
  };

  return (
    <Span
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    >
      <InputNative
        step={step}
        max={max}
        min={min}
        type='number'
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
        onBeforeInput={handleBlur}
        onFocus={handleFocus}
        /* eslint-disable react/jsx-props-no-spreading */
        {...props}
      />
    </Span>
  );
};

Input.defaultProps = {
  disabled: false,
  max: 0,
  min: 0,
  step: 0,
  value: 0,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {}
};
Input.propTypes = {
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func
};

export default Input;
