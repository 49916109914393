import styled from 'styled-components';

import NativeButton from '~/components/Button';
import NativeText from '~/components/Text';

export const Container = styled.div`
  width: 100%;
`;

export const Text = styled(NativeText)`
  margin-bottom: 28px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gra500};
  line-height: 1.4;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.pri500};
  margin-bottom: 12px;
`;

// @ts-ignore
export const Button = styled(NativeButton)`
  margin-bottom: 8px;
`;
