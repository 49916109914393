import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useHoverDirty } from 'react-use';
import styled from 'styled-components';

// Locals
import Thumb from '../Thumb';
import Tooltip from '../Tooltip';

const Container = styled.span`
  display: flex;
  outline: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: currentColor;
`;

const ValueLabel = ({ value, thumb, style, ...props }) => {
  const ref = useRef();

  const isHover = useHoverDirty(ref);
  const isShow = isHover || props.active;
  return (
    <>
      <Container
        innerRef={ref}
        /* eslint-disable react/jsx-props-no-spreading */
        {...props}
      >
        <Thumb
          isShow={isShow}
          style={style}
          data-index={props.index}
          /* eslint-disable react/jsx-props-no-spreading */
          {...thumb}
          /* eslint-disable react/jsx-props-no-spreading */
          {...props}
        />
        <Tooltip
          style={style}
          isHover={isShow}
          /* eslint-disable react/jsx-props-no-spreading */
          {...props}
        >
          {value}
        </Tooltip>
      </Container>
    </>
  );
};

ValueLabel.defaultProps = {
  value: '',
  index: 0,
  active: false,
  style: null,
  thumb: {
    onMouseOver: () => {},
    onMouseLeave: () => {}
  }
};

ValueLabel.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  active: PropTypes.bool,
  style: PropTypes.objectOf({}),
  thumb: PropTypes.objectOf({
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func
  })
};

export default ValueLabel;
