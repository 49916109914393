import { DeploymentPlanProvider } from './context/DeploymentPlanProvider';
import Create from './Create';
import Detail from './Detail';
import ExecuteDeploy from './ExecuteDeploy';
import List from './List';

import i18n from '~/common/helpers/i18n';
import { ConfirmDialogProvider } from '~/components/ConfirmDialogV2/contexts/confirmDialogProvider';

export const DeploymentPlanRoutes = {
  path: 'deployment/deploymentPlan',
  name: 'run-deployment-plan',
  element: (
    <ConfirmDialogProvider>
      <DeploymentPlanProvider>
        <List />
      </DeploymentPlanProvider>
    </ConfirmDialogProvider>
  ),
  title: i18n.t('action.deployment_plan'),
  children: [
    {
      path: 'create',
      element: <Create />
    },
    {
      path: 'detail',
      element: <Detail />
    },
    {
      path: 'executeDeploy/:env',
      element: <ExecuteDeploy />
    }
  ]
};
