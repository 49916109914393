import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PORTAL_URL } from '~/common/helpers/environment';
import parseStringWithVariable from '~/common/helpers/parseStringWithVariable';
import withHooks from '~/common/hoc/withHooks';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  redirectToRealm: (realm: string) => void,
}

const enhancer = withHooks((outProps: object) => {
  const { application } = useSelector((state: StoreRootState) => state);

  useEffect(() => {
    if (application?.subdomain)
      window.location.href = parseStringWithVariable(PORTAL_URL, {
        realm: application?.subdomain
      });
  }, []);

  const props: Props = {
    redirectToRealm: realm => {
      window.location.href = parseStringWithVariable(PORTAL_URL, { realm });
    }
  };

  return {
    ...outProps,
    ...props
  };
});

export default enhancer;
