import axios from 'axios';

import { URL, API_KEY } from '~/common/helpers/environment';

const forgotPassword = {
  send({ realm, email, recaptcha }) {
    return axios.post(
      `${URL}/${realm}/forgot-password`,
      { email, 'g-recaptcha-response': recaptcha },
      {
        headers: {
          'Content-Type': 'application/json',
          apikey: API_KEY
        }
      }
    );
  },
  resetPassword({ realm, ...params }) {
    return axios.put(
      `${URL}/${realm}/forgot-password/change-password`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          apikey: API_KEY
        }
      }
    );
  }
};

export default forgotPassword;
