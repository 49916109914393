import { css } from 'styled-components';

export const baseStyles = () => css`
  position: relative;
  letter-spacing: 0.0333333333em;
  font-weight: 400;
  font-smoothing: antialiased;
  line-height: 24px;
  padding-left: 12px;
  box-sizing: border-box;
`;

export const marginStyles = ({ isActived, danger }) => css`
  ${danger &&
  css`
    margin-top: 0px;
  `};
  ${isActived &&
  !danger &&
  css`
    margin-top: 2px;
  `};
`;

export const colorStyles = ({ theme, isActived, danger, disabled }) => css`
  ${isActived &&
  css`
    color: ${theme.colors.pri500} !important;
  `};

  ${danger &&
  css`
    color: ${theme.colors.red500} !important;
  `}

  ${disabled &&
  css`
    color: ${theme.colors.gra700} !important;
  `}
`;
