/* eslint-disable */

import { reduce } from 'lodash';

const capatalize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const parsePropArrayObjectInString = (obj, cb) => {
  return reduce(
    Object.keys(obj),
    (preview, current) => {
      return {
        ...preview,
        [current]:
          Array.isArray(obj[current]) && cb
            ? obj[current].map(cb)
            : obj[current]
      };
    },
    {}
  );
};

/*
  Array,
  alias,
  call
  {
    array :  data,
    alias: id,
    call
  }
*/

const normalize = ({
  array = [],
  alias = 'id',
  callbackParseObjectInString
}) => {
  const all = `all${capatalize(alias)}s`;
  const by = `by${capatalize(alias)}`;

  return reduce(
    array,
    (preview, current) => {
      const obj = parsePropArrayObjectInString(
        current,
        callbackParseObjectInString
      );
      return {
        [by]: {
          [current[alias]]: obj,
          ...preview[by]
        },
        [all]: [...preview[[all]], current[alias]]
      };
    },
    { [by]: {}, [all]: [] }
  );
};

export default normalize;
