import { connect } from 'react-redux';
import { compose } from 'recompose';

// Locals
import withHooks from './withHooks';

const defaultAcls = ['GROUP:ADMIN'];

const mapStateToProps = ({ acls }) => ({
  scopes: acls?.scopes
});

const enhancer = compose(
  connect(mapStateToProps, null),
  withHooks(props => {
    const actions = {
      containScopes: name => {
        const roles = [].concat(name, defaultAcls);
        const isAuthorized = props?.scopes?.some(scope =>
          roles.includes(scope)
        );
        return !isAuthorized;
      }
    };
    return {
      ...props,
      ...actions
    };
  }, [])
);

export default enhancer;
