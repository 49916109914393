import { Button as ButtonV2, Input, Select } from '@digibee/beehive-ui';
import { Formik } from 'formik';
import React from 'react';

import * as Elements from './Header.elements';

import i18n from '~/common/helpers/i18n';
import { Option } from '~/entities/HistoryDeployment';

type PropsSubmit = {
  pipelineName: string;
  projectId: string;
  page: number;
};

type Props = {
  fetch: (values: PropsSubmit) => void;
  projects: Array<Option>;
};

const Header: React.FC<Props> = ({ fetch, projects }) => (
  <Formik
    enableReinitialize
    initialValues={{
      pipelineName: '',
      projectId: ''
    }}
    onSubmit={(values: any) => {
      fetch({
        ...values,
        page: 0
      });
    }}
    render={({
      setFieldValue,
      submitForm,
      handleChange,
      handleBlur,
      resetForm,
      values
    }) => (
      <Elements.Row isMarginBottomNegative={false}>
        <Elements.Col lg={5} md={2} isMarginBottomNegative={false}>
          <Input
            placeholder={i18n.t('label.search_by_pipe_name_placeholder')}
            data-testid='run-history-pipeline-name'
            name='pipelineName'
            id='pipelineName'
            onChange={handleChange}
            value={values?.pipelineName}
            label={i18n.t('label.pipeline_name_vers_two')}
            onBlur={handleBlur}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          />
        </Elements.Col>
        <Elements.Col lg={4} md={2} isMarginBottomNegative={false}>
          <Select
            name='projectId'
            onChange={(data: Option) => {
              setFieldValue('projectId', data?.value);
            }}
            label={i18n.t('label.project_name')}
            placeholder={i18n.t('label.select_placeholder')}
            data-testid='project-select'
            value={{
              label:
                projects?.find(project => project?.value === values?.projectId)
                  ?.label || '',
              value: values?.projectId
            }}
            options={projects}
          />
        </Elements.Col>
        <Elements.Row isMarginBottomNegative>
          <Elements.Col md={6} sm={12} isMarginBottomNegative={false}>
            <Elements.ContainerButton isNewDS>
              <ButtonV2
                variant='primary'
                data-testid='run-history-button-search'
                size='medium'
                // @ts-ignore
                onClick={submitForm}
              >
                {i18n.t('common.actions.search').toUpperCase()}
              </ButtonV2>
            </Elements.ContainerButton>
          </Elements.Col>
          <Elements.Col md={5} sm={12} isMarginBottomNegative={false}>
            <Elements.ContainerButton isNewDS>
              <ButtonV2
                variant='primary'
                outlined
                data-testid='run-history-button-clear'
                size='medium'
                onClick={() => {
                  resetForm({
                    values: {
                      pipelineName: '',
                      projectId: ''
                    }
                  });
                }}
              >
                {i18n.t('action.clear').toUpperCase()}
              </ButtonV2>
            </Elements.ContainerButton>
          </Elements.Col>
        </Elements.Row>
      </Elements.Row>
    )}
  />
);

export default Header;
