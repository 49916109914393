export const resource = {
  type: 'graphql',
  componentProp: 'source',
  schemaField: 'options',
  query:
    'query accounts($realm: String!, $search: String) { accounts(realm: $realm, search: $search) {id description fields label accountType} }',
  response: {
    field: 'accounts',
    normalizeMap: {
      value: 'label',
      label: 'label'
    }
  }
};

const addAccountSectionOnFormSchema = (formSchema, accounts) => {
  const accountsSchema = {
    label: 'Accounts',
    schema: accounts.map(account => ({
      property: `accountLabels.${account.name}`,
      description: account.description,
      label: account.name,
      type: 'string',
      input: 'select',
      allowEmpty: true,
      resource
    }))
  };

  return formSchema.concat(accountsSchema);
};

export default addAccountSectionOnFormSchema;
