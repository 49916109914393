/* eslint-disable */

import { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import uuid from 'uuid/v4';
import { string, bool } from 'prop-types';
// Locals
import { FloatingLabel } from '../FloatingLabel';
import { GroupContext } from '../Group';

const Wrapper = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
`;

const LabelText = styled(FloatingLabel)``;

const Container = styled.div`
  &:focus-within {
    border: 2px solid
      ${props =>
        props.danger ? props.theme.colors.red500 : props.theme.colors.pri500};

    &:hover {
      border: 2px solid
        ${props =>
          props.danger ? props.theme.colors.red500 : props.theme.colors.pri500};
    }
  }
  font-family: ${props => props.theme.typography.fontFamily};
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  position: relative;
  height: 48px;
  border: 1px solid ${props => props.theme.colors.gra600};
  border-radius: 4px;
  align-items: center;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  &::before,
  &::after {
    box-sizing: border-box;
  }

  ${props =>
    props.danger &&
    css`
      &:focus-within ${LabelText} {
        color: ${props.theme.colors.red500};
      }
      &: hover {
        border: 2px solid ${props.theme.colors.red500};
      }
      border: 2px solid ${props.theme.colors.red500};
      margin: -1px;
    `};

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${props => props.theme.colors.gra400};
    `};
`;
const HelperText = styled.div`
  position: relative;
  color: ${props =>
    props.danger ? props.theme.colors.red500 : props.theme.colors.gra700};
  ${props =>
    props.actived &&
    !props.danger &&
    css`
      color: ${props => props.theme.colors.pri500};
    `};
  font-size: 12px;
  letter-spacing: 0.0333333333em;
  font-weight: 400;
  font-smoothing: antialiased;
  line-height: 24px;
  padding-left: 12px;
  box-sizing: border-box;

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.gra700};
    `};
`;

const WrapperLabel = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  left: 0;
  box-sizing: border-box;
  pointer-events: none;
  height: 100%;
`;

const InputText = styled.input`
  color: ${props =>
    props.danger ? props.theme.colors.gra800 : props.theme.colors.gra700};
  padding-right: 12px;
  padding-left: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  appearance: none;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  outline: 0;
  display: block;
  margin: 0;
  font-family: inherit;
  background: none;
  resize: none;
  font-size: 14px;
  background-color: ${props => props.theme.colors.white};
  -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.colors.white} inset;
  transition: background-color 5000s ease-in-out 0s;
  &::placeholder {
    opacity: ${props => (props.isActived ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  ${props =>
    props.endAdornment &&
    css`
      width: 96.9%;
      padding-right: 1px;
    `};
`;

const WrapperEndAdornment = styled.div`
  display: inline-block;
  right: inherit;
  cursor: pointer;
  margin-left: 9px;
  margin-right: 1.49%;
`;

export const Input = function ({
  placeholder,
  onFocus,
  onBlur,
  id,
  actived,
  endAdornment,
  renderLeadingIcon,
  clearButton,
  helperText,
  onChange,
  disabled,
  danger = false,
  labelSecondary = false,
  isFloatLabel = false,
  ...props
}) {
  const [isActived, setActived] = useState(actived);
  const [isValue, setValue] = useState(false);
  const { setGroupFocus } = useContext(GroupContext);

  const [generatorId] = useState(uuid());

  function renderHelperText() {
    if (helperText) {
      return (
        <HelperText disabled={disabled} actived={isActived} danger={danger}>
          {helperText}
        </HelperText>
      );
    }
  }

  function renderLabelText() {
    return (
      <LabelText
        disabled={disabled}
        danger={danger}
        isActived={isActived}
        isValue={isFloatLabel || isValue}
        secondary={labelSecondary}
        htmlFor={id || generatorId}
      >
        {placeholder}
      </LabelText>
    );
  }

  function renderEndAdornment() {
    return (
      <WrapperEndAdornment>
        {endAdornment ? endAdornment : null}
      </WrapperEndAdornment>
    );
  }

  function renderInput() {
    useEffect(() => {
      setValue(props.value && props.value.length !== 0);
    }, [props.value]);
    useEffect(() => {
      setValue(props.value && props.value.length !== 0);
    }, []);
    const handlerFocus = event => {
      setActived(true);
      setGroupFocus(true);
      if (onFocus) {
        onFocus(event);
      }
    };
    const handlerBlur = event => {
      setActived(false);
      setGroupFocus(false);
      if (onBlur) {
        onBlur(event);
      }
    };
    const handlerOnchange = event => {
      setValue(event.target.value.length !== 0);
      if (onChange) {
        onChange(event);
      }
    };
    return (
      <InputText
        disabled={disabled ? 'disabled' : ''}
        endAdornment={endAdornment}
        id={id || generatorId}
        isActived={isActived}
        onBlur={handlerBlur}
        onFocus={handlerFocus}
        danger={danger}
        onChange={handlerOnchange}
        {...props}
      />
    );
  }
  return (
    <Wrapper>
      <Container danger={danger} disabled={disabled} isActived={isActived}>
        {renderInput()}
        <WrapperLabel>{renderLabelText()}</WrapperLabel>
        {renderEndAdornment()}
      </Container>
      {renderHelperText()}
    </Wrapper>
  );
};

Input.propTypes = {
  danger: string,
  actived: bool
};

Input.defaultProps = {
  danger: '',
  actived: false
};
