import { theme, Menu, Icon as IconNative, Text } from '@digibee/beehive-ui'

import ListNative from '~/components/List';

export const Icon = theme.styled(IconNative, {
  fontSize: '16px',
  marginRight: '10px',
});

export const BadgeIcon = theme.styled(IconNative, {
  fontSize: '14px',
});

export const List = theme.styled(ListNative, {
  width: 'auto',
  borderRadius: '10px',
});

export const Item = theme.styled(ListNative.Item, {
  padding: '0 16px',
  display: 'flex',
  backgroundColor: '$gra25',
});

export const ItemText = theme.styled(Text, {
  fontSize: '$1',
  fontWeight: '300',
})

export const Profile = theme.styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '16px',
  marginBottom: '24px',
});

export const Name = theme.styled(Text, {
  width: '80%',
  wordBreak: 'break-word',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '400',
});

export const Email = theme.styled(Text, {
  lineHeight: 'normal',
  width: '80%',
  wordBreak: 'break-word',
  textAlign: 'center',
  color: '$gra600',
  fontSize: '12px',
  fontWeight: '300',
});

export const Timezone = theme.styled(Text, {
  marginTop: '16px',
  color: '$gra600',
  fontSize: '12px',
  fontWeight: '300',
});

export const MenuContent = theme.styled(Menu.Content, {
  height: 'auto',
  width: '256px',
  zIndex: '1002',
});

export const Badge = theme.styled('div', {
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  flexShrink: '0',
  userSelect: 'none',
  borderRadius: '50%',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '24px',
  height: '24px',
  backgroundColor: '$white',
  left: '65%',
  top: '-24px',
});
