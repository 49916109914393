import { Button, theme } from '@digibee/beehive-ui';

export const StyledFields = theme.styled('div', {
  padding: '10px 0px',
});

export const ButtonWrapper = theme.styled('div', {
  width: '100%',
  display: 'flex',
  padding: '10px 0px',
  justifyContent: 'center',
});

export const StyledButton = theme.styled(Button, {
  width: '100%',
  justifyContent: 'center',
})

export const StyledContainer = theme.styled('div');

export const FormContainer = theme.styled('form', {
  width: '100%',
});

export const StyledMessage = theme.styled('h2', {
  textAlign: 'left',
  lineHeight: '1.4',
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
  fontFamily: '$display',
});

export const MessageError = theme.styled('div');
