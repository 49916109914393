/* eslint-disable */

import { ApolloClient, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import storage from '~/common/helpers/storage';

// Locals
import onErrorHandlers from '~/common/helpers/onErrorHandlers';
import { GRAPHQL_SERVER, API_KEY } from './variables';
const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: GRAPHQL_SERVER
  });

  const authLink = setContext((_, { headers }) => {
    const user = storage.get('userData', true);
    const explanation =
      window.store.getState().dialogs?.confirmDialog?.context?.explanation;

    let additionalHeaders = {
      apiKey: API_KEY,
      authorization: user ? `${user.token}` : null
    };

    if (explanation) {
      additionalHeaders['x-dgb-explanation'] = explanation;
    }

    return {
      headers: {
        ...headers,
        ...additionalHeaders
      }
    };
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none'
    }
  };

  return new ApolloClient({
    link: ApolloLink.from([onErrorHandlers, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions
  });
};
const clientApollo = createApolloClient();

export default clientApollo;
