import { Input } from '@digibee/beehive-ui';
import get from 'lodash/get';
import { FormEvent } from 'react';
import { Link } from 'react-router-dom';

import PrivacyPolicy from '../../PrivacyPolicy';
import * as Elements from '../FormLogin.elements';
import { FormPageType } from '../FormLogin.enhancer';

import i18n from '~/common/helpers/i18n';

const Credentials = ({ values, loading, subdomain, realmParams, touched, onErrors, errors, handleChange, setFieldValue, handleSubmit }: FormPageType) => (
  <>
    {!subdomain && (
      <Elements.Fields>
        <Input
          data-testid='governance-realm-input-login-page'
          className='fs-mask'
          name='realm'
          value={values.realm}
          helperText={i18n.t(
            'scenes.login.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(onErrors(touched, errors, 'realm'))}`
            }
          )}
          invalid={Boolean(onErrors(touched, errors, 'realm'))}
          onBlur={event =>
            setFieldValue(
              'realm',
              get(event, 'target.value', '').toLowerCase()
            )
          }
          onChange={handleChange}
          label={i18n.t(
            'scenes.login.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t('scenes.login.labels.realm')}`
            }
          )}
          placeholder={i18n.t(
            'scenes.login.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t('scenes.login.labels.realm')}`
            }
          )}
        />
      </Elements.Fields>
    )}
    <Elements.Fields>
      <Input
        data-testid='governance-email-input-login-page'
        className='fs-mask'
        name='email'
        value={values.email}
        helperText={i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t(onErrors(touched, errors, 'email'))}`
          }
        )}
        invalid={Boolean(onErrors(touched, errors, 'email'))}
        autoComplete='new-password'
        onChange={handleChange}
        label={i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t('scenes.login.labels.email')}`
          }
        )}
        placeholder={i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t('scenes.login.labels.email')}`
          }
        )}
      />
    </Elements.Fields>
    <Elements.Fields>
      <Input
        data-testid='governance-password-input-login-page'
        className='fs-exclude'
        name='password'
        value={values.password}
        onChange={handleChange}
        autoComplete='new-password'
        helperText={i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t(onErrors(touched, errors, 'password'))}`
          }
        )}
        invalid={Boolean(onErrors(touched, errors, 'password'))}
        type='password'
        label={i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t('scenes.login.labels.password')}`
          }
        )}
        placeholder={i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t('scenes.login.labels.password')}`
          }
        )}
      />
    </Elements.Fields>
    <Elements.Fields>
      <Elements.LinksContainer>
        <Elements.StyledLink
          as={Link}
          to='/login/forgotpassword'
          data-testid='governance-forgot-password-link-login-page'
        >
          {i18n.t(
            'scenes.login.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.login.messages.prompt.forgot_password'
              )}`
            }
          )}
        </Elements.StyledLink>
        <Elements.StyledLink
          as={Link}
          to='/login/two-factor'
          data-testid='governance-two-factor-link-login-page'
        >
          {i18n.t('action.dont_have_access_to_two_factor_auth_link')}
        </Elements.StyledLink>
      </Elements.LinksContainer>
      <Elements.SubmitButton
        key='save'
        variant='primary'
        loading={loading}
        onClick={(event) => handleSubmit(event as unknown as FormEvent<HTMLFormElement>)}
        data-testid='governance-submit-button-login-page'
      >
        {i18n.t(
          'scenes.login.labels.upper_and_capitalize.capitalized',
          {
            item: `${i18n.t('scenes.login.actions.login')}`
          }
        )}
      </Elements.SubmitButton>
      {realmParams?.hasCustomerIdentityProvider && (
        <Elements.IdpButton
          onClick={() => {
            window.location.href =
              `${realmParams?.redirectUrl}&locale=${i18n.t('label.fusion_localization_key')}`;
          }}
          data-testid='governance-idp-link-login-page'
        >
          {i18n.t('action.login_identity_provider')}
        </Elements.IdpButton>
      )}
      <PrivacyPolicy />
    </Elements.Fields>
  </>
);

export default Credentials;
