import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const pulseKeyframe = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const waveKeyframe = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    /* +0.5s of delay between each loop */
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const Root = styled.div`
  display: block;
  background-color: ${p => p.theme.colors.gra50};
  height: 1.2em;

  width: ${p => p.width};
  height: ${p => p.height};

  ${({ variant }) =>
    variant == 'text' &&
    css`
      margin-top: 0;
      margin-bottom: 0;
      transform-origin: 0 55%;
      height: 1.2em;
      transform: scale(1, 0.6);
      border-radius: 10px;
      &:empty:before: {
        content: \\00a0;
      }
    `};

  ${({ variant }) =>
    variant == 'circular' &&
    css`
      border-radius: 50%;
    `};

  ${({ hasChildren }) =>
    hasChildren &&
    css`
      & > * {
        visibility: hidden;
      }

      ${({ width }) =>
        !width &&
        css`
          max-width: fit-content;
        `};

      ${({ height }) =>
        !height &&
        css`
          height: auto;
        `};
    `};

  ${({ animation }) =>
    animation == 'pulse' &&
    css`
      animation: ${pulseKeyframe} 1.5s ease-in-out 0.5s infinite;
    `};

  ${({ animation }) =>
    animation == 'wave' &&
    css`
      position: relative;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      &::after {
        animation: ${waveKeyframe} 1.6s linear 0.5s infinite;
        background: linear-gradient(
          90deg,
          transparent,
          ${p => p.theme.colors.gra300},
          transparent
        );
        content: '';
        position: absolute;
        transform: translateX(-100%);
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
    `};
`;

const Skeleton = React.forwardRef((props, ref) => (
  <Root ref={ref} hasChildren={Boolean(props.children)} {...props} />
));

Skeleton.defaultProps = {
  animation: 'wave',
  variant: 'text'
};

Skeleton.propTypes = {
  /**
   * The animation.
   * If `false` the animation effect is disabled.
   */
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
  /**
   * Optional children to infer width and height from.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The type of content that will be rendered.
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['circular', 'rectangular', 'text'])
  ]),
  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Skeleton;
