import { Text } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { BaseComponent } from '@digibee/flow';
import { useSelector } from '@xstate/react';
import { useMemo } from 'react';
import { useSelector as useReduxSelector } from 'react-redux';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import MockTag from '~/components/MockTag';
import { MockType } from '~/entities/Mock';

type ComponentNodeProps = {
  node: TreeNode<BaseComponent>;
};

const ComponentNode = ({ node }: ComponentNodeProps) => {
  const { BUILD_CONNECTOR_MOCKING } = featureFlagConstants;
  const { treatments } = useFeatureFlag([BUILD_CONNECTOR_MOCKING]);
  const isConnectorMockingEnabled =
    treatments[BUILD_CONNECTOR_MOCKING].treatment === 'on';

  const mocks = useReduxSelector(
    // @ts-ignore
    state => state.designPipeline.pipeline?.mocks
  );
  const hasMockActive = mocks?.find(
    (mock: MockType) => mock.connectorId === node.id && mock.enabled
  );

  const actor = useTreeContext(state => state.actor);
  const actionsSlot = useTreeContext(state => state.actionsSlot);
  const mapConnectorImage = useTreeContext(state => state.mapConnectorImage);
  const onItemDoubleClick = useTreeContext(state => state.onItemDoubleClick);

  const isCurrentLevelComponent = useSelector(
    actor,
    state => state.context.currentLevelComponent?.id() === node.id
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state =>
    state.context.currentLevelComponent
      ? node.item.comesFromComponent(state.context.currentLevelComponent.id())
      : false
  );

  // eslint-disable-next-line no-nested-ternary
  const emphasis = isCurrentLevelComponent
    ? 'component'
    : comesFromCurrentLevelComponent
    ? 'children'
    : undefined;

  const actionsSlotRendered = actionsSlot?.({
    item: node.item
  });

  const { shape, image } = useMemo(
    () => mapConnectorImage(node.item),
    [node.item]
  );

  return (
    <Elements.Container data-testid='flow-tree-node-container'>
      <Elements.Item
        onDoubleClick={() => {
          onItemDoubleClick(node.item);
        }}
        depthLeft={node.depth}
        emphasis={emphasis}
        data-testid='build-flow-tree-node-item'
      >
        <Elements.Shape2 shape={shape} image={image} />
        {isConnectorMockingEnabled && hasMockActive && (
          <Elements.MockTagContainer>
            <MockTag isFlowTree />
          </Elements.MockTagContainer>
        )}
        <Elements.Label>
          <Text variant='b3' css={{ fontWeight: 400 }}>
            {node.label}
          </Text>
        </Elements.Label>
        {iff(!!actionsSlotRendered, () => (
          <Elements.FloatAction>{actionsSlotRendered}</Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default ComponentNode;
