import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import i18n from '~/common/helpers/i18n';
import IconNative from '~/components/IconCommunity';
import ListNative from '~/components/List';
import Menu from '~/components/Menu';
import Text from '~/components/Text';

const Label = styled(Text)`
  width: fit-content;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  background-color: ${p => p.theme.colors.pri25};
  color: ${p => p.theme.colors.pri500};
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;

  ${({ align }) =>
    align === 'left' &&
    css`
      padding-left: 24px;
      border-radius: 0px 4px 4px 0px;
    `};

  ${({ align }) =>
    align === 'right' &&
    css`
      padding-right: 24px;
      border-radius: 4px 0px 0px 4px;
    `};
`;

const Indicator = styled(IconNative)`
  font-size: 12px;
  margin-left: 8px;
  color: ${p => p.theme.colors.pri500};
`;

const Icon = styled(IconNative)`
  font-size: 16px;
  margin-right: 16px;
  color: ${p => p.theme.colors.pri500};
`;

const List = styled(ListNative)`
  min-width: 116px;
  border-radius: 10px;
`;

const Item = styled(ListNative.Item)`
  padding: 0 16px;
`;

const useControlledState = (value, defaultValue) => {
  if (value === undefined) {
    const [state, setState] = useState(defaultValue);
    return [state, setState, false];
  }
  return [value, () => {}, true]; // just bypass
};

const SmallSelect = ({
  options,
  'data-testid': dataTestId,
  value: valueProp,
  onChange,
  placeholder,
  iconName,
  defaultValue,
  align,
  zIndexValue
}) => {
  const [value, setValue] = useControlledState(valueProp, defaultValue);

  const handleOnChange = optionValue => {
    setValue(optionValue);
    onChange(optionValue);
  };

  // eslint-disable-next-line eqeqeq
  const selectedOption = options.filter(option => option?.value == value)[0];

  return (
    <Menu
      data-testid={dataTestId}
      zIndexValue={zIndexValue}
      component={({ toggle, opened }) => (
        <Label
          align={align}
          onClick={toggle}
          data-testid={`${dataTestId}-selected`}
        >
          {iconName && <Icon name={iconName} faType='solid' />}
          {selectedOption ? selectedOption?.label : placeholder}
          <Indicator name={opened ? 'AngleUp' : 'AngleDown'} faType='solid' />
        </Label>
      )}
    >
      {iff(options.length, () => (
        <List inset noHairlines>
          {options?.map(option => (
            <Item
              data-testid={`${dataTestId}-button-${option.label}`}
              onClick={() => handleOnChange(option.value)}
            >
              <Text align='left'>{option?.label}</Text>
            </Item>
          ))}
        </List>
      ))}
    </Menu>
  );
};

SmallSelect.defaultProps = {
  options: [],
  onChange: () => null,
  placeholder: i18n.t('common.actions.select'),
  iconName: '',
  align: 'center',
  'data-testid': 'small-select',
  value: '',
  defaultValue: '',
  zIndexValue: 1000
};

SmallSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf([PropTypes.string]),
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  defaultValue: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  'data-testid': PropTypes.string,
  zIndexValue: PropTypes.number
};

export default SmallSelect;
