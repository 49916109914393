import styled, { css } from 'styled-components';

import ellipse from '~/common/styled/mixins/ellipsis';
import _Button from '~/components/Button';
import { DialogFooter, Title as TitleNative } from '~/components/Dialog';
import _Text from '~/components/Text';

export const Button = styled(_Button)`
  ${props =>
    props.variant === 'danger' &&
    css`
      span {
        color: ${props.theme.colors.red500};
      }
    `}
  ${props =>
    props.type === 'link' &&
    props.variant === '' &&
    css`
      color: ${props.theme.colors.gra400};
    `}
`;

export const Text = styled(_Text)`
  color: ${props => props.theme.colors.gra700};
`;

export const StyledDialogFooter = styled(DialogFooter)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
`;

export const Separator = styled.div`
  padding-left: ${props => props.theme.spacings.bit};
`;

export const Title = styled(TitleNative)`
  ${ellipse('99%')};
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
