import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Ripple from '../Ripple';
import Label from './components/Label';
import * as Elements from './Switch.elements';

const Switch = React.forwardRef((props, ref) => {
  const {
    checked: checkedProp,
    onChange,
    onFocus,
    onBlur,
    defaultChecked,
    disabled,
    rippled,
    label,
    subtext,
    ...restProps
  } = props;
  const initialValue = checkedProp || defaultChecked;
  const [checked, setChecked] = useState(initialValue);

  const onChangeHandler = event => {
    setChecked(!checked);
    if (onChange) onChange(event, checked);
  };

  const onFocusHandler = event => {
    if (onFocus) onFocus(event);
  };

  const onBlurHandler = event => {
    if (onBlur) onBlur(event, checked);
  };

  return (
    <Label text={label} subText={subtext}>
      <Elements.SwitchRoot disabled={disabled}>
        <Elements.SwitchButton
          disabled={disabled}
          checked={checked}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
        >
          <Elements.SwitchButtonContent>
            <Elements.Input
              type='checkbox'
              disabled={disabled}
              checked={checked}
              value=''
              onChange={onChangeHandler}
              ref={ref}
              {...restProps}
            />
            <Elements.SwitchThumb />
          </Elements.SwitchButtonContent>
          {rippled && <Ripple />}
        </Elements.SwitchButton>
        <Elements.SwitchTrack checked={checked} />
      </Elements.SwitchRoot>
    </Label>
  );
});

Switch.defaultProps = {
  label: '',
  subtext: '',
  checked: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  disabled: false,
  defaultChecked: false,
  rippled: true
};

Switch.propTypes = {
  label: PropTypes.string,
  subtext: PropTypes.string,
  checked: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  rippled: PropTypes.bool
};

export default Switch;
