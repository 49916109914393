import { Text, Flex, Tabs, Button } from '@digibee/beehive-ui';
import { FC } from 'react';
import { Outlet, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import i18n from '~/common/helpers/i18n';
import Role from '~/components/Role';

const ConnectivityView: FC = () => {
  const location = useLocation();
  const activeTab = location.pathname.split('/').pop();

  return (
    <Flex
      direction='column'
      style={{ height: 'calc(100vh - 170px)', overflowY: 'auto' }}
    >
      <Flex
        style={{
          padding: '32px 24px 24px'
        }}
        justify='between'
      >
        <Text
          data-testid='connectivity-title-text'
          variant='h2'
          css={{ fontWeight: '$regular' }}
        >
          {i18n.t('scenes.connectivity.title')}
        </Text>
        <a
          data-testid='connectivity-read-about-ztna-button'
          href={i18n.t('action.read_about_ztna_url')}
          target='_blank'
          rel='noopener noreferrer'
          style={{ textDecoration: 'none' }}
        >
          <Button variant='primary' outlined>
            {i18n.t('scenes.connectivity.cta.actions.read-more')}
          </Button>
        </a>
      </Flex>
      <Tabs.Root
        style={{
          padding: '0 24px',
          width: 'auto'
        }}
        defaultValue='edge-routers'
        value={activeTab}
      >
        <Tabs.List>
          <Role name='ZTNA:READ'>
            <NavLink style={{ textDecoration: 'none' }} to='edge-routers'>
              <Tabs.Trigger
                data-testid='connectivity-edge-router-tab'
                value='edge-routers'
              >
                {i18n.t('scenes.connectivity.tabs.edge-router')}
              </Tabs.Trigger>
            </NavLink>
          </Role>
          <NavLink style={{ textDecoration: 'none' }} to='network-mapping'>
            <Tabs.Trigger
              data-testid='connectivity-network-mapping-tab'
              value='network-mapping'
            >
              {i18n.t('scenes.connectivity.tabs.network-mapping')}
            </Tabs.Trigger>
          </NavLink>
        </Tabs.List>
        <Tabs.Content value='edge-routers'>
          <Outlet />
        </Tabs.Content>
        <Tabs.Content value='network-mapping'>
          <Outlet />
        </Tabs.Content>
      </Tabs.Root>
    </Flex>
  );
};

export default ConnectivityView;
