/* eslint-disable react/prop-types */
import styled, { keyframes } from 'styled-components';

import theme from '~/common/styled/theme';

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

type LoaderProps = {
  size?: 'small' | 'medium' | 'large';
  background?: string;
};

const Loader = styled.img<LoaderProps>`
  background-color: ${props => props.background || ''};
  animation: ${blink} 2s linear infinite;
  padding: 30px;
  width: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.size === 'small'
      ? '20px'
      : props.size === 'medium'
      ? '30px'
      : '50px'};
`;

type StyledLoadingProps = {
  background?: string;
};

const StyledLoading = styled.div<StyledLoadingProps>`
  align-items: center;
  background-color: ${props => props.background || ''};
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  outline: none;
  user-select: none;
  z-index: 9000;
`;

type LoadingProps = {
  active: boolean;
  size?: 'small' | 'medium' | 'large';
  background?: string;
};

const Loading = ({ active, size, ...rest }: LoadingProps) => {
  if (active === false) return null;

  return (
    <StyledLoading {...rest}>
      <Loader
        size={size}
        src={theme.images.pictureNewLogoDigibeeWithoutText}
        {...rest}
      />
    </StyledLoading>
  );
};

Loading.defaultProps = {
  background: 'white',
  size: 'large'
};

export default Loading;
