import { choose, otherwise, when } from '@digibee/control-statements';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--neutral-neu-400, #6b7072);
  /* align-self: flex-start; */
`;

type Tag = {
  isFlowTree: boolean;
};

export const Tag = styled.div<Tag>`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--black-white-white, var(--Color, #fff));
  font-family: 'Noto Sans Display';
  text-align: center;
  font-size: ${props => (props.isFlowTree ? '10px' : '12px')};
  font-style: normal;
  font-weight: ${props => (props.isFlowTree ? 500 : 700)};
  height: 16px;
  width: 16px;
  line-height: 0px;
`;

export const TagTooltip = styled.div`
  font-family: 'Noto Sans Display';
  font-size: 12px;
  position: absolute;
  right: 50%;
  left: 50%;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #000;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
`;
