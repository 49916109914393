import { gql } from '@apollo/client';
import axios from 'axios';

import clientApollo from './clientApollo';

import { API_KEY, URL } from '~/common/helpers/environment';

const profile = {
  get: ({ realm }) =>
    clientApollo.query({
      query: gql`
        query profile($realm: String!) {
          profile(realm: $realm) {
            secret
            secretBase32Encoded
            twoFactorEnabled
            methodId
          }
        }
      `,
      variables: {
        realm
      }
    }),
  saveProfileImage: async ({ token, realm, file, id } = {}) => {
    try {
      const formData = new FormData();
      formData.append('file', file, `${id}.jpg`);
      const { data } = await axios.put(
        `${URL}/design/realms/${realm}/user/profile/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return data?.contentName;
    } catch (e) {
      throw new Error(e);
    }
  },
  getUser: ({ realm }) =>
    clientApollo.query({
      query: gql`
        query getProfile($realm: String!) {
          getProfile(realm: $realm) {
            id
            firstName
            lastName
            imageUrl
            timezone
            email
          }
        }
      `,
      variables: {
        realm
      }
    }),
  edit(params) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editProfile($realm: String!, $profile: UserProfileInput) {
          editProfile(realm: $realm, profile: $profile) {
            id
            firstName
            lastName
            imageUrl
            timezone
            email
          }
        }
      `,
      variables: {
        ...params
      }
    });
  },
  save(params) {
    return clientApollo.mutate({
      mutation: gql`
        mutation saveProfile(
          $realm: String!
          $code: String!
          $secret: String!
        ) {
          saveProfile(realm: $realm, code: $code, secret: $secret)
        }
      `,
      variables: {
        ...params
      }
    });
  },
  remove(params) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteProfile($realm: String!, $code: String!, $methodId: String) {
          deleteProfile(realm: $realm, code: $code, methodId: $methodId)
        }
      `,
      variables: {
        ...params
      }
    });
  },
  newPassword(params) {
    return clientApollo.mutate({
      mutation: gql`
        mutation newPassword(
          $realm: String!
          $currentPassword: String!
          $newPassword: String!
          $email: String!
        ) {
          newPassword(
            realm: $realm
            currentPassword: $currentPassword
            newPassword: $newPassword
            email: $email
          )
        }
      `,
      variables: {
        ...params
      }
    });
  }
};

export default profile;
