import { theme } from '@digibee/beehive-ui';

import i18n from '~/common/helpers/i18n';

const StyledContainer = theme.styled('div', {
  paddingTop: '15px',
  fontFamily: '$display',
  fontSize: '$1',
  color: '$neu500',
});

const Link = theme.styled('a', {
  marginLeft: '2px',
  marginRight: '2px',
});

const PrivacyPolicy = () => (
  <StyledContainer>
    {i18n.t('scenes.login.guidance.privacy_policy')}
    <Link href='https://policies.google.com/privacy'>Privacy Policy</Link>
    <Link href='https://policies.google.com/terms'>Terms of Service</Link>
  </StyledContainer>
);

export default PrivacyPolicy;
