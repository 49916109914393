import Flow, { FlowSpec, LevelComponent, isParallelSpec } from '@digibee/flow';

import { DisconnectedFlowSpec } from '~/entities/DisconnectedFlowSpec';

const parseParallelsExecutions = (fullFlowSpec: FlowSpec) =>
  Object.keys(fullFlowSpec).reduce((accFlow, currentTrackName) => {
    const components = fullFlowSpec[currentTrackName].map(component => {
      if (isParallelSpec(component)) {
        const parallelExecutions = component.params.parallelExecutions || '[]';
        const parsedExecutions =
          typeof parallelExecutions === 'string'
            ? JSON.parse(parallelExecutions)
            : parallelExecutions;
        const spec = {
          ...component,
          params: {
            ...component.params,
            parallelExecutions: parsedExecutions
          }
        };

        return spec;
      }

      return component;
    });

    return {
      ...accFlow,
      [currentTrackName]: components
    };
  }, {});

const fixLevelComponents = (flow: Flow) =>
  flow.reduceComponents((accFlow, currentComponent) => {
    try {
      const component = accFlow.getComponent(currentComponent.id());

      if (!component || !(component instanceof LevelComponent)) {
        return accFlow;
      }

      const componentSpec = component.spec();
      const oldOnProcessTrackName = componentSpec.params.onProcess;
      const oldOnExceptionTrackName = componentSpec.params.onException;
      const onProcessTrackSpec = accFlow
        .getTrack(oldOnProcessTrackName)
        ?.spec();
      const onExceptionTrackSpec = accFlow
        .getTrack(oldOnExceptionTrackName)
        ?.spec();
      const newOnProcessTrackName = `${componentSpec.id}-onProcessTrack`;
      const newOnExceptionTrackName = `${componentSpec.id}-onExceptionTrack`;
      const flowWithFixedComponent = accFlow.updateComponent(
        component.id(),
        currentComponentSpec => ({
          ...currentComponentSpec,
          params: {
            ...currentComponentSpec.params,
            onProcess: newOnProcessTrackName,
            onException: newOnExceptionTrackName
          }
        }),
        true
      );
      const flowWithFixedTracks = flowWithFixedComponent
        .iff(
          !!onProcessTrackSpec && onProcessTrackSpec.length > 0,
          currentFlow =>
            currentFlow.setIn([newOnProcessTrackName], onProcessTrackSpec)
        )
        .iff(
          !!onExceptionTrackSpec && onExceptionTrackSpec.length > 0,
          currentFlow =>
            currentFlow.setIn([oldOnExceptionTrackName], onExceptionTrackSpec)
        );

      return flowWithFixedTracks.without([
        ...(oldOnProcessTrackName !== newOnProcessTrackName
          ? [oldOnProcessTrackName]
          : []),
        ...(oldOnExceptionTrackName !== newOnExceptionTrackName
          ? [oldOnExceptionTrackName]
          : [])
      ]);
    } catch (error) {
      return accFlow;
    }
  }, flow);

const mergeDisconnectedFlowsWithFlowSpec = (
  connectedFlowSpec: FlowSpec = {},
  disconnectedFlowSpecs: DisconnectedFlowSpec[] = [],
  disableFixLevelComponents = false
) => {
  const disconnectedFlowSpecsWithReplacedRoot = JSON.parse(
    JSON.stringify(disconnectedFlowSpecs).replace(
      /disconnected-root/g,
      'disconnected-start'
    )
  ) as DisconnectedFlowSpec[];
  const fullFlowSpec = disconnectedFlowSpecsWithReplacedRoot.reduce(
    (accFlowSpec, disconnectedFlowSpec) => ({
      ...accFlowSpec,
      ...disconnectedFlowSpec.flowSpec
    }),
    connectedFlowSpec
  );
  const parsedFlowSpec = parseParallelsExecutions(fullFlowSpec);
  const flow = new Flow(parsedFlowSpec);

  if (disableFixLevelComponents) return flow.spec();

  const flowWithFixedLevelComponents = fixLevelComponents(flow);

  return flowWithFixedLevelComponents.spec();
};

export default mergeDisconnectedFlowsWithFlowSpec;
