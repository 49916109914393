import styled, { css } from 'styled-components';

import TextNative from '~/components/Text';

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px 16px 8px;
  min-height: 72px;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
  &:hover {
    background: ${props => props.theme.colors.pri50};
  }
`;

type DetailsProps = {
  weight: string;
  type: string;
  size: string;
  as: string;
  disabled: boolean;
  children: any;
};

export const DetailsDescription = styled(TextNative)<DetailsProps>`
  padding-top: 8px;
  ${props =>
    props.disabled
      ? css`
          curso: default;
          pointer-events: none;
          color: ${props.theme.colors.gra300};
        `
      : ''};
`;
