import isEquals from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';

const enhancer = withHooks((props: any) => {
  const open = useSelector(
    (state: any) => state.runtime.modalBetaCreateDeploy,
    isEquals
  );
  const dispatch: any = useDispatch();
  const actions = {
    createDeploy: (variant: any, confirm: any) => {
      if (variant.type === 'redeploy') {
        dispatch.runtime.redeploy(variant, confirm);
      } else {
        dispatch.runtime.create(variant, confirm);
      }
    }
  };
  return {
    ...props,
    ...actions,
    open
  };
});

export default enhancer;
