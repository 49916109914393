/* eslint-disable  */
import React, { useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import IconNative from '../IconCommunity';

import Ripple from '../Ripple';
import { ButtonGroupContext } from '../ButtonGroup';
import { ButtonToolbarContext } from '../ButtonToolbar';
import { iff } from '@digibee/control-statements';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const scale = keyframes`
  0% {
    opacity: 0.5;
    transform: scale(3);
  }
  40% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const SizeHeight = (size = 'md') => {
  const height = {
    xs: '28px',
    sm: '30px',
    md: '34px',
    lg: '40px',
    xl: '48px'
  };
  return height[size];
};

const mapsColors = {
  primary: 'pri500',
  success: 'gre500',
  danger: 'red500',
  warning: 'yel500',
  complementary: 'blue500'
};

const Icon = styled(IconNative)`
  font-size: 16px;
  color: ${props => props.theme.colors.white};

  ${props =>
    ['outlined'].includes(props.emphasis) &&
    css`
      color: ${props => props.theme.colors[mapsColors[props.type]]};
    `}

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.gra400};
    `}
`;

const ButtonIsIcon = ({ theme, emphasis, iconName }) => css`
  ${iconName &&
  !['text'].includes(emphasis) &&
  css`
    padding: 0 ${theme.spacings.mega} 0 0;
    line-height: 0;
  `}
`;

export const StyledButton = styled.button`
  --primary-color: ${props => props.theme.button.backgroundColor};
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-family: ${props => props.theme.button.fontFamily};
  font-weight: ${props => props.theme.button.fontWeight};
  height: 2em;
  justify-content: center;
  line-height: 2.1em;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 ${props => props.theme.button.paddingHorizontal};
  position: relative;
  text-align: center;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  transition: all 0.2s cubic-bezier(0.06, 0.67, 0.37, 0.99);
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  min-height: ${({ size }) => SizeHeight(size)};
  min-width: ${({ size }) => SizeHeight(size)};
  ${ButtonIsIcon};
  ${props =>
    props.toolbarExists === true
      ? css`
          margin-left: ${props.spacing}px;

          &:first-of-type {
            margin-left: 0px;
          }
        `
      : ''}

  ${props =>
    props.groupExists === true
      ? css`
          box-shadow: none;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          margin-left: 0;
          border-radius: 0;

          &:first-of-type {
            border-left: 1px solid ${props.theme.button.backgroundColor};
            border-radius: 3px 0 0 3px;
          }

          &:last-of-type {
            border-radius: 0 3px 3px 0;
            margin-right: 0;
          }
        `
      : ''}

  ${props =>
    props.fluid === true
      ? css`
          width: 100%;
        `
      : ''}

  ${props =>
    props.active === false
      ? css`
          color: ${props.theme.colors.gra500};
          cursor: not-allowed;
          opacity: 0.8;
          user-select: all;
        `
      : ''}

  ${({ size, theme }) => {
    switch (size) {
      case 'xs':
        return css`
          font-size: calc(${theme.button.fontSize} * 0.7);
          ${Icon} {
            font-size: 14px;
          }
        `;
      case 'sm':
        return css`
          font-size: calc(${theme.button.fontSize} * 0.85);
          ${Icon} {
            font-size: 14px;
          }
        `;
      case 'lg':
        return css`
          font-size: calc(${theme.button.fontSize} * 1.1);
          ${Icon} {
            font-size: 17px;
          }
        `;
      case 'xl':
        return css`
          font-size: calc(${theme.button.fontSize} * 1.35);
          ${Icon} {
            font-size: 18px;
          }
        `;
      case 'md':
      default:
        return css`
          font-size: ${theme.button.fontSize};
        `;
    }
  }}

  ${props =>
    props.primary === true
      ? css`
          --primary-color: ${props.theme.colors.pri500};
          --primary-color-opacity: ${props.theme.colors.pri500}10;
        `
      : ''}

  ${props =>
    props.complementary === true
      ? css`
          --primary-color: ${props.theme.colors.blu500};
          --primary-color-opacity: ${props.theme.colors.blu500}10;
        `
      : ''}

  ${props =>
    props.success === true
      ? css`
          --primary-color: ${props.theme.colors.gre500};
          --primary-color-opacity: ${props.theme.colors.gre500}10;
        `
      : ''}

  ${props =>
    props.warning === true
      ? css`
          --primary-color: ${props.theme.colors.yel500};
          --primary-color-opacity: ${props.theme.colors.yel500}10;
        `
      : ''}

  ${props =>
    props.danger === true
      ? css`
          --primary-color: ${props.theme.colors.red500};
          --primary-color-opacity: ${props.theme.colors.red500}10;
        `
      : ''}
  ${props =>
    props.secondary === true
      ? css`
          --primary-color: ${props.theme.colors.gra500};
          --primary-color-opacity: ${props.theme.colors.gra500}10;
        `
      : ''}


  ${props =>
    props.link === true
      ? css`
          background-color: transparent;
          box-shadow: none;
          ${props =>
            !props.active &&
            css`
              user-select: all;
            `}
          color: ${
            props.active ? props.theme.colors.pri500 : props.theme.colors.gra400
          };

          &:hover {
            box-shadow: none;
          },
        `
      : ''}

  ${({ emphasis, size, theme, ...types }) => {
    switch (emphasis) {
      case 'icon':
        return css`
          border-radius: 50%;
          color: var(--primary-color);
          padding: 0 ${theme.button.emphasis.text.paddingHorizontal};
          text-align: center;
          min-height: 36px;
          min-width: 36px;
          ${
            types['data-disabled'] &&
            css`
              curso: default;
              pointer-events: none;
            `
          }
          &:hover {
            background-color: ${({ theme }) => `${theme.colors.gra25}`};
            box-shadow: none;
          },
        `;
      case 'text':
        return css`
          color: var(--primary-color);
          padding: 0 10px;

          &:hover {
            background-color: var(--primary-color-opacity);
            box-shadow: none;
          },
        `;
      case 'outlined':
        return css`
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          min-width: 64px;
          user-select: none;
          ${types['data-disabled'] &&
          css`
            border: 1px solid ${theme.colors.gra400};
            color: ${theme.colors.gra400};
          `}
          &:hover,
          &:focus {
            background-color: var(--primary-color-opacity);
            box-shadow: none;
          }
        `;
      case 'contained':
      default:
        if (
          [
            'primary',
            'secondary',
            'complementary',
            'success',
            'danger',
            'warning'
          ].find(type => types[type])
        ) {
          return css`
            background-color: var(--primary-color);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            color: ${props => props.theme.colors.white};
            min-width: 64px;
          `;
        }

        return css`
          background-color: var(--primary-color);
          color: theme.button.color;
        `;
    }
  }}

  ${({ rounded, size }) =>
    rounded &&
    css`
      border-radius: 50%;
      text-align: center;
      padding: 0;
      min-width: 0;
      width: ${SizeHeight(size)};
      height: ${SizeHeight(size)};
    `}

  ${({ extended, theme, iconName, emphasis, size, rounded }) =>
    extended &&
    css`
      ${rounded && `border-radius: calc(${SizeHeight(size)} * 0.5);`}
      width: auto;
      height: auto;
      min-width: 36px;
      padding: ${iconName && !['text'].includes(emphasis)
        ? `0 ${theme.button.emphasis.text.paddingHorizontal} 0 0`
        : `0 ${theme.button.emphasis.text.paddingHorizontal}`};
    `}
`;

const Overlay = styled.div`
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-name: ${scale};
  background-color: var(--primary-color);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  ${({ emphasis }) =>
    emphasis == 'outlined' &&
    css`
      background-color: #fff;
    `}
`;
const WrapperIcon = styled.div`
  display: inline;
  padding: 0 ${props => props.theme.spacings.byte};
`;
const Loading = styled(props => <FontAwesomeIcon {...props} />)`
  animation-duration: 0.65s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  font-size: 1rem;
  margin: 10px 0 0;
`;

const WrapperText = styled.span`
  display: flex;
  align-items: center;
  line-height: 0;
  min-height: 20px;
`;

const Button = ({
  children,
  disabled,
  loading,
  is,
  rippled,
  iconName,
  iconNotification,
  emphasis,
  ...rest
}) => {
  const { groupExists } = useContext(ButtonGroupContext);
  const { spacing, toolbarExists } = useContext(ButtonToolbarContext);

  const types = {};

  [
    'primary',
    'secondary',
    'complementary',
    'success',
    'danger',
    'warning',
    'link'
  ].map(type => {
    types[type] = rest.type === type;
    return true;
  });

  const handleClick = event => {
    if (disabled === true || loading === true) {
      return event.preventDefault();
    }

    rest.onClick(event);
  };

  const renderRipple = () => {
    if (rippled === false || disabled === true || loading === true) return null;
    return <Ripple />;
  };

  const renderLoading = () => {
    if (loading === false || disabled === true) return null;
    return (
      <Overlay emphasis={emphasis}>
        <Loading icon={faSpinnerThird} />
      </Overlay>
    );
  };

  return (
    <StyledButton
      active={disabled === false}
      data-disabled={disabled}
      groupExists={groupExists}
      spacing={spacing}
      toolbarExists={toolbarExists}
      iconName={iconName}
      {...rest}
      {...types}
      emphasis={emphasis}
      onClick={handleClick}
      type={is}
    >
      {iff(iconName && !['text'].includes(emphasis), () => (
        <WrapperIcon>
          <Icon
            disabled={disabled}
            name={iconName}
            {...omit(rest, 'data-testid')}
            emphasis={emphasis}
            isNotification={iconNotification}
          />
        </WrapperIcon>
      ))}
      <WrapperText>{children}</WrapperText>
      {renderLoading()}
      {renderRipple()}
    </StyledButton>
  );
};

Button.defaultProps = {
  children: [],
  disabled: false,
  emphasis: 'contained',
  fluid: false,
  is: 'button',
  loading: false,
  onClick: () => {},
  rippled: true,
  size: 'md',
  title: false,
  type: false,
  spacing: 10,
  uppercase: false,
  iconName: '',
  iconNotification: false,
  rounded: false,
  'data-testid': 'button'
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string]),

  disabled: PropTypes.bool,

  /**
   * The reason for alert and styled.
   *
   * @type { 'icon' | 'text' | 'outlined' | 'contained'}
   */
  emphasis: PropTypes.oneOfType([PropTypes.string]),

  fluid: PropTypes.bool,

  is: PropTypes.string,

  loading: PropTypes.bool,

  onClick: PropTypes.func,

  /**
   * The badges size options.
   *
   * @type {'xs' | 'sm' | 'md' | 'lg' | 'xl'}
   */
  size: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  rippled: PropTypes.bool,

  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /**
   * The reason for alert and styled.
   *
   * @type { 'primary' | 'complementary' | 'success' | 'danger' | 'warning'}
   */
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  spacing: PropTypes.number,

  uppercase: PropTypes.bool,
  /**
   * The reason for icon.
   *
   * @type icon aplication
   */
  iconName: PropTypes.string,
  iconNotification: PropTypes.bool,
  rounded: PropTypes.bool,
  'data-testid': PropTypes.string
};

export default Button;
