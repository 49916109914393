import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const messages = {
  messagesByKey: async params => {
    try {
      const response = await clientApollo.query({
        query: gql`
          query messagesByKey(
            $realm: String!
            $key: String
            $environment: String
          ) {
            messagesByKey(realm: $realm, key: $key, environment: $environment) {
              body
              processorStepName
              processorType
              elapsed
            }
          }
        `,
        variables: {
          ...params
        }
      });
      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  reexecute: async params => {
    try {
      await clientApollo.mutate({
        mutation: gql`
          mutation reexecutePipeline(
            $realm: String!
            $pipeName: String!
            $version: Int!
            $environment: String!
            $payload: JSON!
          ) {
            reexecutePipeline(
              realm: $realm
              pipeName: $pipeName
              version: $version
              environment: $environment
              payload: $payload
            )
          }
        `,
        variables: {
          ...params
        }
      });
    } catch (e) {
      throw new Error(e);
    }
  },
  fetch: async params => {
    try {
      const messages = await clientApollo.query({
        query: gql`
          query messages(
            $realm: String!
            $environment: String!
            $dateFrom: String
            $dateTo: String
            $messageType: String
            $pipelineKey: String
            $pipelineName: String
            $pipelineVersion: String
            $payloadSearch: String
            $jwtHash: String
            $jwtUser: String
            $errorCode: String
            $source: String
            $searchAfterTimestamp: String
            $searchAfterId: String
          ) {
            messages(
              realm: $realm
              environment: $environment
              dateFrom: $dateFrom
              dateTo: $dateTo
              messageType: $messageType
              pipelineKey: $pipelineKey
              pipelineName: $pipelineName
              pipelineVersion: $pipelineVersion
              payloadSearch: $payloadSearch
              searchAfterId: $searchAfterId
              searchAfterTimestamp: $searchAfterTimestamp
              jwtHash: $jwtHash
              jwtUser: $jwtUser
              errorCode: $errorCode
              source: $source
            ) {
              id
              request
              response
              messageType
              key
              pipeline_name
              pipeline_version
              pipeline_source
              elapsed_time
              request_timestamp
              response_timestamp
              jwt
              jwt_user
              request_size_bytes
              response_size_bytes
              pipeline_id
            }
          }
        `,
        variables: {
          ...params
        }
      });
      if (messages?.errors) {
        throw new Error(messages.errors);
      }
      return messages;
    } catch (e) {
      throw new Error(e);
    }
  }
};
export default messages;
