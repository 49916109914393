import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  isSendEmail: StoreRootState['forgotPassword']['isSendEmail'],
  disabledForgotPassword?: StoreRootState['forgotPassword']['disabledForgotPassword'],
  loading: StoreRootState['forgotPassword']['loading'],
  subdomain: StoreRootState['application']['subdomain'],

  send: StoreDispatcher['forgotPassword']['send'],
  returnToSignIn: StoreDispatcher['forgotPassword']['returnToSignIn'],
}

const enhancer = withHooks((outProps: object) => {
  const dispatch = useDispatch<StoreDispatcher>();
  const { forgotPassword, application, authentication } = useSelector((state: StoreRootState) => state);

  const props: Props = {
    isSendEmail: forgotPassword.isSendEmail,
    disabledForgotPassword: forgotPassword.disabledForgotPassword || authentication.realmParams?.disabledForgotPassword,
    loading: forgotPassword.loading,
    subdomain: application.subdomain,

    send: dispatch.forgotPassword.send,
    returnToSignIn: dispatch.forgotPassword.returnToSignIn,
  }

  useEffect(() => {
    dispatch.forgotPassword.setDisableForgotPassword(false)
  }, []);

  return {
    ...outProps,
    ...props,
  };
});

export default enhancer;
