/* eslint-disable */

import Immutable from 'seamless-immutable';
import i18n from '~/common/helpers/i18n';
import map from 'lodash/map';
import find from 'lodash/find';
// Locals
import { runtime } from '~/api';
import normalize from '~/common/helpers/normalize';

const formatError = error => error.replace('GraphQL error:', '');

const omitEmptyValues = object =>
  _.omitBy(object, v =>
    _.isBoolean(v) || _.isFinite(v) ? false : _.isEmpty(v)
  );

const statusDeploy = {
  SERVICE_ACTIVE: {
    text: 'Deployed',
    success: true
  },
  SERVICE_ERROR: {
    text: 'Error',
    danger: true
  },
  DELETING: {
    text: 'Deleting',
    danger: true
  },
  REDEPLOY: {
    text: 'Redeploy',
    success: true
  },
  STARTING: {
    text: 'Starting',
    warning: true
  },
  NEW: {
    text: 'New',
    warning: true
  },
  SERVICE_ACTIVE_BUT_DEGRADED: {
    text: 'Degraded',
    warning: true
  }
};

const initialState = Immutable({
  loading: true,
  loadingSearch: false,
  loadingCreate: false,
  error: null,
  search: '',
  errorFind: null,
  modalCreateDeploy: false,
  modalBetaCreateDeploy: false,
  pipeline: null,
  deploymentDetails: {
    modalVisible: false,
    id: ''
  },
  byId: {},
  allIds: [],
  createDeploy: {
    pipelines: [],
    force: false,
    error: null,
    loading: false
  },
  removeDeploy: {
    error: null,
    success: null
  },
  deployment: null,
  deploysHistoryDistinct: null,
  deployHistoric: null,
  loadingDeployHistoric: true
});

const normalizeStatus = deployments => {
  return map(deployments, deployment => ({
    ...deployment,
    status: statusDeploy[deployment.status]
  }));
};

const runtimeModel = {
  name: 'runtime',
  state: initialState,
  reducers: {
    findSuccess(state, payload) {
      return state.merge({
        error: null,
        ...payload
      });
    },
    findSuccessDeployHistoryDistinct(state, payload) {
      return state.merge({
        error: null,
        ...payload
      });
    },
    findSuccessDeployHistoric(state, payload) {
      return state.merge({
        error: null,
        ...payload
      });
    },
    setSearch: (state, search) => state.merge({ search }),
    setPipeline: (state, pipeline) =>
      state.merge({ pipeline, modalBetaCreateDeploy: true }),
    loading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    loadingDeployHistoric(state, payload) {
      return state.merge({
        loadingDeployHistoric: payload
      });
    },
    loadingSearch(state, payload) {
      return state.merge({
        loadingSearch: payload
      });
    },
    loadingCreate(state, payload) {
      return state.merge({
        loadingCreate: payload
      });
    },
    modalCreateDeploy(state, payload) {
      return state.merge({
        modalCreateDeploy: payload
      });
    },
    modalBetaCreateDeploy(state, payload) {
      return state.merge({
        pipeline: null,
        modalBetaCreateDeploy: payload
      });
    },
    deploy(state, payload) {
      return state.merge({
        ...state.createDeploy,
        force: payload
      });
    },
    setError(state, payload) {
      return state.merge({
        errorFind: payload
      });
    },
    error(state, payload) {
      return state.merge({
        ...state.createDeploy,
        error: payload
      });
    },
    success(state, payload) {
      return state.merge({
        ...state.createDeploy,
        success: payload
      });
    },
    removeDeploy(state, paylaod) {
      return state.merge({
        ...state.removeDeploy,
        success: paylaod
      });
    },
    onShowModalDeployment(state, paylaod) {
      return state.merge({
        deploymentDetails: {
          ...paylaod
        }
      });
    },
    reset() {
      return initialState;
    },
    onShowModalPipelineDeployment(state, paylaod) {
      return state.merge({
        deployment: paylaod
      });
    },
    resetDeployment(state) {
      return state.merge({
        deployment: null
      });
    }
  },
  effects: dispatch => ({
    async find({ realm, environment, search, status }) {
      try {
        const { data } = await runtime.find(
          realm,
          environment,
          omitEmptyValues(search),
          status
        );

        const deploymentsWithNormilizeStatus = normalizeStatus(
          data.deployments
        );
        const deployments = normalize({
          array: deploymentsWithNormilizeStatus
        });
        dispatch.runtime.findSuccess(deployments);
      } catch (e) {
        dispatch.runtime.setError(true);
      }
    },
    async remove({ realm, deployment, environment }) {
      try {
        await runtime.remove(realm, deployment, environment);
        dispatch.runtime.removeDeploy({ realm, deployment, environment });
      } catch (e) {
        dispatch.runtime.error(e.message);
      }
    },
    showCreateDeploy(open) {
      dispatch.runtime.modalCreateDeploy(open);
    },
    showBetaCreateDeploy(open) {
      dispatch.runtime.modalBetaCreateDeploy(open);
    },
    async validationDeploy(params) {
      try {
        const { data } = await runtime.getPipelineInDeploy(params);
        dispatch.runtime.deploy(data.pipelineInDeploy, params);
      } catch (e) {
        dispatch.runtime.error(e.message);
      }
    },
    async create(params) {
      try {
        await runtime.create(params);
        params?.actions?.resetForm?.();
        dispatch.runtime.success(params);
      } catch (e) {
        dispatch.runtime.error(e.message);
      }
    },
    async redeploy(params) {
      try {
        await runtime.redeploy(params);
        params?.actions?.resetForm?.();
        dispatch.runtime.success(params);
      } catch (e) {
        dispatch.runtime.error(e.message);
      }
    },
    async findByDeployHistoryDistinct(params) {
      try {
        const { data } = await runtime.findByDeployHistoryDistinct(params);
        dispatch.runtime.findSuccessDeployHistoryDistinct({
          deploysHistoryDistinct: data?.deployHistoryDistinct
        });
      } catch (e) {
        dispatch.runtime.setError(true);
      }
    },
    async findByDeployHistoricDetail({ realm, deploymentSelect, page }) {
      try {
        const { data } = await runtime.findByDeployHistory({
          realm,
          pipelineName: deploymentSelect.pipelineName,
          pipelineMajorVersion: deploymentSelect.pipelineVersionMajor,
          page
        });
        dispatch.runtime.findSuccessDeployHistoric({
          deployHistoric: {
            deploy: deploymentSelect,
            ...data?.deployHistory
          }
        });
      } catch (e) {
        dispatch.runtime.setError(true);
      }
    }
  }),
  logics: [
    {
      type: 'runtime/find',
      latest: true,
      process({ action }, dispatch, done) {
        if (action?.payload?.search?.projectId) {
          dispatch.project.setCurrent(action?.payload?.search?.projectId);
        }
        if (action?.payload?.search) {
          dispatch.runtime.setSearch(omitEmptyValues(action?.payload?.search));
        }

        if (!action?.meta?.type) {
          dispatch.runtime.loading(true);
        } else {
          dispatch.runtime.loadingSearch(true);
        }

        done();
      }
    },
    {
      type: 'runtime/findByDeployHistoricDetail',
      latest: true,
      process(context, dispatch, done) {
        dispatch.runtime.loadingDeployHistoric(true);
        done();
      }
    },
    {
      type: ['runtime/findSuccessDeployHistoric'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.runtime.loadingDeployHistoric(false);
        done();
      }
    },
    {
      type: 'runtime/findByDeployHistoryDistinct',
      latest: true,
      process(context, dispatch, done) {
        dispatch.runtime.loading(true);
        done();
      }
    },
    {
      type: [
        'runtime/findSuccess',
        'runtime/setError',
        'runtime/findSuccessDeployHistoryDistinct'
      ],
      latest: true,
      process(context, dispatch, done) {
        dispatch.runtime.loading(false);
        dispatch.runtime.loadingSearch(false);
        done();
      }
    },
    {
      type: 'runtime/create',
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.runtime.loadingCreate(true);

        done();
      }
    },
    {
      type: 'runtime/redeploy',
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.runtime.loadingCreate(true);

        done();
      }
    },
    {
      type: 'runtime/deploy',
      latest: true,
      async process({ action }, dispatch, done) {
        const { payload, meta } = action;
        dispatch.runtime.modalCreateDeploy(!payload);
        const confirmConfiguration = payload
          ? {
              confirmDialog: {
                title: i18n.t(
                  'scenes.runtime.labels.upper_and_capitalize.uppercase',
                  {
                    item: i18n.t('scenes.runtime.actions.confirm')
                  }
                ),
                cancelText: i18n.t(
                  'scenes.runtime.labels.upper_and_capitalize.uppercase',
                  {
                    item: i18n.t('scenes.runtime.actions.cancel')
                  }
                ),
                confirmText: i18n.t(
                  'scenes.runtime.labels.upper_and_capitalize.uppercase',
                  {
                    item: i18n.t('scenes.runtime.actions.confirm')
                  }
                ),
                text: i18n.t(
                  'scenes.runtime.labels.upper_and_capitalize.capitalized',
                  {
                    item: i18n.t(
                      'scenes.runtime.messages.prompt.replace_deployment'
                    )
                  }
                ),
                open: true
              }
            }
          : null;
        dispatch.runtime.create(
          Object.assign(meta, {
            force: payload || false
          }),
          confirmConfiguration
        );
      }
    },
    {
      type: 'runtime/error',
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.runtime.loadingCreate(false);
        dispatch.snackbar.create({
          text: formatError(payload)
        });
        done();
      }
    },
    {
      type: 'runtime/success',
      latest: true,
      process(prop, dispatch, done) {
        dispatch.runtime.modalCreateDeploy(false);
        dispatch.runtime.modalBetaCreateDeploy(false);
        dispatch.runtime.loadingCreate(false);
        dispatch.configuration.successItems([]);
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.runtime.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t('scenes.runtime.messages.success.item_deployed')}`
            }
          ),
          success: true,
          open: true
        });
        done();
      }
    },
    {
      type: 'runtime/modalCreateDeploy',
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        if (!payload) {
          dispatch.configuration.successItems([]);
          dispatch.pipeline.multiInstance([]);
        }
        done();
      }
    },
    {
      type: 'runtime/onShowModalPipelineDeployment',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.runtime.modalBetaCreateDeploy(true);
        dispatch.runtime.onShowModalDeployment({
          id: '',
          visible: false
        });
        done();
      }
    },

    {
      type: 'runtime/removeDeploy',
      latest: true,
      process({ action, getState }, dispatch, done) {
        const { payload } = action;
        const { runtime } = getState();
        dispatch.runtime.find({
          ...payload,
          search: {
            ...runtime.search
          }
        });
        done();
      }
    }
  ]
};

export default runtimeModel;
