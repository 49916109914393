import { Box, Alert as DSAlert, Text as DSText } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { faBox, faLayerGroup, faUser } from '@fortawesome/pro-light-svg-icons';
import { faGears } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import * as Elements from './OldStatusBody.elements';
import TextWithIcon from '../TextWithIcon';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Alert from '~/components/Alert';
import { checkErrorDeployment } from '~/scenes/Runtime/utils/validErrorsDeployment';

const OldStatusBody = ({ deployment, disabled, isAlert, isDeleting }) => {
  const { application } = useSelector(state => state);
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM], {
    realm: application?.activeRealm
  });

  const quantityDays = 7;

  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  if (showDesignSystemComponents) {
    const errors = checkErrorDeployment(
      isDeleting,
      isAlert,
      disabled,
      deployment?.status?.text,
      deployment,
      quantityDays
    );
    return (
      <>
        {!disabled && (
          <Box
            css={{
              marginTop: '$3',
              display: 'flex',
              marginLeft: '$3',
              marginRight: '$2',
              alignSelf: 'flex-start'
            }}
          >
            <FontAwesomeIcon
              icon={faGears}
              disable={disabled}
              style={{ color: '#939899', marginRight: '8px' }}
              isEllipse
            />
            <DSText css={{ fontSize: '$1' }} isEllipse>
              {`${i18n.t(
                'label.engine_version'
              )} ${deployment?.deploymentStatus?.pipelineEngineVersion?.replace(
                /^([^.]*\.[^.]*\.[^.]*).*/,
                '$1'
              )}`}
            </DSText>
          </Box>
        )}

        <Elements.ContainerBodyV2>
          {iff(deployment?.metadata?.userName, () => (
            <Box
              css={{
                marginTop: '$3',
                marginBottom: '$3',
                display: 'flex',
                marginLeft: '$3',
                marginRight: '$2'
              }}
            >
              <FontAwesomeIcon
                icon={faUser}
                disable={disabled}
                style={{ color: '#939899', marginRight: '8px' }}
                isEllipse
              />
              <DSText css={{ fontSize: '$1' }} isEllipse>
                {deployment?.metadata?.userName}
              </DSText>
            </Box>
          ))}
          <Box
            css={{
              marginTop: '$3',
              marginBottom: '$3',
              display: 'flex',
              marginLeft: '$3',
              marginRight: '$2'
            }}
          >
            <FontAwesomeIcon
              icon={faBox}
              disable={disabled}
              style={{ color: '#939899', marginRight: '8px' }}
              isEllipse
            />
            <DSText css={{ fontSize: '$1' }}>
              {moment(deployment?.startTime, 'x').format('DD/MM/YYYY HH:mm:ss')}
            </DSText>
          </Box>
          {deployment?.pipeline?.parameterizedReplica && (
            <Box
              css={{
                marginTop: '$3',
                marginBottom: '$2',
                display: 'flex',
                marginLeft: '$3',
                marginRight: '$2'
              }}
            >
              <FontAwesomeIcon
                icon={faLayerGroup}
                disable={disabled}
                style={{ color: '#939899', marginRight: '8px' }}
                isEllipse
              />
              <DSText css={{ fontSize: '$1' }}>Multi-instance</DSText>
            </Box>
          )}
        </Elements.ContainerBodyV2>
        <Box
          css={{
            width: '96%',
            marginBottom: '$3'
          }}
        >
          <DSAlert
            title={errors.text}
            variant={errors.type === 'infoWarn' ? 'info' : errors.type}
          />
        </Box>
      </>
    );
  }
  return (
    <>
      <Elements.ContainerBody>
        {iff(deployment?.metadata?.userName, () => (
          <TextWithIcon
            icon={faUser}
            disable={disabled}
            text={deployment?.metadata?.userName}
            isEllipse
          />
        ))}
        <TextWithIcon
          icon={faBox}
          disable={disabled}
          isEllipse
          text={moment(deployment?.startTime, 'x').format(
            'DD/MM/YYYY HH:mm:ss'
          )}
        />

        {deployment?.pipeline?.parameterizedReplica && (
          <TextWithIcon
            icon={faLayerGroup}
            text='Multi-instance'
            disable={disabled}
          />
        )}
      </Elements.ContainerBody>
      <Alert
        width='100%'
        size='small'
        {...checkErrorDeployment(
          isDeleting,
          isAlert,
          disabled,
          deployment?.status?.text,
          deployment,
          quantityDays
        )}
      />
    </>
  );
};

OldStatusBody.propTypes = {
  deployment: PropTypes.objectOf({
    pipeline: PropTypes.objectOf({
      parameterizedReplica: PropTypes.string
    }),
    startTime: PropTypes.string,
    errorCount: PropTypes.number,
    oomCount: PropTypes.string,
    pipelineUpdateProject: PropTypes.string,
    status: PropTypes.objectOf({})
  }).isRequired,
  isAlert: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default OldStatusBody;
