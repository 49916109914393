import { Buffer } from 'buffer';

import axios from 'axios';

import { API_KEY } from '~/common/helpers/environment';

const getBase64Url = async (url, token) => {
  const header = token
    ? {
        headers: {
          apikey: API_KEY,
          authorization: token
        }
      }
    : null;
  const options = {
    ...header,
    responseType: 'arraybuffer'
  };

  const result = await axios
    .get(url, options)
    .then(response => {
      const image = Buffer.from(response.data, 'binary').toString('base64');
      return `data:${response.headers[
        'content-type'
      ].toLowerCase()};base64,${image}`;
    })
    .catch(() => null);

  return result;
};

export default getBase64Url;
