import { theme, Text } from '@digibee/beehive-ui';

export const Container = theme.styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  gap: '16px',
});

export const Title = theme.styled(Text, {
  fontSize: '$3',
  fontWeight: '$medium',
  color: '$pri500',
});

export const Information = theme.styled(Text, {
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
});

export const StyledLink = theme.styled('a', {
  color: '$blu500',
  fontSize: '$2',
  fontFamily: '$display',
  textDecoration: 'none'
});
