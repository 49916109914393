import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const consumers = {
  find: ({ realm, search, environment }) =>
    clientApollo.query({
      query: gql`
        query consumers(
          $realm: String!
          $search: String
          $environment: String!
        ) {
          consumers(realm: $realm, search: $search, environment: $environment) {
            id
            acls
            customId
            apiKeys {
              apiKey
              idKeyAuth
              createdAt
            }
            description
          }
        }
      `,
      variables: {
        realm,
        search,
        environment
      }
    }),
  findAcls: ({ realm }) =>
    clientApollo.query({
      query: gql`
        query pipelines($realm: String!) {
          pipelines(realm: $realm) {
            name
            description
          }
        }
      `,
      variables: {
        realm
      }
    }),
  create({ realm, consumer, environment, acls, description }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation saveConsumer(
          $realm: String!
          $consumer: String!
          $environment: String!
          $acls: [String]
          $description: String
        ) {
          saveConsumer(
            realm: $realm
            consumer: $consumer
            environment: $environment
            acls: $acls
            description: $description
          )
        }
      `,
      variables: {
        realm,
        consumer,
        environment,
        acls,
        description
      }
    });
  },
  createApiKey({ realm, consumer, environment }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createApiKey(
          $realm: String!
          $consumer: String!
          $environment: String!
        ) {
          createApiKey(
            realm: $realm
            consumer: $consumer
            environment: $environment
          ) {
            apiKey
            idKeyAuth
          }
        }
      `,
      variables: {
        realm,
        consumer,
        environment
      }
    });
  },
  edit({ realm, consumer, environment, acls, description, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editConsumer(
          $realm: String!
          $consumer: String!
          $environment: String!
          $acls: [String]
          $description: String
          $id: String!
        ) {
          editConsumer(
            realm: $realm
            consumer: $consumer
            environment: $environment
            acls: $acls
            description: $description
            id: $id
          )
        }
      `,
      variables: {
        realm,
        consumer,
        environment,
        acls,
        description,
        id
      }
    });
  },
  async remove({ realm, consumerId, environment }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteConsumers(
          $realm: String!
          $consumerId: String!
          $environment: String!
        ) {
          deleteConsumers(
            realm: $realm
            consumerId: $consumerId
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        consumerId,
        environment
      }
    });
  },
  async removeApiKey({ realm, consumer, environment, apikeyId }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteApiKey(
          $realm: String!
          $consumer: String!
          $apikeyId: String!
          $environment: String!
        ) {
          deleteApiKey(
            realm: $realm
            consumer: $consumer
            apikeyId: $apikeyId
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        consumer,
        apikeyId,
        environment
      }
    });
  }
};

export default consumers;
