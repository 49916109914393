import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import { oauthProviders } from '~/api';
import i18n from '~/common/helpers/i18n';

const normalizeData = ({ entity, data, id = 'id' }) => {
  const Entity = new schema.Entity(entity, {}, { idAttribute: id });
  const Schema = { [entity]: [Entity] };

  return normalize(data, Schema);
};

const initialState = Immutable({
  data: [],
  providersLoading: false,
  providersError: undefined,
  providersIds: [],
  providersById: {},
  baseProvidersLoading: false,
  baseProviders: [],
  providerLoading: false,
  provider: {},
  createProviderLoading: false,
  updateProviderLoading: false,
  archiveProviderLoading: false
});

const oauthProvidersModel = {
  name: 'oauthProviders',
  state: initialState,
  reducers: {
    setProvidersLoading: (state, loading) =>
      state.merge({ providersLoading: loading }),
    setProvidersError: (state, error) => state.merge({ providersError: error }),
    setProviders: (state, { providersIds, providersById }) =>
      state.merge({ providersIds, providersById }),
    setBaseProvidersLoading: (state, loading) =>
      state.merge({ baseProvidersLoading: loading }),
    setBaseProviders: (state, baseProviders) => state.merge({ baseProviders }),
    setProviderLoading: (state, loading) =>
      state.merge({ providerLoading: loading }),
    setProvider: (state, provider) => state.merge({ provider }),
    setData: (state, data) => state.merge({ data }),
    setCreateProviderLoading: (state, loading) =>
      state.merge({ createProviderLoading: loading }),
    setUpdateProviderLoading: (state, loading) =>
      state.merge({ updateProviderLoading: loading })
  },
  effects: dispatch => ({
    find: async (_, { application }) => {
      try {
        dispatch.oauthProviders.setProvidersLoading(true);

        const { data } = await oauthProviders.find({
          realm: application.realm.realm
        });
        const { result, entities } = normalizeData({
          entity: 'oauthProviders',
          data
        });

        dispatch.oauthProviders.setData(data?.oauthProviders);
        return dispatch.oauthProviders.setProviders({
          providersIds: result.oauthProviders,
          providersById: entities.oauthProviders
        });
      } catch (error) {
        return dispatch.oauthProviders.setProvidersError(error.message);
      } finally {
        dispatch.oauthProviders.setProvidersLoading(false);
      }
    },
    findBaseProviders: async (_, { application }) => {
      try {
        dispatch.oauthProviders.setBaseProvidersLoading(true);

        const { data } = await oauthProviders.find({
          realm: application.realm.realm
        });
        const baseProviders = data.oauthProviders.map(
          ({ id, title, authURL, realm }) => ({
            value: id,
            label: title,
            authURL,
            realm
          })
        );

        return dispatch.oauthProviders.setBaseProviders(baseProviders);
      } catch (error) {
        return dispatch.snackbar.create({
          text: error.message,
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } finally {
        dispatch.oauthProviders.setBaseProvidersLoading(false);
      }
    },
    get: async (id, { application }) => {
      try {
        dispatch.oauthProviders.setProviderLoading(true);

        const { data } = await oauthProviders.get({
          realm: application.realm.realm,
          id
        });

        return dispatch.oauthProviders.setProvider(data.oauthProvider);
      } catch (error) {
        return dispatch.snackbar.create({
          text: error.message,
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } finally {
        dispatch.oauthProviders.setProviderLoading(false);
      }
    },
    create: async (data, { application }) => {
      try {
        dispatch.oauthProviders.setCreateProviderLoading(true);

        await oauthProviders.create({
          data,
          realm: application.realm.realm
        });

        dispatch.oauthProviders.find();

        return dispatch.snackbar.create({
          text: i18n.t('label.provider_successfully_create_msg', {
            provider_name: data.title
          }),
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } catch (error) {
        return dispatch.snackbar.create({
          text: error.message,
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } finally {
        dispatch.oauthProviders.setCreateProviderLoading(false);
      }
    },
    update: async ({ id, ...data }, { application }) => {
      try {
        dispatch.oauthProviders.setUpdateProviderLoading(true);

        await oauthProviders.update({
          id,
          data,
          realm: application.realm.realm
        });

        dispatch.oauthProviders.find();

        return dispatch.snackbar.create({
          text: i18n.t('label.successfully_modified_provider', {
            provider_name: data.title
          }),
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } catch (error) {
        return dispatch.snackbar.create({
          text: error.message,
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } finally {
        dispatch.oauthProviders.setUpdateProviderLoading(false);
      }
    },
    archive: async ({ id, ...data }, { application }) => {
      try {
        await oauthProviders.archive({
          id,
          realm: application.realm.realm
        });

        dispatch.oauthProviders.find();

        return dispatch.snackbar.create({
          text: i18n.t('label.provider_successfully_archived_msg', {
            provider_name: data.title
          }),
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      } catch (error) {
        return dispatch.snackbar.create({
          text: error.message,
          action: {
            label: i18n.t('action.ok'),
            callback: dispatch.snackbar.clearCurrent
          }
        });
      }
    }
  }),
  logics: []
};

export default oauthProvidersModel;
