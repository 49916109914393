import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import FormFirstAccess from './FormFirstAccess';
import FormForgotPassword from './FormForgotPassword';
import FormIdentityProvider from './FormIdentityProvider';
import FormLogin from './FormLogin';
import FormLoginV4 from './FormLoginV4';
import FormRealm from './FormRealm';
import FormResetPassword from './FormResetPassword';
import FormUserBlocked from './FormUserBlocked';
import FormUserTemporaryLocked from './FormUserTemporaryLocked';
import FormUserUnblocked from './FormUserUnblocked';
import TwoFactorAlert from './TwoFactorAlert';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

const RouteComponent = () => {
  const { GOVERNANCE_NEW_LOGIN_PAGE } = featureFlagConstants;
  const { treatments } = useFeatureFlag([GOVERNANCE_NEW_LOGIN_PAGE]);
  const showNewLoginPage = treatments[GOVERNANCE_NEW_LOGIN_PAGE].treatment === 'on';

  const { authentication: { firstaccess } } = useSelector((state: StoreRootState) => state);
  const params = useParams();

  switch (params.actionId) {
    case 'forgotpassword':
      return <FormForgotPassword />;
    case 'changepassword':
      return firstaccess ? <FormFirstAccess /> : <Navigate to='/login' />;
    case 'resetpassword':
      return <FormResetPassword />;
    case 'realm':
      return <FormRealm />;
    case 'error':
      return <FormUserBlocked />;
    case 'success':
      return <FormUserUnblocked />;
    case 'temporary-locked':
      return <FormUserTemporaryLocked />;
    case 'identity-provider':
      return <FormIdentityProvider />;
    case 'two-factor':
      return <TwoFactorAlert />;
    default:
      return showNewLoginPage ? <FormLoginV4 /> : <FormLogin />;
  }
};

export default RouteComponent;
