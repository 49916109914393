import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

// Locals
import Text from '~/components/Text';

const BORDER_RADIUS = 1;

const Container = styled.span`
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: ${BORDER_RADIUS}px;
  background-color: currentColor;
  opacity: 0.4;
  ${props =>
    props.size === 'md' &&
    css`
      width: 3px;
      height: 3px;
      border-radius: 3px;
    `};
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      top: 50%;
      transform: translate(-1px, -50%);
    `};

  ${props =>
    props.orientation === 'vertical' &&
    css`
      left: 50%;
      transform: translate(-50%, 1px);
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;
const Label = styled(Text)`
  white-space: nowrap;
  position: absolute;
  padding-left: 5px;
  color: ${props => props.theme.colors.gra600};
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      top: 40px;
      transform: translateX(-50%);
      @media (pointer: coarse) {
        top: 40px;
      }
    `};

  ${props =>
    props.orientation === 'vertical' &&
    css`
      left: 25px;
      transform: translateY(50%);
      @media (pointer: coarse) {
        left: 44px;
      }
    `};
`;

const Mark = ({ index, value, label, ...restProps }) => (
  <>
    <Container
      data-index={index}
      /* eslint-disable react/jsx-props-no-spreading */
      {...restProps}
    />
    {label && (
      <Label
        type='secondary'
        data-index={index}
        /* eslint-disable react/jsx-props-no-spreading */
        {...restProps}
        as='span'
        size='md'
      >
        {label}
      </Label>
    )}
  </>
);

Mark.defaultProps = {
  index: 0,
  value: 0,
  label: '',
  markRail: false
};

Mark.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  markRail: PropTypes.bool
};

export default Mark;
