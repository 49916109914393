import { map } from 'lodash';
import Immutable from 'seamless-immutable';

import { pipeline } from '~/api';

const initialState = Immutable({
  multiInstance: {
    items: []
  },
  pipelines: [],
  loading: false,
  validate: null,
  loadingValidate: false
});

const pipelineModel = {
  name: 'pipeline',
  state: initialState,
  reducers: {
    multiInstance(state, payload) {
      return state.merge({
        multiInstance: {
          items: payload
        }
      });
    },
    deployPipelines(state, payload) {
      return state.merge({
        pipelines: payload
      });
    },
    loading(state, loading) {
      return state.merge({
        loading
      });
    },
    setLoadingValidate(state, loadingValidate) {
      return state.merge({
        loadingValidate
      });
    },
    setValidate(state, validate) {
      return state.merge({
        validate
      });
    }
  },
  effects: dispatch => ({
    async findMultiInstance(params, { application }) {
      try {
        const { realm } = application.realm;
        const { data } = await pipeline.multiInstance({ realm, ...params });
        performance.mark('pipeline-fetch-finish');
        dispatch.pipeline.multiInstance(
          map(data.replicaInstance, ({ name }) => ({
            value: name,
            label: name
          }))
        );
      } catch (e) {
        dispatch.pipeline.multiInstance([]);
      }
    },
    async findPipelines({ realm }) {
      try {
        const { data } = await pipeline.find(realm);
        dispatch.pipeline.deployPipelines(
          map(data.pipelines, item => ({
            label: `${item.name} - V${item.versionMajor}`,
            value: JSON.stringify(item),
            versionMajor: item.versionMajor,
            deployment: item.deployments,
            parameterizedReplica: item.parameterizedReplica
          }))
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.log(e);
      }
    },
    async validatePipeline({ pipelineId, environment }, { application }) {
      try {
        const { realm } = application.realm;
        const validate = await pipeline.validatePipeline({
          realm,
          pipelineId,
          environment
        });
        dispatch.pipeline.setValidate(validate);
      } catch (e) {
        dispatch.pipeline.setValidate(null);
      }
    }
  }),
  logics: [
    {
      type: 'pipeline/findPipelines',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipeline.loading(true);
        done();
      }
    },
    {
      type: 'pipeline/validatePipeline',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipeline.setLoadingValidate(true);
        done();
      }
    },
    {
      type: 'pipeline/setValidate',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipeline.setLoadingValidate(false);
        done();
      }
    },
    {
      type: 'pipeline/deployPipelines',
      latest: true,
      process(context, dispacth, done) {
        dispacth.pipeline.loading(false);
        done();
      }
    }
  ]
};

export default pipelineModel;
