// @ts-nocheck
import {
  Avatar,
  Button,
  Skeleton,
  Pagination,
  SideSheet,
  Text,
  Tooltip,
  Box,
  Menu,
  Icon,
  IconButton,
  Flex
} from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import { faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, head } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import ShowDialogLicenseRevert from './components/ShowDialogLicenseRevert';
import PipelineDeployWithServerless from '../../Run/components/PipelineDeploy/PipelineDeployWithServerless';
import TriggerDetails from '../../Run/components/TriggerDetails';
import { useIsAutoscaling } from '../../Run/hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../Run/utils/isAutoScalingDeploy';
import { HistoryDeploymentContext } from '../context/HistoryDeploymentContext';

import apiLicenseStatus from '~/api/licenseStatus';
import apiPipeline from '~/api/pipeline';
import api from '~/api/runtime';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import useSnackbar from '~/common/hooks/useSnackbar';
import { Store } from '~/common/types/Store';
import { HistoricType } from '~/entities/HistoryDeployment';
import { LicenseStatusType } from '~/scenes/Runtime/components/CardLicense/CardLicense';
import { getMessageStatus } from '~/scenes/Runtime/components/StatusDeploy';

export type ValidatePipelineData = {
  realm: string;
  pipelineId: string;
  environment: string;
};

type ValidationPropTypes = {
  code: string;
  message: string;
  details: [
    {
      level: string;
      details: string[];
      code: string;
      message: string;
      objects: string[];
    }
  ];
};

type ValidationLicenseTypes = {
  environment: string;
  pipelineId: string;
  size: string;
  replicas: number;
  replicaInstanceName: string;
  realm: string;
};

const mapValueSchema = (values = {}, schema = []) => {
  const keys = Object.keys(values);
  return keys.reduce((acc, current) => {
    const prop = schema.find(x => x.property === current);
    if (prop) {
      acc.push({
        label: prop.label,
        value: JSON.stringify(values[current])
      });
      return acc;
    }
    return acc;
  }, []);
};

const Detail: React.FC = () => {
  const { realm } = useSelector(({ application }: Store) => application.realm);

  const {
    deploymentSelected,
    lastDeploy,
    loading,
    historic: historics,
    getData
  } = useContext(HistoryDeploymentContext);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [showDialog, setShowDialog] = useState(false);
  const [licenseStatus, setLicenseStatus] = useState<LicenseStatusType | null>(
    null
  );
  const [history, setHistory] = useState<HistoricType | null>(null);
  const [environment, setEnvironment] = useState('');
  const [triggers, setTriggers] = useState<any>([]);
  const application = useSelector(state => state.application);
  const { RUN_ENABLE_3DOTS_HISTORY } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_ENABLE_3DOTS_HISTORY]);
  const isEnable3DotsHistory =
    treatments[RUN_ENABLE_3DOTS_HISTORY].treatment === 'on';
  const showNewAutoScalingExperience = useIsAutoscaling();

  async function getTriggers() {
    const { data } = await api.getTriggers({
      realm: application.activeRealm
    });
    setTriggers(data.triggers);
  }

  useEffect(() => {
    getTriggers();
  }, []);

  const trigger = find(
    triggers,
    t =>
      t.jsonExample.name === lastDeploy?.pipeline?.triggerSpec?.name &&
      t.jsonExample.type === lastDeploy?.pipeline?.triggerSpec?.type
  );

  const triggerSource = mapValueSchema(
    lastDeploy?.pipeline?.triggerSpec,
    head(trigger?.jsonSchema)?.schema
  );

  if (!deploymentSelected) {
    return (
      <Navigate to={`/${realm}/operation/run/deployment/history`} replace />
    );
  }

  const normalizeSize = (name: string) => {
    if (!name) return '';
    return name?.split(' ')[0];
  };

  async function validatePipeline(variant: ValidatePipelineData) {
    const data: ReturnType<typeof apiPipeline.validatePipeline> =
      apiPipeline.validatePipeline({
        ...variant,
        realm
      });

    const analysisData = await data;
    return Promise.resolve(Array.isArray(analysisData) ? analysisData : []);
  }

  async function checkLicense(variant: ValidationLicenseTypes) {
    const { data } = await api.checkLicense({
      ...variant
    });
    return data?.checkLicense?.analysis;
  }

  async function openModal(historic: HistoricType) {
    setHistory(historic);
    const pipelineValidation = await validatePipeline({
      pipelineId: historic.pipeline?.id,
      environment: historic.environment.name.toString(),
      realm
    });

    const isError = pipelineValidation?.filter?.(
      (item: ValidationPropTypes) => {
        const errors = item?.details.filter(
          detail => detail?.level === 'ERROR'
        );
        return errors.length;
      }
    );

    if (isError.length > 0) {
      snackbar.createSnack(
        i18n.t('label.couldnt_restore_minor_version_pipe_deploy_msg_alert')
      );
      return;
    }

    const sizeValue = normalizeSize(
      historic.runtimeConfiguration.description.toString()
    );
    const licenseValid = await checkLicense({
      environment: historic?.environment?.name.toString(),
      pipelineId: historic?.pipeline?.id,
      size: sizeValue,
      replicas: parseInt(
        historic.runtimeConfiguration.replicaCount.toString(),
        10
      ),
      replicaInstanceName: '',
      realm
    });

    if (licenseValid.length > 0) {
      snackbar.createSnack(
        i18n.t('label.couldnt_restore_minor_version_pipe_deploy_msg_alert')
      );
      return;
    }

    const data = await apiLicenseStatus.fetch({
      environment: historic.environment.name,
      realm,
      replicas: historic.runtimeConfiguration.replicaCount,
      pipelineId: historic.pipeline.id,
      size: sizeValue
    });

    setEnvironment(historic?.environment?.name.toString());
    setLicenseStatus(data);
    setShowDialog(true);
  }

  function goToPipeline(id: string) {
    return window.open(`/${realm}/design/v2/pipelines/${id}`, '_blank');
  }

  function convertData(time: string) {
    return moment(new Date(parseInt(time, 10))).format('DD/MM/YYYY HH:mm');
  }

  const styleBoldText = {
    paddingLeft: '$1',
    paddingRight: '$1',
    lineHeight: '$2',
    borderRight: '1px solid $neu600',
    color: '$black'
  };

  return (
    <SideSheet
      open
      onClose={() => {
        navigate(-1);
      }}
      header={i18n.t('label.pipeline_deployment_info')}
      data-testid='run-history-pipeline-information'
      size='small'
    >
      {choose(
        when(loading || !lastDeploy, () => (
          <Skeleton animation='pulse' sizes='small' variant='text' />
        )),
        otherwise(() => (
          <>
            <Text
              as='h4'
              css={{
                fontSize: '22px',
                lineHeight: '1.6',
                marginBottom: '-$3',
                marginTop: '$5',
                color: '$black'
              }}
            >
              {i18n.t('label.last_deployment_details')}
            </Text>
            <PipelineDeployWithServerless
              deployment={
                {
                  pipeline: lastDeploy!.pipeline,
                  activeConfiguration: {
                    environment: lastDeploy!.environment,
                    name: lastDeploy!.runtimeConfiguration.name,
                    actualConsumers:
                      lastDeploy!.runtimeConfiguration.actualConsumers,
                    replicaCount: lastDeploy!.runtimeConfiguration.replicaCount,
                    minReplicaCount:
                      lastDeploy!.runtimeConfiguration.minReplicaCount
                  },
                  project: lastDeploy!.project.name,
                  startTime: lastDeploy!.deployedAt,
                  metadata: { userName: lastDeploy!.deployedBy.name },
                  isHistory: true
                } as any
              }
            />
            <SideSheet.Section>
              {!trigger ? (
                <Skeleton
                  variant='rectangular'
                  css={{
                    width: '100%',
                    height: '113px'
                  }}
                />
              ) : (
                <Box>
                  <Box
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '12px'
                    }}
                  >
                    <Box
                      css={{
                        display: 'flex',
                        width: '45px',
                        height: '45px'
                      }}
                    >
                      <Box
                        as='img'
                        css={{
                          userDrag: 'none',
                          userSelect: 'none',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          border: '1px solid $gra25'
                        }}
                        title={i18n.t(
                          'scenes.beta_pipelines.labels.trigger_icon'
                        )}
                        src={`${trigger.iconURL}`}
                      />
                    </Box>
                    <Text css={{ paddingLeft: '$2' }} variant='b3'>
                      {lastDeploy?.pipeline?.description}
                    </Text>
                  </Box>
                  <Box css={{ marginTop: '$1' }}>
                    <TriggerDetails
                      source={triggerSource}
                      title={head(trigger?.jsonSchema)?.label}
                      triggers={lastDeploy?.triggerSpec}
                    />
                  </Box>
                </Box>
              )}
            </SideSheet.Section>
            <SideSheet.Section
              title={i18n.t('label.implementation_history')}
              data-testid='run-history-pipeline-deployments'
            >
              <Box
                css={{
                  paddingTop: '8px'
                }}
              >
                {historics?.content.map(historic => (
                  <Flex
                    css={{
                      justifyContent: 'space-between'
                    }}
                    key={historic.id}
                  >
                    <Flex>
                      <Box
                        css={{
                          paddingRight: '16px',
                          borderRight: '2px solid #939899',
                          width: '135px',
                          marginRight: '40px'
                        }}
                      >
                        <Text
                          css={{
                            color: '#667085',
                            width: '98px',
                            fontSize: '$1',
                            lineHeight: '$2'
                          }}
                        >
                          {convertData(historic.deployedAt)}
                        </Text>
                      </Box>
                      <Flex
                        css={{
                          flexDirection: 'column',
                          gap: '8px',
                          paddingBottom: '22px'
                        }}
                      >
                        <Text
                          css={{
                            fontWeight: '$medium',
                            color: '$black',
                            lineHeight: '$2'
                          }}
                        >
                          {historic.pipeline.name}
                        </Text>

                        <Flex
                          css={{
                            flexDirection: 'column'
                          }}
                        >
                          <Flex>
                            <Text css={{ lineHeight: '$2', color: '$black' }}>
                              {i18n.t('label.size_colon')}
                            </Text>
                            <Text css={styleBoldText}>
                              {historic.runtimeConfiguration.description.charAt(
                                0
                              )}
                            </Text>
                            <Text
                              css={{
                                paddingLeft: '$1',
                                lineHeight: '$2',
                                color: '$black'
                              }}
                            >
                              {i18n.t('label.conc_exec_colon')}
                            </Text>
                            <Text css={styleBoldText}>
                              {historic.runtimeConfiguration.actualConsumers}
                            </Text>
                            {(() => {
                              const showAutoScalingValues =
                                isAutoScalingDeploy({
                                  minReplicaCount:
                                    historic.runtimeConfiguration!
                                      .minReplicaCount,
                                  replicaCount:
                                    historic.runtimeConfiguration!.replicaCount
                                }) && showNewAutoScalingExperience;
                              return showAutoScalingValues ? (
                                <>
                                  <Text
                                    css={{
                                      paddingLeft: '$1',
                                      lineHeight: '$2',
                                      color: '$black'
                                    }}
                                  >
                                    {i18n.t(
                                      'scenes.runtime.labels.minimum_replicas'
                                    )}
                                    :
                                  </Text>
                                  <Text css={styleBoldText}>
                                    {
                                      historic.runtimeConfiguration
                                        .minReplicaCount
                                    }
                                  </Text>
                                  <Text
                                    css={{
                                      paddingLeft: '$1',
                                      lineHeight: '$2',
                                      color: '$black'
                                    }}
                                  >
                                    {i18n.t(
                                      'scenes.runtime.labels.maximum_replicas'
                                    )}
                                    :
                                  </Text>
                                  <Text css={styleBoldText}>
                                    {historic.runtimeConfiguration.replicaCount}
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <Text
                                    css={{
                                      paddingLeft: '$1',
                                      lineHeight: '$2',
                                      color: '$black'
                                    }}
                                  >
                                    {i18n.t(
                                      'scenes.runtime.labels.target_of_replicas'
                                    )}
                                    :
                                  </Text>
                                  <Text css={styleBoldText}>
                                    {historic.runtimeConfiguration.replicaCount}
                                  </Text>
                                </>
                              );
                            })()}
                          </Flex>
                          <Text
                            css={{
                              lineHeight: '$2',
                              color: '$black'
                            }}
                          >
                            {i18n.t('label.minor_version_colon')}{' '}
                            {historic.pipeline.versionMajor}.
                            {historic.pipeline.versionMinor}
                          </Text>
                          <Text
                            css={{
                              lineHeight: '$2',
                              color: '$black'
                            }}
                          >
                            {i18n.t('label.project_colon')}{' '}
                            {historic.project.name}
                          </Text>
                          <Text
                            css={{
                              lineHeight: '$2',
                              color: '$black'
                            }}
                          >
                            {i18n.t('label.environment_colon')}{' '}
                            {historic.environment.name}
                          </Text>

                          <Flex
                            css={{
                              alignItems: 'center'
                            }}
                          >
                            <Text
                              css={{
                                lineHeight: '$2',
                                color: '$black'
                              }}
                            >
                              {i18n.t('label.last_action_by_colon')}
                            </Text>
                            <Tooltip
                              align='center'
                              content={historic.deployedBy.name}
                              side='top'
                            >
                              <Flex css={{ marginLeft: '8px' }}>
                                <Avatar
                                  size='xsmall'
                                  text={historic.deployedBy.name.toString()}
                                  type='char'
                                />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <Flex
                            css={{
                              flexDirection: 'column'
                            }}
                          >
                            <Text
                              css={{
                                lineHeight: '$2',
                                color: '$black'
                              }}
                            >
                              {i18n.t('label.status_colon')}
                            </Text>

                            {historic.status.map(dataStatus => (
                              <Flex
                                css={{
                                  alignItems: 'center',
                                  marginLeft: '8px'
                                }}
                                key={dataStatus.date}
                              >
                                &bull;
                                <Text
                                  css={{
                                    lineHeight: '$2',
                                    color: '$black',
                                    marginLeft: '8px'
                                  }}
                                >
                                  {
                                    getMessageStatus({
                                      status: {
                                        text: dataStatus.label
                                      }
                                    } as any).text
                                  }
                                  : {convertData(dataStatus.date)}
                                </Text>
                              </Flex>
                            ))}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>

                    {isEnable3DotsHistory && (
                      <Menu.Root>
                        <Menu.Content
                          componentTrigger={
                            <IconButton size='small'>
                              <Icon icon='ellipsis-v' />
                            </IconButton>
                          }
                        >
                          <Menu.Item
                            css={{ fontSize: '$1', paddingRight: '30px' }}
                            size='large'
                            onClick={() => {
                              openModal(historic);
                            }}
                          >
                            <Box css={{ marginRight: '$2' }}>
                              <FontAwesomeIcon icon={faArrowRotateLeft} />
                            </Box>
                            {i18n.t('action.restore_version')}
                          </Menu.Item>
                          <Menu.Item
                            size='large'
                            data-testid='run-card-action-detail'
                            onClick={() => goToPipeline(historic.pipeline.id)}
                            css={{ fontSize: '$1' }}
                          >
                            <Box style={{ marginRight: '10px' }}>
                              <Icon icon='project-diagram' size='md' />
                            </Box>
                            {i18n.t('scenes.runtime.actions.go_to_pipeline')}
                          </Menu.Item>
                        </Menu.Content>
                      </Menu.Root>
                    )}
                  </Flex>
                ))}
              </Box>
              <Box
                css={{
                  margin: '34px 0'
                }}
              >
                <Pagination
                  count={historics.totalElements}
                  page={historics.number}
                  rowsPerPage={historics.size}
                  rowsPerPageOptions={[historics.size]}
                  disabledPrevious={historics.first}
                  disabledNext={historics.last}
                  nextPage={() => {
                    getData(historics.number + 1);
                  }}
                  previousPage={() => {
                    getData(historics.number - 1);
                  }}
                  showRowsPerPage={false}
                />
              </Box>
              <ShowDialogLicenseRevert
                opened={showDialog}
                onCancel={() => setShowDialog(false)}
                licenseStatus={licenseStatus}
                history={history}
                environment={environment}
              />
            </SideSheet.Section>
          </>
        ))
      )}

      <SideSheet.Footer>
        <Button onClick={() => navigate(-1)}>{i18n.t('action.close')}</Button>
      </SideSheet.Footer>
    </SideSheet>
  );
};

export default Detail;
