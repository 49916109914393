import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-left: 16px;

  ${p =>
    p.isEllipse &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}

  @media (min-width: 1750px) {
    max-width: 300px;
  }
`;

export const Icon = styled(props => <FontAwesomeIcon {...props} />)`
  color: ${props => props.theme.colors.gra500};
  margin-right: 8px;
`;
