/* eslint-disable */

// deprecated
import { ApolloClient, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import onErrorHandlers from '~/common/helpers/onErrorHandlers';
import { GRAPHQL_SERVER, API_KEY } from './environment';
import storage from '~/common/helpers/storage';

export const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: GRAPHQL_SERVER
  });

  const authLink = setContext((_, { headers }) => {
    const user = storage.get('userData', true);

    return {
      headers: {
        ...headers,
        apiKey: API_KEY,
        authorization: user ? `${user.token}` : null
      }
    };
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  };

  return new ApolloClient({
    link: ApolloLink.from([onErrorHandlers, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions
  });
};
