import React, { useState, useRef, FC, ReactNode } from 'react';
import { useClickAway, useSize } from 'react-use';
import styled, { css } from 'styled-components';

const Container = styled.div<{ zIndexValue: number }>`
  position: relative;
  z-index: ${p => p.zIndexValue};
`;

const ContentWrapper = styled.div`
  position: relative;
  display: initial;
`;

const calculateMenuHeight = ({ height = 200 }) => (height > 300 ? 300 : height);

const MenuContainer = styled.div<{
  left?: number;
  width: string;
  height: number;
  fullWidth?: boolean;
  opened?: boolean;
}>`
  position: absolute;
  display: block;
  background: ${props => props?.theme?.colors?.white};
  margin: 0;
  padding: 0;
  top: 100%;
  left: ${p => p.left || 0};
  overflow-x: hidden;
  overflow-y: auto;
  width: ${p => p.width};
  height: ${calculateMenuHeight}px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 1000;
  ${p =>
    p.fullWidth
      ? css`
          width: 100%;
        `
      : ''}

  ${p =>
    p.opened
      ? css`
          opacity: 1;
        `
      : css`
          visibility: hidden;
        `}
`;

interface MenuProps {
  position?: string;
  width?: string;
  fullWidth?: boolean;
  component:
    | ReactNode
    | (({
        opened,
        toggle
      }: {
        toggle?: (value?: boolean) => void;
        opened: boolean;
      }) => ReactNode);
  children:
    | ReactNode
    | (({
        opened,
        toggle
      }: {
        toggle?: (value?: boolean) => void;
        opened: boolean;
      }) => ReactNode);

  zIndexValue?: number;
  'data-testid'?: string;
}

const Menu: FC<MenuProps> = ({
  children,
  component,
  fullWidth,
  width = '200px',
  zIndexValue = 2000,
  'data-testid': dataTestId = 'menu',
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const toggle = () => setOpened(!opened);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpened(false);
  });

  const helpers = { toggle, opened };

  const wrappedComponent =
    typeof component === 'function' ? component(helpers) : component;

  const [content, { height }] = useSize(
    () => (
      <div>
        {typeof children === 'function' ? (
          children(helpers)
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div onClick={() => setOpened(false)}>{children}</div>
        )}
      </div>
    ),
    {
      height: 100
    }
  );

  return (
    <div ref={ref}>
      <Container data-testid={dataTestId} zIndexValue={zIndexValue}>
        <ContentWrapper>{wrappedComponent}</ContentWrapper>
        <MenuContainer
          fullWidth={fullWidth}
          opened={opened}
          height={height || 200}
          width={width}
          {...props}
          data-testid={null}
        >
          {content}
        </MenuContainer>
      </Container>
    </div>
  );
};

export default Menu;
