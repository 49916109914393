import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;
  padding: 36px;
  overflow-y: auto;
  height: calc(100vh - 260px);
  padding-top: 56px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled((props: FontAwesomeIconProps) => (
  <FontAwesomeIcon {...props} />
))`
  animation-duration: 1.65s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  font-size: 1rem;
`;
