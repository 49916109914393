import { isNumber } from 'lodash';

type isAutoScalingDeployParams = {
  minReplicaCount?: number;
  replicaCount?: number;
};

export const isAutoScalingDeploy = ({
  replicaCount,
  minReplicaCount
}: isAutoScalingDeployParams) =>
  isNumber(minReplicaCount) && replicaCount !== minReplicaCount;
