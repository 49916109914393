const parseCookie = str =>
  str
    .split('; ')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0])] = decodeURIComponent(v[1]);
      return acc;
    }, {});

export default parseCookie;
