import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';

import { prepare } from './pipelineExperimental';

// Locals
import * as api from '~/api';

const initialState = Immutable({
  isOpen: false,
  loading: false,
  result: {
    data: '',
    digibeeKey: ''
  },
  failed: {
    message: null
  },
  field: {
    code: {
      value: '{}',
      error: false
    },
    autoForm: null
  },
  success: {
    test: {
      data: '',
      digibeeKey: ''
    }
  }
});

const testCapsule = {
  name: 'testCapsule',
  state: initialState,
  reducers: {
    setOpen: (state, isOpen = true) => state.merge({ isOpen }),
    setFieldCode(state, payload) {
      return state.merge(
        {
          field: {
            code: payload
          }
        },
        { deep: true }
      );
    },
    setFieldAutoForm(state, payload) {
      return state.setIn(['field', 'autoForm'], payload);
    },
    setSuccess(state, payload) {
      return state.merge({
        result: payload
      });
    },
    setFailed(state, payload) {
      return state.merge({
        failed: payload
      });
    },
    setLoading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    reset: state => state.merge(initialState)
  },
  effects: dispatch => ({
    cancel() {
      api.testCapsule.cancel();
    },

    async execute(
      payload,
      { capsule, application, cy, design, testCapsule: test }
    ) {
      try {
        const { inSpec, outSpec } = capsule.data.pipeline;
        const { realm } = application.realm;
        const { trackingId } = design;
        const canvas = prepare(cy.present.getIn(['root']));
        const { code, autoForm } = test.field;
        const result = await api.testCapsule.execute({
          inSpec: inSpec || {},
          outSpec: outSpec || {},
          realm,
          canvas,
          payload: code.value,
          trackingId,
          accountLabels: {},
          params: {
            params: autoForm ? autoForm.params : {},
            accountLabels: autoForm ? autoForm.accountLabels : {}
          }
        });

        dispatch.testCapsule.setSuccess(result);
      } catch (e) {
        dispatch.testCapsule.setFailed(e.messge);
        toast.error(e.message);
      }
    },

    run() {
      dispatch.design.setOpenTestMode();
      dispatch.testCapsule.execute();
    },

    toggle(payload, state) {
      dispatch.design.setOpenTestMode(!state.design.isOpenTestMode);
    }
  }),
  logics: [
    {
      type: 'testCapsule/execute',
      latest: true,
      process(context, dispatch, done) {
        dispatch.logs.reset();
        dispatch.message.reset();
        dispatch.testCapsule.setLoading(true);
        done();
      }
    },
    {
      type: 'testCapsule/setSuccess',
      latest: true,
      process({ action }, dispatch, done) {
        const { digibeeKey } = action.payload;
        dispatch.testCapsule.setLoading(false);
        dispatch.message.fetchMessages({ key: digibeeKey });
        dispatch.logs.fetchLogs({ key: digibeeKey });
        done();
      }
    },

    {
      type: 'testCapsule/setFailed',
      latest: true,
      process(context, dispatch, done) {
        dispatch.testCapsule.setLoading(false);
        done();
      }
    },
    {
      type: 'capsule/reset',
      latest: true,
      process(context, dispatch, done) {
        dispatch.logs.reset();
        dispatch.message.reset();
        done();
      }
    }
  ]
};

export default testCapsule;
