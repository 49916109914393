import Immutable from 'seamless-immutable';

import { acls } from '~/api';

const initialState = Immutable({
  scopes: [],
  loading: true,
  error: null
});

const aclsModel = {
  name: 'acls',
  state: initialState,
  reducers: {
    setScopes(state, scopes) {
      return state.merge({ scopes });
    },
    loading(state, loading) {
      return state.merge({ loading });
    },
    actionNotPermitted(state) {
      return state;
    }
  },
  effects: dispatch => ({
    async fetch(payload, rootState) {
      dispatch.acls.loading(true);
      try {
        const { realm } = rootState.application.realm;
        const { scopes } = await acls.fetch({ realm });
        dispatch.acls.setScopes(scopes);
        dispatch.acls.loading(false);
      } catch (error) {
        dispatch.acls.loading(false);
      }
    }
  }),
  logics: [
    {
      type: 'application/findRealms',
      latest: true,
      process(context, dispatch, done) {
        dispatch.acls.fetch();
        done();
      }
    },
    {
      type: '*',
      latest: true,
      validate({ getState, action }, allow, reject) {
        if (action.meta && action.meta.acls) {
          const defaultAcls = ['GROUP:ADMIN'];
          const roles = [].concat(action?.meta?.acls, defaultAcls);
          const scopes = getState().acls?.scopes;
          const isAuthorized = scopes.some(scope => roles.includes(scope));
          if (isAuthorized) {
            allow(action);
          } else {
            reject({
              type: 'acls/actionNotPermitted',
              payload: {},
              error: true
            });
          }
        } else {
          allow(action);
        }
      }
    }
  ]
};

export default aclsModel;
