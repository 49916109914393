import { Formik } from 'formik';
import { func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

// Locals
import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import Button from '~/components/Button';
import { Input } from '~/components/Input';

const Schema = yup.object().shape({
  realm: yup.string().required('scenes.login.messages.error.required_field')
});

const StyledContainer = styled.form`
  width: 100%;
`;

const StyledFields = styled.div`
  padding: 10px 0px;
`;

const FormRealm = ({ redirectToRealm }) => (
  <StyledContainer autoComplete='off'>
    <Formik
      data-testid='governance-prior-realm-input-realm-page'
      validationSchema={Schema}
      initialValues={{
        realm: ''
      }}
      onSubmit={({ realm }) => redirectToRealm(realm)}
      render={({ handleChange, handleSubmit, values, errors, touched }) => (
        <form
          onSubmit={ev => {
            ev.preventDefault();
          }}
        >
          <StyledFields>
            <Input
              data-testid='governance-realm-input-realm-page'
              name='realm'
              value={values.realm}
              helperText={i18n.t(getFieldError(touched, errors, 'realm'))}
              danger={i18n.t(getFieldError(touched, errors, 'realm'))}
              onChange={handleChange}
              placeholder={i18n.t('scenes.login.labels.realm')}
            />
          </StyledFields>
          <StyledFields>
            <Button
              data-testid='governance-continue-button-realm-page'
              key='save'
              is='submit'
              type='primary'
              fluid
              onClick={handleSubmit}
            >
              {i18n.t('action.continue')}
            </Button>
          </StyledFields>
        </form>
      )}
    />
  </StyledContainer>
);

FormRealm.propTypes = {
  redirectToRealm: func
};

FormRealm.defaultProps = {
  redirectToRealm: () => { }
};

export default FormRealm;
