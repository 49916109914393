import styled from 'styled-components';

import DefaultButton from '~/components/Button';
import { DialogFooter } from '~/components/Dialog';

// @ts-ignore
export const Button = styled(DefaultButton)`
  &:last-of-type {
    margin-right: 0;
  }
`;

export const Footer = styled(DialogFooter)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Separator = styled.div`
  padding-left: ${props => props.theme.spacings.bit};
`;

export const ContainerText = styled.div`
  margin-bottom: 10px;
`;

export const ContainerPromote = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const TitleEnv = styled.p`
  font-weight: bold;
`;

export const BoxPromote = styled.div`
  width: 47%;
`;
