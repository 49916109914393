import { Text } from '@digibee/beehive-ui';
import React from 'react';

type Props = {
  text: string;
  link: string;
  linkText: string;
};

const DescriptionHelperV2: React.FC<Props> = ({ text, link, linkText }) => (
  <>
    <Text css={{ color: '$neu400', lineHeight: '22px' }}>
      {text}
      <a
        target='_blank'
        rel='no noreferrer'
        href={link}
        style={{ textDecoration: 'none' }}
      >
        <Text
          css={{
            fontWeight: 'bold',

            color: '$pri500'
          }}
        >
          {linkText}
        </Text>
      </a>
    </Text>
  </>
);

export default DescriptionHelperV2;
