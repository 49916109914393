import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const globals = {
  find: ({ realm, search }) =>
    clientApollo.query({
      query: gql`
        query globals($realm: String!, $search: String) {
          globals(realm: $realm, search: $search) {
            id
            category
            description
            field
            valuesByEnv
            realm {
              id
              name
              description
              companyName
            }
          }
        }
      `,
      variables: {
        realm,
        search
      }
    }),
  findPaginated: ({ realm, field, page }) =>
    clientApollo.query({
      query: gql`
        query globalsPaginated(
          $realm: String!
          $field: String
          $page: Int
        ) {
          globalsPaginated(realm: $realm, field: $field, page: $page) {
            content {
              id
              category
              description
              field
              valuesByEnv
              realm {
                id
                name
                description
                companyName
              }
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
      variables: {
        realm,
        field,
        page
      }
    }),
  findPaginatedGlobalsPipelines: ({ realm, globalId, page }) => clientApollo.query({
    query: gql`
        query globalsAssociatedPipelines($realm: String!, $globalId: String, $page: Int) {
          globalsAssociatedPipelines(realm: $realm, globalId: $globalId, page: $page) {
            content {
              pipeline {
                id
                name
                description
                versionMajor
                versionMinor
                canvasVersion
              }
              project {
                name
              }
              environments {
                description
                name
                id
              }
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
    variables: {
      realm,
      globalId,
      page
    }
  }),
  findPaginatedDeployments: ({ realm, globalId, page }) => clientApollo.query({
    query: gql`
        query globalsAssociatedDeployments($realm: String!, $globalId: String, $page: Int) {
          globalsAssociatedDeployments(realm: $realm, globalId: $globalId, page: $page) {
            content {
              pipeline
              project
              environment
              activeConfiguration
              id
              status
              startTime
              availableReplicas
              oomCount
              lastError
              errorCount
              lastOMM
              endpoint
              trigger
              metadata
              licenses
              replicaName
              deploymentStatus
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
    variables: {
      realm,
      globalId,
      page
    }
  }),
  globalsNotification({ realm, globalId, environment, deployments }) {
    return clientApollo.mutate({
      mutation: gql`
          mutation globalsNotification($realm: String!, $globalId: String, $environment: String!,  $deployments:JSON) {
            globalsNotification(realm: $realm, globalId: $globalId, environment: $environment,deployments: $deployments)
          }
        `,
      variables: {
        realm,
        globalId,
        environment,
        deployments
      }
    });
  },
  create({ realm, global }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createGlobal($realm: String!, $globalObject: GlobalInput) {
          createGlobal(realm: $realm, globalObject: $globalObject)
        }
      `,
      variables: {
        realm,
        globalObject: global
      }
    });
  },
  edit({ realm, global, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editGlobal(
          $realm: String!
          $globalObject: GlobalInput
          $globalId: String
        ) {
          editGlobal(
            realm: $realm
            globalObject: $globalObject
            globalId: $globalId
          )
        }
      `,
      variables: {
        realm,
        globalObject: global,
        globalId: id
      }
    });
  },
  async remove({ realm, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteGlobal($realm: String!, $globalId: String!) {
          deleteGlobal(realm: $realm, globalId: $globalId)
        }
      `,
      variables: {
        realm,
        globalId: id
      }
    });
  },
  async isGlobalNameValid({ realm, name }) {
    const request = await clientApollo.query({
      query: gql`
        query validateGlobalName($realm: String!, $name: String!) {
          validateGlobalName(realm: $realm, name: $name) {
            isValid
            error
          }
        }
      `,
      variables: {
        name,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request?.data?.validateGlobalName;
  },
};

export default globals;
