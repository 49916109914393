const parseJson = <T = unknown>(obj: string) => {
  const type = typeof obj;

  if (type === 'object' || Array.isArray(obj)) return obj as T;
  if (type === 'string' && obj !== 'null') return JSON.parse(obj) as T;

  return '' as T;
};

export default parseJson;
