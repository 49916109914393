import { get, omit, partition } from 'lodash';
import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  PropsWithChildren,
  FC,
  ReactNode,
  Children,
  createContext,
  ReactElement,
  HTMLAttributes
} from 'react';
import { Minus } from 'react-feather';
import { Link, LinkProps, To } from 'react-router-dom';
import styled, { css } from 'styled-components';

import i18n from '~/common/helpers/i18n';

interface ListContextProps {
  listExist: boolean;
  mediaType: boolean;
  registerMediaType: (value: any) => void;
  setItemSelected: (value: boolean) => void;
  itemSelected: boolean;
}

const ListContext = createContext<ListContextProps>({
  listExist: false,
  mediaType: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerMediaType: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItemSelected: () => {},
  itemSelected: false
});

const CustomLink = styled<LinkProps>(props => <Link {...props} />)`
  text-decoration: none;
  color: inherit;
  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }
`;

let StyledItem = styled.li`
  font-family: ${props => props.theme.typography.fontFamily};
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  ${props =>
    props.onClick
      ? css`
          cursor: pointer;
        `
      : ''};
`;

StyledItem = Object.assign(StyledItem, {
  'data-testid': 'item'
});

const StyledItemContent = styled.div<{ minimum?: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.gra300};
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 48px;
  pointer-events: none;
  overflow: inherit;
  ${props =>
    props.minimum
      ? css`
          width: 10%;
        `
      : ''}
`;

const StyledListing = styled.ul<{
  inset?: boolean;
  noHairlines?: boolean;
  itemSelected?: boolean;
  drag?: boolean;
}>`
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  ${props =>
    props.inset
      ? css`
          margin: 0;
        `
      : ''}

  ${props =>
    props.noHairlines === true
      ? css`
          ${StyledItemContent} {
            border-bottom: 1px solid transparent;
          }
        `
      : ''}

    ${props =>
    props.itemSelected === false
      ? css`
          ${StyledItem} {
            &:hover {
              background-color: rgba(223, 223, 223, 0.5);
              ${props.drag
                ? css`
                    cursor: grab;
                    position: relative;
                    z-index: 900;
                    &:before {
                      background-image: url(${props.theme.images
                        .iconActionDrag});
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: 18px;
                      content: '';
                      cursor: grab;
                      height: 100%;
                      left: -1px;
                      position: absolute;
                      opacity: 0.35;
                      width: 18px;
                    }
                  `
                : ''}
            }
          }
        `
      : ''}

  ${props =>
    props.drag
      ? css`
          cursor: grab;
          overflow: auto;
        `
      : ''}
`;

type mediaTypes = 'avatar' | 'image' | 'icon' | 'label';

interface StyleMediaProps {
  type?: mediaTypes;
}

const StyledMedia = styled.div<StyleMediaProps>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  pointer-events: none;

  ${props => {
    switch (props.type) {
      case 'image':
        return css`
          height: 56px;
          margin: 8px 16px 8px 0;
          width: 100px;
        `;
      case 'avatar':
        return css`
          height: 42px;
          margin: 8px 16px 8px 16px;
          width: 42px;
        `;
      case 'icon':
        return css`
          height: 24px;
          margin: 16px 32px 16px 16px;
          width: 24px;
        `;
      case 'label':
        return css`
          margin: 12px 10px 10px 10px;
          width: 30px;
          height: 30px;
        `;
      default:
        return css`
          margin-left: 16px;
          width: 0;
        `;
    }
  }}
`;

let Media: FC<PropsWithChildren & StyleMediaProps> = ({
  children,
  ...rest
}) => <StyledMedia {...rest}>{children}</StyledMedia>;

Media = Object.assign(Media, {
  role: 'Media'
});

const StyledEmpty = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.gra500};
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  justify-content: center;
  line-height: 3em;
  padding: 16px;
`;

const StyledLoading = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.gra500};
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  line-height: 3em;
`;

const DefaultEmpty: FC<PropsWithChildren> = ({ children, ...restProps }) => (
  <StyledEmpty {...restProps}>
    <Minus color='rgba(0, 0, 0, .35)' size={64} />
    {children}
  </StyledEmpty>
);

interface ItemProps extends Omit<HTMLAttributes<HTMLLIElement>, 'children'> {
  children: ReactNode;
  linkTo?: To;
  target?: string;
}

const Item: FC<ItemProps> = ({ children, linkTo, target, ...rest }) => {
  const { mediaType, registerMediaType, setItemSelected, itemSelected } =
    useContext(ListContext);

  const [mediaList, componentList] = partition(
    Children.toArray(children),
    item => get(item, ['type', 'role']) === 'Media'
  );

  const handleMouseUp = useCallback(() => {
    if (itemSelected) {
      setItemSelected(false);
    }
  }, [itemSelected, setItemSelected]);

  const handleMouseDown = useCallback(() => {
    setItemSelected(true);
  }, [setItemSelected]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  // Somente o primeiro tipo de mídia é aceito.
  if (mediaType === false && mediaList.length !== 0)
    registerMediaType((mediaList[0] as ReactElement).props.type);

  const item = (
    <StyledItem onMouseDown={handleMouseDown} {...rest}>
      {mediaList}
      <StyledItemContent className='pipeline-content'>
        {componentList}
      </StyledItemContent>
    </StyledItem>
  );

  if (linkTo) {
    return (
      <CustomLink to={linkTo} target={target}>
        {item}
      </CustomLink>
    );
  }

  return item;
};

interface ListProps {
  children?: ReactNode | null;
  textWhenEmpty?: string;
  textWhenLoading?: string;
  components?: { Loading?: any; Empty: any };
  draggable?: boolean;
  'data-testid'?: string;
  ref?: any;
  noHairlines?: boolean;
  inset?: boolean;
}

const List: FC<ListProps> = ({
  children = null,
  textWhenEmpty = i18n.t('common.guidance.empty_list'),
  textWhenLoading = i18n.t('common.labels.loading'),
  'data-testid': dataTestId = 'list',
  components = {
    Loading: StyledLoading,
    Empty: DefaultEmpty
  },
  draggable,
  ...rest
}) => {
  const [mediaType, registerMediaType] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);

  const renderEmpty = () => {
    const { Empty, Loading } = components;
    if (children === null) return <Loading>{textWhenLoading}</Loading>;
    if (React.Children.count(children) !== 0) return [];

    return <Empty>{textWhenEmpty}</Empty>;
  };

  const renderListing = () => (
    <StyledListing
      itemSelected={itemSelected}
      drag={draggable}
      innerRef={rest.ref}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </StyledListing>
  );

  const propagateProps = omit(rest, ...['ref']);

  return (
    <ListContext.Provider
      {...propagateProps}
      value={{
        listExist: true,
        mediaType,
        registerMediaType,
        setItemSelected,
        itemSelected
      }}
    >
      {renderEmpty()}
      {renderListing()}
    </ListContext.Provider>
  );
};

export default Object.assign(List, {
  role: 'List',
  Item,
  Media
});
