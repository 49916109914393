import styled from 'styled-components';

const Card = styled.div`
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  border: 1 solid ${props => props.theme.colors.gra400};
`;

Card.displayName = 'Card';
Card.defaultProps = {
  'data-testid': 'card'
};

const WapperTextSection = styled.div`
  padding: 16px 12px 16px 12px;
`;

WapperTextSection.displayName = 'WapperTextSection';

const Title = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: ${props => props.theme.fontWeight.regular};
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: capitalize;
`;

Title.displayName = 'Title';

const Subhead = styled(Title)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  opacity: 0.6;
`;

Subhead.displayName = 'Subhead';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
`;

Actions.displayName = 'Actions';

const Media = styled.div`
  margin-top: 56.25%;
`;

Media.displayName = 'Media';

Card.WapperTextSection = WapperTextSection;
Card.Title = Title;
Card.Subhead = Subhead;
Card.Actions = Actions;
Card.Media = Media;

export default Card;
