import { Text, theme } from '@digibee/beehive-ui';
import { useTranslation } from 'react-i18next';

const ContainerFooter = theme.styled('div', {
  width: '100%',
  position: 'absolute',
  left: '50px',
  bottom: '32px',
  textAlign: 'left',

  'div': {
    color: '$neu500',
  }
});

const Link = theme.styled('a', {
  color: '$blu500',
  textDecoration: 'none'
});

const Information = theme.styled(Text, {
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
});

function FooterLoginIssues() {
  const { t } = useTranslation();

  return (
    <ContainerFooter>
      <Information variant='b3'>{t('label.still_have_login_problems')}</Information>
      <Information variant='b3'>
        {t('label.read_our_article_about')}
        &nbsp;
        <Link
          href={t('action.blocked_access_login_issues_url')}
          target='_blank'
        >
          {t('action.blocked_access_login_issues')}
        </Link>
      </Information>
    </ContainerFooter>
  );
}

export default FooterLoginIssues;
