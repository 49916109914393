import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import withHooks from '~/common/hoc/withHooks';

type Store = {
  authentication: {
    realmParams: {
      redirectUrl: string;
    };
    error: {
      realm: string;
    };
  };
};

type Dispatch = {
  authentication: {
    getNotLoggedPlatformParameter: ({realm, param}: {realm: string, param: string}) => void;
  };
};

const enhancer = withHooks(() => {
  const [buttonReady, setButtonReady] = useState<boolean>(false);
  const { redirectUrl, realm } = useSelector(({ authentication }: Store) => ({
    redirectUrl: authentication.realmParams?.redirectUrl,
    realm: authentication.error?.realm
  }));
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (realm) {
      if (!redirectUrl) dispatch.authentication.getNotLoggedPlatformParameter({realm, param: 'hasCustomerIdentityProvider'});
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      setButtonReady(true);
    } else {
      setButtonReady(false);
    }
  }, [redirectUrl]);

  return {
    buttonLoading: !buttonReady,
    idpLoginUrl: redirectUrl
  };
});

export default enhancer;
