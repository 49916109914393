import * as Elements from './TwoFactorAlert.elements';
import { Props } from './TwoFactorAlert.enhancer';

import i18n from '~/common/helpers/i18n';

const TwoFactorAlert = ({ returnToSignIn }: Props) => (
  <Elements.StyledContainer>
    <Elements.StyledMessage data-testid='governance-message-two-factor-page'>{i18n.t('label.if_you_dont_have_access_two_factor_contact_manager')}</Elements.StyledMessage>
    <Elements.StyledButton data-testid='governance-return-to-sing-in-button-two-factor-page' variant='primary' onClick={returnToSignIn}>
      {i18n.t('scenes.login.actions.return_sign_in')}
    </Elements.StyledButton>
  </Elements.StyledContainer>
);

export default TwoFactorAlert;
