import styled from 'styled-components';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.gra400};
  box-sizing: border-box;
`;

export const CardContent = styled.div`
  display: grid;
  column-gap: 5px;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-items: center;
`;

interface InfoProps {
  total?: boolean;
}

export const Info = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme, total }) =>
    total ? theme?.colors?.gra50 : theme?.colors?.white};
`;

export const Operator = styled.div`
  background-color: ${({ theme }) => theme.colors.gra50};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
