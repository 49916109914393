import * as Elements from './Loadingbar.elements';

const LoadingBar = () => (
  <Elements.LoadingBarContainer data-testid='debugger-loading-bar'>
    <Elements.LoadingBarProgressBarWrapper>
      <Elements.LoadingBarProgressBar />
    </Elements.LoadingBarProgressBarWrapper>
  </Elements.LoadingBarContainer>
);

export default LoadingBar;
