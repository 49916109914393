import { string } from 'prop-types';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

// Locals
import CloseButton from './components';

const Container = styled.div``;

const StyledToastContainer = styled(ToastContainer)`
  z-index: 99991;
  .Toastify__toast {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    z-index: 4000;
  }
  .Toastify__toast-container--top-right {
    z-index: 99991;
  }
  .Toastify__toast-body {
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.fontSize}px;
  }
  .Toastify__toast-container {
    width: 344px;
    z-index: 99991;
  }
  .Toastify__toast--success {
    background: ${props => props.theme.colors.gre500};
  }
  .Toastify__toast--error {
    background: ${props => props.theme.colors.red500};
  }
  .Toastify__toast--info {
    background: ${props => props.theme.colors.pri500};
  }
  .Toastify__toast--warning {
    background: ${props => props.theme.colors.yel500};
  }
`;

const Toast = ({ textClose }) => (
  <Container>
    <StyledToastContainer
      style={{ zIndex: 100000 }}
      closeButton={<CloseButton textClose={textClose} />}
    />
  </Container>
);

Toast.propTypes = {
  textClose: string
};

Toast.defaultProps = {
  textClose: ''
};

export default Toast;
