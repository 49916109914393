import styled from 'styled-components';

import NativeButton from '~/components/Button';
import NativeText from '~/components/Text';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerFooter = styled(Container)`
  position: absolute;
  bottom: 32px;
`;

export const Text = styled(NativeText)`
  margin-bottom: 28px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gra500};
  line-height: 1.4;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.pri500};
  margin-bottom: 12px;
`;

export const InputContainer = styled.div`
  margin: 24px auto;
`;

export const Caption = styled(Text)`
  font-size: 12px;
  margin-bottom: unset;
`;

export const DangerCaption = styled(Caption)`
  color: #ab073b;
  font-weight: 400;
`;

export const Link = styled.a``;

// @ts-ignore
export const Button = styled(NativeButton)`
  margin-bottom: 8px;
`;
