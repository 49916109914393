import {
  Box,
  Icon,
  IconButton,
  Menu,
  Table,
  Tag,
  TagProps,
  Text,
  Tooltip
} from '@digibee/beehive-ui';
import {
  faArrowRotateLeft,
  faUpFromDottedLine,
  faQuestion,
  faWarning,
  faTrash,
  faCircleCheck
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { find } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useIsAutoscaling } from '../../hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../utils/isAutoScalingDeploy';
import {
  Deployment,
  GenericCardRuntimeProps,
  handleClickWithoutBubbling
} from '../CardRuntime';
import {
  DEPLOY_STATUS,
  isDisabled,
  normalizeSizeDeploy
} from '../CardRuntime/CardRuntime.hook';

import i18n from '~/common/helpers/i18n';
import ConfirmDialog from '~/components/ConfirmDialogV2';
import Role from '~/components/Role';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';
import { DataMessageType } from '~/scenes/Runtime/components/StatusDeploy';

interface DeployedPipelineProps extends GenericCardRuntimeProps {
  triggers: any[];
}

export const getMessageStatus = (deployment: Deployment): DataMessageType => {
  const status: { [key: string]: DataMessageType } = {
    deleting: {
      text: i18n.t('label.deleting'),
      icon: faTrash,
      style: 'warning'
    },
    starting: {
      text: i18n.t('label.starting'),
      icon: faQuestion,
      loader: true,
      style: 'neutral'
    },
    degraded: {
      text: i18n.t('label.starting'),
      icon: faQuestion,
      style: 'info'
    },
    error: {
      text: i18n.t('label.error'),
      style: 'danger',
      icon: faWarning
    },
    deployed: {
      text: i18n.t('label.deployed'),
      style: 'success',
      icon: faCircleCheck
    },
    rollbacked: {
      text: i18n.t('label.rolled_back'),
      icon: faQuestion,
      style: 'info'
    },
    restored: {
      text: i18n.t('label.restored_colon'),
      icon: faCircleCheck,
      style: 'success'
    }
  };

  const statusSelected: string = deployment?.status?.text?.toLowerCase();
  if (status[statusSelected]) {
    return status[statusSelected];
  }
  return status.deployed;
};

export function DeployedPipeline({
  deployment,
  triggers,
  remove,
  goToPipeline,
  redeploy,
  openDetailPromote,
  openModalRollbackDeploy,
  onClick
}: DeployedPipelineProps) {
  const confirmDialog = ConfirmDialog.useConfirmDialog();
  const { environment, activeRealm } = useSelector(
    (state: StoreRootState) => state.application
  );
  const [_, setDisabled] = useState(isDisabled(deployment?.status?.text));

  useEffect(() => {
    setDisabled(isDisabled(deployment?.status?.text));
  }, [deployment]);

  const isRunMovePipelineShow = environment !== 'prod';

  const newEnvironment = environment === 'test' ? 'prod' : 'test';

  const promoteValue = t(`action.move_to_${newEnvironment}`);
  const trigger = find(
    triggers,
    value =>
      value.jsonExample.name === deployment?.pipeline.triggerSpec?.name &&
      value.jsonExample.type === deployment?.pipeline.triggerSpec?.type
  );

  const showNewAutoScalingExperience = useIsAutoscaling();
  const showAutoScalingValues =
    isAutoScalingDeploy({
      minReplicaCount: deployment.activeConfiguration.minReplicaCount,
      replicaCount: deployment.activeConfiguration.replicaCount
    }) && showNewAutoScalingExperience;
  const dataMessage = getMessageStatus(deployment);

  return (
    <Table.Row className='table-body-row'>
      <Table.Cell>
        <Box
          as='img'
          css={{
            borderRadius: '50%',
            display: 'block',
            marginInline: 'auto'
          }}
          alt={t('scenes.beta_pipelines.labels.trigger_icon')}
          height={32}
          width={32}
          src={trigger?.iconURL}
        />
      </Table.Cell>
      <Table.Cell>
        <Text as='p' css={{ fontSize: '$1', lineHeight: '$2' }}>
          {deployment.pipeline.name}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Box
          css={{
            borderRadius: '$1',
            border: '1px solid $neu500',
            width: 'fit-content',
            padding: '2px 8px'
          }}
        >
          <Text as='p' css={{ fontSize: '$1', lineHeight: '$2' }}>
            V. {deployment.pipeline.versionMajor}.
            {deployment.pipeline.versionMinor}
          </Text>
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Tag variant={dataMessage.style as TagProps['variant']}>
          {dataMessage.text}
        </Tag>
      </Table.Cell>
      <Table.Cell>
        <Box
          css={{
            borderRadius: '$1',
            border: '1px solid $neu500',
            width: 'fit-content',
            padding: '2px 8px'
          }}
        >
          <Text as='p' css={{ fontSize: '$1', lineHeight: '$2' }}>
            {deployment.deploymentStatus?.pipelineEngineVersion?.replace(
              /^([^.]*\.[^.]*\.[^.]*).*/,
              '$1'
            )}
          </Text>
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Text as='p' css={{ fontSize: '$1', lineHeight: '$2' }}>
          {normalizeSizeDeploy(deployment.activeConfiguration.name)} (
          {deployment.activeConfiguration.actualConsumers})
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Tooltip
          content={
            showAutoScalingValues
              ? `${i18n.t('scenes.runtime.labels.minimum_replicas')} ${
                  deployment.activeConfiguration.minReplicaCount
                } / ${i18n.t('scenes.runtime.labels.maximum_replicas')} ${
                  deployment.activeConfiguration.replicaCount
                }`
              : `${i18n.t('scenes.runtime.labels.running_replicas')} ${
                  deployment.availableReplicas?.split('/')[0] || 0
                } / ${i18n.t('scenes.runtime.labels.target_of_replicas')} ${
                  deployment.activeConfiguration.replicaCount
                }`
          }
        >
          <Text as='p' css={{ fontSize: '$1', lineHeight: '$2' }}>
            {showAutoScalingValues
              ? `Min. ${deployment.activeConfiguration.minReplicaCount} / Max. ${deployment.activeConfiguration.replicaCount}`
              : `Run. ${
                  deployment.availableReplicas?.split('/')[0] || 0
                } / Targ. ${deployment.activeConfiguration.replicaCount}`}
          </Text>
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <Text as='p' css={{ fontSize: '$1', lineHeight: '$2' }}>
          {moment(deployment.startTime, 'x').format('L LTS')}
        </Text>
      </Table.Cell>
      <Table.Cell fixedRight>
        <Role
          name={[
            `DEPLOYMENT:DELETE{ENV=${environment?.toUpperCase()}}`,
            'DEPLOYMENT:DELETE'
          ]}
        >
          <Menu.Root>
            <Menu.Content
              componentTrigger={
                <IconButton
                  disabled={
                    deployment?.status?.text === DEPLOY_STATUS.STARTING ||
                    deployment?.status?.text === DEPLOY_STATUS.DELETING
                  }
                  size='small'
                  data-testid='run-deployed-pipelines-table-pipeline-actions'
                >
                  <Icon icon='ellipsis-vertical' />
                </IconButton>
              }
              css={{ zIndex: 50, padding: 0 }}
            >
              <Menu.Item
                size='large'
                data-testid='run-deployed-pipelines-table-pipeline-action-pipeline-details'
                onClick={handleClickWithoutBubbling(() => onClick())}
                css={{
                  fontSize: '$2',
                  padding: '13px 16px',
                  height: 'auto',
                  lineHeight: '$2',
                  color: '$neu600'
                }}
              >
                <div style={{ marginRight: '10px' }}>
                  <Icon icon='circle-info' size='md' />
                </div>
                {t('scenes.runtime.actions.more_info')}
              </Menu.Item>
              <Menu.Item
                size='large'
                data-testid='run-deployed-pipelines-table-pipeline-action-go-to-pipeline'
                onClick={handleClickWithoutBubbling(() =>
                  goToPipeline(deployment?.pipeline?.id)
                )}
                css={{
                  fontSize: '$2',
                  padding: '13px 16px',
                  height: 'auto',
                  lineHeight: '$2',
                  color: '$neu600'
                }}
              >
                <div style={{ marginRight: '10px' }}>
                  <Icon icon='project-diagram' size='md' />
                </div>
                {t('scenes.runtime.actions.go_to_pipeline')}
              </Menu.Item>
              <Menu.Item
                size='large'
                data-testid='run-deployed-pipelines-table-pipeline-action-redeploy'
                onClick={handleClickWithoutBubbling(() =>
                  redeploy({
                    ...deployment?.pipeline,
                    isPipelineEngine:
                      deployment?.deploymentStatus?.pipelineEngineBeta ===
                      'true'
                  })
                )}
                css={{
                  fontSize: '$2',
                  padding: '13px 16px',
                  height: 'auto',
                  lineHeight: '$2',
                  color: '$neu600'
                }}
              >
                <div style={{ marginRight: '10px' }}>
                  <Icon icon='rocket' size='md' />
                </div>
                {t('scenes.runtime.labels.redeploy')}
              </Menu.Item>
              {isRunMovePipelineShow ? (
                <Menu.Item
                  size='large'
                  data-testid='run-deployed-pipelines-table-pipeline-action-promote'
                  onClick={handleClickWithoutBubbling(() =>
                    openDetailPromote({
                      text: promoteValue,
                      newEnvironment,
                      ...deployment
                    })
                  )}
                  css={{
                    fontSize: '$2',
                    padding: '13px 16px',
                    height: 'auto',
                    lineHeight: '$2',
                    color: '$neu600'
                  }}
                >
                  <div style={{ marginRight: '10px', fontSize: 16 }}>
                    <FontAwesomeIcon icon={faUpFromDottedLine} />
                  </div>
                  {i18n.t('action.promote_to_environment')}
                </Menu.Item>
              ) : (
                <></>
              )}
              <Menu.Item
                size='large'
                data-testid='run-deployed-pipelines-table-pipeline-action-rollback'
                onClick={handleClickWithoutBubbling(() =>
                  openModalRollbackDeploy(deployment)
                )}
                css={{
                  fontSize: '$2',
                  padding: '13px 16px',
                  height: 'auto',
                  lineHeight: '$2',
                  color: '$neu600'
                }}
              >
                <div style={{ marginRight: '10px', fontSize: 16 }}>
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                </div>
                Rollback
              </Menu.Item>
              <Menu.Item
                size='large'
                data-testid='run-deployed-pipelines-table-pipeline-action-delete'
                css={{
                  fontSize: '$2',
                  padding: '13px 16px',
                  height: 'auto',
                  lineHeight: '$2',
                  color: '$neu600'
                }}
                onClick={handleClickWithoutBubbling(() =>
                  confirmDialog.show({
                    title: i18n.t('scenes.runtime.actions.confirm'),
                    content: i18n.t('scenes.runtime.messages.prompt.undeploy'),
                    danger: true,
                    confirmText: i18n.t('scenes.runtime.actions.confirm'),
                    cancelText: i18n.t('scenes.runtime.actions.cancel'),
                    onConfirm: async () => {
                      try {
                        await remove({
                          environment,
                          realm: activeRealm,
                          deployment: deployment.id
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    }
                  })
                )}
              >
                <div style={{ marginRight: '10px' }}>
                  <Icon icon='trash-alt' size='md' />
                </div>
                {t('scenes.runtime.actions.delete_deploy')}
              </Menu.Item>
            </Menu.Content>
          </Menu.Root>
        </Role>
      </Table.Cell>
    </Table.Row>
  );
}
