import styled, { css } from 'styled-components';

import { Chip } from '../Chip';

import base from '~/common/styled/input/base';
import borderStyles from '~/common/styled/input/border';

export const TextField = styled.input`
  font-family: ${p => p.theme.typography.fontFamily};
  flex-grow: 1;
  border: none;
  outline: none;
  height: 46px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0px 0px 0px 7px;
`;

TextField.displayName = 'TextField';

export const TagWrapper = styled.div`
  padding-top: 4px;
`;

TagWrapper.displayName = 'TagWrapper';

export const Container = styled.div`
  ${base};
  ${borderStyles};
  min-height: 46px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;

  &:focus-within {
    span {
      color: ${props =>
        !props.danger ? props.theme.colors.pri500 : props.theme.colors.red500};
    }
  }
`;

Container.displayName = 'Container';

export const HelperText = styled.div`
  position: relative;
  color: ${props =>
    props.danger ? props.theme.colors.red500 : props.theme.colors.gra700};
  ${props =>
    props.actived &&
    !props.danger &&
    css`
      color: ${({ theme }) => theme.colors.pri500};
    `};
  font-size: 12px;
  letter-spacing: 0.0333333333em;
  font-weight: 400;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  padding-left: 12px;
  box-sizing: border-box;
`;

HelperText.displayName = 'HelperText';

export const Tag = styled(Chip)``;

Tag.displayName = 'Tag';

export const Label = styled.span`
  position: absolute;
  top: -7.5px;
  background: ${props => props.theme.colors.white};
  padding: 0px 7px;
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${p => p.theme.colors.gra700};
  font-size: 12px;
  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.red500};
    `};
`;

Label.displayName = 'Label';
