/* eslint-disable */

import Immutable from 'seamless-immutable';

import { values, omit, find } from 'lodash';
// Locals
import { relations } from '~/api';
import i18n from '~/common/helpers/i18n';

const initEditState = {
  name: '',
  description: '',
  type: '',
  fieldNameA: '',
  fieldNameB: ''
};

const types = [
  { label: 'Id translation', value: 'ID_TRANSLATION' },
  { label: 'Parent child', value: 'PARENT_CHILD' },
  {
    label: 'Id translation no overwrite',
    value: 'ID_TRANSLATION_NO_OVERWRITE'
  }
];

const initialState = Immutable({
  items: [],
  loading: false,
  loadingSave: false,
  errorFind: null,
  error: null,
  saveModalOpened: false,
  showModaDataOpened: false,
  editRelation: initEditState,
  instance: null,
  selectRelationInstance: {
    name: '',
    fields: [],
    fieldNameA: '',
    fieldNameB: ''
  },
  loadingInstance: false,
  errorInstace: null,
  activedPage: 1,
  types: types
});

const relationsModal = {
  name: 'relations',
  state: initialState,
  reducers: {
    loading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    errorFind(state, errorFind) {
      return state.merge({
        errorFind
      });
    },
    error(state, error) {
      return state.merge({
        error: error
      });
    },
    success(state, payload) {
      return state.merge({
        items: payload.relations
      });
    },
    saveModalOpen(state, payload) {
      return state.merge({
        saveModalOpened: payload
      });
    },
    selectEdit(state, payload) {
      return state.merge({
        editRelation: payload
      });
    },
    activedPage(state, payload) {
      return state.merge({
        activedPage: payload
      });
    },
    showModaDataOpened(state, showModaDataOpened) {
      return state.merge({ showModaDataOpened });
    },
    relationshipInstanceSuccess(state, instance) {
      return state.merge({ instance });
    },
    selectRelashiphip(state, selectRelationInstance) {
      return state.merge({ selectRelationInstance });
    },
    loadingInstance(state, loadingInstance) {
      return state.merge({ loadingInstance });
    },
    loadingSave(state, loadingSave) {
      return state.merge({ loadingSave });
    },
    relationshipInstanceError(state, error) {
      return state.merge({ errorInstace: error });
    }
  },
  effects: dispatch => ({
    async find({ realm, environment }) {
      try {
        const { data } = await relations.find(realm, environment);
        dispatch.relations.success(data);
      } catch (e) {
        dispatch.relations.errorFind(e);
      }
    },
    async remove({ item, realm, environment }) {
      try {
        await relations.remove(realm, item.name, environment);
        dispatch.relations.success(
          {},
          {
            snackbar: {
              text: i18n.t('scenes.relations.messages.success.delete_relationship', {
                relationshipName: item.name
              }),
              action: {
                label: i18n.t('action.ok'),
                callback: dispatch.snackbar.clearCurrent
              },
              success: true,
              open: true
            },
            find: true
          }
        );
      } catch (e) {
        dispatch.relations.error(e, {
          snackbar: {
            text: i18n.t(
              'scenes.relations.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t(
                  'scenes.relations.messages.error.delete_relationship'
                )}`
              }
            ),
            danger: true,
            open: true
          }
        });
      }
    },
    async create({ realm, newRelation, environment, actions }) {
      dispatch.relations.loadingSave(true);
      try {
        await relations.create(realm.realm, newRelation, environment);
        dispatch.relations.success(
          {},
          {
            snackbar: {
              text: i18n.t('scenes.relations.messages.success.create_relationship_model', {
                relationshipName: newRelation.name
              }),
              action: {
                label: i18n.t('action.ok'),
                callback: dispatch.snackbar.clearCurrent
              },
              success: true,
              open: true
            },
            find: true
          }
        );
        actions?.resetForm();
        dispatch.relations.loadingSave(false);
      } catch (e) {
        dispatch.relations.error(e, {
          snackbar: {
            text: i18n.t(
              'scenes.relations.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t(
                  'scenes.relations.messages.error.create_relationship_model'
                )}`
              }
            ),
            danger: true,
            open: true
          }
        });
        dispatch.relations.loadingSave(false);
      }
    },
    async put({ realm, newRelation, environment }) {
      dispatch.relations.loadingSave(true);
      try {
        await relations.put(realm.realm, newRelation, environment);
        dispatch.relations.success(
          {},
          {
            snackbar: {
              text: i18n.t('scenes.relations.messages.success.update_model_relationship', {
                relationshipName: newRelation.name
              }),
              action: {
                label: i18n.t('action.ok'),
                callback: dispatch.snackbar.clearCurrent
              },
              success: true,
              open: true
            },
            find: true
          }
        );
        dispatch.relations.loadingSave(false);
      } catch (e) {
        dispatch.relations.error(e, {
          snackbar: {
            text: i18n.t(
              'scenes.relations.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t(
                  'scenes.relations.messages.error.update_relationship_model'
                )}`
              }
            ),
            danger: true,
            open: true
          }
        });
        dispatch.relations.loadingSave(false);
      }
    },
    showModalOpen(open) {
      dispatch.relations.saveModalOpen(open);
    },
    selectEditRelation({ item }) {
      dispatch.relations.selectEdit({
        ...item,
        type: find(types, ({ value }) => item.type === value)
      });
    },
    showModaDataOpen(open) {
      dispatch.relations.showModaDataOpened(open);
    },
    selectedRelastionship(selectRelationInstance) {
      dispatch.relations.selectRelashiphip({
        fields: values(omit(selectRelationInstance, ['name'])),
        ...selectRelationInstance
      });
    },
    setActivedPage(page) {
      dispatch.relations.activedPage(page);
    },
    async findInstance({ realm, name, environment, searchQuery, page }) {
      try {
        const requestRelationshipInstance = await relations.findInstance(
          realm,
          name,
          environment,
          searchQuery,
          page
        );
        dispatch.relations.relationshipInstanceSuccess(
          requestRelationshipInstance.data.relationsInstance
        );
      } catch (e) {
        dispatch.relations.relationshipInstanceError(e);
      }
    }
  }),
  logics: [
    {
      type: ['relations/error', 'relations/success', 'relations/errorFind'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.relations.loading(false);
        done();
      }
    },
    {
      type: ['relations/find'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.relations.loading(true);
        done();
      }
    },
    {
      type: 'relations/success',
      latest: true,
      process({ getState, action }, dispatch, done) {
        dispatch.relations.showModalOpen(false);
        const { meta } = action;
        if (meta && meta.find) {
          dispatch.relations.find({
            realm: getState().application.realm.realm,
            environment: 'test'
          });
        }
        done();
      }
    },
    {
      type: 'relations/saveModalOpen',
      latest: true,
      process({ action }, dispatch, done) {
        if (!action.payload) {
          dispatch.relations.selectEditRelation({ item: initEditState });
        }
        done();
      }
    },
    {
      type: 'relations/findInstance',
      latest: true,
      process(context, dispatch, done) {
        dispatch.relations.loadingInstance(true);
        done();
      }
    },
    {
      type: [
        'relations/relationshipInstanceSuccess',
        'relations/relationshipInstanceError'
      ],
      latest: true,
      process(context, dispatch, done) {
        dispatch.relations.loadingInstance(false);
      }
    },
    {
      type: 'relations/showModaDataOpened',
      latest: true,
      process(context, dispatch, done) {
        dispatch.relations.relationshipInstanceSuccess(null);
      }
    }
  ]
};

export default relationsModal;
