import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withHooks from '~/common/hoc/withHooks';

import type { StoreDispatcher } from '../../types/StoreDispatcher';
import type { StoreRootState } from '../../types/StoreRootState';

export type Props = {
  open: StoreRootState['feedback']['modal']['sendFeedback'],
  loading: StoreRootState['feedback']['loading']['sendFeedback'],
  sendFeedback: StoreDispatcher['feedback']['sendFeedback'],
  setModal: StoreDispatcher['feedback']['setModal'],
  onCancel: () => void,
};

export type FeedbackProps = {
  open: boolean;
  loading: boolean;
  sendFeedback: (values: { message: string }) => void;
  setModal: (isOpen: boolean) => void;
  onCancel: () => void;
};

const enhancer = withHooks(() => {
  const dispatch = useDispatch<StoreDispatcher>();

  const open = useSelector((state: StoreRootState) => state.feedback.modal.sendFeedback);
  const loading = useSelector((state: StoreRootState) => state.feedback.loading.sendFeedback);

  const sendFeedback = (values: { message: string }) => {
    dispatch.feedback.sendFeedback({ message: values.message });
  };

  const setModal = (isOpen: boolean) => {
    dispatch.feedback.setModal({ path: 'sendFeedback', value: isOpen });
  };

  const onCancel = () => {
    dispatch.feedback.setModal({ path: 'sendFeedback', value: false });
  };

  useEffect(() => {
  }, [dispatch]);

  return { open, loading, sendFeedback, setModal, onCancel };
});

export default enhancer;
