/* eslint-disable react/jsx-no-undef */
import { Text } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as Elements from './TriggerDetails.Elements';

import Accordion from '~/components/Accordion';
import { CopyToClipboard } from '~/components/CopyToClipboard';
import TextNative from '~/components/Text';

const TriggerDetails = ({ source, title, triggers }) => {
  const [opened, setOpned] = useState(false);

  return (
    <Accordion
      title={<div style={{ paddingBottom: '3px' }}>{title}</div>}
      opened={opened}
      onToggle={() => setOpned(!opened)}
    >
      {iff(triggers.length, () => (
        <Elements.EndpointSeparator>
          {map(triggers, item => (
            <Elements.ContainerEndpoint>
              <Elements.EndPoint>
                <TextNative size='sm' weight='medium' align='left'>
                  {item.key}:
                </TextNative>
                <Elements.EndPointText size='sm' align='left'>
                  {item.value}
                </Elements.EndPointText>
              </Elements.EndPoint>
              <CopyToClipboard text={item.value}>
                <Elements.Icon name='Copy' />
              </CopyToClipboard>
            </Elements.ContainerEndpoint>
          ))}
        </Elements.EndpointSeparator>
      ))}
      <Elements.Container>
        {map(source, item => (
          <>
            <Elements.Row title={item.value}>
              <Text css={{ width: '98%' }}>{item.label}</Text>
              <Text css={{ width: '98%' }}>{item.value}</Text>
            </Elements.Row>
            <Elements.Separator />
          </>
        ))}
      </Elements.Container>
    </Accordion>
  );
};
TriggerDetails.defaultProps = {
  source: [],
  triggers: [],
  title: null
};

TriggerDetails.propTypes = {
  source: PropTypes.objectOf({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  triggers: PropTypes.arrayOf({}),
  title: PropTypes.string
};
export default TriggerDetails;
