import { Box, Flex, Tag } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { FC } from 'react';

import * as Elements from './SubHeader.elements';
import Action from '../Action';

import i18n from '~/common/helpers/i18n';
import Role from '~/components/Role';
import { SubHeader as Header, SubHeaderLink } from '~/components/SubHeader';

interface SubHeaderProps {
  containScopes: (roles: string[]) => boolean;
  realm: string;
  configActionButton: any[];
  configActionButtonRoles: string[];
  environments: string[];
  hiddenPages: string[];
  environment: string;
  changeEnvironment: () => void;
  links?: any[];
  showEnvironmentSelect?: boolean;
}

const SubHeader: FC<SubHeaderProps> = ({
  environment,
  environments,
  realm,
  configActionButton,
  configActionButtonRoles,
  changeEnvironment,
  hiddenPages,
  links = [],
  showEnvironmentSelect = false
}) => {
  const Link = ({ routeName, ...props }: any) => {
    if (routeName && hiddenPages.includes(routeName)) return null;
    return <SubHeaderLink style={{ marginRight: 0 }} {...props} />;
  };

  const renderLinks = () =>
    links
      ?.filter(({ isHidden }) => !isHidden)
      .map(({ roles, path, name, isBeta, ...linkProps }) => {
        const link = (
          <Flex align='center'>
            <Link to={`/${realm}/${path}`} {...linkProps}>
              {({ isActive }: { isActive: boolean }) => (
                <Elements.Text weight='regular' active={isActive} as='span'>
                  {name}
                </Elements.Text>
              )}
            </Link>
            {iff(isBeta, () => (
              <Elements.WrapperTag>
                <Tag
                  variant='success'
                  // @ts-ignore
                  css={{ margin: '4px', color: '$primary' }}
                >
                  {i18n.t('label.beta')}
                </Tag>
              </Elements.WrapperTag>
            ))}
          </Flex>
        );

        if (roles && roles.length > 0) {
          return <Role name={roles}>{link}</Role>;
        }
        return link;
      });

  return (
    <Box css={{ position: 'relative', borderTop: '1px solid $pri500' }}>
      {iff(links?.length > 0, () => (
        // @ts-ignore
        <Header isNotBorder>
          <Flex align='center' gap={4}>
            {renderLinks()}
          </Flex>
        </Header>
      ))}
      {showEnvironmentSelect ? (
        <Elements.WrapperEnvironment>
          {environments.length > 0 && (
            // @ts-ignore
            <Elements.SmallSelect
              data-testid='operation-header-monitor-enviroment_list'
              options={environments?.map(env => ({
                label: env,
                value: env
              }))}
              value={environment}
              // @ts-ignore
              onChange={value => changeEnvironment(value)}
              align='left'
            />
          )}
        </Elements.WrapperEnvironment>
      ) : null}
      <Elements.Actions data-testid='build-create-button'>
        <Role name={configActionButtonRoles}>
          <Action configActionButton={configActionButton} />
        </Role>
      </Elements.Actions>
    </Box>
  );
};

export default SubHeader;
