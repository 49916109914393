import { useState, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';

import { useTranslation } from 'react-i18next';
import type { ProposalUpsell } from '~/api/license';

export type Props = {
  proposalUpsell: ProposalUpsell,
  loading: boolean,
  dictionary: (key: string) => string,
  success: boolean,
  setSuccess: Dispatch<boolean>,
}

const enhancer = withHooks(({environment}: {environment: string}) => {
    const { license } = useSelector((state: any) => state);
    const dispatch: any = useDispatch();
    const [success, setSuccess] = useState<boolean>(false);

    const [t] = useTranslation();

    const dictionary = (key: string) => {
      const data: { [keyof: string]: string } = {
        MPS: "label.message_per_seconds",
        EGRESS_TRAFFIC: "label.egress_traffic",
        VPN: "label.virtual_private_network_vpn",
        RELATIONSHIP_MANAGEMENT: "label.relationship_management",
        OBJECT_STORE: "label.object_store_component",
        DIGIBEE_STORE: "label.digibee_storage_component",
        QUEUE: "label.queue",
        PIPELINE_LOG: "label.pipeline_logs",
        COMPLETED_EXECUTION_LOG: "label.completed_exec_logs",
        Gigabytes: "label.gigabytes",
        Units: "label.unit",
        Megabytes: "label.megabytes",
      };

      return data[key] ? t(data[key]) : key;
    };

    useEffect(() => {
      dispatch.license.getProposalUpsell({
        environment: environment
      });

      return () => {
        dispatch.license.setProposalUpsell(null);
      }
    }, [environment]);

    const state: Props = {
      dictionary,
      setSuccess,
      success,
      loading: license.loadingProposalUpsell,
      proposalUpsell: license.proposalUpsell
    };

    return state;
  });

export default enhancer;
