import styled, { css } from 'styled-components';

const Nav = styled.nav``;

Nav.Container = styled.div``;

Nav.Item = styled.div`
  width: 100%;
  display: flex;
  height: 32px;
  margin: 8px 0px;
  cursor: pointer;
  ${props =>
    props.actived &&
    css`
      background: ${props.theme.colors.pri25};
    `};

  ${props =>
    props.isDraggingOver &&
    css`
      border: 1px solid ${props => props.theme.colors.yel900};
      background: ${props => props.theme.colors.yel200};
      box-sizing: content-box;
    `}
`;

export default Nav;
