import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const WrapperAlign = styled.span`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  ${({ align }) =>
    align === 'right' &&
    css`
      align-items: flex-end;
    `};

  ${({ align }) =>
    align === 'center' &&
    css`
      align-items: center;
    `};

  ${({ align }) =>
    align === 'left' &&
    css`
      align-items: flex-start;
    `};

  ${({ placement }) =>
    placement === 'start' &&
    css`
      flex-direction: row-reverse;
      align-items: center;
    `};

  ${({ placement }) =>
    placement === 'top' &&
    css`
      flex-direction: column-reverse;
    `};

  ${({ placement }) =>
    placement === 'end' &&
    css`
      align-items: center;
    `};

  ${({ placement }) =>
    placement === 'bottom' &&
    css`
      flex-direction: column;
    `};
`;
const LabelContent = styled.label`
  cursor: pointer;
  margin-right: 16px;
  margin-left: 0px;

  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

const LabelText = styled.span`
  line-height: 16px;

  ${({ placement }) =>
    placement === 'start' &&
    css`
      margin-right: 16px;
    `};

  ${({ placement }) =>
    placement === 'end' &&
    css`
      margin-left: 7px;
    `};

  ${({
    align,
    theme: {
      fontWeight,
      typographyCurrent: { subHeadings },
      colors
    }
  }) =>
    css`
                        text-align: ${align}
                        font-size: ${subHeadings.md.fontSize};
                        color: ${colors.gra700};
                        font-weight: ${fontWeight.regular};
                        letter-spacing: ${subHeadings.lg.letterSpacing};
        `};
`;

const LabelSubText = styled.span`
  display: block;
  font-size: 12px;
  margin-top: 6px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.gra700};
  ${({ theme: { fontWeight } }) =>
    css`
      font-weight: ${fontWeight.thin};
    `};
`;

const Label = React.forwardRef((props, ref) => {
  const {
    text,
    subText,
    align,
    className,
    placement,
    children,
    render,
    ...restProps
  } = props;

  return (
    <LabelContent className={className} ref={ref} {...restProps}>
      {render()}
      <WrapperAlign placement={placement} align={align}>
        {children}
        {iff(text || subText, () => (
          <LabelText align={align} placement={placement}>
            {text}
            {subText && <LabelSubText>{subText}</LabelSubText>}
          </LabelText>
        ))}
      </WrapperAlign>
    </LabelContent>
  );
});

Label.defaultProps = {
  align: 'left',
  placement: 'end',
  render: () => {},
  text: '',
  subText: null,
  className: null,
  children: null
};

Label.propTypes = {
  text: PropTypes.node,
  subText: PropTypes.node,
  placement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  align: PropTypes.oneOf(['center', 'left', 'right']),
  className: PropTypes.string,
  children: PropTypes.node,
  render: PropTypes.func
};

export default Label;
