import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import withHooks from '~/common/hoc/withHooks';

type Store = {
  authentication: {
    error?: {
      email?: string;
      lockAuth?: boolean;
    };
  };
};

const enhancer = withHooks(() => {
  const navigate = useNavigate();
  const { error } = useSelector(({ authentication }: Store) => authentication);

  function backToLogin() {
    navigate('/login');
  }

  useEffect(() => {
    if (!error || !error.lockAuth || !error.email) backToLogin();
  }, [error]);
});

export default enhancer;
