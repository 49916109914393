/* eslint-disable */
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withHooks from '~/common/hoc/withHooks';
import { useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';

const mapStateToProps = ({ snackbar }) => ({
  config: snackbar.current
});

const mapDispatchToProps = ({ snackbar }) => ({
  create: snackbar.create,
  popSnackbar: snackbar.popSnackbar
});

const TIME_DISTANCE_BETWEEN_CALLS = 100;
const DEFAULT_TIMEOUT = 4000;

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHooks(props => {
    const [visible, setVisible] = useState(false);

    const closeSnackbar = () => {
      setVisible(false);
      props.popSnackbar();
    };

    const [isReady, cancel, reset] = useTimeoutFn(() => {
      if (props.config) {
        closeSnackbar();
      }
    }, props.config?.timeout || DEFAULT_TIMEOUT);

    useEffect(() => {
      if (props.config) {
        setTimeout(() => {
          setVisible(true);
          reset();
        }, TIME_DISTANCE_BETWEEN_CALLS);
      }
    }, [props.config]);

    const onActionClick = ev => {
      closeSnackbar();
      cancel();
      props.config?.action?.callback?.(ev);
    };

    return {
      visible,
      text: props.config?.text,
      type: props.config?.type,
      actionLabel: props.config?.action?.label,
      callback: props.config?.action?.callback,
      offsetX: props.config?.offsetX,
      offsetY: props.config?.offsetY,
      freeze: cancel,
      reset,
      onActionClick,
      ...props
    };
  })
);

export default enhancer;
