import { ActorRefFrom, createMachine } from 'xstate';
import { assign } from 'xstate/lib/actions';

export type GlobalSidePanelContext = {
  panelsNames: string[];
  currentPanel?: string;
};

export type GlobalSidePanelEvents =
  | { type: 'OPEN'; panelName?: string }
  | { type: 'CLOSE' }
  | { type: 'TOGGLE'; panelName?: string };

export type GlobalSidePanelServices = {
  fetchSuggestions: {
    data: {
      suggestions: string[];
    };
  };
};

type globalSidePanelMachineParams = {
  panelsNames: string[];
};

const globalSidePanelMachine = ({
  panelsNames
}: globalSidePanelMachineParams) =>
  createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5RQDYHsBGBDFBlAlhGAApYB2YKAdAMbqyQDEA8sQKIByA2gAwC6iUAAc0sfABd8aMoJAAPRAGYAjAFYqqgJyKA7IoAc+5QDZjOwzoA0IAJ6IATIp5UdmzQBYdPAzzWr9OgC+gdaomDgERKQU1HSiTAAqzADiyQAybLwCSCAiYpLSsgoI9prGGsr69mo67oruysqK1nYIRlSK9qVN7pr6qsp1ysGh6Nh4hCTklFRoQmBkLOzc-LJ5ElIyOcXKPJpUZV0NyvaqXl7KLYgnOlTGKqo8qsb9Hk3GIyBh45FTMbPzRYAYTSzFwmVWOXWBS2oGKpXKAyqNTqx2atmuKiog30dXs7i0PGMak+3wik2iMzmC0YSVSGSya1EG0K2wcZQqyLOqMa6NalWcmj0mgGDTKPH0L2CIRAZDQRHgOTJEyi0xQTPymyKiAAtJcMQgdeUeCbTWazUEZcrfpTYvRIBqWbD5Ih3KVsQFPI4JWZiaorghlDp1K4EfYeKVDMYRaSxuTVf9qc7oVq2QhPAHKvYqASvPjjO4C4ZVKppYEgA */
      id: 'globalSidePanel',
      tsTypes: {} as import('./GlobalSidePanel.machine.typegen').Typegen0,
      schema: {
        context: {} as GlobalSidePanelContext,
        events: {} as GlobalSidePanelEvents,
        services: {} as GlobalSidePanelServices
      },
      context: {
        panelsNames,
        currentPanel: panelsNames[0]
      },
      initial: 'closed',
      states: {
        closed: {
          on: {
            OPEN: {
              target: 'open',
              cond: 'isPanelNameValid'
            },
            TOGGLE: {
              target: 'open',
              cond: 'isPanelNameValid'
            }
          }
        },
        open: {
          entry: 'setCurrentPanel',
          on: {
            OPEN: {
              target: 'open',
              cond: 'isPanelNameValid'
            },
            CLOSE: 'closed',
            TOGGLE: 'closed'
          }
        }
      }
    },
    {
      guards: {
        isPanelNameValid: (context, { panelName }) =>
          panelName ? context.panelsNames.includes(panelName) : false
      },
      actions: {
        setCurrentPanel: assign({
          currentPanel: ({ currentPanel }, event) => {
            if ('panelName' in event) {
              return event.panelName || currentPanel;
            }
            return currentPanel;
          }
        })
      }
    }
  );

export type ComponentSuggestionActor = ActorRefFrom<
  typeof globalSidePanelMachine
>;

export default globalSidePanelMachine;
