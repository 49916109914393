import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const ContainerStatus = styled.div`
  padding-top: 10px;
`;

export const ContainerAvatar = styled.div`
  padding-right: 2px;
  padding-left: 6px;
`;

export const RowAvatar = styled.div`
  display: flex;
`;

type ArrowIconProps = {
  icon: IconProp;
};
export const Footer = styled.div`
  margin: 34px 0px;
`;

export const ArrowIcon = styled((props: ArrowIconProps) => (
  <FontAwesomeIcon {...props} />
))`
  font-size: 1rem;
  cursor: pointer;
  color: ${props => props.theme.colors.pri300};
`;
