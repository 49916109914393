import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SubHeader = styled.div<{ isNotBorder?: boolean }>`
  ${props =>
    !props.isNotBorder
      ? css`
          border-top: 1px solid ${props.theme.colors.pri500};
        `
      : ''}
  transition: box-shadow 0.3s ease 0s;
  border-bottom: 1px solid ${p => p.theme.colors.gra300};
  background: ${p => p.theme.colors.pri25};
  padding: 0 24px;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
`;

// @ts-ignore
export const SubHeaderLink = styled(props => <NavLink {...props} />)`
  padding: 0;
  margin-right: 24px;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
  span {
    opacity: 0.5;
  }

  &.active * {
    opacity: 1 !important;
    color: ${props => props.theme.colors.pri500};
  }

  ${props =>
    props.disabled
      ? css`
          pointer-events: none;
        `
      : ''}
`;
