import { Tag, Box, TagProps } from '@digibee/beehive-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestion,
  faWarning,
  faTrash,
  faCircleCheck
} from '@fortawesome/pro-light-svg-icons';

import * as Elements from './OldStatusDeploy.elements';
import { type Deployment } from '../../scenes/Run/components/CardRuntime';
import { useIsAutoscaling } from '../../scenes/Run/hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../scenes/Run/utils/isAutoScalingDeploy';

import i18n from '~/common/helpers/i18n';

type StatusDeployProps = {
  deployment: Deployment;
  disabled?: boolean;
  isMarginBottom?: boolean;
  isMinHeight?: boolean;
  isPipelineEngine?: boolean;
};

export type DataMessageType = {
  loader?: boolean;
  text: string;
  icon: IconProp;
  style: string;
};

export const getMessageStatus = (deployment: Deployment): DataMessageType => {
  const status: { [key: string]: DataMessageType } = {
    deleting: {
      text: i18n.t('label.deleting'),
      icon: faTrash,
      style: 'warning'
    },
    starting: {
      text: i18n.t('label.starting'),
      icon: faQuestion,
      loader: true,
      style: 'neutral'
    },
    degraded: {
      text: i18n.t('label.starting'),
      icon: faQuestion,
      style: 'info'
    },
    error: {
      text: i18n.t('label.error'),
      style: 'danger',
      icon: faWarning
    },
    deployed: {
      text: i18n.t('label.deployed'),
      style: 'success',
      icon: faCircleCheck
    },
    rollbacked: {
      text: i18n.t('label.rolled_back'),
      icon: faQuestion,
      style: 'info'
    },
    restored: {
      text: i18n.t('label.restored_colon'),
      icon: faCircleCheck,
      style: 'success'
    }
  };

  const statusSelected: string = deployment?.status?.text?.toLowerCase();
  if (status[statusSelected]) {
    return status[statusSelected];
  }
  return status.deployed;
};

export const StatusDeploy = ({
  deployment,
  isMarginBottom = true,
  isMinHeight = true
}: StatusDeployProps) => {
  const dataMessage: DataMessageType = getMessageStatus(deployment);
  const showNewAutoScalingExperience = useIsAutoscaling();
  const showAutoScalingValues =
    isAutoScalingDeploy({
      minReplicaCount: deployment.activeConfiguration?.minReplicaCount,
      replicaCount: deployment.activeConfiguration?.replicaCount
    }) && showNewAutoScalingExperience;

  return (
    <Elements.ContainerStatus
      isMarginBottom={isMarginBottom}
      isMinHeight={isMinHeight}
    >
      <Tag variant={dataMessage.style as TagProps['variant']}>
        {dataMessage.text}
      </Tag>
      {showAutoScalingValues && (
        <Box css={{ marginLeft: '$3' }}>
          <Tag variant='info'>Autoscaling</Tag>
        </Box>
      )}
    </Elements.ContainerStatus>
  );
};
