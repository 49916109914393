import styled from 'styled-components';

import NativeButton from '~/components/Button';
import NativeText from '~/components/Text';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerFooter = styled(Container)`
  position: absolute;
  bottom: 32px;
`;

export const Text = styled(NativeText)`
  text-align: left;
  color: ${({ theme }) => theme.colors.gra500};
  line-height: 1.4;
`;

export const Caption = styled(Text)`
  text-align: left;
  font-size: 12px;
`;

export const Link = styled.a``;

// @ts-ignore
export const Button = styled(NativeButton)`
  margin-top: 48px;
  margin-bottom: 8px;
`;
