/* eslint-disable */

import { keys, filter, isEmpty, forEach } from 'lodash';

const getPropertiesInObject = (object, prefix) => {
  const objectNew = {};
  if (isEmpty(object)) {
    throw new Error('[GetPropertiesInObject] object is required');
  }
  if (!prefix) {
    throw new Error('[GetPropertiesInObject] prefix is required');
  }

  const stringKeys = keys(object);
  const list = filter(stringKeys, key => key.indexOf(prefix) >= 0);
  forEach(list, item => {
    objectNew[item] = object[item];
  });
  return objectNew;
};

export default getPropertiesInObject;
