/* eslint-disable import/no-cycle */
import Immutable from 'seamless-immutable';

import { profile } from '~/api';
// eslint-disable-next-line import/order
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const initialState = Immutable({
  success: {
    item: null
  },
  user: null,
  isModalNewPassword: false,
  isModalTwoFactor: false,
  isModalProfile: false,
  avatarTimestamp: +new Date(),
  faild: {
    item: null,
    create: null,
    newPassword: null
  },
  loading: {
    item: false,
    create: false,
    remove: false,
    newPassword: false,
    edit: false,
    saveImageProfile: false
  }
});

const profileModel = {
  name: 'profile',
  state: initialState,
  reducers: {
    success(state, { path, value }) {
      return state.merge(
        {
          success: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    loading: (state, { path, value }) =>
      state.merge(
        {
          loading: {
            [path]: value
          }
        },
        { deep: true }
      ),
    faild: (state, { path, value }) =>
      state.merge({
        faild: {
          [path]: value
        }
      }),
    reset: state => state.merge(initialState),
    setUser: (state, value) => state.setIn(['user'], value),
    setModalNewPassword: (state, value) =>
      state.setIn(['isModalNewPassword'], value),
    setModalTwoFactor: (state, value) =>
      state.setIn(['isModalTwoFactor'], value),
    setModalProfile: (state, value) => state.setIn(['isModalProfile'], value),
    setAvatarTimestamp: (state, value) =>
      state.setIn(['avatarTimestamp'], value)
  },
  effects: dispatch => ({
    async get(context, state) {
      try {
        const { data } = await profile.get({
          realm: state.application.realm.realm
        });
        dispatch.profile.success({
          path: 'item',
          value: data.profile
        });
      } catch (e) {
        dispatch.profile.faild({
          path: 'item',
          value: e
        });
      }
    },
    async getUser(context, state) {
      try {
        const { data } = await profile.getUser({
          realm: state.application.realm.realm
        });
        dispatch.profile.setUser(data?.getProfile);
      } catch (e) {
        dispatch.profile.setUser(null);
      }
    },
    async saveImageProfile({ id, file }, state) {
      try {
        dispatch.profile.loading({ path: 'saveImageProfile', value: true });

        if (file.size >= 20971520)
          throw new Error(i18n.t('label.file_too_large_img_msg_alert'));

        const fileType = file.type;
        if (
          fileType !== 'image/png' &&
          fileType !== 'image/jpeg' &&
          fileType !== 'image/jpg'
        )
          throw new Error(
            i18n.t('label.file_extension_not_supported_img_msg_alert')
          );

        const imageUrl = await profile.saveProfileImage({
          realm: state.application.realm.realm,
          token: state?.authentication?.userData?.token,
          id,
          file
        });
        dispatch.profile.edit({
          values: {
            imageUrl: `users/avatars/${imageUrl}`
          }
        });
        dispatch.profile.setAvatarTimestamp(+new Date());
      } catch (e) {
        dispatch.profile.faild(
          {
            path: 'saveImageProfile',
            value: e
          },
          {
            snackbar: {
              text: e?.message,
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      } finally {
        dispatch.profile.loading({ path: 'saveImageProfile', value: false });
      }
    },
    async edit({ values }, state) {
      try {
        const resquest = await profile.edit({
          realm: state.application.realm.realm,
          profile: values
        });
        dispatch.profile.success(
          {
            path: 'edit',
            value: resquest.data.editProfile
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.profile.messages.success.edit_details_success'
              ),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
        dispatch.profile.setModalProfile(false);
        dispatch.profile.setUser(resquest.data.editProfile);
      } catch (error) {
        dispatch.profile.faild(
          {
            path: 'edit',
            value: error
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(error?.message),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    },
    async save(params, state) {
      try {
        const resquest = await profile.save({
          ...params,
          realm: state.application.realm.realm
        });
        dispatch.profile.success(
          {
            path: 'create',
            value: resquest.data
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.two_factor.messages.success.activate_two_factor_success'
              ),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
        params?.actions?.resetForm();
      } catch (error) {
        dispatch.profile.faild(
          {
            path: 'create',
            value: error
          },
          {
            snackbar: {
              text: i18n.t('scenes.two_factor.messages.error.incorrect_code'),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    },
    async remove(params, state) {
      try {
        const resquest = await profile.remove({
          ...params,
          realm: state.application.realm.realm
        });
        dispatch.profile.success(
          {
            path: 'remove',
            value: resquest.data
          },
          {
            snackbar: {
              text: i18n.t('noun.two_step_auth_delete'),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
        params?.actions?.resetForm();
      } catch (error) {
        dispatch.profile.faild(
          {
            path: 'remove',
            value: error
          },
          {
            snackbar: {
              text: i18n.t('scenes.profile.messages.error.incorrect_code'),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    },
    async newPassword(params, state) {
      try {
        const resquest = await profile.newPassword({
          newPassword: params?.values?.newPassword,
          currentPassword: params?.values?.currentPassword,
          realm: state?.application?.realm?.realm,
          email: state?.authentication?.userData?.email
        });
        dispatch.profile.success(
          {
            path: 'newPassword',
            value: resquest.data
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.new_password.messages.success.change_password_successfully'
              ),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
        params?.actions?.resetForm?.();
        dispatch.profile.setModalNewPassword(false);
      } catch (error) {
        const { graphQLErrors } = error;
        const errorMessages = {
          PASSWORD_ALREADY_USED:
            'label.cannot_use_same_password_try_again_msg_alert'
        };
        dispatch.profile.faild(
          {
            path: 'newPassword',
            value: error
          },
          {
            snackbar: {
              text: i18n.t(
                errorMessages[graphQLErrors[0]?.extensions?.code] ||
                'label.issues_updating_password_try_again_msg_alert'
              ),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: 'profile/get',
      latest: true,
      process(context, dispatch, done) {
        dispatch.profile.loading({
          path: 'item',
          value: true
        });
        done();
      }
    },
    {
      type: 'profile/success',
      latest: true,
      process({ action }, dispatch, done) {
        const { path } = action.payload;
        if (['create', 'remove'].includes(path)) {
          dispatch.profile.get();
        }
        done();
      }
    },
    {
      type: ['profile/success', 'profile/faild'],
      latest: true,
      process({ action }, dispatch, done) {
        const { path } = action.payload;
        if (path) {
          dispatch.profile.loading({
            path,
            value: false
          });
        }
        done();
      }
    },
    {
      type: ['profile/save'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.profile.loading({
          path: 'create',
          value: true
        });
        done();
      }
    },
    {
      type: ['profile/edit'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.profile.loading({
          path: 'edit',
          value: true
        });
        done();
      }
    },
    {
      type: ['profile/newPassword'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.profile.loading({
          path: 'newPassword',
          value: true
        });
        done();
      }
    },
    {
      type: ['profile/remove'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.profile.loading({
          path: 'remove',
          value: true
        });
        done();
      }
    }
  ]
};

export default profileModel;
// 'profile/save'
