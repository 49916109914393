import { theme } from '@digibee/beehive-ui';
import styled from 'styled-components';

export const EulaWrapper = theme.styled('div', {
  // BR
  'ol.lst-kix_list_1-3': {
    listStyleType: 'none'
  },
  'ol.lst-kix_list_1-4': {
    listStyleType: 'none'
  },
  'ol.lst-kix_list_1-5': {
    listStyleType: 'none'
  },
  'ol.lst-kix_list_1-6': {
    listStyleType: 'none'
  },
  'ol.lst-kix_list_1-0': {
    listStyleType: 'none'
  },
  '.lst-kix_list_1-4 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-4'
  },
  'ol.lst-kix_list_1-1': {
    listStyleType: 'none'
  },
  'ol.lst-kix_list_1-2': {
    listStyleType: 'none'
  },
  'ol.lst-kix_list_1-6.start': {
    counterReset: 'lst-ctn-kix_list_1-6 0'
  },
  '.lst-kix_list_1-1 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-1'
  },
  'ol.lst-kix_list_1-3.start': {
    counterReset: 'lst-ctn-kix_list_1-3 0'
  },
  'ol.lst-kix_list_1-2.start': {
    counterReset: 'lst-ctn-kix_list_1-2 0'
  },
  'ol.lst-kix_list_1-8.start': {
    counterReset: 'lst-ctn-kix_list_1-8 0'
  },
  '.lst-kix_list_1-0 > li:before': {
    content: '"" counter(lst-ctn-kix_list_1-0, decimal) ". "'
  },
  'ol.lst-kix_list_1-5.start': {
    counterReset: 'lst-ctn-kix_list_1-5 0'
  },
  'ol.lst-kix_list_1-7': {
    listStyleType: 'none'
  },
  '.lst-kix_list_1-1 > li:before': {
    content:
      '"" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) ". "'
  },
  '.lst-kix_list_1-2 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) ". "`
  },
  '.lst-kix_list_1-7 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-7'
  },
  'ol.lst-kix_list_1-8': {
    listStyleType: 'none'
  },
  '.lst-kix_list_1-3 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) ". "`
  },
  '.lst-kix_list_1-4 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) ". "`
  },
  'ol.lst-kix_list_1-0.start': {
    counterReset: 'lst-ctn-kix_list_1-0 0'
  },
  '.lst-kix_list_1-0 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-0'
  },
  'li.li-bullet-1:before': {
    marginLeft: '-3.8pt',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    minWidth: '3.8pt'
  },
  '.lst-kix_list_1-6 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-6'
  },
  '.lst-kix_list_1-7 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) "."
    counter(lst-ctn-kix_list_1-7, decimal) ". "`
  },
  '.lst-kix_list_1-3 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-3'
  },
  '.lst-kix_list_1-5 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) ". "`
  },
  '.lst-kix_list_1-6 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) ". "`
  },
  'li.li-bullet-0:before': {
    marginLeft: '-10.9pt',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    minWidth: '10.9pt'
  },
  'li.li-bullet-2:before': {
    marginLeft: '-3.8pt',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    minWidth: '3.8pt'
  },
  'ol.lst-kix_list_1-7.start': {
    counterReset: 'lst-ctn-kix_list_1-7 0'
  },
  '.lst-kix_list_1-2 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-2'
  },
  '.lst-kix_list_1-5 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-5'
  },
  '.lst-kix_list_1-8 > li': {
    counterIncrement: 'lst-ctn-kix_list_1-8'
  },
  'ol.lst-kix_list_1-4.start': {
    counterReset: 'lst-ctn-kix_list_1-4 0'
  },
  '.lst-kix_list_1-8 > li:before': {
    content: `"" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) "."
    counter(lst-ctn-kix_list_1-7, decimal) "."
    counter(lst-ctn-kix_list_1-8, decimal) ". "`
  },
  'ol.lst-kix_list_1-1.start': {
    counterReset: 'lst-ctn-kix_list_1-1 0'
  },
  ol: {
    margin: 0,
    padding: 0
  },
  'table td, table th': {
    padding: 0
  },
  'ol.olterm': {
    margin: '0.2rem 2rem !important'
  },
  'p.bodyterm': {
    margin: '0 2rem 0 0 !important',
    lineHeight: '1.5rem !important',
    textAlign: 'justify !important',
    fontSize: '14px !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  'p.smallterm': {
    fontSize: '12px !important',
    marginBottom: '10px !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  '.c3 li.literm': {
    marginTop: '4px',
    fontSize: '14px !important',
    lineHeight: '1.2rem !important',
    textAlign: 'justify !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  'h1.h1term': {
    fontSize: '16px !important',
    marginTop: '1.1rem',
    marginBottom: '0.8rem',
    textAlign: 'center !important',
    fontWeight: 'bold !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  'h3.h3term': {
    marginTop: '2rem !important',
    marginBottom: '0.8rem',
    fontSize: '14px !important',
    fontWeight: 'bold !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  // US
  '.titlebig': {
    fontSize: '16px !important',
    marginTop: '1.1rem !important',
    marginBottom: '0.8rem !important',
    textAlign: 'center !important',
    fontWeight: 'bold !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  '.center': {
    textAlign: 'center'
  },
  '.title': {
    fontSize: '14px !important',
    margin: '10px 0 !important',
    fontWeight: 'bold !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  '.parag': {
    lineHeight: '20px !important',
    textAlign: 'justify !important',
    fontSize: '14px !important',
    marginBottom: '9px'
  },
  '.ol': {
    marginLeft: '16px !important',
    marginBottom: '2rem !important'
  },
  '.ml': {
    marginLeft: '16px !important'
  },
  '.body': {
    padding: '0 16px 0 0 !important',
    color: '#666666 !important',
    fontFamily: '"Montserrat", sans-serif !important'
  },
  '.small': {
    marginBottom: '10px !important',
    fontSize: '13px !important'
  }
});

export const Divider = styled.div`
  border-bottom: 1px solid ${props => props?.theme?.colors?.gra100};
  width: 95%;
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
`;
