import { connect } from 'react-redux';
import { compose } from 'recompose';

const mapStateToProps = ({ forgotPassword, application }) => ({
  isSendEmail: forgotPassword.isSendEmail,
  isInvalidLink: forgotPassword.isInvalidLink,
  loading: forgotPassword.loading,
  error: forgotPassword.error.resetPassword,
  resetPassword: forgotPassword.success.resetPassword,
  subdomain: application.subdomain
});

const mapDispatchToProps = ({ forgotPassword }) => ({
  send: forgotPassword.send,
  returnToSignIn: forgotPassword.returnToSignIn,
  returnToForgotPassword: forgotPassword.returnToForgotPassword,
  onResetPassword: forgotPassword.onResetPassword
});

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhancer;
