import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RadioGroupContext from './RadioGroupContext';
import useId from './useid';

import useDynamicState from '~/common/hooks/useDynamicState';

const FormGroup = styled.span`
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  flex-wrap: wrap;
`;

const Container = styled.span`
  display: flex;
`;

const RadioGroup = React.forwardRef(props => {
  const {
    actions,
    children,
    name: nameProp,
    onChange,
    value: valueProp,
    defaultValue,
    ...other
  } = props;

  const initialValue = valueProp || defaultValue || null;
  const [value, setValueState] = useDynamicState(initialValue);

  const handleChange = event => {
    setValueState(event.target.value);

    if (onChange) {
      onChange(event, event.target.value);
    }
  };
  const name = useId(nameProp);
  return (
    <RadioGroupContext.Provider value={{ name, onChange: handleChange, value }}>
      <Container>
        <FormGroup
          /* eslint-disable react/jsx-props-no-spreading */
          {...other}
        >
          {children}
        </FormGroup>
      </Container>
    </RadioGroupContext.Provider>
  );
});

RadioGroup.defaultProps = {
  /**
   * The content of the component.
   */
  children: null,
  defaultValue: null,
  name: '',
  onChange: () => {},
  value: '',
  actions: () => {}
};

RadioGroup.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /* eslint-disable react/forbid-prop-types */
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  value: PropTypes.any,
  actions: PropTypes.func
};

export default RadioGroup;
