import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const retention = {
  find: (realm, environment) =>
    clientApollo.query({
      query: gql`
        query retention($realm: String!, $environment: String) {
          retention(realm: $realm, environment: $environment) {
            scope
            type
          }
        }
      `,
      variables: {
        realm,
        environment
      }
    })
};

export default retention;
