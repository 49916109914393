import { theme, Button } from '@digibee/beehive-ui';

export const StyledContainer = theme.styled('div');

export const StyledButton = theme.styled(Button, {
  width: '100%',
  justifyContent: 'center',
});

export const StyledFields = theme.styled('div', {
  padding: '10px 0px',
});

export const StyledWelcome = theme.styled('div', {
  fontSize: '$1',
  lineHeight: '1.5',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.38)',
  paddingTop: '10px',
  paddingBottom: '10px',
  fontFamily: '$display',
});

export const StyledMessage = theme.styled('h2', {
  textAlign: 'left',
  lineHeight: '1.4',
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
  fontFamily: '$display',
});

export const ButtonWrapper = theme.styled('div', {
  width: '100%',
  display: 'flex',
  padding: '10px 0px',
  justifyContent: 'center',
});