import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router';

import withHooks from '~/common/hoc/withHooks';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import featureFlags from '~/common/helpers/featureFlagConstants';

const { RECAPTCHA_TEMPORARY_LOCKING } = featureFlags;

type P = {
  verificationCode: string;
};

type T = {
  authentication: {
    validateVerificationCode: (props: P) => void;
    resetError: () => void;
    sendVerificationCode: () => void;
  };
};

const enhancer = withHooks(() => {
  const [codeExpirationInSeconds, setCodeExpirationInSeconds] = useState(0);
  const [expirationTime, setExpirationTime] = useState<number>(0);
  const dispatch = useDispatch<T>();
  const navigate = useNavigate();
  const { treatments } = useFeatureFlag([RECAPTCHA_TEMPORARY_LOCKING]);

  const temporaryLockingFeatureFlagEnabled =
    treatments[RECAPTCHA_TEMPORARY_LOCKING].treatment === 'on';

  const authenticationStore = useStore().getState().authentication;

  const error: {
    lockAuth?: boolean;
    verificationCodeError?: boolean;
    attemptsRemaining?: number;
  } | null = authenticationStore.error;
  const loading: boolean = authenticationStore.loading;

  function startExpirationCountdown() {
    const now = new Date().getTime();
    const difference = (expirationTime - now) / 1000;
    if (difference < 0) {
      setCodeExpirationInSeconds(0);
    } else {
      setCodeExpirationInSeconds(Math.floor(difference));
      setTimeout(() => startExpirationCountdown(), 1000);
    }
  }

  function generateCodeExpiration() {
    const expiration = new Date();
    expiration.setSeconds(expiration.getSeconds() + 60);
    setExpirationTime(expiration.getTime());
  }

  function validateVerificationCode(verificationCode: string) {
    dispatch.authentication.validateVerificationCode({ verificationCode });
  }

  function resendCode() {
    dispatch.authentication.sendVerificationCode();
    generateCodeExpiration();
  }

  useEffect(() => {
    startExpirationCountdown();
  }, [expirationTime]);

  useEffect((): void => {
    if (!error?.lockAuth) {
      dispatch.authentication.resetError();
      navigate('/login');
    } else {
      generateCodeExpiration();
    }
  }, []);

  return {
    codeExpirationInSeconds,
    validateVerificationCode,
    loading,
    validationError: error?.verificationCodeError,
    attemptsRemaining: error?.attemptsRemaining,
    resendCode,
    temporaryLockingFeatureFlagEnabled
  };
});

export default enhancer;
