import { FC } from 'react'
import * as Elements from './TrafficLight.elements'
import { Tooltip } from '@digibee/beehive-ui'
import { useTranslation } from 'react-i18next'
import type { HealthStatus } from '~/api/license'

const TrafficLight: FC<{ status?: HealthStatus }> = ({ status }) => {
  const [t] = useTranslation();
  const translate: {[key in HealthStatus]: string} = {
    GREEN: 'label.green_healthy_quota_tooltip',
    YELLOW: 'label.yellow_near_exceeded_quota_tooltip',
    RED: 'label.red_exceeded_quota_tooltip'
  };
  return (
    <Tooltip
      align="start"
      content={
        status ?
        t(translate[status]) :
        t('label.unavailable_for_this_resource_tooltip')
      }
      side="bottom"
      data-testid='traffic-light'
    >
      <Elements.Container data-testid='traffic-light-container'>
        <Elements.Circle data-testid='green' color='GREEN' active={status === 'GREEN'}/>
        <Elements.Circle data-testid='yellow' color='YELLOW' active={status === 'YELLOW'}/>
        <Elements.Circle data-testid='red' color='RED' active={status === 'RED'}/>
      </Elements.Container>
    </Tooltip>
  );
}

export default TrafficLight;
