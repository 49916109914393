import { choose, otherwise, when } from '@digibee/control-statements';
import Fuse from 'fuse.js';
import { intersectionWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Locals
import SubMenu from './components/SubMenu';

import i18n from '~/common/helpers/i18n';
import { border, base } from '~/common/styled/input';
import Checkbox from '~/components/Checkbox';
import { FloatingLabel as FloatingLabelNative } from '~/components/FloatingLabel';
import Icon from '~/components/IconCommunity';
import InputSearch from '~/components/InputSearch';
import List from '~/components/List';
import TextNative from '~/components/Text';

const SelectNative = styled.div`
  ${base};
  ${border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  &:focus,
  &:hover {
    border: 1px solid ${props => props.theme.colors.gra400};
  }
`;

const FloatingLabel = styled(FloatingLabelNative)`
  position: relative;
  left: 0px;
  top: 0px;
  ${props =>
    (props.isActived || props.isValue) &&
    css`
      position: absolute;
      left: 9px;
      top: 20px;
    `}
`;

const SubMenuStyle = styled(SubMenu)`
  width: 100%;
  height: 54px;
  top: 0;
  max-height: 170px;
  height: auto;
  overflow: hidden;
  background: ${props => props.theme.colors.white};
}
`;
const Wrapper = styled.div`
  position: relative;
`;

const ListItem = styled(List.Item)`
  width: 100%;
  border: none;
  overflow: hidden;
`;
const ListStyled = styled(List)`
  margin: 0;
`;

const ellipsis = width => () =>
  css`
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

const Text = styled(TextNative)`
  ${ellipsis('96%')};
`;

const TextName = styled(TextNative)`
  visibility: hidden;
  color: ${props => props.theme.colors.gra700};
  font-weight: 400;
  ${props =>
    props.isVisible &&
    css`
      ${ellipsis('96%')}
      visibility: visible;
    `}
`;

const WrapperList = styled.div`
  max-height: 200px;
  height: auto;
  overflow-x: hidden;
`;
const searchByName = (items, name) =>
  name
    ? new Fuse(items || [], {
        keys: ['name'],
        threshold: 0.1,
        tokenize: true,
        ignoreLocation: true
      }).search(name)
    : items;

const MultiSelect = ({
  registerSubMenu,
  toggleSubMenu,
  isSubMenuActive,
  placeholder,
  source = [],
  onChange,
  value = []
}) => {
  const [valueSearch, setValueSearch] = useState('');
  const initialValues = intersectionWith(
    value,
    source,
    (v, s) => v === s.value
  );
  return (
    <Wrapper innerRef={wrapper => registerSubMenu('select', wrapper)}>
      <SelectNative onClick={toggleSubMenu('select')}>
        <FloatingLabel
          isActived={isSubMenuActive('select')}
          isValue={initialValues.length}
        >
          {placeholder}
        </FloatingLabel>
        {choose(
          when(initialValues.length === source.length, () => (
            <TextName as='span' align='left' isVisible={initialValues.length}>
              {i18n.t('common.labels.selected_all')}
            </TextName>
          )),
          otherwise(() => (
            <TextName as='span' align='left' isVisible={initialValues.length}>
              {i18n.t('common.labels.selected')} ( {initialValues.length} )
            </TextName>
          ))
        )}
        <Icon name={isSubMenuActive('select') ? 'ChevronUp' : 'ChevronDown'} />
      </SelectNative>
      <SubMenuStyle visible={isSubMenuActive('select')}>
        <InputSearch
          placeholder='Search'
          value={valueSearch}
          onChange={e => setValueSearch(e.target.value)}
        />
        <WrapperList>
          <ListStyled>
            {searchByName(source, valueSearch)?.map(item => (
              <ListItem
                title={item.name}
                onClick={() => {
                  const newValue = !initialValues.find(x => x === item.value)
                    ? [...initialValues, item.value]
                    : initialValues.filter(i => i !== item.value);
                  onChange(newValue);
                }}
              >
                <Checkbox checked={initialValues.includes(item.value)} />
                <Text as='span' align='left'>
                  {item.name}
                </Text>
              </ListItem>
            ))}
          </ListStyled>
        </WrapperList>
      </SubMenuStyle>
    </Wrapper>
  );
};

MultiSelect.propTypes = {
  registerSubMenu: PropTypes.func,
  toggleSubMenu: PropTypes.func,
  isSubMenuActive: PropTypes.bool,
  placeholder: PropTypes.string,
  getLabel: PropTypes.func,
  source: PropTypes.arrayOf({
    name: PropTypes.string,
    value: PropTypes.string
  }),
  onChange: PropTypes.func,
  value: PropTypes.arrayOf([PropTypes.string])
};

export default MultiSelect;
