/* eslint-disable */
import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';
import { omit } from 'lodash';
// Locals

import { FloatingLabel } from '../../FloatingLabel';

const LabelText = styled(FloatingLabel)`
  left: 16px;
  ${props => {
    switch (props.size) {
      case 'xs':
        return css`
          margin-top: 1px;
          ${!props.isValue &&
          css`
            top: 7px;
          `}
        `;
      case 'sm':
        return css`
          top: 2.575em;
        `;
      case 'md':
      default:
        return css`
          top: ${p => (p.isActived || p.isValue ? '20px' : '16px')};
        `;
    }
  }}
`;

const ControlComponent = styled.div`
  background: ${props =>
    props.secondary ? props.theme.colors.gra25 : props.theme.colors.white};
  &:focus-within {
    margin: -1px;
    border: 2px solid
      ${props =>
        props.danger ? props.theme.colors.red500 : props.theme.colors.pri500};
    &:hover {
      border: 2px solid
        ${props =>
          props.danger ? props.theme.colors.red500 : props.theme.colors.pri500};
    }
  }
  display: flex;
  flex-wrap: wrap;
  font-family: ${props => props.theme.typography.fontFamily};
  width: 100%;
  position: relative;
  justify-content: space-between;
  height: 48px;
  border: 1px solid ${props => props.theme.colors.gra600};
  border-radius: 4px;
  cursor: default;
  outline: 0 !important;
  transition: all 100ms;
  box-sizing: border-box;

  ${props =>
    props.isFocused &&
    css`
      margin-left: -1px;
      margin-right: -1px;
    `};

  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.red500};
      &:focus-within ${LabelText} {
        color: ${props.theme.colors.red500};
      }
      &: hover {
        border: 2px solid ${props.theme.colors.red500};
      }
      border: 2px solid ${props.theme.colors.red500};
      margin: -1px;
    `};
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.gra400};
      border: 1px solid ${props => props.theme.colors.gra400};
    `};
  ${props => {
    switch (props.selectProps.size) {
      case 'xs':
        return css`
          height: 1.935em;
        `;
      case 'sm':
        return css`
          height: 2.575em;
        `;
      case 'md':
      default:
        return css`
          height: 48px;
        `;
    }
  }}

  ${props =>
    props.selectProps.isMulti &&
    css`
      height: auto;
      min-height: 48px;
    `};
`;

ControlComponent.defaultProps = {
  'data-testid': 'input'
};

const StyledContainer = styled.div``;

const submitOnBlur = ({ value, onSubmit }) => {
  if (!value || !onSubmit) return;
  onSubmit(value);
};

export const Control = ({
  innerRef,
  innerProps,
  isFloatingLabel,
  ...props
}) => {
  return (
    <StyledContainer>
      <ControlComponent
        danger={props.selectProps.danger}
        disabled={props.isDisabled}
        secondary={props.selectProps.labelSecondary}
        {...innerProps}
        {...omit(props, ['theme'])}
        innerRef={innerRef}
        onBlur={() => submitOnBlur(props.selectProps)}
      >
        <LabelText
          danger={props.selectProps.danger}
          isActived={props.isFocused}
          isValue={props.hasValue}
          disabled={props.isDisabled}
          secondary={props.selectProps.labelSecondary}
          size={props.selectProps.size}
          noFloatingLabel={props.selectProps.noFloatingLabel}
        >
          {props.selectProps.placeholder}
        </LabelText>
        {props.children}
      </ControlComponent>
    </StyledContainer>
  );
};
