import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScrollNative from 'react-infinite-scroll-component';

// Local
import { Loading } from './components';

const InfiniteScroll = ({
  children,
  innerRef,
  'data-testid': dataTestId,
  ...props
}) => (
  <InfiniteScrollNative loader={<Loading />} ref={e => innerRef(e)} {...props}>
    <div data-testid={dataTestId}>{children}</div>
  </InfiniteScrollNative>
);

InfiniteScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf([])]),
  components: PropTypes.objectOf({}),
  loading: PropTypes.bool,
  dataLength: PropTypes.number,
  innerRef: PropTypes.func,
  'data-testid': PropTypes.string
};

InfiniteScroll.defaultProps = {
  children: [],
  innerRef: () => {},
  components: {
    Loading
  },
  loading: true,
  dataLength: 0,
  'data-testid': ''
};
export default InfiniteScroll;
