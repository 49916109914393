import Immutable from 'seamless-immutable';
import { feedback } from '~/api';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  modal: {
    sendFeedback: false
  },
  loading: {
    sendFeedback: false
  },
  result: {
    sendFeedback: null
  },
  success: null
});

const feedbackModel = {
  name: 'feedback',
  state: initialState,
  reducers: {
    setModal(state, { path, value }) {
      return state.merge({
        modal: {
          [path]: value
        }
      });
    },
    setResult(state, { path, value }) {
      return state.merge({
        result: {
          [path]: value
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    success(state, payload) {
      return state.merge({
        success: payload
      });
    }
  },
  effects: dispatch => ({
    async sendFeedback(props, { application, authentication }) {
      try {
        const response = await feedback.create({
          realm: application.realm.realm,
          email: authentication.userData.email,
          message: props.message,
          path: window.location.pathname
        });
        dispatch.feedback.setResult({
          path: 'sendFeedback',
          value: response
        });
      } catch (e) {
        dispatch.feedback.setError({
          path: 'sendFeedback',
          value: e.message
        });
      }
    }
  }),
  logics: [
    {
      type: ['feedback/sendFeedback'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.feedback.setLoading({
          path: 'sendFeedback',
          value: true
        });
        done();
      }
    },
    {
      type: ['feedback/setError'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.feedback.setLoading({
          path: 'sendFeedback',
          value: false
        });
        dispatch.snackbar.create({
          text: i18n.t('common.messages.error.feedback'),
          action: {
            label: i18n.t('action.ok')
          }
        });
        done();
      }
    },
    {
      type: ['feedback/setResult'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.feedback.setLoading({
          path: 'sendFeedback',
          value: false
        });
        dispatch.feedback.setModal({
          path: 'sendFeedback',
          value: false
        });
        dispatch.snackbar.create({
          text: i18n.t('common.messages.success.feedback'),
          action: {
            label: i18n.t('action.ok'),
          }
        });
        done();
      }
    },
    {
      type: 'feedback/success',
      latest: true,
      process(_, dispatch, done) {
        dispatch.feedback.setModal({
          path: 'sendFeedback',
          value: false
        });
        done();
      }
    }
  ]
};

export default feedbackModel;
