import { FormikErrors, FormikTouched, FormikHelpers, FormikHandlers } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  loading: StoreRootState['login']['loading'],
  error: StoreRootState['login']['error'],
  errorCode: StoreRootState['login']['errorCode'],
  subdomain: StoreRootState['application']['subdomain'],
  realmParams: StoreRootState['authentication']['realmParams'],
  isSoftLocked: StoreRootState['login']['isSoftLocked'],
  back: StoreDispatcher['login']['reset'],
  signin: StoreDispatcher['login']['signin'],
}

export type LoginSchemaType = {
  email: string;
  realm: string;
  password: string;
  code: string;
  recaptcha: string;
  securityCode: string;
}

export type FormPageType = {
  values: LoginSchemaType,
  loading: boolean, 
  subdomain: StoreRootState['application']['subdomain'], 
  realmParams: StoreRootState['authentication']['realmParams'], 
  touched: FormikTouched<LoginSchemaType>, 
  onErrors: (
    touchedFields: FormikTouched<LoginSchemaType>, 
    errorsFields: FormikErrors<LoginSchemaType>, 
    field: string, 
    genericsError?: boolean, 
    especificError?: string | string[]
    ) => string, 
  errors: FormikErrors<LoginSchemaType>, 
  handleChange: FormikHandlers['handleChange'], 
  setFieldValue: FormikHelpers<LoginSchemaType>['setFieldValue'], 
  handleSubmit: FormikHandlers['handleSubmit']
};

const enhancer = withHooks((outProps: object) => {
  const { authentication, login, application } = useSelector((state: StoreRootState) => state);
  const dispatch = useDispatch<StoreDispatcher>();

  const props: Props = {
    error: login.error,
    errorCode: login.errorCode,
    loading: login.loading,
    subdomain: application.subdomain,
    realmParams: authentication.realmParams,
    isSoftLocked: login.isSoftLocked,
    signin: dispatch.login.signin,
    back: dispatch.login.reset
  }

  useEffect(() => {
    if (props?.subdomain) {
      dispatch.authentication.getNotLoggedPlatformParameter({ realm: props?.subdomain, param: 'hasCustomerIdentityProvider' });
      dispatch.authentication.getNotLoggedPlatformParameter({ realm: props?.subdomain, param: 'disabledForgotPassword' });
    }
  }, [props?.subdomain]);

  useEffect(() => () => {
      dispatch.login.reset();
  }, []);
  
  return {
    ...outProps,
    ...props,
  };
});

export default enhancer;
