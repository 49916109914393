import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import { Userpilot } from 'userpilot';

import fullstory from '~/api/fullstory';
import {
  PORTAL_FULLSTORY_ORG_ID,
  FULLSTORY_RELAY,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  DATADOG_SERVICE,
  DATADOG_ENVIRONMENT,
  DATADOG_PREMIUM_SESSION_RATE,
  DATADOG_SESSION_RATE,
  APPLICATION_VERSION,
  URL
} from '~/common/helpers/environment';

type UserData = {
  email: string;
  realm: string;
};

class WebAnalytics {
  static async init(userData: UserData | undefined) {
    if (DATADOG_APPLICATION_ID) {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE,
        env: DATADOG_ENVIRONMENT,
        version: APPLICATION_VERSION,
        sessionSampleRate: parseInt(DATADOG_SESSION_RATE, 10),
        premiumSampleRate: parseInt(DATADOG_PREMIUM_SESSION_RATE, 10),
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        enableExperimentalFeatures: ['feature_flags'],
        defaultPrivacyLevel: 'mask-user-input'
      });
    }

    const isAdBlockEnabled = await fullstory.isAdBlockEnabled();
    const fsHost = URL.substring(URL.indexOf('://') + 3);

    FullStory.init({
      orgId: PORTAL_FULLSTORY_ORG_ID,
      host:
        isAdBlockEnabled && String(FULLSTORY_RELAY) === 'true'
          ? `${fsHost}/fsrelay`
          : undefined,
      script:
        isAdBlockEnabled && String(FULLSTORY_RELAY) === 'true'
          ? `${fsHost}/fsscript/s/fs.js`
          : undefined
    });

    if (!userData) return FullStory.anonymize();

    return FullStory.identify(userData.email, {
      email: userData.email,
      realm_str: userData.realm
    });
  }

  static sendEvent(eventName: string, data: Record<string, unknown> = {}) {
    Userpilot.track(eventName, data);

    if (!FullStory.isInitialized()) return;

    try {
      FullStory.event(eventName, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  static startSurvey(eventName: string) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.Intercom) window.Intercom('trackEvent', eventName);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}

export default WebAnalytics;
