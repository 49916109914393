import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withHooks from '~/common/hoc/withHooks';

const mapStateToProps = ({ forgotPassword, application, authentication }) => ({
  isSendEmail: forgotPassword.isSendEmail,
  disabledForgotPassword: forgotPassword.disabledForgotPassword || authentication.realmParams?.disabledForgotPassword,
  loading: forgotPassword.loading,
  subdomain: application.subdomain,
});

const mapDispatchToProps = ({ forgotPassword }) => ({
  send: forgotPassword.send,
  returnToSignIn: forgotPassword.returnToSignIn,
  error: forgotPassword.error.sendEmail,
  setDisableForgotPassword: forgotPassword.setDisableForgotPassword
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHooks(props => {
    useEffect(() => {
      props.setDisableForgotPassword(false)
    }, []);
    return props;
  })
);

export default enhancer;
