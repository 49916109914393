import { useState } from 'react';
import * as Elements from './MockTag.elements';

type Props = {
  id?: string;
  title?: string;
  isFlowTree?: boolean;
};

const MockTag = ({ id, title, isFlowTree = false }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <Elements.Container
        data-testid={`connector-mock-tag-${id}`}
        onMouseEnter={() => title && setShowTooltip(true)}
        onMouseLeave={() => setTimeout(() => setShowTooltip(false), 300)}
      >
        <Elements.Tag isFlowTree={isFlowTree}>M</Elements.Tag>
      </Elements.Container>
      {showTooltip && (
        <Elements.TagTooltip data-testid={`connector-mock-tag-tooltip-${id}`}>
          {title}
        </Elements.TagTooltip>
      )}
    </>
  );
};

export default MockTag;
