import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TablePipelines from '../TablePipelines/TablePipelines';

import i18n from '~/common/helpers/i18n';
import SideSheetNative, { SidesheetHeader } from '~/components/SideSheet';

const SideSheet = styled(SideSheetNative)`
  width: 62%;
`;

const WrapperSide = styled.div`
  height: 90%;
  overflow-y: auto;
`;

const WrapperExplanation = styled.div`
  padding: 16px;
`;

function AccountsPipelineSidesheet({
  onClose,
  pipelines,
  loading,
  getPipelinesByAccounts,
  account
}) {
  return (
    <SideSheet
      data-testid='accounts-sidesheet-pipelines'
      onCloseClick={() => onClose()}
      opened
    >
      <WrapperSide className='fs-exclude'>
        <SidesheetHeader>{account.label}</SidesheetHeader>
        <WrapperExplanation>
          {i18n.t('label.details_or_edit_account_explanation')}
        </WrapperExplanation>
        <TablePipelines
          pipelines={pipelines}
          fetch={getPipelinesByAccounts}
          loading={loading}
        />
      </WrapperSide>
    </SideSheet>
  );
}

AccountsPipelineSidesheet.propTypes = {
  pipelines: PropTypes.objectOf({}).isRequired,
  onClose: PropTypes.func.isRequired,
  getPipelinesByAccounts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  account: PropTypes.objectOf({}).isRequired
};

export default AccountsPipelineSidesheet;
