import { throttle } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import Links from './components/Links';
import Menu from './components/Menu';
import Select from './components/Select';
import SubHeader from './components/SubHeader';
import * as Elements from './Header.elements';

import theme from '~/common/styled/theme';
import { useHeaderContext } from '~/components/HeaderProvider';

interface HeaderProps {
  byId: {
    [key: string]: {
      id: string;
      name: string;
      companyName: string;
    };
  };
  goToDefaultLayout: () => void;
  goToAdmin: () => void;
  goToConnectivity: () => void;
  allIds: string[];
  activeRealm: {
    realm: string;
  };
  containPaths: () => void;
  setModalNewPassword: () => void;
  setModalTwoFactor: () => void;
  onSignOut: () => void;
  user: { email: string };
  goToSettings: () => void;
  environments: string[];
  eula: object;
  pathNameRuntime: string;
  getEnvriomentByFeatureFlag: () => void;
  links: any[];
  showEnvironmentSelect?: boolean;
}

const Header: FC<HeaderProps> = ({
  allIds = [],
  byId = {},
  activeRealm,
  containPaths,
  setModalNewPassword,
  setModalTwoFactor,
  onSignOut,
  user,
  goToDefaultLayout,
  goToSettings,
  environments,
  eula,
  goToAdmin,
  pathNameRuntime,
  getEnvriomentByFeatureFlag,
  goToConnectivity,
  links,
  showEnvironmentSelect
}) => {
  const { configActionButton, node } = useHeaderContext();
  const [isAnimation, setAnimation] = useState(false);

  useEffect(() => {
    setAnimation(false);

    if (!node) return;

    const scrollHandle = throttle(e => {
      if (e.target.scrollTop > 50) {
        setAnimation(true);
      } else if (e.target.scrollTop < 50) {
        setAnimation(false);
      }
    }, 100);

    node.el.addEventListener('scroll', scrollHandle);

    // eslint-disable-next-line consistent-return
    return () => node.el.removeEventListener('scroll', scrollHandle);
  }, [node]);

  return (
    <Elements.Container isAnimation={isAnimation}>
      <Elements.Top>
        <Elements.Left>
          <Select allIds={allIds} byId={byId} activeRealm={activeRealm.realm} />
        </Elements.Left>
        <Elements.Center>
          <Elements.Logo src={theme.images.pictureNewLogoDigibeeWithText} />
        </Elements.Center>
        <Elements.Right>
          <Links
            setModalTwoFactor={setModalTwoFactor}
            setModalNewPassword={setModalNewPassword}
            onSignOut={onSignOut}
            email={user?.email}
            goToDefaultLayout={goToDefaultLayout}
            goToSettings={goToSettings}
            goToAdmin={goToAdmin}
            goToConnectivity={goToConnectivity}
            eula={eula}
          />
        </Elements.Right>
      </Elements.Top>
      <Elements.Bottom>
        <Menu
          containPaths={containPaths}
          realm={activeRealm.realm}
          environments={environments}
          pathNameRuntime={pathNameRuntime}
          getEnvriomentByFeatureFlag={getEnvriomentByFeatureFlag}
        />
      </Elements.Bottom>
      <SubHeader
        showEnvironmentSelect={showEnvironmentSelect}
        links={links}
        activeRealm={activeRealm}
        configActionButton={configActionButton}
        environments={environments}
      />
    </Elements.Container>
  );
};

export default Header;
