/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const configuration = {
  find: ({ realm, pipelineVersionId, environment }) => {
    return clientApollo.query({
      query: gql`
        query configurations(
          $realm: String!
          $pipelineId: String!
          $environment: String!
        ) {
          configurations(
            realm: $realm
            pipelineId: $pipelineId
            environment: $environment
          ) {
            id
            name
            description
            consumers
            actualConsumers
          }
        }
      `,
      variables: {
        realm,
        pipelineId: pipelineVersionId,
        environment
      }
    });
  }
};

export default configuration;
