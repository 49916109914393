import axios from 'axios';
import { API_KEY, URL } from '~/common/helpers/environment';
import { Domain, DomainAccessType, DomainUsersData } from '~/entities/Domain';

export interface ApiResponse {
  success: boolean;
  errorMessage?: string;
}

export interface FetchDomainsResponse extends ApiResponse {
  data?: Array<Domain>;
}

type UsersData = {
  content: Array<{
    totalUsers: number;
    users: DomainUsersData[];
  }>;
};
export interface FetchUsersDataResponse extends ApiResponse {
  data?: {
    totalUsers: number;
    users: DomainUsersData[];
  };
}

type AxiosHeader = [string, string];

const axiosDomain = axios.create({
  headers: {
    'Content-type': 'application/json',
    apikey: API_KEY
  }
});

const domains = {
  setHeaders(headers: AxiosHeader[]): void {
    headers.forEach(header => {
      axiosDomain.defaults.headers.common[header[0]] = header[1];
    });
  },
  async updateDomain(
    realm: string,
    domainData: {
      domainName: string;
      domainAccessType: DomainAccessType;
      users: Array<{
        userEmail: string;
        isDIGIBEEAccessAllowed: boolean;
      }>;
    }
  ): Promise<ApiResponse> {
    try {
      await axiosDomain.put<null>(
        `${URL}/design/realms/${realm}/domains`,
        domainData
      );
      return {
        success: true
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: 'unknown_error'
      };
    }
  },
  async createDomain(
    realm: string,
    domainAccessType: DomainAccessType,
    domainName: string
  ): Promise<ApiResponse> {
    try {
      await axiosDomain.post<null>(`${URL}/design/realms/${realm}/domains`, {
        domainAccessType,
        domainName
      });
      return {
        success: true
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409)
          return {
            success: false,
            errorMessage: 'domain_address_already_exists'
          };
        return {
          success: false,
          errorMessage: 'api_error'
        };
      } else {
        return {
          success: false,
          errorMessage: 'unknown_error'
        };
      }
    }
  },
  async fetchDomains(realm: string): Promise<FetchDomainsResponse> {
    try {
      const result = await axiosDomain.get<Array<Domain>>(
        `${URL}/design/realms/${realm}/domains`
      );
      return {
        success: true,
        data: result.data
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: 'unknown_error'
      };
    }
  },
  async fetchUsersData(
    realm: string,
    domain: string,
    page: number,
    size: number,
    filter?: string
  ): Promise<FetchUsersDataResponse> {
    try {
      let endpoint = `${URL}/design/realms/${realm}/domains/${domain}/users?size=${size}&page=${page}`;
      if (filter) endpoint = `${endpoint}&filter=${filter}`;

      const result = await axiosDomain.get<UsersData>(endpoint);
      return {
        success: true,
        data: result.data.content[0]
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: 'unknown_error'
      };
    }
  },
  async activateAccessRules(realm: string): Promise<ApiResponse> {
    try {
      await axiosDomain.patch(
        `${URL}/design/realms/${realm}/domains/activate-rules`
      );
      return {
        success: true
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: 'unknown_error'
      };
    }
  }
};

export default domains;
