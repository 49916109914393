import { choose, otherwise, when } from '@digibee/control-statements';
import { Formik } from 'formik';
import { func, objectOf, bool, string } from 'prop-types';
import React from 'react';
import ArrowLeft from 'react-feather/dist/icons/arrow-left';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';

// Locals
import PrivacyPolicy from '../PrivacyPolicy';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import Button from '~/components/Button';
import { Input } from '~/components/Input';

const StyledFields = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;
`;
const StyledContainer = styled.div`
  width: 100%;
`;

const FogotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('scenes.login.messages.error.required_field')
    .required('scenes.login.messages.error.required_field'),
  realm: yup.string().required('scenes.login.messages.error.required_field')
});

const StyledMessage = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: rgba(0, 0, 0, 0.38);
  padding-top: 10px;
  padding-bottom: 40px;
  font-family: ${props => props.theme.typography.fontFamily};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  left: 10.5%;
  overflow: hidden;
  color: ${props => props.theme.colors.pri500};
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.8;
  }
`;
const StyledWrapperIcon = styled.a``;

const FormForgotPassword = ({
  send,
  isSendEmail,
  returnToSignIn,
  loading,
  subdomain,
  disabledForgotPassword
}) => {
  const navigate = useNavigate();

  function renderSendEmail() {
    return (
      <StyledContainer>
        <StyledMessage data-testid='governance-success-message-forgot-password-page'>{i18n.t('label.redefine_password_msg')}</StyledMessage>
        <Button fluid type='primary' onClick={returnToSignIn}>
          {i18n.t('scenes.login.actions.return_sign_in')}
        </Button>
      </StyledContainer>
    );
  }
  function renderForgotPasswordDisabled() {
    return (
      <StyledContainer>
        <StyledMessage data-testid='governance-manager-message-forgot-password-page'>{i18n.t('label.to_request_new_password_contact_manager')}</StyledMessage>
        <Button fluid type='primary' onClick={returnToSignIn}>
          {i18n.t('scenes.login.actions.return_sign_in')}
        </Button>
      </StyledContainer>
    );
  }
  function renderForgotPassword() {
    return (
      <StyledContainer>
        <Formik
          validationSchema={FogotPasswordSchema}
          initialValues={{
            email: '',
            realm: subdomain || ''
          }}
          onSubmit={send}
          render={({ handleChange, values, touched, errors, handleSubmit }) => (
            <form
              onSubmit={ev => {
                ev.preventDefault();
              }}
            >
              <StyledWrapperIcon onClick={() => navigate(-1)}>
                <StyledArrowLeft />
              </StyledWrapperIcon>
              {!subdomain && (
                <StyledFields>
                  <Input
                    data-testid='governance-realm-input-forgot-password-page'
                    className='fs-mask'
                    name='realm'
                    value={values.realm}
                    onChange={handleChange}
                    danger={i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t(
                          getFieldError(touched, errors, 'realm')
                        )}`
                      }
                    )}
                    placeholder={i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t('scenes.login.labels.realm')}`
                      }
                    )}
                  />
                </StyledFields>
              )}
              <StyledFields>
                <Input
                  data-testid='governance-email-input-forgot-password-page'
                  className='fs-mask'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  danger={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t(getFieldError(touched, errors, 'email'))}`
                    }
                  )}
                  placeholder={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t('scenes.login.labels.email')}`
                    }
                  )}
                />
              </StyledFields>
              <StyledFields>
                <Button
                  data-testid='governance-confirm-password-button-forgot-password-page'
                  key='buttonForgotPassword'
                  is='submit'
                  type='primary'
                  fluid
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t('scenes.login.actions.confirm')}`
                    }
                  )}
                </Button>
              </StyledFields>
              <PrivacyPolicy />
            </form>
          )}
        />
      </StyledContainer>
    );
  }
  return (
    choose(
      when(disabledForgotPassword, () => renderForgotPasswordDisabled()),
      when(isSendEmail, () => renderSendEmail()),
      otherwise(() => renderForgotPassword())
    )
  );
};

FormForgotPassword.propTypes = {
  send: func,
  isSendEmail: bool,
  returnToSignIn: func,
  loading: bool,
  error: objectOf({}),
  disabledForgotPassword: bool,
  subdomain: string.isRequired
};
FormForgotPassword.defaultProps = {
  send: () => { },
  isSendEmail: false,
  returnToSignIn: () => { },
  loading: bool,
  error: null,
};
export default FormForgotPassword;
