import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledContainer = styled.form`
  width: 100%;
`;

export const StyledForgetPassword = styled(props => <Link {...props} />)`
  font-size: 12px;
  margin-bottom: 19px;
  float: right;
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${p => p.theme.colors.pri500};
`;

export const StyledFields = styled.div`
  padding: 10px 0px;
`;

export const IdpButton = styled.span`
  font-size: 12px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  text-decoration: underline;
  color: ${p => p.theme.colors.pri500};
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
  display: block;
  cursor: pointer;
`;
