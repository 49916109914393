/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const logs = {
  fetchLogs: params => {
    return clientApollo.query({
      query: gql`
        query logs(
          $realm: String!
          $key: String!
          $time: String
          $env: String
          $logIdAfter: String
        ) {
          logs(
            realm: $realm
            key: $key
            time: $time
            env: $env
            logIdAfter: $logIdAfter
          ) {
            id
            pipelineName
            logMessage
            timestamp
            logLevel
          }
        }
      `,
      variables: {
        ...params
      }
    });
  }
};

export default logs;
