import { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

type colorTypes =
  | 'primary'
  | 'secondary'
  | 'inverted'
  | 'black'
  | 'danger'
  | 'disabled'
  | 'light';

type weightTypes = 'thin' | 'regular' | 'medium' | 'bold';
type alignTypes = 'center' | 'left';
type sizesTypes = 'lg' | 'md' | 'sm';
type transformTypes =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'full-width';

const MAP_COLORS = {
  primary: {
    default: 'pri500'
  },
  secondary: {
    default: 'gra500'
  },
  inverted: {
    default: 'white'
  },
  black: {
    default: 'black'
  },
  danger: {
    default: 'red500'
  },
  disabled: {
    default: 'gra300'
  },
  light: {
    default: 'gra50'
  }
};

const getColors = (type: colorTypes) => MAP_COLORS[type];

interface StyledTextProps {
  type: colorTypes;
  weight: weightTypes;
  align: alignTypes;
  size: sizesTypes;
  transform: transformTypes;
}

const StyledElement = styled.span<StyledTextProps>`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${props => props.theme.colors[getColors(props.type).default]};
  font-size: ${props =>
    props.theme.typographyCurrent.text[props.size].fontSize};
  letter-spacing: ${props =>
    props.theme.typographyCurrent.text[props.size].letterSpacing};
  ${props =>
    props.align
      ? css`
          text-align: ${props.align};
        `
      : ''};
  font-weight: ${props => props.theme.fontWeight[props.weight]};

  &:focus {
    outline-width: 1px;
    outline-style: auto;
    outline-color: ${props => props.theme.colors.gra300};
    background-color: ${props => props.theme.colors.pri25};
    padding: 0 4px;
  }
`;

interface TextProps extends PropsWithChildren {
  type?: colorTypes;
  weight?: weightTypes;
  align?: alignTypes;
  size?: sizesTypes;
  transform?: transformTypes;
  as?: 'span' | 'p' | 'div' | 'label';
  'data-testid'?: string;
  active?: boolean;
}

const Text: FC<TextProps> = ({
  type = 'black',
  weight = 'thin',
  align = 'center',
  size = 'md',
  transform = 'none',
  ...restProps
}) => (
  <StyledElement
    type={type}
    weight={weight}
    align={align}
    size={size}
    transform={transform}
    {...restProps}
  />
);

export default Text;
