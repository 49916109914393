import { omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

// Locals
import { groups, groupsIntegration } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';
import WebAnalytics from '~/common/helpers/webAnalytics';

const integrationsEntity = new schema.Entity('integrations');
const integrationsSchema = { integrations: [integrationsEntity] };

const schemesEntity = new schema.Entity('schemes');
const schemesSchema = { schemes: [schemesEntity] };

const initialState = Immutable({
  result: {
    integrations: []
  },
  entities: {
    integrations: {}
  },
  last: true,
  totalElements: null,
  totalPages: null,
  sort: [],
  size: 10,
  number: 0,
  first: null,
  numberOfElements: null,
  loading: {
    groups: false
  },
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    integration: null,
    simulationRequestEmail: false,
    simulationForceCreate: false,
    simulationRunning: false
  },
  simulation: {
    data: {},
    alreadyRunning: false,
    timeout: 0
  },
  error: null,
  status: [],
  search: ''
});

const groupsIntegrationModel = {
  name: 'groupsIntegration',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setEntity(state, { data, path, params, integrationsData }) {
      return state.merge({
        data: integrationsData,
        result: {
          ...state.result,
          [path]: data.result[path]
        },
        entities: {
          ...state.entities,
          [path]: data.entities[path]
        },
        ...params
      });
    },
    setError: (state, error) => state.merge({ error }),
    setModal(state, payload) {
      return state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      });
    },
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    },
    setSimulation(state, simulation) {
      return state.merge({
        simulation: {
          ...state.simulation,
          ...simulation
        }
      });
    },
    reset: () => initialState
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const request = await groups.getIntegrations({
          realm: state.application.realm.realm,
          search: {
            page: state?.groupsIntegration?.number,
            size: state?.groupsIntegration?.size,
            disabled: false,
            ...state?.groupsIntegration?.search,
            ...params
          }
        });

        const data = normalize(
          { integrations: request?.data?.samlGroupMapping?.content },
          integrationsSchema
        );

        dispatch.groupsIntegration.setEntity({
          data,
          integrationsData: request?.data?.samlGroupMapping?.content,
          path: 'integrations',
          params: omit(request?.data?.samlGroupMapping, ['content'])
        });
      } catch (e) {
        dispatch.groupsIntegration.setError(e);
      }
    },
    async getIntegrationsSchemes(_, state) {
      const request = await groups.getIntegrationsSchemes({
        realm: state.application.realm.realm
      });

      const data = normalize(
        { schemes: request?.data?.samlSchemes },
        schemesSchema
      );

      dispatch.groupsIntegration.setEntity({
        data,
        path: 'schemes',
        params: {}
      });
    },
    async confirmFinishSimulation(resultDialogClosed) {
      if (resultDialogClosed) {
        dispatch.groupsIntegration.setActionModal(
          {
            simulationRunning: false,
            loading: false
          },
          {
            snackbar: {
              text: i18n.t('label.pending_test_refresh_msg_info'),
              open: true,
              action: {
                label: i18n.t('action.close')
              }
            }
          }
        );
        dispatch.groupsIntegration.clearSimulation();
        dispatch.groupsIntegration.find();
      } else {
        await dispatch.groupsIntegration.setModal({
          simulationRunning: true
        });
      }
    },
    async finishSimulation(params, state) {
      dispatch.groupsIntegration.setModal({
        loading: true
      });
      const { realm } = state.application.realm;
      const { token } = state.authentication.userData;
      try {
        const { data } = await groupsIntegration.getSimulation({
          realm,
          token,
          simulationId: params?.simulationId
        });
        await dispatch.groupsIntegration.setSimulation({
          data
        });
        if (data.status === 'PENDING' && params?.remainingTime > 0) {
          await dispatch.groupsIntegration.setModal({
            simulationRunning: false
          });
          const dialogResultfunction = await params?.dialogResultfunction();
          await dispatch.groupsIntegration.confirmFinishSimulation(dialogResultfunction);
        } else {
          if (data.status === 'SUCCESS') {
            await dispatch.groupsIntegration.setActionModal(
              {
                simulationRunning: false
              },
              {
                snackbar: {
                  text: i18n.t('label.integration_tested_msg_success', {
                    integrationName:
                      state.groupsIntegration?.entities?.integrations[
                        params?.simulationId
                      ]?.label
                  }),
                  open: true,
                  action: {
                    label: i18n.t('action.close')
                  }
                }
              }
            );
          } else if (data.status === 'FAILURE') {
            await dispatch.groupsIntegration.setActionModal(
              {
                simulationRunning: false
              },
              {
                snackbar: {
                  text: i18n.t('label.tested_integration_failed_msg_alert'),
                  open: true,
                  action: {
                    label: i18n.t('action.close')
                  }
                }
              }
            );
          } else {
            await dispatch.groupsIntegration.setActionModal(
              {
                simulationRunning: false
              },
              {
                snackbar: {
                  text: i18n.t('label.expired_tested_integration_msg_alert'),
                  open: true,
                  action: {
                    label: i18n.t('action.close')
                  }
                }
              }
            );
          }
          dispatch.groupsIntegration.clearSimulation();
          dispatch.groupsIntegration.find();
        }
      } catch (error) {
        dispatch.groupsIntegration.setActionModal(
          { error },
          {
            snackbar: {
              text: error.message,
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } finally {
        await dispatch.groupsIntegration.setModal({
          loading: false
        });
      }
    },
    async createSimulation(params, state) {
      dispatch.groupsIntegration.setModal({
        loading: true
      });
      const { realm } = state.application.realm;
      const { token } = state.authentication.userData;
      try {
        if (params?.forceCreate) {
          await groupsIntegration.updateSimulation({
            realm,
            token,
            simulationId: params?.simulationId,
            simulationStatus: 'CANCELED'
          });
        }
        const { status, data } = await groupsIntegration.createSimulation({
          realm,
          token,
          email: params?.email,
          samlGroupMappingId: params?.samlGroupMappingId,
          simulationTimeoutInSeconds: params?.simulationTimeoutInSeconds
        });
        await dispatch.groupsIntegration.setSimulation({
          alreadyRunning: Boolean(status === 412),
          data
        });
        if (status === 412) {
          await dispatch.groupsIntegration.setModal({
            simulationRequestEmail: false,
            simulationForceCreate: true
          });
        } else {
          await dispatch.groupsIntegration.setModal({
            simulationRequestEmail: false,
            simulationForceCreate: false,
            simulationRunning: true
          });
          await dispatch.groupsIntegration.setSimulation({
            timeout: params?.simulationTimeoutInSeconds || 300
          });
        }
      } catch (error) {
        dispatch.groupsIntegration.setActionModal(
          { error },
          {
            snackbar: {
              text: error.message,
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } finally {
        dispatch.groupsIntegration.setModal({
          loading: false
        });
      }
    },
    async cancelSimulation(params, state) {
      dispatch.groupsIntegration.setModal({
        loading: true
      });
      try {
        const { data } = await groupsIntegration.updateSimulation({
          realm: state.application.realm.realm,
          token: state.authentication.userData.token,
          simulationId: params.simulationId,
          status: params.status
        });
        await dispatch.groupsIntegration.setSimulation({
          alreadyRunning: false,
          data
        });
      } catch (error) {
        dispatch.groupsIntegration.setActionModal(
          { error },
          {
            snackbar: {
              text: error.message,
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      }
    },
    async clearSimulation() {
      await dispatch.groupsIntegration.setSimulation({
        alreadyRunning: false,
        data: {},
        timeout: 0
      });
    },
    async create(params, state) {
      dispatch.groupsIntegration.setModalLoading(true);
      try {
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: params?.explanation
        });
        const integrations = params?.values?.integrations?.map?.(
          integration => {
            const groupName = integration?.groupName?.value;
            const samlScheme =
              integration?.samlScheme?.value === 'custom'
                ? null
                : integration?.samlScheme?.value;
            const customSamlXPath =
              integration?.samlScheme?.value === 'custom'
                ? integration?.customSamlXPath
                : null;

            return {
              label: integration?.label,
              sourceGroupId: integration?.sourceGroupId,
              customSamlXPath,
              groupName,
              samlScheme
            };
          }
        );

        const response = await groups.createIntegrations({
          realm: state.application.realm.realm,
          integrations,
          explanation: state?.dialogs?.confirmDialog?.explanation?.value
        });
        dispatch.groupsIntegration.setActionModal(
          { success: response },
          {
            snackbar: {
              text:
                integrations.length > 1
                  ? i18n.t('label.create_integration_success_msg_plural')
                  : i18n.t('label.create_integration_success_msg', {
                    integrationName: integrations[0].label
                  }),
              success: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );

        params?.props?.resetForm();
      } catch (e) {
        dispatch.groupsIntegration.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } finally {
        dispatch.groupsIntegration.setModalLoading(false);
      }
    },

    async edit(params, state) {
      dispatch.groupsIntegration.setModalLoading(true);
      try {
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: params?.explanation
        });
        const values = params?.values?.integrations?.[0];
        const groupName = values?.groupName?.value;
        const samlScheme =
          values?.samlScheme?.value === 'custom'
            ? null
            : values?.samlScheme?.value;
        const customSamlXPath =
          values?.samlScheme?.value === 'custom'
            ? values?.customSamlXPath
            : null;

        const integration = {
          id: values?.id,
          label: values?.label,
          sourceGroupId: values?.sourceGroupId,
          customSamlXPath,
          groupName,
          samlScheme
        };

        const response = await groups.editIntegration({
          realm: state.application.realm.realm,
          integration,
          explanation: state?.dialogs?.confirmDialog?.explanation?.value
        });
        dispatch.groupsIntegration.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t('label.edit_integration_success_msg', {
                integrationName: integration?.label
              }),
              success: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );

        params?.props?.resetForm();
      } catch (e) {
        dispatch.groupsIntegration.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } finally {
        dispatch.groupsIntegration.setModalLoading(false);
      }
    },
    async remove(id, state) {
      try {
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: id?.explanation
        });
        const { realm } = state.application.realm;
        const remove = await groups.removeIntegration({
          realm,
          explanation: state?.dialogs?.confirmDialog?.explanation?.value,
          id: id?.id || id
        });
        WebAnalytics.sendEvent(
          '[Gov] (Groups Integrations) Archive Group Integration',
          { realm }
        );
        dispatch.groupsIntegration.setActionModal(
          {
            success: remove
          },
          {
            snackbar: {
              text: i18n.t('label.delete_integration_success_msg', {
                integrationName:
                  state.groupsIntegration?.entities?.integrations[id?.id || id]?.label
              }),
              success: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } catch (e) {
        dispatch.groupsIntegration.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } finally {
        dispatch.application.getPlatformParameter(
          'forceIntegratedGroupsToUserIdp'
        );
      }
    }
  }),
  logics: [
    {
      type: 'groupsIntegration/find',
      latest: true,
      process(context, dispatch, done) {
        dispatch.groupsIntegration.setLoading({
          path: 'integrations',
          value: true
        });
        done();
      }
    },
    {
      type: ['groupsIntegration/setError', 'groupsIntegration/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.groupsIntegration.setLoading({
          path: 'integrations',
          value: false
        });
        done();
      }
    },
    {
      type: ['groupsIntegration/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { status, search } = context.getState().user;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.groupsIntegration.setModal({
            visible: false,
            loading: false,
            success: true,
            integration: null
          });
          dispatch.groupsIntegration.find({ status, search, page: 0 });
        } else {
          dispatch.groupsIntegration.setModal({
            loading: false,
            success: false
          });
        }
        done();
      }
    },
    {
      type: ['groupsIntegration/create', 'groupsIntegration/edit'],
      latest: true
    },
    {
      type: ['groupsIntegration/setSearch'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.groupsIntegration.find({
          page: 0
        });
        done();
      }
    }
  ]
};

export default groupsIntegrationModel;
