import { Schema } from '~/entities/Capsule';
import parseJson from '../scenes/Capsule/utils/parseJson';

const defaultSchema = [
  {
    label: 'none',
    schema: []
  }
];

const ensureFormSchema = (values: string | Schema[]): Schema[] => {
  try {
    const fantaSchema = typeof values === 'string' ? parseJson(values) : values;

    if (!fantaSchema || !Array.isArray(fantaSchema) || !fantaSchema[0].schema) {
      return defaultSchema;
    }

    return fantaSchema;
  } catch (error) {
    return defaultSchema;
  }
};

export default ensureFormSchema;
