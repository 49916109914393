import { Formik } from 'formik';
import { objectOf, func, bool, string } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import Button from '~/components/Button';
import { Input } from '~/components/Input';

const StyledFields = styled.div`
  padding: 10px 0px;
`;
const StyledContainer = styled.div`
  width: 100%;
`;

const StyledMessage = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: rgba(0, 0, 0, 0.38);
  padding-top: 10px;
  padding-bottom: 20px;
  font-family: ${props => props.theme.typography.fontFamily};
`;

const MessageError = styled.div`
  display: block;
`;

const ResetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('scenes.login.messages.error.required_field')
    .required('scenes.login.messages.error.required_field'),
  realm: yup.string().required('scenes.login.messages.error.required_field'),
  newPassword: yup
    .string()
    .required('scenes.login.messages.error.required_field'),
  newPasswordConfirm: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'scenes.login.messages.error.password_not_equal'
    )
    .required('scenes.login.messages.error.required_field')
});

const FormResetPassword = ({
  loading,
  onResetPassword,
  error,
  returnToSignIn,
  returnToForgotPassword,
  resetPassword,
  isInvalidLink,
  subdomain
}) => {
  const params = useParams();

  const renderFormResetPassword = () => (
    <StyledContainer>
      <Formik
        initialValues={{
          email: '',
          realm: subdomain || '',
          newPassword: '',
          newPasswordConfirm: ''
        }}
        onSubmit={values =>
          onResetPassword({
            ...values,
            changePasswordId: params.changePasswordId
          })
        }
        validationSchema={ResetPasswordSchema}
        render={({ values, touched, errors, handleChange, handleSubmit }) => (
          <form
            onSubmit={ev => {
              ev.preventDefault();
            }}
          >
            <StyledMessage>
              {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
                item: `${i18n.t('noun.change_password')}`
              })}
            </StyledMessage>
            {!subdomain && (
              <StyledFields>
                <Input
                  data-testid='governance-realm-input-forgot-password-page'
                  className='fs-mask'
                  name='realm'
                  value={values.realm}
                  onChange={handleChange}
                  danger={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: getFieldError(touched, errors, 'realm')
                        ? i18n.t(getFieldError(touched, errors, 'realm'))
                        : error.unauthorized
                    }
                  )}
                  helperText={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: getFieldError(touched, errors, 'realm')
                        ? i18n.t(getFieldError(touched, errors, 'realm'))
                        : error.unauthorized
                    }
                  )}
                  placeholder={i18n.t(
                    'scenes.login.labels.upper_and_capitalize.capitalized',
                    {
                      item: `${i18n.t('scenes.login.labels.realm')}`
                    }
                  )}
                />
              </StyledFields>
            )}
            <StyledFields>
              <Input
                data-testid='governance-email-input-forgot-password-page'
                className='fs-mask'
                name='email'
                value={values.email}
                onChange={handleChange}
                autoComplete='new-password'
                danger={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: getFieldError(touched, errors, 'email')
                      ? i18n.t(getFieldError(touched, errors, 'email'))
                      : error.unauthorized
                  }
                )}
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: getFieldError(touched, errors, 'email')
                      ? i18n.t(getFieldError(touched, errors, 'email'))
                      : error.unauthorized
                  }
                )}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.email')}`
                  }
                )}
              />
            </StyledFields>
            <StyledFields>
              <Input
                data-testid='governance-password-input-forgot-password-page'
                className='fs-exclude'
                name='newPassword'
                type='password'
                autoComplete='new-password'
                value={values.newPassword}
                onChange={handleChange}
                danger={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: getFieldError(touched, errors, 'newPassword')
                      ? i18n.t(getFieldError(touched, errors, 'newPassword'))
                      : error.password
                  }
                )}
                helperText={
                  getFieldError(touched, errors, 'newPassword') ? (
                    i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: i18n.t(
                          getFieldError(touched, errors, 'newPassword')
                        )
                      }
                    )
                  ) : (
                    <>
                      {error?.messages?.password?.map(e => (
                        <MessageError>{e?.message}</MessageError>
                      ))}
                    </>
                  )
                }
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.new_password')}`
                  }
                )}
              />
            </StyledFields>
            <StyledFields>
              <Input
                data-testid='governance-confirm-password-input-forgot-password-page'
                className='fs-exclude'
                name='newPasswordConfirm'
                type='password'
                autoComplete='new-password'
                value={values.newPasswordConfirm}
                danger={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      getFieldError(touched, errors, 'newPasswordConfirm')
                    )}`
                  }
                )}
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      getFieldError(touched, errors, 'newPasswordConfirm')
                    )}`
                  }
                )}
                onChange={handleChange}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      'scenes.login.actions.confirm_new_password'
                    )}`
                  }
                )}
              />
            </StyledFields>
            <StyledFields>
              <Button
                data-testid='governance-confirm-button-forgot-password-page'
                key='buttonFirstAccess'
                is='submit'
                type='primary'
                fluid
                onClick={handleSubmit}
                loading={loading}
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.actions.confirm')}`
                  }
                )}
              </Button>
            </StyledFields>
          </form>
        )}
      />
    </StyledContainer>
  );
  const renderResetPassword = () => (
    <StyledContainer>
      <StyledMessage data-testid='governance-success-message-forgot-password-page'>
        {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
          item: `${i18n.t('scenes.login.messages.success.new_password_set')}`
        })}
      </StyledMessage>
      <Button data-testid='governance-success-message-return-to-sing-in-forgot-password-page' fluid type='primary' onClick={returnToSignIn}>
        {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
          item: `${i18n.t('scenes.login.actions.return_sign_in')}`
        })}
      </Button>
    </StyledContainer>
  );

  const renderInvalidLink = () => (
    <StyledContainer>
      <StyledMessage data-testid='governance-error-message-forgot-password-page'>
        {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
          item: `${i18n.t(
            'scenes.login.messages.error.reset_password_invalid'
          )}`
        })}
      </StyledMessage>
      <Button data-testid='governance-error-message-return-to-sing-in-forgot-password-page' fluid type='primary' onClick={returnToForgotPassword}>
        {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
          item: `${i18n.t('scenes.login.actions.new_link')}`
        })}
      </Button>
    </StyledContainer>
  );

  if (isInvalidLink) {
    return renderInvalidLink();
  }

  if (resetPassword) {
    return renderResetPassword();
  }

  return renderFormResetPassword();
};
FormResetPassword.propTypes = {
  loading: bool,
  onResetPassword: func,
  error: objectOf({}),
  returnToSignIn: func,
  returnToForgotPassword: func,
  resetPassword: objectOf({}),
  isInvalidLink: bool,
  subdomain: string.isRequired
};

FormResetPassword.defaultProps = {
  loading: false,
  onResetPassword: () => { },
  error: null,
  returnToSignIn: () => { },
  resetPassword: null
};

export default FormResetPassword;
