import styled, { css, keyframes } from 'styled-components';

const isIpadOS = () =>
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2 &&
  /MacIntel/.test(navigator.platform);

const enter = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d();
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: .5;
  }
`;

export const SideSheetContainer = styled.div`
  position: absolute;
  max-width: calc(100vw - 120px);
  height: ${() =>
    isIpadOS
      ? css`
          100vh;
        `
      : css`
          calc(100vh - 38px);
        `};
  background: ${props => props?.theme?.colors?.white};
  top: 0;
  transition: width 1s 2s;
  right: 0;
  will-change: transform;

  font-family: ${props => props.theme.typography.fontFamily};
  box-sizing: border-box;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);

  ${p =>
    p.state === 'entering' &&
    css`
      display: block;
      animation: ${enter} 0.25s cubic-bezier(0, 0, 0.2, 1);
    `}

  ${p =>
    p.state === 'entered' &&
    css`
      display: block;
    `}

  ${p =>
    p.state === 'exiting' &&
    css`
      animation: ${enter} 0.25s cubic-bezier(0.4, 0, 1, 1) reverse;
      animation-fill-mode: forwards;
      display: block;
      pointer-events: none;
    `}

  ${p =>
    p.state === 'exited' &&
    css`
      display: none;
    `}
`;

SideSheetContainer.displayName = 'SideSheetContainer';
SideSheetContainer.defaultProps = {
  'data-testid': 'container'
};

export const SideSheetRoot = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  ${p =>
    p.state === 'entering' &&
    css`
      display: block;
    `}

  ${p =>
    p.state === 'entered' &&
    css`
      display: block;
    `}

  ${p =>
    p.state === 'exiting' &&
    css`
      display: block;
    `}

  ${p =>
    p.state === 'exited' &&
    css`
      display: none;
    `}
`;

SideSheetRoot.displayName = 'SideSheetRoot';

export const SideSheetBackdrop = styled.div`
  background-color: rgb(0, 0, 0);
  display: none;
  height: 100vh;
  position: fixed;
  width: 100vw;

  ${p =>
    p.state === 'entering' &&
    css`
      display: block;
      opacity: 0.5;
      animation: ${fadeIn} 0.24s cubic-bezier(0, 0, 0.2, 1);
    `}

  ${p =>
    p.state === 'entered' &&
    css`
      display: block;
      opacity: 0.5;
    `}

  ${p =>
    p.state === 'exiting' &&
    css`
      display: block;
      animation: ${fadeIn} 0.26s cubic-bezier(0.4, 0, 1, 1) reverse;
      animation-fill-mode: forwards;
    `}

  ${p =>
    p.state === 'exited' &&
    css`
      display: none;
    `}
`;

SideSheetBackdrop.displayName = 'SideSheetBackdrop';
SideSheetBackdrop.defaultProps = {
  'data-testid': 'backdrop'
};

export const SideSheetContent = styled.div`
  height: ${p => p.height};
  overflow-y: ${props => (props.noScroll ? 'hidden' : 'auto')};
  overflow-x: ${props => (props.noScrollX ? 'hidden' : 'auto')};
  width: 100%;
`;

SideSheetContent.displayName = 'SideSheetContent';

export const SideSheetFooter = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;
  z-index: 10;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  border-top: 1px solid rgb(245, 245, 245);
  padding: 0px 41px;
  background: ${props => props?.theme?.colors?.white};
`;

SideSheetFooter.displayName = 'SideSheetFooter';
SideSheetFooter.defaultProps = {
  'data-testid': 'footer'
};

export const SideSheetHeader = styled.div`
  width: 100%;
  height: ${props => props.theme.header.height};
  border-bottom: 1px solid ${props => props?.theme?.colors?.gra50};
  padding: 0 15px;
  position: sticky;
  top: 0;
  z-index: 10;
  ${props =>
    props.showDesignSystemComponents
      ? css`
          background: ${props?.theme?.colors?.white};
        `
      : css`
          background: ${props?.theme?.colors?.gra25};
        `};

  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: ${p => p.theme.typography.fontFamily};

  ${p =>
    p.shadow &&
    css`
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    `}
`;

SideSheetHeader.displayName = 'SideSheetHeader';
SideSheetHeader.defaultProps = {
  'data-testid': 'header'
};
