import Dialog, {
  DialogContent,
  DialogTitle,
  DialogFooter,
  ContentTitle,
  Title,
  ContentText,
  Container
} from './Dialog';

export {
  DialogContent,
  DialogTitle,
  DialogFooter,
  ContentTitle,
  Title,
  ContentText,
  Container
};
export default Dialog;
