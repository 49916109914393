import { useSelector } from 'react-redux';

import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export const useIsAutoscaling = () => {
  const { realms } = useSelector<
    StoreRootState,
    Pick<StoreRootState, 'realms'>
  >(state => state);

  return realms?.getRealm?.licenseModel === 'CONSUMPTION_BASED_MODEL';
};
