import { PORTAL_URL } from '~/common/helpers/environment';

const getSubdomain = () => {
  if (!PORTAL_URL) return null;

  const { hostname } = window.location;
  const portalUrl = PORTAL_URL?.replace?.(/{([^}]*)}./, '');

  if (hostname?.split?.('.')?.length <= portalUrl?.split?.('.')?.length)
    return null;

  return hostname?.split?.('.')?.[0];
};

export default getSubdomain;
