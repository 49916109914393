import { createContext, useContext, useState } from 'react';
import { Toast, ToastProps } from '@digibee/beehive-ui';

interface ToastContextType {
  addToast: (props: Partial<ToastProps>) => void;
  hideToast: () => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('ToastProvider not found');
  }
  return context;
};

export const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = useState<ToastProps | null>(null);

  const addToast = (props: Partial<ToastProps>) => {
    setToast({
      ...props,
      visible: true,
      onDismiss: () => setToast(null)
    } as ToastProps);
  };

  const hideToast = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ addToast, hideToast }}>
      {children}
      {toast && <Toast {...toast} />}
    </ToastContext.Provider>
  );
};
