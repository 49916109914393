import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
// Locals
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Pagination from '~/components/_oldComponents/Pagination';
import Button from '~/components/Button';
import ButtonToolbar from '~/components/ButtonToolbar';
import { Table, Th, THead, TBody, Tr, Td } from '~/components/DataTable';
import IconNative from '~/components/IconCommunity';
import Role from '~/components/Role';

const Container = styled.div`
  margin-bottom: 40px;
`;

const Icon = styled(IconNative)`
  font-size: 16px;
`;

const Truncate = styled.p`
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  margin: 34px 0px;
`;

const TableGlobals = ({
  allIds,
  byId,
  remove,
  openModal,
  canEdit,
  getAccountsPaginated,
  openSidesheetPipelineAssociate
}) => {
  const { SETTINGS_ACCOUNTS_V2 } = featureFlagConstants;
  const { treatments } = useFeatureFlag([SETTINGS_ACCOUNTS_V2]);

  const showAccountsV2 = treatments[SETTINGS_ACCOUNTS_V2].treatment === 'on';

  return (
    <Container className='fs-exclude'>
      <Table>
        <THead>
          <Tr>
            <Th data-testid='accounts-table--th-account'>
              {i18n.t(
                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('scenes.accounts.labels.account_label')}`
                }
              )}
            </Th>
            <Th data-testid='accounts-table-th-description'>
              {i18n.t(
                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t(
                    'scenes.accounts.labels.account_description'
                  )}`
                }
              )}
            </Th>
            <Th data-testid='accounts-table-th-account_type'>
              {i18n.t(
                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('scenes.accounts.labels.account_type')}`
                }
              )}
            </Th>
            <Th data-testid='accounts-table-th-action_plural'>
              {i18n.t(
                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('scenes.accounts.labels.action_plural')}`
                }
              )}
            </Th>
          </Tr>
        </THead>
        <TBody>
          {showAccountsV2
            ? allIds.content.map(account => (
                <Tr key={account.id}>
                  <Td data-testid='accounts-table-td-label'>
                    <Truncate title={account.field}>{account.label}</Truncate>
                  </Td>
                  <Td data-testid='accounts-table-td-category'>
                    <Truncate title={account.category}>
                      {account.description}
                    </Truncate>
                  </Td>
                  <Td data-testid='accounts-table-td-description'>
                    <Truncate title={account.description}>
                      {account.accountType}
                    </Truncate>
                  </Td>
                  <Td>
                    <ButtonToolbar>
                      <Button
                        data-testid='accounts-button-action-show-pipeline'
                        type='primary'
                        emphasis='icon'
                        title={i18n.t(
                          'scenes.globals.labels.upper_and_capitalize.capitalized',
                          {
                            item: `${i18n.t('label.see_acc_details_tooltip')}`
                          }
                        )}
                        onClick={() => openSidesheetPipelineAssociate(account)}
                      >
                        <Icon name='Eye' />
                      </Button>
                      {iff(canEdit, () => (
                        <>
                          <Role name='ACCOUNT:UPDATE'>
                            <Button
                              data-testid='accounts-button-action-edit-account'
                              type='primary'
                              emphasis='icon'
                              title={i18n.t(
                                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                                {
                                  item: `${i18n.t(
                                    'scenes.accounts.actions.edit_account'
                                  )}`
                                }
                              )}
                              onClick={() => openModal(account)}
                            >
                              <Icon name='Edit' />
                            </Button>
                          </Role>
                          <Role name='ACCOUNT:DELETE'>
                            <Button
                              data-testid='accounts-button-action-delete-account'
                              type='primary'
                              emphasis='icon'
                              title={i18n.t(
                                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                                {
                                  item: `${i18n.t(
                                    'scenes.accounts.actions.dele_account'
                                  )}`
                                }
                              )}
                              onClick={() => remove(account)}
                            >
                              <Icon name='TrashAlt' />
                            </Button>
                          </Role>
                        </>
                      ))}
                      {iff(!canEdit, () => (
                        <Button
                          type='primary'
                          emphasis='icon'
                          title={i18n.t('label.view')}
                          onClick={() => openModal(account)}
                          data-testid='view-button'
                        >
                          <Icon name='Eye' />
                        </Button>
                      ))}
                    </ButtonToolbar>
                  </Td>
                </Tr>
              ))
            : allIds.map(id => (
                <Tr key={id}>
                  <Td data-testid='accounts-table-td-label'>
                    <Truncate title={byId[id]?.field}>
                      {byId[id]?.label}
                    </Truncate>
                  </Td>
                  <Td data-testid='accounts-table-td-category'>
                    <Truncate title={byId[id]?.category}>
                      {byId[id]?.description}
                    </Truncate>
                  </Td>
                  <Td data-testid='accounts-table-td-description'>
                    <Truncate title={byId[id]?.description}>
                      {byId[id]?.accountType}
                    </Truncate>
                  </Td>
                  <Td>
                    <ButtonToolbar>
                      {iff(canEdit, () => (
                        <>
                          <Role name='ACCOUNT:UPDATE'>
                            <Button
                              data-testid='accounts-button-action-edit-account'
                              type='primary'
                              emphasis='icon'
                              title={i18n.t(
                                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                                {
                                  item: `${i18n.t(
                                    'scenes.accounts.actions.edit_account'
                                  )}`
                                }
                              )}
                              onClick={() => openModal(byId[id])}
                            >
                              <Icon name='Edit' />
                            </Button>
                          </Role>
                          <Role name='ACCOUNT:DELETE'>
                            <Button
                              data-testid='accounts-button-action-delete-account'
                              type='primary'
                              emphasis='icon'
                              title={i18n.t(
                                'scenes.accounts.labels.upper_and_capitalize.capitalized',
                                {
                                  item: `${i18n.t(
                                    'scenes.accounts.actions.dele_account'
                                  )}`
                                }
                              )}
                              onClick={() => remove(id)}
                            >
                              <Icon name='TrashAlt' />
                            </Button>
                          </Role>
                        </>
                      ))}
                      {iff(!canEdit, () => (
                        <Button
                          type='primary'
                          emphasis='icon'
                          title={i18n.t('label.view')}
                          onClick={() => openModal(byId[id])}
                          data-testid='view-button'
                        >
                          <Icon name='Eye' />
                        </Button>
                      ))}
                    </ButtonToolbar>
                  </Td>
                </Tr>
              ))}
        </TBody>
      </Table>
      {showAccountsV2 && (
        <Footer>
          <Pagination
            state={{}}
            actions={{}}
            activedPage={allIds.number + 1}
            currentRangePage={10}
            onChange={page => getAccountsPaginated(page - 1)}
            totalElements={allIds.totalElements}
            perPageSize={allIds.size}
          />
        </Footer>
      )}
    </Container>
  );
};

TableGlobals.propTypes = {
  allIds: PropTypes.arrayOf([PropTypes.string]).isRequired,
  byId: PropTypes.objectOf({}).isRequired,
  remove: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  getAccountsPaginated: PropTypes.func.isRequired,
  openSidesheetPipelineAssociate: PropTypes.func.isRequired
};

export default TableGlobals;
