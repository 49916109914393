import { get } from 'lodash';
import Immutable from 'seamless-immutable';

import hello from './hello';
import helloExtends from './hello.extends';

const initialState = new Immutable({
  authenticated: false,
  authData: null,
  error: null
});

const enhancer = {
  state: initialState,
  reducers: {
    setAuthenticated: (state, payload) =>
      state.merge({ authenticated: true, authData: payload }),
    setError: (state, error) => state.merge({ error })
  },
  effects: actions => ({
    authenticate: (
      _,
      __,
      { provider, scope: selectedScopes, onAuthenticated }
    ) => {
      const scopesObject = provider.authorization.scopes.reduce(
        (accum, curr) => ({ ...accum, [curr]: curr }),
        {}
      );
      const service = {
        custom: provider.id,
        options: {
          scope: scopesObject,
          response_type: provider.authorization.queryParams.response_type
        }
      };
      const helloOptions = {
        oauth_proxy: provider.options.oauth_proxy,
        redirect_uri: `${window.location.origin}/oauth2/success`
      };

      const authQueryParams = provider.authorization.queryParams;
      const queryParamsKeys = Object.keys(authQueryParams);
      const queryParams = queryParamsKeys.reduce((prev, curr) => {
        if (curr === 'redirect_uri') return prev;

        return {
          ...prev,
          ...(authQueryParams[curr] === '{{ oauth.client_id }}'
            ? { [curr]: provider.clientId }
            : { [curr]: authQueryParams[curr] })
        };
      }, {});
      const data = {
        ...provider,
        authorization: {
          ...provider.authorization,
          queryParams
        },
        scopes: provider.authorization.scopes,
        scopesObject,
        selectedScopes,
        oauth_proxy: provider.options.oauth_proxy,
        redirect_uri: `${window.location.origin}/oauth2/success`,
        response_type: provider.authorization.queryParams.response_type,
        force: false
      };

      hello.init(service, helloOptions);

      helloExtends(hello, provider);

      hello('custom').login(data, ({ error }) => {
        if (error && error.code === 'cancelled') return;

        // eslint-disable-next-line consistent-return
        if (error) return actions.setError(error.message);

        actions.setError(null);

        const authData = hello(provider.provider).getAuthResponse('custom');
        const mappedAuthData = {
          access_token: get(
            authData,
            provider.grantResponse.accessTokenJsonPath.replace('$.', '')
          ),
          refresh_token: get(
            authData,
            provider.grantResponse.refreshTokenJsonPath.replace('$.', '')
          ),
          expires_in: get(
            authData,
            provider.grantResponse.expiresInJsonPath.replace('$.', '')
          )
        };

        actions.setAuthenticated(mappedAuthData);

        if (onAuthenticated) onAuthenticated(authData);
      });

      return actions;
    }
  })
};

export default enhancer;
