import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './CustomAlert.elements';

import Button from '~/components/Button';
import Role from '~/components/Role';

const CustomAlert = ({ text, textButton, onClick }) => (
  <>
    <Elements.Text weight='regular' size='sm' align='left'>
      {text}
    </Elements.Text>
    {textButton && (
      <Elements.ContentBodyAlert>
        <Role name='BETA:CREATE-DEPLOYMENT'>
          <Button onClick={onClick} type='primary'>
            {textButton}
          </Button>
        </Role>
      </Elements.ContentBodyAlert>
    )}
  </>
);

CustomAlert.defaultProps = {
  text: '',
  textButton: ''
};

CustomAlert.propTypes = {
  text: PropTypes.string,
  textButton: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default CustomAlert;
