import React from 'react';
import styled from 'styled-components';

import i18n from '~/common/helpers/i18n';
import InnerEmpty from '~/components/InnerEmpty';

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  z-index: 10000000;
  height: 256px;
`;

export const NoOptionsMessage = () => (
  <Container>
    <InnerEmpty
      icon='Search'
      text={i18n.t('common.messages.error.result_not_found')}
    />
  </Container>
);
