import { Box, Flex, Text, Tooltip } from '@digibee/beehive-ui';

export type DetailsProps = {
  name: string;
  nameTooltip?: string | null;
  value?: string | number;
  valueTooltip?: string | null;
};

export const Details = ({
  name,
  nameTooltip,
  value,
  valueTooltip
}: DetailsProps) => (
  <Box>
    <Flex
      css={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '8px 5px 5px'
      }}
    >
      {nameTooltip ? (
        <Tooltip content={nameTooltip}>
          <Text css={{ fontSize: '$2' }}>{name}</Text>
        </Tooltip>
      ) : (
        <Text css={{ fontSize: '$2' }}>{name}</Text>
      )}
      {valueTooltip ? (
        <Tooltip content={valueTooltip}>
          <Text
            css={{ color: '$pri500', fontWeight: '$medium', fontSize: '$4' }}
          >
            {value}
          </Text>
        </Tooltip>
      ) : (
        <Text
          css={{
            color: '$pri500',
            fontWeight: '$medium',
            fontSize: '$4',
            lineHeight: '42px'
          }}
        >
          {value}
        </Text>
      )}
    </Flex>
  </Box>
);
