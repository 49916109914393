import { Flex, Tag } from '@digibee/beehive-ui';

import type { Deployment } from '../..';

import i18n from '~/common/helpers/i18n';
import { StatusDeploy } from '~/scenes/Runtime/components/StatusDeploy';

type StatusHeaderProps = {
  deployment: Deployment;
  disabled?: boolean;
};

export const StatusHeader = ({ deployment, disabled }: StatusHeaderProps) => (
  <Flex
    css={{
      alignItems: 'baseline',
      width: '100%',
      justifyContent: 'space-between'
    }}
  >
    <Flex css={{ alignItems: 'baseline' }}>
      <Tag
        variant='version'
        // @ts-ignore
        title={`${i18n.t('scenes.runtime.labels.version')} ${
          deployment?.pipeline.versionMajor
        }.${deployment?.pipeline?.versionMinor}`}
        onClick={(ev: any) => ev.stopPropagation()}
        data-testid='build-card-tag-version'
      >
        {`${i18n.t('scenes.runtime.labels.version')} ${
          deployment?.pipeline.versionMajor
        }.${deployment?.pipeline?.versionMinor}`}
      </Tag>
      <StatusDeploy
        // @ts-ignore
        deployment={deployment}
        disabled={disabled}
        isMarginBottom={false}
      />
    </Flex>
  </Flex>
);
