import { init } from '@rematch/core';
import { combineReducers } from 'redux';
import reduxLogic, { createLogicMiddleware } from 'redux-logic';
import undoable, { groupByActionTypes, includeAction } from 'redux-undo';

import * as buildModels from '~/scenes/Build/models';
import * as ComponentTeamModels from '~/scenes/Components/models';
import * as governanceModels from '~/scenes/Governance/models';
import * as identityAndAccessModels from '~/scenes/IdentityAndAccess/models';
import * as monitorModels from '~/scenes/Monitor/models';
import * as othersModels from '~/scenes/Others/models';
import * as runModels from '~/scenes/Runtime/models';

const models = {
  ...buildModels,
  ...identityAndAccessModels,
  ...monitorModels,
  ...othersModels,
  ...runModels,
  ...governanceModels,
  ...ComponentTeamModels
};

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

let localStore;
const arrLogics = [];

const decorateProcess = process => (context, dipatch, done) => {
  process(context, localStore.dispatch, done);
};

const plugin = logicMiddlewaresArray => ({
  onModel(model) {
    const logics = model.logics || [];
    logics.forEach(logic => {
      if (logic.process) {
        // eslint-disable-next-line no-param-reassign
        logic.process = decorateProcess(logic.process);
      }
      arrLogics.push(reduxLogic.createLogic(logic));
    });
  },
  onStoreCreated(store) {
    localStore = store;
    const chunks = chunk(arrLogics, 100);
    chunks.forEach((logic, index) => {
      logicMiddlewaresArray[index]?.addLogic?.(logic);
    });
  }
});

const totalLogics = Object.keys(models).reduce((total, key) => {
  const sum = models[key].logics?.length || 0;
  return total + sum;
}, 0);

const CHUNK_SIZE = 40;

const createStore = (navigate = () => { }, initialState = {}) => {
  const createLogicMiddlewareByChunkSize = chunkSize => {
    const chunksSize = Math.ceil(totalLogics / chunkSize);
    const middlewaresArray = [];

    for (let index = 0; index < chunksSize; index += 1) {
      middlewaresArray.push(createLogicMiddleware([], {}));
    }
    return middlewaresArray;
  };

  const middlewaresArray = createLogicMiddlewareByChunkSize(CHUNK_SIZE);

  const store = init({
    models: {
      ...models,
      router: {
        name: 'router',
        state: {},
        reducers: {},
        effects: () => ({
          navigate: args => {
            const types = ['string', 'number'];

            if (types.includes(typeof args)) return navigate(args);

            const { to, options, ...rest } = args;

            return navigate({ pathname: to, ...rest }, options);
          }
        })
      }
    },
    plugins: [plugin(middlewaresArray, CHUNK_SIZE)],
    redux: {
      initialState,
      middlewares: middlewaresArray,
      combineReducers: ({ cy, ...reducers }) =>
        combineReducers({
          ...reducers,
          cy: undoable(cy, {
            filter: includeAction(['cy/setRoot', 'cy/breadcrumbsDecrement']),
            groupBy: groupByActionTypes([
              'cy/updateCanvas',
              'cy/breadcrumbsDecrement'
            ]),
            ignoreInitialState: true,
            limit: 1
          })
        })
    }
  });

  window.store = store;

  return store;
};

export default createStore;
