import { useState } from 'react';

const useDynamicState = (value, defaultValue) => {
  if (value === undefined) {
    const [state, setState] = useState(defaultValue);
    return [state, setState, false];
  }
  return [value, () => {}, true]; // just bypass
};

export default useDynamicState;
