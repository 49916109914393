/* eslint-disable */

import { split, head, without } from 'lodash';

const backList = ['login'];
const getRealmToPathName = pathName => {
  const listOfPathSplitToBar = split(pathName, '/');
  return {
    realm: head(without(listOfPathSplitToBar, '', ...backList) || '')
  };
};

export default getRealmToPathName;
