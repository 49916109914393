import { useEffect, useRef } from 'react';

type Props = {
  title?: string;
  prevailOnUnmount?: boolean;
};

const useDocumentTitle = ({ title, prevailOnUnmount = false }: Props) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'Digibee';
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
};

export default useDocumentTitle;
