import { useDispatch } from 'react-redux';

type Snackbar = {
  create: (props: { text: string; action?: string }) => void;
};

function useSnackbar() {
  const { snackbar } = useDispatch<{
    snackbar: Snackbar;
  }>();

  function createSnack(text: string, actionLabel?: string): void {
    snackbar.create({
      text,
      action: actionLabel
    });
  }

  return {
    createSnack
  };
}

export default useSnackbar;
