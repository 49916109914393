import PropTypes from 'prop-types';

import * as Elements from './TextWithIcon.elements';

import Text from '~/components/Text';

const TextWithIcon = ({ icon, text, isEllipse, disable, ...props }) => (
  <Elements.Container isEllipse={isEllipse}>
    {icon && <Elements.Icon icon={icon} />}
    <Text
      as='span'
      align='left'
      size='sm'
      type={disable ? 'disabled' : 'black'}
      {...props}
    >
      {text}
    </Text>
  </Elements.Container>
);

TextWithIcon.defaultProps = {
  isEllipse: false,
  disable: false,
  icon: null
};

TextWithIcon.propTypes = {
  icon: PropTypes.objectOf({}),
  text: PropTypes.string.isRequired,
  isEllipse: PropTypes.bool,
  disable: PropTypes.bool
};

export default TextWithIcon;
