import styled from 'styled-components';

import Text from '~/components/Text';

export const DetailsDescription = styled(Text)`
  font-size: 24px;
  color: ${props =>
    props.disabled ? props.theme.colors.gra300 : props.theme.colors.pri200};
  padding-top: 8px;
`;
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 5px 5px 5px;
`;
