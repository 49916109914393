/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const environments = {
  find: ({ realm }) => {
    return clientApollo.query({
      query: gql`
        query environments($realm: String!) {
          environments(realm: $realm) {
            id
            name
            retention {
              scope
              type
            }
          }
        }
      `,
      variables: {
        realm
      }
    });
  }
};

export default environments;
