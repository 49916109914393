import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Chip } from '../Chip';

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  min-height: 48px;
  box-sizing: border-box;
  padding-left: 5px;
  position: relative;
`;

const TagWrapper = styled.div`
  margin-top: 6px;
`;

const Tag = styled(Chip)``;

const Label = styled.div`
  position: absolute;
  top: -9px;
  background: ${props => props.theme.colors.white};
  padding: 0px 7px;
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${p => p.theme.colors.pri500};
  font-size: 12px;
`;

const TextField = styled.input`
  font-family: ${p => p.theme.typography.fontFamily};
  flex-grow: 1;
  border: none;
  outline: none;
  height: 46px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0px 0px 0px 7px;
`;

const InputTags = ({ value, label, onChange, className }) => {
  const [tags, setTags] = useState(value);
  const [textValue, setTextValue] = useState('');

  const onKeyDownHandler = ev => {
    if (ev.keyCode === 13) {
      if (!textValue) return;
      const updatedValue = tags.concat(textValue);
      setTags(updatedValue);
      onChange(updatedValue);
      setTextValue('');
    }

    if (ev.keyCode === 8) {
      if (textValue) return;
      const updatedValue = tags.slice(0, -1);
      setTags(updatedValue);
      onChange(updatedValue);
    }
  };

  const removeTag = index => () => {
    const updatedValue = tags.slice(0, index).concat(tags.slice(index + 1));
    setTags(updatedValue);
    onChange(updatedValue);
  };

  const onChangeHandler = ev => {
    setTextValue(ev.target.value);
  };

  useEffect(() => {
    if (!isEqual(value, tags)) {
      setTags(value);
    }
  }, [value]);

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      {tags.map((title, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TagWrapper key={idx}>
          <Tag inverted title={title} onDoubleClick={removeTag(idx)} />
        </TagWrapper>
      ))}
      <TextField
        value={textValue}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
        className={className}
      />
    </Wrapper>
  );
};
InputTags.defaultProps = {
  value: [],
  onChange: () => {},
  label: ''
};

InputTags.propTypes = {
  value: PropTypes.arrayOf([PropTypes.string]),
  onChange: PropTypes.func,
  label: PropTypes.string
};

export default InputTags;
