import { useState, useEffect } from 'react';

const getNextState = (value, states, currentState) => {
  const valueConfig = states.find(config => config.value === value);
  const pipeConfigIndex = valueConfig.pipe.findIndex(
    config => config.state === currentState
  );
  const nextStateConfig = valueConfig.pipe[pipeConfigIndex + 1];
  return nextStateConfig;
};

function AnimationTransitionState({ value, states, defaultState, children }) {
  const [currentState, setState] = useState(defaultState);

  useEffect(() => {
    const config = getNextState(value, states, currentState);

    if (config) {
      setTimeout(() => {
        setState(config.state);
      }, config.timeout);
    }
  });
  return children({ value, state: currentState });
}

AnimationTransitionState.defaultProps = {
  value: null,
  defaultState: 'exited',
  states: [
    {
      value: true,
      pipe: [{ state: 'entering' }, { state: 'entered', timeout: 250 }]
    },
    {
      value: false,
      pipe: [{ state: 'exiting' }, { state: 'exited', timeout: 250 }]
    }
  ]
};

export default AnimationTransitionState;
