/* eslint-disable */
import React from 'react';
import pagination from 'paginator';
import withRemacth from 'with-rematch';
import { object, number, func } from 'prop-types';
import Immutable from 'seamless-immutable';
import styled from 'styled-components';
import { isEqual, isFunction } from 'lodash';
import ArrowLeft from 'react-icons/lib/md/arrow-back';
import ArrowRight from 'react-icons/lib/md/arrow-forward';
import usePaginator from './usePaginator';
// Locals
import Page from './components/Page';
import { iff } from '@digibee/control-statements';

function Pagination({ state, actions, ...props }) {
  const pagination = usePaginator(props);
  return (
    <List>
      {iff(pagination.totalPages, () => (
        <Page
          textPage={<ArrowLeft size={11} />}
          disabled={!pagination.hasPreviousPage}
          key={`preview ${pagination.previousPage}`}
          onClick={props.onChange}
          pageNumber={pagination.previousPage}
        />
      ))}
      {pagination.arrayPage.map((item, index) => {
        const pageNumber = pagination.firstPage + index;
        return (
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            textPage={`${pageNumber}`}
            activedPage={pageNumber === props.activedPage}
            onClick={props.onChange}
          />
        );
      })}
      {iff(pagination.totalPages, () => (
        <Page
          textPage={<ArrowRight size={11} />}
          disabled={!pagination.hasNextPage}
          key={'next' + pagination.nextPage}
          pageNumber={pagination.nextPage}
          onClick={props.onChange}
        />
      ))}
    </List>
  );
}

Pagination.propTypes = {
  page: number,
  pageSize: number,
  pagination: func,
  actions: object,
  activedPage: number
};

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
  padding: 20;
`;

export default Pagination;
