import styled from 'styled-components';

// Locals
import TextNative from '../../../Text';

import { helperText } from '~/common/styled/input';

const HelperText = styled(TextNative)`
  ${helperText.baseStyles};
  ${helperText.colorStyles};
  ${helperText.marginStyles};
`;

HelperText.defaultProps = {
  'data-testid': 'helpertext'
};

HelperText.propTypes = {
  isActived: false,
  danger: false
};

export default HelperText;
