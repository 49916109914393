import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { HistoryDeploymentContext } from './HistoryDeploymentContext';
import { Deployments, HistoricType } from '../types';

import api from '~/api/runtime';
import { Pagination } from '~/common/types/Pagination';

type HistoryDeploymentProviderProps = {
  children: React.ReactElement;
};

export function HistoryDeploymentProvider({
  children
}: HistoryDeploymentProviderProps) {
  const { application } = useSelector((state: any) => state);
  const [deploymentSelected, setDeploymentSelected] =
    useState<Deployments | null>(null);
  const [loading, setLoading] = useState(false);
  const [historic, setHistoric] = useState<Pagination<HistoricType>>({
    last: true,
    content: [],
    totalPages: 0,
    totalElements: 0,
    number: 0,
    first: false,
    numberOfElements: 0,
    size: 0
  });
  const [lastDeploy, setLastDeploy] = useState<HistoricType>();

  async function getData(page = 0) {
    const { data } = await api.findByDeployHistory({
      realm: application?.activeRealm,
      pipelineName: deploymentSelected?.pipelineName,
      pipelineMajorVersion: deploymentSelected?.pipelineVersionMajor,
      page
    });
    setHistoric(data?.deployHistory);
    setLoading(false);
  }

  useEffect(() => {
    if (deploymentSelected) {
      getData();
    }
  }, [deploymentSelected]);

  async function selectDeployment(deployment: any) {
    setDeploymentSelected(deployment);

    setLoading(true);

    const { data } = await api.getLastDeploy({
      realm: application?.activeRealm,
      pipelineName: deployment?.pipelineName,
      pipelineMajorVersion: deployment?.pipelineVersionMajor
    });

    setLastDeploy(data.deployHistory.content[0]);
  }

  return (
    <HistoryDeploymentContext.Provider
      value={{
        deploymentSelected,
        lastDeploy,
        selectDeployment,
        loading,
        historic,
        getData
      }}
    >
      {children}
    </HistoryDeploymentContext.Provider>
  );
}
