import { theme, Button as NativeButton, Text as NativeText } from '@digibee/beehive-ui';

export const Container = theme.styled('div', {
  width: '100%',
});

export const ContainerFooter = theme.styled(Container, {
  bottom: '32px',
  position: 'absolute',
});

export const Text = theme.styled(NativeText, {
  marginBottom: '28px',
  textAlign: 'left',
  lineHeight: '1.4',
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
});

export const Title = theme.styled(Text, {
  marginBottom: '12px',
  fontSize: '$3',
  fontWeight: '$medium',
  color: '$pri500',
});

export const InputContainer = theme.styled('div', {
  margin: '24px auto',
});

export const Caption = theme.styled(Text, {
  fontSize: '$2',
  marginBottom: 'unset',
});

export const DangerCaption = theme.styled(Caption, {
  color: '$red500',
  fontWeight: '400',
});

export const Link = theme.styled('a', {
  color: '$blu500',
  fontSize: '$2',
  fontFamily: '$display',
  textDecoration: 'none'
});

export const Button = theme.styled(NativeButton, {
  width: '100%',
  justifyContent: 'center',
  marginBottom: '8px',
});
