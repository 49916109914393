import { theme, Button } from '@digibee/beehive-ui';

export const Container = theme.styled('form', {
  width: '100%',
});

export const LinksContainer = theme.styled('div', {
  display: 'flex',
  flexFlow: 'column',
  gap: '$3',
  alignItems: 'flex-end',
  marginBottom: '$3',
});

export const StyledLink = theme.styled('a', {
  float: 'right',
  color: '$pri500',
  fontSize: '$0',
  fontFamily: '$display',
});

export const Link = theme.styled('a', {
  color: '$blu500',
  fontSize: '$2',
  fontFamily: '$display',
  textDecoration: 'none'
});

export const Fields = theme.styled('div', {
  padding: '10px 0px',
});

export const IdpButton = theme.styled('span', {
  fontSize: '$0',
  fontFamily: '$display',
  textDecoration: 'underline',
  color: '$pri500',
  textAlign: 'center',
  marginTop: '$3',
  marginBottom: '$4',
  display: 'block',
  cursor: 'pointer',
});

export const SubmitButton = theme.styled(Button, {
  width: '100%',
  justifyContent: 'center',
});
