import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  modal: false,
  loading: false,
  result: null,
  error: false
});

const feedbackModel = {
  name: 'canvasFeedback',
  state: initialState,
  reducers: {
    setModal(state, payload) {
      return state.merge({
        modal: payload
      });
    },
    setResult(state, payload) {
      return state.merge({
        result: payload
      });
    },
    setError(state, payload) {
      return state.merge({
        error: payload
      });
    },
    setLoading(state, payload) {
      return state.merge({
        loading: payload
      });
    }
  },
  effects: dispatch => ({
    async create(props, { application, authentication }) {
      try {
        const id = props.pipeline.id || 'new';
        const message = `Pipeline Id: ${id}
          PipelineName: ${props.pipeline.name}
          Issue description: ${props.message}`;
        const response = await api.feedback.create({
          realm: application.realm.realm,
          email: authentication.userData.email,
          path: window.location.pathname,
          message
        });
        const onSuccess = () =>
          dispatch.router.navigate({
            to: `/${application.realm.realm}/design/pipelines/${id}`
          });

        dispatch.canvasFeedback.setResult(response);

        if (id !== 'new') props.revert();

        dispatch.snackbar.create(
          props.isDirty
            ? {
                timeout: 10000,
                text: i18n.t(
                  'label.new_canvas_not_able_return_unsaved_pipeline_msg_alert'
                )
              }
            : {
                text: i18n.t('label.new_canvas_return_old_canvas_msg_success')
              }
        );

        if (!props.isDirty) onSuccess();
      } catch (e) {
        dispatch.canvasFeedback.setError(true);
      }
    }
  }),
  logics: [
    {
      type: ['canvasFeedback/create'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.canvasFeedback.setLoading(true);
        done();
      }
    },
    {
      type: ['canvasFeedback/setError'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.canvasFeedback.setLoading(false);
        done();
      }
    },
    {
      type: ['canvasFeedback/setResult'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.canvasFeedback.setLoading(false);
        dispatch.canvasFeedback.setModal(false);
        done();
      }
    }
  ]
};

export default feedbackModel;
