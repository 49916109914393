import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import * as Elements from './Editor.elements';

import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/mode/xml';
import 'brace/theme/textmate';
import './style.css';

const Editor = ({
  withBorder,
  label,
  value,
  className,
  danger,
  helperText,
  commands,
  ...props
}) => (
  <Elements.Wrapper withBorder={withBorder} className={className}>
    {label && <Elements.FieldLabel>{label}</Elements.FieldLabel>}
    <AceEditor
      height='100%'
      {...props}
      value={typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
      theme='textmate'
      editorProps={{ $blockScrolling: Infinity }}
      width='100%'
      commands={commands || []}
    />
    {helperText && (
      <Elements.HelperText danger={danger}>{helperText}</Elements.HelperText>
    )}
  </Elements.Wrapper>
);

Editor.defaultProps = {
  className: '',
  danger: false,
  helperText: '',
  commands: []
};

Editor.propTypes = {
  className: PropTypes.string,
  withBorder: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  danger: PropTypes.bool,
  helperText: PropTypes.string,
  commands: PropTypes.shape([{}])
};

export default Editor;
