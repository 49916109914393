import { Input } from '@digibee/beehive-ui';
import { useFormik } from 'formik';
import { FormEvent } from 'react';
// @ts-ignore
import * as yup from 'yup';

import * as Elements from './FormRealm.elements';
import { Props } from './FormRealm.enhancer';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';

const Schema = yup.object().shape({
  realm: yup.string().required('scenes.login.messages.error.required_field')
});

const FormRealm = ({ redirectToRealm }: Props) => {
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched
  } = useFormik({
    validationSchema: Schema,
    initialValues: {
      realm: '',
    },
    onSubmit: ({ realm }) => redirectToRealm(realm),
  });

  return (
    <Elements.StyledContainer
      data-testid='governance-prior-realm-input-realm-page'
      autoComplete='off'
      onSubmit={ev => {
        ev.preventDefault();
      }}
    >
      <Elements.StyledFields>
        <Input
          data-testid='governance-realm-input-realm-page'
          name='realm'
          value={values.realm}
          helperText={i18n.t(getFieldError(touched, errors, 'realm'))}
          invalid={Boolean(getFieldError(touched, errors, 'realm'))}
          onChange={handleChange}
          label={i18n.t('scenes.login.labels.realm')}
          placeholder={i18n.t('scenes.login.labels.realm')}
        />
      </Elements.StyledFields>
      <Elements.StyledFields>
        <Elements.SubmitButton
          data-testid='governance-continue-button-realm-page'
          key='save'
          variant='primary'
          onClick={(event) => handleSubmit(event as unknown as FormEvent<HTMLFormElement>)}
        >
          {i18n.t('action.continue')}
        </Elements.SubmitButton>
      </Elements.StyledFields>
    </Elements.StyledContainer>
  )
};

export default FormRealm;
