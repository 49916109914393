import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import styled, { css } from 'styled-components';

import { Chip } from '../Chip';
import List from '../List';
import Menu from '../Menu';

import Icon from '~/components/IconCommunity';

/**
|--------------------------------------------------
| Styles
|--------------------------------------------------
*/

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gra400};
  ${props =>
    props.focused
      ? css`
          background-color: ${p => p.theme.colors.gra25};
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
            0px 2px 2px 0px rgba(0, 0, 0, 0.07),
            0px 1px 5px 0px rgba(0, 0, 0, 0.06);
        `
      : css`
          background-color: ${p => p.theme.colors.white};
        `}
`;

const TagsContainer = styled.div`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
`;

const StyledListItem = styled(List.Item)`
  padding: 0 12px;
  font-size: 14px;
`;

const WrapperAction = styled.div`
  display: flex;
`;

const StyledInput = styled.input`
  border: none;
  background: transparent;
  font-size: 14px;
  height: 100%;
  padding: 0;
  width: calc(100% - 120px);
  outline: none;
  margin-left: 8px;
  color: ${props => props.theme.colors.gra900};
  font-family: ${props => props.theme.typography.fontFamily};
  ::placeholder {
    color: ${props => props.theme.colors.gra600};
    font-family: ${props => props.theme.typography.fontFamily};
  }
`;

StyledInput.defaultProps = {
  'data-testid': 'build-searchbar-input'
};

const SearchIcon = styled(Icon)`
  margin-left: 16px;
  margin-right: 8px;
`;

SearchIcon.defaultProps = {
  name: 'Search',
  faType: 'solid'
};

const ClearIcon = styled(Icon)`
  margin: 0 12px;
  width: 14px !important;
  font-size: 22.41px;
`;

ClearIcon.defaultProps = {
  name: 'Times'
};

const CaretIcon = styled(Icon)`
  margin: 0 12px;
  width: 10px !important;
`;

CaretIcon.defaultProps = {
  name: 'CaretDown',
  faType: 'solid'
};

const Action = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.gra25};
  }
`;

Action.defaultProps = {
  'data-testid': 'action'
};

const ActionClear = styled(Action)`
  visibility: hidden;
  ${props =>
    props.isValue &&
    css`
      visibility: visible;
    `};
`;

/**
|--------------------------------------------------
| Search
|--------------------------------------------------
*/

const useControlledState = (value, defaultValue) => {
  if (value === undefined) {
    const [state, setState] = useState(defaultValue);
    return [state, setState, false];
  }
  return [value, () => { }, true]; // just bypass
};

const Search = ({
  placeholder,
  tagsSource,
  tagsValue: tagsValueProp,
  textValue,
  onTextChange,
  onSearch,
  onTagsChange,
  onChange,
  zIndexValue,
  ...props
}) => {
  const ref = useRef(null);
  const refInput = useRef(null);
  const [tagsValue, setTagsValue] = useControlledState(tagsValueProp, []);
  const [inputValue, setInputValue] = useControlledState(textValue, '');
  const [focused, setFocused] = useState(false);

  const handleItemClick = (value, toggle) => () => {
    if (tagsValue.includes(value)) return;
    const newTagsValue = [...tagsValue, value];
    setTagsValue(newTagsValue);
    onTagsChange(newTagsValue);
    onChange(inputValue, newTagsValue);
    onSearch(inputValue, newTagsValue);
    if (tagsValue.length === tagsSource.length - 1) {
      toggle();
    }
  };

  const handleTagRemove = value => () => {
    const newTagsValue = tagsValue.filter(tagValue => tagValue !== value);
    setTagsValue(newTagsValue);
    onTagsChange(newTagsValue);
    onChange(inputValue, newTagsValue);
    onSearch(inputValue, newTagsValue);
  };

  const handleInputChange = ({ target }) => {
    onTextChange(target.value);
    setInputValue(target.value);
    onChange(target.value, tagsValue);
  };

  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      // Enter key
      onSearch(inputValue, tagsValue);
    }
  };

  const clearInput = () => {
    onTextChange('');
    setInputValue('');
    onChange('', tagsValue);
    onSearch('', tagsValue);
    refInput.current.focus();
  };

  useClickAway(ref, () => setFocused(false));

  return (
    <Menu
      {...props}
      fullWidth
      zIndexValue={zIndexValue}
      component={({ toggle }) => (
        <Container
          data-testid='build-search-container'
          innerRef={ref}
          focused={focused}
        >
          <SearchIcon />
          <TagsContainer>
            {tagsValue.map(tagValue => (
              <Chip
                isClose
                title={tagsSource.find(({ value }) => value === tagValue).label}
                onRemove={handleTagRemove(tagValue)}
                className='fs-unmask'
                data-testid='build-searchbar-result-archive-tag'
              />
            ))}
          </TagsContainer>
          <StyledInput
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            innerRef={refInput}
          />
          <WrapperAction>
            <ActionClear
              data-testid='build-clear-action'
              isValue={inputValue.length}
              onClick={clearInput}
            >
              <ClearIcon />
            </ActionClear>

            <Action
              data-testid='build-open-list-button'
              onClick={() => {
                setFocused(true);
                toggle();
              }}
            >
              <CaretIcon />
            </Action>
          </WrapperAction>
        </Container>
      )}
    >
      {({ toggle }) => (
        <List data-testid='build-searchbar-list-archive' inset noHairlines>
          {tagsSource.map(item => (
            <StyledListItem
              data-testid='build-searchbar-result-archive'
              onClick={handleItemClick(item.value, toggle)}
            >
              {item.label}
            </StyledListItem>
          ))}
        </List>
      )}
    </Menu>
  );
};

Search.defaultProps = {
  tagsValue: undefined,
  textValue: undefined,
  tagsSource: [],
  onChange: () => { },
  onTextChange: () => { },
  onTagsChange: () => { },
  onSearch: () => { },
  placeholder: '',
  'data-testid': 'build-searchbar',
  zIndexValue: 1000
};

Search.propTypes = {
  tagsValue: PropTypes.arrayOf(PropTypes.string),
  tagsSource: PropTypes.arrayOf({}),
  textValue: PropTypes.string,
  onChange: PropTypes.func,
  onTextChange: PropTypes.func,
  onTagsChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  'data-testid': PropTypes.string,
  zIndexValue: PropTypes.number
};

export default Search;
