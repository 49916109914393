/* eslint-disable */
import { normalize, schema } from 'normalizr';
import moment from 'moment';
import { head, isEmpty, uniqBy } from 'lodash';
import Immutable from 'seamless-immutable';

// Locals
import * as api from '~/api';
import clientApollo from '~/api/clientApollo';

const logsEntity = new schema.Entity('logs');
const logsSchema = { logs: [logsEntity] };

const initialState = Immutable({
  result: {
    logs: []
  },
  loading: {
    fetchLogs: false
  },
  current: {
    time: null,
    logIdAfter: null
  }
});

const logsModel = {
  name: 'logs',
  state: initialState,
  reducers: {
    setResult(state, { path, value }) {
      return state.merge({
        result: {
          [path]: [...state.result.logs, ...value]
        }
      });
    },
    setCurrent: (state, value) =>
      state.merge({
        current: {
          ...value
        }
      }),
    loading: (state, { path, value }) =>
      state.merge({
        loading: {
          [path]: value
        }
      }),
    reset: () => initialState
  },
  effects: dispatch => ({
    async fetchLogs(params, state) {
      try {
        const realm = state.application.realm.realm;
        const { logIdAfter, time } = state.logs.current;
        const { data } = await api.logs.fetchLogs({
          key: params.key,
          env: params.env,
          realm,
          logIdAfter,
          time
        });

        dispatch.logs.setResult({
          path: 'logs',
          value: data.logs ? data.logs : []
        });
      } catch (e) {
        // console.log(e);
      }
    }
  }),
  logics: [
    {
      type: 'logs/fetchLogs',
      latest: true,
      process(state, dispatch, done) {
        dispatch.logs.loading({ path: 'fetchLogs', value: true });
        done();
      }
    },
    {
      type: ['logs/setResult'],
      latest: true,
      process({ action, getState }, dispatch, done) {
        const { path, value } = action.payload;
        const byMaxTimeStamp = array => {
          if (!array) {
            return {
              timestamp: getState().logs.current.time,
              id: getState().logs.current.logIdAfter
            };
          }
          const maxTimeStamp = (a, b) =>
            new moment(b.timestamp) - new moment(a.timestamp);
          const sortedArray = array.sort(maxTimeStamp);
          return head(sortedArray)
            ? head(sortedArray)
            : {
                timestamp: getState().logs.current.time,
                id: getState().logs.current.logIdAfter
              };
        };
        const maxTimeStamp = byMaxTimeStamp(value);
        dispatch.logs.setCurrent({
          time: maxTimeStamp.timestamp,
          logIdAfter: maxTimeStamp.id
        });
        dispatch.logs.loading({ path: 'fetchLogs', value: false });

        done();
      }
    }
  ]
};
export default logsModel;
