import { theme, Text, Icon } from '@digibee/beehive-ui';

export const Container = theme.styled('div', {
  padding: '$4',
})

export const Footer = theme.styled('div', {
  display: 'flex',
  marginTop: '$5',
  gap: '$3'
})

/**
|--------------------------------------------------
| Card
|--------------------------------------------------
*/

export const Cards = theme.styled('div', {
  display: 'flex',
  marginTop: '$5',
  marginBottom: '$4'
})

export const Card = theme.styled('div', {
  padding: '20px',
  width: '45%',
  border: '1px solid $neu200',
  borderRadius: '$1'
})

export const Number = theme.styled(Text, {
  fontWeight: '$bold',
  fontSize: 64,
  color: '$pri500',
  textAlign: 'center',
  margin: '0 auto',
  marginTop: '$3',
  width: 100
})

export const CardIndicator = theme.styled('div', {
  width: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const CardIcon = theme.styled(Icon, {
  width: '35px',
  transform: 'rotate(180deg)',
})

/**
|--------------------------------------------------
| Success
|--------------------------------------------------
*/

export const Success = theme.styled('div', {
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '$5'
})

export const Illustration = theme.styled('img', {
  width: '300px',
  marginTop: '$5'
})