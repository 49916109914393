/* global window */

import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const controllers = [];

const convertJson = stringJson => {
  if (!stringJson) return {};
  return JSON.parse(stringJson);
};

const convertString = json => {
  if (!json) return '';
  return JSON.stringify(json, null, '  ');
};

const testLibrary = {
  execute: async params => {
    const controller = new window.AbortController();

    controllers.push(controller);

    try {
      const responseExecTest = await clientApollo.mutate({
        mutation: gql`
          mutation testLibrary(
            $realm: String!
            $canvas: CanvasInput!
            $payload: String!
            $trackingId: String!
            $inSpec: JSON
            $outSpec: JSON
            $params: JSON
            $accountLabels: JSON
          ) {
            testLibrary(
              realm: $realm
              canvas: $canvas
              payload: $payload
              trackingId: $trackingId
              params: $params
              inSpec: $inSpec
              outSpec: $outSpec
              accountLabels: $accountLabels
            )
          }
        `,
        variables: {
          ...params
        },
        context: { fetchOptions: { signal: controller.signal } }
      });
      const responseTestLibrary = convertJson(
        responseExecTest.data.testLibrary
      );
      if ([500, 409, 405].includes(responseTestLibrary.status)) {
        if (typeof responseTestLibrary.message === 'string')
          throw new Error(responseTestLibrary.message);
        throw new Error('Problem sending to test');
      }

      const { data } = JSON.parse(responseExecTest.data.testLibrary);

      return {
        digibeeKey: responseTestLibrary['X-Digibee-Key'],
        data: convertString(data)
      };
    } catch (e) {
      throw e;
    }
  },
  cancel: () => {
    controllers.map(controller => controller.abort());
  }
};
export default testLibrary;
