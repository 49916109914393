import {
  SelectAsync,
  SideSheet,
  Box,
  Button,
  Input,
  TextArea
} from '@digibee/beehive-ui';
import { FieldArray, useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
// @ts-ignore
import * as yup from 'yup';

import TableConfiguration from './components/TableConfiguration';
import { TypeCreateDeploymentPlan } from './types';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import ConfirmDialogV2 from '~/components/ConfirmDialogV2';

const FormGlobalsSchema = () =>
  yup.lazy(() =>
    yup.object().shape({
      name: yup
        .string()
        .required('scenes.globals.messages.error.required_field'),
      description: yup
        .string()
        .required('scenes.globals.messages.error.required_field'),
      deploymentPlanItems: yup
        .array()
        .min(1)
        .required('scenes.globals.messages.error.required_field')
    })
  );

const Create: React.FC<TypeCreateDeploymentPlan> = ({
  onClose,
  pipelineValue,
  loadPipelines,
  handleSelectPipe,
  loading,
  removeItem,
  saveData,
  loadingSave,
  maxQuantityPipes,
  pipelineInvalid
}) => {
  const navigate = useNavigate();

  const confirmDialog = ConfirmDialogV2.useConfirmDialog();

  const formik = useFormik({
    enableReinitialize: false,
    isInitialValid: false,
    initialValues: {
      name: '',
      description: '',
      deploymentPlanItems: []
    },
    validationSchema: FormGlobalsSchema(),
    onSubmit: (values: any) => {
      saveData(values);
    }
  });

  function submit(e: any) {
    e.preventDefault();

    confirmDialog.show({
      title: i18n.t('label.save_deployment_plan_prompt', {
        planName: formik.values.name
      }),
      content: i18n.t('label.save_deployment_plan_msg'),
      confirmText: i18n.t('action.save'),
      cancelText: i18n.t('action.cancel'),
      onConfirm: () => formik.handleSubmit()
    });
  }

  function checkError(): string {
    if (pipelineInvalid.length > 0) {
      return pipelineInvalid.map(i => `${i.value} - ${i.error}` || '').join();
    }

    if (
      pipelineValue?.length > 0 &&
      pipelineValue?.length === maxQuantityPipes
    ) {
      return i18n.t('label.maximum_number_of_pipelines');
    }

    return '';
  }

  return (
    <SideSheet
      open
      onClose={() => navigate(-1)}
      header={i18n.t('label.create_deployment_plan')}
      subHeader={i18n.t('label.create_deployment_plan_explanation')}
      data-testid='run-sidesheet-deployment'
      size='medium'
    >
      <form onSubmit={submit}>
        <SideSheet.Section title={i18n.t('label.deployment_plan_information')}>
          <Box css={{ marginBottom: '$3' }}>
            <Input
              label={i18n.t('label.deployment_plan_name')}
              id='name'
              name='name'
              onChange={formik.handleChange}
              value={formik?.values?.name}
              placeholder={i18n.t(
                'label.enter_deployment_plan_name_here_placeholder'
              )}
              css={{ marginBottom: '$2' }}
              helperText={i18n.t(
                getFieldError(formik.touched, formik.errors, 'name')
              )}
              invalid={!!getFieldError(formik.touched, formik.errors, 'name')}
            />
          </Box>
          <Box css={{ marginBottom: '$3' }}>
            <TextArea
              onChange={formik.handleChange}
              value={formik?.values?.description}
              // @ts-ignore
              label={i18n.t('label.description')}
              id='description'
              name='description'
              placeholder={i18n.t('label.enter_description_placeholder')}
              helperText={i18n.t(
                getFieldError(formik.touched, formik.errors, 'description')
              )}
              invalid={
                !!getFieldError(formik.touched, formik.errors, 'description')
              }
            />
          </Box>

          <SelectAsync
            isClearable
            cacheOptions
            data-testid='run-batch-deployment'
            variant='async-paginate'
            placeholder={i18n.t('label.select_pipelines_placeholder')}
            value={pipelineValue}
            // @ts-ignore
            loadOptions={loadPipelines || loading}
            // @ts-ignore
            onChange={(value, action) => {
              handleSelectPipe(value, action, formik);
            }}
            isMulti
            invalid={checkError() !== ''}
            helperText={checkError()}
            isDisabled={loading || pipelineValue.length === maxQuantityPipes}
            additional={{
              page: -1,
              searchPage: -1
            }}
            label={i18n.t(
              'scenes.metrics.labels.upper_and_capitalize.capitalized',
              {
                item: i18n.t('label.pipelines')
              }
            )}
            className='fs-mask'
          />
        </SideSheet.Section>

        {(formik.values?.deploymentPlanItems?.length > 0 || loading) && (
          <SideSheet.Section
            title={i18n.t('label.pipeline_specific_configuration_environ')}
            subTitle={i18n.t(
              'label.pipeline_specific_configuration_environ_explanation'
            )}
          >
            <FieldArray
              name='deploymentPlanItems'
              validateOnChange={false}
              render={() => (
                <TableConfiguration
                  loading={loading}
                  deploymentPlanItems={formik.values?.deploymentPlanItems || []}
                  deleteItem={removeItem}
                  formik={formik}
                />
              )}
            />
          </SideSheet.Section>
        )}

        <SideSheet.Footer>
          <Button
            disabled={!formik.isValid}
            // @ts-ignore
            type='submit'
            loading={loadingSave}
          >
            {i18n.t('action.save')}
          </Button>
          <Box css={{ marginLeft: '$4' }}>
            <Button
              outlined
              // @ts-ignore
              type='button'
              onClick={() => navigate(-1)}
            >
              {i18n.t('action.cancel')}
            </Button>
          </Box>
        </SideSheet.Footer>
      </form>
    </SideSheet>
  );
};

export default Create;
