import styled from 'styled-components';

import TextNative from '~/components/Text';

export const Text = styled(TextNative)``;

export const ContentBodyAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`;
