import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

// Locals
//
import RadioButtonIcon from './components/RadioButtonIcon';

import useDynamicState from '~/common/hooks/useDynamicState';
import useRadioGroup from '~/components/RadioGroup/useRadioGroup';

function createChainedFunction(...funcs) {
  const [onChangeRadio, onChangeRadioGroup] = funcs;
  return function chainedFunction(...args) {
    if (onChangeRadio) {
      onChangeRadio.apply(this, args);
    }
    if (onChangeRadioGroup) {
      onChangeRadioGroup.apply(this, args);
    }
  };
}

const RadioBaseStyle = styled.input`
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
`;

const RadioContent = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  position: relative;
`;

const RadioRoot = styled.span`
  width: 25px;
  height: 25px;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  background-color: transparent;
  tap-highlight-color: transparent;
  flex: 0 0 auto;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 8px;

  color: ${({ theme }) => `${theme.colors.gra600}`};
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.gra900}0A`};
  }

  ${({ checked, theme }) =>
    checked &&
    css`
      color: ${theme.colors.pri500};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`;
const Radio = React.forwardRef(
  ({
    autoFocus,
    onFocus,
    onBlur,
    onChange: onChangeProp,
    checked: checkedProp,
    defaultChecked,
    disabled,
    id,
    name: nameProp,
    type,
    ...restProps
  }) => {
    const initialValue = checkedProp || defaultChecked;

    const [checkedState] = useDynamicState(initialValue);

    const handleBlur = event => {
      if (onBlur) {
        onBlur(event);
      }
    };
    const handleFocus = event => {
      if (onFocus) {
        onFocus(event);
      }
    };
    const radioGroup = useRadioGroup();
    let checked = checkedState;
    let name = nameProp;

    const onChange = createChainedFunction(
      onChangeProp,
      radioGroup && radioGroup.onChange
    );
    if (radioGroup) {
      if (typeof checked === 'undefined') {
        checked = radioGroup.value === restProps.value;
      }
      if (typeof name === 'undefined') {
        name = radioGroup.name;
      }
    }
    const handleInputChange = event => {
      if (event.nativeEvent.defaultPrevented) {
        return;
      }
      const newChecked = event.target.checked;

      if (onChange) {
        onChange(event, newChecked);
      }
    };

    return (
      <RadioRoot
        disabled={disabled}
        onBlur={handleBlur}
        checked={checked}
        onFocus={handleFocus}
      >
        <RadioContent>
          <RadioBaseStyle
            disabled={disabled}
            defaultChecked={defaultChecked}
            type={type}
            autoFocus={autoFocus}
            checked={checked}
            onChange={handleInputChange}
            id={id}
            name={name}
            /* eslint-disable react/jsx-props-no-spreading */
            {...restProps}
          />
          <RadioButtonIcon checked={checked} disabled={disabled} />
        </RadioContent>
      </RadioRoot>
    );
  }
);

Radio.defaultProps = {
  autoFocus: false,
  onFocus: () => {},
  checked: false,
  defaultChecked: undefined,
  disabled: false,
  onChange: () => {},
  readOnly: false,
  type: '',
  value: '',
  name: '',
  id: '',
  onBlur: () => {}
};

Radio.propTypes = {
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  value: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func
};

export default Radio;
