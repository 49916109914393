import React from 'react';

import * as Elements from './CloseButton.elements';

const CloseButton = props => (
  <Elements.Button {...props}>
    <Elements.Close size={20} />
  </Elements.Button>
);

export default CloseButton;
