/* eslint-disable */
import { head } from 'lodash';

const normalizeError = {
  onGraphQL: error => {
    if (typeof error === 'string') {
      return error.replace('GraphQL error:', '');
    }
    if (error.length) {
      return head(error).message;
    }
    return error;
  }
};

export default normalizeError;
