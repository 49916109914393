import Immutable from 'seamless-immutable';

import { acls } from '~/api';

const initialState = Immutable({
  name: '',
  active: false,
  roles: [],
  loading: {
    fetch: false
  },
  error: {
    scope: null
  }
});

const scope = {
  name: 'scope',
  state: initialState,
  reducers: {
    setScope(state, scopes) {
      return state.merge({ ...scopes });
    },
    loading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    }
  },
  effects: dispatch => ({
    async fetch(payload, rootState) {
      dispatch.acls.loading(true);
      try {
        const { realm } = rootState.application.realm;
        const aclScope = await acls.getScope({ realm });
        dispatch.scope.setScope(aclScope);
      } catch (error) {
        dispatch.scope.setError({ path: 'scope', value: error.message });
      }
    }
  }),
  logics: [
    {
      type: 'application/findRealms',
      latest: true,
      process(context, dispatch, done) {
        dispatch.scope.fetch();
        done();
      }
    },
    {
      type: 'scope/fetch',
      latest: true,
      process(context, dispatch, done) {
        dispatch.scope.loading({
          path: 'fetch',
          value: true
        });
        done();
      }
    },
    {
      type: ['scope/setError', 'scope/setScope'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.scope.loading({
          path: 'fetch',
          value: false
        });
        done();
      }
    }
  ]
};

export default scope;
