import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { withTheme } from 'styled-components';

import * as Elements from './OldStatusDeploy.elements';
import { getMessageStatus } from './StatusDeploy';

import i18n from '~/common/helpers/i18n';
import Badge from '~/components/Badge';
import IconNative from '~/components/IconCommunity';
import Text from '~/components/Text';

type Props = {
  deployment: object;
  disabled?: boolean;
  isMarginBottom?: boolean;
  isPipelineEngine?: boolean;
};

type DataMessageType = {
  loader?: boolean;
  text: string;
  icon: IconProp;
  style: string;
};

const OldStatusDeploy = ({
  deployment,
  isMarginBottom = true,
  isPipelineEngine
}: Props) => {
  if (!isPipelineEngine) {
    const dataMessage: DataMessageType = getMessageStatus(deployment as any);

    return (
      <Elements.ContainerStatus isMarginBottom={isMarginBottom}>
        <Badge type={dataMessage.style}>{dataMessage.text}</Badge>
      </Elements.ContainerStatus>
    );
  }

  return (
    <Elements.ContainerStatus>
      <Elements.ContainerIcon>
        <Elements.ContainerElement>
          <IconNative
            name='Gears'
            isNotification={undefined}
            withHtml={undefined}
            faType={undefined}
            complement={undefined}
          />
        </Elements.ContainerElement>
        <Text as='span' align='left' size='sm' type='black'>
          {i18n.t('label.engine_version_tag')}
        </Text>
      </Elements.ContainerIcon>
      <Elements.ContainerBadge>
        <Badge type='primary'>{i18n.t('label.beta')}</Badge>
      </Elements.ContainerBadge>
    </Elements.ContainerStatus>
  );
};

export default withTheme(OldStatusDeploy);
