import queryString from 'querystring';

import { omit, identity, pickBy } from 'lodash';
import moment from 'moment';
import { normalize, schema } from 'normalizr';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';
// Locals

const normalizeData = ({ entity, data }) => {
  const Schema = new schema.Entity(entity, {}, { idAttribute: 'key' });
  const mySchema = [Schema];
  return normalize(data, mySchema);
};

const getPropsSearch = () => {
  const params = queryString.parse(window.location.search.replace('?', ''));
  const STRING_EMPTY = '';
  const TIME_TYPE_DEFAUT = '5 Minutes';
  const SPECIFIC_DATE_FROM_DEFAULT = moment().seconds(0).milliseconds(0);
  return {
    pipelineName: params.pipelineName || STRING_EMPTY,
    key: params.key || STRING_EMPTY,
    time: params.time || TIME_TYPE_DEFAUT,
    desc: true,
    specific: {
      dateStart: moment(params.dateFrom) || SPECIFIC_DATE_FROM_DEFAULT,
      dateEnd: moment(params.dateTo) || SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const transformFormatDate = (values, params) =>
  moment().subtract(values, params).toISOString();

const initialState = Immutable({
  result: {
    inflightTransactions: []
  },
  pagination: {
    page: 0,
    count: 0,
    pageSize: 10
  },
  entities: {
    inflightTransactions: {}
  },
  loading: {
    fetch: false,
    more: false
  },
  success: {
    fetch: null
  },
  last: true,
  error: {
    fetch: null
  },
  modal: {
    reexecution: false,
    logsDetails: false,
    pipeline: null
  },
  search: getPropsSearch()
});

const inflightTransactions = {
  name: 'inflightTransactions',
  state: initialState,
  reducers: {
    set(state, payload) {
      return state.merge({ ...payload }, { deep: true });
    },
    loading(state, { path, value }) {
      return state.merge(
        {
          loading: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    success(state, { path, value }) {
      return state.merge({
        success: {
          [path]: value
        }
      });
    },
    setLast(state, payload) {
      return state.merge({
        last: payload
      });
    },
    open(state, { path, value }) {
      return state.merge(
        {
          modal: {
            [path]: value
          }
        },
        {
          deep: true
        }
      );
    },
    setModalPipeline(state, payload) {
      return state.merge(
        {
          modal: {
            pipeline: payload
          }
        },
        { deep: true }
      );
    },
    setSearch(state, payload) {
      return state.merge({
        search: payload
      });
    },
    clearError(state) {
      return state.merge(
        {
          error: {
            fetch: null
          }
        },
        { deep: true }
      );
    },
    clearMessage(state) {
      return state.merge({
        result: {
          inflightTransactions: []
        },
        entities: {
          inflightTransactions: {}
        }
      });
    },
    clearSearch(state) {
      return state.merge(
        {
          search: getPropsSearch()
        },
        { deep: true }
      );
    }
  },
  effects: dispatch => ({
    async fetch(params, rootState) {
      try {
        const { realm } = rootState.application.realm;
        const mapTimes = {
          '5 Minutes': {
            dateStart: transformFormatDate(5, 'minutes'),
            dateEnd: moment().toISOString()
          },
          '15 Minutes': {
            dateStart: transformFormatDate(15, 'minutes'),
            dateEnd: moment().toISOString()
          },
          '1 Hour': {
            dateStart: transformFormatDate(1, 'hour'),
            dateEnd: moment().toISOString()
          }
        };
        const getMapTimes = (type = '5 Minutes', payload) =>
          mapTimes[type] || {
            dateFrom: moment(payload.dateFrom)
              .seconds(0)
              .milliseconds(0)
              .toISOString(),
            dateTo: moment(payload.dateTo)
              .seconds(59)
              .milliseconds(999)
              .toISOString()
          };
        const paramsSearch = params.isQueryString
          ? getPropsSearch()
          : params?.search;
        const specific = getMapTimes(
          paramsSearch?.time,
          paramsSearch?.specific
        );
        const search = {
          ...omit(
            pickBy({ ...paramsSearch, ...specific }, identity),
            'specific'
          )
        };
        dispatch.router.navigate({
          pathname: window.location.pathname,
          search: `?${createSearchParams(search)}`,
          options: { replace: true }
        });
        const response = await api.inflightTransactions.fetch({
          realm,
          ...search,
          environment: params.environment,
          ...specific
        });
        const inflightTransactionsData = normalizeData({
          entity: 'inflightTransactions',
          data: response?.data?.inflightTransactions?.result
        });
        dispatch.inflightTransactions.set(inflightTransactionsData, {
          ...search,
          specific
        });
      } catch (error) {
        dispatch.inflightTransactions.setError({
          path: 'fetch',
          value: error.message
        });
      }
    },
    async delete(params, rootState) {
      try {
        const { realm } = rootState.application.realm;
        const response = await api.inflightTransactions.delete({
          realm,
          environment: params.environment,
          key: params.key
        });
        toast.success(i18n.t('message.success.cancel_transaction'));
        dispatch.inflightTransactions.fetch({
          environment: params.environment,
          isQueryString: true
        });
        return response.data;
      } catch (error) {
        dispatch.inflightTransactions.setError({
          path: 'delete',
          value: error.message
        });
        toast.error(i18n.t('message.error.cancel_transaction'));
        return error;
      }
    }
  }),
  logics: [
    {
      type: 'inflightTransactions/fetch',
      latest: true,
      process(context, dispatch, done) {
        dispatch.inflightTransactions.loading({
          path: 'fetch',
          value: true
        });
        dispatch.inflightTransactions.clearError();
        done();
      }
    },
    {
      type: 'inflightTransactions/set',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.inflightTransactions.setSearch(meta);
        dispatch.inflightTransactions.loading({
          path: 'more',
          value: false
        });
        dispatch.inflightTransactions.loading({
          path: 'fetch',
          value: false
        });
        done();
      }
    },
    {
      type: 'inflightTransactions/setError',
      latest: true,
      process({ action }, dispatch, done) {
        if (['fetch'].includes(action?.payload?.path)) {
          dispatch.inflightTransactions.loading({
            path: action?.payload.path,
            value: false
          });
          dispatch.inflightTransactions.clearMessage();
        }
        done();
      }
    },
    {
      type: 'inflightTransactions/open',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.inflightTransactions.setModalPipeline(meta);
        done();
      }
    }
  ]
};

export default inflightTransactions;
