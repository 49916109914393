import { useSelector, useDispatch } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';

type StoreRootState = {
  application: {
    realm: {
      realm: string;
    };
    hasCustomerIdentityProvider: any;
  };
  authentication: { userData: any };
  realms: {
    result: { realms: any };
    entities: { realms: any };
  };
};

const enhancer = withHooks((props: any) => {
  const { authentication, application, realms } = useSelector<
    any,
    StoreRootState
  >(state => state);
  const dispatch = useDispatch<any>();
  const state = {
    allIds: realms.result.realms,
    byId: realms.entities.realms,
    user: authentication?.userData,
    activeRealm: application.realm,
    hasCustomerIdentityProvider: application.hasCustomerIdentityProvider
  };
  const actions = {
    setModalTwoFactor: dispatch.profile.setModalTwoFactor,
    setModalNewPassword: dispatch.profile.setModalNewPassword,
    onSignOut: dispatch.authentication.signOut
  };

  return {
    ...props,
    ...state,
    ...actions
  };
});

export default enhancer;
