/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const insights = {
  find: ({ dateTo, dateFrom, environment, realm, pipelineName, pipelineMajorVersion, pipelineMinorVersion }) => {
    return clientApollo.query({
      query: gql`
        query insights(
          $dateTo: String!
          $dateFrom: String!
          $environment: String!
          $realm: String!
          $pipelineName: String!
          $pipelineMajorVersion: Int!
          $pipelineMinorVersion: Int!
        ) {
          insights(
            dateTo: $dateTo
            dateFrom: $dateFrom
            environment: $environment
            realm: $realm
            pipelineName: $pipelineName
            pipelineMajorVersion: $pipelineMajorVersion
            pipelineMinorVersion: $pipelineMinorVersion
          ) {
            Insights
          }
        }
      `,
      variables: {
        dateTo,
        dateFrom,
        environment,
        realm,
        pipelineName,
        pipelineMajorVersion,
        pipelineMinorVersion,
      },
    });
  },
};

export default insights;
