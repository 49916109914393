import omit from 'lodash/omit';
import Immutable from 'seamless-immutable';

import { globals } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

// Locals


const initialState = Immutable({
  result: {
    globals: []
  },
  globals: [],
  entities: {
    globals: {}
  },
  loading: {
    globals: false
  },
  error: null,
  status: [],
  search: '',
  environment: '',
  isGlobalAssociate: true,
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    global: {}
  }
});

const globalsModel = {
  name: 'globals',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      });
    },
    setEntity(state, data) {
      return state.merge({
        ...state,
        globals: data
      });
    },
    setIsGlobalAssociate(state, data) {
      return state.merge({
        ...state,
        isGlobalAssociate: data
      });
    },
    setError: (state, error) => state.merge({ error }),
    setModal(state, payload) {
      return state.merge(
        {
          modal: {
            ...state.modal,
            ...payload
          }
        },
        { deep: true }
      );
    },
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    }
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const result = await globals.find(
          Object.assign(params, {
            realm: state.application.realm.realm,
            search: state.globals.search
          })
        );

        dispatch.globals.setEntity(result.data?.globals);
      } catch (e) {
        dispatch.globals.setError(e?.message);
      }
    },
    async findPaginated(params = {}, state) {
      try {
        const result = await globals.findPaginated(
          Object.assign(params, {
            realm: state.application.realm.realm,
            field: state.globals.search,
          })
        );
        dispatch.globals.setIsGlobalAssociate(true)
        dispatch.globals.setEntity(result.data?.globalsPaginated);
      } catch (e) {
        dispatch.globals.setError(e?.message);
      }
    },
    async create({ params, props }, state) {
      try {
        dispatch.globals.setModal({ loading: true });
        const response = await globals.create({
          realm: state?.application?.realm?.realm,
          global: params
        });
        dispatch.globals.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'label.global_created_msg_success',
                {
                  globalName: params?.field
                }
              ),
              success: true,
              open: true
            }
          }
        );
        props?.resetForm?.();
      } catch (e) {
        dispatch.globals.setActionModal(
          { error: e },
          {
            snackbar: {
              text: i18n.t(
                'label.couldnt_create_global_msg_alert',
                {
                  globalName: params?.field
                }
              ),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async edit({ params, props }, state) {
      try {
        dispatch.globals.setModal({ loading: true });
        const response = await globals.edit({
          realm: state?.application?.realm?.realm,
          global: omit(params, 'id'),
          id: state?.globals?.modal?.global?.id || params.id
        });
        dispatch.globals.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t('label.edit_global_msg_success', {globalName: state?.globals?.modal?.global?.field || params.field}),
              success: true,
              open: true
            }
          }
        );
        props?.resetForm?.();
      } catch (e) {
        dispatch.globals.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async remove(params, state) {
      try {
        const remove = await globals.remove({
          realm: state.application.realm.realm,
          id: params?.id || params
        });
        dispatch.globals.setActionModal(
          {
            success: remove
          },
          {
            snackbar: {
              text: i18n.t('label.deleted_global_msg_success', {globalName: params.field}),
              success: true,
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.globals.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: ['globals/find', 'globals/remove', 'globals/findPaginated'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.globals.setLoading({ path: 'globals', value: true });
        done();
      }
    },
    {
      type: ['globals/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { search, isGlobalAssociate } = context.getState().globals;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.globals.setModal({
            visible: false,
            loading: false,
            success: true,
            global: null
          });
          dispatch.globals.setLoading({ path: 'globals', value: false });
          if(isGlobalAssociate) {
            dispatch.globals.findPaginated({ page: 0, search });
            return
          }
          dispatch.globals.find({ search });
        } else {
          dispatch.globals.setModal({ loading: false, success: false });
          dispatch.globals.setLoading({ path: 'globals', value: false });
        }
        done();
      }
    },
    {
      type: ['globals/setError', 'globals/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.globals.setLoading({ path: 'globals', value: false });
        done();
      }
    }
  ]
};

export default globalsModel;
