import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const eventHoverAndMove = props => css`
  ${props.isShow &&
  css`
    box-shadow: 0px 0px 0px 14px ${props.theme.colors.pri500}16;
  `}
`;

const Container = styled.span`
  label: Thumb;
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  outline: 0;
  background-color: currentColor;
  box-sizing: border-box;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: currentColor;

  &::before {
    position: absolute;
    content: '  ';
    border-radius: inherit;
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }

  $::after {
    position: absolute;
    content: ' ';
    border-radius: 50%;
    width: 42;
    height: 42;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${props =>
    props.size === 'sm' &&
    css`
      width: 12px;
      height: 12px;
    `}

  ${props =>
    props.orientation === 'horizontal' &&
    css`
      top: 50%;
      transform: translate(-50%, -50%);
    `};

  ${props =>
    props.orientation === 'vertical' &&
    css`
      left: 50%;
      transform: translate(-50%, 50%);
    `};

  ${eventHoverAndMove};
`;

const Thumb = ({ children, ...props }) => (
  <Container
    /* eslint-disable react/jsx-props-no-spreading */
    {...props}
  >
    {children}
  </Container>
);

Thumb.defaultProps = {
  orientation: 'horizontal',
  size: 'md',
  children: null,
  isShow: false,
  marked: false
};

Thumb.propTypes = {
  orientation: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.element,
  isShow: PropTypes.bool,
  marked: PropTypes.bool
};

export default Thumb;
