/* eslint-disable */
import React from 'react';

// eslint-disable-next-line
const pick = (obj, arr) =>
  arr.reduce((acc, curr) => (curr in obj && (acc[curr] = obj[curr]), acc), {});

const withHooks =
  (mapHooksToProps, { frozenProps = [] } = {}) =>
  WrappedComponent =>
  props => {
    const overrideablePropsObj = mapHooksToProps(props);
    const frozenPropsObj = pick(overrideablePropsObj, frozenProps);

    const newProps = {
      ...props,
      ...overrideablePropsObj,
      ...frozenPropsObj
    };

    return React.createElement(WrappedComponent, newProps);
  };

export default withHooks;
