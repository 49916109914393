import { Button, Dialog, Box, Text } from '@digibee/beehive-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import storage from '~/common/helpers/storage';

const RealmAccessDenied = () => {

  const { t } = useTranslation();
  const [RealmAccessDenied, setRealmAccessDenied] = useState(storage.get('RealmAccessDenied'));
  const handleClick = () => {
    setRealmAccessDenied(null);
    storage.remove('RealmAccessDenied');
  }

  return (
    <Dialog.Root open={!!RealmAccessDenied}>
      <Dialog.Content data-testid='confirm-dialog-realm-access-denied'>
        <Box css={{padding: '18px', display: 'flex', flexDirection: 'column', gap: '18px'}}>
          <Text as='h1' variant='h4'>
            {t('label.you_dont_have_access_realm_prompt', {realmName: RealmAccessDenied})}
          </Text>
          <Dialog.Body>
            <Text as='p' variant='b2'>
                {t('label.you_dont_have_access_realm_msg')}
            </Text>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              size='medium'
              variant='primary'
              onClick={() => handleClick()}
              data-testid='confirm-dialog-realm-access-denied-confirm-button'
            >
              {t('action.ok')}
            </Button>
          </Dialog.Footer>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};


export default RealmAccessDenied;
