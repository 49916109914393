import { useDispatch } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';


export type Props = {
  returnToSignIn: StoreDispatcher['forgotPassword']['returnToSignIn'],
}

const enhancer = withHooks((outProps: object) => {
  const dispatch = useDispatch<StoreDispatcher>();

  const props: Props = {
    returnToSignIn: dispatch.forgotPassword.returnToSignIn,
  }

  return {
    ...outProps,
    ...props,
  }
});

export default enhancer;
