import { Box, Card, Text } from '@digibee/beehive-ui';
import { choose, when, otherwise } from '@digibee/control-statements';
import React from 'react';

import * as Elements from './CardLicense.elements';

import i18n from '~/common/helpers/i18n';

export type LicenseStatusType = {
  licenseModel: string;
  totalAvailableLicenses?: number;
  pipelineSubscriptionAndRtu: {
    pipelineSubscriptionProd: {
      totalAmountOfPipelineSubscriptionAllowedProd: number;
      pipelineSubscriptionUsageProd: number;
      pipelineSubscriptionAddProd: number;
      availableAmountOfPipelineSubscriptionProd: number;
    };
    pipelineSubscriptionTest: {
      totalAmountOfPipelineSubscriptionAllowedTest: number;
      pipelineSubscriptionUsageTest: number;
      pipelineSubscriptionAddTest: number;
      availableAmountOfPipelineSubscriptionTest: number;
    };
    rtuProd: {
      totalRtuAllowedProd: number;
      totalRtuUsageProd: number;
      totalRtuAvailableProd: number;
      totalRtuAddProd: number;
    };
    rtuTest: {
      totalRtuAllowedTest: number;
      totalRtuUsageTest: number;
      totalRtuAvailableTest: number;
      totalRtuAddTest: number;
    };
  };
};

type Props = {
  licenses: number;
  licenseStatus: LicenseStatusType;
  environment: string;
  isRedeploy: boolean;
};

function CardLicense({
  licenseStatus,
  environment,
  licenses,
  isRedeploy
}: Props) {
  return (
    <>
      {choose(
        when(licenseStatus?.licenseModel === 'PIPELINE', () => (
          <>
            <Box
              css={{
                top: '$2',
                bottom: '$2',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              className='fs-mask'
            >
              <Text
                css={{
                  color: '$neu400',
                  fontWeight: 'lighter'
                }}
                variant='b3'
              >
                {i18n.t('scenes.runtime.labels.total_realm_licenses_alert')}
              </Text>
              <Text
                css={{
                  color: '$neu400',
                  fontWeight: 'lighter'
                }}
                variant='b3'
              >
                {licenseStatus?.totalAvailableLicenses}
              </Text>
            </Box>

            <Box
              css={{
                bottom: '$2',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              className='fs-mask'
            >
              <Text
                css={{
                  color: '$neu400',
                  fontWeight: 'lighter'
                }}
                variant='b3'
              >
                {isRedeploy
                  ? i18n.t('label.redeploy_licenses_alert')
                  : i18n.t('scenes.runtime.labels.deploy_licenses_alert')}
              </Text>
              <Text
                css={{
                  color: '$neu400',
                  fontWeight: 'lighter'
                }}
                variant='b3'
              >
                {licenses}
              </Text>
            </Box>

            <Elements.Separator />

            <Box
              css={{
                bottom: '$4',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              className='fs-mask'
            >
              <Text
                css={{
                  color: '$neu400',
                  fontWeight: 'lighter'
                }}
                variant='b3'
              >
                {isRedeploy
                  ? i18n.t('label.licenses_available_after_redeploy')
                  : i18n.t('scenes.runtime.labels.available_licenses_deploy')}
              </Text>
              <Text
                css={{
                  color: '$neu400',
                  fontWeight: 'lighter'
                }}
                variant='b3'
              >
                {(licenseStatus?.totalAvailableLicenses || 0) - licenses}
              </Text>
            </Box>
          </>
        )),
        otherwise(() => (
          <>
            <Card.Root>
              <Box css={{ padding: '$3' }}>
                <Box
                  css={{ paddingBottom: '$1', alignItems: 'center' }}
                  className='fs-mask'
                >
                  <Text css={{ paddingBottom: '$1' }}>
                    {i18n.t(
                      environment === 'prod'
                        ? 'label.prod_pipeline_subscription'
                        : 'label.test_pipeline_subscription'
                    )}
                  </Text>
                </Box>
                <Box css={{ marginBottom: '$2' }}>
                  <Elements.CardContent className='fs-mask'>
                    <Elements.Info>
                      <Text>
                        {environment === 'prod'
                          ? licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionProd
                              .totalAmountOfPipelineSubscriptionAllowedProd
                          : licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionTest
                              .totalAmountOfPipelineSubscriptionAllowedTest}
                      </Text>
                      <Text css={{ fontSize: '$1' }}>
                        {i18n.t('label.licensed')}
                      </Text>
                    </Elements.Info>

                    <Elements.Operator>-</Elements.Operator>

                    <Elements.Info>
                      <Text>
                        {environment === 'prod'
                          ? licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionProd
                              .pipelineSubscriptionUsageProd
                          : licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionTest
                              .pipelineSubscriptionUsageTest}
                      </Text>
                      <Text css={{ fontSize: '$1' }}>
                        {i18n.t('label.in_use')}
                      </Text>
                    </Elements.Info>

                    <Elements.Operator>-</Elements.Operator>

                    <Elements.Info>
                      <Text>
                        {environment === 'prod'
                          ? licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionProd
                              .pipelineSubscriptionAddProd
                          : licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionTest
                              .pipelineSubscriptionAddTest}
                      </Text>
                      <Text css={{ fontSize: '$1' }}>
                        {i18n.t('label.used_in_this_deploy')}
                      </Text>
                    </Elements.Info>

                    <Elements.Operator>=</Elements.Operator>

                    <Elements.Info total>
                      <Text>
                        {environment === 'prod'
                          ? licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionProd
                              .availableAmountOfPipelineSubscriptionProd
                          : licenseStatus?.pipelineSubscriptionAndRtu
                              .pipelineSubscriptionTest
                              .availableAmountOfPipelineSubscriptionTest}
                      </Text>
                      <Text css={{ fontSize: '$1' }}>
                        {i18n.t('label.available_after_deploy')}
                      </Text>
                    </Elements.Info>
                  </Elements.CardContent>
                </Box>
                <Elements.Separator />

                <Box
                  css={{ paddingBottom: '$1', paddingTop: '$2' }}
                  className='fs-mask'
                >
                  <Text css={{ top: '$2' }}>
                    {i18n.t(
                      environment === 'prod'
                        ? 'label.prod_runtime_unit'
                        : 'label.test_runtime_unit'
                    )}
                  </Text>
                </Box>

                <Elements.CardContent className='fs-mask'>
                  <Elements.Info>
                    <Text>
                      {environment === 'prod'
                        ? licenseStatus?.pipelineSubscriptionAndRtu.rtuProd
                            .totalRtuAllowedProd
                        : licenseStatus?.pipelineSubscriptionAndRtu.rtuTest
                            .totalRtuAllowedTest}
                    </Text>
                    <Text css={{ fontSize: '$1' }}>
                      {i18n.t('label.licensed')}
                    </Text>
                  </Elements.Info>

                  <Elements.Operator>-</Elements.Operator>

                  <Elements.Info>
                    <Text>
                      {environment === 'prod'
                        ? licenseStatus?.pipelineSubscriptionAndRtu.rtuProd
                            .totalRtuUsageProd
                        : licenseStatus?.pipelineSubscriptionAndRtu.rtuTest
                            .totalRtuUsageTest}
                    </Text>
                    <Text css={{ fontSize: '$1' }}>
                      {i18n.t('label.in_use')}
                    </Text>
                  </Elements.Info>

                  <Elements.Operator>-</Elements.Operator>

                  <Elements.Info>
                    <Text>
                      {environment === 'prod'
                        ? licenseStatus?.pipelineSubscriptionAndRtu.rtuProd
                            .totalRtuAddProd
                        : licenseStatus?.pipelineSubscriptionAndRtu.rtuTest
                            .totalRtuAddTest}
                    </Text>
                    <Text css={{ fontSize: '$1' }}>
                      {i18n.t('label.used_in_this_deploy')}
                    </Text>
                  </Elements.Info>

                  <Elements.Operator>=</Elements.Operator>

                  <Elements.Info total>
                    <Text>
                      {environment === 'prod'
                        ? licenseStatus?.pipelineSubscriptionAndRtu.rtuProd
                            .totalRtuAvailableProd
                        : licenseStatus?.pipelineSubscriptionAndRtu.rtuTest
                            .totalRtuAvailableTest}
                    </Text>
                    <Text css={{ fontSize: '$1' }}>
                      {i18n.t('label.available_after_deploy')}
                    </Text>
                  </Elements.Info>
                </Elements.CardContent>
              </Box>
            </Card.Root>
          </>
        ))
      )}
    </>
  );
}

export default CardLicense;
