import head from 'lodash/head';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import withHooks from '~/common/hoc/withHooks';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

const enhancer = withHooks(props => {
  const location = useLocation();
  const { acceptanceTerm, realms, application, authentication, acls } =
    useSelector(state => state);
  const dispatch = useDispatch();
  const { MONITOR_SETDEFAULTENVIRONMENT_FRONT } = featureFlagConstants;
  const { treatments } = useFeatureFlag([MONITOR_SETDEFAULTENVIRONMENT_FRONT]);

  const enviromentDefault =
    treatments[MONITOR_SETDEFAULTENVIRONMENT_FRONT].treatment;

  function getEnvriomentByFeatureFlag(allEnviroment) {
    return allEnviroment.find(env => env === enviromentDefault);
  }

  function getEnvriomentByFeatureFlagRun(allEnviroment) {
    return allEnviroment.find(env => env === 'test');
  }

  const state = {
    allIds: realms?.result?.realms || [],
    byId: realms.entities.realms,
    activeRealm: application?.realm,
    user: authentication?.userData,
    scopes: acls?.scopes,
    environments: application?.environments?.allNames,
    eula: acceptanceTerm?.result
  };
  const actions = {
    setModalTwoFactor: dispatch.profile.setModalTwoFactor,
    setModalNewPassword: dispatch.profile.setModalNewPassword,
    onSignOut: dispatch.authentication.signOut,
    containPaths: paths =>
      paths?.reduce((result, path) => {
        if (!result) return location.pathname.indexOf(path) > -1;

        return result;
      }, false),
    goToDefaultLayout: () => {
      dispatch.router.navigate({ to: `/${state.activeRealm.realm}/pipelines` });
    },
    pathNameRuntime: () => {
      const envsWithAcl = (acc, current) => {
        const defaultAcl = ['GROUP:ADMIN', 'DEPLOYMENT:READ'];
        const acl = `DEPLOYMENT:READ{ENV=${current.toUpperCase()}}`;
        const roles = [...defaultAcl, acl];
        const isRole = state.scopes.find(r => roles.includes(r));

        if (isRole) return [...acc, current];

        return acc;
      };
      const envsAcl = state.environments.reduce(envsWithAcl, []);

      const newEnvironmentDefault = getEnvriomentByFeatureFlagRun(envsAcl);

      if (envsAcl.length > 0 && newEnvironmentDefault)
        return head([newEnvironmentDefault]);

      return head(state.environments);
    },
    goToSettings: () => {
      dispatch.router.navigate({
        to: `/${state.activeRealm.realm}/settings/globals`
      });
    },
    goToAdmin: () => {
      dispatch.router.navigate({
        to: `/${state.activeRealm.realm}/settings/beta/user`
      });
    },
    goToConnectivity: () => {
      dispatch.router.navigate({
        to: `/${state.activeRealm.realm}/connectivity/edge-routers`
      });
    },
    getEnvriomentByFeatureFlag
  };

  return {
    ...props,
    ...state,
    ...actions
  };
});

export default enhancer;
