/* eslint-disable */

import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import IconNative from '~/components/IconCommunity';

const Icon = styled(IconNative)`
  margin-right: ${props =>
    ['sm', 'xs'].includes(props.size) ? '6px' : '12px'};

  ${props =>
    !props.extended &&
    css`
      margin-right: 0px;
    `};
  font-size: 1.2em;
  color: currentColor;
`;

const StyledButton = styled(Button)`
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  position: absolute;
  padding: ${props =>
    ['sm', 'xs'].includes(props.size)
      ? '0px 10px 0px 8px;'
      : '0px 20px 0px 16px;'};
  font-size: ${props => (['sm', 'xs'].includes(props.size) ? '12px' : '14px')};
  ${props =>
    !props.extended &&
    css`
      padding: 0;
    `}

  &:hover {
    background-color: ${props => props.theme.colors.pri300};
  }

  ${({ extended }) =>
    extended === false &&
    css`
      border-radius: 50%;
    `}

  ${props =>
    props.position === 'center' &&
    css`
      margin: 0;
      top: 50%;
      right: 50%;
    `}
  ${props =>
    props.position === 'center-right' &&
    css`
      margin-top: 0;
      margin-right: 16px;
      top: 50%;
      right: 16px;
    `}
  ${props =>
    props.position === 'bottom-left' &&
    css`
      bottom: 16px;
      left: 16px;
    `}
  ${props =>
    props.position === 'center-left' &&
    css`
      margin-top: 0;
      top: 50%;
      left: 16px;
    `}
  ${props =>
    props.position === 'top-right' &&
    css`
      margin-right: 16px;
      top: 16px;
      right: 16px;
    `}
  ${props =>
    props.position === 'bottom-right' &&
    css`
      margin-right: 16px;
      bottom: 16px;
      right: 16px;
    `}
  ${props =>
    props.position === 'top-left' &&
    css`
      top: 16px;
      left: 16px;
    `};
`;

const Fab = ({ children, icon, ...restProps }) => (
  <StyledButton {...restProps} rounded={true}>
    <Icon name={icon} extended={restProps.extended} />
    {children}
  </StyledButton>
);

Fab.propTypes = {
  children: PropTypes.element,
  position: PropTypes.string,
  icon: PropTypes.string
};

Fab.defaultProps = {
  position: 'bottom-right',
  icon: 'Plus'
};

export default Fab;
