import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import type { StoreDispatcher } from '../../types/StoreDispatcher';
import type { StoreRootState } from '../../types/StoreRootState';

import i18n from '~/common/helpers/i18n';
import withHooks from '~/common/hoc/withHooks';
import ConfirmDialog from '~/components/ConfirmDialogV2';

export type EulaProps = {
  open: StoreRootState['eula']['modal'],
  loading: StoreRootState['eula']['loading'],
  checked: StoreRootState['eula']['checked'],
  setChecked: StoreDispatcher['eula']['setChecked'],
  setModal: StoreDispatcher['eula']['setModal'],
  onAccept: StoreDispatcher['eula']['onAccept'],
  onRefuse: StoreDispatcher['eula']['onRefuse'],
  onPostpone: StoreDispatcher['eula']['onPostpone'],
  onTryagain: StoreDispatcher['eula']['onTryagain'],
  onCancel: () => void,
  data: StoreRootState['acceptanceTerm']['result'],
};

const enhancer = withHooks(() => {
  const dispatch = useDispatch<StoreDispatcher>();
  const { acceptanceTerm } = useSelector((state: StoreRootState) => state);

  const open = useSelector((state: StoreRootState) => state.eula.modal);
  const loading = useSelector((state: StoreRootState) => state.eula.loading);
  const checked = useSelector((state: StoreRootState) => state.eula.checked);

  const confirmDialog = ConfirmDialog.useConfirmDialog();

  const setModal = (isOpen: boolean) => {
    dispatch.eula.setModal(isOpen);
  };

  const onCancel = () => {
    dispatch.eula.setModal(false);
  };

  const onRefuse = () => {
    confirmDialog.show({
      title: i18n.t('scenes.eula.labels.confirm_refuse_term'),
      content: i18n.t('scenes.eula.labels.support_message_refuse'),
      confirmText: i18n.t('scenes.eula.actions.confirm'),
      cancelText: i18n.t('scenes.eula.actions.back'),
      danger: false,
      onConfirm: () => dispatch.eula.onRefuse(),
    });
  }

  const onPostpone = () => {
    dispatch.eula.onPostpone();
  }

  const onAccept = () => {
    confirmDialog.show({
      title: i18n.t('scenes.eula.labels.term_accepted'),
      content: i18n.t('scenes.eula.labels.support_message_acceptance'),
      confirmText: i18n.t('scenes.eula.actions.confirm'),
      cancelText: i18n.t('scenes.eula.actions.back'),
      danger: false,
      onConfirm: () => dispatch.eula.onAccept(),
    });
  }

  const onTryagain = () => {
    dispatch.eula.onTryagain();
  }

  const setChecked = (isChecked: boolean) => {
    dispatch.eula.setChecked(isChecked);
  };

  useEffect(() => {
    dispatch.eula.setChecked(Boolean(acceptanceTerm?.result?.acceptanceDate));
  }, [dispatch]);

  return {
    open,
    loading,
    checked,
    setModal,
    onCancel,
    onRefuse,
    onPostpone,
    onAccept,
    onTryagain,
    setChecked,
    data: acceptanceTerm?.result,
  };
});

export default enhancer;
