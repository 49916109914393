import * as Elements from './Upsell.elements';
import { useTranslation } from 'react-i18next';

import {
  DataGrid,
  Text,
  Skeleton,
  Button,
  Dialog,
  Empty
} from '@digibee/beehive-ui';
// @ts-ignore
import successImage from '~/common/images/empty-state-list.svg';

import type { Props } from './Upsell.enhancer';

const Upsell = ({proposalUpsell, success, loading, dictionary, setSuccess}: Props) => {
  const [t] = useTranslation();
  return (
    <Elements.Container>
      {!success && 
        <>
          <Text
            as="h1"
            variant="h4"
            css={{ marginBottom: '$2' }}
          >
            {t('label.runtime_units_rtus_parenthesis')}
          </Text>
          <Text
            as="p"
            variant="b2"
          >
            {t('label.upgrade_solicitation_rtus_explanation_msg')}
          </Text>
          <Elements.Cards>
            <Elements.Card data-testid='licensing-upsell-actual-rtu-card'>
              <Text variant='h3' css={{ textAlign: 'center', fontSize: '$3', fontWeight: '$medium' }}>
                {t('label.current')}
              </Text>
              <Elements.Number data-testid='licensing-upsell-actual-rtu-card-number'>
                {loading && <Skeleton sizes='medium' variant="rectangular" />}
                {!loading && proposalUpsell?.rtu}
              </Elements.Number>

              <Text
                as='p'
                variant='b2'
                css={{
                  lineHeight: '$1',
                  color: '$gra200',
                  marginTop: '$5',
                  textAlign: 'center'
                }}>
                {t('label.runtime_units_rtus_parenthesis')}
              </Text>
            </Elements.Card>
            <Elements.CardIndicator>
              <Elements.CardIcon
                icon="arrow-circle-left"
                variant="primary"
                size='lg'
                disabled />
            </Elements.CardIndicator>
            <Elements.Card data-testid='licensing-upsell-proposal-rtu-card'>
              <Text variant='h3' css={{ textAlign: 'center', fontSize: '$3', fontWeight: '$medium' }}>
                {t('label.suggestion')}
              </Text>
              <Elements.Number data-testid='licensing-upsell-proposal-rtu-card-number'>
                {loading && <Skeleton sizes='medium' variant="rectangular" />}
                {!loading && proposalUpsell?.proposalRtu}
              </Elements.Number>
              <Text
                as='p'
                variant='b2'
                css={{
                  lineHeight: '$1',
                  color: '$gra200',
                  marginTop: '$5',
                  textAlign: 'center'
                }}>
                {t('label.runtime_units_rtus_parenthesis')}
              </Text>
            </Elements.Card>
          </Elements.Cards>
          <DataGrid
            loading={loading}
            data-testid='licensing-upsell-table'
            data={proposalUpsell?.realmLimits || []}
            columns={[
              {
                id: 'resource_name',
                Header: t('label.resource_name'),
                accessor: 'name',
                Cell: ({ value }: { value: string; }) => <>{dictionary(value)}</>
              },
              {
                id: 'quota',
                Header: t('label.limit'),
                accessor: 'myLimit',
                Cell: ({ value }: { value: string | number; }) => value ?
                  <>
                    {Number(value).toLocaleString() === 'NaN' ?
                      value :
                      Number(value).toLocaleString()}
                  </> :
                  '-'
              },
              {
                id: 'unit_of_measurement',
                Header: t('label.unit_of_measurement'),
                accessor: 'unitOfMeasure',
                Cell: ({ value }: { value: string; }) => <>{dictionary(value)}</>
              }
            ]} 
          />
          <Elements.Footer>
            <Button variant="primary" onClick={() => setSuccess(true)}>
              {t('action.send_suggestion')}
            </Button>
            <Dialog.Close asChild>
              <Button variant="primary" outlined>
                {t('action.return_two')}
              </Button>
            </Dialog.Close>
          </Elements.Footer>
        </>
      }

      {success && 
        <Elements.Success>
          <Elements.Illustration src={successImage}/>
          <Text 
          variant='h3' 
          css={{ 
            marginTop: '$4', textAlign: 'center', fontWeight: '$medium' 
          }}>
            {t('label.successfully_sent')}
          </Text>

          <Text
            as='p'
            css={{
              lineHeight: '$1',
              color: '$gra200',
              marginTop: '$4',
              paddingLeft: '$10',
              paddingRight: '$10',
              textAlign: 'center',
              fontWeight: '400',
              fontSize: '$3',
              marginBottom: '$5',
            }}>
            {t('label.upgrade_suggestion_successfully_sent_msg_success')}
          </Text>

          <Dialog.Close asChild>
            <Button size='medium' variant="primary">
              {t('action.back')}
            </Button>
          </Dialog.Close>
        </Elements.Success>
      }
      
    </Elements.Container>
  )
}

export default Upsell;
