import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import * as Elements from './OldStatusHeader.elements';

import i18n from '~/common/helpers/i18n';
import Version from '~/components/Version';
import { OldStatusDeploy } from '~/scenes/Runtime/components/StatusDeploy';

const OldStatusHeader = ({ deployment, disabled, theme }) => (
  <Elements.Container>
    <Elements.Row>
      <Version type='secondary' as='span' size='sm'>
        {`${i18n.t('scenes.runtime.labels.version')} ${
          deployment?.pipeline.versionMajor
        }.${deployment?.pipeline?.versionMinor}`}
      </Version>
      <OldStatusDeploy
        deployment={deployment}
        theme={theme}
        disabled={disabled}
        isMarginBottom={false}
        isPipelineEngine={
          deployment?.deploymentStatus?.pipelineEngineBeta === 'true'
        }
      />
    </Elements.Row>
  </Elements.Container>
);

OldStatusHeader.defaultProps = {
  theme: {}
};

OldStatusHeader.propTypes = {
  deployment: PropTypes.objectOf({
    pipeline: PropTypes.objectOf({
      id: PropTypes.string,
      name: PropTypes.string,
      parameterizedReplica: PropTypes.string,
      versionMajor: PropTypes.number,
      versionMinor: PropTypes.number
    }),
    startTime: PropTypes.string,
    errorCount: PropTypes.string,
    oomCount: PropTypes.string,
    status: PropTypes.objectOf({})
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.objectOf({})
};

export default withTheme(OldStatusHeader);
