import {
  DataGrid,
  Page,
  IconButton,
  Icon,
  Avatar as AvatarV2,
  Text
} from '@digibee/beehive-ui';
import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';

import * as Elements from './TableHistory.elements';
import { HistoryDeploymentContext } from '../../../context/HistoryDeploymentContext';
import { Deployments } from '../../../types';

import i18n from '~/common/helpers/i18n';
import { Pagination } from '~/common/types/Pagination';
import { OldStatusDeployV2 } from '~/scenes/Runtime/components/StatusDeploy';

const VALUE_SLICE_USER = 4;

const CellCustom = ({ value }: { value: string }) => (
  <Text
    as='p'
    css={{
      _truncate: '$12'
    }}
  >
    {value}
  </Text>
);

const CellCustomPipeline = ({ row }: any) => (
  <Text as='p'>
    {`${row?.original?.pipelineVersionMajor}.${row?.original?.pipelineVersionMinor}`}
  </Text>
);

const CellCustomStatus = ({ row }: any) => (
  <OldStatusDeployV2
    // @ts-ignore
    deployment={{
      status: {
        text: row?.original?.lastStatus
      }
    }}
  />
);

const CellCustomDeployed = ({ row }: any) => (
  <>
    <Elements.RowAvatar>
      {row?.original?.deployedBy.slice(0, VALUE_SLICE_USER).map((item: any) => (
        <Elements.ContainerAvatar key={item.name}>
          <AvatarV2 size='xsmall' text={item.email} type='char' />
        </Elements.ContainerAvatar>
      ))}
      {row?.original?.deployedBy?.length > VALUE_SLICE_USER && (
        <Elements.ContainerAvatar>
          <AvatarV2
            size='xsmall'
            text={`+ ${
              row?.original?.deployedBy?.slice(
                VALUE_SLICE_USER,
                row?.original?.deployedBy?.length
              ).length
            }`}
            type='count'
          />
        </Elements.ContainerAvatar>
      )}
    </Elements.RowAvatar>
  </>
);

type Props = {
  deployments: Pagination<Deployments> | undefined;
  changePage: Function;
  loading: boolean;
};

const TableHistory: React.FC<Props> = ({
  deployments,
  changePage,
  loading
}) => {
  const navigate = useNavigate();
  const { selectDeployment } = useContext(HistoryDeploymentContext);

  const CellCustomActions = ({ row }: any) => (
    <IconButton
      onClick={() => {
        selectDeployment(row?.original);
        navigate('detail');
      }}
    >
      <Icon variant='primary' icon='angle-right' />
    </IconButton>
  );

  const columnsMemo = useMemo(
    () =>
      [
        {
          id: 'pipelineName',
          Header: i18n.t('label.pipeline_name_vers_two'),
          accessor: 'pipelineName',
          Cell: CellCustom
        },
        {
          id: 'projectName',
          Header: i18n.t('label.project_name'),
          accessor: 'projectName',
          Cell: CellCustom
        },
        {
          id: 'pipelineVersionMajor',
          Header: i18n.t('label.major_version'),
          accessor: 'pipelineVersionMajor',
          Cell: CellCustomPipeline
        },
        {
          id: 'lastStatus',
          Header: i18n.t('label.latest_status'),
          accessor: 'lastStatus',
          Cell: CellCustomStatus
        },
        {
          id: 'deployedBy',
          Header: i18n.t('label.deployed_by'),
          accessor: 'deployedBy',
          Cell: CellCustomDeployed
        },
        {
          dataTestId: 'table_actions',
          id: 'actions',
          Cell: CellCustomActions
        }
      ] as Column<{}>[],
    []
  );

  return (
    <DataGrid
      data-testid='history'
      columns={columnsMemo}
      data={deployments?.content || []}
      totalElements={deployments?.totalElements}
      // @ts-ignore
      fetchData={(e: Page) => {
        if (deployments?.number !== e.pageIndex) {
          changePage(e.pageIndex);
        }
      }}
      pageCount={deployments?.totalPages}
      page={0}
      loading={loading}
      rowsPerPageOptions={[20]}
      isPagination
      manualPagination
      initialState={{
        pageIndex: 0,
        pageSize: 20
      }}
      showRowsPerPage={false}
    />
  );
};

export default TableHistory;
