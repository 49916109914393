import styled from 'styled-components';

import ellipsis from '~/common/styled/mixins/ellipsis';
import IconNative from '~/components/IconCommunity';
import TextNative from '~/components/Text';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gra200};
`;

export const Icon = styled(IconNative)`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  cursor: pointer;
  visibility: visible;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex;
  margin: 8px 16px;
`;

export const EndPointText = styled(TextNative)`
  margin-left: 8px;
`;

export const Text = styled(TextNative)`
  ${ellipsis('98%')}
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.gra200};
`;

export const EndPoint = styled.div`
  display: flex;
  margin: 4px 2px;
  ${ellipsis('96%')}
`;
export const ContainerEndpoint = styled.div`
  display: flex;
  &:hover ${Icon} {
    visibility: visible;
  }
`;

export const EndpointSeparator = styled.div`
  margin-bottom: 16px;
`;
