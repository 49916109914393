/* eslint-disable */
import Immutable from 'immutable';
import { isEmpty, omit, pick } from 'lodash';

export function transformNode(node) {
  if (node.id === 'start-track') {
    node.data = {
      type: 'start-track'
    };
  }
  const scope =
    typeof !!node.data && typeof node.data === 'string'
      ? JSON.parse(node.data)
      : node.data;

  if (node.data.type === 'transformer') {
    node.data.transformSpec = JSON.stringify(node.data.transformSpec, null, 2);
  }

  if (node.data.type === 'validator') {
    node.data.validationSpec = JSON.stringify(
      node.data.validationSpec,
      null,
      2
    );
  }
  if (isEmpty(scope)) {
    return Object.assign({}, node, {
      data: {
        ...omit(node, 'data')
      },
      group: 'nodes'
    });
  }

  if (scope.key) return node;

  const onProcessTrack = scope.onProcessTrack
    ? { onProcessTrack: transformCanvas(scope.onProcessTrack).toJS() }
    : {};

  const onExceptionTrack = scope.onExceptionTrack
    ? { onExceptionTrack: transformCanvas(scope.onExceptionTrack).toJS() }
    : {};

  const data = Object.assign(
    {},
    omit(scope, ['blank', 'uuid']),
    pick(node, 'id'),
    {
      key: node.type || scope.type,
      stepName: scope.stepName
    },
    onExceptionTrack,
    onProcessTrack
  );
  const response = Object.assign({}, node, {
    data,
    group: 'nodes'
  });
  return response;
}

export function transformEdge({ data, ...edge }) {
  if (typeof edge.group === 'string') return { ...edge, data };

  return Object.assign({}, edge, {
    data: { ...edge, data },
    group: 'edges'
  });
}

export function transformCanvas(canvas) {
  // TODO: Utilizar empty canvas universal.
  const { edges, nodes } = Object.assign(
    {
      edges: [],
      nodes: []
    },
    canvas
  );

  const response = Immutable.Map({
    nodes: Immutable.List(nodes.map(transformNode)),
    edges: Immutable.List(edges.map(transformEdge))
  });

  return response;
}

export default transformCanvas;
