import { isApolloError, gql } from '@apollo/client';
import axios from 'axios';

import clientApollo from './clientApollo';
import { URL } from './variables';

import { API_KEY } from '~/common/helpers/environment';

type LogsExportInput = {
  realm: string;
  environment: string;
  pipelineKey: string;
  separator?: string;
  token?: string;
};

const logsExports = {
  getExportLogFile: async ({
    realm,
    environment,
    pipelineKey,
    token,
    separator = ';'
  }: LogsExportInput) => {
    try {
      const { data } = await axios.get<Blob>(
        `${URL}/runtime/realms/${realm}/logs/export?environment=${environment}&pipelineKey=${pipelineKey}&separator=${encodeURIComponent(
          separator
        )}`,
        {
          headers: {
            authorization: token,
            apikey: API_KEY
          }
        }
      );

      return data;
    } catch (error) {
      if (error instanceof Error) throw Error(error.message);
      throw error;
    }
  },
  countedLogs: async ({ realm, environment, pipelineKey }: LogsExportInput) => {
    try {
      const result = await clientApollo.query({
        query: gql`
          query countedLogs(
            $realm: String!
            $environment: String!
            $pipelineKey: String!
          ) {
            countedLogs(
              realm: $realm
              environment: $environment
              pipelineKey: $pipelineKey
            )
          }
        `,
        variables: {
          realm,
          environment,
          pipelineKey
        }
      });

      return result.data.countedLogs;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default logsExports;
