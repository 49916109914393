import { useRef } from 'react';

import { BOTTOM, LEFT, RIGHT, TOP } from '~/common/helpers/constants';

const calcAlignLeft = ({ left, width }) => {
  const WIDTH_MAXIMO_POSITION = 0;
  return left - width > WIDTH_MAXIMO_POSITION ? LEFT : RIGHT;
};

const calcAlignRight = ({ left, width }) => {
  const WIDTH_MAXIMO_POSITION = 60;
  return window.innerWidth - (left + width) > WIDTH_MAXIMO_POSITION
    ? RIGHT
    : LEFT;
};
const calcAlingCenter = ({ left, width }) => {
  const WIDTH_MAXIMO_POSITION = 0;
  return left - width > WIDTH_MAXIMO_POSITION ? LEFT : RIGHT;
};

const calcAling = {
  left: calcAlignLeft,
  center: calcAlingCenter,
  right: calcAlignRight
};

const calcPositionTop = ({ top }) => {
  const TOP_MAXIMO_POSITION = 90;
  return top > TOP_MAXIMO_POSITION ? TOP : BOTTOM;
};
const calcPositionBottom = ({ top, height, bottom }) => {
  const BOTTOM_MAXIMO_POSITION = 0;
  return window.innerHeight - (bottom + height) <= BOTTOM_MAXIMO_POSITION
    ? TOP
    : BOTTOM;
};

const getPosition = {
  [TOP]: calcPositionTop,
  [BOTTOM]: calcPositionBottom,
  [LEFT]: calcAlignLeft,
  [RIGHT]: calcAlignRight
};

function useCalcPosition({ align, position, forcePosition }) {
  const ref = useRef(null);
  const client = ref.current?.getBoundingClientRect();
  if (!forcePosition && client) {
    return [
      ref,
      {
        align: calcAling[align](client),
        position: getPosition[position](client)
      }
    ];
  }
  return [ref, { align, position }];
}

export default useCalcPosition;
