import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

export const ButtonToolbarContext = React.createContext({
  toolbarExists: false,
  spacing: false
});

const StyledButtonToolbar = styled.div`
  align-items: center;
  display: inline-flex;

  ${props =>
    props.fluid === true
      ? css`
          display: flex;
        `
      : ''}
`;

const ButtonToolbar = ({ children, spacing = 10, ...rest }) => (
  <ButtonToolbarContext.Provider value={{ toolbarExists: true, spacing }}>
    <StyledButtonToolbar spacing={spacing} {...rest}>
      {children}
    </StyledButtonToolbar>
  </ButtonToolbarContext.Provider>
);

ButtonToolbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string]).isRequired,

  fluid: PropTypes.bool,

  spacing: PropTypes.number
};
ButtonToolbar.defaultProps = {
  fluid: false,
  spacing: 10
};

export default ButtonToolbar;
