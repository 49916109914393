import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withHooks from '~/common/hoc/withHooks';

const mapStateToProps = ({ authentication, application }) => ({
  loading: authentication.loading,
  error: authentication.error,
  subdomain: application.subdomain,
  realmParams: authentication.realmParams
});

const mapDispatchToProps = ({ authentication }) => ({
  signin: authentication.signin,
  getNotLoggedPlatformParameter: authentication.getNotLoggedPlatformParameter
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHooks(props => {
    useEffect(() => {
      if (props?.subdomain) {
        props.getNotLoggedPlatformParameter({realm: props?.subdomain, param: 'hasCustomerIdentityProvider'});
        props.getNotLoggedPlatformParameter({realm: props?.subdomain, param: 'disabledForgotPassword'});
      }
    }, [props?.subdomain]);

    const actions = {};

    return {
      ...props,
      ...actions
    };
  })
);

export default enhancer;
