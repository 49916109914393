import moment from 'moment';

import i18n from '~/common/helpers/i18n';
import { Deployment } from '~/entities/Deployment';

const isValidationMultipleErrors = (deployment: Deployment) => {
  if (!deployment) return false;
  return (
    (Number(deployment?.errorCount) > 0 && Number(deployment?.oomCount) > 0) ||
    (deployment?.pipelineUpdateProject && Number(deployment?.errorCount) > 0) ||
    (deployment?.pipelineUpdateProject && Number(deployment?.oomCount) > 0)
  );
};

const checkDateError = (deployment: Deployment, quantityDays: number) => {
  if (deployment?.lastError || deployment?.lastOMM) {
    const today = moment();
    const dateLastError = moment(
      deployment?.lastError || deployment?.lastOMM,
      'x'
    );
    const dataDaysLater = dateLastError.clone().add(quantityDays, 'days');
    if (today.isSameOrAfter(dataDaysLater)) {
      return true;
    }
  }
  return false;
};

export function checkErrorDeployment(
  isDeleting: boolean,
  isAlert: boolean,
  disabled: boolean,
  status: string,
  deployment: Deployment,
  quantityDays: number
) {
  if (isDeleting) {
    return {
      type: 'warning',
      text: i18n.t('label.pipeline_being_deleted_generic_msg_warning')
    };
  }

  if (deployment.metadata?.errorOutdatedAccount) {
    return {
      type: 'warning',
      text: i18n.t('label.update_accounts_redeploy_needed_msg_warning')
    };
  }

  if (deployment.metadata?.errorOutdatedGlobal) {
    return {
      type: 'warning',
      text: i18n.t('label.update_globals_redeploy_needed_msg_warning')
    };
  }

  const isDataLater = checkDateError(deployment, quantityDays);
  if (isDataLater && !deployment?.pipelineUpdateProject) {
    return {
      type: 'success',
      text: `${i18n.t('label.pipeline_deployed_msg_success')}`
    };
  }

  if (disabled) {
    return {
      type: 'infoWarn',
      text: `${i18n.t('label.pipeline_being_deployed_msg_info')}`
    };
  }

  if (isAlert) {
    if (deployment?.errorCount) {
      return {
        type: 'hybrid',
        text: `${i18n.t(
          'label.pipeline_has_errors_issues_generic_mixed_warning_error_msg'
        )} ${i18n.t('label.learn_more_here_generic')}`
      };
    }

    return {
      type: status === 'Error' ? 'danger' : 'warning',
      text: `${i18n.t(
        'label.pipeline_has_errors_issues_generic_mixed_warning_error_msg'
      )} ${i18n.t('label.learn_more_here_generic')}`
    };
  }

  if (isValidationMultipleErrors(deployment)) {
    return {
      type: 'hybrid',
      text: `${i18n.t(
        'label.pipeline_has_errors_issues_generic_mixed_warning_error_msg'
      )} ${i18n.t('label.learn_more_here_generic')}`
    };
  }

  if (Number(deployment?.oomCount) > 0) {
    return {
      type: 'warning',
      text: `${i18n.t('label.pipeline_has_issue_generic_msg_warning')} ${i18n.t(
        'label.learn_more_here_generic'
      )}`
    };
  }

  if (deployment?.pipelineUpdateProject) {
    return {
      type: 'warning',
      text: `${i18n.t('label.pipeline_has_issue_generic_msg_warning')} ${i18n.t(
        'label.learn_more_here_generic'
      )}`
    };
  }

  return {
    type: 'success',
    text: `${i18n.t('label.pipeline_deployed_msg_success')}`
  };
}
