import { theme } from '@digibee/beehive-ui';

export const Wrapper = theme.styled('div', {
  fontFamily: '$display',
  'input': {
    borderWidth: '1px',
    borderColor: 'unset',
  },
  'span': {
    color: '$neu600'
  },
  '&:focus-within': {
    'input': {
      border: '2px solid $pri500'
    },
    'span': {
      color: '$pri500'
    }
  },
  '&[data-danger="true"]': {
    'input': {
      borderWidth: '2px',
      borderColor: '$red500',
    },
    'span': {
      color: '$red500'
    },
    '&:focus-within': {
      'input': {
        border: '2px solid $red500'
      },
      'span': {
        color: '$red500'
      }
    }
  }
});

export const InputWrapper = theme.styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '8px'
});

export const Input = theme.styled('input', {
  minWidth: '40px',
  height: '40px',
  textAlign: 'center',
  textTransform: 'uppercase',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: 'transparent',
  outline: '0',
  boxSizing: 'border-box',
  border: 'solid 1px $neu600',
  '&::before,&::after': {
    boxSizing: 'border-box',
  }
});

export const HelperText = theme.styled('span', {
  position: 'relative',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '24px',
  boxSizing: 'border-box'
});
