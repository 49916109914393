import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import { consumers } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

// Locals

const normalizeData = ({ entity, data, id = 'id' }) => {
  const Entity = new schema.Entity(entity, {}, { idAttribute: id });
  const Schema = { [entity]: [Entity] };
  return normalize(data, Schema);
};

const initConsumer = {
  id: '',
  consumer: '',
  acls: []
};

const initialState = Immutable({
  result: {
    consumers: [],
    pipelines: []
  },
  entities: {
    consumers: {},
    pipelines: {}
  },
  loading: {
    consumers: false
  },
  error: null,
  status: [],
  search: '',
  environment: '',
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    consumer: initConsumer
  },
  modalApiKeys: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    consumer: initConsumer
  }
});

const consumersModel = {
  name: 'consumers',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      });
    },
    setEntity(state, { result, entities }) {
      return state.merge({
        ...state,
        result: {
          ...state.result,
          consumers: result?.consumers
        },
        entities: {
          ...state.entities,
          consumers: entities?.consumers
        }
      });
    },
    setEnvironment(state, environment) {
      return state.merge({ ...state, environment });
    },
    setAcls(state, { result, entities }) {
      return state.merge({
        ...state,
        result: {
          ...state.result,
          pipelines: result?.pipelines
        },
        entities: {
          ...state.entities,
          pipelines: entities?.pipelines
        }
      });
    },
    setError: (state, error) => state.merge({ error }),
    setModal(state, payload) {
      return state.merge(
        {
          modal: {
            ...state.modal,
            ...payload
          }
        },
        { deep: true }
      );
    },
    setModalApiKeys(state, payload) {
      return state.merge(
        {
          modalApiKeys: {
            ...state.modalApiKeys,
            ...payload
          }
        },
        { deep: true }
      );
    },
    setActionModalApiKeys: (state, payload) =>
      state.merge({
        modalApiKeys: {
          ...state.modalApiKeys,
          ...payload
        }
      }),
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    },
    reset: () => initialState
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const result = await consumers.find(
          Object.assign(params, {
            realm: state.application.realm.realm,
            environment: state.consumers.environment,
            search: state.consumers.search
          })
        );
        const data = normalizeData({
          entity: 'consumers',
          data: result.data
        });
        dispatch.consumers.setEntity(data);
      } catch (e) {
        dispatch.consumers.setError(e);
      }
    },
    async findAcls(params = {}, state) {
      try {
        const result = await consumers.findAcls(
          Object.assign(params, {
            realm: state.application.realm.realm
          })
        );
        const data = normalizeData({
          entity: 'pipelines',
          data: result.data,
          id: 'name'
        });
        dispatch.consumers.setAcls(data);
      } catch (e) {
        dispatch.consumers.setError(e);
      }
    },
    async create({ params, props }, state) {
      try {
        dispatch.consumers.setModal({ loading: true });
        const response = await consumers.create({
          realm: state.application.realm.realm,
          environment: state.consumers.environment,
          acls: params?.acls,
          consumer: params.consumer,
          description: params.description
        });
        dispatch.consumers.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_create_api_key'
              ),
              success: true,
              open: true
            }
          }
        );
        props.resetForm();
      } catch (e) {
        dispatch.consumers.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async createApiKey(id, state) {
      try {
        dispatch.consumers.setModalApiKeys({ loading: true });
        const response = await consumers.createApiKey({
          realm: state.application.realm.realm,
          environment: state.consumers.environment,
          consumer: id
        });
        dispatch.consumers.setActionModalApiKeys(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_create_api_key'
              ),
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.consumers.setActionModalApiKeys(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async edit(params, state) {
      try {
        dispatch.consumers.setModal({ loading: true });
        const response = await consumers.edit({
          id: params?.id,
          realm: state.application.realm.realm,
          environment: state.consumers.environment,
          acls: params?.acls,
          consumer: params.consumer,
          description: params.description
        });
        dispatch.consumers.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_edit_api_key'
              )
            }
          }
        );
      } catch (e) {
        dispatch.consumers.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async remove(params, state) {
      try {
        const remove = await consumers.remove({
          realm: state.application.realm.realm,
          environment: state.consumers.environment,
          consumerId: params.customId
        });
        dispatch.consumers.setActionModal(
          {
            success: remove
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_archive_api_key'
              )
            }
          }
        );
      } catch (e) {
        dispatch.consumers.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async removeApiKey(params, state) {
      try {
        const remove = await consumers.removeApiKey({
          realm: state?.application?.realm?.realm,
          environment: state?.consumers?.environment,
          consumer: params?.customId,
          apikeyId: params.apikeyId
        });
        dispatch.consumers.setActionModalApiKeys(
          {
            success: remove
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_delete_api_key_value'
              )
            }
          }
        );
      } catch (e) {
        dispatch.consumers.setActionModalApiKeys(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: 'consumers/find',
      latest: true,
      process(context, dispatch, done) {
        dispatch.consumers.setLoading({ path: 'consumers', value: true });
        done();
      }
    },
    {
      type: 'consumers/findAcls',
      latest: true,
      process(context, dispatch, done) {
        dispatch.consumers.setLoading({ path: 'pipelines', value: true });
        done();
      }
    },
    {
      type: ['consumers/setAcls'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.consumers.setLoading({ path: 'pipelines', value: false });
        done();
      }
    },
    {
      type: ['consumers/setError', 'consumers/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.consumers.setLoading({ path: 'consumers', value: false });
        done();
      }
    },
    {
      type: ['consumers/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { search, environment } = context.getState().consumers;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.consumers.setModal({
            visible: false,
            loading: false,
            success: true,
            consumer: initConsumer
          });
          dispatch.consumers.find({ search, environment });
        } else {
          dispatch.consumers.setModal({ loading: false, success: false });
        }
        done();
      }
    },
    {
      type: ['consumers/setActionModalApiKeys'],
      latest: true,
      process(context, dispatch, done) {
        const { search, environment } = context.getState().consumers;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.consumers.setModalApiKeys({ loading: false, success: true });
          dispatch.consumers.find({ search, environment });
        } else {
          dispatch.consumers.setModalApiKeys({
            loading: false,
            success: false
          });
        }
        done();
      }
    }
  ]
};

export default consumersModel;
