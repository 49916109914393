import { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Text, Dialog, Button, TextArea } from '@digibee/beehive-ui';
import i18n from '~/common/helpers/i18n';
import getFieldError from '~/common/helpers/getFieldError';
// @ts-ignore
import * as yup from 'yup';

interface FeedbackProps {
  sendFeedback: (values: { message: string }) => void;
  setModal: (modal: { path: string; value: boolean }) => void;
  onCancel: () => void;
  open: boolean;
  loading: boolean;
}

const Schema = yup.object().shape({
  message: yup
    .string()
    .required('scenes.feedback.messages.error.required_field')
});

const Feedback: FunctionComponent<FeedbackProps> = ({ sendFeedback, open, onCancel, setModal, loading }) => (
  <Formik
    initialValues={{ message: '' }}
    validationSchema={Schema}
    onSubmit={(values, { setSubmitting }) => {
      sendFeedback(values);
      setSubmitting(false);
    }}
  >
    {({ handleSubmit, values, setFieldValue, touched, errors, resetForm }) => {
      useEffect(() => {
        if (open) {
          resetForm();
        }
      }, [open, resetForm]);

      return (
        <Dialog.Root open={open} onOpenChange={(open) => !open && onCancel()} data-testid='send-feedback-dialog'>
          <Dialog.Content style={{ padding: '16px', width: '50rem', height: '22rem', maxHeight: '50em' }}>
            <Dialog.Header>
              <Text variant='b1' as='h4' style={{ fontWeight: 'bold' }}>
                {i18n.t('scenes.feedback.actions.send_feedback')}
              </Text>
            </Dialog.Header>
            <Dialog.Body style={{ overflow: 'inherit', marginTop: '2px' }}>
              <Text variant='b2'>
                {i18n.t('scenes.feedback.guidance.support_text')}
              </Text>
              <Field
                component={TextArea}
                name="message"
                placeholder={i18n.t('scenes.feedback.guidance.write_comment')}
                value={values.message}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setFieldValue('message', e.target.value)}
                style={{ height: '174px', paddingTop: '14px', marginTop: '15px', resize: 'none'}}
                data-testid='send-feedback-text-area'
                danger={i18n.t(getFieldError(touched, errors, 'message'))}
                invalid={Boolean(getFieldError(touched, errors, 'message'))}
                helperText={i18n.t(getFieldError(touched, errors, 'message'))}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant='primary'
                onClick={() => handleSubmit()}
                loading={loading}
                data-testid='send-feedback-button-send'
              >
                {i18n.t('noun.send')}
              </Button>
              <Dialog.Close asChild>
                <Button variant='primary' outlined data-testid='send-feedback-button-cancel'>
                  {i18n.t('scenes.feedback.actions.cancel')}
                </Button>
              </Dialog.Close>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog.Root>
      );
    }}
  </Formik>
);

export default Feedback;
