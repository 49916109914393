import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Rail = styled.span`
  label: Rail;
  display: block;
  opacity: 0.21;
  position: absolute;
  background-color: ${props => props?.theme?.colors?.red500};
  border-radius: inherit;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: currentColor;
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      width: 100%;
      transform: translateY(-50%);
      height: inherit;
      top: 50%;
    `};
  ${props =>
    props.orientation === 'vertical' &&
    css`
      height: 100%;
      transform: translateY(-50%);
      width: inherit;
      top: 50%;
    `};
`;

Rail.defaultProps = {
  orientation: 'horizontal'
};

Rail.propTypes = {
  orientation: PropTypes.string
};

export default Rail;
