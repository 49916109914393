/* eslint-disable */
import React from 'react';
import Icon from '~/components/IconCommunity';
import { renderToString } from 'react-dom/server';

const defaultHeader =
  'data:image/jpg;base64, iVBORw0KGgoAAAANSUhEUgAAAFoAAAAUCAIAAACCmL1JAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAi0lEQVR4nO2WywoAIQhF+/5+uhYzDPYCZ9G9F/SsJAT1QFapiaGwG9AidQygdTQDuLQHqI62gKzuQUKHjh2yjq0XZEsTEjrWHGRXFr6OUxqysQ++Dikj0jrwRsjfsGfg7fqgGJHQMQWp4439t+kSQjrqn+VyCfQq9ZwE1XGaM4qO6n41gq5SNVLHQAf7FRHjVjAKhAAAAABJRU5ErkJggg==';

const capsule = ({ header, background, accent, icon }) => {
  return (
    <svg
      width='90px'
      height='78px'
      viewBox='0 0 90 78'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect
        id='background'
        fill={background}
        x='0'
        y='0'
        width='90'
        height='90'
      ></rect>
      <image
        id='header'
        x='0'
        y='0'
        width='90'
        height='20'
        transform='translate(0 0) scale(1)'
        href={header ? header : defaultHeader}
      ></image>
      <g transform='translate(27 30.5) scale(0.4)'>
        <Icon fill={accent} name={icon} />
      </g>
    </svg>
  );
};

const createIconCapsule = props => {
  try {
    const svg = renderToString(capsule(props));
    const newSvg = svg.replace('currentColor', props.accent);
    return encodeURIComponent(newSvg);
  } catch (e) {
    // console.log(e);
  }
};
export default createIconCapsule;
