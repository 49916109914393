import styled from 'styled-components';

import NativeBadge from '~/components/Badge';
import FabNative from '~/components/Fab';
import IconNative from '~/components/IconCommunity';
import ListNative from '~/components/List';

export const Badge = styled(NativeBadge)`
  position: relative;
  top: -8px;
  margin-left: 5px;
`;

export const List = styled(ListNative)`
  width: 195px;
  border-radius: 10px;
`;

export const Fab = styled(FabNative)`
  position: initial;
  margin-right: 0;
`;

export const Icon = styled(IconNative)`
  font-size: 16px;
  margin-right: 8px;
`;

export const Item = styled(ListNative.Item)`
  padding: 0 16px;
  display: flex;
`;
