import Immutable from 'seamless-immutable';

import { forgotPassword, authentication } from '~/api';
import { RECAPTCHA_SITE_KEY } from '~/common/helpers/environment';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  isSendEmail: false,
  isInvalidLink: false,
  disabledForgotPassword: false,
  loading: false,
  error: {
    sendEmail: null,
    resetPassword: {
      unauthorized: null,
      password: null
    }
  },
  success: {
    isResetpassword: false,
    resetPassword: null
  }
});

const getValueInProps = details => {
  if (details) {
    const keys = Object.keys(details);
    return keys.reduce((accumulator, currentValue) => {
      const messages = details[currentValue].map(x => x.message);
      return {
        message: messages.toString()
      };
    }, '');
  }
  return null;
};

const normalizeErros = error => {
  const errorData = error.details || error.data;
  if (!errorData) {
    return {
      unauthorized: i18n.t('message.error.reset_password_error'),
      password: null
    };
  }
  return {
    unauthorized: null,
    password: getValueInProps(errorData?.fieldErrors)
      ? getValueInProps(errorData?.fieldErrors).message
      : '',
    messages: errorData?.fieldErrors
  };
};

const forgotPasswordModel = {
  name: 'forgotPassword',
  state: initialState,
  reducers: {
    sendEmail: (state, isSendEmail) => state.merge({ isSendEmail }),
    setInvalidLink: (state, value) => state.setIn(['isInvalidLink'], value),
    setDisableForgotPassword: (state, value) => state.setIn(['disabledForgotPassword'], value),
    loading: (state, loading) => state.merge({ loading }),
    success: (state, { path, value }) =>
      state.merge(
        {
          success: {
            [path]: value
          }
        },
        { deep: true }
      ),
    error: (state, { path, value }) =>
      state.merge(
        {
          error: {
            [path]: value
          }
        },
        { deep: true }
      )
  },
  effects: dispatch => ({
    async send(params) {
      try {
        // eslint-disable-next-line no-undef
        const recaptcha = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action: 'forgotPassword'
        });
        const { data } = await authentication.getNotLoggedPlatformParameter(params?.realm, 'disabledForgotPassword');
        if(data?.disabledForgotPassword) {
          dispatch.forgotPassword.setDisableForgotPassword(data?.disabledForgotPassword);
          return;
        };
        await forgotPassword.send({ ...params, recaptcha });
        dispatch.forgotPassword.sendEmail(true);
      } catch (e) {
        dispatch.forgotPassword.error({
          path: 'sendEmail',
          value: e
        });
      }
    },
    returnToSignIn() {
      dispatch.router.navigate({ to: '/login' });
    },
    returnToForgotPassword() {
      dispatch.router.navigate({ to: '/login/forgotpassword' });
    },
    async onResetPassword(params) {
      try {
        const response = await forgotPassword.resetPassword(params);
        dispatch.forgotPassword.success({
          path: 'resetPassword',
          value: response
        });
      } catch (error) {
        if (error?.response?.status === 404) {
          dispatch.forgotPassword.setInvalidLink(true);
        }
        dispatch.forgotPassword.error({
          value: normalizeErros(error.response ? error.response.data : error),
          path: 'resetPassword'
        });
      }
    }
  }),
  logics: [
    {
      type: 'forgotPassword/returnToForgotPassword',
      latest: true,
      process(context, dispatch, done) {
        dispatch.forgotPassword.setInvalidLink(false);
        done();
      }
    },
    {
      type: 'forgotPassword/returnToSignIn',
      latest: true,
      process(context, dispatch, done) {
        dispatch.forgotPassword.sendEmail(false);
        done();
      }
    },
    {
      type: ['forgotPassword/onResetPassword', 'forgotPassword/send'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.forgotPassword.loading(true);
        done();
      }
    },
    {
      type: [
        'forgotPassword/success',
        'forgotPassword/error',
        'forgotPassword/sendEmail',
        'forgotPassword/setDisableForgotPassword'
      ],
      latest: true,
      process(_, dispatch, done) {
        dispatch.forgotPassword.loading(false);
        done();
      }
    }
  ]
};

export default forgotPasswordModel;
