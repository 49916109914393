import styled, { keyframes } from 'styled-components';

export const LoadingBarContainer = styled.div``;

export const LoadingBarProgressBarWrapper = styled.div`
  width: 128px;
  height: 12px;
  border-radius: ${props => props.theme.spacings.bit};
  background-color: ${props => props.theme.colors.pri50};
  box-sizing: border-box;
  padding: ${props => props.theme.spacings.bit};
  display: flex;
  align-items: center;
`;

export const progressBarAnimation = keyframes`
  0% {
    width: 1%;
  }
  5% {
    width: 10%;
  }
  50% {
    width: 85%;
  }
  100% {
    width: 97%;
  }
`;

export const LoadingBarProgressBar = styled.div`
  width: 10%;
  height: ${props => props.theme.spacings.byte};
  border-radius: 6px;
  background-color: #5f1eba;

  animation: ${progressBarAnimation} 30s ease-in;
  animation-fill-mode: forwards;
`;
