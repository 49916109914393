import React, { FC } from 'react';
import { Link as LinkNative, LinkProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import IconNative from '~/components/IconCommunity';
import ListNative from '~/components/List';
import Menu from '~/components/Menu';
import Role from '~/components/Role';
import Text from '~/components/Text';

const Label = styled(Text)<{ 'data-testid': string; onClick?: () => void }>`
  cursor: pointer;
  display: flex;
  font-size: 12px;
  color: ${p => p.theme.colors.gra600};
  align-items: center;
`;

const Icon = styled(IconNative)`
  font-size: 16px;
  margin-left: 16px;
`;

const List = styled(ListNative)`
  width: 116px;
  border-radius: 10px;
`;

const Item = styled(ListNative.Item)`
  padding: 0 16px;
`;

const Link = styled<LinkProps>(props => <LinkNative {...props} />)`
  text-decoration: none;
`;

interface SelectProps {
  activeRealm: string;
  byId?: {
    [key: string]: {
      id: string;
      name: string;
      companyName: string;
    };
  };
  allIds?: string[];
}

const Select: FC<SelectProps> = ({
  activeRealm = '',
  allIds = [],
  byId = {}
}) => {
  const location = useLocation();

  if (allIds.length > 1) {
    return (
      <Menu
        data-testid='realm-selection-menu'
        component={({ toggle }) => (
          <Label data-testid='selected-text' weight='regular' onClick={toggle}>
            Realm: {activeRealm}
            <Role name={['GROUP:SUPPORT']}>
              <Icon name='CaretDown' faType='solid' />
            </Role>
          </Label>
        )}
      >
        {({ toggle }) => (
          <Role name={['GROUP:SUPPORT']}>
            {allIds.length > 1 ? (
              <List inset noHairlines>
                {allIds?.map(id => (
                  <Link
                    key={id}
                    to={location.pathname.replace(
                      activeRealm,
                      byId?.[id]?.name
                    )}
                    data-testid='link'
                    onClick={() => toggle?.(false)}
                  >
                    <Item>
                      <Text data-testid='value-text' align='left'>
                        {byId?.[id]?.companyName}
                      </Text>
                    </Item>
                  </Link>
                ))}
              </List>
            ) : null}
          </Role>
        )}
      </Menu>
    );
  }

  return (
    <div data-testid='realm-selection-menu'>
      <Label data-testid='selected-text' weight='regular'>
        Realm: {activeRealm}
      </Label>
    </div>
  );
};

export default Select;
