import isEmpty from 'lodash/isEmpty';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const normalizeData = ({ entity, data, id = 'id' }) => {
  const Entity = new schema.Entity(entity, {}, { idAttribute: id });
  const Schema = { [entity]: [Entity] };
  return normalize(data, Schema);
};

// Locals

const initialState = Immutable({
  deployPipelineById: {},
  deployPipelineIds: [],
  pipelineById: {},
  pipelineIds: [],
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
  page: 0,
  size: 20,
  loading: {
    deployPipeline: false,
    pipelines: false,
    updatePipelineVersionMinor: false
  },
  errors: {
    find: false
  }
});

const historyPipelineModel = {
  name: 'historyPipeline',
  state: initialState,
  reducers: {
    setVersionDeployedInAllEnvironments: (
      state,
      { deployPipelineIds, deployPipelineById }
    ) => state.merge({ deployPipelineIds, deployPipelineById }),
    setLoading: (state, { path, value }) =>
      state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      }),
    setHistoryPipeline: (
      state,
      {
        pipelineIds,
        pipelineById,
        totalPages,
        totalElements,
        page,
        size,
        numberOfElements
      }
    ) =>
      state.merge({
        pipelineIds,
        pipelineById,
        totalPages,
        totalElements,
        page,
        size,
        numberOfElements
      }),
    setUpdateDelete(state, { path, value }) {
      return state.merge({
        errors: {
          [path]: value
        }
      });
    },
    setErrors(state, { path, value }) {
      return state.merge({
        errors: {
          [path]: value
        }
      });
    },
    clear: () => initialState
  },
  effects: dispatch => ({
    async find(props, state) {
      try {
        const { realm } = state.application.realm;
        dispatch.historyPipeline.setLoading({
          path: 'pipelines',
          value: true
        });
        const historyPipelines = await api.pipelineHistory.findHistoryPipeline({
          ...props,
          realm
        });
        dispatch.historyPipeline.setLoading({
          path: 'pipelines',
          value: false
        });
        const { result, entities } = normalizeData({
          entity: 'historyPipeline',
          data: {
            historyPipeline:
              historyPipelines?.data.historyPipeline?.content || []
          }
        });
        dispatch.historyPipeline.setHistoryPipeline({
          pipelineIds: result?.historyPipeline,
          pipelineById: entities?.historyPipeline,
          size: historyPipelines?.data?.historyPipeline?.size,
          page: historyPipelines?.data?.historyPipeline?.number,
          totalElements: historyPipelines?.data?.historyPipeline?.totalElements,
          totalPages: historyPipelines?.data?.historyPipeline?.totalPages,
          numberOfElements: historyPipelines?.data?.historyPipeline?.totalPages
        });
      } catch (e) {
        dispatch.historyPipeline.setErrors({
          path: 'find',
          value: true
        });
        dispatch.historyPipeline.setLoading({
          path: 'pipelines',
          value: false
        });
      }
    },
    async findPipelineDeployed(props, state) {
      try {
        const { realm } = state.application.realm;
        dispatch.historyPipeline.setLoading({
          path: 'deployPipeline',
          value: true
        });
        const { data } = await api.pipelineHistory.findPipelineDeployed({
          ...props,
          realm
        });
        dispatch.historyPipeline.setLoading({
          path: 'deployPipeline',
          value: false
        });
        const { result, entities } = normalizeData({
          entity: 'versionDeployedInAllEnvironments',
          data
        });
        dispatch.historyPipeline.setVersionDeployedInAllEnvironments({
          deployPipelineIds: result.versionDeployedInAllEnvironments,
          deployPipelineById: entities.versionDeployedInAllEnvironments
        });
      } catch (e) {
        dispatch.historyPipeline.setErrors({
          path: 'findPipelineDeployed',
          value: e
        });
      }
    },
    async remove(params, { application }) {
      try {
        const { realm } = application.realm;
        const { data } = await api.pipelineHistory.remove({ ...params, realm });

        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipelines.messages.success.archived_successfully'
          )
        });
        dispatch.historyPipeline.setUpdateDelete(
          { path: 'remove', value: data },
          { ...params, page: params?.page, size: params?.size }
        );
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('scenes.pipeline_history.messages.error.delete_item', {
            item: i18n.t('scenes.pipeline_history.labels.pipeline_singular')
          })
        });
        dispatch.pipelineHistory.setErrors({ path: 'delete', value: e });
      }
    },
    async archive(params, { application }) {
      try {
        dispatch.historyPipeline.setLoading({
          path: 'archive',
          value: true
        });
        const { realm } = application.realm;
        const { data } = await api.pipelineHistory.archive({
          ...params,
          realm
        });
        dispatch.historyPipeline.setLoading({
          path: 'archive',
          value: false
        });

        dispatch.historyPipeline.setUpdateDelete(
          { path: 'archive', value: data },
          { ...params, size: params.size, page: params.page }
        );
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipeline_history.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.pipeline_history.messages.success.item_restored',
                {
                  item: `${i18n.t(
                    'scenes.pipeline_history.labels.pipeline_singular'
                  )}`
                }
              )}`
            }
          )
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipeline_history.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.pipeline_history.messages.error.restore',
                {
                  item: `${i18n.t(
                    'scenes.pipeline_history.labels.pipeline_singular'
                  )}`
                }
              )}`
            }
          )
        });
        dispatch.historyPipeline.setErrors({
          path: 'archive',
          value: e
        });
        dispatch.historyPipeline.setLoading({
          path: 'archive',
          value: false
        });
      }
    },
    async updatePipelineVersionMinor(params, { application }) {
      try {
        dispatch.historyPipeline.setLoading({
          path: 'updatePipelineVersionMinor',
          value: true
        });
        const { realm } = application.realm;
        const { data } = await api.pipelineHistory.updatePipelineVersionMinor({
          ...params,
          realm
        });

        dispatch.historyPipeline.setUpdateDelete({
          path: 'updatePipelineVersionMinor',
          value: data
        });
        dispatch.router.navigate({
          to: `/${realm}/design/pipelines/${data.updatePipelineVersionMinor}`,
          replace: true
        });
        dispatch.historyPipeline.setLoading({
          path: 'updatePipelineVersionMinor',
          value: false
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.pipeline_history.labels.upper_and_capitalize.capitalized',
            {
              item: `${i18n.t(
                'scenes.pipeline_history.messages.error.update_item',
                {
                  item: `${i18n.t(
                    'scenes.pipeline_history.labels.pipeline_singular'
                  )}`
                }
              )}`
            }
          )
        });
      }
    }
  }),
  logics: [
    {
      type: 'historyPipeline/find',
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        dispatch.historyPipeline.setErrors({
          path: 'pipelines',
          value: false
        });
        if (payload.pipelineName) {
          dispatch.historyPipeline.setLoading({
            path: 'historyPipeline',
            value: true
          });
        }
        done();
      }
    },
    {
      type: 'historyPipeline/setUpdateDelete',
      latest: true,
      process(context, dispatch, done) {
        const { pipelines } = context.getState();
        const { meta } = context.action;
        dispatch.designPipeline.reload();
        if (!isEmpty(meta)) {
          dispatch.historyPipeline.find({
            pipelineName: meta.name,
            versionMajor: meta.versionMajor,
            size: meta?.size,
            page: meta?.page
          });
          dispatch.pipelines.getAll({
            search: { ...pipelines?.searchParams, page: 0 }
          });
          dispatch.project.fetch(null, { loading: false });
        }
        dispatch.historyPipeline.setLoading({
          path: 'updatePipelineVersionMinor',
          value: false
        });
        done();
      }
    }
  ]
};

export default historyPipelineModel;
