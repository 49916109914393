import React from 'react';
import styled from 'styled-components';

const RadioButtonIconBase = styled.svg`
  fill: currentColor;
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;
const RadioButtonUnchecked = props => (
  <RadioButtonIconBase
    focusable='false'
    viewBox='0 0 24 24'
    aria-hidden='true'
    {...props}
  >
    <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
  </RadioButtonIconBase>
);

export default RadioButtonUnchecked;
