import React from 'react';

import { HistoricType } from '../types';

import { Pagination } from '~/common/types/Pagination';

type HistoryDeploymentValue = {
  deploymentSelected: any;
  lastDeploy?: HistoricType;
  selectDeployment: (deployment: any) => void;
  loading: boolean;
  historic: Pagination<HistoricType>;
  getData: (page: number) => void;
};

export const HistoryDeploymentContext =
  React.createContext<HistoryDeploymentValue>({
    deploymentSelected: {},
    historic: {
      last: true,
      content: [],
      totalPages: 0,
      totalElements: 0,
      number: 0,
      first: false,
      numberOfElements: 0,
      size: 0
    },
    loading: false,
    selectDeployment: async () => null,
    getData: async () => null
  });
