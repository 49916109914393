import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';

import withAcls from '~/common/hoc/withAcls';
import withHooks from '~/common/hoc/withHooks';

const enhancer = compose(
  withAcls,
  withHooks(props => {
    const [configActionButtonRoles, setConfigActionButtonRoles] = useState([]);

    const location = useLocation();
    const { application } = useSelector(state => state);
    const dispatch = useDispatch();
    const state = {
      realm: application.realm.realm,
      environment: application.environment,
      environments: application.environments.allNames,
      hiddenPages: application.hiddenPages || []
    };
    const actions = {
      changeEnvironment: value => {
        const pathParts = location.pathname.split('/');
        const lastPartUrl = pathParts.pop();
        const secondLastPartUrl = pathParts[pathParts.length - 1];

        const basePath = `${state.realm}/operation/monitor`;
        const path = secondLastPartUrl === 'alerts-config'
          ? `${basePath}/alerts-config/${value}`
          : `${basePath}/${value}/${lastPartUrl}`;

        dispatch.application.setEnvironments(value);
        dispatch.router.navigate({ to: path });
      }
    };

    useEffect(() => {
      setConfigActionButtonRoles(
        props.configActionButton.reduce(
          (acc, actionButton) => acc.concat(actionButton.role),
          []
        )
      );
    }, [props.configActionButton]);

    return {
      ...props,
      ...state,
      ...actions,
      configActionButtonRoles
    };
  })
);

export default enhancer;
