/* eslint-disable */
import { normalize, schema } from 'normalizr';
import { csvDownload } from '~/common/helpers/jsonToCsv';

import Immutable from 'seamless-immutable';

// Locals
import { realms } from '~/api';

const ralmsEntity = new schema.Entity('realms');
const realmsSchema = { realms: [ralmsEntity] };

const initialState = Immutable({
  entities: {
    realms: {}
  },
  result: {
    realms: []
  },
  current: '',
  getRealm: null
});

const realmsModel = {
  name: 'realms',
  state: initialState,
  reducers: {
    setEntity(state, realms) {
      return state.merge({ ...realms });
    },
    setRealm(state, realm) {
      return state.merge({ current: realm });
    },
    setGetRealm(state, realm) {
      return state.merge({ getRealm: realm });
    }
  },
  effects: dispatch => ({
    async find() {
      try {
        const { data } = await realms.find();
        dispatch.realms.setEntity(normalize(data, realmsSchema));
      } catch (e) {
        // console.log(e);
      }
    },
    async getRealm(_, state) {
      try {
        const { data } = await realms.getRealm(state.application.realm.realm);
        dispatch.realms.setGetRealm(data?.getRealm);
      } catch (e) {
        dispatch.realms.setGetRealm(null);
      }
    }
  })
};

export default realmsModel;
