import Flow, {
  ChoiceComponent,
  ParallelComponent,
  LevelComponent,
  Track
} from '@digibee/flow';

import { TreeNode } from '../Node';
import { TreeContext } from '../Tree.machine';

import i18n from '~/common/helpers/i18n';
import { TriggerSpec } from '~/entities/TriggerSpec';

type Options = {
  depth: number;
};

type NodeElement<T = unknown> = {
  id: string;
  label: string;
  item: T;
  depth: number;
  parentId?: string;
};

export type TreeList = NodeElement[];

export function treeToList(
  tree: TreeNode,
  expandedById: TreeContext['expandedById'],
  parentId?: string
): TreeList {
  const { id, label, item, depth, children } = tree;

  const node: NodeElement = {
    id,
    label,
    item,
    depth,
    parentId
  };

  if (expandedById[id] === false) return [node];

  const childrenList =
    children?.flatMap(child => treeToList(child, expandedById, id)) || [];

  return [node, ...childrenList];
}

const flowSpecToTree = (
  flow: Flow,
  triggerSpec?: TriggerSpec,
  withoutStarterNode = false
): TreeNode => {
  const traverse = (level: Track, { depth }: Options): TreeNode[] => {
    const components: TreeNode[] = level.components().map(currentComponent => {
      if (currentComponent instanceof ChoiceComponent) {
        const whensChildren = currentComponent
          .conditions()
          .whens()
          .all()
          .map(currentWhen => {
            const track = flow.getTrack(currentWhen.targetName());

            return {
              id: currentWhen.targetName(),
              label: currentWhen.targetName(),
              item: currentWhen,
              depth: depth + 1,
              children: track ? traverse(track, { depth: depth + 2 }) : []
            };
          });

        const otherwiseExists = !!currentComponent.spec().otherwise;
        const otherwiseTrack = otherwiseExists
          ? flow.getTrack(
              currentComponent.conditions().otherwise().targetName()
            )
          : null;
        const otherwiseChild = otherwiseExists
          ? {
              id: currentComponent.conditions().otherwise().targetName(),
              label: currentComponent.conditions().otherwise().targetName(),
              item: currentComponent.conditions().otherwise(),
              depth: depth + 1,
              children: otherwiseTrack
                ? traverse(otherwiseTrack, { depth: depth + 2 })
                : []
            }
          : null;

        const children = otherwiseChild
          ? [...whensChildren, otherwiseChild]
          : whensChildren;

        return {
          id: currentComponent.id(),
          label: currentComponent.spec().stepName,
          item: currentComponent,
          depth,
          children
        };
      }

      if (currentComponent instanceof ParallelComponent) {
        const parallelChildren = currentComponent
          .executions()
          .all()
          .map(currentExecution => {
            const track = flow.getTrack(currentExecution.targetName());

            return {
              id: currentExecution.targetName(),
              label: currentExecution.spec().description,
              item: currentExecution,
              depth: depth + 1,
              children: track ? traverse(track, { depth: depth + 2 }) : []
            };
          });

        return {
          id: currentComponent.id(),
          label: currentComponent.spec().stepName,
          item: currentComponent,
          depth,
          children: parallelChildren
        };
      }

      if (currentComponent instanceof LevelComponent) {
        const onProcessLevel = currentComponent.level('onProcess');

        const onProcessDisconnectedChildren = onProcessLevel
          ?.disconnectedTracks()
          .map((track, index) => ({
            id: track.name(),
            label: `${i18n.t('label.disconnected')} ${index + 1}`,
            item: track,
            parentId: onProcessLevel?.name(),
            depth: depth + 3,
            children: traverse(track, { depth: depth + 4 })
          }));

        const onProcessDisconnectedGroup: TreeNode = {
          id: `${onProcessLevel?.name()}-disconnected-group`,
          label: i18n.t('label.disconnected'),
          item: 'disconnected-group',
          parentId: onProcessLevel?.name(),
          depth: depth + 2,
          children: onProcessDisconnectedChildren
        };

        const onExceptionLevel = currentComponent.level('onException');

        const onExceptionDisconnectedChildren = onExceptionLevel
          ?.disconnectedTracks()
          .map((track, index) => ({
            id: track.name(),
            label: `${i18n.t('label.disconnected')} ${index + 1}`,
            item: track,
            parentId: onExceptionLevel?.name(),
            depth: depth + 3,
            children: traverse(track, { depth: depth + 4 })
          }));

        const onExceptionDisconnectedGroup: TreeNode = {
          id: `${onExceptionLevel?.name()}-disconnected-group`,
          label: i18n.t('label.disconnected'),
          item: 'disconnected-group',
          parentId: onExceptionLevel?.name(),
          depth: depth + 2,
          children: onExceptionDisconnectedChildren
        };

        const onProcessChildren = onProcessLevel
          ? [
              {
                id: onProcessLevel.name(),
                label: i18n.t('label.on_process'),
                item: onProcessLevel,
                depth: depth + 1,
                children: [
                  ...(onProcessLevel
                    ? traverse(onProcessLevel, { depth: depth + 2 })
                    : []),
                  ...(onProcessDisconnectedChildren?.length
                    ? [onProcessDisconnectedGroup]
                    : [])
                ]
              }
            ]
          : [];

        const onExceptionChildren = onExceptionLevel
          ? [
              {
                id: onExceptionLevel.name(),
                label: i18n.t('label.on_exception'),
                item: currentComponent.level('onException'),
                depth: depth + 1,
                children: [
                  ...(onExceptionLevel
                    ? traverse(onExceptionLevel, { depth: depth + 2 })
                    : []),
                  ...(onExceptionDisconnectedChildren?.length
                    ? [onExceptionDisconnectedGroup]
                    : [])
                ]
              }
            ]
          : [];

        return {
          id: currentComponent.id(),
          label: currentComponent.spec().stepName,
          item: currentComponent,
          depth,
          children: [...onProcessChildren, ...onExceptionChildren]
        };
      }

      return {
        id: currentComponent.id(),
        label: currentComponent.spec().stepName,
        item: currentComponent,
        depth
      };
    });

    return components;
  };

  const trigger = triggerSpec
    ? {
        id: 'trigger',
        label: 'Trigger',
        item: 'trigger',
        depth: 1
      }
    : {
        id: 'starter-node',
        label: 'Starter Node',
        item: 'starter-node',
        depth: 1
      };

  const startLevel = flow.getLevel('start');

  if (!startLevel) {
    const tree = {
      id: 'root',
      label: i18n.t('label.root'),
      depth: 0,
      item: startLevel,
      children: [trigger]
    };
    return tree;
  }

  const startDisconnectedChildren = startLevel
    .disconnectedTracks()
    .map((track, index) => ({
      id: track.name(),
      label: `${i18n.t('label.disconnected_group')} ${index + 1}`,
      item: track,
      depth: 2,
      children: traverse(track, { depth: 3 })
    }));
  const startDisconnectedGroup: TreeNode = {
    id: 'start-disconnected-group',
    label: i18n.t('label.disconnected'),
    item: 'disconnected-group',
    depth: 1,
    children: startDisconnectedChildren
  };

  const children = withoutStarterNode
    ? [
        ...traverse(startLevel, { depth: 1 }),
        ...(startDisconnectedChildren.length ? [startDisconnectedGroup] : [])
      ]
    : [
        trigger,
        ...traverse(startLevel, { depth: 1 }),
        ...(startDisconnectedChildren.length ? [startDisconnectedGroup] : [])
      ];

  const tree = {
    id: 'root',
    label: i18n.t('label.root'),
    depth: 0,
    item: startLevel,
    children
  };

  return tree;
};

export default flowSpecToTree;
