import withHooks from '~/common/hoc/withHooks';

interface Props {
  onChange: Function;
}

const enhancer = withHooks(({ onChange, ...props }: Props) => {
  const actions = {
    search: async (e: any, tags: any) => {
      onChange({ search: { name: e, status: tags } });
    }
  };
  return {
    ...props,
    ...actions
  };
});

export default enhancer;
