import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Track = styled.span`
  label: Track;
  ${props => props.css}
  display: block;
  position: absolute;
  background-color: currentColor;
  border: 1px solid currentColor;
  border-radius: inherit;
  animation: slide 0, 5s forwards;
  animation-delay: 2s;
  ${props =>
    props.size === 'sm' &&
    css`
      border: none;
    `};
  &:after {
    box-sizing: inherit;
  }
  &:before {
    box-sizing: inherit;
  }

  ${props =>
    props.orientation === 'horizontal' &&
    css`
      top: 50%;
      transform: translateY(-50%);
      height: inherit;
      @keyframes slide {
        100% {
          left: 0;
        }
      }
    `};

  ${props =>
    props.orientation === 'vertical' &&
    css`
      width: inherit;
      left: 50%;
      transform: translateX(-50%);
      @keyframes slide {
        100% {
          bototm: 0;
        }
      }
    `};
`;

Track.defaultProps = {
  orientation: 'horizontal'
};

Track.propTypes = {
  orientation: PropTypes.string
};

export default Track;
