import { Tag, TagProps } from '@digibee/beehive-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { withTheme } from 'styled-components';

import * as Elements from './OldStatusDeploy.elements';
import { getMessageStatus } from './StatusDeploy';
import { type Deployment } from '../../scenes/Run/components/CardRuntime';

type Props = {
  deployment: Deployment;
  disabled?: boolean;
  isMarginBottom?: boolean;
  isMinHeight?: boolean;
  isPipelineEngine?: boolean;
};

export type DataMessageType = {
  loader?: boolean;
  text: string;
  icon: IconProp;
  style: string;
};

const OldStatusDeployV2 = ({
  deployment,
  isMarginBottom = true,
  isMinHeight = true
}: Props) => {
  const dataMessage: DataMessageType = getMessageStatus(deployment);

  return (
    <Elements.ContainerStatus
      isMarginBottom={isMarginBottom}
      isMinHeight={isMinHeight}
    >
      <Tag variant={dataMessage.style as TagProps['variant']}>
        {dataMessage.text}
      </Tag>
    </Elements.ContainerStatus>
  );
};

export default withTheme(OldStatusDeployV2);
