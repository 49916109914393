import { css } from 'styled-components';

const borderStyles = ({ theme, danger, disabled }) => css`
  border-radius: ${theme.borderRadius.mega};
  border: 1px solid ${disabled ? theme.colors.gra400 : theme.colors.gra600};
  &:focus-within {
    margin: ${danger ? '0px' : '-1px'};
    border: 2px solid ${danger ? theme.colors.red500 : theme.colors.pri500};
    &: hover {
      border: 2px solid ${danger ? theme.colors.red500 : theme.colors.pri500};
    }
  }
  ${danger &&
  css`
    border: 2px solid ${theme.colors.red500};
  `};
  ${disabled &&
  css`
    border: 1px solid ${theme.colors.gra400};
  `};
`;

export default borderStyles;
