/* eslint-disable */

import Immutable from 'seamless-immutable';
import { map } from 'lodash';

// Locals
import { configuration } from '~/api';

const initialState = Immutable({
  // realm: null,
  loading: false,
  items: []
});

const configurationModel = {
  name: 'configuration',
  state: initialState,
  reducers: {
    loading(state, loading) {
      return state.merge({
        loading
      });
    },
    successItems(state, items) {
      return state.merge({
        items
      });
    },
    errorItems(state, error) {
      return state.merge({
        error
      });
    }
  },
  effects: dispatch => ({
    async find(params) {
      try {
        const { data } = await configuration.find(params);

        dispatch.configuration.successItems(
          map(data.configurations, (configuration, index) => ({
            name: configuration.name.split('-')[0],
            description: configuration.description,
            id: configuration.id,
            selected: false,
            consumers: configuration.consumers,
            actualConsumers: configuration.actualConsumers
          }))
        );
      } catch (e) {
        dispatch.configuration.errorItems(e);
      }
    }
  }),
  logics: [
    {
      type: 'configuration/find',
      latest: true,
      process(context, dispatch, done) {
        dispatch.configuration.loading(true);
        done();
      }
    },
    {
      type: 'configuration/successItems',
      latest: true,
      process(context, dispatch, done) {
        dispatch.configuration.loading(false);
        done();
      }
    }
  ]
};

export default configurationModel;
