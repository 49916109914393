import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Portal } from 'react-portal';

import AnimationTransitionState from '../AnimationTransitionState';
import CloseButton from './components/CloseButton';
import * as Elements from './SideSheet.elements';

const SideSheet = ({
  opened,
  onBackdropClick,
  onCloseClick,
  noScroll,
  noScrollX,
  children,
  height,
  'data-testid': dataTestId,
  ...props
}) => {
  const escKeyupHandler = ev => {
    if (ev.which === 27) onCloseClick();
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (opened) {
      document.addEventListener('keyup', escKeyupHandler);
      const body = document.querySelector('body');
      body.style.overflow = 'hidden';

      return () => {
        body.style.overflow = '';
        document.removeEventListener('keyup', escKeyupHandler);
      };
    }
  }, [opened, onCloseClick]);

  return (
    <Portal node={document && document.querySelector('.application-root')}>
      <AnimationTransitionState value={opened}>
        {({ state }) => (
          <Elements.SideSheetRoot state={state} data-testid={dataTestId}>
            <Elements.SideSheetBackdrop
              state={state}
              onClick={onBackdropClick}
            />
            <Elements.SideSheetContainer state={state} {...props}>
              <CloseButton onClick={onCloseClick} />
              <Elements.SideSheetContent
                height={height}
                noScroll={noScroll}
                noScrollX={noScrollX}
              >
                {opened && children}
              </Elements.SideSheetContent>
            </Elements.SideSheetContainer>
          </Elements.SideSheetRoot>
        )}
      </AnimationTransitionState>
    </Portal>
  );
};

SideSheet.defaultProps = {
  children: [],
  opened: false,
  onBackdropClick: () => {},
  onCloseClick: () => {},
  height: '100%',
  noScrollX: false,
  noScroll: false,
  'data-testid': 'sidesheet'
};

SideSheet.propTypes = {
  opened: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  children: PropTypes.arrayOf([]),
  height: PropTypes.string,
  noScrollX: PropTypes.bool,
  noScroll: PropTypes.bool,
  'data-testid': PropTypes.string
};

export default SideSheet;
