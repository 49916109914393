import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const eventTooltip = props => css`
  ${props.isHover &&
  css`
    visibility: visible;
  `}
`;

const baseStyles = ({ theme, ...props }) => css`
  label: TrackTooltip;
  display: block;
  visibility: hidden;
  position: absolute;
  height: 27px;
  width: 0;
  transition: opacity 0.15s linear;
  text-align: center;
  ${props.orientation === 'horizontal' &&
  css`
    bottom: calc(0.3em + 0.375rem + 1px + 1.2rem + 10px + 3px);
  `};

  ${props.orientation === 'vertical' &&
  css`
    margin-bottom: calc(0.33em + 0.375rem + 1px + 0.37rem + 3px + 3px);
  `};

  z-index: 1070;
  overflow: visible;
  margin-left: 1px !important;
`;

const baseStyleToolTipBody = ({ theme }) => css`
  position: absolute;
  background-color: ${theme.colors.gra600};
  color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.mega};
  transition: opacity 0.3s;
  font-family: ${theme.typography.fontFamily};
  transform: translateX(-50%);
  max-width: 200px;
  height: 26px;
  padding: 0px 6px;
  line-height: 26px;
  color: ${theme?.colors?.white};
  box-sizing: border-box;
  font-size: 12px;
  white-space: nowrap;
`;

const Indication = styled.span`
  position: absolute;
  transform: translateX(-100%);
  display: block;
  width: 0.8rem;
  height: 0.5rem;
  bottom: -0.4rem;
  &::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: ${props => props.theme.colors.gra600};
  }
`;

const TooltipBody = styled.span`
  ${baseStyleToolTipBody};
`;

const Tooltip = styled.span`
  ${baseStyles};
  ${eventTooltip};
`;

const BaseComponente = ({ isHover, children, ...props }) => (
  <Tooltip
    isHover={isHover}
    /* eslint-disable react/jsx-props-no-spreading */
    {...props}
  >
    <TooltipBody>{children}</TooltipBody>
    <Indication />
  </Tooltip>
);

BaseComponente.defaultProps = {
  isHover: false,
  children: null,
  orientation: 'horizontal'
};

BaseComponente.propTypes = {
  isHover: PropTypes.bool,
  children: PropTypes.element,
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

export default BaseComponente;
