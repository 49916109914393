import i18n from '~/common/helpers/i18n';
import { SearchBar, Box } from '@digibee/beehive-ui';

const Header = ({ search }: { search: (e: string, tags: string[] | undefined) => void }) => (
  <Box 
    css={{ 
      width: '100%', 
      display: 'flex',
      justifyContent: 'start', 
      alignItems: 'start'
    }}
  >

    <Box 
      css={{ 
        width: '45vw',
        '@media only screen and (max-width: 761px)': {
          width: '100%',
          padding: '0 15px'
        }
      }}
    >
      <SearchBar
        data-testid='licensing-limit-of-use-local-search-input'
        placeholder={i18n.t('label.search_by_pipe_name_placeholder')}
        onSearch={(e: string, tags: string[] | undefined) => search(e, tags)}
        placeholderTags={i18n.t('label.all')}
        tagsSource={[
          {
            label: i18n.t('label.exceeded'),
            value: 'RED',
            isFixed: false
          },
          {
            label: i18n.t('label.almost_exceeding'),
            value: 'YELLOW',
            isFixed: false
          },
          {
            label: i18n.t('label.not_exceeding'),
            value: 'GREEN',
            isFixed: false
          }
        ]}
      />
    </Box>
  </Box>
);

export default Header;
