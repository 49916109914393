/* eslint-disable */
import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const message = {
  fetchMessages: params =>
    clientApollo.query({
      query: gql`
        query messagesByKey(
          $realm: String!
          $key: String
          $environment: String
          $limit: Int
        ) {
          messagesByKey(
            realm: $realm
            key: $key
            environment: $environment
            limit: $limit
          ) {
            body
            processorStepName
            processorType
            elapsed
          }
        }
      `,
      variables: {
        ...params
      }
    })
};
export default message;
