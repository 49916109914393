import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Header from './components/Header';

const Container = styled.div`
  overflow: hidden;
  height: 100vh;
`;

interface OperationProps {
  links?: any[];
  showEnvironmentSelect?: boolean;
}

const Operation: FC<OperationProps> = ({
  links = [],
  showEnvironmentSelect = false
}) => (
  <Container>
    <div>
      <Header links={links} showEnvironmentSelect={showEnvironmentSelect} />
    </div>
    <Outlet />
  </Container>
);

export default Operation;
