/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

// Locals
import theme from '~/common/styled/theme';
import Text from '~/components/Text';
import Dialog, {
  DialogFooter,
  ContentTitle,
  Title as TitleNative,
  ContentText,
  Container
} from '~/components/Dialog';
import _Button from '~/components/Button';

import * as Elements from './ConfirmDialog.elements';
import { iff } from '@digibee/control-statements';

const sizes = {
  sm: '512px',
  md: '720px'
};

export const ConfirmDialog = ({
  state = {
    variant: 'primary',
    size: 'sm'
  },
  hideCorfirm,
  setConfirmDialogContext,
  setCanConfirm
}) => {
  return (
    <Dialog
      opened={state.open}
      onRequestClose={() => !state?.disableClose && hideCorfirm(false)}
      width={sizes[state.size] ? sizes[state.size] : sizes.sm}
    >
      <Container data-testid='confirm-dialog'>
        <ContentTitle>
          <Elements.Title
            as='span'
            title={state.title}
            align='left'
            weight='medium'
            size='lg'
          >
            {state.title}
          </Elements.Title>
        </ContentTitle>
        <ContentText>
          {iff(state.text, () => (
            <Elements.Text type='secondary' align='left'>
              {state.text}
            </Elements.Text>
          ))}
          {iff(state.render, () => (
            <>
              {state.render({
                setConfirmDialogContext,
                context: state.context,
                setCanConfirm
              })}
            </>
          ))}
        </ContentText>
      </Container>
      <Elements.StyledDialogFooter>
        {iff(state.confirmText, () => (
          <>
            <Elements.Button
              onClick={() => hideCorfirm(true)}
              type={
                state.canConfirm
                  ? state?.variant
                    ? state?.variant
                    : 'primary'
                  : ''
              }
              data-testid={state.dataTestId}
              disabled={!state.canConfirm}
            >
              {state.confirmText}
            </Elements.Button>
            <Elements.Separator />
          </>
        ))}
        {iff(state.cancelText, () => (
          <>
            <Elements.Button
              type='link'
              variant={state?.variant ? state?.variant : 'primary'}
              onClick={() => hideCorfirm(false, { isCallbackCancel: true })}
              data-testid='cancel-button'
            >
              {state.cancelText}
            </Elements.Button>
            <Elements.Separator />
          </>
        ))}
      </Elements.StyledDialogFooter>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  /**
   * Definition object for dialog use.
   *
   * @variant { 'title' |  'open' | 'confirmText' |  'cancelText' |  'canConfirm'}
   */
  state: PropTypes.objectOf({
    title: PropTypes.string,
    open: PropTypes.bool,
    dataTestId: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    canConfirm: PropTypes.bool,
    /**
     * The width size
     *
     * @variant { 'sm' |  'md' }
     */
    size: PropTypes.oneOfType(['sm', 'md']),
    /**
     * The reason for alert and styled.
     *
     * @variant { 'primary' |  'danger' }
     */
    variant: PropTypes.oneOfType(['danger', 'primary'])
  }),
  /**
   * Dialog component display cancel function.
   */
  hideCorfirm: PropTypes.func.isRequired
};
