import { parse as cronParser } from '@datasert/cronjs-parser';
import {
  faCalendar,
  faClockTenThirty,
  faSlidersSimple,
  faGears
} from '@fortawesome/pro-regular-svg-icons';
import cronstrue from 'cronstrue';
import moment from 'moment';
import { memo } from 'react';

import { Infos } from './components/StatusBody';
import {
  GenericCardRuntime,
  GenericCardRuntimeProps
} from './GenericCardRuntime';

import i18n from '~/common/helpers/i18n';

type SchedulerCardRuntimeProps = GenericCardRuntimeProps;

export const SchedulerCardRuntime = memo((props: SchedulerCardRuntimeProps) => {
  const checkCronExpression = () => {
    try {
      cronParser(props.deployment.pipeline.triggerSpec.cronExpression, {
        hasSeconds: true
      });
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  };

  const infos: Infos = [
    [
      {
        icon: faCalendar,
        value: moment(props.deployment.startTime, 'x').format(
          'DD/MM/YYYY HH:mm:ss'
        )
      },
      {
        icon: faGears,
        title: i18n.t('label.engine_version'),
        value: props.disabledMenu
          ? null
          : props.deployment.deploymentStatus?.pipelineEngineVersion?.replace(
              /^([^.]*\.[^.]*\.[^.]*).*/,
              '$1'
            )
      }
    ],
    [
      {
        icon: faSlidersSimple,
        title: i18n.t('scenes.runtime.labels.conc_scheduling'),
        titleTooltip: i18n.t('scenes.runtime.labels.conc_scheduling_tooltip'),
        value: props.deployment.pipeline?.triggerSpec.concurrentScheduling
          ? 'on'
          : 'off'
      },
      {
        icon: faClockTenThirty,
        title: i18n.t('scenes.runtime.labels.recurring_intervals'),
        value: checkCronExpression()
          ? cronstrue.toString(
              props.deployment.pipeline?.triggerSpec.cronExpression
            )
          : null,
        valueTooltip: checkCronExpression()
          ? cronstrue.toString(
              props.deployment.pipeline?.triggerSpec.cronExpression
            )
          : null
      }
    ]
  ];

  return <GenericCardRuntime {...props} infos={infos} />;
});
