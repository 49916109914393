import React from 'react';
import styled from 'styled-components';

import i18n from '~/common/helpers/i18n';

const StyledContainer = styled.div`
  padding-top: 15px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 14px;
  color: ${props => props.theme.colors.gra500};
`;

const Link = styled.a`
  margin-left: 2px;
  margin-right: 2px;
`;

const PrivacyPolicy = () => (
  <StyledContainer>
    {i18n.t('scenes.login.guidance.privacy_policy')}
    <Link href='https://policies.google.com/privacy'>Privacy Policy</Link>
    <Link href='https://policies.google.com/terms'>Terms of Service</Link>
  </StyledContainer>
);

export default PrivacyPolicy;
