/* eslint-disable */

import React from 'react';
import {
  compose,
  setPropTypes,
  withHandlers,
  withProps,
  pure
} from 'recompose';
import { element, string, func, object } from 'prop-types';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';
import i18n from '~/common/helpers/i18n';

const mapStateToProps = () => ({});

const mapDispatchToProps = ({ snackbar }) => ({
  showSnackbar: snackbar.create
});

export const enhancer = compose(
  setPropTypes({
    children: element.isRequired,
    text: string,
    onCopy: func,
    messages: object
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClick:
      ({ onCopy, text, children, showSnackbar, messages }) =>
      async event => {
        const elem = React.Children.only(children);
        const result = copy(text);
        if (result) {
          showSnackbar({
            text: messages?.success || i18n.t('message.success.clipboard_copy')
          });
        } else {
          showSnackbar({
            text: messages?.error || i18n.t('message.error.clipboard_copy')
          });
        }
        if (typeof onClick === 'function') {
          onCopy(text, result);
        }
        if (elem && elem.props && typeof elem.props.onClick === 'function') {
          elem.props.onClick(event);
        }
      }
  }),
  withProps(props => ({
    renderCopy: () => {
      const { children, onClick, ...rest } = props;
      const elem = React.Children.only(children);
      return React.cloneElement(elem, { onClick, ...rest });
    }
  })),
  pure
);
