import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const inflightTransactions = {
  fetch: async params => {
    try {
      const inflightTransactions = await clientApollo.query({
        query: gql`
          query inflightTransactions(
            $realm: String!
            $environment: String!
            $dateStart: String
            $dateEnd: String
            $pipelineName: String
            $key: String
            $desc: String
            $pageSize: Int
            $page: Int
          ) {
            inflightTransactions(
              realm: $realm
              environment: $environment
              dateStart: $dateStart
              dateEnd: $dateEnd
              pipelineName: $pipelineName
              key: $key
              desc: $desc
              pageSize: $pageSize
              page: $page
            ) {
              page
              count
              order
              result {
                realm
                environment
                key
                pipelineName
                replicaName
                version
                source
                engineVersion
                requestMessageSize
                timestampStart
                timestampEnd
                timeout
                state
                cancelRequest
              }
            }
          }
        `,
        variables: {
          ...params
        }
      });
      if (inflightTransactions?.errors) {
        throw new Error(inflightTransactions.errors);
      }
      return inflightTransactions;
    } catch (e) {
      throw new Error(e);
    }
  },
  delete(params) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteInflightTransaction(
          $realm: String!
          $environment: String
          $key: String
        ) {
          deleteInflightTransaction(
            realm: $realm
            environment: $environment
            key: $key
          )
        }
      `,
      variables: {
        ...params
      }
    });
  }
};
export default inflightTransactions;
