import { dropRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './InputTagsCommunity.elements';
import { Chip } from '../Chip';

const enterKey = 13;
const deleteKey = 8;

const keyEvents = {
  [enterKey]: (event, valueOnly, items, onChange) => {
    event.preventDefault();

    const value = event.target.value.trim();

    if (value) {
      onChange([...items, valueOnly ? value : { value, removable: true }]);

      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  },
  [deleteKey]: (event, valueOnly, items, onChange) => {
    const { target } = event;

    if (!target.value) {
      event.preventDefault();

      const removableItems = items?.filter(({ removable }) => removable);
      const removed = dropRight(valueOnly ? items : removableItems);

      onChange([
        ...(items.filter(item => !valueOnly && !item.removable) || []),
        ...removed
      ]);
    }
  }
};

const InputTagsCommunity = ({
  valueOnly,
  value,
  onChangeHandler,
  placeholder,
  danger,
  helperText
}) => {
  const onKeyDownHandler = event => {
    const keyHandler = keyEvents[event.keyCode];

    if (!keyHandler) return;

    keyHandler(event, valueOnly, value, onChangeHandler);
  };
  const onRemove = (item, itemIndex) => () => {
    if (!valueOnly && !item.removable) return () => {};

    return onChangeHandler(value?.filter((_, index) => index !== itemIndex));
  };

  const onBlur = event => {
    const keyHandler = keyEvents[enterKey];

    if (!keyHandler) return;

    keyHandler(event, valueOnly, value, onChangeHandler);
  };

  return (
    <div>
      <Elements.Container danger={danger}>
        {placeholder && (
          <Elements.Label danger={danger}>{placeholder}</Elements.Label>
        )}
        {value?.map((item, itemIndex) => (
          <Elements.TagWrapper key={Math.random(itemIndex)}>
            <Chip
              isClose
              title={valueOnly ? item : item.value}
              disabled={!valueOnly && !item.removable}
              onRemove={onRemove(item, itemIndex)}
            />
          </Elements.TagWrapper>
        ))}
        <Elements.TextField onKeyDown={onKeyDownHandler} onBlur={onBlur} />
      </Elements.Container>

      {helperText && (
        <Elements.HelperText danger={danger}>{helperText}</Elements.HelperText>
      )}
    </div>
  );
};

InputTagsCommunity.defaultProps = {
  value: [],
  onChangeHandler: () => {},
  placeholder: '',
  danger: false,
  helperText: '',
  valueOnly: false
};

InputTagsCommunity.propTypes = {
  valueOnly: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  danger: PropTypes.bool,
  helperText: PropTypes.string
};

export default InputTagsCommunity;
