import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const roles = {
  find: ({ realm, search = {} }) =>
    clientApollo.query({
      query: gql`
        query roles($realm: String!, $search: JSON) {
          roles(realm: $realm, search: $search) {
            content {
              id
              name
              description
              disabled
              custom
              permissions {
                id
                name
                label
                description
                restricted
                environmentCapable
              }
            }
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables: {
        realm,
        search
      }
    }),
  async remove({ realm, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteRole($realm: String!, $id: String!) {
          deleteRole(realm: $realm, id: $id)
        }
      `,
      variables: {
        realm,
        id
      }
    });
  },
  async isRoleNameValid({ realm, name }) {
    const request = await clientApollo.query({
      query: gql`
        query validateRoleName($realm: String!, $name: String!) {
          validateRoleName(realm: $realm, name: $name)
        }
      `,
      variables: {
        name,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request?.data?.validateRoleName;
  },
  async create({ realm, name, description, permissions }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createRole($realm: String!, $role: RoleInput!) {
          createRole(realm: $realm, role: $role) {
            id
            name
            description
            disabled
            custom
            permissions {
              id
              name
              label
              description
              restricted
              environmentCapable
            }
          }
        }
      `,
      variables: {
        realm,
        role: {
          name,
          description,
          permissions
        }
      }
    });
  },
  async edit({ id, realm, name, description, permissions }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editRole($realm: String!, $role: RoleInput!) {
          editRole(realm: $realm, role: $role) {
            id
            name
            description
            disabled
            custom
            permissions {
              id
              name
              label
              description
              restricted
              environmentCapable
            }
          }
        }
      `,
      variables: {
        realm,
        role: {
          id,
          name,
          description,
          permissions
        }
      }
    });
  }
};

export default roles;
