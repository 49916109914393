/* eslint-disable react/jsx-no-undef */
import { choose, otherwise, when } from '@digibee/control-statements';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import MultiSelect from '~/components/_oldComponents/MultiSelect';
import Checkbox from '~/components/Checkbox';
import Editor from '~/components/Editor';
import { Input } from '~/components/Input';
import InputNumber from '~/components/InputNumber';
import InputTags from '~/components/InputTags';
import InputTagsCommunity from '~/components/InputTagsCommunity';
import Label from '~/components/Label';
import Select, { SelectAsync } from '~/components/Select';
import Switch from '~/components/Switch';
import TextArea from '~/components/TextArea';

const Container = styled.div`
  ${({ theme, isMargin }) =>
    isMargin &&
    css`
      margin: ${theme.spacings.mega};
    `};

  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      height: 100%;
    `}
`;

const Field = ({ type = 'text', ...props }) => (
  <Container {...omit(props, 'onChange')}>
    {choose(
      when(['editor', 'code'].includes(type), () => <Editor {...props} />),
      when(type === 'textarea', () => <TextArea {...props} />),
      when(type === 'select', () => <Select {...props} />),
      when(type === 'select-async', () => <SelectAsync {...props} />),
      when(type === 'number', () => <InputNumber {...props} />),
      when(type === 'tags', () => <InputTags {...props} />),
      when(type === 'tagsCommunity', () => <InputTagsCommunity {...props} />),
      when(type === 'checkbox', () => (
        <Label {...props}>
          <Checkbox {...props} />
        </Label>
      )),
      when(type === 'multi-select', () => <MultiSelect {...props} />),
      when(type === 'text', () => <Input {...props} />),
      when(type === 'switch', () => (
        <Label {...props}>
          <Switch {...props} />
        </Label>
      )),
      otherwise(() => <Input type={type} {...props} />)
    )}
  </Container>
);

Field.defaultProps = {
  isMargin: true,
  type: '',
  intellisense: null
};

Field.propTypes = {
  type: PropTypes.string,
  intellisense: PropTypes.objectOf({}),
  isMargin: PropTypes.bool
};
export default Field;
