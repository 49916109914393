/* eslint-disable */

import React from 'react';
import styled, { css } from 'styled-components';
import { isFunction } from 'lodash';
import { string, func, number, bool, oneOfType, object } from 'prop-types';

// Locals
import Text from '~/components/Text';

const Page = ({ textPage, onClick, pageNumber, ...props }) => {
  const handlerClick = () => {
    if (onClick) {
      onClick(pageNumber);
    }
  };
  return (
    <Item disabled={props.disabled}>
      <Label as='span' {...props} onClick={handlerClick}>
        {textPage}
      </Label>
    </Item>
  );
};

Page.propTypes = {
  textPage: oneOfType([object, string]),
  onClick: func.isRequired,
  pageNumber: number,
  disabled: bool
};

const Item = styled.li`
  box-sizing: border-box;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const Label = styled(Text)`
  padding: 11px 12px;
  border: 1px solid ${props => props.theme.colors.gra500};
  margin-left: -1px;
  cursor: pointer;
  ${props =>
    props.activedPage &&
    css`
      background-color: ${props => props.theme.colors.pri500};
      border-color: ${props => props.theme.colors.pri500};
      pointer-events: none;
      cursor: default;
      color: ${props => props.theme.colors.white};
    `}
`;

export default Page;
