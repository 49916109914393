import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';

const Container = styled.div`
  width: fit-content;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label``;

const InputFile = ({ onChange, children, ...props }) => {
  const [generatorId] = useState(uuid());
  return (
    <Container>
      <Input
        type='file'
        onChange={onChange}
        onClick={() => null}
        id={generatorId}
        {...props}
      />
      <Label htmlFor={generatorId}>{children}</Label>
    </Container>
  );
};

InputFile.defaultProps = {
  onChange: () => null
};

InputFile.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node
};

export default InputFile;
