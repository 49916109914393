/* eslint-disable */

import { onError } from '@apollo/client/link/error';
import storage from '~/common/helpers/storage';
import { ACTIVATE_POLLING_PERMISSONS } from '~/common/helpers/environment';

const logout = () => {
  storage.remove('userData');
  window.location = '/login';
};

const onErrorHandlers = onError(({ graphQLErrors, networkError, ...rest }) => {
  if (graphQLErrors && graphQLErrors.length) {
    switch (graphQLErrors[0]?.name) {
      case 'Unauthorized':
        if (window.location.pathname !== '/login') {
          logout();
        }
        break;
      case 'Forbidden':
        if (ACTIVATE_POLLING_PERMISSONS == 'true') {
          window.store.dispatch.acls.fetch();
        }
        break;
    }
    if (graphQLErrors[0]?.message === 'Forbidden: Request failed with status code 403' && 
        graphQLErrors[0]?.path[0] === 'getRealm' && 
        window?.location?.pathname !== '/oauth2/success' &&
        window?.location?.pathname !== '/oauth/error' && 
        !window?.location?.pathname?.includes?.("/login")
    ) {
      const realm = window?.location?.pathname?.split('/')[1];
      const userData = storage.get('userData');
      if(realm !== userData?.realm) {
        storage.set('RealmAccessDenied', realm);
        window.location = window.location.href.replace(realm, userData?.realm);
      }
    }
  }
  if (networkError) {
    switch (networkError.statusCode) {
      case 401:
        logout();
        break;
      case 403:
        if (
          networkError.result &&
          networkError.result.message.indexOf(
            'No credentials found for given'
          ) > -1
        ) {
          logout();
        }
        break;
    }
  }
});

export default onErrorHandlers;
