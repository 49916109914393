import { isApolloError, gql } from '@apollo/client';
import Flow, { FlowSpec } from '@digibee/flow';

import clientApollo from './clientApollo';

import { Nullish } from '~/common/types/Nullish';

export type LogLevel = 'INFO' | 'WARN' | 'ERROR';

export type ExecutionMessage = {
  id: string;
  body: string; // a stringified JSON
  elapsed: number;
  processorStepName: string;
  processorType: string;
  connectorId: string;
  connectorName: string;
  timestampStart: number;
  timestampEnd: number;
  indexes: Record<string, number>;
};

type IndexPath = Record<string, number>;

type ExecutedComponents = string[];

function componentHasExecuted(
  componentId: string,
  executedComponents: ExecutedComponents
): boolean {
  return executedComponents.includes(componentId);
}

function generateExecutedComponents(executionMessages: ExecutionMessage[]) {
  return executionMessages.reduce<ExecutedComponents>(
    (acc, currentExecutionMessage) =>
      acc.concat(currentExecutionMessage.connectorId),
    []
  );
}

function matchIndexPath(
  indexes: ExecutionMessage['indexes'],
  indexPath: IndexPath
): boolean {
  return Object.keys(indexPath).every(
    levelName => indexPath[levelName] === indexes[levelName]
  );
}

function getLoopBlockByIndex(
  levelName: string,
  executionMessages: ExecutionMessage[],
  flow: Flow,
  indexPath: IndexPath,
  index: number
) {
  const level = flow.getLevel(levelName);
  if (!level) return [];

  // fill lacking parent indexes with zero
  const allParentLevels = level.allParentLevels();
  const fullIndexPaths = allParentLevels
    ? allParentLevels.reduce<IndexPath>((acc, currentLevel) => {
        if (currentLevel.isStart()) return acc;
        return {
          ...acc,
          [currentLevel.name()]:
            currentLevel.name() in indexPath
              ? indexPath[currentLevel.name()]
              : 0
        };
      }, {})
    : indexPath;

  return executionMessages.filter(({ connectorId, indexes }) => {
    if (level.has(connectorId)) {
      return (
        indexes[level.name()] === index &&
        matchIndexPath(indexes, fullIndexPaths)
      ); // maybe only the matchIndexPath could be enough here
    }
    return false;
  });
}

export type ExecutionLog = {
  id: string;
  logLevel: LogLevel;
  logMessage: string;
  pipelineName: string;
  timestamp: string;
};

type ErrorResultData = {
  status?: number;
  timeout?: number;
  message?: string;
};

type ExecuteResultData = {
  status?: number;
  timeout?: number;
  message?: string;
};

type ConvertJson = {
  message: string | undefined;
  status: number;
  data: any;
  'X-Digibee-Debug': string;
  'X-Digibee-Key': string;
};

type TestExecuteResult = {
  digibeeKey: string;
  data: string;
};

const controllers: AbortController[] = [];

const convertJson = (stringJson: string | undefined): ConvertJson => {
  if (!stringJson) return {} as ConvertJson;
  return JSON.parse(stringJson);
};

// execute

export type ExecuteParams = {
  realm: string;
  flowSpec: FlowSpec;
  payload: string;
  trackingId: string;
  parameterizedReplica: Nullish<string>;
  replicaInstanceName: Nullish<string>;
};

type ExecuteResult = {
  testPipelineV2: string; // a stringified JSON
};

const execute = async (params: ExecuteParams): Promise<TestExecuteResult> => {
  try {
    const controller = new window.AbortController();

    controllers.push(controller);

    const response = await clientApollo.mutate<ExecuteResult>({
      mutation: gql`
        mutation testPipelineV2(
          $realm: String!
          $flowSpec: JSON!
          $payload: String!
          $trackingId: String!
          $replicaInstanceName: String
          $parameterizedReplica: String
        ) {
          testPipelineV2(
            realm: $realm
            flowSpec: $flowSpec
            payload: $payload
            trackingId: $trackingId
            replicaInstanceName: $replicaInstanceName
            parameterizedReplica: $parameterizedReplica
          )
        }
      `,
      variables: {
        ...params
      },
      context: { fetchOptions: { signal: controller.signal } }
    });

    const { data, message, status, ...header } = convertJson(
      response?.data?.testPipelineV2
    );

    if ([400, 409, 500].includes(status)) {
      if (typeof message === 'string') throw new Error(message);

      throw new Error('Problem sending to test');
    }

    if ([502, 504].includes(status) || data?.message === 'Timeout') {
      throw new Error('timeout');
    }

    if (!data && message) {
      throw new Error(message);
    }

    return {
      digibeeKey: header['X-Digibee-Key'],
      data
    };
  } catch (e) {
    if (e instanceof Error && isApolloError(e)) throw Error(e.message);

    throw e;
  }
};

// get messages

type GetMessagesParams = {
  realm: string;
  key: string;
  environment: string;
  limit?: number;
};

type GetMessagesResult = {
  messagesByKey: ExecutionMessage[];
};

const getMessages = async (params: GetMessagesParams) => {
  try {
    const response = await clientApollo.query<GetMessagesResult>({
      query: gql`
        query messagesByKey(
          $realm: String!
          $key: String
          $environment: String
        ) {
          messagesByKey(realm: $realm, key: $key, environment: $environment) {
            id
            body
            processorStepName
            processorType
            elapsed
            connectorId
            connectorName
            timestampStart
            timestampEnd
            indexes
          }
        }
      `,
      variables: {
        ...params
      }
    });
    return response.data;
  } catch (e) {
    throw new Error(e as string);
  }
};

// get logs

type GetLogsParams = {
  realm: string;
  key: string;
};

type GetLogsResult = {
  logs: ExecutionLog[];
};

const getLogs = async (params: GetLogsParams) => {
  try {
    const response = await clientApollo.query<GetLogsResult>({
      query: gql`
        query logs(
          $realm: String!
          $key: String!
          $time: String
          $env: String
          $logIdAfter: String
        ) {
          logs(
            realm: $realm
            key: $key
            time: $time
            env: $env
            logIdAfter: $logIdAfter
          ) {
            id
            pipelineName
            logMessage
            timestamp
            logLevel
          }
        }
      `,
      variables: {
        ...params
      }
    });
    return response.data;
  } catch (e) {
    throw new Error(e as string);
  }
};

const test = {
  execute,
  getMessages,
  getLogs,
  cancel: (): void => {
    controllers.map(controller => controller.abort());
  }
};

export default test;
