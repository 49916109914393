import React from 'react';
import styled from 'styled-components';

import IconNative from '~/components/IconCommunity';

const Icon = styled(IconNative)`
  color: ${props => props.theme.colors.pri400};
  margin-right: 16px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

export const LoadingMessage = () => (
  <Container>
    <Icon name='SpinnerThird' faType='solid' spin />
  </Container>
);
