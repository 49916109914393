/* eslint-disable */

import Immutable from 'seamless-immutable';

const initState = {
  text: '',
  success: false,
  danger: false,
  warning: false,
  open: false,
  timeout: 10000
};

const initialState = Immutable({
  toast: initState
});

const toast = {
  name: 'toast',
  state: initialState,
  reducers: {
    setToast(state, toast) {
      return state.merge({ toast });
    },
    reset(state) {
      return state.merge({ toast: initialState });
    }
  },
  effects: dispatch => ({
    showToast(payload) {
      dispatch.toast.setToast(payload);
      setTimeout(() => {
        dispatch.toast.reset();
      }, 5000);
    }
  }),
  logics: [
    {
      type: ['*'],
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        if (meta && meta.toast) {
          dispatch.toast.reset();
          dispatch.toast.setToast(meta.toast);
          setTimeout(() => {
            dispatch.toast.reset();
          }, meta.timeout || 5000);
        }
        done();
      }
    }
  ]
};

export default toast;
