import { connect } from 'react-redux';
import { compose } from 'recompose';

const mapDispatchToProps = ({ authentication }) => ({
  signinFirstAccess: authentication.signinFirstAccess
});

const mapStateToProps = ({ authentication }) => ({
  errorFirstAccess: authentication.errorFirstAccess,
  loading: authentication.loading
});

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhancer;
