import { Button as ButtonV2, Text as TextV2 } from '@digibee/beehive-ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './HelloJsAuthButton.elements';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Button from '~/components/Button';
import Text from '~/components/Text';

const HelloJsAuthButton = ({ state, actions, authenticated }) => {
  const { SETTINGS_ACCOUNTS_NEW_DS } = featureFlagConstants;
  const { treatments } = useFeatureFlag([SETTINGS_ACCOUNTS_NEW_DS]);

  const showAccountsV2 =
    treatments[SETTINGS_ACCOUNTS_NEW_DS].treatment === 'on';

  if (showAccountsV2) {
    return (
      <>
        <ButtonV2 outlined onClick={actions.authenticate} type='button'>
          <TextV2 css={{ color: '$pri500' }}>
            {state.error || state.authenticated || authenticated
              ? i18n.t('action.oauth_reauthenticate')
              : i18n.t('action.oauth_authenticate')}
          </TextV2>
        </ButtonV2>
        {state.error && (
          <Elements.ErrorMessage danger>{state.error}</Elements.ErrorMessage>
        )}
      </>
    );
  }

  return (
    <>
      <Button success onClick={actions.authenticate}>
        <Text color='white'>
          {state.error || state.authenticated || authenticated
            ? i18n.t('action.oauth_reauthenticate')
            : i18n.t('action.oauth_authenticate')}
        </Text>
      </Button>
      {state.error && (
        <Elements.ErrorMessage danger>{state.error}</Elements.ErrorMessage>
      )}
    </>
  );
};

HelloJsAuthButton.propTypes = {
  provider: PropTypes.shape({}).isRequired,
  state: PropTypes.shape({
    error: PropTypes.string,
    authenticated: PropTypes.bool
  }).isRequired,
  actions: PropTypes.shape({
    authenticate: PropTypes.func.isRequired
  }).isRequired,
  authenticated: PropTypes.bool.isRequired
};

export default HelloJsAuthButton;
