/* eslint-disable */
import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const licenseStatus = {
  fetch: async params => {
    const { data } = await clientApollo.query({
      query: gql`
        query licenseStatus(
          $environment: String!
          $realm: String!
          $replicas: Int!
          $pipelineId: String!
          $size: String!
        ) {
          licenseStatus(
            environment: $environment
            realm: $realm
            replicas: $replicas
            pipelineId: $pipelineId
            size: $size
          ) {
            licenseModel
            pipelineSubscriptionAndRtu
            totalRealmLicenses
            totalLicensesInUse
            totalAvailableLicenses
          }
        }
      `,
      variables: params
    });

    return data.licenseStatus;
  }
};

export default licenseStatus;
