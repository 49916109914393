import { BrowserRouter } from 'react-router-dom';

import Application from './components/Application';
import ReduxProvider from './components/ReduxProvider';

const App = () => (
  <BrowserRouter>
    <ReduxProvider>
      <Application />
    </ReduxProvider>
  </BrowserRouter>
);

export default App;
