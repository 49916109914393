import Connectivity from './Connectivity';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import asyncComponent from '~/components/AsyncComponent';
import Operation from '~/scenes/Others/scenes/App/Operation';

const Connections = asyncComponent(
  () => import('./Connectivity/scenes/Connections')
);

const Routes = asyncComponent(() => import('./Connectivity/scenes/Routes'));

export const ConnectivityRoutes = () => {
  const { CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT } = featureFlagConstants;
  const { treatments } = useFeatureFlag([CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT]);

  const enableConnectivityRoutes =
    treatments[CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT].treatment === 'on';

  if (enableConnectivityRoutes) {
    return [
      {
        path: '/:realm',
        element: <Operation />,
        title: 'Connectivity',
        children: [
          {
            path: 'connectivity',
            name: 'connectivity',
            element: <Connectivity />,
            children: [
              {
                path: 'edge-routers',
                name: 'edge-routers',
                element: <Connections />
              },
              {
                path: 'network-mapping',
                name: 'network-mapping',
                element: <Routes />
              }
            ]
          }
        ]
      }
    ];
  }

  return [];
};
