import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.height &&
    css`
      height: ${typeof props.height === 'number'
        ? `${props.height}px`
        : props.height};
    `};

  ${props =>
    props.withBorder &&
    css`
      border: 1px solid ${props.theme.colors.gra400};
    `};
`;

export const FieldLabel = styled.div`
  font-family: ${p => p.theme.typography.fontFamily};
  color: ${p => p.theme.colors.pri500};
  margin: 20px 0 7px;
  font-size: 14px;
`;

FieldLabel.displayName = 'FieldLabel';

export const HelperText = styled.div`
  position: relative;
  color: ${props =>
    props.danger ? props.theme.colors.red500 : props.theme.colors.gra700};

  ${props =>
    props.actived &&
    !props.danger &&
    css`
      color: ${({ theme }) => theme.colors.pri500};
    `};

  font-size: 12px;
  letter-spacing: 0.0333333333em;
  font-weight: 400;
  font-smoothing: antialiased;
  line-height: 24px;
  padding-left: 12px;
  box-sizing: border-box;
`;

HelperText.displayName = 'HelperText';
