import { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  activeRealm: StoreRootState['application']['realm'],
  user: StoreRootState['profile']['user'],
  token: StoreRootState['authentication']['userData']['token'],
  avatarTimestamp: StoreRootState['profile']['avatarTimestamp'],
  saveImageProfileLoading: StoreRootState['profile']['loading']['saveImageProfile'],

  setModalTwoFactor: StoreDispatcher['profile']['setModalTwoFactor'],
  setModalNewPassword: StoreDispatcher['profile']['setModalNewPassword'],
  setModalProfile: StoreDispatcher['profile']['setModalProfile'],
  onSignOut: StoreDispatcher['authentication']['signOut'],

  onChangeImage: (event: ChangeEvent<HTMLInputElement>) => void,
}

const enhancer = withHooks(() => {
  const { application, profile, authentication } = useSelector(
    (state: StoreRootState) => state
  );
  const dispatch = useDispatch<StoreDispatcher>();

  const props: Props = {
    activeRealm: application?.realm,
    user: profile?.user,
    token: authentication?.userData?.token,
    avatarTimestamp: profile.avatarTimestamp,
    saveImageProfileLoading: profile.loading.saveImageProfile,

    setModalTwoFactor: dispatch.profile.setModalTwoFactor,
    setModalNewPassword: dispatch.profile.setModalNewPassword,
    setModalProfile: dispatch.profile.setModalProfile,
    onSignOut: dispatch.authentication.signOut,
    onChangeImage: (event) => {
      const [file] = event?.target?.files as FileList;

      if (file) dispatch.profile.saveImageProfile({ file, id: profile?.user?.id });
    }
  }

  return props;
});

export default enhancer;
