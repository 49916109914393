import { theme } from '@digibee/beehive-ui';

/**
|--------------------------------------------------
| Structure
|--------------------------------------------------
*/

export const Container = theme.styled('div', {
  width: 'fit-content',
  display: 'flex',
  gap: '$1'
})

export const Circle = theme.styled('div', {
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  borderRadius: '$round',
  justifyContent: 'center',
  width: '$3',
  height: '$3',
  alignItems: 'center',
  compoundVariants: [
    {
      color: 'GREEN',
      active: false,
      css: {
        backgroundColor: '$neu100'
      }
    },
    {
      color: 'YELLOW',
      active: false,
      css: {
        backgroundColor: '$neu100'
      }
    },
    {
      color: 'RED',
      active: false,
      css: {
        backgroundColor: '$neu100'
      }
    }
  ],
  variants: {
    color: {
      GREEN: {
        backgroundColor: '$gre300',
      },
      YELLOW: {
        backgroundColor: '$yel300'
      },
      RED: {
        backgroundColor: '$red300'
      }
    },
    active: {
      false: {
        backgroundColor: '$neu100'
      },
    },
  }
})