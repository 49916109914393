import { iff } from '@digibee/control-statements';
import React from 'react';
import styled from 'styled-components';

import { DeployPipeline } from '../DeployPipeline';

import SideSheetNative from '~/components/SideSheet';

interface SideSheetProps {
  children: any;
  opened?: boolean;
  noScroll?: boolean;
  onCloseClick: Function;
}

const SideSheet = styled(SideSheetNative)<SideSheetProps>`
  width: 600px;
`;

interface SideSheetDeployPipelineProps {
  open?: boolean;
  realm?: string;
  environment?: string;
  createDeploy?: () => void;
  loading?: boolean;
  triggers?: { [key: string]: any };
  onCancel?: () => void;
  editPipelinePath?: string;
  find: () => void;
}

const SideSheetDeployPipeline: React.FC<SideSheetDeployPipelineProps> = ({
  open = false,
  realm = '',
  environment = '',
  createDeploy = () => {},
  loading = false,
  triggers = null,
  onCancel = () => {},
  editPipelinePath = '',
  find
}) => {
  if (open) {
    return (
      <DeployPipeline
        realm={realm}
        environment={environment}
        createDeploy={createDeploy}
        loading={loading}
        triggers={triggers}
        editPipelinePath={editPipelinePath}
        find={find}
        onCloseClick={onCancel}
      />
    );
  }

  return (
    <SideSheet noScroll opened={open} onCloseClick={onCancel}>
      {iff(open, () => (
        <DeployPipeline
          realm={realm}
          environment={environment}
          createDeploy={createDeploy}
          loading={loading}
          triggers={triggers}
          editPipelinePath={editPipelinePath}
          find={find}
        />
      ))}
    </SideSheet>
  );
};

export default SideSheetDeployPipeline;
