import { useNavigate } from 'react-router';

import withHooks from '~/common/hoc/withHooks';

const enhancer = withHooks(() => {
  const navigate = useNavigate();

  function onReturnToLogin() {
    return navigate('/login');
  }

  return {
    onReturnToLogin
  };
});

export default enhancer;
