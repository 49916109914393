/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';

export const FloatingLabel = styled.label`
  position: absolute;
  box-sizing: border-box;
  left: 14px;
  top: 15px;
  font-size: 14px;
  line-height: 1.15rem;
  text-align: left;
  transition: all 0.1s ease-in-out;
  cursor: text;
  transform-origin: left top;
  background: ${props =>
    props.secondary ? props.theme.colors.gra25 : props.theme.colors.white};
  will-change: transform;
  font-family: ${props => props.theme.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.009375em;
  text-overflow: ellipsis;
  ${props =>
    !props.isActived &&
    css`
      color: ${props => props.theme.colors.gra700};
    `}

  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.red500};
    `};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.gra400};
    `};
    
  ${props =>
    props.isActived &&
    !props.danger &&
    css`
      color: ${props.theme.colors.pri500} !important;
    `}
  
  ${props =>
    (props.isActived || props.isValue) &&
    css`
      font-size: 14px;
      top: 19px;
      transform: translate3d(0, -150%, 0) scale(0.75);
      background-color: ${props =>
        props.secondary ? props.theme.colors.gra25 : props.theme.colors.white};
      padding: 0 6px;
      left: 8px;
      z-index: 6;
    `};

    ${props =>
      (props.isActived || props.isValue) &&
      props.noFloatingLabel &&
      css`
        display: none;
      `};
}
`;
