import { FC, ReactNode } from 'react'
import * as Elements from './HelpText.elements'
import { Tooltip } from '@digibee/beehive-ui'

const HelpText: FC<{ text: string, children: ReactNode }> = ({ text, children }) => {
  return (
    <Elements.Container data-testid='help-text-container'>
      {children}
      <Tooltip
        align="start"
        content={text}
        side="bottom"
        data-testid='help-text-tooltip'
      >
        <Elements.HelpIcon icon='question-circle' data-testid='help-text-icon'/>
      </Tooltip>
    </Elements.Container>
  );
}

export default HelpText;
