import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const feedback = {
  create: params => {
    return clientApollo.mutate({
      mutation: gql`
        mutation createFeedback(
          $realm: String!,
          $email: String!,
          $message: String!,
          $path: String!
        ) {
          createFeedback(
            realm: $realm,
            email: $email,
            message: $message,
            path: $path
          )
        }
      `,
      variables: {
        ...params
      }
    })
  }
};

export default feedback;
