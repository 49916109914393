/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';
import { omit } from 'lodash';

const parameterizedInstances = {
  find: (realm, params) => {
    return clientApollo.query({
      query: gql`
        query parameterizedInstances($realm: String!, $page: Int, $size: Int) {
          parameterizedInstances(realm: $realm, page: $page, size: $size) {
            content {
              id
              label
              description
              fields
              instanceEnvironmentFieldValue
            }
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables: {
        realm,
        page: params.page,
        size: params.size,
      }
    });
  },
  get: (realm, name) => {
    return clientApollo.query({
      query: gql`
        query parameterizedInstance($realm: String!, $name: String) {
          parameterizedInstance(realm: $realm, name: $name) {
            id
            label
            description
            fields
            instanceEnvironmentFieldValue
          }
        }
      `,
      variables: {
        realm,
        name
      }
    });
  },
  remove: (realm, label) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteParameterizedInstances(
          $realm: String!
          $label: String!
        ) {
          deleteParameterizedInstances(realm: $realm, label: $label)
        }
      `,
      variables: {
        realm,
        label
      }
    });
  },
  createInstanceModel: instanceModel => {
    return clientApollo.mutate({
      mutation: gql`
        mutation createParameterizedInstances(
          $realm: String!
          $instanceModel: ParameterizedInstancesInput
        ) {
          createParameterizedInstances(
            realm: $realm
            instanceModel: $instanceModel
          )
        }
      `,
      variables: {
        instanceModel: omit(instanceModel, 'realm'),
        realm: instanceModel.realm
      }
    });
  },
  createInstanceConfiguration: (realm, replicaName, configuration) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation createParameterizedInstanceConfiguration(
          $realm: String!
          $replicaName: String!
          $configuration: JSON
        ) {
          createParameterizedInstanceConfiguration(
            realm: $realm
            replicaName: $replicaName
            configuration: $configuration
          )
        }
      `,
      variables: {
        configuration: configuration,
        replicaName: replicaName,
        realm: realm
      }
    });
  },
  updateInstanceConfiguration: (realm, replicaName, configuration) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation updateParameterizedInstanceConfiguration(
          $realm: String!
          $replicaName: String!
          $configuration: JSON
        ) {
          updateParameterizedInstanceConfiguration(
            realm: $realm
            replicaName: $replicaName
            configuration: $configuration
          )
        }
      `,
      variables: {
        configuration: configuration,
        replicaName: replicaName,
        realm: realm
      }
    });
  },
  updateInstanceFields: ({ realm, replicaName, fields }) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation updateInstanceFields(
          $realm: String!
          $replicaName: String!
          $fields: [String]
        ) {
          updateInstanceFields(
            realm: $realm
            replicaName: $replicaName
            fields: $fields
          )
        }
      `,
      variables: {
        fields,
        replicaName,
        realm
      }
    });
  },
  checkReplicaName: ({ realm, name }) => {
    return clientApollo.mutate({
      mutation: gql`
        mutation checkReplicaName(
          $realm: String!
          $name: String!
        ) {
          checkReplicaName(
            realm: $realm
            name: $name
          )
        }
      `,
      variables: {
        realm,
        name,
      }
    });
  }
};

export default parameterizedInstances;
