import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Userpilot } from 'userpilot';


import {
  RECAPTCHA_SITE_KEY,
  PORTAL_FULLSTORY_ORG_ID,
  USEPILOT_KEY,
  ACTIVATE_POLLING_PERMISSONS,
  POLLING_PERMISSIONS_INTERVAL
} from '~/common/helpers/environment';
import getEnvironmentsFromPathName from '~/common/helpers/getEnvironmentsToPathName';
import getRealmFromPathName from '~/common/helpers/getRealmToPathName';
import WebAnalytics from '~/common/helpers/webAnalytics';
import withHooks from '~/common/hoc/withHooks';
import usePolling from '~/common/hooks/usePolling';

const loadReCaptcha = siteKey => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};

const enhancer = withHooks(props => {
  const { pathname } = useLocation();
  const location = useLocation();
  const application = useSelector(state => state.application);
  const authentication = useSelector(state => state.authentication);
  const design = useSelector(state => state.design);
  const intercom = useSelector(state => state.intercom);
  const dispatch = useDispatch();
  const state = {
    isSignedIn: authentication.isSignedIn,
    userData: authentication.userData,
    loadingConnectors: design.loading,
    realm: application.realm.realm,
    hiddenPages: application.hiddenPages || []
  };

  useEffect(() => {
    performance.mark(location.pathname);
  }, []);

  useEffect(() => {
    loadReCaptcha(RECAPTCHA_SITE_KEY);
    Userpilot.initialize(USEPILOT_KEY);
  }, []);

  useEffect(() => {
    if (authentication?.userData) {
      Userpilot.reload();
    }
  }, [location]);

  useEffect(() => {
    if (authentication?.userData) {
      Userpilot.identify(authentication?.userData.email, {
        realm: authentication?.userData.realm,
        email: authentication?.userData.email,
        lastLogin: authentication?.userData.lastLogin,
        createdAt: authentication?.userData.createdAt,
        updatedAt: authentication?.userData.updatedAt
      });
    }

    if (PORTAL_FULLSTORY_ORG_ID) {
      WebAnalytics.init(authentication?.userData);
    }
  }, [intercom?.key]);

  useEffect(() => {
    if (state.isSignedIn) {
      const { realm } = getRealmFromPathName(pathname);
      const environment = getEnvironmentsFromPathName(pathname);

      if (environment) dispatch.application.setEnvironments(environment);

      if (realm) {
        dispatch.application.setRealm({ realm });

        if (application.activeRealm !== realm) {
          dispatch.application.changeRealm({ realm });
          dispatch.application.getTogaiToken(realm);
        }
      } else if (state.userData) {
        const pipelinesPath = `/${state.userData.realm}/operation/build/pipelines`;

        dispatch.router.navigate({ to: pipelinesPath });
      }
    } else if (!/(\/login|\/oauth)/.test(pathname)) {
      dispatch.router.navigate({ to: '/login' });
    }
  }, [pathname]);

  usePolling(
    () => dispatch.acls.fetch(),
    POLLING_PERMISSIONS_INTERVAL,
    ACTIVATE_POLLING_PERMISSONS === 'true' && state.userData && state.realm
  );

  useEffect(() => {
    if (state.userData && state.realm) {
      dispatch.application.initialization();
    }
  }, [state.realm]);

  return {
    ...props,
    ...state
  };
});

export default enhancer;
