/* eslint-disable */
import Immutable from 'seamless-immutable';
import { toast } from 'react-toastify';

// Locals
import { test } from '~/api';
import { prepare } from './pipelineExperimental';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  isOpen: false,
  field: {
    code: {
      value: '{}',
      error: false
    },
    instance: null
  },
  size: 0,
  success: {
    test: {
      data: '',
      digibeeKey: ''
    }
  },
  loading: {
    test: false
  }
});

const testModel = {
  name: 'test',
  state: initialState,
  reducers: {
    setOpen: (state, isOpen = true) => state.merge({ isOpen }),
    setFieldCode: (state, payload) => {
      return state.merge(
        {
          field: {
            code: payload
          }
        },
        { deep: true }
      );
    },
    setFieldMultiInstance(state, payload) {
      return state.merge(
        {
          field: {
            instance: payload
          }
        },
        { deep: true }
      );
    },
    setSuccess: (state, { path, value }) =>
      state.merge({
        success: {
          [path]: value
        }
      }),
    setFailed: (state, { path, value }) =>
      state.merge(
        {
          failed: {
            [path]: value
          }
        },
        { deep: true }
      ),
    setLoading: (state, { path, value }) =>
      state.merge(
        {
          loading: {
            [path]: value
          }
        },
        { deep: true }
      ),
    reset: () => initialState
  },
  effects: dispatch => ({
    cancel() {
      test.cancel();
    },

    async execute(payload, state) {
      try {
        const { code, instance } = state.test.field;
        const { realm } = state.application.realm;
        const { root } = state.cy.present.toJS();
        const { trackingId } = state.design;
        const { data } = state.pipelineExperimental;
        const responseTest = await test.execute({
          canvas: prepare(root),
          realm,
          trackingId,
          payload: code.value,
          parameterizedReplica: data.pipeline.parameterizedReplica,
          replicaInstanceName: instance
        });
        dispatch.test.setSuccess({
          path: 'test',
          value: responseTest
        });
      } catch (e) {
        dispatch.test.setFailed({
          path: 'failed',
          value: e.message
        });
        if (e.message == 'noun.test_mode_time_limit_exceeded') {
          toast.warn(i18n.t(e.message), { autoClose: false });
        } else {
          toast.error(e.message);
        }
      }
    },

    run() {
      dispatch.design.setOpenTestMode();
      dispatch.test.execute();
    },

    toggle(payload, state) {
      dispatch.design.setOpenTestMode(!state.design.isOpenTestMode);
    }
  }),
  logics: [
    {
      type: 'test/execute',
      latest: true,
      process(context, dispatch, done) {
        dispatch.test.setLoading({ path: 'test', value: true });
        dispatch.logs.reset();
        dispatch.message.reset();
        done();
      }
    },
    {
      type: 'test/setSuccess',
      latest: true,
      process({ action }, dispatch, done) {
        const { digibeeKey } = action.payload.value;
        dispatch.test.setLoading({ path: 'test', value: false });
        dispatch.logs.fetchLogs({ key: digibeeKey });
        dispatch.message.fetchMessages({ key: digibeeKey });
        dispatch.logs.reset();
        dispatch.message.reset();
        done();
      }
    },
    {
      type: 'test/setFailed',
      latest: true,
      process(context, dispatch, done) {
        dispatch.test.setLoading({ path: 'test', value: false });
        done();
      }
    },
    {
      type: 'test/reset',
      latest: true,
      process(context, dispatch, done) {
        dispatch.logs.reset();
        dispatch.message.reset();
        done();
      }
    }
  ]
};

export default testModel;
