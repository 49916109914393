/* eslint-disable */

import Immutable from 'seamless-immutable';
import { parameterizedInstances } from '~/api';
import { map, omit } from 'lodash';
// Locals
import i18n from '~/common/helpers/i18n';

const initEditState = {
  label: '',
  description: '',
  fields: []
};

const initialState = Immutable({
  page: 0,
  item: null,
  errorFind: null,
  loading: false,
  loadingInstance: false,
  successInstance: false,
  success: null,
  error: null,
  errorInstance: null,
  paramsInstancesModal: false,
  editInstance: initEditState,
  instanceConfigurationModal: {
    opened: false,
    newConfiguration: false,
    model: null,
    selectedConfiguration: null
  }
});

const parameterizedInstancesModel = {
  name: 'parameterizedInstances',
  state: initialState,
  reducers: {
    newConfiguration(state) {
      return state.merge({
        ...state,
        instanceConfigurationModal: {
          ...state.instanceConfigurationModal,
          selectedConfiguration: null,
          newConfiguration: true
        }
      });
    },
    selectConfiguration(state, configuration) {
      return state.merge({
        ...state,
        instanceConfigurationModal: {
          ...state.instanceConfigurationModal,
          selectedConfiguration: configuration,
          newConfiguration: false
        }
      });
    },
    successFind(state, payload) {
      return state.merge({
        item: payload
      });
    },
    setErrorFind(state, errorFind) {
      return state.merge({
        errorFind
      });
    },
    loading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    error(state, payload) {
      return state.merge({
        error: payload
      });
    },
    loadingInstance(state, payload) {
      return state.merge({
        loadingInstance: payload
      });
    },
    errorInstance(state, payload) {
      return state.merge({
        errorInstance: payload
      });
    },
    successInstance(state, payload) {
      return state.merge({
        successInstance: payload,
        errorInstance: null
      });
    },
    success(state, payload) {
      return state.merge({
        success: payload,
      });
    },
    paramsInstancesModal(state, payload) {
      return state.merge({
        paramsInstancesModal: payload
      });
    },
    toggleInstanceConfigurationModal(state, model) {
      return state.merge({
        instanceConfigurationModal: {
          opened: !state.instanceConfigurationModal.opened,
          model: model
        }
      });
    },
    setModel(state, model) {
      return state.merge({
        instanceConfigurationModal: {
          ...state.instanceConfigurationModal,
          model: model
        }
      });
    },
    setPage(state, page) {
      return state.merge({
        page
      });
    },
    setEditInstance(state, payload) {
      return state.merge({
        editInstance: payload
      });
    }
  },
  effects: dispatch => ({
    async find(params = { size: 10 }, state) {
      try {
        dispatch.parameterizedInstances.setPage(params.page ?? 0);
        params.page = params.page ?? state.parameterizedInstances.page;
        const { data } = await parameterizedInstances.find(state.application.realm.realm, params);
        dispatch.parameterizedInstances.successFind(
          data.parameterizedInstances
        );
      } catch (e) {
        dispatch.parameterizedInstances.setErrorFind(e);
      }
    },
    async selectModel({ realm, name }) {
      const { data } = await parameterizedInstances.get(realm, name);
      dispatch.parameterizedInstances.toggleInstanceConfigurationModal(
        data.parameterizedInstance
      );
    },
    async syncModel({ realm, name }) {
      const { data } = await parameterizedInstances.get(realm, name);
      dispatch.parameterizedInstances.setModel(data.parameterizedInstance);
    },
    async remove({ realm, label }) {
      try {
        await parameterizedInstances.remove(realm, label);
        dispatch.parameterizedInstances.success(
          {},
          {
            snackbar: {
              text: i18n.t(
                'label.delete_multi_instance_msg_success', {
                multiinstanceName: label,
              }),
              success: true,
              open: true
            },
            find: true
          }
        );

        // create_multi_instance_model
      } catch (e) {
        if (e.message === 'Parameterized replica in use') {
          dispatch.parameterizedInstances.error(e, {
            snackbar: {
              text: i18n.t('label.delete_multi_instance_msg_alert', {
                multiinstanceName: label,
              }),
              danger: true,
              open: true
            }
          });
          return
        }
        dispatch.parameterizedInstances.error(e, {
          snackbar: {
            text: i18n.t(
              'scenes.parameterized_instances.messages.error.delete_multi_instance_model'
            ),
            danger: true,
            open: true
          }
        });
      }
    },
    editParameterizedInstances(instance) {
      dispatch.parameterizedInstances.setEditInstance(instance);
    },
    activedParamsInstancesModal(visible) {
      dispatch.parameterizedInstances.paramsInstancesModal(visible);
    },
    async createInstanceModel(params) {
      try {
        const requestSuccess = await parameterizedInstances.createInstanceModel(params);

        dispatch.parameterizedInstances.success(requestSuccess, {
          snackbar: {
            text: i18n.t(
              'label.create_multi_instance_msg_success', {
              multiinstanceName: params.label,
            }),
            success: true,
            open: true
          },
          find: true
        });
        params.callback();
      } catch (e) {
        dispatch.parameterizedInstances.error(e, {
          snackbar: {
            text: i18n.t(
              'scenes.parameterized_instances.messages.error.create_multi_instance_model'
            ),
            danger: true,
            open: true
          }
        });
      }
    },
    async createInstanceConfiguration(
      { replicaName, configuration },
      { application }
    ) {
      try {
        const requestSuccess =
          await parameterizedInstances.createInstanceConfiguration(
            application.realm.realm,
            configuration,
            replicaName
          );
        dispatch.parameterizedInstances.successInstance(
          { ...requestSuccess, replicaName },
          {
            snackbar: {
              text: i18n.t(
                'label.save_instance_msg_success', {
                instanceName: replicaName.instanceName
              }
              ),
              success: true,
              open: true
            },
            find: true
          }
        );
      } catch (e) {
        dispatch.parameterizedInstances.errorInstance(e, {
          snackbar: {
            text: e.message,
            danger: true,
            open: true
          }
        });
      }
    },
    async updateInstanceConfiguration(
      { replicaName, configuration },
      { application }
    ) {
      try {
        const requestSuccess =
          await parameterizedInstances.updateInstanceConfiguration(
            application.realm.realm,
            configuration,
            replicaName
          );

        dispatch.parameterizedInstances.successInstance(requestSuccess, {
          snackbar: {
            text: i18n.t(
              'label.save_instance_msg_success', {
              instanceName: replicaName.instanceName,
            }
            ),
            success: true,
            open: true
          },
          find: true
        });
      } catch (e) {
        dispatch.parameterizedInstances.errorInstance(e, {
          snackbar: {
            text: e.message,
            danger: true,
            open: true
          }
        });
      }
    },
    async updateInstanceFields(params) {
      try {
        const requestSuccess =
          await parameterizedInstances.updateInstanceFields(
            omit(
              Object.assign(params, {
                replicaName: params.label
              }),
              ['description', 'instanceEnvironmentFieldValue', 'id']
            )
          );
        dispatch.parameterizedInstances.success(requestSuccess, {
          snackbar: {
            text: i18n.t(
              'label.edit_multi_instance_msg_success', {
              multiinstanceName: params.label,
            }),
            success: true,
            open: true
          }
        });
        params.callback();
      } catch (e) {
        dispatch.parameterizedInstances.error(e, {
          snackbar: {
            text: e.message,
            danger: true,
            open: true
          }
        });
      }
    }
  }),
  logics: [
    {
      type: [
        'parameterizedInstances/updateInstanceConfiguration',
        'parameterizedInstances/createInstanceConfiguration'
      ],
      latest: true,
      process(context, dispatch, done) {
        dispatch.parameterizedInstances.loadingInstance(true);
        done();
      }
    },
    {
      type: 'parameterizedInstances/find',
      latest: true,
      process(context, dispatch, done) {
        dispatch.parameterizedInstances.loading(true);
        done();
      }
    },
    {
      type: [
        'parameterizedInstances/successInstance',
        'parameterizedInstances/errorInstance'
      ],
      latest: true,
      process(context, dispatch, done) {
        dispatch.parameterizedInstances.loadingInstance(false);
        done();
      }
    },
    {
      type: [
        'parameterizedInstances/successFind',
        'parameterizedInstances/setErrorFind'
      ],
      latest: true,
      process(context, dispatch, done) {
        dispatch.parameterizedInstances.loading(false);
        done();
      }
    },
    {
      type: 'parameterizedInstances/success',
      latest: true,
      process({ getState }, dispatch, done) {
        dispatch.parameterizedInstances.paramsInstancesModal(false);
        dispatch.parameterizedInstances.find({
          realm: getState().application.realm.realm,
        });
        done();
      }
    },
    {
      type: 'parameterizedInstances/activedParamsInstancesModal',
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.parameterizedInstances.setEditInstance(initEditState);
        done();
      }
    },
    {
      type: ['parameterizedInstances/successInstance'],
      latest: true,
      process({ action, getState }, dispatch, done) {
        const { payload } = action;
        if (payload.replicaName) {
          dispatch.parameterizedInstances.selectConfiguration({
            name: payload.replicaName.instanceName,
            values: payload.replicaName.fieldValues
          });
        }

        const { application, parameterizedInstances } = getState();
        dispatch.parameterizedInstances.syncModel({
          realm: application.realm.realm,
          name: parameterizedInstances.instanceConfigurationModal.model.label
        });
        done();
      }
    }
  ]
};

export default parameterizedInstancesModel;
