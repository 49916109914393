import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon from '../IconCommunity';
import Text from '../Text';

const Container = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 24px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  max-width: 480px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  align-self: center;
`;

const Ilustration = styled(Icon)`
  width: 1em !important;
  font-size: 48px;
  margin-bottom: 16px;
  color: ${p => p.theme.colors.gra50};
`;

const CustomIlustration = styled.div`
  margin-bottom: 16px;
`;

const Description = styled(Text)`
  margin: 0;
  font-size: 12px;
  color: ${p => p.theme.colors.gra600};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const InnerEmpty = ({
  icon,
  text,
  customIlustration,
  'data-testid': dataTestId,
  ...props
}) => (
  <Container data-testid={dataTestId}>
    <Content>
      {!customIlustration && <Ilustration name={icon} {...props} />}
      {customIlustration && (
        <CustomIlustration>{customIlustration}</CustomIlustration>
      )}
      <Description>{text}</Description>
    </Content>
  </Container>
);

InnerEmpty.defaultProps = {
  customIlustration: false,
  'data-testid': 'empty'
};

InnerEmpty.propTypes = {
  text: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  customIlustration: PropTypes.node,
  'data-testid': PropTypes.string
};

export default InnerEmpty;
