/* eslint-disable */

import { gql } from '@apollo/client';
import axios from 'axios';
import clientApollo from './clientApollo';
import {
  PIPELINE_INTERCOM_FALLBACK_APIKEY,
  PIPELINE_INTERCOM_FALLBACK_ENDPOINT
} from '~/common/helpers/environment';

const intercom = {
  create: () => {
    return clientApollo.query({
      query: gql`
        query intercom {
          intercom
        }
      `
    });
  },
  async createFallbackMessage(params) {
    try {
      const { data } = await axios.post(
        PIPELINE_INTERCOM_FALLBACK_ENDPOINT,
        params,
        {
          headers: {
            apikey: PIPELINE_INTERCOM_FALLBACK_APIKEY
          }
        }
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default intercom;
