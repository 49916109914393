import {
  Box,
  Icon,
  IconButton,
  InputNumber,
  Select,
  SideSheet,
  Table,
  Text,
  GroupButton,
  Button
} from '@digibee/beehive-ui';
import { FormikContextType } from 'formik';
import { head } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { DeploymentPlanItem, EnvironmentConfiguration } from '../../../types';

import api from '~/api/runtime';
import i18n from '~/common/helpers/i18n';

const normalizeNameConfiguration = (name: string) => head(name?.split('-'));
interface TableDeploymentPlanProps {
  deploymentPlanItems: DeploymentPlanItem[];
  loading: boolean;
  deleteItem: (name: string) => void;
  formik: FormikContextType<any>;
}

type ConfigType = {
  name: string;
  id: string;
  actualConsumers: number;
  consumers: number;
  description: string;
};

export const Footer = styled.div`
  margin: 34px 0px;
`;

const TableConfiguration: React.FC<TableDeploymentPlanProps> = ({
  deploymentPlanItems,
  loading,
  deleteItem,
  formik
}) => {
  const [openTableId, setOpenTableId] = useState('');
  const [errorReplicas, setErrorReplicas] = useState(false);
  const { application } = useSelector((state: any) => state);

  function redirect(idPipeline: string) {
    return window.open(
      `/${application?.activeRealm}/design/v2/pipelines/${idPipeline}`,
      '_blank'
    );
  }

  function changeEnvironmentConfigurationForm(
    indexDeploymentPlanItems: number,
    index: number,
    prop: string,
    newValue: any
  ) {
    formik.setFieldValue(
      `deploymentPlanItems[${indexDeploymentPlanItems}].environmentConfiguration[${index}]${prop}`,
      newValue
    );
  }

  function changeSize(
    env: EnvironmentConfiguration,
    newValue: string,
    indexDeploymentPlanItems: number,
    index: number
  ) {
    const config: ConfigType = env.runtimeConfiguration.configurations.find(
      (i: ConfigType) => i.name === newValue
    );
    changeEnvironmentConfigurationForm(
      indexDeploymentPlanItems,
      index,
      '.runtimeConfiguration',
      {
        ...env.runtimeConfiguration,
        name: newValue,
        size: newValue,
        description: config.description,
        maxSize: config.consumers,
        id: config.id
      }
    );
  }

  function changeMinorVersion(
    prodIndex: number,
    indexDeploymentPlanItems: number,
    envIndex: number,
    data: { value: string; versionMinor: number }
  ) {
    const environmentConfiguration =
      formik?.values.deploymentPlanItems[indexDeploymentPlanItems]
        .environmentConfiguration[envIndex];

    const newObj = {
      runtimeConfiguration: {
        ...environmentConfiguration.runtimeConfiguration,
        minorVersionsId: data
      },
      pipeline: {
        ...environmentConfiguration.pipeline,
        id: data.value,
        versionMinor: data.versionMinor
      }
    };

    changeEnvironmentConfigurationForm(indexDeploymentPlanItems, envIndex, '', {
      ...environmentConfiguration,
      ...newObj
    });

    const environmentConfigurationProd =
      formik.values.deploymentPlanItems[indexDeploymentPlanItems]
        .environmentConfiguration[prodIndex];

    changeEnvironmentConfigurationForm(
      indexDeploymentPlanItems,
      prodIndex,
      '',
      { ...environmentConfigurationProd, ...newObj }
    );
  }

  const checkLicense = async (variant: any) => {
    if (!variant?.size) return;

    const { data } = await api.checkLicense({
      ...variant
    });

    // eslint-disable-next-line consistent-return
    return data?.checkLicense?.analysis;
  };

  async function changeReplicas(
    indexDeploymentPlanItems: number,
    index: number,
    prop: string,
    newValue: any
  ) {
    const environmentConfiguration =
      formik.values.deploymentPlanItems[indexDeploymentPlanItems]
        .environmentConfiguration[index];

    const licenseValid = await checkLicense({
      environment: environmentConfiguration.environment.name,
      pipelineId:
        environmentConfiguration.runtimeConfiguration.minorVersionsId.value,
      size: normalizeNameConfiguration(
        environmentConfiguration.runtimeConfiguration.size
      ),
      replicas: parseInt(newValue, 10),
      replicaInstanceName: '',
      realm: application.activeRealm
    });

    if (licenseValid?.length > 0) {
      formik.setFieldError('deploymentPlanItems', '');
      setErrorReplicas(true);
      return;
    }
    setErrorReplicas(false);
    changeEnvironmentConfigurationForm(
      indexDeploymentPlanItems,
      index,
      prop,
      newValue
    );
  }

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.Row>
            <Table.Cell>{i18n.t('label.pipeline_name_vers_two')}</Table.Cell>
            <Table.Cell>{i18n.t('label.project')}</Table.Cell>
            <Table.Cell>{i18n.t('label.major_version')}</Table.Cell>
            <Table.Cell>{i18n.t('label.actions')}</Table.Cell>
          </Table.Row>
        </Table.Head>
        {loading ? (
          <Table.Loading rows={5} cols={4} />
        ) : (
          <Table.Body>
            {deploymentPlanItems?.map((planItem, indexDeploymentPlanItems) => (
              <>
                <Table.Row key={planItem.pipelineMajorVersion.name.toString()}>
                  <Table.Cell>
                    <Text
                      as='p'
                      css={{
                        _truncate: '$12'
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (
                            planItem.pipelineMajorVersion.name === openTableId
                          ) {
                            setOpenTableId('');
                            return;
                          }

                          setOpenTableId(
                            planItem.pipelineMajorVersion.name.toString()
                          );
                        }}
                        type='button'
                        size='medium'
                      >
                        <Icon
                          icon={`angle-${
                            openTableId === planItem.pipelineMajorVersion.name
                              ? 'up'
                              : 'down'
                          }`}
                          size='md'
                          variant='primary'
                        />
                      </IconButton>
                      {planItem.pipelineMajorVersion.name}
                    </Text>
                  </Table.Cell>
                  <Table.Cell> {planItem.projectName}</Table.Cell>
                  <Table.Cell>
                    {' '}
                    V{planItem.pipelineMajorVersion.majorVersion}
                  </Table.Cell>
                  <Table.Cell>
                    <IconButton
                      type='button'
                      onClick={() => redirect(planItem.pipelineId)}
                      size='medium'
                      data-testid='run-button-action-show-pipeline'
                    >
                      <Icon icon='external-link' size='md' variant='primary' />
                    </IconButton>
                    <IconButton
                      size='medium'
                      data-testid='run-button-action-show-pipeline'
                      onClick={() => {
                        deleteItem(
                          planItem.pipelineMajorVersion.name.toString()
                        );
                        formik.setFieldValue(
                          'deploymentPlanItems',
                          deploymentPlanItems.filter(
                            deploymentPlanItem =>
                              deploymentPlanItem.pipelineMajorVersion.name !==
                              planItem.pipelineMajorVersion.name
                          )
                        );
                      }}
                    >
                      <Icon icon='trash-alt' size='md' variant='primary' />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>{' '}
                {openTableId === planItem.pipelineMajorVersion.name && (
                  <>
                    <Table.Row>
                      <td
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        size='lg'
                        colSpan={5}
                      >
                        {planItem.environmentConfiguration.map(
                          (env: EnvironmentConfiguration, index: number) => (
                            <>
                              <SideSheet.Section
                                title={i18n.t(
                                  'label.env_name_as_variable_environment',
                                  {
                                    environmentName: env.environment.name
                                  }
                                )}
                                subTitle={i18n.t(
                                  'label.configure_pipe_environm_variable_explanation',
                                  {
                                    environmentName: env.environment.name
                                  }
                                )}
                                key={env.environment.name}
                              >
                                <Box>
                                  <Box
                                    css={{
                                      display: 'flex',
                                      gap: '$6',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Box
                                      css={{
                                        padding: '$2',
                                        width: '14rem'
                                      }}
                                    >
                                      <Select
                                        label={i18n.t('label.minor_version')}
                                        value={
                                          env?.runtimeConfiguration
                                            ?.minorVersionsId
                                        }
                                        options={
                                          env?.runtimeConfiguration
                                            ?.minorVersions
                                        }
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        onChange={data => {
                                          const prodIndex =
                                            planItem.environmentConfiguration.findIndex(
                                              envs =>
                                                envs.environment.name === 'prod'
                                            );

                                          changeMinorVersion(
                                            prodIndex,
                                            indexDeploymentPlanItems,
                                            index,
                                            data
                                          );
                                        }}
                                        isDisabled={
                                          env?.environment?.name === 'prod'
                                        }
                                      />
                                    </Box>
                                    <Box css={{ padding: '$2' }}>
                                      <Text
                                        as='h4'
                                        css={{
                                          marginBottom: '$2',
                                          fontSize: '$2'
                                        }}
                                      >
                                        {i18n.t('label.pipeline_size')}
                                      </Text>
                                      <GroupButton>
                                        {env?.runtimeConfiguration?.configurations.map(
                                          (config: ConfigType) => {
                                            const name =
                                              normalizeNameConfiguration(
                                                config?.name
                                              );

                                            return (
                                              <Button
                                                key={config?.name}
                                                size='large'
                                                variant={
                                                  env?.runtimeConfiguration
                                                    ?.size === config.name
                                                    ? 'primary'
                                                    : 'secondary'
                                                }
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                type='button'
                                                onClick={() =>
                                                  changeSize(
                                                    env,
                                                    config.name,
                                                    indexDeploymentPlanItems,
                                                    index
                                                  )
                                                }
                                              >
                                                {name
                                                  ?.toString()
                                                  ?.toUpperCase()}
                                              </Button>
                                            );
                                          }
                                        )}
                                      </GroupButton>
                                    </Box>
                                  </Box>
                                  <Box
                                    css={{
                                      display: 'flex',
                                      marginTop: '$3',
                                      gap: '$6'
                                    }}
                                  >
                                    <Box css={{ padding: '$2' }}>
                                      <InputNumber
                                        max={parseInt(
                                          env?.runtimeConfiguration?.maxSize,
                                          10
                                        )}
                                        min={1}
                                        value={
                                          env?.runtimeConfiguration
                                            ?.actualConsumers
                                        }
                                        onChange={e => {
                                          changeEnvironmentConfigurationForm(
                                            indexDeploymentPlanItems,
                                            index,
                                            '.runtimeConfiguration.actualConsumers',
                                            e
                                          );
                                        }}
                                        label={i18n.t(
                                          'label.concurrent_executions'
                                        )}
                                        step={1}
                                      />
                                      <Text variant='b3'>
                                        Min 1/ Max{' '}
                                        {env?.runtimeConfiguration?.maxSize}
                                      </Text>
                                    </Box>
                                    <Box css={{ padding: '$2' }}>
                                      <InputNumber
                                        min={1}
                                        value={
                                          env?.runtimeConfiguration
                                            ?.replicaCount
                                        }
                                        label={i18n.t('label.replicas')}
                                        invalid={errorReplicas}
                                        helperText={
                                          errorReplicas
                                            ? i18n.t(
                                                'label.licenses_exceeded_assist_error'
                                              )
                                            : ''
                                        }
                                        onChange={e => {
                                          if (
                                            parseInt(e as string, 10) < 99999999
                                          ) {
                                            changeReplicas(
                                              indexDeploymentPlanItems,
                                              index,
                                              '.runtimeConfiguration.replicaCount',
                                              e
                                            );
                                          }
                                        }}
                                        step={1}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </SideSheet.Section>
                            </>
                          )
                        )}
                      </td>
                    </Table.Row>
                  </>
                )}
              </>
            ))}
          </Table.Body>
        )}
      </Table.Root>
    </>
  );
};

export default TableConfiguration;
