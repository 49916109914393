import { isApolloError, gql } from '@apollo/client';

import clientApollo from './clientApollo';

import { Pagination } from '~/common/types/Pagination';
import { Credentials } from '~/entities/Credential';

export type CredentialsInput = {
  username: string;
  password: string;
};

type Credential = {
  id: string;
  realm: string;
  username: string;
  apiKey: string;
  creationDate: string;
  disabled: boolean;
  apiV1Url: string;
};

type CredentialUrl = {
  apiV1Url: string;
};

type CredentialSaveData = {
  realm: string;
  environment: string;
  credential: CredentialsInput;
};

type SaveCredentialResult = {
  createCredentials: Credential;
};

type CredentialFetchData = {
  realm: string;
  environment: string;
  page?: number;
  size?: number;
  search?: string;
};

type CredentialsFetchAllResult = {
  getCredentials: Pagination<Credentials>;
};

type CredentialUrlFetchAllResult = {
  getCredentialsUrl: CredentialUrl;
};

type RemoveCredentialData = {
  realm: string;
  environment: string;
  id: string;
};

type RemoveCredentialResult = {
  deleteCredentials: boolean;
};

const credentials = {
  getCredentials: async ({
    realm,
    environment,
    page,
    size = 20,
    search
  }: CredentialFetchData) => {
    try {
      const result = await clientApollo.query<CredentialsFetchAllResult>({
        query: gql`
          query getCredentials(
            $realm: String!
            $environment: String!
            $page: Int
            $size: Int
            $search: String
          ) {
            getCredentials(
              realm: $realm
              environment: $environment
              page: $page
              size: $size
              search: $search
            )
          }
        `,
        variables: {
          realm,
          environment,
          page,
          size,
          search
        }
      });

      return result.data?.getCredentials;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getCredentialsUrl: async ({ realm, environment }: CredentialFetchData) => {
    try {
      const result = await clientApollo.query<CredentialUrlFetchAllResult>({
        query: gql`
          query getCredentialsUrl($realm: String!, $environment: String!) {
            getCredentialsUrl(realm: $realm, environment: $environment)
          }
        `,
        variables: {
          realm,
          environment
        }
      });

      return result.data?.getCredentialsUrl;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  createCredentials: async ({
    realm,
    environment,
    credential
  }: CredentialSaveData) => {
    try {
      const result = await clientApollo.mutate<SaveCredentialResult>({
        mutation: gql`
          mutation createCredentials(
            $realm: String!
            $environment: String!
            $credential: JSON
          ) {
            createCredentials(
              realm: $realm
              environment: $environment
              credential: $credential
            )
          }
        `,
        variables: {
          realm,
          environment,
          credential
        }
      });
      return result.data?.createCredentials;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  deleteCredentials: async ({
    realm,
    environment,
    id
  }: RemoveCredentialData) => {
    try {
      const result = await clientApollo.mutate<RemoveCredentialResult>({
        mutation: gql`
          mutation deleteCredentials(
            $realm: String!
            $environment: String!
            $id: String!
          ) {
            deleteCredentials(realm: $realm, environment: $environment, id: $id)
          }
        `,
        variables: {
          realm,
          environment,
          id
        }
      });

      return result.data?.deleteCredentials;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default credentials;
