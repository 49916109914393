import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import Button from '~/components/Button';
import Text from '~/components/Text';
import ThemeProvider from '~/components/ThemeProvider';
/**
|--------------------------------------------------
| Styles
|--------------------------------------------------
*/

const Container = styled.div`
  position: absolute;
  width: 456px;
  height: 64px;
  left: calc(50% - 228px + ${props => props.offsetX || 0}px);
  bottom: calc(16px + ${props => -props.offsetY || 0}px);
  background: ${props => props.theme.colors.gra800};
  color: ${props => props.theme.colors.white};
  z-index: 999999999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 16px;
  border-radius: ${props => props.theme.borderRadius.mega};

  ${({ snackbarType }) =>
    snackbarType === 'error' &&
    css`
      background: ${props => props.theme.colors.red50};
      color: ${props => props.theme.colors.gra900};
    `};
`;

const MessageArea = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ActionArea = styled.div`
  margin-left: 8px;
`;

const StyledButton = styled(Button)`
  color: ${props => props.theme.colors.pri100};

  ${({ snackbarType }) =>
    snackbarType === 'error' &&
    css`
      color: ${props => props.theme.colors.red600};
    `};
`;

StyledButton.defaultProps = {
  'data-testid': 'action-button'
};

const Message = styled(Text)`
  line-height: 20px;
  color: ${props => props.theme.colors.white};
  ${({ snackbarType }) =>
    snackbarType === 'error' &&
    css`
      color: ${props => props.theme.colors.gra900};
    `};
`;

Message.defaultProps = {
  'data-testid': 'message'
};

/**
|--------------------------------------------------
| Snackbar
|--------------------------------------------------
*/

const Snackbar = ({
  visible,
  freeze,
  reset,
  text,
  actionLabel,
  onActionClick,
  offsetX,
  offsetY,
  type,
  'data-testid': dataTestId
}) => {
  if (!visible) return null;

  return (
    <ThemeProvider>
      <Container
        onMouseEnter={freeze}
        onMouseLeave={reset}
        offsetX={offsetX}
        offsetY={offsetY}
        snackbarType={type}
        data-testid={dataTestId}
      >
        <MessageArea title={text}>
          <Message as='span' weight='regular' align='left' snackbarType={type}>
            {text}
          </Message>
        </MessageArea>
        {actionLabel && (
          <ActionArea>
            <StyledButton
              snackbarType={type}
              type='link'
              onClick={onActionClick}
              data-testid='action-button'
            >
              {actionLabel}
            </StyledButton>
          </ActionArea>
        )}
      </Container>
    </ThemeProvider>
  );
};

Snackbar.defaultProps = {
  type: 'default',
  'data-testid': 'snackbar'
};

Snackbar.propTypes = {
  visible: PropTypes.bool.isRequired,
  freeze: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['default', 'error']),
  'data-testid': PropTypes.string
};

export default Snackbar;
