import { Link as LinkNative } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Icon from '~/components/IconCommunity';
import Text from '~/components/Text';

export const Container = styled.div`
  width: 216px;
  border-right: 1px solid ${props => props.theme.colors.gra300};
  height: 100%;
  overflow: auto;
  padding-bottom: 48px;
`;

export const Badger = styled.div`
  display: block;
  font-size: 10px;
  line-height: 15px;
  border-radius: 100px;
  color: gra900;
  cursor: text;
  padding: 0 6px;
  font-weight: 400;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  font-family: ${props => props.theme.typography.fontFamily};
  height: 16px;
  background-color: ${props =>
    props.isBadgeAlpha
      ? props?.theme?.colors?.blu100
      : props?.theme?.colors?.pri100};
  pointer-events: none;
  cursor: default;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: ${props =>
    props.isBadgeAlpha
      ? props?.theme?.colors?.blu500
      : props?.theme?.colors?.gra900};
  margin-left: 1px;
  margin-top: -10px;
`;

export const Link = styled(props => <LinkNative {...props} />)`
  text-decoration: none;
  padding-left: 22px;
  ${props =>
    props.active &&
    css`
      pointer-events: none;
    `}
  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const TextLink = styled(Text)`
  opacity: 0.5;
  ${props =>
    props.active &&
    css`
      opacity: 1 !important;
      color: ${props.theme.colors.pri500}!important;
      font-weight: ${props.theme.fontWeight.bold} !important;
      letter-spacing: 0;

    `}

    ${props =>
      props.externalLink &&
      css`
        margin-right: 5px;
      `}

  &:hover {
    opacity: 1 !important;
    color: ${({ theme }) => theme.colors.pri500} !important;
    font-weight: ${({ theme }) => theme.fontWeight.bold} !important;
    letter-spacing: 0;
  }
`;

export const Logo = styled.img`
  width: 121.5px;
`;

export const WrapperLogo = styled.div`
  padding: ${props => props.theme.spacings.mega}
    ${props => props.theme.spacings.mega} 0px
    ${props => props.theme.spacings.mega};
`;

export const Title = styled(Text)`
  color: ${props => props.theme.colors.gra900};
  font-weight: ${props => (props.active ? '500' : '300')};
`;

export const Nav = styled.nav`
  padding: 0 24px;
`;

export const IconTitle = styled(Icon)`
  width: 16px !important;
  padding-right: 6px;
`;

export const IconExternal = styled(Icon)`
  width: 14px !important;
  opacity: 0.5;
  color: #000;
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.theme.spacings.mega};
  padding-top: 32px;
`;

export const WrapperLink = styled.div``;
export const ContentLink = styled.div`
  height: 32px;
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  ${props =>
    props.active &&
    css`
      background: ${props.theme.colors.pri25};
    `}
`;
