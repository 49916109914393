import { theme, Button as NativeButton, Text as NativeText } from '@digibee/beehive-ui';

export const Container = theme.styled('div', {
  width: '100%'
});

export const Text = theme.styled(NativeText, {
  color: '$neu500',
  fontSize: '$2',
  fontWeight: '$regular',
});

export const Caption = theme.styled(Text, {
  textAlign: 'left',
  fontSize: '12px',
});

export const Link = theme.styled('a');

export const Button = theme.styled(NativeButton, {
  marginTop: '$7',
  marginBottom: '$2',
  width: '100%',
  justifyContent: 'center'
});
