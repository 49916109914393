import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Locals
import Skeleton from '~/components/Skeleton';

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
`;

const ContentRadio = styled.div`
  display: flex;
  padding-top: 20px;
`;

const useArrayWithIndexInValue = length =>
  Array(length)
    .fill(undefined)
    .map((val, idx) => idx);

const SizePipelineLoading = ({ length }) => {
  const items = useArrayWithIndexInValue(length);
  return (
    <Container>
      {map(items, () => (
        <Content>
          <Skeleton variant='rect' height='98px' width='98px' />
          <ContentRadio>
            <Skeleton
              style={{ marginRight: 15 }}
              variant='circular'
              height='20px'
              width='20px'
            />
            <Skeleton variant='text' width='60px' />
          </ContentRadio>
        </Content>
      ))}
    </Container>
  );
};

SizePipelineLoading.defaultProps = {
  length: 3
};

SizePipelineLoading.propTypes = {
  length: PropTypes.number
};

export default SizePipelineLoading;
