import { theme, Dialog } from '@digibee/beehive-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
|--------------------------------------------------
| Structure
|--------------------------------------------------
*/

export const Container = theme.styled('div', {
  width: 'auto',
  display: 'flex',
  marginLeft: '-24px',
  marginRight: '-24px',
  paddingBottom: '$9'
})

export const Content = theme.styled('div', {
  padding: '$4',
  width: '100%'
})

export const SelectEnviroment = theme.styled('div', {
  marginLeft: '-24px',
  marginBottom: '$7'
})

export const Link = theme.styled('a', {
  fontSize: '$2',
  marginLeft: '$1',
  textDecoration: 'none',
  cursor: 'pointer',
  color: '$pri500',
  textDecorationLine: 'underline'
})

export const DialogContent = theme.styled(Dialog.Content, {
  minWidth: '50%',
  minHeight: '50vh',
})

export const DialogBody = theme.styled(Dialog.Body, {
  minWidth: '50%',
  maxHeight: '85vh'
})

export const Separator = theme.styled('div', {
  borderTop: '1px solid $neu200',
  marginTop: '$4',
  marginBottom: '$4'
})

export const IconFontAwesome = theme.styled(FontAwesomeIcon, {
  color: '$pri500',
  fontSize: '$2',
  margin: '0 2px'
})
