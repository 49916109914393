/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { func, objectOf, bool } from 'prop-types';
// Loclas
import getFieldError from '~/common/helpers/getFieldError';
import { Input } from '~/components/Input';
import Button from '~/components/Button';
import i18n from '~/common/helpers/i18n';

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledFields = styled.div`
  padding: 10px 0px;
`;

const StyledWelwelcome = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: rgba(0, 0, 0, 0.38);
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: ${props => props.theme.typography.fontFamily};
`;

const MessageError = styled.div`
  display: block;
`;

const ResertSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('scenes.login.messages.error.required_field'),
  password: yup.string().required('scenes.login.messages.error.required_field'),
  newPasswordConfirm: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'scenes.login.messages.error.password_not_equal'
    )
    .required('scenes.login.messages.error.required_field')
});

export const FormFirstAccess = ({
  signinFirstAccess,
  errorFirstAccess,
  loading
}) => {
  function onErrors(touched, errors, field) {
    const keyErrorRequest = 'scenes.login.messages.error.password_invalid';
    const fieldError = getFieldError(touched, errors, field);
    const isError = fieldError || errorFirstAccess.authentication;
    return isError ? fieldError || keyErrorRequest : null;
  }
  return (
    <StyledContainer>
      <Formik
        validationSchema={ResertSchema}
        onSubmit={signinFirstAccess}
        initialValues={{
          newPassword: '',
          password: '',
          newPasswordConfirm: ''
        }}
        render={({ handleChange, values, touched, errors, handleSubmit }) => (
          <form
            onSubmit={ev => {
              ev.preventDefault();
            }}
          >
            <StyledWelwelcome>
              {i18n.t('scenes.login.labels.upper_and_capitalize.capitalized', {
                item: `${i18n.t('scenes.login.guidance.welcome')}`
              })}
            </StyledWelwelcome>
            <StyledFields>
              <Input
                data-testid='governance-input-password-first-access-page'
                name='password'
                value={values.password}
                onChange={handleChange}
                type='password'
                danger={
                  onErrors(touched, errors, 'password')
                    ? i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t(
                          onErrors(touched, errors, 'password')
                        )}`
                      }
                    )
                    : errorFirstAccess?.password
                }
                helperText={
                  onErrors(touched, errors, 'password')
                    ? i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t(
                          onErrors(touched, errors, 'password')
                        )}`
                      }
                    )
                    : errorFirstAccess?.password &&
                    i18n.t('label.current_password_incorrect_assist_error')
                }
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.password')}`
                  }
                )}
              />
            </StyledFields>
            <StyledFields>
              <Input
                data-testid='governance-input-new-password-first-access-page'
                name='newPassword'
                value={values.newPassword}
                onChange={handleChange}
                type='password'
                danger={
                  getFieldError(touched, errors, 'newPassword')
                    ? i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t(
                          getFieldError(touched, errors, 'newPassword')
                        )}`
                      }
                    )
                    : errorFirstAccess?.newPassword
                }
                helperText={
                  getFieldError(touched, errors, 'newPassword') ? (
                    i18n.t(
                      'scenes.login.labels.upper_and_capitalize.capitalized',
                      {
                        item: `${i18n.t(
                          getFieldError(touched, errors, 'newPassword')
                        )}`
                      }
                    )
                  ) : (
                    <>
                      {errorFirstAccess?.newPassword &&
                        errorFirstAccess?.messages?.map(e => (
                          <MessageError>{e?.message}</MessageError>
                        ))}
                    </>
                  )
                }
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.labels.new_password')}`
                  }
                )}
              />
            </StyledFields>
            <StyledFields>
              <Input
                data-testid='governance-input-new-password-confim-first-access-page'
                name='newPasswordConfirm'
                type='password'
                value={values.newPasswordConfirm}
                danger={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      getFieldError(touched, errors, 'newPasswordConfirm')
                    )}`
                  }
                )}
                helperText={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      getFieldError(touched, errors, 'newPasswordConfirm')
                    )}`
                  }
                )}
                onChange={handleChange}
                placeholder={i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t(
                      'scenes.login.actions.confirm_new_password'
                    )}`
                  }
                )}
              />
            </StyledFields>
            <StyledFields>
              <Button
                data-testid='governance-confirm-button-first-access-page'
                key='buttonFirstAccess'
                is='submit'
                type='primary'
                fluid
                onClick={handleSubmit}
                loading={loading}
              >
                {i18n.t(
                  'scenes.login.labels.upper_and_capitalize.capitalized',
                  {
                    item: `${i18n.t('scenes.login.actions.confirm')}`
                  }
                )}
              </Button>
            </StyledFields>
          </form>
        )}
      />
    </StyledContainer>
  );
};

FormFirstAccess.defaultProps = {
  signinFirstAccess: () => { },
  errorFirstAccess: null,
  loading: false
};

FormFirstAccess.propTypes = {
  signinFirstAccess: func,
  errorFirstAccess: objectOf({}),
  loading: bool
};
