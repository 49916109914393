import {
  Alert,
  Box,
  Icon,
  SideSheet,
  Skeleton,
  Text
} from '@digibee/beehive-ui';
import head from 'lodash/head';
import moment from 'moment';
import React from 'react';
import { Link, LinkProps, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CustomAlertV2 from './components/CustomAlert/CustomAlertV2';
import PipelineDeployWithServerless from '../PipelineDeploy/PipelineDeployWithServerless';
import TriggerDetails from '../TriggerDetails/TriggerDetails';

import i18n from '~/common/helpers/i18n';
import { Deployment } from '~/entities/Deployment';
import { Trigger } from '~/entities/Trigger';

interface DetailsDeploymentWithServerlessProps {
  onCancel: (value?: boolean) => void;
  title?: string;
  deployment: Deployment;
  trigger: Trigger;
  linkGoToPipeline: string;
  triggerSource?: { label: string; value: string };
  redeploy: (deployment: Deployment['pipeline']) => void;
  isRyclingMessage: boolean;
  isAlert: string | boolean;
}

const getMessage = (deployment: Deployment) => {
  if (deployment?.lastError || deployment?.lastOMM) {
    return ` - ${moment(
      deployment?.lastError || deployment?.lastOMM,
      'x'
    ).format('DD/MM/YYYY HH:mm:ss')}`;
  }
  return '';
};

const WrapperTrigger = styled.div`
  width: 10%;
  display: flex;
  width: 45px;
  height: 45px;
`;

const TriggerImage = styled.img`
  user-drag: none;
  user-select: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.gra25};
`;

const LinkPipeline = styled((props: LinkProps) => <Link {...props} />)`
  display: flex;
  text-decoration: none;
  p {
    padding-right: 8px;
  }
  color: ${props => props.theme.colors.pri500};
`;

const DetailsDeploymentWithServerless = ({
  onCancel,
  deployment,
  trigger,
  linkGoToPipeline,
  triggerSource,
  redeploy,
  isAlert
}: DetailsDeploymentWithServerlessProps) => {
  const { realm, environment } = useParams();

  return (
    <SideSheet
      open
      onClose={() => onCancel(false)}
      header={i18n.t('scenes.runtime.labels.details_deployment')}
      size='small'
      actions={
        <>
          <SideSheet.Actions>
            <LinkPipeline
              target='_blank'
              to={`${linkGoToPipeline}`}
              data-testid='run-page-detail-open-new-pipeline'
            >
              <Icon icon='external-link' size='md' variant='primary' />
            </LinkPipeline>
          </SideSheet.Actions>
        </>
      }
    >
      <PipelineDeployWithServerless deployment={deployment} />
      <SideSheet.Section data-testid='run-page-detail'>
        <Box>
          {!trigger ? (
            <Skeleton variant='circular' />
          ) : (
            <Box css={{ width: 'auto' }}>
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '12px'
                }}
              >
                <Box css={{ display: 'flex', width: 45, height: 45 }}>
                  <WrapperTrigger>
                    <TriggerImage
                      title={i18n.t(
                        'scenes.beta_pipelines.labels.trigger_icon'
                      )}
                      src={`${trigger.iconURL}`}
                    />
                  </WrapperTrigger>
                </Box>
                <Text css={{ paddingLeft: '$2' }} variant='b3'>
                  {deployment?.pipeline?.description}
                </Text>
              </Box>
              <Box css={{ marginTop: '$1' }}>
                <TriggerDetails
                  source={triggerSource}
                  // @ts-ignore
                  title={head(trigger?.jsonSchema)?.label}
                  // @ts-ignore
                  triggers={deployment?.trigger?.data}
                />
              </Box>
            </Box>
          )}
        </Box>
      </SideSheet.Section>
      {(Number(deployment?.oomCount) > 0 ||
        deployment?.metadata?.errorOutdatedGlobal ||
        deployment?.metadata?.errorOutdatedAccount ||
        (isAlert && Number(deployment?.errorCount) > 0) ||
        deployment?.pipelineUpdateProject) && (
        <SideSheet.Section>
          <Box>
            {Number(deployment?.oomCount) > 0 && (
              <Box>
                <Alert
                  title={`Out of Memory ${getMessage(deployment)}`}
                  collapsible
                  variant='warning'
                  content={i18n.t(
                    'scenes.runtime.messages.error.out_of_memory'
                  )}
                />
              </Box>
            )}
            {deployment?.metadata?.errorOutdatedGlobal && (
              <Box>
                <Alert
                  title={i18n.t(
                    'label.global_has_been_edited_details_title_msg'
                  )}
                  collapsible
                  variant='warning'
                  // @ts-ignore
                  content={
                    <CustomAlertV2
                      text={
                        <>
                          {i18n.t(
                            'label.update_globals_redeploy_needed_card_details_msg_warning'
                          )}
                          <br />
                          <a
                            href={i18n.t('action.learn_more_about_globals_url')}
                            data-testid='run-detail-link-recycled'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {i18n.t(
                              'action.learn_more_about_globals_warning_link'
                            )}
                          </a>
                        </>
                      }
                    />
                  }
                />
              </Box>
            )}
            {deployment?.metadata?.errorOutdatedAccount && (
              <Box>
                <Alert
                  title={i18n.t('label.edited_account')}
                  collapsible
                  variant='warning'
                  // @ts-ignore
                  content={
                    <CustomAlertV2
                      text={
                        <>
                          {i18n.t(
                            'label.update_accounts_redeploy_needed_card_details_msg_warning'
                          )}
                          <br />
                          <a
                            href={i18n.t(
                              'action.learn_more_about_accounts_warning_url'
                            )}
                            data-testid='run-detail-link-recycled'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {i18n.t(
                              'action.learn_more_about_accounts_warning_link'
                            )}
                          </a>
                        </>
                      }
                    />
                  }
                />
              </Box>
            )}
            {isAlert && deployment?.errorCount && (
              <Box>
                <Alert
                  title={`${i18n.t(
                    'label.pipeline_has_errors_issues_generic_mixed_warning_error_msg'
                  )} ${i18n.t('label.learn_more_here_generic')}`}
                  collapsible
                  variant='danger'
                  // @ts-ignore
                  content={
                    <CustomAlertV2
                      text={
                        <>
                          {i18n.t('label.error-message-pipeline-runtime')}
                          <Link
                            target='_blank'
                            data-testid='monitor-completed-executions-button-executions-details-see-all-logs'
                            to={`${`/${realm}/operation/monitor/${environment}/pipeline-logs`}?pipelineName=${
                              deployment?.pipeline.name
                            }&time=1 Hour`}
                          >
                            {i18n.t(
                              'scenes.execution_finished.actions.see_all_logs'
                            )}
                          </Link>
                        </>
                      }
                    />
                  }
                />
              </Box>
            )}
            {deployment?.pipelineUpdateProject && (
              <Box>
                <Alert
                  title={`${i18n.t('label.needs_redeploy_alert')}`}
                  collapsible
                  variant='warning'
                  // @ts-ignore
                  content={
                    <CustomAlertV2
                      text={i18n.t('label.needs_redeploy_msg')}
                      textButton={i18n.t('action.redeploy_pipeline')}
                      onClick={() => {
                        onCancel();
                        redeploy(deployment?.pipeline);
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        </SideSheet.Section>
      )}
    </SideSheet>
  );
};
export default DetailsDeploymentWithServerless;
