import { head } from 'lodash';
import { compose } from 'recompose';

// Locals
import withHooks from '~/common/hoc/withHooks';
import withSubMenus from '~/common/hoc/withSubMenus';

const enhancer = compose(
  withSubMenus,
  withHooks(props => {
    const actions = {
      getLabel: () => {
        const name = head(props?.value)?.name;
        const size = props?.value?.length - 1;
        return {
          name,
          size
        };
      }
    };
    return {
      ...actions,
      ...props
    };
  })
);

export default enhancer;
