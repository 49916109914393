import { IconButton, Icon } from '@digibee/beehive-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Role from '~/components/Role';
import Actions from '~/scenes/Others/scenes/App/components/Actions';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Links = ({ goToAdmin, goToSettings, goToConnectivity, ...props }) => {
  const { CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT } = featureFlagConstants;
  const { treatments } = useFeatureFlag([CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT]);

  const showConnectivityButton =
    treatments[CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT].treatment === 'on';

  return (
    <Container>
      {showConnectivityButton ? (
        <Role name={['ZTNA:READ']}>
          <IconButton
            onClick={goToConnectivity}
            size='medium'
            title='Connectivity'
            data-testid='header-connectivity-button'
          >
            <Icon size='md' variant='secondary' icon='plug-circle-plus' />
          </IconButton>
        </Role>
      ) : null}
      <IconButton
        onClick={goToAdmin}
        size='medium'
        title={i18n.t('scenes.beta.labels.administration')}
        data-testid='header-administration-button'
      >
        <Icon size='md' variant='secondary' icon='user-lock' />
      </IconButton>
      <IconButton
        onClick={goToSettings}
        size='medium'
        title={i18n.t('scenes.beta.labels.setting_plural')}
        data-testid='header-settings-button'
      >
        <Icon size='md' variant='secondary' icon='sliders-v-square' />
      </IconButton>
      <Actions {...props} />
    </Container>
  );
};

Links.propTypes = {
  goToDefaultLayout: PropTypes.func.isRequired,
  goToSettings: PropTypes.func.isRequired,
  goToAdmin: PropTypes.func.isRequired,
  goToConnectivity: PropTypes.func.isRequired
};

export default Links;
