/* eslint-disable react/jsx-no-undef */
// eslint-disable-next-line react/jsx-no-undef
import { choose, otherwise, when } from '@digibee/control-statements';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './PipelineDeploy.elements';
import Details from '../Details';

import i18n from '~/common/helpers/i18n';
import Skeleton from '~/components/Skeleton';
import Version from '~/components/Version';

const normalizeSizeTitle = name => {
  if (!name) return '';
  return name.split('-')[0]?.toUpperCase();
};

const normalizeSizeDeploy = name => name?.split('-')[0]?.toUpperCase();

const getPrimary = variant =>
  ['primary'].includes(variant) ? 'black' : 'secondary';

const PipelineDeploy = ({ deployment, trigger, variant, linkGoToPipeline }) => (
  <>
    <Elements.Header bottom={10}>
      <Elements.Text align='left' size='lg' weight='medium' className='fs-mask'>
        {deployment?.pipeline?.name}
      </Elements.Text>
      <Elements.Box>
        <Elements.Link
          target='_blank'
          to={`${linkGoToPipeline}`}
          data-testid='run-page-detail-open-new-pipeline'
        >
          <Elements.Text type='primary' align='left' size='sm' weight='medium'>
            {i18n.t('scenes.runtime.labels.open_pipeline_in_blank')}
          </Elements.Text>
          <Elements.Icon faType='solid' name='ExternalLink' primary />
        </Elements.Link>
      </Elements.Box>
    </Elements.Header>
    <Elements.Box>
      <Version type='secondary' as='span' size='sm'>
        {`${i18n.t('scenes.runtime.labels.version')} ${
          deployment?.pipeline.versionMajor
        }.${deployment?.pipeline?.versionMinor}`}
      </Version>
    </Elements.Box>
    <Elements.Box top={8}>
      <Elements.Text
        weight='regular'
        size='sm'
        type={getPrimary(variant)}
      >{`${i18n.t('label.engine_version')} : ${
        deployment?.deploymentStatus?.pipelineEngineVersion
          ? deployment?.deploymentStatus?.pipelineEngineVersion
          : i18n.t('label.legacy')
      }`}</Elements.Text>
    </Elements.Box>
    {deployment?.project && (
      <Elements.Box top={8}>
        <Elements.Text weight='regular' size='sm' type={getPrimary(variant)}>
          {`${i18n.t('noun.project')}: ${deployment.project}`}
        </Elements.Text>
      </Elements.Box>
    )}
    <Elements.Box top={8}>
      <Elements.Text weight='regular' size='sm' type={getPrimary(variant)}>
        {`${i18n.t('scenes.runtime.labels.deploy_start_date')} ${moment(
          deployment?.startTime,
          'x'
        ).format('DD/MM/YYYY HH:mm')}`}
      </Elements.Text>
    </Elements.Box>
    {deployment?.metadata?.userEmail && (
      <Elements.Box bottom={8}>
        <Elements.Text
          title={deployment?.metadata?.userName}
          weight='regular'
          size='sm'
          type={getPrimary(variant)}
          className='fs-mask'
        >
          {`${i18n.t('label.deploy_user')}: ${deployment?.metadata?.userEmail}`}
        </Elements.Text>
      </Elements.Box>
    )}
    <Elements.Box top={8} bottom={8}>
      {choose(
        when(!trigger, () => (
          <Skeleton variant='circular' height='45px' width='45px' />
        )),
        otherwise(() => (
          <Elements.WrapperTrigger>
            <Elements.TriggerImage
              title={i18n.t('scenes.beta_pipelines.labels.trigger_icon')}
              src={`${trigger.iconURL}`}
            />
          </Elements.WrapperTrigger>
        ))
      )}
      <Elements.TextDescription
        align='left'
        weight='regular'
        size='sm'
        type={getPrimary(variant)}
      >
        {deployment?.pipeline?.description}
      </Elements.TextDescription>
    </Elements.Box>
    <Elements.Separator />
    <Elements.WrapperDetails>
      <Details
        title={`${i18n.t(
          'scenes.runtime.guidance.deploy_size_tooltip'
        )} - ${normalizeSizeTitle(deployment?.activeConfiguration?.name)}`}
        name={i18n.t('scenes.runtime.labels.deploy_size')}
        result={normalizeSizeDeploy(deployment?.activeConfiguration?.name)}
      />
      <Details
        title={i18n.t('scenes.runtime.guidance.max_executions_tooltip')}
        name={i18n.t('scenes.runtime.labels.max_executions')}
        result={deployment?.activeConfiguration?.actualConsumers}
      />
      <Details
        name={i18n.t('scenes.runtime.labels.deploy_replicas')}
        title={i18n.t('scenes.runtime.labels.deploy_replicas')}
        result={deployment?.availableReplicas}
      />
      <Details
        name={i18n.t('scenes.runtime.labels.deploy_licenses')}
        title={i18n.t('scenes.runtime.guidance.deploy_licenses_tooltip')}
        result={deployment?.licenses}
      />
    </Elements.WrapperDetails>
  </>
);

PipelineDeploy.defaultProps = {
  deployment: null,
  linkGoToPipeline: '',
  trigger: null,
  variant: 'black'
};

PipelineDeploy.propTypes = {
  deployment: PropTypes.objectOf({
    pipeline: PropTypes.objectOf({
      name: PropTypes.string,
      id: PropTypes.string,
      versionMinor: PropTypes.string,
      parameterizedReplica: PropTypes.string
    }),
    project: PropTypes.string,
    startTime: PropTypes.string
  }),
  trigger: PropTypes.objectOf({
    iconURL: PropTypes.string
  }),
  variant: PropTypes.string,
  linkGoToPipeline: PropTypes.string
};

export default PipelineDeploy;
