import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

type StatusProps = {
  disabled?: boolean;
};

type ContainerStatusProps = {
  isMarginBottom?: boolean;
  isMinHeight?: boolean;
};

export const ContainerStatus = styled.div<ContainerStatusProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.isMarginBottom ? '10px' : '0px')};
  min-height: ${props => (props.isMinHeight ? '33px' : '0px')};
  margin-left: 16px;
`;

export const Status = styled.div<StatusProps>`
  background: ${props => props?.theme?.colors?.gra25};
  padding: 6px 16px;
  color: ${props =>
    props.disabled ? props.theme.colors.gra300 : props?.theme?.colors?.gra900};
  border-radius: 50px;
  border: 1px solid ${props => props.theme.colors.gra600};
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type LoadingProps = {
  icon: IconProp;
};

export const Loading = styled((props: LoadingProps) => (
  <FontAwesomeIcon {...props} />
))`
  animation-duration: 0.65s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  font-size: 1rem;
  margin-right: 10px;
`;

type IconProps = {
  icon: IconProp;
  color: string;
};

export const Icon = styled((props: IconProps) => (
  <FontAwesomeIcon {...props} />
))`
  color: ${props => (props.color ? props.color : props.theme.colors.gra700)};
  margin-right: 8px;
  font-size: 14px;
`;

export const ContainerIcon = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1750px) {
    max-width: 300px;
  }
`;

export const ContainerElement = styled.div`
  padding-right: 5px;
  padding-left: 5px;
`;

export const ContainerBadge = styled.div`
  margin-bottom: 10px;
`;
