import * as light from '@fortawesome/pro-light-svg-icons';
import * as regular from '@fortawesome/pro-regular-svg-icons';
import * as solid from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import Notification from '../Notification';

const faTypes = {
  light,
  solid,
  regular
};

interface StyledFontAwesomeIconProps extends FontAwesomeIconProps {
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  inverted?: boolean;
  white?: boolean;
  black?: boolean;
  disabled?: boolean;
}

const StyledFontAwesomeIcon = styled<StyledFontAwesomeIconProps>(
  ({ icon, ...rest }) => <FontAwesomeIcon icon={icon} {...rest} />
)`
  color: ${props => props?.theme?.colors?.gra700};

  ${props =>
    props.primary
      ? css`
          color: ${props?.theme?.colors?.pri500};
        `
      : ''};

  ${props =>
    props?.success
      ? css`
          color: ${props?.theme?.colors?.success};
        `
      : ''}

  ${props =>
    props?.danger
      ? css`
          color: ${props?.theme?.colors?.red500};
        `
      : ''};
  ${props =>
    props?.warning
      ? css`
          color: ${props?.theme?.colors?.warning};
        `
      : ''};

  ${props =>
    props?.inverted
      ? css`
    color ${props?.theme?.colors?.gra400};
  `
      : ''}

  ${props =>
    props?.white
      ? css`
          color: ${props?.theme?.colors?.white};
        `
      : ''}

    ${props =>
    props?.black
      ? css`
          color: ${props?.theme?.colors?.black};
        `
      : ''}

  ${props =>
    props?.disabled
      ? css`
          color: ${props?.theme?.colors?.gra400};
        `
      : ''}
`;

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

interface IconCommunityProps {
  name?: string;
  isNotification?: boolean;
  faType?: 'light' | 'solid' | 'regular';
  withHtml?: boolean;
  complement?: string;

  // repeated types
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  inverted?: boolean;
  white?: boolean;
  black?: boolean;
  disabled?: boolean;
}

const IconCommunity: FC<IconCommunityProps> = ({
  name = 'Stars',
  isNotification,
  withHtml,
  faType = 'light',
  complement = 'fa',
  ...restProps
}) => {
  const iconName = `${complement}${name}`;
  // TODO: typescript problem not worth it
  const icon = (faTypes?.[faType] as any)[`${iconName}`];

  if (withHtml) {
    return (
      <Wrapper>
        {isNotification ? <Notification /> : null}
        <StyledFontAwesomeIcon icon={icon} {...restProps} />
      </Wrapper>
    );
  }

  return <StyledFontAwesomeIcon icon={icon} {...restProps} />;
};

export default IconCommunity;
