/* eslint-disable */

import { compose } from 'recompose';
import { connect } from 'react-redux';
const mapStateToProps = ({ dialogs }) => ({
  state: dialogs.confirmDialog
});
const mapDispatchToProps = ({ dialogs }) => ({
  hideCorfirm: dialogs.hideCorfirm,
  setConfirmDialogContext: dialogs.setConfirmDialogContext,
  setCanConfirm: dialogs.setCanConfirm
});

export const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));
