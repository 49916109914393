// @ts-ignore
import bgCanvas from '~/common/images/bg-canvas.png';
// @ts-ignore
import capsuleEmpty from '~/common/images/capsule-empty.png';
// @ts-ignore
import defaultAvatar from '~/common/images/default-avatar.svg';
// @ts-ignore
import emptyStateList from '~/common/images/empty-state-list.svg';
// @ts-ignore
import emptyStateRuntimeNoResult from '~/common/images/empty-state-runtime-no-result.svg';
// @ts-ignore
import emptyStateRuntime from '~/common/images/empty-state-runtime.svg';
// @ts-ignore
import empty from '~/common/images/empty.svg';
// @ts-ignore
import EmptyListCapsule from '~/common/images/EmptyListCapsule.svg';
// @ts-ignore
import emptySearch from '~/common/images/emptySearch.svg';
// @ts-ignore
import iconActionDrag from '~/common/images/icon-action-drag-24x24.svg';
// @ts-ignore
import settingsIcon from '~/common/images/icon-gear-24x24.svg';
// @ts-ignore
import onProcessIcon from '~/common/images/icon-reload-24x24.svg';
// @ts-ignore
import deleteIcon from '~/common/images/icon-trash-24x24.svg';
// @ts-ignore
import onExceptionIcon from '~/common/images/icon-warning-rounded-24x24.svg';
// @ts-ignore
import linterListEmptyState from '~/common/images/linter-list-empty-state.svg';
// @ts-ignore
import pictureNewLogoDigibeeWithText from '~/common/images/new-logo-with-text.png';
// @ts-ignore
import pictureNewLogoDigibeeWithoutText from '~/common/images/new-logo-without-text.png';
// @ts-ignore
import pictureError from '~/common/images/picture-error.svg';
// @ts-ignore
import pictureLogin from '~/common/images/picture-login.svg';
// @ts-ignore
// @ts-ignore
import pictureNoResults from '~/common/images/picture-no-results.svg';
// @ts-ignore
import pipelineLicensingIcon from '~/common/images/pipeline-licensing-icon.svg';
// @ts-ignore
import rtuLicensingIcon from '~/common/images/rtu-licensing-icon.svg';

const unit = 10;
const fontFamily = 'Montserrat';

const images = {
  pictureLogoDigibeeWithTitleLight: pictureNewLogoDigibeeWithText,
  pictureNewLogoDigibeeWithText,
  pictureNewLogoDigibeeWithoutText,
  iconLogoDigibee: pictureNewLogoDigibeeWithoutText,
  iconActionDrag,
  bgCanvas,
  settingsIcon,
  deleteIcon,
  onExceptionIcon,
  onProcessIcon,
  loading: pictureNewLogoDigibeeWithoutText,
  pictureLogin,
  pictureError,
  pictureNoResults,
  capsuleEmpty,
  empty,
  emptyStateList,
  emptyStateRuntimeNoResult,
  emptyStateRuntime,
  emptySearch,
  defaultAvatar,
  pipelineLicensingIcon,
  rtuLicensingIcon,
  linterListEmptyState,
  EmptyListCapsule
};

// TODO: Rename for primary when remover primary all components.
// brand 2.0
const primary = {
  pri25: '#FBF7FF',
  pri50: '#e9e1f0',
  pri100: '#c7b3d9',
  pri200: '#a281c0',
  pri300: '#7c4fa7',
  pri400: '#602994',
  pri500: '#440381',
  pri600: '#3e0379',
  pri700: '#35026e',
  pri800: '#2d0264',
  pri900: '#1f0151'
};
const green = {
  gre50: '#e4f7eb',
  gre100: '#bceccc',
  gre200: '#90dfaa',
  gre300: '#63d288',
  gre400: '#41c96f',
  gre500: '#20bf55',
  gre600: '#1cb94e',
  gre700: '#18b144',
  gre800: '#13a93b',
  gre900: '#0b9b2a'
};
const grayscale = {
  gra25: '#F3F3F3',
  gra50: '#EBEBEB',
  gra100: '#E5E5E5',
  gra200: '#E0E0E0',
  gra300: '#D6D6D6',
  gra400: '#BBBBBB',
  gra500: '#9E9E9E',
  gra600: '#757575',
  gra700: '#666666',
  gra800: '#2E2E2E',
  gra900: '#000000'
};
const blue = {
  blu25: '#F3F7FF',
  blu50: '#e6f0ff',
  blu100: '#c1dafe',
  blu200: '#98c2fe',
  blu300: '#6faafd',
  blu400: '#5097fc',
  blu500: '#3185fc',
  blu600: '#2c7dfc',
  blu700: '#2572fb',
  blu800: '#1f68fb',
  blu900: '#1355fa'
};
const yellow = {
  yel50: '#fef4e3',
  yel100: '#fbe5b8',
  yel200: '#f9d389',
  yel300: '#f7c159',
  yel400: '#f5b436',
  yel500: '#f3a712',
  yel600: '#f19f10',
  yel700: '#ef960d',
  yel800: '#ed8c0a',
  yel900: '#ea7c05'
};
const red = {
  red25: '#FDF2F3',
  red50: '#fbe6e7',
  red100: '#f4bfc4',
  red200: '#ed959c',
  red300: '#e66b74',
  red400: '#e04b57',
  red500: '#db2b39',
  red600: '#d72633',
  red700: '#d2202c',
  red800: '#cd1a24',
  red900: '#c41017'
};

// brand 1.0
const deprecated = {
  primary: '#440381',
  secondary: '#9E9E9E',
  complementary: '#3185fc',
  acent: '#00d0d0',
  danger: '#db2b39',
  success: '#20bf55',
  info: 'rgb(0, 122, 255)',
  warning: '#f3a712',
  grayExtraLight: '#f4f5f7',
  grayLight: '#666666',
  gray: '#9E9E9E',
  grayDark: 'rgba(0, 0, 0, .60)',
  grayExtraDark: 'rgba(0, 0, 0, .87)',
  grayTooltip: '#757575'
};

const colors = {
  white: '#ffffff',
  black: '#000000',
  ...deprecated,
  ...primary,
  ...grayscale,
  ...blue,
  ...red,
  ...yellow,
  ...green
};
const fontWeight = {
  thin: '300',
  regular: '400',
  medium: '500',
  bold: '600'
};
const spacings = {
  bit: '6px',
  byte: '8px',
  kilo: '12px',
  mega: '16px',
  giga: '24px',
  tera: '36px',
  peta: '40px',
  exa: '48px',
  zetta: '56px'
};

const typographyCurrent = {
  headings: {
    xl: {
      fontSize: '96px',
      letterSpacing: '-3.2px'
    },
    lg: {
      fontSize: '60px',
      letterSpacing: '-2px'
    },
    md: {
      fontSize: '48px',
      letterSpacing: '-1.6px'
    },
    sm: {
      fontSize: '34px',
      letterSpacing: '-1.13px'
    },
    xs: {
      fontSize: '24px',
      letterSpacing: '-0.8px'
    },
    xxs: {
      fontSize: '20px',
      letterSpacing: '-0.67px'
    }
  },
  subHeadings: {
    lg: {
      fontSize: '16px',
      letterSpacing: '0.15px'
    },
    md: {
      fontSize: '14px',
      letterSpacing: '0.1px'
    },
    sm: {
      fontSize: '12px',
      letterSpacing: '0'
    }
  },
  text: {
    lg: {
      fontSize: '16px',
      letterSpacing: '0'
    },
    md: {
      fontSize: '14px',
      letterSpacing: '0.5px'
    },
    sm: {
      fontSize: '12px',
      letterSpacing: '0'
    }
  }
};

const typography = {
  color: colors.gra900,
  fontSize: unit * 1.4,
  fontFamily,
  codeFontFamily: 'Monaco',
  heading: {
    injectStyle: {
      marginBottom: 5
    }
  },
  h1: {
    fontSize: '2.5rem'
  },
  h2: {
    fontSize: '2.25rem'
  },
  h3: {
    fontSize: '2rem'
  },
  h4: {
    fontSize: '1.75rem'
  },
  h5: {
    fontSize: '1.5rem'
  },
  h6: {
    fontSize: '1.25rem'
  },
  italic: {
    color: 'black'
  }
};
const borderRadius = {
  kilo: '1px',
  mega: '4px',
  giga: '5px'
};
const zIndex = {
  default: 0,
  absolute: 1,
  drawer: 10,
  select: 20,
  popover: 30,
  tooltip: 31,
  header: 600,
  backdrop: 700,
  sidebar: 800,
  modal: 1000
};
const theme = {
  button: {
    backgroundColor: colors.gra25,
    borderRadius: '0.3em',
    color: '#007AFF',
    fontFamily,
    fontWeight: 'normal',
    fontSize: '14px',
    marginHorizontal: '0.3em',
    paddingHorizontal: '16px',

    disabled: {
      color: '#585858'
    },
    emphasis: {
      text: {
        paddingHorizontal: '8px'
      }
    }
  },
  accordion: {
    contentPadding: '16px',
    headerPadding: '16px',
    titleFontSize: '16px',
    subtitleFontSize: '12px',
    subtitlePaddingLeft: '9px',
    iconSize: '24px'
  },
  colors,
  footer: {
    height: '50px'
  },
  header: {
    height: '54px'
  },
  typography,
  typographyCurrent,
  spacings,
  borderRadius,
  fontWeight,
  zIndex,
  images
};

export type Theme = typeof theme;
export default theme;
