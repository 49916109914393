const style = theme => [
  {
    selector: 'node',
    style: {
      'background-image': '/assets/connectors/trigger.svg',
      'overlay-opacity': 0,
      height: 80,
      opacity: 1,
      width: 80
    }
  },
  {
    selector: 'node.animate-start',
    style: {
      height: 40,
      width: 40
    }
  },
  {
    selector: 'node',
    style: {
      'font-family': theme?.typography?.fontFamily,
      'font-size': 16,
      'text-valign': 'bottom',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-margin-y': 10,
      'text-max-width': 120,
      color: theme?.colors?.gra800,
      label: el => el.data().stepName || ''
    }
  },
  {
    selector: 'node:selected',
    style: {
      'border-color': theme?.colors?.blu400,
      'border-width': 3,
      'overlay-color': theme?.colors?.gra800,
      'overlay-opacity': 0.1
    }
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'bezier', // bezier | taxi
      'target-arrow-shape': 'triangle',
      opacity: 1,
      width: 2
    }
  },
  {
    selector: 'edge[data]',
    style: {
      'font-family': theme?.typography?.fontFamily,
      'font-size': 14,
      'line-height': 1.2,
      'text-background-color': theme?.colors?.white,
      'text-background-opacity': 1,
      'text-background-padding': '5px 10px',
      'text-background-shape': 'roundrectangle',
      'text-events': 'yes',
      'text-halign': 'center',
      'text-margin-y': 5,
      'text-max-width': 120,
      'text-valign': 'bottom',
      'text-wrap': 'wrap',
      color: theme?.colors?.gra800,
      label: el => el.data().data?.label || el.data().data?.description || ''
    }
  },
  {
    selector: 'edge:selected',
    style: {
      'line-color': theme?.colors?.blu400,
      'target-arrow-color': theme?.colors?.blu400,
      'z-index': 1000
    }
  },
  {
    selector: '.eh-handle',
    style: {
      'background-color': theme?.colors?.white,
      'background-image': 'none',
      'border-color': 'gray',
      'border-width': 2,
      'overlay-opacity': 0,
      height: 10,
      shape: 'ellipsis',
      width: 10
    }
  },
  {
    selector: '.eh-hover',
    style: {
      'background-color': 'transparent',
      'border-width': 2,
      'border-color': theme?.colors?.gre300
    }
  },
  {
    selector: '.eh-source',
    style: {
      'border-width': 2,
      'border-color': theme?.colors?.gre300
    }
  },
  {
    selector: '.eh-target',
    style: {
      'border-width': 2,
      'border-color': theme?.colors?.gre300
    }
  },
  {
    selector: '.eh-preview, .eh-ghost-edge',
    style: {
      'line-color': theme?.colors?.gre300,
      'target-arrow-color': theme?.colors?.gre300,
      'source-arrow-color': theme?.colors?.gre300
    }
  },
  {
    selector: '.eh-target.error',
    style: {
      'border-width': 2,
      'border-color': theme?.colors?.red500
    }
  },
  {
    selector: 'edge.eh-preview.error',
    style: {
      'line-fill': 'linear-gradient',
      'line-style': 'dashed',
      'line-gradient-stop-colors': '#66D4B1 #b00020',
      'line-gradient-stop-positions': '0% 100%',
      'target-arrow-color': theme?.colors?.red500,
      'source-arrow-color': theme?.colors?.red500
    }
  },
  {
    selector: '.eh-ghost-edge.eh-preview-active',
    style: {
      opacity: 0
    }
  }
];

export default style;
