import { useSelector } from 'react-redux';

import { DeploymentPlanRoutes } from './DeploymentPlan';
import { HistoryDeploymentRoutes } from './HistoryDeployment';
import RuntimeV2 from './Run/RunV2';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import { ConfirmDialogProvider } from '~/components/ConfirmDialogV2/contexts/confirmDialogProvider';
import Operation from '~/scenes/Others/scenes/App/Operation';

export const RoutesRun = () => {
  const { RUN_ENABLE_DEPLOYMENTPLAN } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_ENABLE_DEPLOYMENTPLAN]);

  const { application } = useSelector<any, any>(state => state);

  const environments: string[] = application.environments.allNames;
  const enviromentDefault = 'test';

  const newEnvironmentDefault = environments.find(
    env => env === enviromentDefault
  );

  const showDeploymentPlan =
    treatments[RUN_ENABLE_DEPLOYMENTPLAN].treatment === 'on';

  const routes = [
    {
      path: ':environment',
      name: 'run',
      title: i18n.t('label.run_tab'),
      element: (
        <ConfirmDialogProvider>
          <RuntimeV2 />
        </ConfirmDialogProvider>
      )
    },
    ...[HistoryDeploymentRoutes]
  ];

  return [
    {
      path: '/:realm/operation/run',
      element: (
        <Operation
          links={[
            {
              routeName: 'run',
              'data-testid': 'run-header-pipelines-tab',
              path: `operation/run/${newEnvironmentDefault || environments[0]}`,
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('action.pipelines')}`
                }
              )
            },
            {
              roles: [`DEPLOYMENT:READ{ENV=TEST}`, `DEPLOYMENT:READ{ENV=PROD}`],
              'data-testid': 'run-header-history-tab',
              routeName: 'run-history',
              path: 'operation/run/deployment/history',
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('action.history')}`
                }
              )
            },
            {
              roles: [`DEPLOYMENT:READ{ENV=TEST}`, `DEPLOYMENT:READ{ENV=PROD}`],
              'data-testid': 'run-header-deployment-plan-tab',
              routeName: 'run-deployment-plan',
              path: 'operation/run/deployment/deploymentPlan',
              isHidden: !showDeploymentPlan,
              isBeta: true,
              name: i18n.t('action.deployment_plan')
            }
          ]}
        />
      ),
      children: showDeploymentPlan ? [...routes, DeploymentPlanRoutes] : routes
    }
  ];
};
