import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

// Locals
import Ripple from '../Ripple';

import useDynamicState from '~/common/hooks/useDynamicState';

const CheckboxButton = styled.span`
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 9px;
  color: ${({ theme }) => `${theme.colors.gra600}`};
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.gra900}0A`};
  }

  ${({ checked, theme }) =>
    checked &&
    css`
      color: ${theme.colors.pri500};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`;

CheckboxButton.defaultProps = {
  'data-testid': 'button'
};

const CheckboxButtonContent = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
`;

const Input = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

Input.defaultProps = {
  'data-testid': 'checkbox'
};

const Icon = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;

const DefaultIcon = () => (
  <Icon focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
    <path
      d='
        M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2
        2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'
    />
  </Icon>
);

const CheckedIcon = () => (
  <Icon focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
    <path
      d='
        M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0
        2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'
    />
  </Icon>
);

const Checkbox = React.forwardRef((props, ref) => {
  const {
    checked: checkedProp,
    onChange,
    onFocus,
    onBlur,
    defaultChecked,
    disabled,
    rippled,
    indeterminate,
    ...restProps
  } = props;
  const initialValue = checkedProp || defaultChecked;
  const [checked, setChecked] = useDynamicState(initialValue);

  const onChangeHandler = event => {
    setChecked(!checked);
    if (onChange) onChange(event, checked);
  };

  const onFocusHandler = event => {
    if (onFocus) onFocus(event);
  };

  const onBlurHandler = event => {
    if (onBlur) onBlur(event, checked);
  };

  return (
    <CheckboxButton
      disabled={disabled}
      checked={checked}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
    >
      <CheckboxButtonContent>
        <Input
          type='checkbox'
          disabled={disabled}
          checked={checked}
          value=''
          data-indeterminate={indeterminate}
          onChange={onChangeHandler}
          ref={ref}
          {...restProps}
        />
        {checked ? <CheckedIcon /> : <DefaultIcon />}
      </CheckboxButtonContent>
      {rippled && <Ripple />}
    </CheckboxButton>
  );
});

Checkbox.defaultProps = {
  onChange: () => {},
  disabled: false,
  defaultChecked: false,
  rippled: true,
  indeterminate: false
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  rippled: PropTypes.bool,
  indeterminate: PropTypes.bool
};

export default Checkbox;
