import { lighten } from 'polished';
import styled, { css, keyframes } from 'styled-components';

const Pulsate = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
`;

interface NotificationProps {
  isLighten?: boolean;
}

const Notification = styled.div<NotificationProps>`
  display: inline-block;
  border-radius: 50%;

  ${props =>
    props.isLighten
      ? css`
          background-color: ${lighten(0.2, props.theme.colors.red500)};
          &::before {
            border: 2px double ${lighten(0.2, props.theme.colors.red500)};
          }
        `
      : ''}

  background-color: ${props => props.theme.colors.red500};
  border: ${props => props.theme.colors.red500};

  color: ${props => props.theme.white};
  padding: 4px;
  position: absolute;
  margin: 0px;
  bottom: 0px;
  right: -2px;
  z-index: 10;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: ${Pulsate} 1s ease infinite;
    border-radius: 50%;
  }
`;

export default Notification;
