import { Box, Flex, Table, Text } from '@digibee/beehive-ui';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DeployedPipeline } from './DeployedPipeline';
import type { GenericCardRuntimeProps } from '../CardRuntime';

import apiRun from '~/api/runtime';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

interface DeployedPipelinesTableProps
  extends Omit<GenericCardRuntimeProps, 'onClick'> {
  deployments: any;
  onClick: (deployment: GenericCardRuntimeProps['deployment']) => void;
  onNextPage: (page: number) => void;
  onPreviousPage: (page: number) => void;
}
function DeployedPipelinesTable({
  deployments,
  onNextPage,
  onPreviousPage,
  ...props
}: DeployedPipelinesTableProps) {
  const { activeRealm } = useSelector(
    (state: StoreRootState) => state.application
  );
  const [triggers, setTriggers] = useState<any>([]);
  // const [openTableAdjustmentsSidesheet, setOpenTableAdjustmentsSidesheet] =
  //   useState(false);

  async function getTriggers() {
    const { data } = await apiRun.getTriggers({
      realm: activeRealm
    });
    setTriggers(data.triggers);
  }

  useEffect(() => {
    getTriggers();
  }, []);

  if (!deployments) return null;

  const mappedDeployments = deployments?.content?.map((deployment: any) => {
    const isStatusError = deployment.status === 'SERVICE_ERROR';

    const statusFormated =
      deployment.status.substring(0, 1) +
      deployment.status.substring(1).toLowerCase();
    return {
      ...deployment,
      status: {
        text: isStatusError ? 'Error' : statusFormated
      }
    };
  }) as GenericCardRuntimeProps['deployment'][];

  if (!mappedDeployments?.length) return null;

  return (
    <>
      <Box
        css={{
          overflowX: 'auto',
          border: '1px solid $neu200',
          borderRadius: '$1',
          '.table-body-row:last-of-type': {
            '& > td': {
              borderBottom: 'none'
            }
          },
          // '.table-body-row:nth-child(odd)': {
          //   backgroundColor: '$neu25',
          //   '& > td:last-of-type': {
          //     backgroundColor: '$neu25'
          //   }
          // },
          // '& tr > td:last-of-type': {
          //   boxShadow: 'inset 1px 0px 0px 0px hsl(180, 4%, 91%)',
          //   width: '64px'
          // },
          marginBlockStart: '$4',
          marginInline: `$3`
        }}
      >
        <Table.Root size='lg'>
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('label.trigger')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center',
                    color: '$pri600',
                    minWidth: '200px',
                    w: '100%'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2',
                      color: 'inherit'
                    }}
                  >
                    {t('scenes.runtime.labels.pipeline_name')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('scenes.runtime.labels.version')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('scenes.runtime.labels.status')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('scenes.runtime.labels.engine_version')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('scenes.runtime.labels.size')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('scenes.runtime.labels.replicas')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex
                  css={{
                    alignItems: 'center'
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$2',
                      fontWeight: '$medium',
                      lineHeight: '$2',
                      marginInlineEnd: '$2'
                    }}
                  >
                    {t('scenes.runtime.labels.last_deploy')}
                  </Text>
                  {/* <Box
                    css={{
                      marginInlineStart: 'auto'
                    }}
                  >
                    <Icon icon='arrow-down-short-wide' size='md' />
                  </Box> */}
                </Flex>
              </Table.Cell>
              <Table.Cell fixedRight>
                {/* <IconButton
                  size='small'
                  data-testid='run-deployed-pipelines-table-actions'
                  onClick={() => setOpenTableAdjustmentsSidesheet(true)}
                >
                  <Icon icon='sliders' />
                </IconButton> waiting for filters to be available at the api */}
                <></>
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {mappedDeployments.map(deployment => (
              <DeployedPipeline
                key={deployment.id}
                {...props}
                onClick={() => props.onClick(deployment)}
                deployment={deployment}
                triggers={triggers}
              />
            ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <Table.Pagination
        count={deployments.totalElements}
        page={deployments.number}
        rowsPerPage={6}
        rowsPerPageOptions={[6]}
        disabledPrevious={deployments.first}
        disabledNext={deployments.last}
        nextPage={() => {
          onNextPage(deployments.number + 1);
        }}
        previousPage={() => {
          onPreviousPage(deployments.number - 1);
        }}
      />
      {/* <TableAdjustmentsSidesheet
        open={openTableAdjustmentsSidesheet}
        onClose={() => setOpenTableAdjustmentsSidesheet(false)}
      /> waiting for filters to be available at the api */}
    </>
  );
}

export default DeployedPipelinesTable;
