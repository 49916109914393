import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import { wrapMenuList } from 'react-select-async-paginate';
import { List, AutoSizer } from 'react-virtualized';
import styled, { css } from 'styled-components';

import { LoadingMessage } from './LoadingMessage';

const MenuContainer = styled.div`
  width: 100%;
  z-index: 11;
  ${props =>
    props.renderOptionPanel &&
    css`
      width: 40%;
    `}
`;

const Container = styled.div`
  display: flex;
`;

Container.defaultProps = {
  'data-testid': 'options'
};

const Panel = styled.div`
  width: 60%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  border-left: 1px solid ${p => p.theme.colors.gra300};
`;

export const MenuList = ({ children, selectProps }) => {
  const rows = children;
  const rowRenderer = ({ key, index, style }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );

  return (
    <Container>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            rowHeight={selectProps?.menuProps?.rowHeight || 48}
            height={selectProps?.menuProps?.height || 300}
            rowCount={rows?.length || 0}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Container>
  );
};

export const MenuListWithPanel = props => {
  const { selectProps, children, focusedOption } = props;

  return (
    <Container>
      <MenuContainer renderOptionPanel={selectProps?.renderOptionPanel}>
        <components.MenuList
          {...props}
          renderOptionPanel={selectProps?.renderOptionPanel}
        >
          {children}
          {iff(selectProps?.isLoading && selectProps?.options?.length, () => (
            <LoadingMessage />
          ))}
        </components.MenuList>
      </MenuContainer>
      {iff(selectProps?.renderOptionPanel, () => (
        <Panel>
          {selectProps?.renderOptionPanel({
            focusedOption
          })}
        </Panel>
      ))}
    </Container>
  );
};

export const MenuListPaginated = wrapMenuList(MenuListWithPanel);

MenuListWithPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf([])])
    .isRequired,
  selectProps: PropTypes.objectOf({}).isRequired,
  focusedOption: PropTypes.objectOf({}).isRequired
};

MenuList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf([])])
    .isRequired,
  selectProps: PropTypes.objectOf({}).isRequired
};
