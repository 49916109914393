import { useTranslation } from 'react-i18next';

import * as Elements from './FormIdentityProvider.elements';

type Props = {
  idpLoginUrl: string;
  buttonLoading: boolean;
};

function FormIdentityProvider({ idpLoginUrl, buttonLoading }: Props) {
  const { t } = useTranslation();

  function navigateToIdpLoginPage() {
    window.location.href = `${idpLoginUrl}&locale=${t(
      'label.fusion_localization_key'
    )}`;
  }

  return (
    <Elements.Container>
      <Elements.Title>{t('label.idp_login_mandatory')}</Elements.Title>
      <Elements.Text>
        {t('label.idp_login_mandatory_msg')}
        &nbsp;
        <a
          data-testid='governance-documentation-link-idp-page'
          href={t('action.via_idp_login_msg_url')}
          target='_blank' rel='noreferrer'
        >
          {t('action.via_idp_login_msg_link')}
        </a>
      </Elements.Text>
      {/* @ts-ignore */}
      <Elements.Button
        data-testid='governance-sing-in-button-idp-page'
        /* @ts-ignore */
        loading={buttonLoading}
        type='primary'
        onClick={() => navigateToIdpLoginPage()}
      >
        {t('action.idp_login')}
      </Elements.Button>
    </Elements.Container>
  );
}

export default FormIdentityProvider;
