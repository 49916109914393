import bgCanvas from '~/common/images/bg-canvas.png';
import capsuleEmpty from '~/common/images/capsule-empty.png';
import defaultAvatar from '~/common/images/default-avatar.svg';
import emptyStateList from '~/common/images/empty-state-list.svg';
import emptyStateRuntimeNoResult from '~/common/images/empty-state-runtime-no-result.svg';
import emptyStateRuntime from '~/common/images/empty-state-runtime.svg';
import empty from '~/common/images/empty.svg';
import emptySearch from '~/common/images/emptySearch.svg';
import iconActionDrag from '~/common/images/icon-action-drag-24x24.svg';
import settingsIcon from '~/common/images/icon-gear-24x24.svg';
import iconLogoDigibee from '~/common/images/icon-logo-digibee-24x24.svg';
import loading from '~/common/images/icon-logo-spinner-50x50.gif';
import onProcessIcon from '~/common/images/icon-reload-24x24.svg';
import deleteIcon from '~/common/images/icon-trash-24x24.svg';
import onExceptionIcon from '~/common/images/icon-warning-rounded-24x24.svg';
import linterListEmptyState from '~/common/images/linter-list-empty-state.svg';
import pictureError from '~/common/images/picture-error.svg';
import pictureLogin from '~/common/images/picture-login.svg';
import pictureLogoDigibeeWithTitleLight from '~/common/images/picture-logo-digibee-with-title-light.svg';
import pictureNoResults from '~/common/images/picture-no-results.svg';
import pipelineLicensingIcon from '~/common/images/pipeline-licensing-icon.svg';
import rtuLicensingIcon from '~/common/images/rtu-licensing-icon.svg';

const unit = 10;
const fontFamily = 'Noto Sans Display';

const images = {
  iconActionDrag,
  bgCanvas,
  settingsIcon,
  deleteIcon,
  onExceptionIcon,
  onProcessIcon,
  pictureLogin,
  pictureError,
  pictureNoResults,
  capsuleEmpty,
  empty,
  emptyStateList,
  emptyStateRuntimeNoResult,
  emptyStateRuntime,
  emptySearch,
  defaultAvatar,
  pipelineLicensingIcon,
  rtuLicensingIcon,
  linterListEmptyState
};

const brandV3 = {
  primary: {
    pri25: 'hsl(255, 67%, 98%)',
    pri50: 'hsl(251, 74%, 95%)',
    pri100: 'hsl(252, 74%, 91%)',
    pri200: 'hsl(258, 68%, 74%)',
    pri300: 'hsl(261, 60%, 61%)',
    pri400: 'hsl(263, 54%, 51%)',
    pri500: 'hsl(265, 72%, 42%)',
    pri600: 'hsl(263, 79%, 35%)'
  },
  grayscale: {
    gra25: 'hsl(0, 0%, 98%)',
    gra50: 'hsl(180, 4%, 95%)',
    gra100: 'hsl(180, 4%, 91%)',
    gra200: 'hsl(190, 6%, 79%)',
    gra300: 'hsl(190, 3%, 59%)',
    gra400: 'hsl(197, 3%, 43%)',
    gra500: 'hsl(203, 6%, 26%)',
    gra600: 'hsl(197, 8%, 16%)'
  },
  blue: {
    blu25: 'hsl(206, 73%, 96%)',
    blu50: 'hsl(207, 72%, 92%)',
    blu100: 'hsl(208, 74%, 83%)',
    blu200: 'hsl(210, 59%, 70%)',
    blu300: 'hsl(213, 55%, 57%)',
    blu400: 'hsl(216, 56%, 47%)',
    blu500: 'hsl(217, 65%, 39%)',
    blu600: 'hsl(215, 77%, 30%)'
  },
  red: {
    red25: 'hsl(352, 75%, 94%)',
    red50: 'hsl(353, 75%, 87%)',
    red100: 'hsl(353, 75%, 75%)',
    red200: 'hsl(350, 73%, 63%)',
    red300: 'hsl(347, 67%, 51%)',
    red400: 'hsl(340, 92%, 39%)',
    red500: 'hsl(341, 92%, 35%)',
    red600: 'hsl(340, 93%, 30%)'
  },
  green: {
    gre25: 'hsl(150, 71%, 97%)',
    gre50: 'hsl(149, 78%, 95%)',
    gre100: 'hsl(150, 78%, 89%)',
    gre200: 'hsl(153, 86%, 77%)',
    gre300: 'hsl(154, 85%, 65%)',
    gre400: 'hsl(156, 78%, 53%)',
    gre500: 'hsl(158, 93%, 42%)',
    gre600: 'hsl(155, 95%, 32%)'
  },
  yellow: {
    yel25: 'hsl(53.3, 69.2%, 97.5%)',
    yel50: 'hsl(51, 76.9%, 94.9%)',
    yel100: 'hsl(52.3, 76.5%, 90%)',
    yel200: 'hsl(53.9, 87.1%, 75.7%)',
    yel300: 'hsl(55.4, 90.6%, 66.5%)',
    yel400: 'hsl(51.3, 93%, 54.9%)',
    yel500: 'hsl(47.4, 98.4%, 49.6%)',
    yel600: 'hsl(44.2, 89.6%, 47.5%)'
  }
};

const colorsNewBranch = {
  primary: {
    pri25: brandV3.primary.pri25,
    pri50: brandV3.primary.pri50,
    pri100: brandV3.primary.pri100,
    pri200: brandV3.primary.pri200,
    pri300: brandV3.primary.pri300,
    pri400: brandV3.primary.pri400,
    pri500: brandV3.primary.pri500,
    pri600: brandV3.primary.pri500,
    pri700: brandV3.primary.pri500,
    pri800: brandV3.primary.pri600,
    pri900: brandV3.primary.pri600
  },
  green: {
    gre25: brandV3.green.gre25,
    gre50: brandV3.green.gre50,
    gre100: brandV3.green.gre100,
    gre200: brandV3.green.gre200,
    gre300: brandV3.green.gre300,
    gre400: brandV3.green.gre500,
    gre500: brandV3.green.gre500,
    gre600: brandV3.green.gre600,
    gre700: brandV3.green.gre600,
    gre800: brandV3.green.gre600,
    gre900: brandV3.green.gre600
  },
  grayscale: {
    gra25: brandV3.grayscale.gra50,
    gra50: brandV3.grayscale.gra100,
    gra100: brandV3.grayscale.gra200,
    gra200: brandV3.grayscale.gra200,
    gra300: brandV3.grayscale.gra200,
    gra400: brandV3.grayscale.gra200,
    gra500: brandV3.grayscale.gra300,
    gra600: brandV3.grayscale.gra500,
    gra700: brandV3.grayscale.gra600,
    gra800: brandV3.grayscale.gra600,
    gra900: brandV3.grayscale.gra600
  },
  blue: {
    blu25: brandV3.blue.blu25,
    blu50: brandV3.blue.blu50,
    blu100: brandV3.blue.blu50,
    blu200: brandV3.blue.blu100,
    blu300: brandV3.blue.blu200,
    blu400: brandV3.blue.blu300,
    blu500: brandV3.blue.blu500,
    blu600: brandV3.blue.blu500,
    blu700: brandV3.blue.blu600,
    blu800: brandV3.blue.blu600,
    blu900: brandV3.blue.blu600
  },
  yellow: {
    yel25: brandV3.yellow.yel25,
    yel50: brandV3.yellow.yel50,
    yel100: brandV3.yellow.yel50,
    yel200: brandV3.yellow.yel100,
    yel300: brandV3.yellow.yel200,
    yel400: brandV3.yellow.yel300,
    yel500: brandV3.yellow.yel500,
    yel600: brandV3.yellow.yel500,
    yel700: brandV3.yellow.yel600,
    yel800: brandV3.yellow.yel600,
    yel900: brandV3.yellow.yel600
  },
  red: {
    red50: brandV3.red.red25,
    red100: brandV3.red.red50,
    red200: brandV3.red.red100,
    red300: brandV3.red.red200,
    red400: brandV3.red.red300,
    red500: brandV3.red.red500,
    red600: brandV3.red.red500,
    red700: brandV3.red.red600,
    red800: brandV3.red.red600,
    red900: brandV3.red.red600
  }
};

const colors = {
  white: '#ffffff',
  black: '#000000',
  ...colorsNewBranch.primary,
  ...colorsNewBranch.blue,
  ...colorsNewBranch.yellow,
  ...colorsNewBranch.red,
  ...colorsNewBranch.grayscale,
  ...colorsNewBranch.green
};
const fontWeight = {
  thin: '300',
  regular: '400',
  medium: '500',
  bold: '600'
};
const spacings = {
  bit: '6px',
  byte: '8px',
  kilo: '12px',
  mega: '16px',
  giga: '24px',
  tera: '36px',
  peta: '40px',
  exa: '48px',
  zetta: '56px'
};

const typographyCurrent = {
  headings: {
    xl: {
      fontSize: '96px',
      letterSpacing: '-3.2px'
    },
    lg: {
      fontSize: '60px',
      letterSpacing: '-2px'
    },
    md: {
      fontSize: '48px',
      letterSpacing: '-1.6px'
    },
    sm: {
      fontSize: '34px',
      letterSpacing: '-1.13px'
    },
    xs: {
      fontSize: '24px',
      letterSpacing: '-0.8px'
    },
    xxs: {
      fontSize: '20px',
      letterSpacing: '-0.67px'
    }
  },
  subHeadings: {
    lg: {
      fontSize: '16px',
      letterSpacing: '0.15px'
    },
    md: {
      fontSize: '14px',
      letterSpacing: '0.1px'
    },
    sm: {
      fontSize: '12px',
      letterSpacing: '0'
    }
  },
  text: {
    lg: {
      fontSize: '16px',
      letterSpacing: '0'
    },
    md: {
      fontSize: '14px',
      letterSpacing: '0.5px'
    },
    sm: {
      fontSize: '12px',
      letterSpacing: '0'
    }
  }
};

const typography = {
  color: colors.gra900,
  fontSize: unit * 1.4,
  fontFamily,
  codeFontFamily: 'Monaco',
  heading: {
    injectStyle: {
      marginBottom: 5
    }
  },
  h1: {
    fontSize: '2.5rem'
  },
  h2: {
    fontSize: '2.25rem'
  },
  h3: {
    fontSize: '2rem'
  },
  h4: {
    fontSize: '1.75rem'
  },
  h5: {
    fontSize: '1.5rem'
  },
  h6: {
    fontSize: '1.25rem'
  },
  italic: {
    color: 'black'
  }
};
const borderRadius = {
  kilo: '1px',
  mega: '4px',
  giga: '5px'
};
const zIndex = {
  default: 0,
  absolute: 1,
  drawer: 10,
  select: 20,
  popover: 30,
  tooltip: 31,
  header: 600,
  backdrop: 700,
  sidebar: 800,
  modal: 1000
};
const theme = {
  button: {
    backgroundColor: colorsNewBranch.grayscale.gra25,
    borderRadius: '0.3em',
    color: '#007AFF',
    fontFamily,
    fontWeight: 'normal',
    fontSize: '14px',
    marginHorizontal: '0.3em',
    paddingHorizontal: '16px',

    disabled: {
      color: '#585858'
    },
    emphasis: {
      text: {
        paddingHorizontal: '8px'
      }
    }
  },
  accordion: {
    contentPadding: '16px',
    headerPadding: '16px',
    titleFontSize: '16px',
    subtitleFontSize: '12px',
    subtitlePaddingLeft: '9px',
    iconSize: '24px'
  },
  colors,
  footer: {
    height: '50px'
  },
  header: {
    height: '54px'
  },
  typography,
  typographyCurrent,
  spacings,
  borderRadius,
  fontWeight,
  zIndex,
  images
};

export default theme;
