import reduce from 'lodash/reduce';

const findClosest = (values, currentValue) => {
  const context = reduce(
    values,
    (acc, value, index) => {
      const distance = Math.abs(currentValue - value);
      if (
        acc === null ||
        distance < acc.distance ||
        distance === acc.distance
      ) {
        return {
          distance,
          index
        };
      }

      return acc;
    },
    null
  );
  return context?.index || 0;
};

export default findClosest;
