/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import IconNative from '~/components/IconCommunity';

const Icon = styled(IconNative)`
  color: ${props => props?.theme?.colors?.white};
`;

const Container = styled.button`
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.pri500};
  cursor: pointer;
  border-radius: 4px;
  margin-right: -0.8px;
  height: auto;
  margin-top: -0.6px;
  z-index: 1000000000000000;
  margin-bottom: -0.5px;
  border: none;
`;

Container.defaultProps = {
  'data-testid': 'button'
};

export const IndicatorsContainer = ({ selectProps }) => (
  <Container
    onMouseDown={event => {
      event.preventDefault();
      event.stopPropagation();
    }}
    onClick={() => {
      selectProps?.onSubmit(selectProps.value);
    }}
  >
    <Icon name='Check' />
  </Container>
);

IndicatorsContainer.propTypes = {
  selectProps: PropTypes.objectOf({})
};
