import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const initialState = Immutable({
  data: [],
  entities: {
    authConfig: {}
  },
  modal: {
    create: false,
    encryption: false
  },
  result: [],
  loading: {
    fetch: true
  },
  error: {
    fetch: null
  },
  encryption: null,
  search: null
});

const normalizeData = ({ entity, data }) => {
  const pipelinesSchema = new schema.Entity(entity);
  const mySchema = [pipelinesSchema];
  return normalize(data, mySchema);
};

const authConfigModel = {
  name: 'authConfig',
  state: initialState,
  reducers: {
    setScopes(state, scopes) {
      return state.merge({ scopes });
    },
    setData(state, data) {
      return state.merge({ data });
    },
    loading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setPayload(state, payload) {
      return state.merge(
        {
          ...payload
        },
        { deep: true }
      );
    },
    setModal(state, { path, value }) {
      return state.merge(
        {
          modal: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setEncryption(state, payload) {
      return state.merge(
        {
          encryption: payload
        },
        { deep: true }
      );
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    setSearch(state, payload) {
      return state.merge({
        search: payload
      });
    },
    clear() {
      return initialState;
    }
  },
  effects: dispatch => ({
    async fetch({ search } = {}, { application }) {
      const { realm } = application.realm;
      try {
        const response = await api.authConfig.fetch({
          realm,
          search
        });
        const authNormalize = normalizeData({
          entity: 'authConfig',
          data: response.data.authConfig
        });
        dispatch.authConfig.setPayload(authNormalize, { search });
        dispatch.authConfig.setData(response.data.authConfig);
      } catch (e) {
        dispatch.authConfig.setError({
          path: 'fetch',
          value: e.message
        });
      }
    },
    async create(params, { application, authentication, authConfig }) {
      try {
        const { realm } = application.realm;
        const { token, email } = authentication.userData;
        await api.authConfig.create({
          owner: email,
          token,
          realm,
          ...params
        });
        dispatch.authConfig.setModal({ path: 'create', value: false });
        dispatch.authConfig.setModal({ path: 'encryption', value: false });
        await dispatch.authConfig.fetch({ search: authConfig.search });
        dispatch.snackbar.create({
          text: i18n.t(
            'scenes.tokenctl.messages.success.token_sucessfully_created',
            {
              tokenCtlName: params?.title
            }),
          action: {
            label: i18n.t('common.labels.ok')
          }
        });
      } catch (e) {
        dispatch.authConfig.setError(
          {
            path: 'create',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    },
    async remove({ authConfigId, authConfigTitle }, { application, authConfig }) {
      try {
        const { realm } = application.realm;
        await api.authConfig.remove({
          realm,
          authConfigId
        });
        const newSearch = { search: authConfig.search };
        dispatch.snackbar.create({
          text:  i18n.t(
            'scenes.tokenctl.messages.success.token_sucessfully_archived',
            {
              tokenCtlName: authConfigTitle
            }),
          action: {
            label: i18n.t('common.labels.ok')
          }
        });
        dispatch.authConfig.fetch(pickBy(newSearch, identity));
      } catch (e) {
        dispatch.authConfig.setError(
          {
            path: 'remove',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: ['authConfig/fetch'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.authConfig.loading({
          path: 'fetch',
          value: true
        });
        done();
      }
    },
    {
      type: ['authConfig/setPayload'],
      latest: true,
      process({ action }, dispatch, done) {
        if (action?.meta?.search) {
          dispatch.authConfig.setSearch(action.meta.search);
        }
        dispatch.authConfig.loading({
          path: 'fetch',
          value: false
        });
        done();
      }
    },
    {
      type: ['authConfig/setModal'],
      latest: true,
      process({ action }, dispatch, done) {
        if (action?.meta?.entity) {
          dispatch.authConfig.setEncryption(action?.meta?.entity);
        }
        done();
      }
    }
  ]
};

export default authConfigModel;
