/* global window */

import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const controllers = [];

const convertJson = stringJson => {
  if (!stringJson) return {};
  return JSON.parse(stringJson);
};

const convertString = json => {
  if (!json) return '';
  return JSON.stringify(json, null, '  ');
};

const test = {
  execute: async params => {
    const controller = new window.AbortController();

    controllers.push(controller);

    try {
      const response = await clientApollo.mutate({
        mutation: gql`
          mutation testPipeline(
            $realm: String!
            $canvas: CanvasInput!
            $payload: String!
            $trackingId: String!
            $replicaInstanceName: String
            $parameterizedReplica: String
          ) {
            testPipeline(
              realm: $realm
              canvas: $canvas
              payload: $payload
              trackingId: $trackingId
              replicaInstanceName: $replicaInstanceName
              parameterizedReplica: $parameterizedReplica
            )
          }
        `,
        variables: {
          ...params
        },
        context: { fetchOptions: { signal: controller.signal } }
      });

      const { message, status, ...header } = convertJson(
        response.data.testPipeline
      );

      if ([400, 409, 500].includes(status)) {
        if (typeof message === 'string') {
          throw new Error(message);
        }
        throw new Error('Problem sending to test');
      }

      if ([504].includes(status)) {
        throw new Error('noun.test_mode_time_limit_exceeded');
      }

      const { data } = JSON.parse(response.data.testPipeline);

      return {
        digibeeKey: header['X-Digibee-Key'],
        data
      };
    } catch (e) {
      throw e;
    }
  },
  cancel: () => {
    controllers.map(controller => controller.abort());
  }
};
export default test;
