import * as Elements from './FormUserUnblocked.elements';
import { Props } from './FormUserUnblocked.enhancer';
import FooterLoginIssues from '../FooterLoginIssuesHelp';

import i18n from '~/common/helpers/i18n';

function FormUserUnblocked({ onReturnToLogin }: Props) {
  return (
    <Elements.Container>
      <Elements.Text>
        {i18n.t('label.unlocked_login_sign_in_msg')}
      </Elements.Text>
      <Elements.Button
        variant='primary'
        onClick={() => onReturnToLogin()}
        data-testid='governance-return-to-login-button-blocked-user-page'
      >
        {i18n.t('action.return_to_sign_in')}
      </Elements.Button>
      <FooterLoginIssues />
    </Elements.Container>
  );
}

export default FormUserUnblocked;
