import { css } from 'styled-components';

const ellipsis = (width, clamp) => css`
  width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${clamp &&
  css`
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: ${clamp};

    -webkit-box-orient: vertical;
  `}
`;

export default ellipsis;
