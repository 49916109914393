import styled, { css } from 'styled-components';

const SubMenu = styled.div`
  position: absolute;
  z-index: 100;
  box-sizing: border-box;
  right: 0;
  top: -8px;
  display: block;
  overflow: hidden;
  max-height: 250px !important;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? 1 : 0)};
  background: ${props => props.theme.colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transform: ${props =>
    props.visible ? 'translateY(0%)' : 'translateY(-5px)'};
  transition: opacity 250ms ease, visibility 250ms ease, transform 250ms ease;
  width: 200px;

  ${props =>
    props.fromBottom &&
    css`
      top: auto;
      bottom: 100%;
      transform: ${props =>
        props.visible ? 'translateY(0%)' : 'translateY(+5px)'};
    `};
`;

export default SubMenu;
