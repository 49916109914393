import styled from 'styled-components';

import Text from '~/components/Text';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(Text)`
  border: 1px solid
    ${props =>
      props.disabled ? props.theme.colors.gra300 : props.theme.colors.gra400};
  padding: 0px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  height: 20px;
  color: ${props => props.theme.colors.gra600};
`;
