import { theme, Button } from "@digibee/beehive-ui";

export const StyledMessage = theme.styled('div', {
  fontSize: '$2',
  lineHeight: '1.5',
  textAlign: 'left',
  color: '$neu500',
  paddingTop: '10px',
  paddingBottom: '$6',
  fontFamily: '$display',
});

export const StyledContainer = theme.styled('div', {
  width: '100%',
});

export const StyledButton = theme.styled(Button, {
  width: '100%',
  justifyContent: 'center',
});
