import { Box, Button, Dialog, Text } from '@digibee/beehive-ui';
import React, { useState } from 'react';

import DeployPipeline from './DeployPipeline';

import api from '~/api/runtime';
import i18n from '~/common/helpers/i18n';
import useSnackbar from '~/common/hooks/useSnackbar';
import { Deployment } from '~/entities/Deployment';

type Props = {
  onClose: () => void;
  confirmDeploy: (value: any) => void;
  dataModal: {
    deployment: Deployment;
    texts: {
      title: string;
      text: string;
      confirmText: string;
    };
    isRedeploy: boolean;
    environment: string;
  };
};

const DeployPipelineDialogV2: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  async function confirmDeploy() {
    try {
      setLoading(true);
      await api.create(props.dataModal);
      props.confirmDeploy(props.dataModal);
      props.onClose();
      setLoading(false);
      snackbar.createSnack(
        i18n.t('scenes.runtime.labels.upper_and_capitalize.capitalized', {
          item: `${i18n.t('scenes.runtime.messages.success.item_deployed')}`
        })
      );
    } catch (error) {
      setLoading(false);

      const formatError = (message: string) =>
        message.replace('GraphQL error:', '').replace('ApolloError: ', '');

      if (error instanceof Error) {
        snackbar.createSnack(formatError(error.message));
      }

      if (typeof error === 'string') {
        snackbar.createSnack(formatError(error));
      }

      console.error(error);
    }
  }
  return (
    <>
      <Dialog.Root open onOpenChange={props.onClose}>
        <React.Fragment key='.0'>
          <Dialog.Content style={{ width: '100rem', maxHeight: '50em' }}>
            <Box css={{ margin: '$3' }}>
              <Text as='h1' variant='h4'>
                {i18n.t(props.dataModal.texts.title, {
                  pipelineName: props.dataModal.deployment.pipeline?.name
                })}
              </Text>
              <DeployPipeline
                environment={props.dataModal.environment || ''}
                // @ts-ignore
                deployment={props.dataModal.deployment}
                isRedeploy={props.dataModal.isRedeploy}
              />

              <Dialog.Footer>
                <Button
                  size='medium'
                  variant='primary'
                  onClick={() => {
                    confirmDeploy();
                  }}
                  loading={loading}
                  data-testid={
                    props.dataModal.isRedeploy
                      ? 'button-confirm-redeploy'
                      : 'button-confirm-deploy'
                  }
                >
                  {i18n.t(props.dataModal.texts.confirmText)}
                </Button>
                <Dialog.Close asChild>
                  <Button outlined size='medium' variant='primary'>
                    {i18n.t('action.cancel')}
                  </Button>
                </Dialog.Close>
              </Dialog.Footer>
            </Box>
          </Dialog.Content>
        </React.Fragment>
      </Dialog.Root>
    </>
  );
};

export default DeployPipelineDialogV2;
