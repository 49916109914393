import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const MAP_COLORS = {
  primary: {
    default: 'pri100'
  },
  secondary: {
    default: 'gra200'
  },
  info: {
    default: 'blu200'
  },
  danger: {
    default: 'red100'
  },
  warning: {
    default: 'yel200'
  },
  success: {
    default: 'gre200'
  },
  black: {
    default: 'gra900'
  },
  light: {
    default: 'gra25'
  }
};

const getColors = (type = 'primary') => MAP_COLORS[type];

const colorStyles = ({ theme, type, emphasis }) => {
  const currentColor = getColors(type);
  if (!currentColor) return null;
  if (emphasis === 'outlined') {
    return css`
      background-color: none;
      border: 1px solid ${theme.colors[currentColor.default]};
      color: ${theme.colors[currentColor.default]};
    `;
  }
  return css`
    background-color: ${theme.colors[currentColor.default]};
    ${type === 'black' &&
    css`
      color: ${theme?.colors?.white};
    `};
  `;
};

const baseStyles = ({ theme, ...props }) => css`
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  border-radius: 12px;
  color: ${theme.colors[props.color]};
  cursor: text;
  padding: 0 ${theme.spacings.byte};
  font-weight: ${theme.fontWeight.regular};
  text-transform: capitalize;
  user-select: none;
  text-align: center;
  font-family: ${theme.typography.fontFamily};
  height: 20px;
`;

const StyledBadge = styled.div`
  ${baseStyles};
  ${colorStyles};
`;

const Badge = props => <StyledBadge {...props} />;
Badge.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  emphasis: PropTypes.string,
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired
};

Badge.defaultProps = {
  type: 'primary',
  color: 'gra900',
  emphasis: 'contained',
  'data-testid': 'badge',
  title: ''
};
export default Badge;
