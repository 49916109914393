import { choose, when, iff } from '@digibee/control-statements';
import { head } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import * as Elements from './Action.elements';

import i18n from '~/common/helpers/i18n';
import Menu from '~/components/Menu';
import Role from '~/components/Role';
import TextNative from '~/components/Text';

const Container = styled.div``;

const Action = ({ configActionButton }) => {
  const configActions =
    configActionButton?.length === 1
      ? head(configActionButton)
      : configActionButton;

  return (
    <Container>
      {choose(
        when(configActions?.action, () => (
          <Elements.Fab
            onClick={configActions.action}
            emphasis='contained'
            size='lg'
            type='primary'
            rounded
            extended
            data-testid={configActions['data-testid']}
          >
            {i18n.t('scenes.beta.actions.create')}
          </Elements.Fab>
        )),
        when(!configActions?.hidden && !configActions?.action, () => (
          <Menu
            left='-105px'
            component={({ toggle }) => (
              <Elements.Fab
                onClick={toggle}
                emphasis='contained'
                size='lg'
                type='primary'
                icon='Plus'
                rounded
                extended
                data-testid={
                  configActions['data-testid'] || 'build-button-container'
                }
              >
                {i18n.t('scenes.beta.actions.create')}
              </Elements.Fab>
            )}
          >
            <Elements.List inset noHairlines>
              {configActionButton?.map(item =>
                iff(!item.hidden, () => (
                  <Role name={item.role}>
                    <Elements.Item
                      onClick={item.action}
                      data-testid={item['data-testid']}
                    >
                      <Elements.Icon name={item.icon} faType='solid' />
                      <TextNative as='span' align='left'>
                        {item.text}
                      </TextNative>
                      {iff(item.badge, () => (
                        <Elements.Badge
                          type='primary'
                          data-testid='build-badge'
                        >
                          {item.badge}
                        </Elements.Badge>
                      ))}
                    </Elements.Item>
                  </Role>
                ))
              )}
            </Elements.List>
          </Menu>
        ))
      )}
    </Container>
  );
};

Action.defaultProps = {
  configActionButton: undefined
};

Action.propTypes = {
  configActionButton: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      text: PropTypes.string,
      icon: PropTypes.string
    })
  )
};

export default Action;
