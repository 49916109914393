/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import { withAsyncPaginate } from 'react-select-async-paginate';
import styled, { css } from 'styled-components';

// Locals
import {
  Control,
  Option,
  SingleValue,
  ValueContainer,
  MultiValue,
  Menu,
  DropdownIndicator,
  MenuList,
  LoadingIndicator,
  LoadingMessage,
  MenuListPaginated,
  NoOptionsMessage,
  IndicatorsContainer,
  OptionAssigment,
  MenuListWithPanel
} from './components';

const Container = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  width: 100%;
  border-radius: 4px;
`;

const HelperText = styled.div`
  position: relative;
  color: ${props => props.theme.colors.gra700};
  ${props =>
    props.danger &&
    css`
      color: ${props => props.theme.colors.red500};
    `};
  ${props =>
    props.isDisabled &&
    css`
      color: ${props => props.theme.colors.gra700};
    `};
  font-size: 12px;
  letter-spacing: 0.0333333333em;
  font-weight: 400;
  font-smoothing: antialiased;
  line-height: 24px;
  padding-left: 12px;
  box-sizing: border-box;
`;

const components = {
  Menu,
  Control,
  Option,
  SingleValue,
  MultiValue,
  DropdownIndicator,
  IndicatorSeparator: () => null,
  Placeholder: () => null,
  LoadingIndicator,
  LoadingMessage,
  NoOptionsMessage
};

const Select = ({
  danger,
  helperText,
  isFloatingLabel,
  valueOnly,
  options,
  isMulti,
  value,
  onChange,
  className,
  ...props
}) => {
  const onChangeFunc = data => {
    if (!valueOnly) return onChange(data);

    return isMulti
      ? onChange(data.map(option => option.value))
      : onChange(data.value);
  };
  const getValue = () => {
    if (!valueOnly) return value;

    return isMulti
      ? options.filter(option => value.includes(option.value))
      : options.find(option => option.value === value);
  };

  return (
    <Container data-testid={props['data-testid']} className={className}>
      <ReactSelect
        {...props}
        danger={danger}
        chipProps={{
          emphasis: 'outlined'
        }}
        filterOption={(option, predicate) =>
          option?.label
            ?.toLocaleLowerCase()
            ?.includes(predicate?.toLocaleLowerCase())
        }
        noOptionsMessage={() => null}
        components={{
          MenuList,
          ValueContainer,
          ...components
        }}
        options={options}
        isMulti={isMulti}
        onChange={onChangeFunc}
        value={getValue()}
      />
      {helperText && (
        <HelperText danger={danger} isDisabled={props.isDisabled}>
          {helperText}
        </HelperText>
      )}
    </Container>
  );
};

const SelectWithAsyncPaginate = withAsyncPaginate(ReactSelect);

const SelectAsync = ({
  danger,
  helperText,
  isFloatingLabel,
  className,
  ...props
}) => (
  <Container data-testid={props['data-testid']} className={className}>
    <SelectWithAsyncPaginate
      danger={danger}
      SelectComponent={ReactSelect}
      chipProps={{
        emphasis: 'outlined'
      }}
      components={{
        ...components,
        ValueContainer,
        Option: OptionAssigment,
        MenuList: MenuListPaginated
      }}
      {...props}
    />
    {helperText && (
      <HelperText danger={danger} isDisabled={props.isDisabled}>
        {helperText}
      </HelperText>
    )}
  </Container>
);

const SelectAssignment = ({
  danger,
  helperText,
  isFloatingLabel,
  onSubmit,
  className,
  'data-testid': dataTestId,
  ...props
}) => {
  const Component = props.loadOptions ? SelectWithAsyncPaginate : ReactSelect;
  return (
    <Container data-testid={dataTestId} className={className}>
      <Component
        {...props}
        filterOption={
          props.loadOptions
            ? false
            : (option, predicate) =>
              option?.label
                ?.toLocaleLowerCase()
                ?.includes(predicate?.toLocaleLowerCase())
        }
        SelectComponent={ReactSelect}
        isMulti
        closeMenuOnSelect={false}
        danger={danger}
        chipProps={{
          emphasis: 'outlined'
        }}
        menuProps={{
          rowHeight: 64
        }}
        onSubmit={onSubmit}
        noFloatingLabel
        components={{
          ...components,
          DropdownIndicator: () => null,
          ClearIndicator: () => null,
          IndicatorsContainer,
          Option: OptionAssigment,
          ValueContainer,
          MenuList: props.loadOptions ? MenuListPaginated : MenuListWithPanel
        }}
      />
      {helperText && (
        <HelperText danger={danger} isDisabled={props.isDisabled}>
          {helperText}
        </HelperText>
      )}
    </Container>
  );
};

SelectAsync.propTypes = {
  isFloatingLabel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  danger: PropTypes.bool,
  helperText: PropTypes.string,
  labelSecondary: PropTypes.bool,
  slotOption: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  'data-testid': PropTypes.string
};

SelectAsync.defaultProps = {
  'data-testid': 'select'
};

SelectAssignment.propTypes = {
  isFloatingLabel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  danger: PropTypes.bool,
  helperText: PropTypes.string,
  labelSecondary: PropTypes.bool,
  slotOption: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  onSubmit: PropTypes.func,
  loadOptions: PropTypes.func,
  'data-testid': PropTypes.string
};

SelectAssignment.defaultProps = {
  onSubmit: () => null,
  'data-testid': 'select-assignment'
};

Select.propTypes = {
  isFloatingLabel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  danger: PropTypes.bool,
  helperText: PropTypes.string,
  labelSecondary: PropTypes.bool,
  slotOption: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  'data-testid': PropTypes.string
};

Select.defaultProps = {
  isFloatingLabel: true,
  isDisabled: false,
  isMulti: false,
  danger: false,
  helperText: '',
  labelSecondary: false,
  slotOption: null,
  'data-testid': 'select'
};
export default Select;

export { SelectAsync, SelectAssignment };
