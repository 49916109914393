import { func } from 'prop-types';
import React from 'react';

import * as Elements from './FormUserUnblocked.elements';

import i18n from '~/common/helpers/i18n';

function FormUserUnblocked({ onReturnToLogin }) {
  return (
    <Elements.Container>
      <Elements.Text>
        {i18n.t('label.unlocked_login_sign_in_msg')}
      </Elements.Text>
      <Elements.Button
        fluid
        type='primary'
        onClick={() => onReturnToLogin()}
        data-testid='governance-return-to-login-button-blocked-user-page'
      >
        {i18n.t('action.return_to_sign_in')}
      </Elements.Button>
      <Elements.ContainerFooter>
        <Elements.Caption>
          {i18n.t('label.still_have_login_problems')}
        </Elements.Caption>
        <Elements.Caption>
          {i18n.t('label.read_our_article_about')}
          &nbsp;
          <Elements.Link
            href={i18n.t('action.blocked_access_login_issues_url')}
            target='_blank'
          >
            {i18n.t('action.blocked_access_login_issues')}
          </Elements.Link>
        </Elements.Caption>
      </Elements.ContainerFooter>
    </Elements.Container>
  );
}

FormUserUnblocked.propTypes = {
  onReturnToLogin: func.isRequired
};

export default FormUserUnblocked;
