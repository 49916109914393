/* eslint-disable */

import axios from 'axios';

const fullstory = {
  async isAdBlockEnabled() {
    try {
      await axios.head("https://edge.fullstory.com/s/fs.js");
      return false;
    } catch (e) {
      return true;
    }
  }
};

export default fullstory;
