import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enLocale from './locales/en.json';
import ptLocale from './locales/pt_BR.json';

const capitalizedTwoStepsTransform = (value: string) =>
  value &&
  value
    .toLowerCase()
    .split(' ')
    .map(x => x[0].toUpperCase() + x.slice(1))
    .join(' ');
const toLowerCaseTransform = (value: string) => value.toLowerCase();
const toUpperCaseTransform = (value: string) => value.toUpperCase();
const capitalizedTransform = (value: string) =>
  value && value[0].toUpperCase() + value.slice(1);

const formatters: { [key: string]: Function } = {
  uppercase: toUpperCaseTransform,
  capitalizeFirstLetter: capitalizedTransform,
  toLowerCase: toLowerCaseTransform,
  capitalizeTwoLetter: capitalizedTwoStepsTransform
};

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    nsSeparator: false,
    resources: {
      'en-US': { translation: enLocale },
      'pt-BR': { translation: ptLocale }
    },
    detection: {
      order: ['navigator']
    },
    fallbackLng: 'en-US',
    debug: false,
    ns: ['translation'],
    defaultNS: 'translation',
    returnedObjectHandler: (key: string) => key,
    returnNull: false,
    interpolation: {
      escapeValue: false,
      format: (value: string, format: string | undefined) => {
        if (!format || !formatters[format]) return value;

        return formatters[format](value);
      }
    }
  });

export default i18n;
