import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const pipelineLogs = {
  fetch: async params => {
    try {
      const pipelineLogs = await clientApollo.query({
        query: gql`
          query logsSearch(
            $realm: String!
            $environment: String
            $dateFrom: String
            $dateTo: String
            $pipelineName: String
            $pipelineVersion: String
            $pipelineKey: String
            $logMessage: String
            $level: String
            $afterDate: String
            $afterId: String
            $sortBy: String
          ) {
            logsSearch(
              realm: $realm
              environment: $environment
              dateFrom: $dateFrom
              dateTo: $dateTo
              pipelineName: $pipelineName
              pipelineVersion: $pipelineVersion
              pipelineKey: $pipelineKey
              logMessage: $logMessage
              level: $level
              afterDate: $afterDate
              afterId: $afterId
              sortBy: $sortBy
            ) {
              id
              pipelineName
              pipelineKey
              logMessage
              timestamp
              timestampMillis
              realm
              logLevel
            }
          }
        `,
        variables: params
      });
      if (pipelineLogs?.errors) {
        throw new Error(pipelineLogs.errors);
      }
      return pipelineLogs;
    } catch (e) {
      throw new Error(e);
    }
  },
  get: async params => {
    try {
      const pipelineLogs = await clientApollo.query({
        query: gql`
          query logSearch($realm: String!, $environment: String, $id: String) {
            logSearch(realm: $realm, environment: $environment, id: $id) {
              id
              pipelineName
              pipelineKey
              logMessage
              timestamp
              timestampMillis
              realm
              logLevel
            }
          }
        `,
        variables: {
          ...params
        }
      });
      if (pipelineLogs?.errors) {
        throw new Error(pipelineLogs.errors);
      }
      return pipelineLogs;
    } catch (e) {
      throw new Error(e);
    }
  }
};
export default pipelineLogs;
