/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { ButtonToolbarContext } from '../ButtonToolbar';

export const ButtonGroupContext = React.createContext({
  groupExists: false
});

const StyledButton = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  display: inline-flex;
  width: auto;

  ${props =>
    props.toolbarExists === true
      ? css`
          margin-left: ${props.spacing}px;
        `
      : ''}

  ${props =>
    props.fluid === true
      ? css`
          display: flex;
        `
      : ''}
`;

const Button = ({ children, is, ...rest }) => {
  const { toolbarExists, spacing } = useContext(ButtonToolbarContext);
  return (
    <ButtonGroupContext.Provider value={{ groupExists: true }}>
      <StyledButton
        toolbarExists={toolbarExists}
        spacing={spacing}
        {...rest}
        type={is}
      >
        {children}
      </StyledButton>
    </ButtonGroupContext.Provider>
  );
};

Button.defaultProps = {
  children: [],
  fluid: false
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string]),

  fluid: PropTypes.bool
};

export default Button;
