/* eslint-disable */

import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { bool, func, array, object, number } from 'prop-types';
import { Formik, Field } from 'formik';
import { map, isEmpty, get } from 'lodash';
import HelloJsAuthButton from './components/HelloJsAuthButton';
// Locals
import Text from '~/components/Text';
import InputField from '~/components/Field';
import Button from '~/components/Button';
import theme from '~/common/styled/theme';
import SideSheetNative, {
  SidesheetHeader,
  SidesheetFooter
} from '~/components/SideSheet';
import IconNative from '~/components/IconCommunity';
import i18n from '~/common/helpers/i18n';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import TableDeployments from '../../components/TableDeployments/TableDeployments';
import Dialog, {
  ContentTitle,
  Title,
  Container,
  DialogFooter
} from '~/components/Dialog';
import { CopyToClipboard } from '~/components/CopyToClipboard';
import FieldComponent from '~/components/Field';
import IconCopy from '~/components/Icon/Icon';

const SideSheet = styled(SideSheetNative)`
  width: 42%;
  padding-bottom: 50px;
`;

const ScrollContent = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const Icon = styled(IconNative)`
  cursor: pointer;
`;

export const ButtonModal = styled(Button)`
  margin-right: 10px;
`;

export const ContainerModal = styled(Container)`
  display: flex;
`;

const Link = styled.a`
  display: flex;
  text-decoration: none;
  p {
    padding-right: 8px;
  }
`;

const ContainerCopy = styled.div`
  padding: 16px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 20px;
`;

export const Footer = styled(DialogFooter)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-right: 0;
  padding-left: 24px;
  padding-right: 24px;
`;

const InputComponent = ({ field, form, ...props }) => (
  <InputField
    {...field}
    {...props}
    helperText={
      get(form, `touched.${field.name}`) &&
      get(form, `errors.${field.name}`, null)
    }
    danger={
      get(form, `touched.${field.name}`) &&
      get(form, `errors.${field.name}`, null)
    }
  />
);

InputComponent.propTypes = {
  field: object,
  form: object
};

const getProviderType = account => {
  return Object.keys(account.env).reduce((accum, key) => {
    if (accum) {
      return accum;
    }
    if (account.env[key].provider) {
      return account.env[key].provider;
    }
    return accum;
  }, '');
};

const ModalAccount = ({
  hideDisplaySensitiveAccountFields,
  visible,
  onCancel,
  onConfirm,
  accountTypesForSelect,
  accountTypes,
  environments,
  account,
  setActiveTab,
  activeTab,
  isSaving,
  canEdit,
  deployments,
  getDeploymentsByAccount,
  loadingPipelines,
  showAccountsV2
}) => {
  const validate = value => {
    return !value
      ? `${i18n.t('scenes.accounts.labels.upper_and_capitalize.capitalized', {
          item: `${i18n.t('scenes.accounts.messages.error.required_field')}`
        })}`
      : null;
  };
  const [showValue, setShowValue] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState('');
  const [typeModal, setTypeModal] = useState('');
  const myFormRef = useRef(null);

  const typeModalTexts = {
    isChange: {
      title: i18n.t('label.leave_account_editing_page_prompt', {
        accountName: myFormRef?.current?.values.label
      }),
      msg: i18n.t('label.leave_global_editing_page_msg'),
      button: i18n.t('action.leave')
    },
    isSubmit: {
      title: i18n.t('label.edit_account_prompt', {
        accountName: myFormRef?.current?.values.label
      }),
      msg: i18n.t('label.edit_global_prompt_msg'),
      button: i18n.t('action.edit')
    }
  };

  function checkTypeModal() {
    if (myFormRef?.current?.dirty) {
      setTypeModal('isChange');
      setConfirmModal(true);
      return;
    }
    onCancel(true);
  }

  function submit() {
    if (account?.id && showAccountsV2) {
      setTypeModal('isSubmit');
      setConfirmModal(true);
      return;
    }
    myFormRef?.current.handleSubmit();
  }

  function confirm() {
    setConfirmEdit('');
    if (typeModal === 'isChange') {
      setTypeModal('');
      setConfirmModal(false);
      onCancel(false);
      return;
    }
    myFormRef.current.handleSubmit();
    setConfirmModal(false);
  }

  return (
    <SideSheet
      data-testid='accounts-sidesheet-account'
      onCloseClick={() => (showAccountsV2 ? checkTypeModal() : onCancel())}
      opened={visible}
      width='80%'
    >
      <ScrollContent className='fs-exclude'>
        {iff(accountTypes.length > 0 && visible, () => (
          <Formik
            innerRef={myFormRef}
            enableReinitialize
            initialValues={
              isEmpty(account)
                ? {
                    label: '',
                    description: '',
                    accountType: '',
                    providerType: '',
                    env: {}
                  }
                : Object.assign({}, account, {
                    providerType: getProviderType(account)
                  })
            }
            onSubmit={onConfirm}
            render={({ values, handleChange, setFieldValue, submitForm }) => (
              <>
                <SidesheetHeader>
                  {i18n.t('scenes.accounts.labels.account')}
                </SidesheetHeader>

                <Block>
                  <Field
                    data-testid='accounts-sidesheet-account-input-label'
                    component={InputComponent}
                    name='label'
                    type='text'
                    placeholder={i18n.t('scenes.accounts.labels.label')}
                    onChange={handleChange}
                    value={values.label}
                    disabled={!isEmpty(account) ? 'disabled' : undefined}
                    validate={validate}
                  />
                  <Field
                    data-testid='accounts-sidesheet-account-input-description'
                    component={InputComponent}
                    name='description'
                    type='text'
                    placeholder={i18n.t('scenes.accounts.labels.description')}
                    onChange={handleChange}
                    value={values.description}
                    disabled={!isEmpty(account) ? 'disabled' : undefined}
                    validate={validate}
                  />
                  <Field
                    data-testid='accounts-sidesheet-account-input-type'
                    component={InputComponent}
                    type='select'
                    placeholder={i18n.t('scenes.accounts.labels.account_type')}
                    name='accountType'
                    options={accountTypesForSelect}
                    onChange={data => setFieldValue('accountType', data.value)}
                    value={{
                      label: (
                        accountTypesForSelect.find(
                          ({ value }) => value === values.accountType
                        ) || {}
                      ).label,
                      value: values.accountType
                    }}
                    isDisabled={!isEmpty(account) ? 'disabled' : undefined}
                    validate={validate}
                  />
                  {iff(
                    values.accountType && values.accountType === 'oauth-2',
                    () => {
                      const selectedAccountType = accountTypes.find(
                        ({ name }) => name == values.accountType
                      );
                      const firstEnvironment = environments[0];
                      const options = accountTypes
                        .find(({ name }) => name == values.accountType)
                        .providers.map(item => ({
                          label: item.title,
                          value: account ? item.provider : item.id
                        }));
                      return (
                        <>
                          <Field
                            component={InputComponent}
                            name='providerType'
                            data-testid={`accounts-sidesheet-account-input-provider`}
                            type='select'
                            placeholder={i18n.t('noun.provider')}
                            options={options}
                            onChange={({ value }) => {
                              setFieldValue('providerType', value);
                              environments.forEach(environment => {
                                setFieldValue(
                                  `env.${environment.name}.scopes`,
                                  ''
                                );
                              });
                            }}
                            value={{
                              label: (
                                options.find(
                                  ({ value }) =>
                                    value === get(values, 'providerType', '')
                                ) || {}
                              ).label,
                              value: get(values, 'providerType', '')
                            }}
                          />
                          {iff(get(values, 'providerType'), () => {
                            const provider = selectedAccountType.providers.find(
                              provider =>
                                (account ? provider.provider : provider.id) ===
                                get(values, 'providerType')
                            );
                            return (
                              <Field
                                data-testid={`accounts-sidesheet-account-input-${firstEnvironment.name}`}
                                component={InputComponent}
                                placeholder={i18n.t(
                                  'scenes.accounts.labels.scope_plural'
                                )}
                                type='multi-select'
                                name={`env.${firstEnvironment.name}.scopes`}
                                onChange={value => {
                                  environments.forEach(environment => {
                                    setFieldValue(
                                      `env.${environment.name}.scopes`,
                                      value.join(' ')
                                    );
                                  });
                                }}
                                source={provider?.authorization.scopes.map(
                                  value => ({
                                    value,
                                    name: value
                                  })
                                )}
                                value={get(
                                  values,
                                  `env.${firstEnvironment.name}.scopes`,
                                  ''
                                )
                                  .split(' ')
                                  .filter(Boolean)
                                  .map(value => value)}
                              />
                            );
                          })}
                        </>
                      );
                    }
                  )}

                  {iff(values.accountType, () => (
                    <>
                      <Header>
                        <HeaderNav>
                          {environments.map((environment, index) => (
                            <HeaderLink
                              data-testid={`accounts-sidesheet-account-tab-${environment.id}`}
                              key={environment.id}
                              active={activeTab === index}
                              onClick={() => setActiveTab(index)}
                            >
                              <Text upper>{environment.name}</Text>
                            </HeaderLink>
                          ))}
                        </HeaderNav>
                      </Header>

                      {environments.map((environment, index) => (
                        <TabBody key={environment.id}>
                          {iff(activeTab === index, () => {
                            const selectedAccountType = accountTypes.find(
                              ({ name }) => name == values.accountType
                            );
                            return (
                              <Tab>
                                {choose(
                                  when(values.accountType === 'oauth-2', () => (
                                    <Block>
                                      <div>
                                        {iff(
                                          get(values, 'providerType'),
                                          () => {
                                            const provider =
                                              selectedAccountType.providers.find(
                                                provider =>
                                                  (account
                                                    ? provider.provider
                                                    : provider.id) ===
                                                  get(values, 'providerType')
                                              );
                                            return (
                                              <>
                                                {iff(
                                                  get(
                                                    values,
                                                    `env.${environment.name}.scopes.length`
                                                  ),
                                                  () => (
                                                    <HelloJsAuthButton
                                                      authenticated={
                                                        !isEmpty(account) ||
                                                        get(
                                                          values,
                                                          `env.${environment.name}.access_token`
                                                        )
                                                      }
                                                      provider={provider}
                                                      scope={get(
                                                        values,
                                                        `env.${environment.name}.scopes`
                                                      )}
                                                      onAuthenticated={authData => {
                                                        setFieldValue(
                                                          `env.${environment.name}.access_token`,
                                                          authData.access_token
                                                        );
                                                        setFieldValue(
                                                          `env.${environment.name}.refresh_token`,
                                                          authData.refresh_token
                                                        );
                                                        setFieldValue(
                                                          `env.${environment.name}.expiresIn`,
                                                          authData.expires_in
                                                        );
                                                        setFieldValue(
                                                          `env.${environment.name}.authenticatedAt`,
                                                          Date.now()
                                                        );
                                                        setFieldValue(
                                                          `env.${environment.name}.provider`,
                                                          provider.provider
                                                        );
                                                      }}
                                                    />
                                                  )
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </Block>
                                  )),
                                  otherwise(() =>
                                    map(selectedAccountType.fields, field => {
                                      const isTextArea =
                                        [
                                          'public-key',
                                          'private-key',
                                          'certificate-chain'
                                        ].includes(selectedAccountType.name) &&
                                        ['key', 'chain'].includes(field);
                                      const isSensitive =
                                        selectedAccountType.sensitive.filter(
                                          f => f === field
                                        )?.length;

                                      return (
                                        <InputField
                                          key={field}
                                          data-testid={`accounts-sidesheet-account-input-${field}`}
                                          placeholder={field.toUpperCase()}
                                          type={
                                            isTextArea
                                              ? 'textarea'
                                              : showValue[field] || !isSensitive
                                              ? 'text'
                                              : 'password'
                                          }
                                          name={`env.${environment.name}.${field}`}
                                          onChange={handleChange}
                                          password={
                                            showValue[field] || !isSensitive
                                              ? false
                                              : true
                                          }
                                          value={get(
                                            values,
                                            `env.${environment.name}.${field}`,
                                            ''
                                          )}
                                          endAdornment={
                                            isSensitive &&
                                            !hideDisplaySensitiveAccountFields && (
                                              <Icon
                                                name={
                                                  showValue[field]
                                                    ? 'Eye'
                                                    : 'EyeSlash'
                                                }
                                                onClick={() =>
                                                  setShowValue({
                                                    ...showValue,
                                                    [field]: showValue[field]
                                                      ? false
                                                      : true
                                                  })
                                                }
                                                secondary
                                              />
                                            )
                                          }
                                          disabled={!canEdit}
                                        />
                                      );
                                    })
                                  )
                                )}
                              </Tab>
                            );
                          })}
                        </TabBody>
                      ))}
                    </>
                  ))}
                </Block>

                {account?.id && showAccountsV2 && (
                  <TableDeployments
                    deployments={deployments}
                    fetch={getDeploymentsByAccount}
                    loading={loadingPipelines}
                  />
                )}

                <SidesheetFooter>
                  {iff(canEdit, () => (
                    <Button
                      data-testid='accounts-sidesheet-account-button-confirm'
                      loading={isSaving}
                      type='primary'
                      onClick={() => submit()}
                    >
                      {i18n.t(
                        'scenes.accounts.labels.upper_and_capitalize.uppercase',
                        {
                          item: `${i18n.t('scenes.accounts.actions.confirm')}`
                        }
                      )}
                    </Button>
                  ))}
                  {iff(!canEdit, () => (
                    <Button
                      type='primary'
                      onClick={onCancel}
                      data-testid='accounts-sidesheet-account-button-cancel'
                    >
                      {i18n.t('common.actions.close')}
                    </Button>
                  ))}
                </SidesheetFooter>
              </>
            )}
          />
        ))}
      </ScrollContent>
      {confirmModal && showAccountsV2 && (
        <Dialog
          opened
          onRequestClose={() => {
            setConfirmEdit('');
          }}
          closeOnEsc={() => {}}
          closeOnOverlayClick={undefined}
          style={undefined}
          data-testid='accounts-modal-confirm'
        >
          <ContainerModal>
            <ContentTitle>
              <Title align='left' weight='bold'>
                {typeModalTexts[typeModal]?.title}
              </Title>

              <Title align='left' weight='regular'>
                {typeModalTexts[typeModal]?.msg}
              </Title>
              {typeModal === 'isSubmit' && (
                <>
                  <Link
                    target='_blank'
                    href={i18n.t('action.learn_more_about_accounts_doc_url')}
                    data-testid='run-page-detail-open-new-pipeline'
                  >
                    <Title as='p' variant='b2'>
                      {i18n.t('action.learn_more_period')}
                    </Title>
                  </Link>

                  <Box>
                    <Title as='p' variant='b2'>
                      {i18n.t('label.edit_global_prompt_msg')}
                    </Title>
                    <Title as='p' variant='b2'>
                      {i18n.t(
                        'label.to_continue_enter_i_want_edit_global_prompt_msg'
                      )}
                    </Title>
                    <CopyToClipboard
                      text={i18n.t('label.i_want_to_edit_global_placeholder')}
                    >
                      <ContainerCopy>
                        <IconCopy name='copy' />
                      </ContainerCopy>
                    </CopyToClipboard>
                  </Box>
                  <div>
                    <FieldComponent
                      data-testid='accounts-sidesheet-global-confirm-edit'
                      placeholder={i18n.t(
                        'label.i_want_to_edit_global_placeholder'
                      )}
                      label={i18n.t('label.confirmation_message')}
                      name='confirmEdit'
                      id='confirmEdit'
                      value={confirmEdit}
                      onChange={e => {
                        setConfirmEdit(e?.target?.value || '');
                      }}
                    />
                  </div>
                </>
              )}
            </ContentTitle>
          </ContainerModal>
          <Footer>
            <ButtonModal
              onClick={() => confirm()}
              type='primary'
              disabled={
                typeModal === 'isSubmit' &&
                confirmEdit !==
                  i18n.t('label.i_want_to_edit_global_placeholder')
              }
              data-testid='accounts-modal-button-confirm'
            >
              {typeModalTexts[typeModal]?.button}
            </ButtonModal>

            <ButtonModal
              data-testid='accounts-modal-button-close'
              onClick={() => {
                setConfirmEdit('');
                setConfirmModal(false);
              }}
            >
              {i18n.t('action.cancel')}
            </ButtonModal>
          </Footer>
        </Dialog>
      )}
    </SideSheet>
  );
};

ModalAccount.propTypes = {
  visible: bool.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  accountTypesForSelect: array,
  setActiveTab: func.isRequired,
  accountType: object,
  accountTypes: array,
  environments: array,
  account: object,
  activeTab: number.isRequired,
  canEdit: bool.isRequired
};

const HeaderLink = styled.a`
  cursor: pointer;
  padding-bottom: 15px;
  display: inline-block;
  margin-right: 20px;

  ${props =>
    props.active &&
    css`
      border-bottom: 4px solid ${props => props.theme.colors.pri500};
    `};
`;

const HeaderNav = styled.nav`
  display: inline-block;
  float: left;
`;

const Tab = styled.div``;

const Header = styled.div`
  padding: 20px 0 0;
  display: block;
  margin: 20px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

const Block = styled.div`
  padding: 15px;
`;

const TabBody = styled.div``;

export default ModalAccount;
