import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Icon from '~/components/IconCommunity';

/**
|--------------------------------------------------
| Styles
|--------------------------------------------------
*/

const MAP_COLORS = {
  primary: {
    default: 'pri25'
  },
  secondary: {
    default: 'gra25'
  }
};

const getColors = (variant = 'primary') => MAP_COLORS[variant];

const colorStyles = ({ theme, variant }) => {
  const currentColor = getColors(variant);
  if (!currentColor) return null;
  return css`
    background-color: ${theme.colors[currentColor.default]};
  `;
};

const Container = styled.div`
  ${colorStyles};
`;

const Header = styled.div`
  padding: ${props => props.theme.accordion.headerPadding};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

Header.defaultProps = {
  'data-testid': 'header'
};

const Title = styled.span`
  font-family: ${props => props.theme.typography.fontFamily};
  max-width: 66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.accordion.titleFontSize};
`;

Title.defaultProps = {
  'data-testid': 'title'
};

const Subtitle = styled.span`
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${props => props.theme.colors.gra700};
  max-width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.accordion.subtitleFontSize};
  padding-left: ${props => props.theme.accordion.subtitlePaddingLeft};
`;

Subtitle.defaultProps = {
  'data-testid': 'title'
};

const Content = styled.div`
  ${props =>
    !props.noPadding &&
    css`
      padding: ${props.theme.accordion.contentPadding};
    `}

  box-sizing: border-box;
`;

Content.defaultProps = {
  'data-testid': 'content'
};

const openingAnimation = contentHeight => keyframes`
  0%    {height: 0px;}
  100%   {height: ${`${contentHeight}px`};}
`;

const closingAnimation = contentHeight => keyframes`
  0%    {height: ${`${contentHeight}px`}}
  100%  {height: 0px;}
`;

const Collapsable = styled.div`
  height: ${props => props.collapsableHeight};
  overflow: auto;
  ${props =>
    props.canAnimate &&
    css`
      animation-name: ${props.opened
        ? openingAnimation(props.contentHeight)
        : closingAnimation(props.contentHeight)};
      animation-fill-mode: forwards;
      animation-duration: 300ms;
    `}
`;

Collapsable.defaultProps = {
  'data-testid': 'collapsable'
};

const Right = styled.div`
  margin-left: auto;
`;

const ToggleIcon = styled(Icon)`
  font-size: ${props => props.theme.accordion.iconSize};
  width: 24px !important;
  ${props =>
    props.rotate &&
    css`
      transform: rotate(180deg);
    `};
  ${props =>
    props.canAnimate &&
    css`
      transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    `}
`;

ToggleIcon.defaultProps = {
  'data-testid': 'toggle-icon'
};

/**
|--------------------------------------------------
| Accordion
|--------------------------------------------------
*/

const useDynamicState = (value, defaultValue) => {
  if (value === undefined) {
    const [state, setState] = useState(defaultValue);
    return [state, setState, false];
  }
  return [value, () => {}, true]; // just bypass
};

const Accordion = ({
  opened: isOpened,
  title,
  subtitle,
  onToggle,
  noPadding,
  children,
  ...props
}) => {
  const [opened, setOpened, isControlled] = useDynamicState(isOpened, true);
  const [collapsableHeight, setCollapsableHeight] = useState(
    opened ? 'initial' : '0px'
  );

  const toggleAccordion = () => {
    const value = !opened;
    if (!isControlled) setOpened(value);
    onToggle(value);
  };

  const handleAnimationEnd = () => {
    setCollapsableHeight(opened ? 'initial' : '0px');
  };

  useEffect(() => {
    setCollapsableHeight(opened ? 'initial' : '0px');
  }, [opened]);

  const contentRef = useRef();
  const contentHeight = contentRef.current
    ? contentRef.current.offsetHeight
    : 0;

  return (
    <Container
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    >
      <Header onClick={toggleAccordion}>
        <Title title={title}>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Right>
          <ToggleIcon name='AngleDown' rotate={opened} />
        </Right>
      </Header>
      <Collapsable
        opened={opened}
        contentHeight={contentHeight}
        collapsableHeight={collapsableHeight}
        onAnimationEnd={handleAnimationEnd}
      >
        <Content innerRef={contentRef} noPadding={noPadding}>
          {children}
        </Content>
      </Collapsable>
    </Container>
  );
};

Accordion.defaultProps = {
  title: '',
  subtitle: '',
  onToggle: () => {},
  noPadding: false,
  opened: undefined,
  variant: 'secondary',
  children: null
};

Accordion.propTypes = {
  opened: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onToggle: PropTypes.func,
  noPadding: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  children: PropTypes.any,
  variant: PropTypes.oneOf(['primary', 'secondary'])
};

export default Accordion;
