import { Row as RowNative, Col as CalNative } from 'react-grid-system';
import styled from 'styled-components';

type StylesProps = {
  children: any;
  isMarginBottomNegative: boolean;
};

export const Row = styled(RowNative)<StylesProps>`
  align-items: center !important;
  overflow: visible !important;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: ${props =>
      props.isMarginBottomNegative ? '-20px' : '16px'};
  }
`;

export const Col = styled(CalNative)<StylesProps>`
  overflow: visible !important;
  @media (max-width: 1320px) {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  padding-left: 0px;
  padding-right: 0px;
`;

type isNewDSType = {
  isNewDS: boolean;
};

export const ContainerButton = styled.div<isNewDSType>`
  padding-bottom: ${props => (props.isNewDS ? '0px' : '20px')};
`;
