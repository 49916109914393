import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHover } from 'react-use';
import styled, { css } from 'styled-components';

import Icon from '~/components/IconCommunity';

/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/

const Title = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  width: fit-content;
  color: ${props => props.theme.colors.gra600};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 14px;
  position: absolute;
  top: -10px;
  left: 16px;
  padding: 0 4px;
  box-sizing: border-box;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${props => props.theme.colors.white};
    color: transparent;
    left: 0;
    padding: 0 4px;
    top: 8px;
    z-index: -1;
    width: 100%;
  }
`;

const Container = styled.div`
  ${props =>
    !props.noMargin &&
    css`
      margin-top: 20px;
    `}
  margin-bottom: 30px;
  padding-right: 10px;
  position: relative;
  position: relative;
  /* width: 100%; */
  z-index: ${p => p.zIndex};
  box-sizing: border-box;
`;

const Content = styled.div`
  position: relative;
  padding: 32px 16px 24px;
  ${props =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0px;
    `}
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.theme.colors.gra200};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${props => props.theme.colors.white};

  ${props =>
    props.isFocused &&
    css`
      ${Title} {
        color: ${p => p.theme.colors.pri500};
      }
    `}

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: 2px;
    top: -2px;
    left: -2px;
    background-color: ${props => props.theme.colors.gra200};
    ${props =>
      props.isFocused &&
      css`
        background-color: ${p => p.theme.colors.pri500};
      `}
  }

  ${props =>
    props.showStripes &&
    css`
      &:after {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0.05;
        background: repeating-linear-gradient(
          45deg,
          ${p => p.theme.colors.red500},
          ${p => p.theme.colors.red500} 51px,
          ${p => p?.theme?.colors?.white} 51px,
          ${p => p?.theme?.colors?.white} 84.5px
        );
      }
    `}
`;

Content.defaultProps = {
  'data-testid': 'content'
};

const CornerArea = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    left: -40px;
    background: transparent;
    width: 70px;
    height: 20px;
    top: -5px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -5px;
    left: 15px;
    background: transparent;
    width: 20px;
    height: 75px;
  }
`;

CornerArea.defaultProps = {
  'data-testid': 'corner-area'
};

const RemoveFloatButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: ${props => props.theme.colors.gra500};
  border-radius: 100%;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.red500};
  }
`;

RemoveFloatButton.defaultProps = {
  'data-testid': 'remove-button'
};

const CloseIcon = styled(Icon)`
  color: ${props => props.theme.colors.white};
`;

/**
|--------------------------------------------------
| Group
|--------------------------------------------------
*/

export const GroupContext = React.createContext({
  setGroupFocus: () => {}
});

const Group = ({
  title,
  onRemove,
  noMargin,
  noPaddingBottom,
  children,
  zIndex,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const contextValue = {
    setGroupFocus: setIsFocused
  };

  const [hoverableRemoveButton, isHoveringRemoveButton] = useHover(() => (
    <RemoveFloatButton onClick={onRemove}>
      <CloseIcon name='Times' width='11' />
    </RemoveFloatButton>
  ));

  const [hoverableContent] = useHover(isHoveringContent => (
    <Content
      noPaddingBottom={noPaddingBottom}
      isFocused={isFocused || isHoveringContent || isHoveringRemoveButton}
      showStripes={isHoveringRemoveButton}
    >
      <Title title={title}>{title}</Title>
      {isHoveringContent && onRemove && (
        <CornerArea>{hoverableRemoveButton}</CornerArea>
      )}
      {children}
    </Content>
  ));

  return (
    <GroupContext.Provider value={contextValue}>
      <Container
        zIndex={zIndex}
        noMargin={noMargin}
        {...props}
        className='fs-exclude'
      >
        {hoverableContent}
      </Container>
    </GroupContext.Provider>
  );
};

Group.defaultProps = {
  title: '',
  children: null,
  noMargin: false,
  noPaddingBottom: false,
  onRemove: () => {},
  zIndex: '10',
  'data-testid': 'group'
};

Group.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  noMargin: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  onRemove: PropTypes.func,
  zIndex: PropTypes.string,
  'data-testid': PropTypes.string
};

export default Group;
