import { func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 12px;
  padding-top: 12px;
  text-transform: uppercase;
`;

const CloseButton = ({ closeToast, textClose }) => (
  <StyledContainer onClick={closeToast}>{textClose}</StyledContainer>
);

CloseButton.propTypes = {
  closeToast: func,
  textClose: string
};

CloseButton.defaultProps = {
  closeToast: () => {},
  textClose: 'Dismiss'
};

export default CloseButton;
