/* eslint-disable */
import React from 'react';
import ReactSelect, { components } from 'react-select';
import styled, { css } from 'styled-components';
import { omit } from 'lodash';
import { choose, otherwise, when } from '@digibee/control-statements';

const ContainerMultiValue = styled(components.ValueContainer)`
  max-height: 88px;
  overflow-y: auto !important;
`;

const StyledValueContainer = styled.div`
  display: flex;
  justify-content: initial;
  align-items: center;
  padding-left: 8px;
  width: 65%;

  ${props => {
    switch (props.selectProps.size) {
      case 'xs':
        return css`
          padding-bottom: 5px;
          padding-left: 0px;
        `;
      case 'md':
      default:
        return css`
          padding-bottom: 0px;
        `;
    }
  }}
`;

export const ValueContainer = ({ children, ...props }) => (
  <>
    {choose(
      when(props?.selectProps?.isMulti, () => (
        <ContainerMultiValue data-testid='container' {...props}>
          {children}
        </ContainerMultiValue>
      )),
      otherwise(() => (
        <StyledValueContainer
          data-testid='container'
          {...omit(props, ['cx', 'theme'])}
        >
          {children}
        </StyledValueContainer>
      ))
    )}
  </>
);
