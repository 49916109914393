import { iff } from '@digibee/control-statements';
import { Formik } from 'formik';
import { bool, func, number } from 'prop-types';
import React from 'react';
import * as yup from 'yup';

import * as Elements from './FormUserBlocked.elements';
import TokenInput from '../TokenInput';

import i18n from '~/common/helpers/i18n';

function FormUserBlocked({
  codeExpirationInSeconds,
  validateVerificationCode,
  loading,
  validationError,
  resendCode,
  temporaryLockingFeatureFlagEnabled,
  attemptsRemaining = 0
}) {
  const shouldWait = codeExpirationInSeconds > 0;
  const codeLength = 6;

  const FormSchema = yup.object().shape({
    inputCode: yup
      .string()
      .min(codeLength, 'label.required_field_assist_error')
      .required('label.required_field_assist_error')
  });

  function handleHelperText(error) {
    let helperText;
    if (error) helperText = i18n.t(error);
    else if (validationError)
      helperText = i18n.t('label.expired_invalid_code_assist_error');
    return helperText;
  }

  return (
    <Elements.Container>
      <Elements.Title data-testid='governance-title-blocked-user-page'>{i18n.t('label.blocked_access')}</Elements.Title>
      <Elements.Text data-testid='governance-explanation-blocked-user-page'>{i18n.t('label.blocked_login_explanation')}</Elements.Text>
      <Formik
        validationSchema={FormSchema}
        initialValues={{
          inputCode: ''
        }}
        onSubmit={({ inputCode }) => validateVerificationCode(inputCode)}
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <>
            <Elements.InputContainer>
              <TokenInput
                data-testid='governance-code-input-blocked-user-page'
                className='fs-mask'
                name='inputCode'
                onChange={val => setFieldValue('inputCode', val)}
                length={codeLength}
                danger={errors.inputCode || validationError}
                helperText={handleHelperText(errors.inputCode)}
              />
              {iff(
                temporaryLockingFeatureFlagEnabled && validationError,
                () => (
                  <Elements.DangerCaption data-testid='governance-attempts-blocked-user-page'>
                    {i18n.t('label.remaining_attempts_number_assist_error', {
                      numberHere: attemptsRemaining
                    })}
                  </Elements.DangerCaption>
                )
              )}
            </Elements.InputContainer>
            <Elements.Button
              data-testid='governance-confirm-button-blocked-user-page'
              fluid
              type='primary'
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
            >
              {i18n.t('action.confirm_code')}
            </Elements.Button>
          </>
        )}
      </Formik>
      <Elements.Button
        data-testid='governance-resend-code-button-blocked-user-page'
        fluid
        type='link'
        onClick={() => resendCode()}
        disabled={shouldWait}
      >
        {shouldWait
          ? `${i18n.t('action.resend_code_colon')} ${codeExpirationInSeconds}`
          : i18n.t('action.resend_code')}
      </Elements.Button>
      <Elements.ContainerFooter>
        <Elements.Caption>
          {i18n.t('label.still_have_login_problems')}
        </Elements.Caption>
        <Elements.Caption>
          {i18n.t('label.read_our_article_about')}
          &nbsp;
          <Elements.Link
            href={i18n.t('action.blocked_access_login_issues_url')}
            target='_blank'
          >
            {i18n.t('action.blocked_access_login_issues')}
          </Elements.Link>
        </Elements.Caption>
      </Elements.ContainerFooter>
    </Elements.Container>
  );
}

FormUserBlocked.propTypes = {
  codeExpirationInSeconds: number.isRequired,
  validateVerificationCode: func.isRequired,
  loading: bool.isRequired,
  validationError: bool.isRequired,
  resendCode: func.isRequired,
  temporaryLockingFeatureFlagEnabled: bool.isRequired,
  attemptsRemaining: number.isRequired
};

export default FormUserBlocked;
