import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';

import { Deployments, Props } from '../types';

import api from '~/api/runtime';
import i18n from '~/common/helpers/i18n';
import withHooks from '~/common/hoc/withHooks';
import { Pagination } from '~/common/types/Pagination';
import { useHeader } from '~/components/HeaderProvider';

const STATUS = [
  {
    label: i18n.t('label.all'),
    value: ''
  },
  {
    label: i18n.t('scenes.runtime.labels.deployed'),
    value: 'SERVICE_ACTIVE'
  },
  {
    label: i18n.t('scenes.runtime.labels.status_error'),
    value: 'SERVICE_ERROR'
  },
  {
    label: i18n.t('scenes.runtime.labels.redeploy'),
    value: 'REDEPLOY'
  },
  {
    label: i18n.t('scenes.runtime.labels.starting'),
    value: 'STARTING'
  }
];

const enhancer = withHooks(() => {
  const { application, project } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const [deployments, setDeployments] = useState<Pagination<Deployments>>();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useHeader({
    configActionButton: [
      {
        hidden: true
      }
    ]
  });

  function getValuesParams() {
    return {
      projectId: searchParams.get('projectId') || '',
      pipelineName: searchParams.get('pipelineName') || '',
      page: parseInt(searchParams.get('page') || '0', 10)
    };
  }

  function setParams(values: any) {
    setSearchParams(values);
  }

  function changePage(page = 0) {
    setParams({
      ...getValuesParams(),
      page
    });
  }

  async function findByDeployHistoryDistinct() {
    setLoading(true);

    const { data } = await api.findByDeployHistoryDistinct({
      ...getValuesParams(),
      realm: application?.activeRealm,
      status: ['']
    });
    setDeployments(data?.deployHistoryDistinct);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    dispatch.project.fetch();
    setParams(getValuesParams());
  }, [application.activeRealm]);

  useEffect(() => {
    if (
      location.pathname ===
      `/${application?.activeRealm}/operation/run/deployment/history`
    ) {
      findByDeployHistoryDistinct();
    }
  }, [searchParams, application?.activeRealm]);

  const states = {
    status: STATUS,
    deployments,
    loading,
    projects: [
      {
        label: i18n.t('label.all'),
        value: ''
      },
      ...project?.result.project
        .asMutable({ deep: true })
        .map((id: string) => ({
          value: id,
          label: project?.entities?.project.asMutable({ deep: true })[id].name,
          description: project?.entities?.project.asMutable({ deep: true })[id]
            .description
        }))
    ]
  };

  const actions = {
    setParams,
    changePage
  };

  const props: Props = {
    ...states,
    ...actions
  };

  return props;
});

export default enhancer;
