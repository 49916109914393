import { camelCase } from 'lodash';
import Paginator from 'paginator';

const usePaginator = ({
  perPageSize = 1,
  currentRangePage,
  totalElements,
  activedPage
}) => {
  const paginationInfo = Paginator(perPageSize, currentRangePage).build(
    totalElements,
    activedPage
  );
  const keys = Object.keys(paginationInfo);
  const arrayPage = Array(paginationInfo.pages).fill(0);
  const pagination = keys.reduce(
    (acc, current) => ({
      arrayPage,
      ...acc,
      [camelCase(current)]: paginationInfo[current]
    }),
    {}
  );
  return pagination;
};

export default usePaginator;
