import React from 'react';

import NativeLabel from '../../../Label';

const Label = ({ children, text, subtext }) =>
  text || subtext ? (
    <NativeLabel text={text} subText={subtext}>
      {children}
    </NativeLabel>
  ) : (
    children
  );

Label.propTypes = NativeLabel.propTypes;

export default Label;
