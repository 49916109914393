import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const oauthProviders = {
  find: variables =>
    clientApollo.query({
      query: gql`
        query oauthProviders($realm: String!) {
          oauthProviders(realm: $realm) {
            id
            title
            provider
            authURL
            scopes
            realm
          }
        }
      `,
      variables
    }),
  get: variables =>
    clientApollo.query({
      query: gql`
        query oauthProvider($realm: String!, $id: String!) {
          oauthProvider(realm: $realm, id: $id) {
            id
            title
            authURL
            scopes
          }
        }
      `,
      variables
    }),
  create: async variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation createOAuthProvider(
          $realm: String!
          $data: CreateOAuthProviderFromBaseProviderInput!
        ) {
          createOAuthProvider(realm: $realm, data: $data)
        }
      `,
      variables
    }),
  update: async variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation updateOAuthProvider(
          $id: String!
          $realm: String!
          $data: UpdateOAuthProviderFromBaseProviderInput!
        ) {
          updateOAuthProvider(id: $id, realm: $realm, data: $data)
        }
      `,
      variables
    }),
  archive: async variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation archiveOAuthProvider($id: String!, $realm: String!) {
          archiveOAuthProvider(id: $id, realm: $realm)
        }
      `,
      variables
    })
};

export default oauthProviders;
