import { includes } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHover } from 'react-use';
import styled, { css } from 'styled-components';

import useCalcPosition from './common/useCalcPosition';

import { BOTTOM, CENTER, LEFT, RIGHT, TOP } from '~/common/helpers/constants';

const positionMap = {
  [TOP]: 'bottom',
  [RIGHT]: 'left',
  [BOTTOM]: 'top',
  [LEFT]: 'right'
};

const getPositionStyles = ({ theme, position }) => {
  const absolutePosition = positionMap[position];
  return `
    padding-${positionMap[position]}: ${theme.spacings.byte};
    ${absolutePosition}: 100%;
    &::after {
      ${positionMap[position]}: -2px;
      border-${position}-color: ${theme?.colors?.gra600};
    }
  `;
};

const getAlignmentStyles = ({ theme, position, align }) => {
  const isHorizontal = includes(position, [TOP, BOTTOM]);
  if (isHorizontal && includes(align, [TOP, BOTTOM, CENTER])) {
    return `
      left: 50%;
      transform: translateX(-50%);
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    `;
  }

  if (!isHorizontal && includes(align, [LEFT, RIGHT, CENTER])) {
    return `
      top: 50%;
      transform: translateY(-50%);
      &::after {
        top: 50%;
        transform: translateY(-50%);
      }
    `;
  }

  const absolutePosition = positionMap[align];
  /* eslint-disable max-len */
  return `
    ${absolutePosition}: 50%;
    ${absolutePosition}: calc(50% - (${theme.spacings.mega} + ${theme.spacings.bit}));
    &::after {
      ${absolutePosition}: ${theme.spacings.mega};
    }
  `;
  /* eslint-enable max-len */
};

const positionAndAlignStyles = ({ theme, position, align }) => css`
  label: ${`tooltip--${position}-${align}`};
  ${getAlignmentStyles({ theme, position, align })};
  ${getPositionStyles({ theme, position })};
`;

const baseStyles = ({ theme }) => css`
  label: tooltip;
  display: inline-block;
  width: auto;
  max-width: 250px;
  font-family: ${theme.typography.fontFamily};
  background-color: ${theme?.colors?.gra600};
  color: ${theme.colors.white};
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  border-radius: ${theme.borderRadius.mega};
  transition: opacity 0.3s;
  font-size: 12px;
  line-height: 20px;
  word-wrap: break-word;
  width: max-content;
`;
const baseStylesAfter = ({ theme }) => css`
  z-index: ${theme.zIndex.tooltip};
  &::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: ${theme.spacings.bit} solid transparent;
  }
`;

const StyledTooltip = styled.div`
  ${baseStyles};
  white-space: break-spaces;
`;

const Wrapper = styled.div`
  position: absolute;
  ${baseStylesAfter};
  ${positionAndAlignStyles};
`;

const StyledTooltipContainer = styled.div`
  position: relative;
  line-height: 0;
  ${Wrapper} {
    visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
    opacity: ${({ opened }) => (opened ? 1 : 0)};
    transition: ${({ opened }) =>
      opened ? 'none' : 'visibility 0s .4s, opacity .4s linear'};
  }
`;

const useDynamicState = (value, defaultValue) => {
  if (value === undefined) {
    const [state, setState] = useState(defaultValue);
    return [state, setState, false];
  }
  return [value, () => {}, true]; // just bypass
};

const TooltipBody = ({ content, ...restProps }) => {
  const [ref, { position, align }] = useCalcPosition(restProps);
  return (
    <Wrapper {...restProps} align={align} position={position} innerRef={ref}>
      <StyledTooltip {...restProps} data-testid='tooltip-message'>
        {content}
      </StyledTooltip>
    </Wrapper>
  );
};
TooltipBody.propTypes = {
  content: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired
};
const Tooltip = ({
  children,
  content,
  opened: isOpened,
  onChange,
  ...restProps
}) => {
  const [hoverable] = useHover(children);
  const [opened, setOpened, isControlled] = useDynamicState(isOpened, false);

  const openTooltip = event => {
    if (!isControlled) setOpened(true);
    onChange(event, true);
  };

  const hideTooltip = event => {
    if (!isControlled) setOpened(false);
    onChange(event, false);
  };

  return (
    <StyledTooltipContainer
      onMouseEnter={openTooltip}
      onMouseLeave={hideTooltip}
      opened={opened}
      data-testid='tooltip-container'
    >
      <TooltipBody
        content={content}
        {...restProps}
        data-testid='tooltip-body'
      />
      {hoverable}
    </StyledTooltipContainer>
  );
};

Tooltip.CENTER = CENTER;
Tooltip.TOP = TOP;
Tooltip.RIGHT = RIGHT;
Tooltip.BOTTOM = BOTTOM;
Tooltip.LEFT = LEFT;

Tooltip.propTypes = {
  /**
   * The HTML heading element to render.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  position: PropTypes.string,
  content: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  align: PropTypes.oneOf([Tooltip.RIGHT, Tooltip.LEFT, Tooltip.CENTER]),
  opened: PropTypes.bool,
  onChange: PropTypes.func
};
Tooltip.defaultProps = {
  position: Tooltip.RIGHT,
  align: Tooltip.CENTER,
  opened: undefined,
  onChange: () => {}
};
export default Tooltip;
