import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DeploymentPlanContext } from './DeploymentPlanContext';
import { ValidatePipelineData } from '../types';

import apiPipeline from '~/api/pipeline';
import api from '~/api/runtime';

type DeploymentPlanProviderProps = {
  children: React.ReactElement;
};

export function DeploymentPlanProvider({
  children
}: DeploymentPlanProviderProps) {
  const { application } = useSelector((state: any) => state);
  const [planSelected, setPlanSelected] = useState(null);
  const [deploymentsPlan, setDeploymentsPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExecuteDeploy, setLoadingExecuteDeploy] = useState(true);
  const [page, setPage] = useState(0);

  async function getPlan() {
    setLoading(true);
    const { data } = await api.deploymentPlanList({
      realm: application?.activeRealm,
      pageSize: 10,
      page
    });
    setDeploymentsPlan(data?.deploymentPlanList);
    setLoading(false);
  }

  useEffect(() => {
    getPlan();
  }, []);

  useEffect(() => {
    getPlan();
  }, [page]);

  const validatePipeline = async (variant: ValidatePipelineData) => {
    const data: any = await apiPipeline.validatePipeline({
      ...variant,
      realm: application.activeRealm
    });

    return data?.analysis || [];
  };

  function selectPlan(plan: any) {
    setPlanSelected(plan);
  }

  function changeLoadingExecuteDeploy(value: boolean) {
    setLoadingExecuteDeploy(value);
  }

  function changePage(page: number) {
    setPage(page);
  }

  const checkLicense = async (variant: any) => {
    if (variant?.size) {
      const { data } = await api.checkLicense({
        ...variant
      });
      return data?.checkLicense?.analysis;
    }
  };

  return (
    <DeploymentPlanContext.Provider
      value={{
        deploymentsPlan,
        loading,
        planSelected,
        loadingExecuteDeploy,
        getPlan,
        validatePipeline,
        selectPlan,
        changeLoadingExecuteDeploy,
        checkLicense,
        page,
        changePage
      }}
    >
      {children}
    </DeploymentPlanContext.Provider>
  );
}
