/* eslint-disable */
export const GRAPHQL_SERVER =
  process.env.NODE_ENV === 'production'
    ? '@@GRAPHQL_SERVER@@'
    : `//${location.hostname}:8000/graphql`;

export const API_KEY =
  process.env.NODE_ENV === 'production'
    ? '@@API_KEY@@'
    : 'MTkhcQlaryFGhaStRoKAtTOO9SzmZRAu';

export const URL =
  process.env.NODE_ENV === 'production'
    ? '@@URL@@'
    : 'https://core.stg.digibee.dev';

export const API_KEY_INTERCOM = 'adccj97f';

export const RECAPTCHA_SITE_KEY =
  process.env.NODE_ENV === 'production'
    ? '@@RECAPTCHA_SITE_KEY@@'
    : '6LdxSE4oAAAAAM-gGoYKtQkVyCb6gx8X1zO9_AGF';

export const EXPOSE_CYTOSCAPE =
  process.env.NODE_ENV === 'production' ? '@@EXPOSE_CYTOSCAPE@@' : 'true';

export const DYNATRACE_APP_ID =
  process.env.NODE_ENV === 'production' ? '@@DYNATRACE_APP_ID@@' : '';

export const DATE_PIPELINE_ENGINE_METRICS_PRODUCER =
  process.env.NODE_ENV === 'production'
    ? '@@DATE_PIPELINE_ENGINE_METRICS_PRODUCER@@'
    : '1619094963000';

export const SHOW_OLD_API_KEY_TAG =
  process.env.NODE_ENV === 'production' ? '@@SHOW_OLD_API_KEY_TAG@@' : 'true';

export const DAYS_TO_SHOW_OLD_API_KEY_TAG =
  process.env.NODE_ENV === 'production'
    ? '@@DAYS_TO_SHOW_OLD_API_KEY_TAG@@'
    : '90';

export const ACTIVATE_POLLING_PERMISSONS =
  process.env.NODE_ENV === 'production'
    ? '@@ACTIVATE_POLLING_PERMISSONS@@'
    : 'true';

export const POLLING_PERMISSIONS_INTERVAL =
  process.env.NODE_ENV === 'production'
    ? '@@POLLING_PERMISSIONS_INTERVAL@@'
    : '60000';

export const PIPELINE_INTERCOM_FALLBACK_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? '@@PIPELINE_INTERCOM_FALLBACK_ENDPOINT@@'
    : 'https://test.godigibee.io/pipeline/digibee/v1/intercom-offline';

export const PIPELINE_INTERCOM_FALLBACK_APIKEY =
  process.env.NODE_ENV === 'production'
    ? '@@PIPELINE_INTERCOM_FALLBACK_APIKEY@@'
    : 'BJsazPnTK2RB6q3afWmEUsQloEhxUjhf';

export const PORTAL_URL =
  process.env.NODE_ENV === 'production'
    ? '@@PORTAL_URL@@'
    : 'http://{realm}.localhost:3000';
