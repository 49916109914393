import { gql } from '@apollo/client';

// eslint-disable-next-line import/no-cycle
import clientApollo from './clientApollo';

const controllers = [];
const user = {
  fetch: async ({ realm, search }) => {
    try {
      const result = await clientApollo.query({
        query: gql`
          query searchUsers($realm: String!, $search: JSON) {
            searchUsers(realm: $realm, search: $search) {
              content {
                id
                active
                email
                realm
                entityId
                scopes
                status
                timezone
                tokenReset
                realmId
                firstName
                lastName
                fullName
                preferredLanguages
                data
                groups
              }
              last
              totalPages
              number
              first
              numberOfElements
              size
              totalElements
            }
          }
        `,
        variables: {
          realm,
          search
        }
      });
      return result;
    } catch (e) {
      throw Error(e);
    }
  },
  find: ({ realm, search, status }) =>
    clientApollo.query({
      query: gql`
        query users($realm: String!, $search: String, $status: [String]) {
          users(realm: $realm, search: $search, status: $status) {
            id
            email
            scopes
            status
            firstName
            lastName
            timezone
          }
        }
      `,
      variables: {
        realm,
        search,
        status
      }
    }),
  create({ realm, email, scopes = [], firstName, lastName, timezone }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createUser($realm: String!, $user: UserInput!) {
          createUser(realm: $realm, user: $user)
        }
      `,
      variables: {
        realm,
        user: {
          email,
          scopes,
          firstName,
          lastName,
          timezone
        }
      }
    });
  },
  getGroupUsers({ realm, users }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation getGroupUsers($realm: String!, $users: [String]) {
          getGroupUsers(realm: $realm, users: $users) {
            id
            firstName
            lastName
            email
            data
          }
        }
      `,
      variables: {
        realm,
        users
      }
    });
  },
  getUser({ realm, id }) {
    return clientApollo.query({
      query: gql`
        query getUser($realm: String!, $id: String!) {
          getUser(realm: $realm, id: $id) {
            id
            firstName
            lastName
            email
            timezone
            status
            groups
            data
          }
        }
      `,
      variables: {
        realm,
        id
      }
    });
  },
  getLostPermissions({ realm, id }) {
    return clientApollo.query({
      query: gql`
        query getLostPermissions($realm: String!, $id: String!) {
          getLostPermissions(realm: $realm, id: $id)
        }
      `,
      variables: {
        realm,
        id
      }
    });
  },
  updatePermissionsV2User({ realm, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation updatePermissionsV2User($realm: String!, $id: String!) {
          updatePermissionsV2User(realm: $realm, id: $id)
        }
      `,
      variables: {
        realm,
        id
      }
    });
  },
  getPermissionsUser({ realm, id, inactive }) {
    return clientApollo.query({
      query: gql`
        query getPermissionsUser(
          $realm: String!
          $id: String!
          $inactive: Boolean
        ) {
          getPermissionsUser(realm: $realm, id: $id, inactive: $inactive)
        }
      `,
      variables: {
        realm,
        id,
        inactive
      }
    });
  },
  edit({ realm, email, scopes, firstName, lastName, timezone }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editUser($realm: String!, $user: UserInput!) {
          editUser(realm: $realm, user: $user)
        }
      `,
      variables: {
        realm,
        user: {
          email,
          scopes,
          firstName,
          lastName,
          timezone
        }
      }
    });
  },
  async remove({ realm, email }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteUser($realm: String!, $email: String!) {
          deleteUser(realm: $realm, email: $email)
        }
      `,
      variables: {
        realm,
        email
      }
    });
  },
  async resetPassword({ realm, email }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation resetPassword($realm: String!, $email: String!) {
          resetPassword(realm: $realm, email: $email)
        }
      `,
      variables: {
        realm,
        email
      }
    });
  },
  async unlockUser({ realm, email }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation unlockUser($realm: String!, $email: String!) {
          unlockUser(realm: $realm, email: $email)
        }
      `,
      variables: {
        realm,
        email
      }
    });
  },
  async reactivate({ realm, email }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation reactivateUser($realm: String!, $email: String!) {
          reactivateUser(realm: $realm, email: $email)
        }
      `,
      variables: {
        realm,
        email
      }
    });
  },
  async assignGroup({ realm, id, groups }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation userAssignGroup(
          $realm: String!
          $id: String!
          $groups: [String]
        ) {
          userAssignGroup(realm: $realm, id: $id, groups: $groups) {
            id
            firstName
            lastName
            email
            timezone
            status
            groups
          }
        }
      `,
      variables: {
        realm,
        id,
        groups
      }
    });
  },
  async isEmailUserValid({ realm, email }) {
    const request = await clientApollo.query({
      query: gql`
        query validateUserEmail($realm: String!, $email: String!) {
          validateUserEmail(realm: $realm, email: $email)
        }
      `,
      variables: {
        email,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request?.data?.validateUserEmail;
  },
  calcel() {
    controllers.map(controller => controller.abort());
  }
};

export default user;
