/* global document, window */
/* eslint-disable no-use-before-define */

import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';
import s from 'underscore.string';
import uuid from 'uuid/v4';

import { pipeline } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeCanvas from '~/common/helpers/normalizeCanvas';

const EMPTY_CANVAS = {
  nodes: [],
  edges: []
};

const DEFAULT_NESTED_CANVAS = {
  edges: [],
  nodes: [
    {
      id: 'start-track',
      type: 'start-track@not-configured',
      data: {
        id: 'start-track',
        type: 'start-track@not-configured'
      },
      group: 'nodes'
    }
  ]
};

const normalizePipelineNode = node => {
  const content =
    typeof node.data === 'string' ? JSON.parse(node.data) : node.data;

  if (!content) {
    const foo = {
      id: node.id,
      type: node.type,
      data: {
        ...node,
        key: node.name
      },
      group: 'nodes'
    };
    return foo;
  }

  const key =
    content.key ||
    [content.name, /connector/.test(content.type) ? undefined : content.type]
      .filter(Boolean)
      .join('-');

  const onProcessTrack = content.onProcessTrack
    ? { onProcessTrack: normalizePipelineCanvas(content.onProcessTrack) }
    : {};
  const onExceptionTrack = content.onExceptionTrack
    ? { onExceptionTrack: normalizePipelineCanvas(content.onExceptionTrack) }
    : {};

  return {
    ...node,
    data: {
      id: node.id,
      ...content,
      key,
      stepName: s.humanize(content.stepName),
      ...onProcessTrack,
      ...onExceptionTrack
    },
    group: 'nodes'
  };
};

const normalizePipelineEdge = edge => {
  if (typeof edge.group === 'string') return edge;
  return {
    ...edge,
    data: edge,
    group: 'edges'
  };
};

const normalizePipelineCanvas = canvas =>
  Immutable({
    nodes: canvas.nodes.map(normalizePipelineNode),
    edges: canvas.edges.map(normalizePipelineEdge)
  });

const INITIAL_CANVAS_ID = uuid();

const initialState = Immutable({
  breadcrumbs: [],
  canvas: {},
  connectorConfiguration: {
    element: {},
    component: {},
    opened: false,
    schema: [],
    type: ''
  },
  triggerConfiguration: {
    element: {},
    component: {},
    opened: false,
    schema: [],
    type: ''
  },
  connectors: {
    opened: true
  },
  currentCanvas: EMPTY_CANVAS,
  currentCanvasId: INITIAL_CANVAS_ID,
  data: {
    sensitiveFields: {
      logSensitiveFields: []
    },
    pipeline: {
      name: '',
      description: '',
      canvas: {
        nodes: [],
        edges: []
      },
      parameterizedReplica: null,
      inSpec: null,
      outSpec: null
    },
    components: [],
    triggers: [],
    replicas: [],
    libraries: []
  },
  edgeConfiguration: {
    data: {},
    opened: false
  },
  normalizedPipeline: EMPTY_CANVAS,
  saveSidesheetOpened: false,
  settingsSidesheetOpened: false,
  shortcuts: {
    opened: false
  },
  save: {
    id: INITIAL_CANVAS_ID
  },
  saving: false,
  scale: 1,
  trackingId: uuid()
});

const CanvasModel = {
  name: 'canvas',
  state: initialState,
  reducers: {
    setNormalizedPipeline(state, normalizedPipeline) {
      return state.merge({ normalizedPipeline });
    },

    setCurrentCanvas(state, currentCanvas) {
      return state.merge({ currentCanvas });
    },

    setCurrentCanvasId(state, currentCanvasId) {
      return state.merge({ currentCanvasId });
    },

    setSaveId(state, id) {
      // Remove prevenção de recarregamento e atualização de rota.
      window.onbeforeunload = null;

      // Devolve ação de swipe para voltar ou recarregar pagina com mousetrack.
      document.body.style.overscrollBehavior = 'auto';

      return state.merge({ save: { id } }, { deep: true });
    },

    updateCanvas(state, canvas) {
      return state.merge({ canvas }, { deep: true });
    },

    setData(state, data) {
      return state.merge(
        // eslint-disable-next-line no-unneeded-ternary
        {
          data: data || {},
          canUpdateInstance: !data.pipeline.parameterizedReplica
        },
        { deep: true }
      );
    },

    updatePipeline(state, data) {
      return state.merge(
        {
          data: {
            pipeline: data
          }
        },
        { deep: true }
      );
    },

    setSaving(state, saving) {
      return state.merge({ saving });
    },

    setScale(state, scale) {
      return state.merge({ scale });
    },

    breadcrumbsDecrement(state, data) {
      return state.merge({
        breadcrumbs: state.breadcrumbs.slice(
          0,
          state.breadcrumbs.length - data - 1
        )
      });
    },

    breadcrumbsIncrement(state, data) {
      return state.merge({ breadcrumbs: [...state.breadcrumbs, data] });
    },

    breadcrumbsReset(state) {
      return state.merge({ breadcrumbs: [] });
    },

    openConnectorConfiguration(
      state,
      { element, component, formValue, schema, type }
    ) {
      return state.merge(
        {
          connectorConfiguration: {
            opened: true,
            element,
            component,
            formValue,
            schema,
            type
          }
        },
        { merge: true }
      );
    },

    openTriggerConfiguration(state, element) {
      return state.merge(
        {
          triggerConfiguration: {
            ...state.triggerConfiguration,
            element,
            opened: true
          }
        },
        { merge: true }
      );
    },

    setTriggerConfiguration(state, { element }) {
      return state.merge(
        {
          triggerConfiguration: {
            ...state.triggerConfiguration,
            element
          }
        },
        { deep: true }
      );
    },

    closeTriggerConfigurarion(state) {
      return state.merge(
        {
          triggerConfiguration: {
            ...state.triggerConfiguration,
            opened: false
          }
        },
        { merge: true }
      );
    },

    closeConnectorConfiguration(state) {
      return state.merge(
        {
          connectorConfiguration: {
            ...state.connectorConfiguration,
            opened: false,
            formValue: null,
            schema: []
          }
        },
        { merge: true }
      );
    },

    openConnectors(state) {
      return state.merge(
        {
          connectors: {
            opened: true
          }
        },
        { merge: true }
      );
    },

    closeConnectors(state) {
      return state.merge(
        {
          connectors: {
            opened: false
          }
        },
        { merge: true }
      );
    },

    openSaveSidesheet(state) {
      return state.merge({ saveSidesheetOpened: true });
    },

    closeSaveSidesheet(state) {
      return state.merge({ saveSidesheetOpened: false });
    },

    openSettingsSidesheet(state) {
      return state.merge({ settingsSidesheetOpened: true });
    },

    closeSettingsSidesheet(state) {
      return state.merge({ settingsSidesheetOpened: false });
    },

    openShortcuts(state) {
      return state.merge(
        {
          shortcuts: {
            opened: true
          }
        },
        { merge: true }
      );
    },

    closeShortcuts(state) {
      return state.merge(
        {
          shortcuts: {
            opened: false
          }
        },
        { merge: true }
      );
    },

    openPreventClose(state) {
      return state.merge(
        {
          preventClose: {
            opened: true
          }
        },
        { merge: true }
      );
    },

    closePreventClose(state) {
      return state.merge(
        {
          preventClose: {
            opened: false
          }
        },
        { merge: true }
      );
    },

    updatePipelineSettings(
      state,
      { description, sensitiveFields, inSpec, outSpec, parameterizedReplica }
    ) {
      return state.merge(
        {
          data: {
            pipeline: {
              description,
              inSpec,
              outSpec,
              parameterizedReplica
            },
            sensitiveFields: {
              logSensitiveFields: sensitiveFields
            }
          }
        },
        { deep: true }
      );
    },
    reset(state) {
      return state.merge({
        connectorConfiguration: {
          element: {},
          component: {},
          opened: false,
          schema: [],
          type: ''
        },
        triggerConfiguration: {
          element: {},
          component: {},
          opened: false,
          schema: [],
          type: ''
        },
        connectors: {
          opened: true
        },
        edgeConfiguration: {
          data: {},
          opened: false
        },
        shortcuts: {
          opened: false
        },
        preventClose: {
          opened: false
        }
      });
    }
  },
  effects: dispatch => ({
    async fetchData({ id, realm }) {
      const response = await pipeline.get(id, realm);
      dispatch.canvas.setData(response.data);
    },

    async savePipeline(payload, rootState) {
      const pipelineData = {
        ...rootState.canvas.data.pipeline,
        ...payload,
        canvas: normalizeCanvas(rootState.canvas.normalizedPipeline),
        triggerSpec: {
          ...rootState.canvas.triggerConfiguration.element
        }
      };

      if (isEmpty(payload) && !pipelineData.id) {
        return dispatch.canvas.openSaveSidesheet();
      }

      try {
        const response = await pipeline.save({
          realm: rootState.application.realm.realm,
          trackingId: rootState.canvas.trackingId,
          pipeline: pipelineData
        });

        const updatedPipeline = response.data.updatePipeline;

        dispatch.canvas.updatePipeline(updatedPipeline);

        return await pipeline.updateSensitiveFields({
          realm: rootState.application.realm.realm,
          pipelineId: updatedPipeline.id,
          sensitiveFields: get(
            rootState,
            'canvas.data.sensitiveFields.logSensitiveFields',
            []
          )
        });
      } catch (error) {
        dispatch.canvas.setSaving(false);
        return toast.error(error.message);
      }
    },

    updateCurrentCanvas(canvas, rootState) {
      const { breadcrumbs, normalizedPipeline } = rootState.canvas;

      if (breadcrumbs.length === 0) {
        return dispatch.canvas.setNormalizedPipeline(canvas);
      }

      const currentCanvasPath = breadcrumbs
        .map(({ path }) => path)
        .join('.')
        .split('.');
      const updated = normalizedPipeline.setIn(currentCanvasPath, canvas);
      return dispatch.canvas.setNormalizedPipeline(updated);
    },

    updateElements(canvas, rootState) {
      const { breadcrumbs, normalizedPipeline } = rootState.canvas;

      if (breadcrumbs.length === 0) {
        return dispatch.canvas.setNormalizedPipeline(canvas);
      }

      const currentCanvasPath = breadcrumbs
        .map(({ path }) => path)
        .join('.')
        .split('.');
      const updated = normalizedPipeline.setIn(currentCanvasPath, canvas);
      return dispatch.canvas.setNormalizedPipeline(updated);
    },

    updateElement(values, rootState) {
      const findNodeIndex = (canvas, id, type) =>
        canvas[type === 'node' ? 'nodes' : 'edges'].findIndex(
          curr => curr.data.id === id
        );
      const setNode = (node = Immutable({}), data) =>
        node.merge(data, { deep: true });
      const {
        breadcrumbs,
        currentCanvas,
        normalizedPipeline,
        connectorConfiguration,
        triggerConfiguration
      } = rootState.canvas;

      const configuration = isEmpty(connectorConfiguration.type)
        ? triggerConfiguration
        : connectorConfiguration;

      const { type } = configuration;

      const currentCanvasPath = breadcrumbs.map(({ path }) => path).join('.');
      const currentNode = configuration.element;
      const currentNodeIndex = findNodeIndex(
        currentCanvas,
        currentNode.id,
        type
      );

      const currentNodePath = [
        ...currentCanvasPath.split('.'),
        type === 'node' ? 'nodes' : 'edges',
        currentNodeIndex,
        ...(type === 'node' ? ['data'] : ['data', 'data'])
      ].filter(Boolean);
      const value =
        type === 'node'
          ? values
          : {
              type: 'choice',
              ...values
            };
      const updated = normalizedPipeline.updateIn(
        currentNodePath,
        setNode,
        value
      );
      return dispatch.canvas.setNormalizedPipeline(updated);
    },
    updateTrigger(value, rootState) {
      // TODO:
      const { normalizedPipeline } = rootState.canvas;
      const setNode = (node = Immutable({}), data) => node.merge(data);
      const currentTriggerPath = ['nodes', 0];
      const updated = normalizedPipeline.updateIn(
        currentTriggerPath,
        setNode,
        value
      );
      return dispatch.canvas.setNormalizedPipeline(updated);
    }
  }),
  logics: [
    {
      type: 'canvas/setData',
      latest: true,
      process({ action }, dispatch, done) {
        const { canvas, triggerSpec } = action.payload.pipeline;
        dispatch.canvas.setTriggerConfiguration({
          element: !isEmpty(triggerSpec)
            ? triggerSpec
            : { id: 'trigger', key: '', type: 'trigger@not-configured' }
        });
        dispatch.canvas.setNormalizedPipeline(normalizePipelineCanvas(canvas));
        done();
      }
    },
    {
      type: [
        'canvas/setNormalizedPipeline',
        'canvas/breadcrumbsIncrement',
        'canvas/breadcrumbsDecrement',
        'canvas/breadcrumbsReset'
      ],
      latest: true,
      process({ getState }, dispatch, done) {
        const { breadcrumbs, normalizedPipeline } = getState().canvas;

        if (breadcrumbs.length === 0) {
          dispatch.canvas.setCurrentCanvas(normalizedPipeline);
          return done();
        }

        const currentCanvas =
          get(
            normalizedPipeline,
            breadcrumbs.map(({ path }) => path).join('.')
          ) || DEFAULT_NESTED_CANVAS;

        dispatch.canvas.setCurrentCanvas(currentCanvas);

        return done();
      }
    },
    {
      type: 'canvas/updateCurrentCanvas',
      latest: true,
      process(context, dispatch, done) {
        // Prevenção de recarregamento e atualização de rota.
        window.onbeforeunload = () => '';

        // Aplica prevenção para ação de swipe para voltar ou recarregar pagina com mousetrack.
        document.body.style.overscrollBehavior = 'none';

        done();
      }
    },
    {
      type: ['canvas/updateElement', 'canvas/updateElements'],
      latest: true,
      process(context, dispatch, done) {
        // Prevenção de recarregamento e atualização de rota.
        window.onbeforeunload = () => '';

        // Aplica prevenção para ação de swipe para voltar ou recarregar pagina com mousetrack.
        document.body.style.overscrollBehavior = 'none';

        dispatch.canvas.setCurrentCanvasId(uuid());
        dispatch.canvas.closeConnectorConfiguration();

        done();
      }
    },
    {
      type: 'canvas/savePipeline',
      latest: true,
      process(context, dispatch, done) {
        // dispatch.canvas.setSaving(true);
        done();
      }
    },
    {
      type: 'canvas/openSaveSidesheet',
      latest: true,
      process(context, dispatch, done) {
        // dispatch.canvas.setSaving(false);
        done();
      }
    },
    {
      type: 'canvas/updatePipelineSettings',
      latest: true,
      process(context, dispatch, done) {
        dispatch.canvas.closeSettingsSidesheet();
        done();
      }
    },
    {
      type: 'canvas/updatePipeline',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.canvas.closeSaveSidesheet();
        dispatch.canvas.setSaving(false);
        dispatch.canvas.setSaveId(getState().canvas.currentCanvasId);

        const { realm } = getState().application;
        const { id } = action.payload;

        if (id) {
          dispatch.router.navigate({
            to: `/${realm.realm}/pipelines/experimental/${id}`
          });
          toast.success(i18n.t('scenes.canvas.messages.success.save'));
        }

        done();
      }
    },
    {
      type: 'canvas/updateTrigger',
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.canvas.setTriggerConfiguration({ element: payload.data });
        done();
      }
    },
    {
      type: [
        'openConnectorConfiguration',
        'openSaveSidesheet',
        'openPreventClose',
        'openSettingsSidesheet',
        'openShortcuts',
        'openTriggerConfiguration'
      ],
      latest: true,
      process(context, dispatch, done) {
        done();
      }
    },
    {
      type: [
        'closeConnectorConfiguration',
        'closePreventClose',
        'closeSaveSidesheet',
        'closeSettingsSidesheet',
        'closeShortcuts',
        'closeTriggerConfiguration'
      ],
      latest: true,
      process(context, dispatch, done) {
        done();
      }
    }
  ]
};

export default CanvasModel;
