/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const libraries = {
  get: variables =>
    clientApollo.query({
      query: gql`
        query data($realm: String!, $id: String!) {
          libraryComponents(realm: $realm, componentId: $id) {
            id
            title
            documentation
            name
            versionMajor
            versionMinor
            versionFix
            disabled
            description
            idLib
            state
            idCatalog
            inSpec
            outSpec
            configExample
            jsonSchema
            testMode
            errorDetail
            canvas {
              edges {
                id
                target
                source
                data {
                  type
                  conditionType
                  conditionRule
                  condition
                  label
                  description
                  executionId
                }
              }
              nodes {
                id
                type
                data
              }
            }
          }
        }
      `,
      variables
    }),

  fetchLibraries: variables =>
    clientApollo.query({
      query: gql`
        query librariesPaginations($realm: String!) {
          librariesPaginations(realm: $realm) {
            content
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables
    }),

  fetchComponents: variables =>
    clientApollo.query({
      query: gql`
        query componentsByLibrary(
          $realm: String!
          $libraryId: String!
          $page: Int
        ) {
          componentsByLibrary(
            realm: $realm
            libraryId: $libraryId
            page: $page
          ) {
            content
            last
            totalPages
            number
            first
            numberOfElements
            size
            totalElements
          }
        }
      `,
      variables
    }),

  saveComponent: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation updateLibrary(
          $realm: String!
          $library: LibraryComponentInput!
        ) {
          updateLibrary(realm: $realm, library: $library) {
            id
            name
            title
            versionMajor
            versionMinor
            versionFix
            disabled
            description
            idLib
            state
            idCatalog
            inSpec
            outSpec
            configExample
            jsonSchema
            testMode
            errorDetail
            canvas {
              nodes {
                id
                type
                data
              }
              edges {
                id
                source
                target
                data {
                  type
                  conditionType
                  conditionRule
                  condition
                  label
                  description
                  executionId
                }
              }
            }
          }
        }
      `,
      variables
    }),
  createNewLibrary: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation createNewLibrary($realm: String!, $library: JSON!) {
          createNewLibrary(realm: $realm, library: $library)
        }
      `,
      variables
    }),
  async isLibraryNameValid({ realm, name }) {
    const request = await clientApollo.query({
      query: gql`
        query validateLibraryName($realm: String!, $name: String!) {
          validateLibraryName(realm: $realm, name: $name)
        }
      `,
      variables: {
        name,
        realm
      },
      fetchPolicy: 'network-only'
    });

    return request.data.validateLibraryName;
  },
  archiveComponent: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation archiveLibraryComponent(
          $realm: String!
          $idLibrary: String!
          $idComponent: String!
        ) {
          archiveLibraryComponent(
            realm: $realm
            idLibrary: $idLibrary
            idComponent: $idComponent
          )
        }
      `,
      variables
    }),
  deployLibraryComponent: variables =>
    clientApollo.mutate({
      mutation: gql`
        mutation deployLibraryComponent(
          $realm: String!
          $idLibrary: String!
          $idComponent: String!
        ) {
          deployLibraryComponent(
            realm: $realm
            idLibrary: $idLibrary
            idComponent: $idComponent
          ) {
            id
            name
            title
            versionMajor
            versionMinor
            versionFix
            disabled
            description
            idLib
            state
            idCatalog
            inSpec
            outSpec
            configExample
            jsonSchema
            testMode
            errorDetail
            canvas {
              nodes {
                id
                type
                data
              }
              edges {
                id
                source
                target
                data {
                  type
                  conditionType
                  conditionRule
                  condition
                  label
                  description
                  executionId
                }
              }
            }
          }
        }
      `,
      variables
    })
};

export default libraries;
