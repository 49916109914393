import styled from 'styled-components';

import FabNative from '~/components/Fab';
import HeadingNative from '~/components/Heading';
import { SubHeaderLink } from '~/components/SubHeader';

export const Container = styled.div`
  height: 112px;
  box-sizing: initial;
`;

export const Fab = styled(FabNative)`
  position: initial;
  margin-right: 0;
`;

export const Heading = styled(HeadingNative)`
  color: ${props => props.theme.colors.gre900};
`;

export const Action = styled.div`
  position: absolute;
  right: 24px;
  bottom: -16px;
  z-index: 1001;
`;

export const Top = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Left = styled.div`
  width: 25%;
  padding: 0 24px;
`;

export const Center = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Right = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
`;

export const SubHeaderLinkWithBetaTag = styled(SubHeaderLink)`
  display: flex;
  align-items: center;
`;

export const Badge = styled.div`
  display: block;
  font-size: 10px;
  line-height: 15px;
  border-radius: 100px;
  padding: 0 6px;
  font-weight: 400;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  font-family: ${props => props.theme.typography.fontFamily};
  height: 16px;
  background-color: ${props => props?.theme?.colors?.pri100};
  pointer-events: none;
  cursor: default;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: ${props => props?.theme?.colors?.gra900};
  margin-left: 4px;
  margin-top: -10px;
`;

export const AlphaBadge = styled(Badge)`
  background-color: ${props => props?.theme?.colors?.blu100};
  color: ${props => props?.theme?.colors?.blu500};
`;
