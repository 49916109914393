import queryString from 'querystring';

import { pickBy, identity, omit } from 'lodash';
import moment from 'moment';
import { createSearchParams } from 'react-router-dom';
import Immutable from 'seamless-immutable';

import { audit } from '~/api';

// eslint-disable-next-line no-restricted-globals
const params = queryString.parse(location.search.replace('?', ''));

const transformFormatDate = (values, props, currentDate) =>
  currentDate
    ? currentDate.subtract(values, props).toISOString()
    : moment().subtract(values, props).toISOString();

const initialState = Immutable({
  item: null,
  loading: false,
  error: null,
  advancedMode: false,
  specificTimeFilterOpened: false,
  search: '',
  searchObject: {
    email: params.email || '',
    service: params.service || '',
    objectId: params.objectId || '',
    description: params.description || '',
    time: params.timeType || '5 Minutes',
    method: params.method || '',
    status: params.status || '',
    specific: {
      dateFrom: moment(params.dateFrom) || moment(),
      dateTo: moment(params.dateTo) || moment()
    }
  }
});

const admin = {
  name: 'audit',
  state: initialState,
  reducers: {
    loading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    error(state, error) {
      return state.merge({
        error
      });
    },
    success(state, payload) {
      return state.merge({
        item: payload.audit
      });
    },
    advancedSearch(state, payload) {
      return state.merge({
        advancedMode: payload
      });
    },
    toggleSpecificTimeFilter(state) {
      return state.merge({
        specificTimeFilterOpened: !state.specificTimeFilterOpened
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    }
  },
  effects: dispatch => ({
    async find({ realm, searchList, page }) {
      let dateFrom;
      let dateTo;
      const { time, description, email, method, service } = searchList;
      const currentDate = moment();
      switch (time) {
        case '5 Minutes':
          dateFrom = transformFormatDate(5, 'minutes', currentDate);
          dateTo = currentDate.toISOString();
          break;
        case '15 Minutes':
          dateFrom = transformFormatDate(15, 'minutes', currentDate);
          dateTo = currentDate.toISOString();
          break;
        case '1 Hour':
          dateFrom = transformFormatDate(1, 'hour', currentDate);
          dateTo = currentDate.toISOString();
          break;
        case 'specific':
          dateFrom = moment(searchList.specific.dateFrom).toISOString();
          dateTo = moment(searchList.specific.dateTo).toISOString();
          break;
        default:
          dateFrom = transformFormatDate(5, 'minutes', currentDate);
          dateTo = currentDate.toISOString();
      }

      dispatch.router.navigate({
        // eslint-disable-next-line no-restricted-globals
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...omit(pickBy(searchList, identity), 'time', 'specific'),
          dateFrom,
          timeType: time,
          dateTo
        })}`
      });

      try {
        const { data } = await audit.find({
          realm,
          description,
          email,
          method,
          dateFrom,
          dateTo,
          page,
          service
        });
        dispatch.audit.success(data);
      } catch (e) {
        dispatch.audit.error(e);
      }
    }
  }),
  logics: [
    {
      type: ['audit/error', 'audit/success'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.audit.loading(false);
        done();
      }
    },
    {
      type: ['audit/find'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.audit.loading(true);
        done();
      }
    }
  ]
};

export default admin;
