/* eslint-disable */
import { omit } from 'lodash';
import { objectOf, element, array, oneOfType, bool } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const OptionComponent = styled.div`
  display: flex;
  height: 64px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  text-align: left;
  align-items: center;
  z-index: 100000;
  font-family: ${props => props.theme.typography.fontFamily};
  ${props =>
    props.isFocused &&
    css`
      background-color: ${p => p.theme.colors.gra50} !important;
    `}

  ${props =>
    props.isSelected &&
    css`
      color: ${p => p.theme.colors.pri500} !important;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
`;

const Label = styled.h1`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 14px;
  color: ${props => props.theme.colors.gra900};
  font-weight: 400;
  margin: 0;
  padding: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Description = styled.p`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  color: ${props => props.theme.colors.gra700};
  font-weight: 400;
  margin: 0;
  padding: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Text = styled.div``;

export const OptionAssigment = ({
  innerProps,
  innerRef,
  isDisabled,
  ...props
}) =>
  !isDisabled ? (
    <OptionComponent
      data-testid='option'
      innerRef={innerRef}
      {...innerProps}
      {...omit(props, ['theme', 'cx'])}
    >
      <Wrapper>
        {props?.selectProps?.slotOption ? (
          <props.selectProps.slotOption {...props} />
        ) : null}
        <Text>
          <Label>{props?.data?.label}</Label>
          <Description>{props?.data?.description}</Description>
        </Text>
      </Wrapper>
    </OptionComponent>
  ) : null;

OptionAssigment.propTypes = {
  children: oneOfType([element, array]).isRequired,
  innerProps: objectOf().isRequired,
  innerRef: objectOf().isRequired,
  isDisabled: bool.isRequired,
  data: objectOf().isRequired,
  selectProps: objectOf().isRequired
};
