import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

// Locals
import {
  XL,
  LG,
  MD,
  SM,
  XS,
  XXS,
  CENTER,
  LEFT
} from '~/common/helpers/constants';

const MAP_COLORS = {
  primary: {
    default: 'pri500'
  },
  secondary: {
    default: 'gra700'
  },
  inverted: {
    default: 'white'
  },
  black: {
    default: 'black'
  }
};

const getColors = (type = 'black') => MAP_COLORS[type];

const baseStyles = ({ theme, type, weight, align }) => css`
  font-weight: ${theme.fontWeight[weight]};
  font-family: ${theme.typography.fontFamily};
  color: ${theme.colors[getColors(type).default]};
  text-align: ${align};
`;

const sizeStyles = ({ theme, size }) => css`
  font-size: ${theme.typographyCurrent.headings[size].fontSize};
  letter-spacing: ${theme.typographyCurrent.headings[size].letterSpacing}px;
`;

const Heading = ({ as, ...restProps }) => {
  const StyledElement = styled(as)`
    ${baseStyles};
    ${sizeStyles};
  `;
  return React.createElement(StyledElement, restProps);
};

Heading.XL = XL;
Heading.LG = LG;
Heading.MD = MD;
Heading.SM = SM;
Heading.XS = XS;
Heading.XXS = XXS;
Heading.CENTER = CENTER;
Heading.LEFT = LEFT;

Heading.propTypes = {
  /**
   * The HTML heading element to render.
   */
  children: PropTypes.element,
  /**
   * The HTML heading element to render.
   */
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs', 'xxs']),
  type: PropTypes.oneOf(['primary', 'secondary', 'inverted', 'black']),
  weight: PropTypes.oneOf(['bold', 'regular', 'thin']),
  align: PropTypes.oneOf(['center', 'left'])
};
Heading.defaultProps = {
  children: null,
  as: 'h2',
  noMargin: false,
  size: Heading.LG,
  type: 'black',
  weight: 'thin',
  align: Heading.LEFT
};

export default Heading;
