
import withHooks from '~/common/hoc/withHooks'

const enhancer = withHooks(({ onChange }: {onChange: (search: {pipeline: string, status?: string[]}) => void}) => {
  const props = {
    search: async (e: string, tags: string[] | undefined) => {
      onChange({ pipeline: e, status: tags});
    }
  };
  return props;
})

export default enhancer;
