import { Text } from '@digibee/beehive-ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Elements from './FormUserTemporaryLocked.elements';
import FooterLoginIssues from '../FooterLoginIssuesHelp';


function FormUserTemporaryLocked() {
  const { t } = useTranslation();

  return (
    <>
      <Elements.Container>
        <Text variant='h4'>{t('label.blocked_login')}</Text>
        <Text variant='b2'>
          {t('label.login_blocked_explanation')}
          &nbsp;
          <Link
            to='/login/forgotpassword'
            data-testid='governance-temporary-locked-login-forgot-password-link-blocked-user-page'
          >
            {t('action.redefine_your_password')}
          </Link>
        </Text>
        <Text data-testid='governance-support-message-blocked-user-page' variant='b2'>
          {t('label.blocked_login_if_problems_persists_support_msg')}
        </Text>
      </Elements.Container>
      <FooterLoginIssues />
    </>
  );
}

export default FormUserTemporaryLocked;
