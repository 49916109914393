/* eslint-disable */
import React from 'react';
import { omit } from 'lodash';
import styled, { css } from 'styled-components';
import { object, element, array, oneOfType, bool } from 'prop-types';

const Image = styled.img`
  width: 30px;
  height: 30px;
  padding: 0 10px 0 0;
`;
const OptionComponent = styled.div`
  display: flex;
  height: 48px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 16px;
  padding-height: 16px;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  text-align: left;
  align-items: center;
  z-index: 100000;
  font-family: ${props => props.theme.typography.fontFamily};
  ${props =>
    props.isFocused &&
    css`
      background-color: ${props => props.theme.colors.gra50} !important;
    `}

  ${props =>
    props.isSelected &&
    css`
      color: ${props => props.theme.colors.pri500} !important;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Option = function ({
  children,
  innerProps,
  innerRef,
  isDisabled,
  ...props
}) {
  return !isDisabled ? (
    <OptionComponent
      data-testid='option'
      innerRef={innerRef}
      {...innerProps}
      {...omit(props, ['theme', 'cx'])}
    >
      <Wrapper>
        {props?.selectProps?.slotOption ? (
          <props.selectProps.slotOption {...props} />
        ) : null}
        {children}
      </Wrapper>
    </OptionComponent>
  ) : null;
};

Option.propTypes = {
  children: oneOfType([element, array]),
  innerProps: object,
  innerRef: object,
  isDisabled: bool
};
