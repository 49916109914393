import { Box, Alert, Text, Tooltip, Flex } from '@digibee/beehive-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { type Deployment } from '../..';

import { checkErrorDeployment } from '~/scenes/Runtime/utils/validErrorsDeployment';

type StatusBodyProps = {
  deployment: Deployment;
  disabled: boolean;
  isAlert: boolean;
  isDeleting: boolean;
  infos: Infos;
  showAlert?: boolean;
  size?: 'sm' | 'md';
};

export type Infos = Item[][];

type Item = {
  icon?: IconProp;
  title?: string;
  titleTooltip?: string | null;
  value?: string | null;
  valueTooltip?: string | null;
};

export const StatusBody = ({
  deployment,
  disabled,
  isAlert,
  isDeleting,
  infos,
  showAlert = true,
  size = 'sm'
}: StatusBodyProps) => {
  const quantityDays = 7;
  const errors = checkErrorDeployment(
    isDeleting,
    isAlert,
    disabled,
    deployment?.status?.text,
    // @ts-ignore
    deployment,
    quantityDays
  );

  return (
    <>
      <Box>
        {infos.map((row, idx) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            css={{
              display: 'flex',
              gap: size === 'sm' ? '$3' : '$4',
              alignItems: 'center',
              padding: size === 'sm' ? '6px 0' : '5px 0',
              '&:empty': {
                display: 'none'
              }
            }}
          >
            {row.map(info => {
              if (!info.value) return null;

              return (
                <Flex
                  key={info.value}
                  css={{
                    gap: '$2',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    flex: 0
                  }}
                >
                  {info.icon && (
                    <FontAwesomeIcon
                      icon={info.icon}
                      // @ts-ignore
                      disable={disabled}
                      style={{ color: 'var(--colors-neu300)' }}
                      isEllipse
                    />
                  )}
                  {info.title &&
                    (info.titleTooltip ? (
                      <Tooltip content={info.titleTooltip}>
                        <Text
                          css={{
                            fontSize: size === 'sm' ? '$1' : '$2',
                            color: '$black',
                            lineHeight: size === 'sm' ? '$2' : '22px',
                            flex: 1,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {info.title}:
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text
                        css={{
                          fontSize: size === 'sm' ? '$1' : '$2',
                          color: '$black',
                          lineHeight: size === 'sm' ? '$2' : '22px',
                          flex: 1,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {info.title}:
                      </Text>
                    ))}
                  {info.valueTooltip ? (
                    <Tooltip content={info.valueTooltip}>
                      <Text
                        css={{
                          fontSize: size === 'sm' ? '$1' : '$2',
                          color: '$black',
                          lineHeight: size === 'sm' ? '$2' : '22px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '250px'
                        }}
                      >
                        {info.value}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text
                      css={{
                        fontSize: size === 'sm' ? '$1' : '$2',
                        color: '$black',
                        lineHeight: size === 'sm' ? '$2' : '22px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '250px'
                      }}
                    >
                      {info.value}
                    </Text>
                  )}
                </Flex>
              );
            })}
          </Box>
        ))}
      </Box>
      {showAlert && (
        <Alert
          title={errors.text}
          css={{
            margin: '0 1px'
          }}
          size='small'
          // @ts-ignore
          variant={errors.type === 'infoWarn' ? 'info' : errors.type}
        />
      )}
    </>
  );
};
